/* eslint-disable */
import { gql } from '@lumar/shared';
import * as ApolloReactCommon from '@lumar/shared';
import * as ApolloReactHooks from '@lumar/shared';
export type ObjectID = any;
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any; }
  /** CompareTo scalar type represents a union of CompareToType enum ("LastCrawl", "None") and an ObjectID of a Crawl. */
  CompareToCrawl: { input: any; output: any; }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** The ObjectID scalar type represents a unique identifier, often used to refetch an object or as key for a cache. The ObjectID type appears in a JSON response as a String; however, it is not intended to be human-readable. */
  ObjectID: { input: ObjectID; output: ObjectID; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type ApiCallbackHeader = {
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ApiCallbackHeaderInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type AbortBuildInput = {
  buildId: Scalars['ObjectID']['input'];
};

export type AbortBuildPayload = {
  build: Build;
};

export type AcceptSuggestedThresholdInput = {
  testResultIds: Array<Scalars['ObjectID']['input']>;
};

export type AccessibilityIssueSolutionSuggestion = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  issueDigest: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  suggestedSolution: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AccessibilityIssueSolutionSuggestionConnection = {
  /** A list of edges. */
  edges: Array<AccessibilityIssueSolutionSuggestionEdge>;
  /** A list of nodes. */
  nodes: Array<AccessibilityIssueSolutionSuggestion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AccessibilityIssueSolutionSuggestions in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AccessibilityIssueSolutionSuggestionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<AccessibilityIssueSolutionSuggestionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<AccessibilityIssueSolutionSuggestionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<AccessibilityIssueSolutionSuggestionConnectionFilterInput>>;
  crawlId?: InputMaybe<ConnectionIntFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type AccessibilityIssueSolutionSuggestionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AccessibilityIssueSolutionSuggestion;
};

export type AccessibilityIssueSolutionSuggestionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order AccessibilityIssueSolutionSuggestions by. */
  field: AccessibilityIssueSolutionSuggestionOrderField;
};

export enum AccessibilityIssueSolutionSuggestionOrderField {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type Account = {
  accountManagers: Array<Scalars['String']['output']>;
  accountSettings: Array<AccountSetting>;
  accountSsoClients?: Maybe<AccountSsoClientConnection>;
  accountUsers?: Maybe<AccountUserConnection>;
  active: Scalars['Boolean']['output'];
  addressCity?: Maybe<Scalars['String']['output']>;
  addressZip?: Maybe<Scalars['String']['output']>;
  aiFeaturesEnabled: Scalars['Boolean']['output'];
  apiCallbackHeaders?: Maybe<Array<ApiCallbackHeader>>;
  apiCallbackUrl?: Maybe<Scalars['String']['output']>;
  availableCredits: Scalars['Float']['output'];
  buildSchedules: BuildScheduleConnection;
  conductorExpiry?: Maybe<Scalars['DateTime']['output']>;
  contractStartDate?: Maybe<Scalars['DateTime']['output']>;
  contractTerminationDate?: Maybe<Scalars['DateTime']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  crawlerIpAddresses: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creditAllocations?: Maybe<CreditAllocationConnection>;
  creditReportExports?: Maybe<CreditReportExportConnection>;
  creditReports?: Maybe<CreditReportConnection>;
  csPartnerName?: Maybe<Scalars['String']['output']>;
  customDashboardCollections: CustomDashboardCollectionConnection;
  customDashboardCollectionsViewCount: Scalars['Int']['output'];
  customDashboardViews?: Maybe<CustomDashboardViewConnection>;
  customDashboards: CustomDashboardConnection;
  customHeaderColor?: Maybe<Scalars['String']['output']>;
  customLogo?: Maybe<Scalars['String']['output']>;
  customMenuColor?: Maybe<Scalars['String']['output']>;
  customMetricContainers: CustomMetricContainerConnection;
  customMetricUploadContainers: CustomMetricUploadContainerConnection;
  customProxy?: Maybe<Scalars['String']['output']>;
  customViews?: Maybe<CustomViewConnection>;
  disableUpselling: Scalars['Boolean']['output'];
  featureFlag: FeatureFlag;
  featureFlags: Array<FeatureFlag>;
  id: Scalars['ObjectID']['output'];
  jiraAuthentications: JiraAuthenticationConnection;
  legacyTasks: LegacyTaskConnection;
  limitLevelsMax: Scalars['Int']['output'];
  limitPagesMax: Scalars['Int']['output'];
  locations: Array<Location>;
  maxConcurrentCrawls: Scalars['Int']['output'];
  /** Maximum crawl rate that can be set for a Project or Test Suite. */
  maxCrawlRate: Scalars['Int']['output'];
  maxCustomReportsPerProject: Scalars['Int']['output'];
  /** Maximum sum of all CustomViews in the Account's CustomDashboardCollections. */
  maxDashboardCollectionViews: Scalars['Int']['output'];
  maxGoogleSearchConsolePropertiesPerProject: Scalars['Int']['output'];
  maxLegacyTasksPerProject: Scalars['Int']['output'];
  /** Maximum total of Tests that can be created for Projects in the Account. */
  maxProjectTests: Scalars['Int']['output'];
  maxReportTemplateOverridesPerProject: Scalars['Int']['output'];
  /** Maximum total of restricted filter predicates allowed in a Segment filter: arrayContainsLike, arrayNotContainsLike, endsWith, contains, notContains, matchesRegex, notMatchesRegex. */
  maxSegmentRestrictedFilterPredicates: Scalars['Int']['output'];
  maxSegmentsPerProject: Scalars['Int']['output'];
  maximumRenderTimeout: Scalars['Int']['output'];
  monitorNotifications: TestResultConnection;
  monitorProjects: ProjectConnection;
  name?: Maybe<Scalars['String']['output']>;
  notLinkedTestSuites?: Maybe<TestSuiteConnection>;
  packageAccount?: Maybe<PackageAccount>;
  packagePlan?: Maybe<Scalars['String']['output']>;
  parentTestSuites?: Maybe<TestSuiteConnection>;
  phone?: Maybe<Scalars['String']['output']>;
  primaryAccountPackage?: Maybe<AccountPackage>;
  project?: Maybe<Project>;
  projectTestsTotalCount: Scalars['Int']['output'];
  projects: ProjectConnection;
  rawID: Scalars['String']['output'];
  segmentedProjects: SegmentedProjectConnection;
  serpentVpcs?: Maybe<Array<Scalars['String']['output']>>;
  serverOverride?: Maybe<MetricsServerId>;
  subscription?: Maybe<AccountSubscription>;
  taggedTaskReportDownloadRowsLimit: Scalars['Int']['output'];
  testSuites?: Maybe<TestSuiteConnection>;
  timezone?: Maybe<Scalars['String']['output']>;
  unscheduledTestSuites?: Maybe<TestSuiteConnection>;
  updatedAt: Scalars['DateTime']['output'];
  userAgentSuffix: Scalars['String']['output'];
  userInvites?: Maybe<UserInviteConnection>;
};


export type AccountAccountSsoClientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountSsoClientConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountSsoClientOrder>>;
};


export type AccountAccountUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountUserConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountAvailableCreditsArgs = {
  creditAllocationType?: InputMaybe<CreditAllocationType>;
};


export type AccountBuildSchedulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BuildScheduleConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BuildScheduleOrder>>;
};


export type AccountCreditAllocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CreditAllocationConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreditAllocationOrder>>;
};


export type AccountCreditReportExportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CreditReportExportConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreditReportExportOrder>>;
};


export type AccountCreditReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CreditReportConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreditReportOrder>>;
};


export type AccountCustomDashboardCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomDashboardCollectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomDashboardCollectionOrder>>;
};


export type AccountCustomDashboardViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomDashboardViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomDashboardViewOrder>>;
};


export type AccountCustomDashboardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomDashboardConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomDashboardOrder>>;
};


export type AccountCustomMetricContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerOrder>>;
};


export type AccountCustomMetricUploadContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricUploadContainerConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricUploadContainerOrder>>;
};


export type AccountCustomViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomViewOrder>>;
};


export type AccountFeatureFlagArgs = {
  flagName: Scalars['String']['input'];
};


export type AccountJiraAuthenticationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountLegacyTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LegacyTaskConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyTaskOrder>>;
};


export type AccountMonitorNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestResultConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  projectIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  withUpToDateTestRule?: InputMaybe<Scalars['Boolean']['input']>;
};


export type AccountMonitorProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectOrder>>;
};


export type AccountNotLinkedTestSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestSuiteOrder>>;
};


export type AccountParentTestSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestSuiteOrder>>;
};


export type AccountProjectArgs = {
  id: Scalars['ObjectID']['input'];
};


export type AccountProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  crawlTypeCodes?: InputMaybe<Array<CrawlType>>;
  crawlTypeCodesExcluded?: InputMaybe<Array<CrawlType>>;
  filter?: InputMaybe<ProjectConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeTestSuites?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectOrder>>;
};


export type AccountSegmentedProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SegmentedProjectConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SegmentedProjectOrder>>;
};


export type AccountTestSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestSuiteOrder>>;
};


export type AccountUnscheduledTestSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestSuiteOrder>>;
};


export type AccountUserInvitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserInviteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserInviteOrder>>;
};

export type AccountConnection = {
  /** A list of edges. */
  edges: Array<AccountEdge>;
  /** A list of nodes. */
  nodes: Array<Account>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Accounts in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AccountConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<AccountConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<AccountConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<AccountConnectionFilterInput>>;
  active?: InputMaybe<ConnectionBooleanFilterInput>;
  aiFeaturesEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  country?: InputMaybe<ConnectionStringFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  csPartnerName?: InputMaybe<ConnectionStringFilterInput>;
  customLogo?: InputMaybe<ConnectionStringFilterInput>;
  disableUpselling?: InputMaybe<ConnectionBooleanFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  maxCustomReportsPerProject?: InputMaybe<ConnectionIntFilterInput>;
  maxGoogleSearchConsolePropertiesPerProject?: InputMaybe<ConnectionIntFilterInput>;
  maxLegacyTasksPerProject?: InputMaybe<ConnectionIntFilterInput>;
  maxProjectTests?: InputMaybe<ConnectionIntFilterInput>;
  maxReportTemplateOverridesPerProject?: InputMaybe<ConnectionIntFilterInput>;
  maxSegmentRestrictedFilterPredicates?: InputMaybe<ConnectionIntFilterInput>;
  maxSegmentsPerProject?: InputMaybe<ConnectionIntFilterInput>;
  maximumRenderTimeout?: InputMaybe<ConnectionIntFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  packagePlan?: InputMaybe<ConnectionStringFilterInput>;
  taggedTaskReportDownloadRowsLimit?: InputMaybe<ConnectionIntFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  userAgentSuffix?: InputMaybe<ConnectionStringFilterInput>;
};

export type AccountEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Account;
};

export type AccountOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Accounts by. */
  field: AccountOrderField;
};

export enum AccountOrderField {
  Active = 'active',
  AiFeaturesEnabled = 'aiFeaturesEnabled',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  PackagePlan = 'packagePlan',
  UpdatedAt = 'updatedAt'
}

export type AccountPackage = {
  credits: Scalars['BigInt']['output'];
  creditsAccessibility: Scalars['BigInt']['output'];
  creditsSiteSpeed: Scalars['BigInt']['output'];
  currency: Currency;
  packageType: AccountPackageType;
};

export enum AccountPackageType {
  Archived = 'Archived',
  PartnerSubscription = 'PartnerSubscription',
  Subscription = 'Subscription',
  Trial = 'Trial'
}

export type AccountSsoClient = {
  account: Account;
  accountId: Scalars['Int']['output'];
  defaultRoleCode?: Maybe<RoleCode>;
  ssoClientAccountId?: Maybe<Scalars['Int']['output']>;
  ssoClientId: Scalars['String']['output'];
};

export type AccountSsoClientConnection = {
  /** A list of edges. */
  edges: Array<AccountSsoClientEdge>;
  /** A list of nodes. */
  nodes: Array<AccountSsoClient>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AccountSSOClients in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AccountSsoClientConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<AccountSsoClientConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<AccountSsoClientConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<AccountSsoClientConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  ssoClientId?: InputMaybe<ConnectionStringFilterInput>;
};

export type AccountSsoClientEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AccountSsoClient;
};

export type AccountSsoClientOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order AccountSSOClients by. */
  field: AccountSsoClientOrderField;
};

export enum AccountSsoClientOrderField {
  AccountId = 'accountId',
  SsoClientId = 'ssoClientId'
}

export type AccountSetting = {
  code: Scalars['String']['output'];
  dataType: AccountSettingDataType;
  description?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  source?: Maybe<AccountSettingSource>;
  type: AccountSettingType;
  unit?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
};

export enum AccountSettingDataType {
  Boolean = 'Boolean',
  Number = 'Number'
}

export enum AccountSettingSource {
  Manual = 'Manual',
  Package = 'Package'
}

export enum AccountSettingType {
  IntegrationAddons = 'IntegrationAddons',
  SubscriptionSettings = 'SubscriptionSettings'
}

export type AccountSubscription = {
  accessibilityAvailable: Scalars['Boolean']['output'];
  addonByIntegrationType?: Maybe<AccountSubscriptionAddon>;
  addons: Array<AccountSubscriptionAddon>;
  analyzeAvailable: Scalars['Boolean']['output'];
  automateAvailable: Scalars['Boolean']['output'];
  billingAt?: Maybe<Scalars['DateTime']['output']>;
  chargebeeId?: Maybe<Scalars['String']['output']>;
  currentBillingPeriod: DateTimeRange;
  googleDataStudioAvailable: Scalars['Boolean']['output'];
  impactAvailable: Scalars['Boolean']['output'];
  jsRenderingAvailable: Scalars['Boolean']['output'];
  kvStoreAvailable: Scalars['Boolean']['output'];
  monitorAvailable: Scalars['Boolean']['output'];
  plan: AccountSubscriptionPlan;
  segmentationAvailable: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
};


export type AccountSubscriptionAddonByIntegrationTypeArgs = {
  integrationType: Scalars['String']['input'];
};

export type AccountSubscriptionAddon = {
  active: Scalars['Boolean']['output'];
  bespoke: Scalars['Boolean']['output'];
  beta: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  free: Scalars['Boolean']['output'];
  integrationType?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  period: Scalars['Int']['output'];
  prices: Array<AccountSubscriptionAddonPrice>;
  settings: Array<AccountSetting>;
  status?: Maybe<Scalars['String']['output']>;
  type: AddonType;
};

export type AccountSubscriptionAddonInput = {
  code: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type AccountSubscriptionAddonPrice = {
  active: Scalars['Boolean']['output'];
  chargebeeId: Scalars['String']['output'];
  currency: Currency;
  price: Scalars['Float']['output'];
};

export type AccountSubscriptionPlan = {
  active: Scalars['Boolean']['output'];
  addons: Array<AccountSubscriptionAddon>;
  bespoke: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  minCommitmentPeriod: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  packageType: Scalars['String']['output'];
  period: Scalars['Int']['output'];
  prices: Array<AccountSubscriptionPlanPrice>;
  settings: Array<AccountSetting>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AccountSubscriptionPlanPrice = {
  active: Scalars['Boolean']['output'];
  chargebeeId: Scalars['String']['output'];
  currency: Currency;
  price: Scalars['Float']['output'];
};

export type AccountSubscriptionPlanSettingInput = {
  code: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountUser = {
  account: Account;
  role: Role;
  roleCode: RoleCode;
  userEmail?: Maybe<Scalars['String']['output']>;
  userEmailValidatedAt?: Maybe<Scalars['DateTime']['output']>;
  userFirstName?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ObjectID']['output'];
  userLastName?: Maybe<Scalars['String']['output']>;
  userSsoClientId?: Maybe<Scalars['String']['output']>;
};

export type AccountUserConnection = {
  /** A list of edges. */
  edges: Array<AccountUserEdge>;
  /** A list of nodes. */
  nodes: Array<AccountUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AccountUsers in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AccountUserConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<AccountUserConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<AccountUserConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<AccountUserConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  roleCode?: InputMaybe<ConnectionRoleCodeFilterInput>;
  userId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type AccountUserEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AccountUser;
};

export type AddAccountUserInput = {
  accountId: Scalars['ObjectID']['input'];
  roleCode: RoleCode;
  userEmail: Scalars['String']['input'];
};

export type AddAccountUserPayload = {
  account: Account;
};

export type AddCustomDashboardToCollectionInput = {
  customDashboardCollectionId: Scalars['ObjectID']['input'];
  customDashboardIds: Array<Scalars['ObjectID']['input']>;
};

export type AddCustomSitemapsInput = {
  projectId: Scalars['ObjectID']['input'];
  sitemapUrls: Array<Scalars['String']['input']>;
};

export type AddCustomSitemapsPayload = {
  sitemaps: Array<Sitemap>;
};

export type AddCustomViewsToCollectionInput = {
  addToCustomDashboards?: InputMaybe<Scalars['Boolean']['input']>;
  customDashboardCollectionId: Scalars['ObjectID']['input'];
  customViewInputs: Array<CustomViewInput>;
};

export type AddCustomViewsToCustomDashboardCollectionPayload = {
  customViews: Array<CustomView>;
};

export type AddCustomViewsToCustomDashboardInput = {
  customDashboardId: Scalars['ObjectID']['input'];
  customViewIds: Array<Scalars['ObjectID']['input']>;
};

export type AddCustomViewsToCustomDashboardPayload = {
  customViews: Array<CustomView>;
};

export enum AddonType {
  IntegrationAddon = 'IntegrationAddon',
  ProjectAddon = 'ProjectAddon',
  UrlAddon = 'UrlAddon'
}

export type AdobeAnalyticsProjectReportSuite = {
  adobeConnection?: Maybe<AdobeConnection>;
  adobeConnectionId?: Maybe<Scalars['Int']['output']>;
  adobeJwtConnection?: Maybe<AdobeJwtConnection>;
  adobeJwtConnectionId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  maxRows?: Maybe<Scalars['Int']['output']>;
  minimumVisits: Scalars['Int']['output'];
  numberOfDays: Scalars['Int']['output'];
  perPage?: Maybe<Scalars['Int']['output']>;
  project: Project;
  rawID: Scalars['String']['output'];
  reportSuiteId: Scalars['String']['output'];
  suiteName: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AdobeAnalyticsProjectReportSuiteConnection = {
  /** A list of edges. */
  edges: Array<AdobeAnalyticsProjectReportSuiteEdge>;
  /** A list of nodes. */
  nodes: Array<AdobeAnalyticsProjectReportSuite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AdobeAnalyticsProjectReportSuites in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AdobeAnalyticsProjectReportSuiteConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<AdobeAnalyticsProjectReportSuiteConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<AdobeAnalyticsProjectReportSuiteConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<AdobeAnalyticsProjectReportSuiteConnectionFilterInput>>;
  adobeConnectionId?: InputMaybe<ConnectionIntFilterInput>;
  adobeJwtConnectionId?: InputMaybe<ConnectionIntFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  maxRows?: InputMaybe<ConnectionIntFilterInput>;
  minimumVisits?: InputMaybe<ConnectionIntFilterInput>;
  numberOfDays?: InputMaybe<ConnectionIntFilterInput>;
  perPage?: InputMaybe<ConnectionIntFilterInput>;
  reportSuiteId?: InputMaybe<ConnectionStringFilterInput>;
  suiteName?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type AdobeAnalyticsProjectReportSuiteEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AdobeAnalyticsProjectReportSuite;
};

export type AdobeAnalyticsProjectReportSuiteOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order AdobeAnalyticsProjectReportSuites by. */
  field: AdobeAnalyticsProjectReportSuiteOrderField;
};

export enum AdobeAnalyticsProjectReportSuiteOrderField {
  AdobeConnectionId = 'adobeConnectionId',
  AdobeJwtConnectionId = 'adobeJwtConnectionId',
  CreatedAt = 'createdAt',
  MaxRows = 'maxRows',
  MinimumVisits = 'minimumVisits',
  NumberOfDays = 'numberOfDays',
  PerPage = 'perPage',
  ReportSuiteId = 'reportSuiteId',
  SuiteName = 'suiteName',
  UpdatedAt = 'updatedAt'
}

export type AdobeAnalyticsReportSuite = {
  connection?: Maybe<AdobeConnection>;
  jwtConnection?: Maybe<AdobeJwtConnection>;
  reportSuiteId: Scalars['String']['output'];
  suiteName: Scalars['String']['output'];
};

export type AdobeConnection = {
  adobeAnalyticsProjectReportSuites: AdobeAnalyticsProjectReportSuiteConnection;
  availableAdobeAnalyticsReportSuites: Array<AdobeAnalyticsReportSuite>;
  company?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  isWorking: Scalars['Boolean']['output'];
  rawID: Scalars['String']['output'];
  secretLastFragment: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  username: Scalars['String']['output'];
};


export type AdobeConnectionAdobeAnalyticsProjectReportSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AdobeAnalyticsProjectReportSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdobeAnalyticsProjectReportSuiteOrder>>;
};

export type AdobeConnectionConnection = {
  /** A list of edges. */
  edges: Array<AdobeConnectionEdge>;
  /** A list of nodes. */
  nodes: Array<AdobeConnection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AdobeConnections in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AdobeConnectionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<AdobeConnectionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<AdobeConnectionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<AdobeConnectionConnectionFilterInput>>;
  company?: InputMaybe<ConnectionStringFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  username?: InputMaybe<ConnectionStringFilterInput>;
};

export type AdobeConnectionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AdobeConnection;
};

export type AdobeConnectionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order AdobeConnections by. */
  field: AdobeConnectionOrderField;
};

export enum AdobeConnectionOrderField {
  Company = 'company',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type AdobeJwtConnection = {
  adobeAnalyticsProjectReportSuites: AdobeAnalyticsProjectReportSuiteConnection;
  availableAdobeAnalyticsReportSuites: Array<AdobeAnalyticsReportSuite>;
  clientId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  isWorking: Scalars['Boolean']['output'];
  orgId: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  secretLastFragment: Scalars['String']['output'];
  technicalAccountId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type AdobeJwtConnectionAdobeAnalyticsProjectReportSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AdobeAnalyticsProjectReportSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdobeAnalyticsProjectReportSuiteOrder>>;
};

export type AdobeJwtConnectionConnection = {
  /** A list of edges. */
  edges: Array<AdobeJwtConnectionEdge>;
  /** A list of nodes. */
  nodes: Array<AdobeJwtConnection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AdobeJwtConnections in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AdobeJwtConnectionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<AdobeJwtConnectionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<AdobeJwtConnectionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<AdobeJwtConnectionConnectionFilterInput>>;
  clientId?: InputMaybe<ConnectionStringFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  orgId?: InputMaybe<ConnectionStringFilterInput>;
  technicalAccountId?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type AdobeJwtConnectionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AdobeJwtConnection;
};

export type AdobeJwtConnectionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order AdobeJwtConnections by. */
  field: AdobeJwtConnectionOrderField;
};

export enum AdobeJwtConnectionOrderField {
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  OrgId = 'orgId',
  TechnicalAccountId = 'technicalAccountId',
  UpdatedAt = 'updatedAt'
}

export type AdvancedCrawlRate = {
  end: AdvancedCrawlRateTime;
  maximumCrawlRate: Scalars['Float']['output'];
  start: AdvancedCrawlRateTime;
  type: AdvancedCrawlRateType;
};

export enum AdvancedCrawlRateDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type AdvancedCrawlRateInput = {
  end: AdvancedCrawlRateTimeInput;
  maximumCrawlRate: Scalars['Float']['input'];
  start: AdvancedCrawlRateTimeInput;
  type: AdvancedCrawlRateType;
};

export type AdvancedCrawlRateTime = {
  day?: Maybe<AdvancedCrawlRateDay>;
  /** UTC time in the HH:MM format. */
  hour: Scalars['String']['output'];
};

export type AdvancedCrawlRateTimeInput = {
  day?: InputMaybe<AdvancedCrawlRateDay>;
  /** UTC time in the HH:MM format. */
  hour: Scalars['String']['input'];
};

export enum AdvancedCrawlRateType {
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export type AdvancedCrawlStats = {
  crawledCrawlRate?: Maybe<Scalars['Float']['output']>;
  externalCrawlRate?: Maybe<Scalars['Float']['output']>;
  internalCrawlRate?: Maybe<Scalars['Float']['output']>;
  uncrawledCrawlRate?: Maybe<Scalars['Float']['output']>;
};

export type AlertSetting = {
  code: AlertSettingCode;
  name: Scalars['String']['output'];
};

export enum AlertSettingCode {
  Always = 'Always',
  Never = 'Never'
}

export enum AlertType {
  Fail = 'Fail',
  Pass = 'Pass',
  Warning = 'Warning'
}

export type AllMetricsCustomReportRowConnection = {
  /** A list of edges. */
  edges: Array<AllMetricsCustomReportRowEdge>;
  /** A list of nodes. */
  nodes: Array<Scalars['JSONObject']['output']>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AllMetricsReportRows in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AllMetricsCustomReportRowEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Scalars['JSONObject']['output'];
};

export type AllMetricsReportRowConnection = {
  /** A list of edges. */
  edges: Array<AllMetricsReportRowEdge>;
  /** A list of nodes. */
  nodes: Array<Scalars['JSONObject']['output']>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AllMetricsReportRows in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AllMetricsReportRowEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Scalars['JSONObject']['output'];
};

export type AllMetricsReportStatRowConnection = {
  /** A list of edges. */
  edges: Array<AllMetricsReportStatRowEdge>;
  /** A list of nodes. */
  nodes: Array<Scalars['JSONObject']['output']>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of AllMetricsReportRows in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type AllMetricsReportStatRowEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Scalars['JSONObject']['output'];
};

export type AuthToken = {
  value: Scalars['String']['output'];
};

export type AutomatorAccountStatistics = {
  averageUrlsCrawledPerDay: Scalars['Float']['output'];
  buildsCount: Scalars['Float']['output'];
  failedTestPercentage: Scalars['Float']['output'];
  passedTestPercentage: Scalars['Float']['output'];
  testSuitesCount: Scalars['Float']['output'];
  testsPerformedCount: Scalars['Float']['output'];
  warnedTestPercentage: Scalars['Float']['output'];
};

export type BaseAccountSetting = {
  code: Scalars['String']['output'];
  dataType: AccountSettingDataType;
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: AccountSettingType;
  unit?: Maybe<Scalars['String']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type BaseProject = {
  alertEmails?: Maybe<Array<Scalars['String']['output']>>;
  allPagesTotal?: Maybe<Scalars['Int']['output']>;
  apiCallbackHeaders?: Maybe<Array<ApiCallbackHeader>>;
  apiCallbackUrl?: Maybe<Scalars['String']['output']>;
  applyCustomHeadersToNavigationRequestsOnly: Scalars['Boolean']['output'];
  autoFinalizeOnCrawlLimits: Scalars['Boolean']['output'];
  /** Flags if threshold on all tests is calculated automatically, based on previous test results. */
  automaticThreshold: Scalars['Boolean']['output'];
  /** Flags if automatic thresholds are calculated for all (true) or some (false) tests. */
  automaticThresholdEnabledForAll: Scalars['Boolean']['output'];
  /** Blocks third-party cookies. */
  block3rdPartyCookies: Scalars['Boolean']['output'];
  clonedAt?: Maybe<Scalars['DateTime']['output']>;
  compareToCrawl?: Maybe<Scalars['CompareToCrawl']['output']>;
  crawlDisallowedUrls1stLevel: Scalars['Boolean']['output'];
  crawlHyperlinksExternal: Scalars['Boolean']['output'];
  crawlHyperlinksInternal: Scalars['Boolean']['output'];
  crawlImagesExternal: Scalars['Boolean']['output'];
  crawlImagesInternal: Scalars['Boolean']['output'];
  crawlNofollowHyperlinks: Scalars['Boolean']['output'];
  crawlNonHtml: Scalars['Boolean']['output'];
  crawlNotIncluded1stLevel: Scalars['Boolean']['output'];
  crawlOtherRelExternal: Scalars['Boolean']['output'];
  crawlOtherRelInternal: Scalars['Boolean']['output'];
  /** @deprecated Renamed to maximumCrawlRate. */
  crawlRate: Scalars['Float']['output'];
  crawlRedirectsExternal: Scalars['Boolean']['output'];
  crawlRedirectsInternal: Scalars['Boolean']['output'];
  crawlRelAmphtmlExternal: Scalars['Boolean']['output'];
  crawlRelAmphtmlInternal: Scalars['Boolean']['output'];
  crawlRelCanonicalsExternal: Scalars['Boolean']['output'];
  crawlRelCanonicalsInternal: Scalars['Boolean']['output'];
  crawlRelHreflangsExternal: Scalars['Boolean']['output'];
  crawlRelHreflangsInternal: Scalars['Boolean']['output'];
  crawlRelMobileExternal: Scalars['Boolean']['output'];
  crawlRelMobileInternal: Scalars['Boolean']['output'];
  crawlRelNextPrevExternal: Scalars['Boolean']['output'];
  crawlRelNextPrevInternal: Scalars['Boolean']['output'];
  crawlRobotsTxtNoindex: Scalars['Boolean']['output'];
  crawlScriptsExternal: Scalars['Boolean']['output'];
  crawlScriptsInternal: Scalars['Boolean']['output'];
  crawlSitemapsExternal: Scalars['Boolean']['output'];
  crawlSitemapsInternal: Scalars['Boolean']['output'];
  crawlStylesheetsExternal: Scalars['Boolean']['output'];
  crawlStylesheetsInternal: Scalars['Boolean']['output'];
  crawlTestSite: Scalars['Boolean']['output'];
  crawlUrlsTotal?: Maybe<Scalars['Int']['output']>;
  crawlingAt?: Maybe<Scalars['DateTime']['output']>;
  crawlsTotalCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customDns?: Maybe<Array<CustomDnsSetting>>;
  /** Use regular expressions to extract custom information from pages when they are crawled. */
  customExtractions?: Maybe<Array<CustomExtractionSetting>>;
  customRequestHeaders?: Maybe<Scalars['JSONObject']['output']>;
  dataLayerName?: Maybe<Scalars['String']['output']>;
  dataOnlyCrawlTypes?: Maybe<Array<CrawlType>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discoverSitemapsInRobotsTxt: Scalars['Boolean']['output'];
  duplicatePrecision: Scalars['Float']['output'];
  emptyPageThreshold?: Maybe<Scalars['Int']['output']>;
  enableKeyValueStore: Scalars['Boolean']['output'];
  excludeUrlPatterns?: Maybe<Array<Scalars['String']['output']>>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  flattenIframes: Scalars['Boolean']['output'];
  flattenShadowDom: Scalars['Boolean']['output'];
  gaDateRange: Scalars['Int']['output'];
  /** @deprecated Renamed to logSummaryRequestsHigh. */
  highLogSummaryRequests: Scalars['Int']['output'];
  ignoreInvalidSSLCertificate?: Maybe<Scalars['Boolean']['output']>;
  ignoreRobotsForNavigationRequests: Scalars['Boolean']['output'];
  includeBestPractices: Scalars['Boolean']['output'];
  includeHttpAndHttps: Scalars['Boolean']['output'];
  includeSubdomains: Scalars['Boolean']['output'];
  includeUrlPatterns?: Maybe<Array<Scalars['String']['output']>>;
  industryCode?: Maybe<Scalars['String']['output']>;
  isTestSuite: Scalars['Boolean']['output'];
  /** Same as crawlingAt, but includes dates of deleted crawls. */
  lastCrawlCrawlingAt?: Maybe<Scalars['DateTime']['output']>;
  lastCrawlStatus?: Maybe<CrawlStatus>;
  legacyTasksTotalCount: Scalars['Int']['output'];
  limitLevelsMax: Scalars['Int']['output'];
  /** @deprecated Renamed to limitUrlsMax. */
  limitPagesMax: Scalars['Int']['output'];
  limitUrlsMax: Scalars['Int']['output'];
  logSummaryRequestsHigh: Scalars['Int']['output'];
  logSummaryRequestsLow: Scalars['Int']['output'];
  /** @deprecated Renamed to logSummaryRequestsLow. */
  lowLogSummaryRequests: Scalars['Int']['output'];
  maxBodyContentLength?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Renamed to maxBodyContentLength. */
  maxContentSize: Scalars['Int']['output'];
  maxDescriptionLength?: Maybe<Scalars['Int']['output']>;
  maxExternalLinks?: Maybe<Scalars['Int']['output']>;
  maxHtmlSize?: Maybe<Scalars['Int']['output']>;
  maxLinks?: Maybe<Scalars['Int']['output']>;
  maxLinksProcessed: Scalars['Int']['output'];
  maxLoadTime?: Maybe<Scalars['Float']['output']>;
  maxRedirections: Scalars['Int']['output'];
  maxTitleWidth: Scalars['Int']['output'];
  maxUrlLength?: Maybe<Scalars['Int']['output']>;
  maximumCrawlRate: Scalars['Float']['output'];
  maximumCrawlRateAdvanced?: Maybe<Array<AdvancedCrawlRate>>;
  minContentRatio?: Maybe<Scalars['Float']['output']>;
  minDescriptionLength: Scalars['Int']['output'];
  minTitleLength: Scalars['Int']['output'];
  minVisits: Scalars['Int']['output'];
  mobileHomepageUrl?: Maybe<Scalars['String']['output']>;
  mobileUrlPattern?: Maybe<Scalars['String']['output']>;
  /** Overrides the viewport height during crawling of the mobile site. */
  mobileViewportHeight?: Maybe<Scalars['Int']['output']>;
  /** Overrides the vieport width during crawling of the mobile site. */
  mobileViewportWidth?: Maybe<Scalars['Int']['output']>;
  moduleCode: ModuleCode;
  name: Scalars['String']['output'];
  primaryDomain: Scalars['String']['output'];
  renderTimeout: Scalars['Int']['output'];
  renderWithImages: Scalars['Boolean']['output'];
  rendererBlockAds: Scalars['Boolean']['output'];
  rendererBlockAnalytics: Scalars['Boolean']['output'];
  rendererBlockCustom?: Maybe<Array<Scalars['String']['output']>>;
  rendererCookies?: Maybe<Array<RendererCookie>>;
  rendererJsString?: Maybe<Scalars['String']['output']>;
  rendererJsUrls?: Maybe<Array<Scalars['String']['output']>>;
  renderingRobotsCheckMode?: Maybe<RenderingRobotsCheckMode>;
  robotsOverwrite?: Maybe<Scalars['String']['output']>;
  schedulesTotalCount: Scalars['Int']['output'];
  secondaryDomains?: Maybe<Array<Scalars['String']['output']>>;
  segmentsTotalCount: Scalars['Int']['output'];
  selectedWcagLevel: WcagLevel;
  selectedWcagVersion: Scalars['Float']['output'];
  /** @deprecated The flag is no longer being used. */
  serpentCrawlerEnabled?: Maybe<Scalars['Boolean']['output']>;
  serpentPlatformId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Renamed to primaryDomain. */
  sitePrimary: Scalars['String']['output'];
  /** @deprecated Renamed to testSiteDomain. */
  siteTest?: Maybe<Scalars['String']['output']>;
  sitemapUrls?: Maybe<Array<Scalars['String']['output']>>;
  startUrls?: Maybe<Array<Scalars['String']['output']>>;
  storeHtml: Scalars['Boolean']['output'];
  targetMaxUncrawledUrlsCount: Scalars['Int']['output'];
  testSiteDomain?: Maybe<Scalars['String']['output']>;
  testSiteUsername?: Maybe<Scalars['String']['output']>;
  testsTotalCount: Scalars['Int']['output'];
  thinPageThreshold?: Maybe<Scalars['Int']['output']>;
  totalSteps?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  urlRewriteQueryParameters?: Maybe<Array<Scalars['String']['output']>>;
  urlRewriteRules?: Maybe<Array<UrlRewriteRule>>;
  urlRewriteStripFragment: Scalars['Boolean']['output'];
  urlSampling?: Maybe<Array<UrlSampling>>;
  /** @deprecated Renamed to excludeUrlPatterns. */
  urlsExcluded?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Renamed to includeUrlPatterns. */
  urlsIncluded?: Maybe<Array<Scalars['String']['output']>>;
  useMobileSettings: Scalars['Boolean']['output'];
  /** Use new Chrome headless mode. */
  useNewHeadlessMode: Scalars['Boolean']['output'];
  useOptimus: Scalars['Boolean']['output'];
  useRenderer: Scalars['Boolean']['output'];
  useRobotsOverwrite: Scalars['Boolean']['output'];
  useStealthMode: Scalars['Boolean']['output'];
  useStealthModeV2: Scalars['Boolean']['output'];
  useUrlRewriteRules: Scalars['Boolean']['output'];
  userAgentIsMobile?: Maybe<Scalars['Boolean']['output']>;
  userAgentMobileIsMobile?: Maybe<Scalars['Boolean']['output']>;
  userAgentString?: Maybe<Scalars['String']['output']>;
  userAgentStringMobile?: Maybe<Scalars['String']['output']>;
  userAgentToken?: Maybe<Scalars['String']['output']>;
  userAgentTokenMobile?: Maybe<Scalars['String']['output']>;
  /** Overrides the default viewport height during crawling, if present. */
  viewportHeight?: Maybe<Scalars['Int']['output']>;
  /** Overrides the default viewport width during crawling, if present. */
  viewportWidth?: Maybe<Scalars['Int']['output']>;
};

export type Build = {
  buildScheduleRun?: Maybe<BuildScheduleRun>;
  canceledByBuild?: Maybe<Build>;
  ciBuildId?: Maybe<Scalars['String']['output']>;
  crawl?: Maybe<Crawl>;
  crawlDcWebUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  failedTestCount?: Maybe<Scalars['Int']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ObjectID']['output'];
  missingTestCount: Scalars['Int']['output'];
  passed: Scalars['Boolean']['output'];
  passedTestCount?: Maybe<Scalars['Int']['output']>;
  rawID: Scalars['String']['output'];
  status: BuildStatus;
  testResults: TestResultConnection;
  testSuite: TestSuite;
  triggerType: BuildTriggerType;
  updatedAt: Scalars['DateTime']['output'];
  warnedTestCount?: Maybe<Scalars['Int']['output']>;
};


export type BuildTestResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestResultConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestResultOrder>>;
};

export type BuildConnection = {
  /** A list of edges. */
  edges: Array<BuildEdge>;
  /** A list of nodes. */
  nodes: Array<Build>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Builds in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type BuildConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<BuildConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<BuildConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<BuildConnectionFilterInput>>;
  ciBuildId?: InputMaybe<ConnectionStringFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  failedTestCount?: InputMaybe<ConnectionIntFilterInput>;
  finishedAt?: InputMaybe<ConnectionDateFilterInput>;
  missingTestCount?: InputMaybe<ConnectionIntFilterInput>;
  passed?: InputMaybe<ConnectionBooleanFilterInput>;
  passedTestCount?: InputMaybe<ConnectionIntFilterInput>;
  status?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  warnedTestCount?: InputMaybe<ConnectionIntFilterInput>;
};

export type BuildEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Build;
};

export type BuildOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Builds by. */
  field: BuildOrderField;
};

export enum BuildOrderField {
  CiBuildId = 'ciBuildId',
  CreatedAt = 'createdAt',
  FailedTestCount = 'failedTestCount',
  FinishedAt = 'finishedAt',
  Id = 'id',
  MissingTestCount = 'missingTestCount',
  Passed = 'passed',
  PassedTestCount = 'passedTestCount',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  WarnedTestCount = 'warnedTestCount'
}

export type BuildSchedule = {
  account: Account;
  buildScheduleTestSuites: BuildScheduleTestSuiteConnection;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  lastRunStartedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  nextRunAt?: Maybe<Scalars['DateTime']['output']>;
  rawID: Scalars['String']['output'];
  repetitionRate?: Maybe<BuildScheduleRepetitionRate>;
  startAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type BuildScheduleBuildScheduleTestSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BuildScheduleTestSuiteOrder>>;
};

export type BuildScheduleConnection = {
  /** A list of edges. */
  edges: Array<BuildScheduleEdge>;
  /** A list of nodes. */
  nodes: Array<BuildSchedule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of BuildSchedules in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type BuildScheduleConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<BuildScheduleConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<BuildScheduleConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<BuildScheduleConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  lastRunStartedAt?: InputMaybe<ConnectionDateFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  nextRunAt?: InputMaybe<ConnectionDateFilterInput>;
  startAt?: InputMaybe<ConnectionDateFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type BuildScheduleEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BuildSchedule;
};

export type BuildScheduleOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order BuildSchedules by. */
  field: BuildScheduleOrderField;
};

export enum BuildScheduleOrderField {
  CreatedAt = 'createdAt',
  Id = 'id',
  LastRunStartedAt = 'lastRunStartedAt',
  Name = 'name',
  NextRunAt = 'nextRunAt',
  StartAt = 'startAt',
  UpdatedAt = 'updatedAt'
}

export enum BuildScheduleRepetitionRate {
  Daily = 'Daily',
  EveryFourHours = 'EveryFourHours',
  EveryTwelveHours = 'EveryTwelveHours',
  EveryWeekday = 'EveryWeekday',
  Hourly = 'Hourly',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type BuildScheduleRun = {
  buildSchedule: BuildSchedule;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  rawID: Scalars['String']['output'];
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BuildScheduleRunConnection = {
  /** A list of edges. */
  edges: Array<BuildScheduleRunEdge>;
  /** A list of nodes. */
  nodes: Array<BuildScheduleRun>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of BuildScheduleRuns in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type BuildScheduleRunConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<BuildScheduleRunConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<BuildScheduleRunConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<BuildScheduleRunConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  startedAt?: InputMaybe<ConnectionDateFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type BuildScheduleRunEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BuildScheduleRun;
};

export type BuildScheduleRunOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order BuildScheduleRuns by. */
  field: BuildScheduleRunOrderField;
};

export enum BuildScheduleRunOrderField {
  CreatedAt = 'createdAt',
  Id = 'id',
  StartedAt = 'startedAt',
  UpdatedAt = 'updatedAt'
}

export type BuildScheduleTestSuite = {
  buildSchedule: BuildSchedule;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  lastRunBuild?: Maybe<Build>;
  rawID: Scalars['String']['output'];
  testSuite: TestSuite;
};

export type BuildScheduleTestSuiteConnection = {
  /** A list of edges. */
  edges: Array<BuildScheduleTestSuiteEdge>;
  /** A list of nodes. */
  nodes: Array<BuildScheduleTestSuite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of BuildScheduleTestSuites in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type BuildScheduleTestSuiteEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BuildScheduleTestSuite;
};

export type BuildScheduleTestSuiteOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order BuildScheduleTestSuites by. */
  field: BuildScheduleTestSuiteOrderField;
};

export enum BuildScheduleTestSuiteOrderField {
  CreatedAt = 'createdAt',
  Id = 'id'
}

export enum BuildStatus {
  Aborted = 'Aborted',
  Cancelled = 'Cancelled',
  Finished = 'Finished',
  Queued = 'Queued',
  Running = 'Running'
}

export enum BuildTriggerType {
  Ci = 'CI',
  Manual = 'Manual',
  Schedule = 'Schedule'
}

export type BulkProcessSuggestedThresholdsPayload = {
  updatedThresholdsCount: Scalars['Int']['output'];
};

export type CancelBuildInput = {
  buildId: Scalars['ObjectID']['input'];
};

export type CancelBuildPayload = {
  build: Build;
};

export type CancelCrawlingInput = {
  crawlId: Scalars['ObjectID']['input'];
};

export type CancelCrawlingPayload = {
  crawl: Crawl;
};

export enum ChargebeeSiteRef {
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Usd = 'USD',
  Usd2 = 'USD2'
}

export type CheckDomainInput = {
  url: Scalars['String']['input'];
};

export type CheckDomainPayload = {
  variants: Array<DomainCheckVariant>;
};

export type CloneProjectPayload = {
  project: Project;
};

export type CloneTestSuiteInput = {
  testSuiteId: Scalars['ObjectID']['input'];
};

export type CloneTestSuitePayload = {
  testSuite: TestSuite;
};

export enum CompareToCrawlType {
  LastCrawl = 'LastCrawl',
  None = 'None'
}

export type ConfirmEmailInput = {
  token: Scalars['String']['input'];
};

export type ConfirmEmailPayload = {
  session: Session;
};

export type ConnectionBigIntFilterInput = {
  eq?: InputMaybe<Scalars['BigInt']['input']>;
  ge?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  le?: InputMaybe<Scalars['BigInt']['input']>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  ne?: InputMaybe<Scalars['BigInt']['input']>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type ConnectionBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConnectionCrawlFsmStateFilterInput = {
  eq?: InputMaybe<CrawlFsmState>;
  in?: InputMaybe<Array<CrawlFsmState>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CrawlFsmState>;
  notIn?: InputMaybe<Array<CrawlFsmState>>;
};

export type ConnectionCrawlStatusFilterInput = {
  eq?: InputMaybe<CrawlStatus>;
  in?: InputMaybe<Array<CrawlStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CrawlStatus>;
  notIn?: InputMaybe<Array<CrawlStatus>>;
};

export type ConnectionCrawlTypeFilterInput = {
  eq?: InputMaybe<CrawlType>;
  in?: InputMaybe<Array<CrawlType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CrawlType>;
  notIn?: InputMaybe<Array<CrawlType>>;
};

export type ConnectionCreditAllocationTypeFilterInput = {
  eq?: InputMaybe<CreditAllocationType>;
  in?: InputMaybe<Array<CreditAllocationType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CreditAllocationType>;
  notIn?: InputMaybe<Array<CreditAllocationType>>;
};

export type ConnectionCustomChartTypeFilterInput = {
  eq?: InputMaybe<CustomChartType>;
  in?: InputMaybe<Array<CustomChartType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CustomChartType>;
  notIn?: InputMaybe<Array<CustomChartType>>;
};

export type ConnectionCustomDashboardTypeFilterInput = {
  eq?: InputMaybe<CustomDashboardType>;
  in?: InputMaybe<Array<CustomDashboardType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CustomDashboardType>;
  notIn?: InputMaybe<Array<CustomDashboardType>>;
};

export type ConnectionCustomMetricContainerInputTypeFilterInput = {
  eq?: InputMaybe<CustomMetricContainerInputType>;
  in?: InputMaybe<Array<CustomMetricContainerInputType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CustomMetricContainerInputType>;
  notIn?: InputMaybe<Array<CustomMetricContainerInputType>>;
};

export type ConnectionCustomMetricContainerScopeFilterInput = {
  eq?: InputMaybe<CustomMetricContainerScope>;
  in?: InputMaybe<Array<CustomMetricContainerScope>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CustomMetricContainerScope>;
  notIn?: InputMaybe<Array<CustomMetricContainerScope>>;
};

export type ConnectionCustomMetricContainerVersionStatusFilterInput = {
  eq?: InputMaybe<CustomMetricContainerVersionStatus>;
  in?: InputMaybe<Array<CustomMetricContainerVersionStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CustomMetricContainerVersionStatus>;
  notIn?: InputMaybe<Array<CustomMetricContainerVersionStatus>>;
};

export type ConnectionCustomTableTypeFilterInput = {
  eq?: InputMaybe<CustomTableType>;
  in?: InputMaybe<Array<CustomTableType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<CustomTableType>;
  notIn?: InputMaybe<Array<CustomTableType>>;
};

export type ConnectionDatasourceCodeFilterInput = {
  eq?: InputMaybe<DatasourceCode>;
  in?: InputMaybe<Array<DatasourceCode>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<DatasourceCode>;
  notIn?: InputMaybe<Array<DatasourceCode>>;
};

export type ConnectionDateFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  ge?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  le?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type ConnectionFloatFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  ge?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  le?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type ConnectionIntArrayFilterInput = {
  arrayContains?: InputMaybe<Scalars['Int']['input']>;
  arrayNotContains?: InputMaybe<Scalars['Int']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConnectionIntFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ConnectionIntObjectArrayMemberFilterInput = {
  arrayContains?: InputMaybe<Scalars['Int']['input']>;
  arrayNotContains?: InputMaybe<Scalars['Int']['input']>;
};

export type ConnectionLegacyTaskPriorityFilterInput = {
  eq?: InputMaybe<LegacyTaskPriority>;
  in?: InputMaybe<Array<LegacyTaskPriority>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<LegacyTaskPriority>;
  notIn?: InputMaybe<Array<LegacyTaskPriority>>;
};

export type ConnectionLegacyTaskStatusFilterInput = {
  eq?: InputMaybe<LegacyTaskStatus>;
  in?: InputMaybe<Array<LegacyTaskStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<LegacyTaskStatus>;
  notIn?: InputMaybe<Array<LegacyTaskStatus>>;
};

export type ConnectionLocationCodeFilterInput = {
  eq?: InputMaybe<LocationCode>;
  in?: InputMaybe<Array<LocationCode>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<LocationCode>;
  notIn?: InputMaybe<Array<LocationCode>>;
};

export type ConnectionMetricTypeFilterInput = {
  eq?: InputMaybe<MetricType>;
  in?: InputMaybe<Array<MetricType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<MetricType>;
  notIn?: InputMaybe<Array<MetricType>>;
};

export type ConnectionModuleCodeFilterInput = {
  eq?: InputMaybe<ModuleCode>;
  in?: InputMaybe<Array<ModuleCode>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<ModuleCode>;
  notIn?: InputMaybe<Array<ModuleCode>>;
};

export type ConnectionObjectIdFilterInput = {
  eq?: InputMaybe<Scalars['ObjectID']['input']>;
  in?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['ObjectID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
};

export enum ConnectionPredicate {
  ArrayContains = 'arrayContains',
  ArrayContainsLike = 'arrayContainsLike',
  ArrayNotContains = 'arrayNotContains',
  ArrayNotContainsLike = 'arrayNotContainsLike',
  BeginsWith = 'beginsWith',
  Contains = 'contains',
  EndsWith = 'endsWith',
  Eq = 'eq',
  Ge = 'ge',
  Gt = 'gt',
  In = 'in',
  IsEmpty = 'isEmpty',
  IsNull = 'isNull',
  Le = 'le',
  Lt = 'lt',
  MatchesRegex = 'matchesRegex',
  Ne = 'ne',
  NotContains = 'notContains',
  NotIn = 'notIn',
  NotMatchesRegex = 'notMatchesRegex'
}

export type ConnectionPredicateMetadata = {
  /** Predicate code */
  code: ConnectionPredicate;
  /** Whether the predicate expects an array value */
  isArray: Scalars['Boolean']['output'];
  /** Human readable predicate name */
  name: Scalars['String']['output'];
  /** What type of value does the predicate expect */
  type: MetricType;
};

export type ConnectionRedirectExclusionReasonFilterInput = {
  eq?: InputMaybe<RedirectExclusionReason>;
  in?: InputMaybe<Array<RedirectExclusionReason>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<RedirectExclusionReason>;
  notIn?: InputMaybe<Array<RedirectExclusionReason>>;
};

export type ConnectionRenderingRobotsCheckModeFilterInput = {
  eq?: InputMaybe<RenderingRobotsCheckMode>;
  in?: InputMaybe<Array<RenderingRobotsCheckMode>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<RenderingRobotsCheckMode>;
  notIn?: InputMaybe<Array<RenderingRobotsCheckMode>>;
};

export type ConnectionReportTypeCodeFilterInput = {
  eq?: InputMaybe<ReportTypeCode>;
  in?: InputMaybe<Array<ReportTypeCode>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<ReportTypeCode>;
  notIn?: InputMaybe<Array<ReportTypeCode>>;
};

export type ConnectionResolvedHttpStatusCodeFilterInput = {
  eq?: InputMaybe<ResolvedHttpStatusCode>;
  in?: InputMaybe<Array<ResolvedHttpStatusCode>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<ResolvedHttpStatusCode>;
  notIn?: InputMaybe<Array<ResolvedHttpStatusCode>>;
};

export type ConnectionRoleCodeFilterInput = {
  eq?: InputMaybe<RoleCode>;
  in?: InputMaybe<Array<RoleCode>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<RoleCode>;
  notIn?: InputMaybe<Array<RoleCode>>;
};

export type ConnectionSerpentCrawlerQueryGraphTypeFilterInput = {
  eq?: InputMaybe<SerpentCrawlerQueryGraphType>;
  in?: InputMaybe<Array<SerpentCrawlerQueryGraphType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<SerpentCrawlerQueryGraphType>;
  notIn?: InputMaybe<Array<SerpentCrawlerQueryGraphType>>;
};

export type ConnectionSinglePageRequesterRequestStatusFilterInput = {
  eq?: InputMaybe<SinglePageRequesterRequestStatus>;
  in?: InputMaybe<Array<SinglePageRequesterRequestStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<SinglePageRequesterRequestStatus>;
  notIn?: InputMaybe<Array<SinglePageRequesterRequestStatus>>;
};

export type ConnectionStringArrayFilterInput = {
  arrayContains?: InputMaybe<Scalars['String']['input']>;
  arrayContainsLike?: InputMaybe<Scalars['String']['input']>;
  arrayNotContains?: InputMaybe<Scalars['String']['input']>;
  arrayNotContainsLike?: InputMaybe<Scalars['String']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConnectionStringFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  matchesRegex?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notMatchesRegex?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectionStringObjectArrayMemberFilterInput = {
  arrayContains?: InputMaybe<Scalars['String']['input']>;
  arrayContainsLike?: InputMaybe<Scalars['String']['input']>;
  arrayNotContains?: InputMaybe<Scalars['String']['input']>;
  arrayNotContainsLike?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectionTestAutoThresholdAcceptanceFilterInput = {
  eq?: InputMaybe<TestAutoThresholdAcceptance>;
  in?: InputMaybe<Array<TestAutoThresholdAcceptance>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<TestAutoThresholdAcceptance>;
  notIn?: InputMaybe<Array<TestAutoThresholdAcceptance>>;
};

export type ConnectionTestResultStatusFilterInput = {
  eq?: InputMaybe<TestResultStatus>;
  in?: InputMaybe<Array<TestResultStatus>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<TestResultStatus>;
  notIn?: InputMaybe<Array<TestResultStatus>>;
};

export type ConnectionThresholdChangeTypeFilterInput = {
  eq?: InputMaybe<ThresholdChangeType>;
  in?: InputMaybe<Array<ThresholdChangeType>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<ThresholdChangeType>;
  notIn?: InputMaybe<Array<ThresholdChangeType>>;
};

export type ConnectionWcagLevelFilterInput = {
  eq?: InputMaybe<WcagLevel>;
  in?: InputMaybe<Array<WcagLevel>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<WcagLevel>;
  notIn?: InputMaybe<Array<WcagLevel>>;
};

export type ContainerExecutionFailure = {
  containerId: Scalars['String']['output'];
  containerName?: Maybe<Scalars['String']['output']>;
  error: Scalars['String']['output'];
};

export type ConvertAccountSubscriptionToChargebeeInput = {
  accountId: Scalars['ObjectID']['input'];
  planCode: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ConvertAccountSubscriptionToChargebeePayload = {
  hostedPageUrl: Scalars['String']['output'];
};

export type CopyCustomDashboardCollectionInput = {
  /** @deprecated No longer used. */
  accountId?: InputMaybe<Scalars['ObjectID']['input']>;
  customDashboardCollectionId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export type CopyCustomDashboardInput = {
  customDashboardCollectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  customDashboardId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export type CopyTestsInput = {
  fromProjectId: Scalars['ObjectID']['input'];
  reportTemplateCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  toProjectId: Scalars['ObjectID']['input'];
};

export type CopyTestsPayload = {
  tests: Array<Test>;
};

export type Crawl = {
  advancedCrawlStats?: Maybe<AdvancedCrawlStats>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  archivingAt?: Maybe<Scalars['DateTime']['output']>;
  archivingEnabled: Scalars['Boolean']['output'];
  brokenAt?: Maybe<Scalars['DateTime']['output']>;
  bucketSlot?: Maybe<Scalars['Int']['output']>;
  build?: Maybe<Build>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  cancelingAt?: Maybe<Scalars['DateTime']['output']>;
  comparedTo?: Maybe<Crawl>;
  comparedToCrawlId?: Maybe<Scalars['ObjectID']['output']>;
  coreUIUrl: Scalars['String']['output'];
  crawlDatasource?: Maybe<CrawlDatasource>;
  crawlDatasources: CrawlDatasourceConnection;
  crawlLevels?: Maybe<Scalars['String']['output']>;
  crawlReportTemplates: CrawlReportTemplateConnection;
  crawlSchemas: Array<CrawlSchema>;
  crawlSegments?: Maybe<CrawlSegmentConnection>;
  /** Returns CrawlSegment health scores for a list of report category codes. */
  crawlSegmentsHealthScores: CrawlSegmentHealthScoreConnection;
  /** CrawlSetting used for this Crawl, or the latest CrawlSetting if the Crawl is still running. */
  crawlSetting?: Maybe<CrawlSetting>;
  crawlStat?: Maybe<CrawlStat>;
  /** Returns all_pages basic Report rows counts for all provided crawlTypes and their intersections. */
  crawlTypeCounts?: Maybe<Array<CrawlTypeCount>>;
  crawlTypes: Array<CrawlType>;
  crawlUrlsTotal?: Maybe<Scalars['Int']['output']>;
  crawlability: Scalars['Float']['output'];
  crawlabilityTrend?: Maybe<Scalars['String']['output']>;
  crawledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Average crawl rate from last 5 seconds sample */
  crawledPerSecond?: Maybe<Scalars['Float']['output']>;
  crawledPhase1At?: Maybe<Scalars['DateTime']['output']>;
  crawledPhase2At?: Maybe<Scalars['DateTime']['output']>;
  crawlerType: CrawlerType;
  crawlingAt?: Maybe<Scalars['DateTime']['output']>;
  crawlingPhase1At?: Maybe<Scalars['DateTime']['output']>;
  crawlingPhase2At?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Basic information about the User who started the Crawl. */
  createdByUser?: Maybe<UserInfo>;
  customMetricContainerVersionCrawls?: Maybe<CustomMetricContainerVersionCrawlConnection>;
  customMetricUploadContainerCrawls?: Maybe<CustomMetricUploadContainerCrawlConnection>;
  customMetrics?: Maybe<Array<CustomMetric>>;
  customReportTemplateVersionCrawls?: Maybe<CustomReportTemplateVersionCrawlConnection>;
  customReports: Array<CustomReport>;
  discoveredUrlsAt?: Maybe<Scalars['DateTime']['output']>;
  discoveringUrlsAt?: Maybe<Scalars['DateTime']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  erroredAt?: Maybe<Scalars['DateTime']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  fsmExtTransitionedAt?: Maybe<Scalars['DateTime']['output']>;
  fsmState: CrawlFsmState;
  fsmTransitionedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Returns the health score trend for a report category code. */
  healthScore: Array<CrawlHealthScoreItem>;
  /** Returns the Crawl's health scores for a list of report category codes. */
  healthScores: Array<CrawlHealthScoreItem>;
  id: Scalars['ObjectID']['output'];
  incomplete?: Maybe<Scalars['Boolean']['output']>;
  internalCrawlRates: Array<CrawlRate>;
  isContainer: Scalars['Boolean']['output'];
  isScheduled?: Maybe<Scalars['Boolean']['output']>;
  languageSettings?: Maybe<Scalars['Int']['output']>;
  languageSettingsAdded?: Maybe<Scalars['Int']['output']>;
  languageSettingsRemoved?: Maybe<Scalars['Int']['output']>;
  limitLevelsMax: Scalars['Float']['output'];
  limitPagesMax: Scalars['Float']['output'];
  maxProcessLinks: Scalars['Float']['output'];
  metricsServerId: MetricsServerId;
  migratedAt?: Maybe<Scalars['DateTime']['output']>;
  migratingAt?: Maybe<Scalars['DateTime']['output']>;
  optimusTransformedAt?: Maybe<Scalars['DateTime']['output']>;
  optimusTransformingAt?: Maybe<Scalars['DateTime']['output']>;
  pageGroupings?: Maybe<Scalars['String']['output']>;
  parentCrawl?: Maybe<Crawl>;
  parquetFiles?: Maybe<Array<CrawlParquetFile>>;
  pauseReason?: Maybe<PauseReason>;
  pausedReminderSent: Scalars['Boolean']['output'];
  platformId?: Maybe<Scalars['String']['output']>;
  populatedAt?: Maybe<Scalars['DateTime']['output']>;
  populatingAt?: Maybe<Scalars['DateTime']['output']>;
  postPopulatedAt?: Maybe<Scalars['DateTime']['output']>;
  postPopulatingAt?: Maybe<Scalars['DateTime']['output']>;
  postTransformedAt?: Maybe<Scalars['DateTime']['output']>;
  postTransformingAt?: Maybe<Scalars['DateTime']['output']>;
  processInstanceType?: Maybe<Scalars['String']['output']>;
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  processingAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Use crawlStat instead. */
  progress: Array<LevelProgress>;
  project: Project;
  projectId: Scalars['ObjectID']['output'];
  queuedAt?: Maybe<Scalars['DateTime']['output']>;
  queueingAt?: Maybe<Scalars['DateTime']['output']>;
  rawID: Scalars['String']['output'];
  readyAt?: Maybe<Scalars['DateTime']['output']>;
  refineryEnabled: Scalars['Boolean']['output'];
  refineryProcessedAt?: Maybe<Scalars['DateTime']['output']>;
  refineryProcessingAt?: Maybe<Scalars['DateTime']['output']>;
  refineryProcessingEnabled: Scalars['Boolean']['output'];
  reportDownloads?: Maybe<ReportDownloadConnection>;
  reportStats: Array<ReportStat>;
  /** @deprecated Use Crawl.reportStats instead. */
  reports: ReportConnection;
  reportsByCode: Array<Report>;
  robotsOverwrite?: Maybe<Scalars['String']['output']>;
  scheduleRunningEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
  scheduled: Scalars['Boolean']['output'];
  /** @deprecated The flag is no longer being used. */
  serpentCrawlerEnabled: Scalars['Boolean']['output'];
  serpentCrawlerQueryGraphType: SerpentCrawlerQueryGraphType;
  siteExplorer: Scalars['Boolean']['output'];
  sitemapCrawlerPlatformId?: Maybe<Scalars['String']['output']>;
  sitemapsCrawl?: Maybe<Crawl>;
  startedByApi: Scalars['Boolean']['output'];
  /** @deprecated Use statusEnum instead. */
  status: Scalars['String']['output'];
  statusEnum: CrawlStatus;
  totalStepLinks?: Maybe<Scalars['BigInt']['output']>;
  totalSteps?: Maybe<Scalars['Int']['output']>;
  transformedAt?: Maybe<Scalars['DateTime']['output']>;
  transformingAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  useEsReportStats?: Maybe<Scalars['Boolean']['output']>;
  useRewriteRules: Scalars['Boolean']['output'];
  useRobotsOverwrite: Scalars['Boolean']['output'];
  version?: Maybe<Scalars['Float']['output']>;
};


export type CrawlCrawlDatasourceArgs = {
  datasourceCode: DatasourceCode;
};


export type CrawlCrawlDatasourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlDatasourceConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlDatasourceOrder>>;
};


export type CrawlCrawlReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlReportTemplateConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlReportTemplateOrder>>;
};


export type CrawlCrawlSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSegmentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSegmentOrder>>;
};


export type CrawlCrawlSegmentsHealthScoresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSegmentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSegmentOrder>>;
  reportCategoryCodes: Array<Scalars['String']['input']>;
};


export type CrawlCrawlTypeCountsArgs = {
  input: CrawlTypeCountsInput;
};


export type CrawlCustomMetricContainerVersionCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerVersionCrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CrawlCustomMetricUploadContainerCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricUploadContainerCrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CrawlCustomReportTemplateVersionCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomReportTemplateVersionCrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CrawlCustomReportsArgs = {
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type CrawlHealthScoreArgs = {
  reportCategoryCode: Scalars['String']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type CrawlHealthScoresArgs = {
  reportCategoryCodes: Array<Scalars['String']['input']>;
};


export type CrawlParquetFilesArgs = {
  datasourceName?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type CrawlReportDownloadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportDownloadConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportDownloadOrder>>;
};


export type CrawlReportStatsArgs = {
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type CrawlReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeUnscopedReports?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportOrder>>;
  reportTemplateFilter?: InputMaybe<ReportTemplateConnectionFilterInput>;
};


export type CrawlReportsByCodeArgs = {
  input: CrawlReportsByCodeInput;
};

export type CrawlAccessibilityIssue = {
  axeResultsGroup?: Maybe<Scalars['String']['output']>;
  base64Image?: Maybe<Scalars['String']['output']>;
  bgAverage?: Maybe<Scalars['String']['output']>;
  bgMaxColor?: Maybe<Scalars['String']['output']>;
  bgMinColor?: Maybe<Scalars['String']['output']>;
  bgStdv?: Maybe<Scalars['String']['output']>;
  boundingBox?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  computedDescription?: Maybe<Scalars['String']['output']>;
  computedName?: Maybe<Scalars['String']['output']>;
  contrastRatio?: Maybe<Scalars['Float']['output']>;
  contrastRatioAverage?: Maybe<Scalars['Float']['output']>;
  elementSelectorCount?: Maybe<Scalars['Int']['output']>;
  elementSource?: Maybe<Scalars['String']['output']>;
  exampleElementSelector?: Maybe<Scalars['String']['output']>;
  exampleUrl?: Maybe<Scalars['String']['output']>;
  exampleUrlDigest?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['String']['output']>;
  gaPageviews?: Maybe<Scalars['Int']['output']>;
  gaVisits?: Maybe<Scalars['Int']['output']>;
  gscClicks?: Maybe<Scalars['Int']['output']>;
  isFontLarge?: Maybe<Scalars['Boolean']['output']>;
  isImageInAnchor?: Maybe<Scalars['Boolean']['output']>;
  issueDescription?: Maybe<Scalars['String']['output']>;
  issueDigest: Scalars['String']['output'];
  issueSeverity?: Maybe<Scalars['String']['output']>;
  issueType?: Maybe<Scalars['String']['output']>;
  legal?: Maybe<Array<Scalars['String']['output']>>;
  legalCount?: Maybe<Scalars['Int']['output']>;
  manualReviewNeededCount?: Maybe<Scalars['Int']['output']>;
  needsManualReview?: Maybe<Scalars['String']['output']>;
  noManualReviewNeededCount?: Maybe<Scalars['Int']['output']>;
  passProbability?: Maybe<Scalars['String']['output']>;
  ruleId?: Maybe<Scalars['String']['output']>;
  safeContrastZone?: Maybe<Scalars['Float']['output']>;
  segmentIds?: Maybe<Array<Scalars['Int']['output']>>;
  segmentIdsCount?: Maybe<Scalars['Int']['output']>;
  segmentNames?: Maybe<Array<Scalars['String']['output']>>;
  segmentNamesCount?: Maybe<Scalars['Int']['output']>;
  stDevWeightedRgb?: Maybe<Scalars['Float']['output']>;
  surroundingTextColor?: Maybe<Scalars['String']['output']>;
  urlCount?: Maybe<Scalars['Int']['output']>;
  wcagLevel?: Maybe<Scalars['String']['output']>;
  wcagSuccessCriteria?: Maybe<Scalars['String']['output']>;
  wcagVersion?: Maybe<Scalars['Float']['output']>;
};

export type CrawlAccessibilityIssueConnection = {
  /** A list of edges. */
  edges: Array<CrawlAccessibilityIssueEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlAccessibilityIssue>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlAccessibilityIssues in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlAccessibilityIssueConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlAccessibilityIssueConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlAccessibilityIssueConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlAccessibilityIssueConnectionFilterInput>>;
  axeResultsGroup?: InputMaybe<ConnectionStringFilterInput>;
  bgAverage?: InputMaybe<ConnectionStringFilterInput>;
  bgMaxColor?: InputMaybe<ConnectionStringFilterInput>;
  bgMinColor?: InputMaybe<ConnectionStringFilterInput>;
  bgStdv?: InputMaybe<ConnectionStringFilterInput>;
  category?: InputMaybe<ConnectionStringFilterInput>;
  computedDescription?: InputMaybe<ConnectionStringFilterInput>;
  computedName?: InputMaybe<ConnectionStringFilterInput>;
  contrastRatio?: InputMaybe<ConnectionFloatFilterInput>;
  contrastRatioAverage?: InputMaybe<ConnectionFloatFilterInput>;
  elementSelectorCount?: InputMaybe<ConnectionIntFilterInput>;
  elementSource?: InputMaybe<ConnectionStringFilterInput>;
  exampleElementSelector?: InputMaybe<ConnectionStringFilterInput>;
  exampleUrl?: InputMaybe<ConnectionStringFilterInput>;
  exampleUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  fontColor?: InputMaybe<ConnectionStringFilterInput>;
  fontSize?: InputMaybe<ConnectionFloatFilterInput>;
  fontWeight?: InputMaybe<ConnectionStringFilterInput>;
  gaPageviews?: InputMaybe<ConnectionIntFilterInput>;
  gaVisits?: InputMaybe<ConnectionIntFilterInput>;
  gscClicks?: InputMaybe<ConnectionIntFilterInput>;
  isFontLarge?: InputMaybe<ConnectionBooleanFilterInput>;
  isImageInAnchor?: InputMaybe<ConnectionBooleanFilterInput>;
  issueDescription?: InputMaybe<ConnectionStringFilterInput>;
  issueDigest?: InputMaybe<ConnectionStringFilterInput>;
  issueSeverity?: InputMaybe<ConnectionStringFilterInput>;
  issueType?: InputMaybe<ConnectionStringFilterInput>;
  legal?: InputMaybe<ConnectionStringArrayFilterInput>;
  legalCount?: InputMaybe<ConnectionIntFilterInput>;
  manualReviewNeededCount?: InputMaybe<ConnectionIntFilterInput>;
  needsManualReview?: InputMaybe<ConnectionStringFilterInput>;
  noManualReviewNeededCount?: InputMaybe<ConnectionIntFilterInput>;
  passProbability?: InputMaybe<ConnectionStringFilterInput>;
  ruleId?: InputMaybe<ConnectionStringFilterInput>;
  safeContrastZone?: InputMaybe<ConnectionFloatFilterInput>;
  segmentIds?: InputMaybe<ConnectionIntArrayFilterInput>;
  segmentIdsCount?: InputMaybe<ConnectionIntFilterInput>;
  segmentNames?: InputMaybe<ConnectionStringArrayFilterInput>;
  segmentNamesCount?: InputMaybe<ConnectionIntFilterInput>;
  stDevWeightedRgb?: InputMaybe<ConnectionFloatFilterInput>;
  surroundingTextColor?: InputMaybe<ConnectionStringFilterInput>;
  urlCount?: InputMaybe<ConnectionIntFilterInput>;
  wcagLevel?: InputMaybe<ConnectionStringFilterInput>;
  wcagSuccessCriteria?: InputMaybe<ConnectionStringFilterInput>;
  wcagVersion?: InputMaybe<ConnectionFloatFilterInput>;
};

export type CrawlAccessibilityIssueEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlAccessibilityIssue;
};

export type CrawlAccessibilityIssueInstance = {
  axeResultsGroup?: Maybe<Scalars['String']['output']>;
  bgAverage?: Maybe<Scalars['String']['output']>;
  bgMaxColor?: Maybe<Scalars['String']['output']>;
  bgMinColor?: Maybe<Scalars['String']['output']>;
  bgStdv?: Maybe<Scalars['String']['output']>;
  boundingBox?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  computedDescription?: Maybe<Scalars['String']['output']>;
  computedName?: Maybe<Scalars['String']['output']>;
  contrastRatio?: Maybe<Scalars['Float']['output']>;
  contrastRatioAverage?: Maybe<Scalars['Float']['output']>;
  elementSelector?: Maybe<Scalars['String']['output']>;
  elementSelectorDigest?: Maybe<Scalars['String']['output']>;
  elementSource?: Maybe<Scalars['String']['output']>;
  fontColor?: Maybe<Scalars['String']['output']>;
  fontSize?: Maybe<Scalars['Float']['output']>;
  fontWeight?: Maybe<Scalars['String']['output']>;
  gaPageviews?: Maybe<Scalars['Int']['output']>;
  gaVisits?: Maybe<Scalars['Int']['output']>;
  gscClicks?: Maybe<Scalars['Int']['output']>;
  instanceIndex: Scalars['Int']['output'];
  isFontLarge?: Maybe<Scalars['Boolean']['output']>;
  isImageInAnchor?: Maybe<Scalars['Boolean']['output']>;
  issueDescription?: Maybe<Scalars['String']['output']>;
  issueDigest: Scalars['String']['output'];
  issueSeverity?: Maybe<Scalars['String']['output']>;
  issueType?: Maybe<Scalars['String']['output']>;
  legal?: Maybe<Array<Scalars['String']['output']>>;
  legalCount?: Maybe<Scalars['Int']['output']>;
  needsManualReview?: Maybe<Scalars['Boolean']['output']>;
  passProbability?: Maybe<Scalars['String']['output']>;
  ruleId?: Maybe<Scalars['String']['output']>;
  safeContrastZone?: Maybe<Scalars['Float']['output']>;
  segmentIds?: Maybe<Array<Scalars['Int']['output']>>;
  segmentIdsCount?: Maybe<Scalars['Int']['output']>;
  segmentNames?: Maybe<Array<Scalars['String']['output']>>;
  segmentNamesCount?: Maybe<Scalars['Int']['output']>;
  stDevWeightedRgb?: Maybe<Scalars['Float']['output']>;
  surroundingTextColor?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlDigest: Scalars['String']['output'];
  wcagLevel?: Maybe<Scalars['String']['output']>;
  wcagSuccessCriteria?: Maybe<Scalars['String']['output']>;
  wcagVersion?: Maybe<Scalars['Float']['output']>;
};

export type CrawlAccessibilityIssueInstanceConnection = {
  /** A list of edges. */
  edges: Array<CrawlAccessibilityIssueInstanceEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlAccessibilityIssueInstance>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlAccessibilityIssueInstances in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlAccessibilityIssueInstanceConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlAccessibilityIssueInstanceConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlAccessibilityIssueInstanceConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlAccessibilityIssueInstanceConnectionFilterInput>>;
  axeResultsGroup?: InputMaybe<ConnectionStringFilterInput>;
  bgAverage?: InputMaybe<ConnectionStringFilterInput>;
  bgMaxColor?: InputMaybe<ConnectionStringFilterInput>;
  bgMinColor?: InputMaybe<ConnectionStringFilterInput>;
  bgStdv?: InputMaybe<ConnectionStringFilterInput>;
  category?: InputMaybe<ConnectionStringFilterInput>;
  computedDescription?: InputMaybe<ConnectionStringFilterInput>;
  computedName?: InputMaybe<ConnectionStringFilterInput>;
  contrastRatio?: InputMaybe<ConnectionFloatFilterInput>;
  contrastRatioAverage?: InputMaybe<ConnectionFloatFilterInput>;
  elementSelector?: InputMaybe<ConnectionStringFilterInput>;
  elementSelectorDigest?: InputMaybe<ConnectionStringFilterInput>;
  elementSource?: InputMaybe<ConnectionStringFilterInput>;
  fontColor?: InputMaybe<ConnectionStringFilterInput>;
  fontSize?: InputMaybe<ConnectionFloatFilterInput>;
  fontWeight?: InputMaybe<ConnectionStringFilterInput>;
  gaPageviews?: InputMaybe<ConnectionIntFilterInput>;
  gaVisits?: InputMaybe<ConnectionIntFilterInput>;
  gscClicks?: InputMaybe<ConnectionIntFilterInput>;
  instanceIndex?: InputMaybe<ConnectionIntFilterInput>;
  isFontLarge?: InputMaybe<ConnectionBooleanFilterInput>;
  isImageInAnchor?: InputMaybe<ConnectionBooleanFilterInput>;
  issueDescription?: InputMaybe<ConnectionStringFilterInput>;
  issueDigest?: InputMaybe<ConnectionStringFilterInput>;
  issueSeverity?: InputMaybe<ConnectionStringFilterInput>;
  issueType?: InputMaybe<ConnectionStringFilterInput>;
  legal?: InputMaybe<ConnectionStringArrayFilterInput>;
  legalCount?: InputMaybe<ConnectionIntFilterInput>;
  needsManualReview?: InputMaybe<ConnectionBooleanFilterInput>;
  passProbability?: InputMaybe<ConnectionStringFilterInput>;
  ruleId?: InputMaybe<ConnectionStringFilterInput>;
  safeContrastZone?: InputMaybe<ConnectionFloatFilterInput>;
  segmentIds?: InputMaybe<ConnectionIntArrayFilterInput>;
  segmentIdsCount?: InputMaybe<ConnectionIntFilterInput>;
  segmentNames?: InputMaybe<ConnectionStringArrayFilterInput>;
  segmentNamesCount?: InputMaybe<ConnectionIntFilterInput>;
  stDevWeightedRgb?: InputMaybe<ConnectionFloatFilterInput>;
  surroundingTextColor?: InputMaybe<ConnectionStringFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
  urlDigest?: InputMaybe<ConnectionStringFilterInput>;
  wcagLevel?: InputMaybe<ConnectionStringFilterInput>;
  wcagSuccessCriteria?: InputMaybe<ConnectionStringFilterInput>;
  wcagVersion?: InputMaybe<ConnectionFloatFilterInput>;
};

export type CrawlAccessibilityIssueInstanceEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlAccessibilityIssueInstance;
};

export type CrawlAccessibilityIssueInstanceOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlAccessibilityIssueInstances by. */
  field: CrawlAccessibilityIssueInstanceOrderField;
};

export enum CrawlAccessibilityIssueInstanceOrderField {
  AxeResultsGroup = 'axeResultsGroup',
  BgAverage = 'bgAverage',
  BgMaxColor = 'bgMaxColor',
  BgMinColor = 'bgMinColor',
  BgStdv = 'bgStdv',
  Category = 'category',
  ComputedDescription = 'computedDescription',
  ComputedName = 'computedName',
  ContrastRatio = 'contrastRatio',
  ContrastRatioAverage = 'contrastRatioAverage',
  ElementSelector = 'elementSelector',
  ElementSelectorDigest = 'elementSelectorDigest',
  ElementSource = 'elementSource',
  FontColor = 'fontColor',
  FontSize = 'fontSize',
  FontWeight = 'fontWeight',
  GaPageviews = 'gaPageviews',
  GaVisits = 'gaVisits',
  GscClicks = 'gscClicks',
  InstanceIndex = 'instanceIndex',
  IsFontLarge = 'isFontLarge',
  IsImageInAnchor = 'isImageInAnchor',
  IssueDescription = 'issueDescription',
  IssueDigest = 'issueDigest',
  IssueSeverity = 'issueSeverity',
  IssueType = 'issueType',
  LegalCount = 'legalCount',
  NeedsManualReview = 'needsManualReview',
  PassProbability = 'passProbability',
  RuleId = 'ruleId',
  SafeContrastZone = 'safeContrastZone',
  SegmentIdsCount = 'segmentIdsCount',
  SegmentNamesCount = 'segmentNamesCount',
  StDevWeightedRgb = 'stDevWeightedRgb',
  SurroundingTextColor = 'surroundingTextColor',
  Url = 'url',
  UrlDigest = 'urlDigest',
  WcagLevel = 'wcagLevel',
  WcagSuccessCriteria = 'wcagSuccessCriteria',
  WcagVersion = 'wcagVersion'
}

export type CrawlAccessibilityIssueOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlAccessibilityIssues by. */
  field: CrawlAccessibilityIssueOrderField;
};

export enum CrawlAccessibilityIssueOrderField {
  AxeResultsGroup = 'axeResultsGroup',
  BgAverage = 'bgAverage',
  BgMaxColor = 'bgMaxColor',
  BgMinColor = 'bgMinColor',
  BgStdv = 'bgStdv',
  Category = 'category',
  ComputedDescription = 'computedDescription',
  ComputedName = 'computedName',
  ContrastRatio = 'contrastRatio',
  ContrastRatioAverage = 'contrastRatioAverage',
  ElementSelectorCount = 'elementSelectorCount',
  ElementSource = 'elementSource',
  ExampleElementSelector = 'exampleElementSelector',
  ExampleUrl = 'exampleUrl',
  ExampleUrlDigest = 'exampleUrlDigest',
  FontColor = 'fontColor',
  FontSize = 'fontSize',
  FontWeight = 'fontWeight',
  GaPageviews = 'gaPageviews',
  GaVisits = 'gaVisits',
  GscClicks = 'gscClicks',
  IsFontLarge = 'isFontLarge',
  IsImageInAnchor = 'isImageInAnchor',
  IssueDescription = 'issueDescription',
  IssueDigest = 'issueDigest',
  IssueSeverity = 'issueSeverity',
  IssueType = 'issueType',
  LegalCount = 'legalCount',
  ManualReviewNeededCount = 'manualReviewNeededCount',
  NeedsManualReview = 'needsManualReview',
  NoManualReviewNeededCount = 'noManualReviewNeededCount',
  PassProbability = 'passProbability',
  RuleId = 'ruleId',
  SafeContrastZone = 'safeContrastZone',
  SegmentIdsCount = 'segmentIdsCount',
  SegmentNamesCount = 'segmentNamesCount',
  StDevWeightedRgb = 'stDevWeightedRgb',
  SurroundingTextColor = 'surroundingTextColor',
  UrlCount = 'urlCount',
  WcagLevel = 'wcagLevel',
  WcagSuccessCriteria = 'wcagSuccessCriteria',
  WcagVersion = 'wcagVersion'
}

export type CrawlConnection = {
  /** A list of edges. */
  edges: Array<CrawlEdge>;
  /** A list of nodes. */
  nodes: Array<Crawl>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Crawls in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlConnectionFilterInput>>;
  archivedAt?: InputMaybe<ConnectionDateFilterInput>;
  archivingAt?: InputMaybe<ConnectionDateFilterInput>;
  archivingEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  brokenAt?: InputMaybe<ConnectionDateFilterInput>;
  bucketSlot?: InputMaybe<ConnectionIntFilterInput>;
  canceledAt?: InputMaybe<ConnectionDateFilterInput>;
  cancelingAt?: InputMaybe<ConnectionDateFilterInput>;
  crawlLevels?: InputMaybe<ConnectionStringFilterInput>;
  crawlUrlsTotal?: InputMaybe<ConnectionIntFilterInput>;
  crawlability?: InputMaybe<ConnectionFloatFilterInput>;
  crawlabilityTrend?: InputMaybe<ConnectionStringFilterInput>;
  crawledAt?: InputMaybe<ConnectionDateFilterInput>;
  crawledPhase1At?: InputMaybe<ConnectionDateFilterInput>;
  crawledPhase2At?: InputMaybe<ConnectionDateFilterInput>;
  crawlingAt?: InputMaybe<ConnectionDateFilterInput>;
  crawlingPhase1At?: InputMaybe<ConnectionDateFilterInput>;
  crawlingPhase2At?: InputMaybe<ConnectionDateFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  discoveredUrlsAt?: InputMaybe<ConnectionDateFilterInput>;
  discoveringUrlsAt?: InputMaybe<ConnectionDateFilterInput>;
  errorMessage?: InputMaybe<ConnectionStringFilterInput>;
  erroredAt?: InputMaybe<ConnectionDateFilterInput>;
  finishedAt?: InputMaybe<ConnectionDateFilterInput>;
  fsmExtTransitionedAt?: InputMaybe<ConnectionDateFilterInput>;
  fsmState?: InputMaybe<ConnectionCrawlFsmStateFilterInput>;
  fsmTransitionedAt?: InputMaybe<ConnectionDateFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  incomplete?: InputMaybe<ConnectionBooleanFilterInput>;
  isContainer?: InputMaybe<ConnectionBooleanFilterInput>;
  isScheduled?: InputMaybe<ConnectionBooleanFilterInput>;
  languageSettings?: InputMaybe<ConnectionIntFilterInput>;
  languageSettingsAdded?: InputMaybe<ConnectionIntFilterInput>;
  languageSettingsRemoved?: InputMaybe<ConnectionIntFilterInput>;
  limitLevelsMax?: InputMaybe<ConnectionFloatFilterInput>;
  limitPagesMax?: InputMaybe<ConnectionFloatFilterInput>;
  maxProcessLinks?: InputMaybe<ConnectionFloatFilterInput>;
  migratedAt?: InputMaybe<ConnectionDateFilterInput>;
  migratingAt?: InputMaybe<ConnectionDateFilterInput>;
  optimusTransformedAt?: InputMaybe<ConnectionDateFilterInput>;
  optimusTransformingAt?: InputMaybe<ConnectionDateFilterInput>;
  pageGroupings?: InputMaybe<ConnectionStringFilterInput>;
  pausedReminderSent?: InputMaybe<ConnectionBooleanFilterInput>;
  platformId?: InputMaybe<ConnectionStringFilterInput>;
  populatedAt?: InputMaybe<ConnectionDateFilterInput>;
  populatingAt?: InputMaybe<ConnectionDateFilterInput>;
  postPopulatedAt?: InputMaybe<ConnectionDateFilterInput>;
  postPopulatingAt?: InputMaybe<ConnectionDateFilterInput>;
  postTransformedAt?: InputMaybe<ConnectionDateFilterInput>;
  postTransformingAt?: InputMaybe<ConnectionDateFilterInput>;
  processInstanceType?: InputMaybe<ConnectionStringFilterInput>;
  processedAt?: InputMaybe<ConnectionDateFilterInput>;
  processingAt?: InputMaybe<ConnectionDateFilterInput>;
  queuedAt?: InputMaybe<ConnectionDateFilterInput>;
  queueingAt?: InputMaybe<ConnectionDateFilterInput>;
  readyAt?: InputMaybe<ConnectionDateFilterInput>;
  refineryEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  refineryProcessedAt?: InputMaybe<ConnectionDateFilterInput>;
  refineryProcessingAt?: InputMaybe<ConnectionDateFilterInput>;
  refineryProcessingEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  robotsOverwrite?: InputMaybe<ConnectionStringFilterInput>;
  scheduleRunningEmailSentAt?: InputMaybe<ConnectionDateFilterInput>;
  scheduled?: InputMaybe<ConnectionBooleanFilterInput>;
  serpentCrawlerEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  serpentCrawlerQueryGraphType?: InputMaybe<ConnectionSerpentCrawlerQueryGraphTypeFilterInput>;
  siteExplorer?: InputMaybe<ConnectionBooleanFilterInput>;
  sitemapCrawlerPlatformId?: InputMaybe<ConnectionStringFilterInput>;
  startedByApi?: InputMaybe<ConnectionBooleanFilterInput>;
  status?: InputMaybe<ConnectionStringFilterInput>;
  statusEnum?: InputMaybe<ConnectionCrawlStatusFilterInput>;
  totalStepLinks?: InputMaybe<ConnectionBigIntFilterInput>;
  totalSteps?: InputMaybe<ConnectionIntFilterInput>;
  transformedAt?: InputMaybe<ConnectionDateFilterInput>;
  transformingAt?: InputMaybe<ConnectionDateFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  useEsReportStats?: InputMaybe<ConnectionBooleanFilterInput>;
  useRewriteRules?: InputMaybe<ConnectionBooleanFilterInput>;
  useRobotsOverwrite?: InputMaybe<ConnectionBooleanFilterInput>;
  version?: InputMaybe<ConnectionFloatFilterInput>;
};

export type CrawlDatasource = {
  datasourceCode: DatasourceCode;
  indexName?: Maybe<Scalars['String']['output']>;
  postCrawlPopulatedAt?: Maybe<Scalars['DateTime']['output']>;
  postCrawlPopulatingAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CrawlDatasourceConnection = {
  /** A list of edges. */
  edges: Array<CrawlDatasourceEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlDatasource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlDatasources in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlDatasourceConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlDatasourceConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlDatasourceConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlDatasourceConnectionFilterInput>>;
  datasourceCode?: InputMaybe<ConnectionDatasourceCodeFilterInput>;
};

export type CrawlDatasourceEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlDatasource;
};

export type CrawlDatasourceOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlDatasources by. */
  field: CrawlDatasourceOrderField;
};

export enum CrawlDatasourceOrderField {
  DatasourceCode = 'datasourceCode'
}

export type CrawlDomain = {
  domain: Scalars['String']['output'];
  domainDigest: Scalars['String']['output'];
};

export type CrawlDomainConnection = {
  /** A list of edges. */
  edges: Array<CrawlDomainEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlDomain>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlDomains in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlDomainConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlDomainConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlDomainConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlDomainConnectionFilterInput>>;
  domain?: InputMaybe<ConnectionStringFilterInput>;
  domainDigest?: InputMaybe<ConnectionStringFilterInput>;
};

export type CrawlDomainEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlDomain;
};

export type CrawlDomainOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlDomains by. */
  field: CrawlDomainOrderField;
};

export enum CrawlDomainOrderField {
  Domain = 'domain'
}

export type CrawlDuplicateUrl = {
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  canonicalUrlDigest?: Maybe<Scalars['String']['output']>;
  contentSize?: Maybe<Scalars['Int']['output']>;
  deeprank?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionLength?: Maybe<Scalars['Int']['output']>;
  descriptionLengthPx?: Maybe<Scalars['Int']['output']>;
  duplicateCount?: Maybe<Scalars['Int']['output']>;
  duplicateNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateType: Scalars['String']['output'];
  exampleDuplicate1?: Maybe<Scalars['String']['output']>;
  exampleDuplicate2?: Maybe<Scalars['String']['output']>;
  exampleDuplicateDigest1?: Maybe<Scalars['String']['output']>;
  exampleDuplicateDigest2?: Maybe<Scalars['String']['output']>;
  foundAtSitemap?: Maybe<Scalars['String']['output']>;
  foundAtUrl?: Maybe<Scalars['String']['output']>;
  h1Tag?: Maybe<Array<Scalars['String']['output']>>;
  h1TagCount?: Maybe<Scalars['Int']['output']>;
  htmlSize?: Maybe<Scalars['Int']['output']>;
  httpStatusCode?: Maybe<Scalars['Int']['output']>;
  indexable?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  ogTitle?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
  pageTitleLength?: Maybe<Scalars['Int']['output']>;
  pageTitleLengthPx?: Maybe<Scalars['Int']['output']>;
  primaryUrl?: Maybe<Scalars['String']['output']>;
  primaryUrlDigest: Scalars['String']['output'];
  wordCount?: Maybe<Scalars['Int']['output']>;
};

export type CrawlDuplicateUrlConnection = {
  /** A list of edges. */
  edges: Array<CrawlDuplicateUrlEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlDuplicateUrl>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlDuplicateUrls in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlDuplicateUrlConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlDuplicateUrlConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlDuplicateUrlConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlDuplicateUrlConnectionFilterInput>>;
  canonicalUrl?: InputMaybe<ConnectionStringFilterInput>;
  canonicalUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  contentSize?: InputMaybe<ConnectionIntFilterInput>;
  deeprank?: InputMaybe<ConnectionFloatFilterInput>;
  description?: InputMaybe<ConnectionStringFilterInput>;
  descriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  descriptionLengthPx?: InputMaybe<ConnectionIntFilterInput>;
  duplicateCount?: InputMaybe<ConnectionIntFilterInput>;
  duplicateNonIndexable?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateType?: InputMaybe<ConnectionStringFilterInput>;
  exampleDuplicate1?: InputMaybe<ConnectionStringFilterInput>;
  exampleDuplicate2?: InputMaybe<ConnectionStringFilterInput>;
  exampleDuplicateDigest1?: InputMaybe<ConnectionStringFilterInput>;
  exampleDuplicateDigest2?: InputMaybe<ConnectionStringFilterInput>;
  foundAtSitemap?: InputMaybe<ConnectionStringFilterInput>;
  foundAtUrl?: InputMaybe<ConnectionStringFilterInput>;
  h1Tag?: InputMaybe<ConnectionStringArrayFilterInput>;
  h1TagCount?: InputMaybe<ConnectionIntFilterInput>;
  htmlSize?: InputMaybe<ConnectionIntFilterInput>;
  httpStatusCode?: InputMaybe<ConnectionIntFilterInput>;
  indexable?: InputMaybe<ConnectionBooleanFilterInput>;
  level?: InputMaybe<ConnectionIntFilterInput>;
  ogTitle?: InputMaybe<ConnectionStringFilterInput>;
  pageTitle?: InputMaybe<ConnectionStringFilterInput>;
  pageTitleLength?: InputMaybe<ConnectionIntFilterInput>;
  pageTitleLengthPx?: InputMaybe<ConnectionIntFilterInput>;
  primaryUrl?: InputMaybe<ConnectionStringFilterInput>;
  primaryUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  wordCount?: InputMaybe<ConnectionIntFilterInput>;
};

export type CrawlDuplicateUrlEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlDuplicateUrl;
};

export type CrawlDuplicateUrlOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlDuplicateUrls by. */
  field: CrawlDuplicateUrlOrderField;
};

export enum CrawlDuplicateUrlOrderField {
  CanonicalUrl = 'canonicalUrl',
  ContentSize = 'contentSize',
  Deeprank = 'deeprank',
  Description = 'description',
  DescriptionLength = 'descriptionLength',
  DescriptionLengthPx = 'descriptionLengthPx',
  DuplicateCount = 'duplicateCount',
  DuplicateNonIndexable = 'duplicateNonIndexable',
  DuplicateType = 'duplicateType',
  ExampleDuplicate1 = 'exampleDuplicate1',
  ExampleDuplicate2 = 'exampleDuplicate2',
  FoundAtSitemap = 'foundAtSitemap',
  FoundAtUrl = 'foundAtUrl',
  H1TagCount = 'h1TagCount',
  HtmlSize = 'htmlSize',
  HttpStatusCode = 'httpStatusCode',
  Indexable = 'indexable',
  Level = 'level',
  OgTitle = 'ogTitle',
  PageTitle = 'pageTitle',
  PageTitleLength = 'pageTitleLength',
  PageTitleLengthPx = 'pageTitleLengthPx',
  PrimaryUrl = 'primaryUrl',
  WordCount = 'wordCount'
}

export type CrawlEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Crawl;
};

export enum CrawlFsmState {
  Archived = 'Archived',
  Archiving = 'Archiving',
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  Crawled1 = 'Crawled1',
  Crawled2 = 'Crawled2',
  Crawling1 = 'Crawling1',
  Crawling2 = 'Crawling2',
  Deleting = 'Deleting',
  DiscoveredUrLs = 'DiscoveredURLs',
  DiscoveringUrLs = 'DiscoveringURLs',
  Draft = 'Draft',
  Errored = 'Errored',
  Finished = 'Finished',
  Migrated = 'Migrated',
  Migrating = 'Migrating',
  Paused1 = 'Paused1',
  Paused2 = 'Paused2',
  Populated = 'Populated',
  Populating = 'Populating',
  PostTransformed = 'PostTransformed',
  PostTransforming = 'PostTransforming',
  Processed = 'Processed',
  Processing = 'Processing',
  Queued = 'Queued',
  Queueing = 'Queueing',
  Transformed = 'Transformed',
  Transforming = 'Transforming'
}

export type CrawlHealthScore = {
  crawlId: Scalars['ObjectID']['output'];
  healthScores: Array<CrawlHealthScoreItem>;
};

export type CrawlHealthScoreConnection = {
  edges: Array<CrawlHealthScoreEdge>;
  nodes: Array<CrawlHealthScore>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CrawlHealthScoreEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: CrawlHealthScore;
};

export type CrawlHealthScoreItem = {
  categoryWeight: Scalars['Float']['output'];
  crawlId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  healthScore: Scalars['Float']['output'];
  reportCategoryCode: Scalars['String']['output'];
  segmentId?: Maybe<Scalars['Int']['output']>;
};

export type CrawlHealthScoreTrend = {
  crawlId: Scalars['ObjectID']['output'];
  healthScores: Array<CrawlHealthScoreItem>;
  reportCategoryCode: Scalars['String']['output'];
};

export type CrawlHealthScoreTrendConnection = {
  edges: Array<CrawlHealthScoreTrendEdge>;
  nodes: Array<CrawlHealthScoreTrend>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CrawlHealthScoreTrendEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: CrawlHealthScoreTrend;
};

export type CrawlHreflangs = {
  hreflangs?: Maybe<Array<Scalars['String']['output']>>;
  hreflangsCount?: Maybe<Scalars['Int']['output']>;
  hreflangsDigest: Scalars['String']['output'];
  urlCount?: Maybe<Scalars['Int']['output']>;
};

export type CrawlHreflangsConnection = {
  /** A list of edges. */
  edges: Array<CrawlHreflangsEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlHreflangs>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlHreflangs in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlHreflangsConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlHreflangsConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlHreflangsConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlHreflangsConnectionFilterInput>>;
  hreflangs?: InputMaybe<ConnectionStringArrayFilterInput>;
  hreflangsCount?: InputMaybe<ConnectionIntFilterInput>;
  hreflangsDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlCount?: InputMaybe<ConnectionIntFilterInput>;
};

export type CrawlHreflangsEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlHreflangs;
};

export type CrawlHreflangsOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlHreflangs by. */
  field: CrawlHreflangsOrderField;
};

export enum CrawlHreflangsOrderField {
  HreflangsCount = 'hreflangsCount',
  UrlCount = 'urlCount'
}

export type CrawlLevel = {
  level?: Maybe<Scalars['Int']['output']>;
  processedAt: Scalars['DateTime']['output'];
  stepsProcessed: Scalars['Int']['output'];
};

export type CrawlLink = {
  anchorText?: Maybe<Scalars['String']['output']>;
  attrRel?: Maybe<Scalars['String']['output']>;
  attrRelId?: Maybe<Scalars['Int']['output']>;
  attrType?: Maybe<Scalars['String']['output']>;
  dominantLanguagesHreflangMismatch?: Maybe<Scalars['Boolean']['output']>;
  handheld?: Maybe<Scalars['Boolean']['output']>;
  hreflang?: Maybe<Scalars['String']['output']>;
  hreflangLanguage?: Maybe<Scalars['String']['output']>;
  hreflangReciprocate?: Maybe<Scalars['Boolean']['output']>;
  identifiedHreflangLanguageMismatch?: Maybe<Scalars['Boolean']['output']>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  isHreflang?: Maybe<Scalars['Boolean']['output']>;
  isRedirect?: Maybe<Scalars['Boolean']['output']>;
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  isValidHreflang?: Maybe<Scalars['Boolean']['output']>;
  linkDigest?: Maybe<Scalars['String']['output']>;
  linkType?: Maybe<Scalars['String']['output']>;
  linkTypeId?: Maybe<Scalars['Int']['output']>;
  mobile?: Maybe<Scalars['Boolean']['output']>;
  relUrlFrom?: Maybe<Scalars['String']['output']>;
  relUrlFromDigest?: Maybe<Scalars['String']['output']>;
  tag: Scalars['String']['output'];
  tagId: Scalars['Int']['output'];
  urlFrom?: Maybe<Scalars['String']['output']>;
  urlFromAlias?: Maybe<Scalars['String']['output']>;
  urlFromAliasDigest?: Maybe<Scalars['String']['output']>;
  urlFromDeeprank?: Maybe<Scalars['Float']['output']>;
  urlFromDigest: Scalars['String']['output'];
  urlFromHttps?: Maybe<Scalars['Boolean']['output']>;
  urlFromStatusCode?: Maybe<Scalars['Int']['output']>;
  urlFromStatusCodeId?: Maybe<Scalars['Float']['output']>;
  urlFromTitle?: Maybe<Scalars['String']['output']>;
  urlTo?: Maybe<Scalars['String']['output']>;
  urlToAlias?: Maybe<Scalars['String']['output']>;
  urlToAliasDigest?: Maybe<Scalars['String']['output']>;
  urlToDeeprank?: Maybe<Scalars['Float']['output']>;
  urlToDigest?: Maybe<Scalars['String']['output']>;
  urlToDominantLanguages?: Maybe<Array<Scalars['String']['output']>>;
  urlToHttps?: Maybe<Scalars['Boolean']['output']>;
  urlToIdentifiedLanguage?: Maybe<Scalars['String']['output']>;
  urlToStatusCode?: Maybe<Scalars['Int']['output']>;
  urlToStatusCodeId?: Maybe<Scalars['Int']['output']>;
  urlToTitle?: Maybe<Scalars['String']['output']>;
};

export type CrawlLinkConnection = {
  /** A list of edges. */
  edges: Array<CrawlLinkEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlLink>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlLinks in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlLinkConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlLinkConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlLinkConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlLinkConnectionFilterInput>>;
  anchorText?: InputMaybe<ConnectionStringFilterInput>;
  attrRel?: InputMaybe<ConnectionStringFilterInput>;
  attrType?: InputMaybe<ConnectionStringFilterInput>;
  dominantLanguagesHreflangMismatch?: InputMaybe<ConnectionBooleanFilterInput>;
  handheld?: InputMaybe<ConnectionBooleanFilterInput>;
  hreflang?: InputMaybe<ConnectionStringFilterInput>;
  hreflangLanguage?: InputMaybe<ConnectionStringFilterInput>;
  hreflangReciprocate?: InputMaybe<ConnectionBooleanFilterInput>;
  identifiedHreflangLanguageMismatch?: InputMaybe<ConnectionBooleanFilterInput>;
  internal?: InputMaybe<ConnectionBooleanFilterInput>;
  isHreflang?: InputMaybe<ConnectionBooleanFilterInput>;
  isRedirect?: InputMaybe<ConnectionBooleanFilterInput>;
  isRestricted?: InputMaybe<ConnectionBooleanFilterInput>;
  isValidHreflang?: InputMaybe<ConnectionBooleanFilterInput>;
  linkDigest?: InputMaybe<ConnectionStringFilterInput>;
  linkType?: InputMaybe<ConnectionStringFilterInput>;
  mobile?: InputMaybe<ConnectionBooleanFilterInput>;
  relUrlFrom?: InputMaybe<ConnectionStringFilterInput>;
  relUrlFromDigest?: InputMaybe<ConnectionStringFilterInput>;
  tag?: InputMaybe<ConnectionStringFilterInput>;
  urlFrom?: InputMaybe<ConnectionStringFilterInput>;
  urlFromAlias?: InputMaybe<ConnectionStringFilterInput>;
  urlFromAliasDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlFromDeeprank?: InputMaybe<ConnectionFloatFilterInput>;
  urlFromDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlFromHttps?: InputMaybe<ConnectionBooleanFilterInput>;
  urlFromStatusCode?: InputMaybe<ConnectionIntFilterInput>;
  urlFromStatusCodeId?: InputMaybe<ConnectionFloatFilterInput>;
  urlFromTitle?: InputMaybe<ConnectionStringFilterInput>;
  urlTo?: InputMaybe<ConnectionStringFilterInput>;
  urlToAlias?: InputMaybe<ConnectionStringFilterInput>;
  urlToAliasDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlToDeeprank?: InputMaybe<ConnectionFloatFilterInput>;
  urlToDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlToDominantLanguages?: InputMaybe<ConnectionStringArrayFilterInput>;
  urlToHttps?: InputMaybe<ConnectionBooleanFilterInput>;
  urlToIdentifiedLanguage?: InputMaybe<ConnectionStringFilterInput>;
  urlToStatusCode?: InputMaybe<ConnectionIntFilterInput>;
  urlToTitle?: InputMaybe<ConnectionStringFilterInput>;
};

export type CrawlLinkEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlLink;
};

export type CrawlLinkOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlLinks by. */
  field: CrawlLinkOrderField;
};

export enum CrawlLinkOrderField {
  AnchorText = 'anchorText',
  AttrRel = 'attrRel',
  AttrType = 'attrType',
  DominantLanguagesHreflangMismatch = 'dominantLanguagesHreflangMismatch',
  Handheld = 'handheld',
  Hreflang = 'hreflang',
  HreflangLanguage = 'hreflangLanguage',
  HreflangReciprocate = 'hreflangReciprocate',
  IdentifiedHreflangLanguageMismatch = 'identifiedHreflangLanguageMismatch',
  Internal = 'internal',
  IsHreflang = 'isHreflang',
  IsRedirect = 'isRedirect',
  IsRestricted = 'isRestricted',
  IsValidHreflang = 'isValidHreflang',
  LinkType = 'linkType',
  Mobile = 'mobile',
  RelUrlFrom = 'relUrlFrom',
  Tag = 'tag',
  UrlFrom = 'urlFrom',
  UrlFromAlias = 'urlFromAlias',
  UrlFromDeeprank = 'urlFromDeeprank',
  UrlFromHttps = 'urlFromHttps',
  UrlFromStatusCode = 'urlFromStatusCode',
  UrlFromTitle = 'urlFromTitle',
  UrlTo = 'urlTo',
  UrlToAlias = 'urlToAlias',
  UrlToDeeprank = 'urlToDeeprank',
  UrlToHttps = 'urlToHttps',
  UrlToIdentifiedLanguage = 'urlToIdentifiedLanguage',
  UrlToStatusCode = 'urlToStatusCode',
  UrlToTitle = 'urlToTitle'
}

export type CrawlLinkedDomain = {
  domain: Scalars['String']['output'];
  domainAlias?: Maybe<Scalars['String']['output']>;
  linkCount?: Maybe<Scalars['Int']['output']>;
};

export type CrawlLinkedDomainConnection = {
  /** A list of edges. */
  edges: Array<CrawlLinkedDomainEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlLinkedDomain>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlLinkedDomains in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlLinkedDomainConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlLinkedDomainConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlLinkedDomainConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlLinkedDomainConnectionFilterInput>>;
  domain?: InputMaybe<ConnectionStringFilterInput>;
  linkCount?: InputMaybe<ConnectionIntFilterInput>;
};

export type CrawlLinkedDomainEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlLinkedDomain;
};

export type CrawlLinkedDomainOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlLinkedDomains by. */
  field: CrawlLinkedDomainOrderField;
};

export enum CrawlLinkedDomainOrderField {
  LinkCount = 'linkCount'
}

export type CrawlOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Crawls by. */
  field: CrawlOrderField;
};

export enum CrawlOrderField {
  ArchivedAt = 'archivedAt',
  ArchivingAt = 'archivingAt',
  BrokenAt = 'brokenAt',
  BucketSlot = 'bucketSlot',
  CanceledAt = 'canceledAt',
  CancelingAt = 'cancelingAt',
  CrawlLevels = 'crawlLevels',
  CrawlUrlsTotal = 'crawlUrlsTotal',
  Crawlability = 'crawlability',
  CrawlabilityTrend = 'crawlabilityTrend',
  CrawledAt = 'crawledAt',
  CrawledPhase1At = 'crawledPhase1At',
  CrawledPhase2At = 'crawledPhase2At',
  CrawlingAt = 'crawlingAt',
  CrawlingPhase1At = 'crawlingPhase1At',
  CrawlingPhase2At = 'crawlingPhase2At',
  CreatedAt = 'createdAt',
  DiscoveredUrlsAt = 'discoveredUrlsAt',
  DiscoveringUrlsAt = 'discoveringUrlsAt',
  ErrorMessage = 'errorMessage',
  ErroredAt = 'erroredAt',
  FinishedAt = 'finishedAt',
  FsmExtTransitionedAt = 'fsmExtTransitionedAt',
  FsmState = 'fsmState',
  FsmTransitionedAt = 'fsmTransitionedAt',
  Id = 'id',
  Incomplete = 'incomplete',
  IsContainer = 'isContainer',
  IsScheduled = 'isScheduled',
  LanguageSettings = 'languageSettings',
  LanguageSettingsAdded = 'languageSettingsAdded',
  LanguageSettingsRemoved = 'languageSettingsRemoved',
  LimitLevelsMax = 'limitLevelsMax',
  LimitPagesMax = 'limitPagesMax',
  MaxProcessLinks = 'maxProcessLinks',
  MigratedAt = 'migratedAt',
  MigratingAt = 'migratingAt',
  PageGroupings = 'pageGroupings',
  PausedReminderSent = 'pausedReminderSent',
  PopulatedAt = 'populatedAt',
  PopulatingAt = 'populatingAt',
  PostPopulatedAt = 'postPopulatedAt',
  PostPopulatingAt = 'postPopulatingAt',
  PostTransformedAt = 'postTransformedAt',
  PostTransformingAt = 'postTransformingAt',
  ProcessInstanceType = 'processInstanceType',
  ProcessedAt = 'processedAt',
  ProcessingAt = 'processingAt',
  QueuedAt = 'queuedAt',
  QueueingAt = 'queueingAt',
  ReadyAt = 'readyAt',
  RefineryEnabled = 'refineryEnabled',
  RefineryProcessedAt = 'refineryProcessedAt',
  RefineryProcessingAt = 'refineryProcessingAt',
  RefineryProcessingEnabled = 'refineryProcessingEnabled',
  RobotsOverwrite = 'robotsOverwrite',
  ScheduleRunningEmailSentAt = 'scheduleRunningEmailSentAt',
  Scheduled = 'scheduled',
  SiteExplorer = 'siteExplorer',
  StartedByApi = 'startedByApi',
  Status = 'status',
  StatusEnum = 'statusEnum',
  TotalStepLinks = 'totalStepLinks',
  TotalSteps = 'totalSteps',
  TransformedAt = 'transformedAt',
  TransformingAt = 'transformingAt',
  UpdatedAt = 'updatedAt',
  UseEsReportStats = 'useEsReportStats',
  UseRewriteRules = 'useRewriteRules',
  UseRobotsOverwrite = 'useRobotsOverwrite',
  Version = 'version'
}

export type CrawlParquetFile = {
  datasourceName: Scalars['String']['output'];
  /** @deprecated File URLs have 1 week expiry and are available as long as Crawl is. */
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  files: Array<Scalars['String']['output']>;
};

export type CrawlRate = {
  crawlingAt: Scalars['DateTime']['output'];
  rate: Scalars['Float']['output'];
};

export type CrawlReportTemplate = {
  internalGeneratedESQuery?: Maybe<Scalars['JSONObject']['output']>;
  queryVersion: Scalars['Int']['output'];
  reportTemplate: ReportTemplate;
  reportTemplateCode: Scalars['String']['output'];
};


export type CrawlReportTemplateInternalGeneratedEsQueryArgs = {
  reportTypeCode: ReportTypeCode;
};

export type CrawlReportTemplateConnection = {
  /** A list of edges. */
  edges: Array<CrawlReportTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlReportTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlReportTemplates in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlReportTemplateConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlReportTemplateConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlReportTemplateConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlReportTemplateConnectionFilterInput>>;
  crawlId?: InputMaybe<ConnectionObjectIdFilterInput>;
  queryVersion?: InputMaybe<ConnectionIntFilterInput>;
  reportTemplateCode?: InputMaybe<ConnectionStringFilterInput>;
};

export type CrawlReportTemplateEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlReportTemplate;
};

export type CrawlReportTemplateOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlReportTemplates by. */
  field: CrawlReportTemplateOrderField;
};

export enum CrawlReportTemplateOrderField {
  QueryVersion = 'queryVersion',
  ReportTemplateCode = 'reportTemplateCode'
}

export type CrawlReportsByCodeInput = {
  orderBy?: InputMaybe<ReportOrder>;
  reportTemplateCodes: Array<Scalars['String']['input']>;
  reportTypeCodes?: Array<ReportTypeCode>;
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type CrawlSchema = {
  createdAt: Scalars['DateTime']['output'];
  tableTypeFields?: Maybe<Array<Scalars['JSONObject']['output']>>;
  tableTypeName?: Maybe<Scalars['String']['output']>;
  tableTypeType?: Maybe<CustomMetricContainerTableType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CrawlSegment = {
  crawl: Crawl;
  crawlId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  failureReason?: Maybe<Scalars['String']['output']>;
  generatedAt?: Maybe<Scalars['DateTime']['output']>;
  generatingAt?: Maybe<Scalars['DateTime']['output']>;
  reportStats: Array<ReportStat>;
  segment: Segment;
  segmentId: Scalars['Int']['output'];
  segmentVersion: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type CrawlSegmentReportStatsArgs = {
  reportTemplateCodes: Array<Scalars['String']['input']>;
};

export type CrawlSegmentConnection = {
  /** A list of edges. */
  edges: Array<CrawlSegmentEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlSegment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlSegments in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlSegmentConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlSegmentConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlSegmentConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlSegmentConnectionFilterInput>>;
  crawlId?: InputMaybe<ConnectionObjectIdFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  failedAt?: InputMaybe<ConnectionDateFilterInput>;
  failureReason?: InputMaybe<ConnectionStringFilterInput>;
  generatedAt?: InputMaybe<ConnectionDateFilterInput>;
  generatingAt?: InputMaybe<ConnectionDateFilterInput>;
  segmentId?: InputMaybe<ConnectionObjectIdFilterInput>;
  segmentVersion?: InputMaybe<ConnectionIntFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type CrawlSegmentEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlSegment;
};

export type CrawlSegmentHealthScore = {
  crawlId: Scalars['ObjectID']['output'];
  healthScores: Array<CrawlHealthScoreItem>;
  segment: Segment;
  segmentId?: Maybe<Scalars['ObjectID']['output']>;
};

export type CrawlSegmentHealthScoreConnection = {
  edges: Array<CrawlSegmentHealthScoreEdge>;
  nodes: Array<CrawlSegmentHealthScore>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CrawlSegmentHealthScoreEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: CrawlSegmentHealthScore;
};

export type CrawlSegmentHealthScoreTrend = {
  crawlId: Scalars['ObjectID']['output'];
  healthScores: Array<CrawlHealthScoreItem>;
  reportCategoryCode: Scalars['String']['output'];
  segment: Segment;
  segmentId?: Maybe<Scalars['ObjectID']['output']>;
};

export type CrawlSegmentHealthScoreTrendConnection = {
  edges: Array<CrawlSegmentHealthScoreTrendEdge>;
  nodes: Array<CrawlSegmentHealthScoreTrend>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CrawlSegmentHealthScoreTrendEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: CrawlSegmentHealthScoreTrend;
};

export type CrawlSegmentOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlSegments by. */
  field: CrawlSegmentOrderField;
};

export enum CrawlSegmentOrderField {
  CreatedAt = 'createdAt',
  FailedAt = 'failedAt',
  FailureReason = 'failureReason',
  GeneratedAt = 'generatedAt',
  GeneratingAt = 'generatingAt',
  SegmentVersion = 'segmentVersion',
  UpdatedAt = 'updatedAt'
}

export type CrawlSetting = {
  activeMaximumCrawlRateAdvanced?: Maybe<AdvancedCrawlRate>;
  alertSetting: AlertSetting;
  applyCustomHeadersToNavigationRequestsOnly: Scalars['Boolean']['output'];
  autoFinalizeOnCrawlLimits: Scalars['Boolean']['output'];
  block3rdPartyCookies: Scalars['Boolean']['output'];
  compareToCrawl?: Maybe<Scalars['CompareToCrawl']['output']>;
  crawlDisallowedUrls1stLevel: Scalars['Boolean']['output'];
  crawlHyperlinksExternal: Scalars['Boolean']['output'];
  crawlHyperlinksInternal: Scalars['Boolean']['output'];
  crawlImagesExternal: Scalars['Boolean']['output'];
  crawlImagesInternal: Scalars['Boolean']['output'];
  crawlNofollowHyperlinks: Scalars['Boolean']['output'];
  crawlNonHtml: Scalars['Boolean']['output'];
  crawlNotIncluded1stLevel: Scalars['Boolean']['output'];
  crawlOtherRelExternal: Scalars['Boolean']['output'];
  crawlOtherRelInternal: Scalars['Boolean']['output'];
  crawlRedirectsExternal: Scalars['Boolean']['output'];
  crawlRedirectsInternal: Scalars['Boolean']['output'];
  crawlRelAmphtmlExternal: Scalars['Boolean']['output'];
  crawlRelAmphtmlInternal: Scalars['Boolean']['output'];
  crawlRelCanonicalsExternal: Scalars['Boolean']['output'];
  crawlRelCanonicalsInternal: Scalars['Boolean']['output'];
  crawlRelHreflangsExternal: Scalars['Boolean']['output'];
  crawlRelHreflangsInternal: Scalars['Boolean']['output'];
  crawlRelMobileExternal: Scalars['Boolean']['output'];
  crawlRelMobileInternal: Scalars['Boolean']['output'];
  crawlRelNextPrevExternal: Scalars['Boolean']['output'];
  crawlRelNextPrevInternal: Scalars['Boolean']['output'];
  crawlRobotsTxtNoindex: Scalars['Boolean']['output'];
  crawlScriptsExternal: Scalars['Boolean']['output'];
  crawlScriptsInternal: Scalars['Boolean']['output'];
  crawlSitemapsExternal: Scalars['Boolean']['output'];
  crawlSitemapsInternal: Scalars['Boolean']['output'];
  crawlStylesheetsExternal: Scalars['Boolean']['output'];
  crawlStylesheetsInternal: Scalars['Boolean']['output'];
  crawlTestSite: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  customDns?: Maybe<Array<CustomDnsSetting>>;
  /** Use regular expressions to extract custom information from pages when they are crawled. */
  customExtractions?: Maybe<Array<CustomExtractionSetting>>;
  customRequestHeaders?: Maybe<Scalars['JSONObject']['output']>;
  dataLayerName?: Maybe<Scalars['String']['output']>;
  dataOnlyCrawlTypes?: Maybe<Array<CrawlType>>;
  discoverSitemapsInRobotsTxt: Scalars['Boolean']['output'];
  duplicatePrecision: Scalars['Float']['output'];
  emptyPageThreshold?: Maybe<Scalars['Int']['output']>;
  enableKeyValueStore: Scalars['Boolean']['output'];
  excludeUrlPatterns?: Maybe<Array<Scalars['String']['output']>>;
  flattenIframes: Scalars['Boolean']['output'];
  flattenShadowDom: Scalars['Boolean']['output'];
  gaDateRange: Scalars['Int']['output'];
  googleAnalytics4DisplayName?: Maybe<Scalars['String']['output']>;
  googleAnalytics4InheritFromSchedulePeriodOverride?: Maybe<Scalars['Boolean']['output']>;
  googleAnalytics4LastNDays?: Maybe<Scalars['Int']['output']>;
  googleAnalytics4MinimumSessions?: Maybe<Scalars['Int']['output']>;
  googleAnalytics4MinimumViews?: Maybe<Scalars['Int']['output']>;
  /** @deprecated GA4 settings flattened on CrawlSetting entity. Prefixed with `googleAnalytics4` */
  googleAnalytics4ProjectProperty?: Maybe<GoogleAnalytics4ProjectProperty>;
  googleAnalytics4PropertyName?: Maybe<Scalars['String']['output']>;
  googleAnalytics4Source?: Maybe<GoogleAnalytics4SourceType>;
  hasTestSitePassword: Scalars['Boolean']['output'];
  /** @deprecated Renamed to logSummaryRequestsHigh. */
  highLogSummaryRequests: Scalars['Int']['output'];
  id: Scalars['ObjectID']['output'];
  ignoreRobotsForNavigationRequests: Scalars['Boolean']['output'];
  includeBestPractices: Scalars['Boolean']['output'];
  includeHttpAndHttps: Scalars['Boolean']['output'];
  includeSubdomains: Scalars['Boolean']['output'];
  includeUrlPatterns?: Maybe<Array<Scalars['String']['output']>>;
  limitLevelsMax: Scalars['Int']['output'];
  limitUrlsMax: Scalars['Int']['output'];
  location: Location;
  logSummaryRequestsHigh: Scalars['Int']['output'];
  logSummaryRequestsLow: Scalars['Int']['output'];
  /** @deprecated Renamed to logSummaryRequestsLow. */
  lowLogSummaryRequests: Scalars['Int']['output'];
  majesticConfigurationEnabled: Scalars['Boolean']['output'];
  majesticConfigurationMaxRows: Scalars['Int']['output'];
  majesticConfigurationUseHistoricData: Scalars['Boolean']['output'];
  majesticConfigurationUseRootDomain: Scalars['Boolean']['output'];
  maxBodyContentLength?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Renamed to maxBodyContentLength. */
  maxContentThreshold: Scalars['Int']['output'];
  maxDescriptionLength?: Maybe<Scalars['Int']['output']>;
  maxFollowedExternalLinks?: Maybe<Scalars['Int']['output']>;
  maxHtmlSize?: Maybe<Scalars['Int']['output']>;
  maxLinks?: Maybe<Scalars['Int']['output']>;
  maxLinksProcessed: Scalars['Int']['output'];
  maxLoadTime?: Maybe<Scalars['Float']['output']>;
  maxRedirections: Scalars['Int']['output'];
  maxTitleWidth: Scalars['Int']['output'];
  maxUrlLength?: Maybe<Scalars['Int']['output']>;
  maximumCrawlRate: Scalars['Float']['output'];
  maximumCrawlRateAdvanced?: Maybe<Array<AdvancedCrawlRate>>;
  minContentRatio?: Maybe<Scalars['Float']['output']>;
  minDescriptionLength: Scalars['Int']['output'];
  minTitleLength: Scalars['Int']['output'];
  minVisits: Scalars['Int']['output'];
  mobileHomepageUrl?: Maybe<Scalars['String']['output']>;
  mobileUrlPattern?: Maybe<Scalars['String']['output']>;
  mobileUserAgent?: Maybe<UserAgent>;
  mobileViewportHeight?: Maybe<Scalars['Int']['output']>;
  mobileViewportWidth?: Maybe<Scalars['Int']['output']>;
  primaryDomain: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  renderTimeout: Scalars['Int']['output'];
  renderWithImages: Scalars['Boolean']['output'];
  rendererBlockAds: Scalars['Boolean']['output'];
  rendererBlockAnalytics: Scalars['Boolean']['output'];
  rendererBlockCustom?: Maybe<Array<Scalars['String']['output']>>;
  rendererCookies?: Maybe<Array<RendererCookie>>;
  renderingRobotsCheckMode?: Maybe<RenderingRobotsCheckMode>;
  reportTemplateOverrides?: Maybe<Array<ReportTemplateOverrideInfo>>;
  robotsOverwrite?: Maybe<Scalars['String']['output']>;
  /** @deprecated Legacy setting, no longer used. Will be removed. */
  safeTitleWidth: Scalars['Int']['output'];
  secondaryDomains?: Maybe<Array<Scalars['String']['output']>>;
  selectedWcagLevel: WcagLevel;
  selectedWcagVersion: Scalars['Float']['output'];
  siteSpeedScoring?: Maybe<Scalars['JSONObject']['output']>;
  sitemapUrls?: Maybe<Array<Scalars['String']['output']>>;
  startUrls?: Maybe<Array<Scalars['String']['output']>>;
  storeHtml: Scalars['Boolean']['output'];
  targetMaxUncrawledUrlsCount: Scalars['Int']['output'];
  testSiteDomain?: Maybe<Scalars['String']['output']>;
  testSiteUsername?: Maybe<Scalars['String']['output']>;
  thinPageThreshold?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Legacy setting, no longer used. Will be removed. */
  twitterDescriptionLength: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  urlRewriteQueryParameters?: Maybe<Array<Scalars['String']['output']>>;
  urlRewriteRules?: Maybe<Array<UrlRewriteRule>>;
  urlRewriteStripFragment: Scalars['Boolean']['output'];
  urlSampling?: Maybe<Array<UrlSampling>>;
  useMobileSettings: Scalars['Boolean']['output'];
  useNewHeadlessMode: Scalars['Boolean']['output'];
  useOptimus: Scalars['Boolean']['output'];
  useRenderer: Scalars['Boolean']['output'];
  useRobotsOverwrite: Scalars['Boolean']['output'];
  useStealthMode?: Maybe<Scalars['Boolean']['output']>;
  useStealthModeV2: Scalars['Boolean']['output'];
  useUrlRewriteRules: Scalars['Boolean']['output'];
  userAgent?: Maybe<UserAgent>;
  userAgentIsMobile?: Maybe<Scalars['Boolean']['output']>;
  userAgentMobileIsMobile?: Maybe<Scalars['Boolean']['output']>;
  userAgentString?: Maybe<Scalars['String']['output']>;
  userAgentStringMobile?: Maybe<Scalars['String']['output']>;
  userAgentSuffix: Scalars['String']['output'];
  userAgentToken?: Maybe<Scalars['String']['output']>;
  userAgentTokenMobile?: Maybe<Scalars['String']['output']>;
  viewportHeight?: Maybe<Scalars['Int']['output']>;
  viewportWidth?: Maybe<Scalars['Int']['output']>;
};

export type CrawlSettingConnection = {
  /** A list of edges. */
  edges: Array<CrawlSettingEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlSetting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlSettings in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlSettingConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlSettingConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlSettingConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlSettingConnectionFilterInput>>;
  applyCustomHeadersToNavigationRequestsOnly?: InputMaybe<ConnectionBooleanFilterInput>;
  block3rdPartyCookies?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicatePrecision?: InputMaybe<ConnectionFloatFilterInput>;
  emptyPageThreshold?: InputMaybe<ConnectionIntFilterInput>;
  enableKeyValueStore?: InputMaybe<ConnectionBooleanFilterInput>;
  flattenIframes?: InputMaybe<ConnectionBooleanFilterInput>;
  flattenShadowDom?: InputMaybe<ConnectionBooleanFilterInput>;
  highLogSummaryRequests?: InputMaybe<ConnectionIntFilterInput>;
  ignoreRobotsForNavigationRequests?: InputMaybe<ConnectionBooleanFilterInput>;
  includeBestPractices?: InputMaybe<ConnectionBooleanFilterInput>;
  lowLogSummaryRequests?: InputMaybe<ConnectionIntFilterInput>;
  maxContentThreshold?: InputMaybe<ConnectionIntFilterInput>;
  maxDescriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  maxFollowedExternalLinks?: InputMaybe<ConnectionIntFilterInput>;
  maxHtmlSize?: InputMaybe<ConnectionIntFilterInput>;
  maxLinks?: InputMaybe<ConnectionIntFilterInput>;
  maxLoadTime?: InputMaybe<ConnectionFloatFilterInput>;
  maxRedirections?: InputMaybe<ConnectionIntFilterInput>;
  maxTitleWidth?: InputMaybe<ConnectionIntFilterInput>;
  maxUrlLength?: InputMaybe<ConnectionIntFilterInput>;
  minContentRatio?: InputMaybe<ConnectionFloatFilterInput>;
  minDescriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  minTitleLength?: InputMaybe<ConnectionIntFilterInput>;
  renderWithImages?: InputMaybe<ConnectionBooleanFilterInput>;
  renderingRobotsCheckMode?: InputMaybe<ConnectionRenderingRobotsCheckModeFilterInput>;
  safeTitleWidth?: InputMaybe<ConnectionIntFilterInput>;
  targetMaxUncrawledUrlsCount?: InputMaybe<ConnectionIntFilterInput>;
  thinPageThreshold?: InputMaybe<ConnectionIntFilterInput>;
  twitterDescriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  useNewHeadlessMode?: InputMaybe<ConnectionBooleanFilterInput>;
};

export type CrawlSettingEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlSetting;
};

export type CrawlSettingOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlSettings by. */
  field: CrawlSettingOrderField;
};

export enum CrawlSettingOrderField {
  DuplicatePrecision = 'duplicatePrecision',
  EmptyPageThreshold = 'emptyPageThreshold',
  EnableKeyValueStore = 'enableKeyValueStore',
  FlattenIframes = 'flattenIframes',
  FlattenShadowDom = 'flattenShadowDom',
  HighLogSummaryRequests = 'highLogSummaryRequests',
  IncludeBestPractices = 'includeBestPractices',
  LowLogSummaryRequests = 'lowLogSummaryRequests',
  MaxContentThreshold = 'maxContentThreshold',
  MaxDescriptionLength = 'maxDescriptionLength',
  MaxFollowedExternalLinks = 'maxFollowedExternalLinks',
  MaxHtmlSize = 'maxHtmlSize',
  MaxLinks = 'maxLinks',
  MaxLoadTime = 'maxLoadTime',
  MaxRedirections = 'maxRedirections',
  MaxTitleWidth = 'maxTitleWidth',
  MaxUrlLength = 'maxUrlLength',
  MinContentRatio = 'minContentRatio',
  MinDescriptionLength = 'minDescriptionLength',
  MinTitleLength = 'minTitleLength',
  RenderWithImages = 'renderWithImages',
  RenderingRobotsCheckMode = 'renderingRobotsCheckMode',
  SafeTitleWidth = 'safeTitleWidth',
  TargetMaxUncrawledUrlsCount = 'targetMaxUncrawledUrlsCount',
  ThinPageThreshold = 'thinPageThreshold',
  TwitterDescriptionLength = 'twitterDescriptionLength'
}

export type CrawlSiteSpeedAudit = {
  auditDigest: Scalars['String']['output'];
  auditId?: Maybe<Scalars['String']['output']>;
  auditResult?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayValue?: Maybe<Scalars['String']['output']>;
  exampleItems?: Maybe<Scalars['String']['output']>;
  gscClicks?: Maybe<Scalars['Int']['output']>;
  guidanceLevel?: Maybe<Scalars['Int']['output']>;
  itemsCount?: Maybe<Scalars['Int']['output']>;
  metricSavingsCls?: Maybe<Scalars['Float']['output']>;
  metricSavingsFcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsInp?: Maybe<Scalars['Float']['output']>;
  metricSavingsLcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsTbt?: Maybe<Scalars['Float']['output']>;
  numericUnit?: Maybe<Scalars['String']['output']>;
  numericValue?: Maybe<Scalars['Float']['output']>;
  pageviews?: Maybe<Scalars['BigInt']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  redirectsChain?: Maybe<Array<SiteSpeedAuditRedirect>>;
  redirectsChainCount?: Maybe<Scalars['Int']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  scoreDisplayMode?: Maybe<Scalars['String']['output']>;
  scoringOptionsMedian?: Maybe<Scalars['Float']['output']>;
  scoringOptionsPercentile?: Maybe<Scalars['Float']['output']>;
  segmentIds?: Maybe<Array<Scalars['Int']['output']>>;
  segmentIdsCount?: Maybe<Scalars['Int']['output']>;
  segmentNames?: Maybe<Array<Scalars['String']['output']>>;
  segmentNamesCount?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalSizeKib?: Maybe<Scalars['Float']['output']>;
  url: Scalars['String']['output'];
  urlDigest: Scalars['String']['output'];
  warnings?: Maybe<Scalars['String']['output']>;
};

export type CrawlSiteSpeedAuditAggregate = {
  auditId?: Maybe<Scalars['String']['output']>;
  auditResult?: Maybe<Scalars['String']['output']>;
  avg: CrawlSiteSpeedAuditAvgCalculation;
  count: CrawlSiteSpeedAuditCountCalculation;
  max: CrawlSiteSpeedAuditMaxCalculation;
  min: CrawlSiteSpeedAuditMinCalculation;
  sum: CrawlSiteSpeedAuditSumCalculation;
  title?: Maybe<Scalars['String']['output']>;
};

export type CrawlSiteSpeedAuditAggregateConnection = {
  /** A list of nodes. */
  nodes: Array<CrawlSiteSpeedAuditAggregate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlSiteSpeedAuditAggregates in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** Used for grouping the CrawlSiteSpeedAudit aggregates results. */
export enum CrawlSiteSpeedAuditAggregateDimension {
  AuditId = 'auditId',
  AuditResult = 'auditResult',
  Title = 'title'
}

export type CrawlSiteSpeedAuditAggregateOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The dimension or aggregation to order by. */
  field: CrawlSiteSpeedAuditAggregateOrderField;
};

export enum CrawlSiteSpeedAuditAggregateOrderField {
  AuditId = 'auditId',
  AuditResult = 'auditResult',
  AvgGscClicks = 'avgGscClicks',
  AvgItemsCount = 'avgItemsCount',
  AvgMetricSavingsCls = 'avgMetricSavingsCls',
  AvgMetricSavingsFcp = 'avgMetricSavingsFcp',
  AvgMetricSavingsInp = 'avgMetricSavingsInp',
  AvgMetricSavingsLcp = 'avgMetricSavingsLcp',
  AvgMetricSavingsTbt = 'avgMetricSavingsTbt',
  AvgNumericValue = 'avgNumericValue',
  AvgPageviews = 'avgPageviews',
  AvgProductOfSavingsKibAndPageviews = 'avgProductOfSavingsKibAndPageviews',
  AvgProductOfSavingsSecsAndPageviews = 'avgProductOfSavingsSecsAndPageviews',
  AvgSavingsKib = 'avgSavingsKib',
  AvgSavingsScore = 'avgSavingsScore',
  AvgSavingsSecs = 'avgSavingsSecs',
  AvgScore = 'avgScore',
  AvgTotalSizeKib = 'avgTotalSizeKib',
  CountGscClicks = 'countGscClicks',
  CountItemsCount = 'countItemsCount',
  CountMetricSavingsCls = 'countMetricSavingsCls',
  CountMetricSavingsFcp = 'countMetricSavingsFcp',
  CountMetricSavingsInp = 'countMetricSavingsInp',
  CountMetricSavingsLcp = 'countMetricSavingsLcp',
  CountMetricSavingsTbt = 'countMetricSavingsTbt',
  CountNumericValue = 'countNumericValue',
  CountPageviews = 'countPageviews',
  CountProductOfSavingsKibAndPageviews = 'countProductOfSavingsKibAndPageviews',
  CountProductOfSavingsSecsAndPageviews = 'countProductOfSavingsSecsAndPageviews',
  CountSavingsKib = 'countSavingsKib',
  CountSavingsScore = 'countSavingsScore',
  CountSavingsSecs = 'countSavingsSecs',
  CountScore = 'countScore',
  CountTotalSizeKib = 'countTotalSizeKib',
  MaxGscClicks = 'maxGscClicks',
  MaxItemsCount = 'maxItemsCount',
  MaxMetricSavingsCls = 'maxMetricSavingsCls',
  MaxMetricSavingsFcp = 'maxMetricSavingsFcp',
  MaxMetricSavingsInp = 'maxMetricSavingsInp',
  MaxMetricSavingsLcp = 'maxMetricSavingsLcp',
  MaxMetricSavingsTbt = 'maxMetricSavingsTbt',
  MaxNumericValue = 'maxNumericValue',
  MaxPageviews = 'maxPageviews',
  MaxProductOfSavingsKibAndPageviews = 'maxProductOfSavingsKibAndPageviews',
  MaxProductOfSavingsSecsAndPageviews = 'maxProductOfSavingsSecsAndPageviews',
  MaxSavingsKib = 'maxSavingsKib',
  MaxSavingsScore = 'maxSavingsScore',
  MaxSavingsSecs = 'maxSavingsSecs',
  MaxScore = 'maxScore',
  MaxTotalSizeKib = 'maxTotalSizeKib',
  MinGscClicks = 'minGscClicks',
  MinItemsCount = 'minItemsCount',
  MinMetricSavingsCls = 'minMetricSavingsCls',
  MinMetricSavingsFcp = 'minMetricSavingsFcp',
  MinMetricSavingsInp = 'minMetricSavingsInp',
  MinMetricSavingsLcp = 'minMetricSavingsLcp',
  MinMetricSavingsTbt = 'minMetricSavingsTbt',
  MinNumericValue = 'minNumericValue',
  MinPageviews = 'minPageviews',
  MinProductOfSavingsKibAndPageviews = 'minProductOfSavingsKibAndPageviews',
  MinProductOfSavingsSecsAndPageviews = 'minProductOfSavingsSecsAndPageviews',
  MinSavingsKib = 'minSavingsKib',
  MinSavingsScore = 'minSavingsScore',
  MinSavingsSecs = 'minSavingsSecs',
  MinScore = 'minScore',
  MinTotalSizeKib = 'minTotalSizeKib',
  SumGscClicks = 'sumGscClicks',
  SumItemsCount = 'sumItemsCount',
  SumMetricSavingsCls = 'sumMetricSavingsCls',
  SumMetricSavingsFcp = 'sumMetricSavingsFcp',
  SumMetricSavingsInp = 'sumMetricSavingsInp',
  SumMetricSavingsLcp = 'sumMetricSavingsLcp',
  SumMetricSavingsTbt = 'sumMetricSavingsTbt',
  SumNumericValue = 'sumNumericValue',
  SumPageviews = 'sumPageviews',
  SumProductOfSavingsKibAndPageviews = 'sumProductOfSavingsKibAndPageviews',
  SumProductOfSavingsSecsAndPageviews = 'sumProductOfSavingsSecsAndPageviews',
  SumSavingsKib = 'sumSavingsKib',
  SumSavingsScore = 'sumSavingsScore',
  SumSavingsSecs = 'sumSavingsSecs',
  SumScore = 'sumScore',
  SumTotalSizeKib = 'sumTotalSizeKib',
  Title = 'title'
}

export type CrawlSiteSpeedAuditAvgCalculation = {
  gscClicks?: Maybe<Scalars['Float']['output']>;
  itemsCount?: Maybe<Scalars['Float']['output']>;
  metricSavingsCls?: Maybe<Scalars['Float']['output']>;
  metricSavingsFcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsInp?: Maybe<Scalars['Float']['output']>;
  metricSavingsLcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsTbt?: Maybe<Scalars['Float']['output']>;
  numericValue?: Maybe<Scalars['Float']['output']>;
  pageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalSizeKib?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSiteSpeedAuditConnection = {
  /** A list of edges. */
  edges: Array<CrawlSiteSpeedAuditEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlSiteSpeedAudit>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlSiteSpeedAudits in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlSiteSpeedAuditConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlSiteSpeedAuditConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlSiteSpeedAuditConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlSiteSpeedAuditConnectionFilterInput>>;
  auditDigest?: InputMaybe<ConnectionStringFilterInput>;
  auditId?: InputMaybe<ConnectionStringFilterInput>;
  auditResult?: InputMaybe<ConnectionStringFilterInput>;
  description?: InputMaybe<ConnectionStringFilterInput>;
  displayValue?: InputMaybe<ConnectionStringFilterInput>;
  exampleItems?: InputMaybe<ConnectionStringFilterInput>;
  gscClicks?: InputMaybe<ConnectionIntFilterInput>;
  guidanceLevel?: InputMaybe<ConnectionIntFilterInput>;
  itemsCount?: InputMaybe<ConnectionIntFilterInput>;
  metricSavingsCls?: InputMaybe<ConnectionFloatFilterInput>;
  metricSavingsFcp?: InputMaybe<ConnectionFloatFilterInput>;
  metricSavingsInp?: InputMaybe<ConnectionFloatFilterInput>;
  metricSavingsLcp?: InputMaybe<ConnectionFloatFilterInput>;
  metricSavingsTbt?: InputMaybe<ConnectionFloatFilterInput>;
  numericUnit?: InputMaybe<ConnectionStringFilterInput>;
  numericValue?: InputMaybe<ConnectionFloatFilterInput>;
  pageviews?: InputMaybe<ConnectionBigIntFilterInput>;
  productOfSavingsKibAndPageviews?: InputMaybe<ConnectionFloatFilterInput>;
  productOfSavingsSecsAndPageviews?: InputMaybe<ConnectionFloatFilterInput>;
  redirectsChainCount?: InputMaybe<ConnectionIntFilterInput>;
  savingsKib?: InputMaybe<ConnectionFloatFilterInput>;
  savingsScore?: InputMaybe<ConnectionFloatFilterInput>;
  savingsSecs?: InputMaybe<ConnectionFloatFilterInput>;
  score?: InputMaybe<ConnectionFloatFilterInput>;
  scoreDisplayMode?: InputMaybe<ConnectionStringFilterInput>;
  scoringOptionsMedian?: InputMaybe<ConnectionFloatFilterInput>;
  scoringOptionsPercentile?: InputMaybe<ConnectionFloatFilterInput>;
  segmentIds?: InputMaybe<ConnectionIntArrayFilterInput>;
  segmentIdsCount?: InputMaybe<ConnectionIntFilterInput>;
  segmentNames?: InputMaybe<ConnectionStringArrayFilterInput>;
  segmentNamesCount?: InputMaybe<ConnectionIntFilterInput>;
  title?: InputMaybe<ConnectionStringFilterInput>;
  totalSizeKib?: InputMaybe<ConnectionFloatFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
  urlDigest?: InputMaybe<ConnectionStringFilterInput>;
  warnings?: InputMaybe<ConnectionStringFilterInput>;
};

export type CrawlSiteSpeedAuditCountCalculation = {
  gscClicks: Scalars['Int']['output'];
  itemsCount: Scalars['Int']['output'];
  metricSavingsCls: Scalars['Int']['output'];
  metricSavingsFcp: Scalars['Int']['output'];
  metricSavingsInp: Scalars['Int']['output'];
  metricSavingsLcp: Scalars['Int']['output'];
  metricSavingsTbt: Scalars['Int']['output'];
  numericValue: Scalars['Int']['output'];
  pageviews: Scalars['Int']['output'];
  productOfSavingsKibAndPageviews: Scalars['Int']['output'];
  productOfSavingsSecsAndPageviews: Scalars['Int']['output'];
  savingsKib: Scalars['Int']['output'];
  savingsScore: Scalars['Int']['output'];
  savingsSecs: Scalars['Int']['output'];
  score: Scalars['Int']['output'];
  totalSizeKib: Scalars['Int']['output'];
};

export type CrawlSiteSpeedAuditEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlSiteSpeedAudit;
};

export type CrawlSiteSpeedAuditItem = {
  auditId?: Maybe<Scalars['String']['output']>;
  auditItemDigest: Scalars['String']['output'];
  auditResult?: Maybe<Scalars['String']['output']>;
  auditScore?: Maybe<Scalars['Float']['output']>;
  blockingTime?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecs?: Maybe<Scalars['Float']['output']>;
  cacheHitProbability?: Maybe<Scalars['Float']['output']>;
  cacheLifetimeMs?: Maybe<Scalars['BigInt']['output']>;
  debugDataMaxAge?: Maybe<Scalars['Int']['output']>;
  debugDataType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayValue?: Maybe<Scalars['String']['output']>;
  durationMs?: Maybe<Scalars['Int']['output']>;
  element: Scalars['String']['output'];
  entity?: Maybe<Scalars['String']['output']>;
  failureType?: Maybe<Scalars['String']['output']>;
  fromProtocol?: Maybe<Scalars['Boolean']['output']>;
  gscClicks?: Maybe<Scalars['Int']['output']>;
  hostname?: Maybe<Scalars['String']['output']>;
  isCrossOrigin?: Maybe<Scalars['Boolean']['output']>;
  itemIndex?: Maybe<Scalars['Int']['output']>;
  layoutShiftImpact?: Maybe<Scalars['Float']['output']>;
  nodeBoundingRect?: Maybe<Scalars['String']['output']>;
  nodeNodeLabel?: Maybe<Scalars['String']['output']>;
  nodePath?: Maybe<Scalars['String']['output']>;
  nodeSelector?: Maybe<Scalars['String']['output']>;
  nodeSnippet?: Maybe<Scalars['String']['output']>;
  opportunityDigest: Scalars['String']['output'];
  pageviews?: Maybe<Scalars['BigInt']['output']>;
  productOfWastedKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfWastedSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  protocol?: Maybe<Scalars['String']['output']>;
  protocolReason?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  requestStartTime?: Maybe<Scalars['Float']['output']>;
  resourceUrl?: Maybe<Scalars['String']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  scriptEvaluationTimeMs?: Maybe<Scalars['Int']['output']>;
  scriptParseTimeMs?: Maybe<Scalars['Int']['output']>;
  segmentIds?: Maybe<Array<Scalars['Int']['output']>>;
  segmentIdsCount?: Maybe<Scalars['Int']['output']>;
  segmentNames?: Maybe<Array<Scalars['String']['output']>>;
  segmentNamesCount?: Maybe<Scalars['Int']['output']>;
  sourceColumn?: Maybe<Scalars['Int']['output']>;
  sourceLine?: Maybe<Scalars['Int']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  statistic?: Maybe<Scalars['String']['output']>;
  statisticValue?: Maybe<Scalars['Int']['output']>;
  subItems?: Maybe<Scalars['String']['output']>;
  subItemsCount?: Maybe<Scalars['Int']['output']>;
  tbtImpact?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalBytes?: Maybe<Scalars['BigInt']['output']>;
  totalCpuTimeMs?: Maybe<Scalars['Int']['output']>;
  totalSizeKib?: Maybe<Scalars['Float']['output']>;
  transferSize?: Maybe<Scalars['BigInt']['output']>;
  url: Scalars['String']['output'];
  urlDigest: Scalars['String']['output'];
  wastedKib?: Maybe<Scalars['Float']['output']>;
  wastedPercent?: Maybe<Scalars['Float']['output']>;
  wastedSecs?: Maybe<Scalars['Float']['output']>;
  wastedWebpBytes?: Maybe<Scalars['BigInt']['output']>;
};

export type CrawlSiteSpeedAuditItemConnection = {
  /** A list of edges. */
  edges: Array<CrawlSiteSpeedAuditItemEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlSiteSpeedAuditItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlSiteSpeedAuditItems in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlSiteSpeedAuditItemConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlSiteSpeedAuditItemConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlSiteSpeedAuditItemConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlSiteSpeedAuditItemConnectionFilterInput>>;
  auditId?: InputMaybe<ConnectionStringFilterInput>;
  auditItemDigest?: InputMaybe<ConnectionStringFilterInput>;
  auditResult?: InputMaybe<ConnectionStringFilterInput>;
  auditScore?: InputMaybe<ConnectionFloatFilterInput>;
  blockingTime?: InputMaybe<ConnectionFloatFilterInput>;
  blockingTimeSecs?: InputMaybe<ConnectionFloatFilterInput>;
  cacheHitProbability?: InputMaybe<ConnectionFloatFilterInput>;
  cacheLifetimeMs?: InputMaybe<ConnectionBigIntFilterInput>;
  debugDataMaxAge?: InputMaybe<ConnectionIntFilterInput>;
  debugDataType?: InputMaybe<ConnectionStringFilterInput>;
  description?: InputMaybe<ConnectionStringFilterInput>;
  displayValue?: InputMaybe<ConnectionStringFilterInput>;
  durationMs?: InputMaybe<ConnectionIntFilterInput>;
  element?: InputMaybe<ConnectionStringFilterInput>;
  failureType?: InputMaybe<ConnectionStringFilterInput>;
  fromProtocol?: InputMaybe<ConnectionBooleanFilterInput>;
  gscClicks?: InputMaybe<ConnectionIntFilterInput>;
  hostname?: InputMaybe<ConnectionStringFilterInput>;
  isCrossOrigin?: InputMaybe<ConnectionBooleanFilterInput>;
  itemIndex?: InputMaybe<ConnectionIntFilterInput>;
  layoutShiftImpact?: InputMaybe<ConnectionFloatFilterInput>;
  nodeBoundingRect?: InputMaybe<ConnectionStringFilterInput>;
  nodeNodeLabel?: InputMaybe<ConnectionStringFilterInput>;
  nodePath?: InputMaybe<ConnectionStringFilterInput>;
  nodeSelector?: InputMaybe<ConnectionStringFilterInput>;
  nodeSnippet?: InputMaybe<ConnectionStringFilterInput>;
  opportunityDigest?: InputMaybe<ConnectionStringFilterInput>;
  pageviews?: InputMaybe<ConnectionBigIntFilterInput>;
  productOfWastedKibAndPageviews?: InputMaybe<ConnectionFloatFilterInput>;
  productOfWastedSecsAndPageviews?: InputMaybe<ConnectionFloatFilterInput>;
  protocol?: InputMaybe<ConnectionStringFilterInput>;
  protocolReason?: InputMaybe<ConnectionStringFilterInput>;
  reason?: InputMaybe<ConnectionStringFilterInput>;
  requestStartTime?: InputMaybe<ConnectionFloatFilterInput>;
  resourceUrl?: InputMaybe<ConnectionStringFilterInput>;
  savingsScore?: InputMaybe<ConnectionFloatFilterInput>;
  scriptEvaluationTimeMs?: InputMaybe<ConnectionIntFilterInput>;
  scriptParseTimeMs?: InputMaybe<ConnectionIntFilterInput>;
  segmentIds?: InputMaybe<ConnectionIntArrayFilterInput>;
  segmentIdsCount?: InputMaybe<ConnectionIntFilterInput>;
  segmentNames?: InputMaybe<ConnectionStringArrayFilterInput>;
  segmentNamesCount?: InputMaybe<ConnectionIntFilterInput>;
  sourceColumn?: InputMaybe<ConnectionIntFilterInput>;
  sourceLine?: InputMaybe<ConnectionIntFilterInput>;
  sourceUrl?: InputMaybe<ConnectionStringFilterInput>;
  statistic?: InputMaybe<ConnectionStringFilterInput>;
  statisticValue?: InputMaybe<ConnectionIntFilterInput>;
  subItems?: InputMaybe<ConnectionStringFilterInput>;
  subItemsCount?: InputMaybe<ConnectionIntFilterInput>;
  tbtImpact?: InputMaybe<ConnectionFloatFilterInput>;
  title?: InputMaybe<ConnectionStringFilterInput>;
  totalBytes?: InputMaybe<ConnectionBigIntFilterInput>;
  totalCpuTimeMs?: InputMaybe<ConnectionIntFilterInput>;
  totalSizeKib?: InputMaybe<ConnectionFloatFilterInput>;
  transferSize?: InputMaybe<ConnectionBigIntFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
  urlDigest?: InputMaybe<ConnectionStringFilterInput>;
  wastedKib?: InputMaybe<ConnectionFloatFilterInput>;
  wastedPercent?: InputMaybe<ConnectionFloatFilterInput>;
  wastedSecs?: InputMaybe<ConnectionFloatFilterInput>;
  wastedWebpBytes?: InputMaybe<ConnectionBigIntFilterInput>;
};

export type CrawlSiteSpeedAuditItemEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlSiteSpeedAuditItem;
};

export type CrawlSiteSpeedAuditItemOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlSiteSpeedAuditItems by. */
  field: CrawlSiteSpeedAuditItemOrderField;
};

export enum CrawlSiteSpeedAuditItemOrderField {
  AuditId = 'auditId',
  AuditItemDigest = 'auditItemDigest',
  AuditResult = 'auditResult',
  AuditScore = 'auditScore',
  BlockingTime = 'blockingTime',
  BlockingTimeSecs = 'blockingTimeSecs',
  CacheHitProbability = 'cacheHitProbability',
  CacheLifetimeMs = 'cacheLifetimeMs',
  DebugDataMaxAge = 'debugDataMaxAge',
  DebugDataType = 'debugDataType',
  Description = 'description',
  DisplayValue = 'displayValue',
  DurationMs = 'durationMs',
  Element = 'element',
  FailureType = 'failureType',
  FromProtocol = 'fromProtocol',
  GscClicks = 'gscClicks',
  Hostname = 'hostname',
  IsCrossOrigin = 'isCrossOrigin',
  ItemIndex = 'itemIndex',
  LayoutShiftImpact = 'layoutShiftImpact',
  NodeNodeLabel = 'nodeNodeLabel',
  NodePath = 'nodePath',
  NodeSelector = 'nodeSelector',
  NodeSnippet = 'nodeSnippet',
  OpportunityDigest = 'opportunityDigest',
  Pageviews = 'pageviews',
  ProductOfWastedKibAndPageviews = 'productOfWastedKibAndPageviews',
  ProductOfWastedSecsAndPageviews = 'productOfWastedSecsAndPageviews',
  Protocol = 'protocol',
  ProtocolReason = 'protocolReason',
  Reason = 'reason',
  RequestStartTime = 'requestStartTime',
  ResourceUrl = 'resourceUrl',
  SavingsScore = 'savingsScore',
  ScriptEvaluationTimeMs = 'scriptEvaluationTimeMs',
  ScriptParseTimeMs = 'scriptParseTimeMs',
  SegmentIdsCount = 'segmentIdsCount',
  SegmentNamesCount = 'segmentNamesCount',
  SourceColumn = 'sourceColumn',
  SourceLine = 'sourceLine',
  SourceUrl = 'sourceUrl',
  StatisticValue = 'statisticValue',
  SubItemsCount = 'subItemsCount',
  TbtImpact = 'tbtImpact',
  Title = 'title',
  TotalBytes = 'totalBytes',
  TotalCpuTimeMs = 'totalCpuTimeMs',
  TotalSizeKib = 'totalSizeKib',
  TransferSize = 'transferSize',
  Url = 'url',
  UrlDigest = 'urlDigest',
  WastedKib = 'wastedKib',
  WastedPercent = 'wastedPercent',
  WastedSecs = 'wastedSecs',
  WastedWebpBytes = 'wastedWebpBytes'
}

export type CrawlSiteSpeedAuditMaxCalculation = {
  gscClicks?: Maybe<Scalars['Int']['output']>;
  itemsCount?: Maybe<Scalars['Int']['output']>;
  metricSavingsCls?: Maybe<Scalars['Float']['output']>;
  metricSavingsFcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsInp?: Maybe<Scalars['Float']['output']>;
  metricSavingsLcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsTbt?: Maybe<Scalars['Float']['output']>;
  numericValue?: Maybe<Scalars['Float']['output']>;
  pageviews?: Maybe<Scalars['BigInt']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalSizeKib?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSiteSpeedAuditMinCalculation = {
  gscClicks?: Maybe<Scalars['Int']['output']>;
  itemsCount?: Maybe<Scalars['Int']['output']>;
  metricSavingsCls?: Maybe<Scalars['Float']['output']>;
  metricSavingsFcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsInp?: Maybe<Scalars['Float']['output']>;
  metricSavingsLcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsTbt?: Maybe<Scalars['Float']['output']>;
  numericValue?: Maybe<Scalars['Float']['output']>;
  pageviews?: Maybe<Scalars['BigInt']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalSizeKib?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSiteSpeedAuditOpportunity = {
  auditId?: Maybe<Scalars['String']['output']>;
  auditScoreAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsSum?: Maybe<Scalars['Float']['output']>;
  blockingTimeSum?: Maybe<Scalars['Float']['output']>;
  cacheHitProbabilityAvg?: Maybe<Scalars['Float']['output']>;
  cacheLifetimeMsSum?: Maybe<Scalars['BigInt']['output']>;
  debugDataMaxAge?: Maybe<Scalars['Int']['output']>;
  debugDataType?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  element: Scalars['String']['output'];
  exampleUrl: Scalars['String']['output'];
  exampleUrlDigest: Scalars['String']['output'];
  failedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  failureType?: Maybe<Scalars['String']['output']>;
  fromProtocol?: Maybe<Scalars['Boolean']['output']>;
  gaPageviewsSum?: Maybe<Scalars['BigInt']['output']>;
  gscClicksSum?: Maybe<Scalars['BigInt']['output']>;
  hostname?: Maybe<Scalars['String']['output']>;
  infoAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  instanceCount?: Maybe<Scalars['BigInt']['output']>;
  isCrossOrigin?: Maybe<Scalars['Boolean']['output']>;
  layoutShiftImpactAvg?: Maybe<Scalars['Float']['output']>;
  layoutShiftImpactSum?: Maybe<Scalars['Float']['output']>;
  nodeBoundingRect?: Maybe<Scalars['String']['output']>;
  nodeNodeLabel?: Maybe<Scalars['String']['output']>;
  nodePath?: Maybe<Scalars['String']['output']>;
  nodeSelector?: Maybe<Scalars['String']['output']>;
  nodeSnippet?: Maybe<Scalars['String']['output']>;
  opportunityDigest: Scalars['String']['output'];
  passedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  productOfWastedKibAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  productOfWastedSecsAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  protocol?: Maybe<Scalars['String']['output']>;
  protocolReason?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  requestStartTime?: Maybe<Scalars['Float']['output']>;
  savingsScoreSum?: Maybe<Scalars['Float']['output']>;
  segmentIds?: Maybe<Array<Scalars['Int']['output']>>;
  segmentIdsCount?: Maybe<Scalars['Int']['output']>;
  segmentNames?: Maybe<Array<Scalars['String']['output']>>;
  segmentNamesCount?: Maybe<Scalars['Int']['output']>;
  sourceColumn?: Maybe<Scalars['Int']['output']>;
  sourceLine?: Maybe<Scalars['Int']['output']>;
  sourceUrl?: Maybe<Scalars['String']['output']>;
  subItems?: Maybe<Scalars['String']['output']>;
  subItemsCount?: Maybe<Scalars['Float']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalBytesAvg?: Maybe<Scalars['Float']['output']>;
  totalBytesSum?: Maybe<Scalars['BigInt']['output']>;
  totalSizeKibAvg?: Maybe<Scalars['Float']['output']>;
  totalSizeKibSum?: Maybe<Scalars['Float']['output']>;
  transferSizeSum?: Maybe<Scalars['BigInt']['output']>;
  urlCount?: Maybe<Scalars['BigInt']['output']>;
  warningAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  wastedKibAvg?: Maybe<Scalars['Float']['output']>;
  wastedKibSum?: Maybe<Scalars['Float']['output']>;
  wastedPercentAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsSum?: Maybe<Scalars['Float']['output']>;
  wastedWebpBytesSum?: Maybe<Scalars['BigInt']['output']>;
  weightedScore?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSiteSpeedAuditOpportunityAggregate = {
  auditId?: Maybe<Scalars['String']['output']>;
  avg: CrawlSiteSpeedAuditOpportunityAvgCalculation;
  count: CrawlSiteSpeedAuditOpportunityCountCalculation;
  max: CrawlSiteSpeedAuditOpportunityMaxCalculation;
  min: CrawlSiteSpeedAuditOpportunityMinCalculation;
  sum: CrawlSiteSpeedAuditOpportunitySumCalculation;
  title?: Maybe<Scalars['String']['output']>;
};

export type CrawlSiteSpeedAuditOpportunityAggregateConnection = {
  /** A list of nodes. */
  nodes: Array<CrawlSiteSpeedAuditOpportunityAggregate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlSiteSpeedAuditOpportunityAggregates in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** Used for grouping the CrawlSiteSpeedAuditOpportunity aggregates results. */
export enum CrawlSiteSpeedAuditOpportunityAggregateDimension {
  AuditId = 'auditId',
  Title = 'title'
}

export type CrawlSiteSpeedAuditOpportunityAggregateOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The dimension or aggregation to order by. */
  field: CrawlSiteSpeedAuditOpportunityAggregateOrderField;
};

export enum CrawlSiteSpeedAuditOpportunityAggregateOrderField {
  AuditId = 'auditId',
  AvgAuditScoreAvg = 'avgAuditScoreAvg',
  AvgBlockingTimeSecsAvg = 'avgBlockingTimeSecsAvg',
  AvgBlockingTimeSecsSum = 'avgBlockingTimeSecsSum',
  AvgBlockingTimeSum = 'avgBlockingTimeSum',
  AvgCacheHitProbabilityAvg = 'avgCacheHitProbabilityAvg',
  AvgCacheLifetimeMsSum = 'avgCacheLifetimeMsSum',
  AvgDebugDataMaxAge = 'avgDebugDataMaxAge',
  AvgFailedAuditsCount = 'avgFailedAuditsCount',
  AvgGaPageviewsSum = 'avgGaPageviewsSum',
  AvgGscClicksSum = 'avgGscClicksSum',
  AvgInfoAuditsCount = 'avgInfoAuditsCount',
  AvgInstanceCount = 'avgInstanceCount',
  AvgLayoutShiftImpactAvg = 'avgLayoutShiftImpactAvg',
  AvgLayoutShiftImpactSum = 'avgLayoutShiftImpactSum',
  AvgPassedAuditsCount = 'avgPassedAuditsCount',
  AvgProductOfWastedKibAndPageviewsSum = 'avgProductOfWastedKibAndPageviewsSum',
  AvgProductOfWastedSecsAndPageviewsSum = 'avgProductOfWastedSecsAndPageviewsSum',
  AvgSavingsScoreSum = 'avgSavingsScoreSum',
  AvgSubItemsCount = 'avgSubItemsCount',
  AvgTotalBytesAvg = 'avgTotalBytesAvg',
  AvgTotalBytesSum = 'avgTotalBytesSum',
  AvgTotalSizeKibAvg = 'avgTotalSizeKibAvg',
  AvgTotalSizeKibSum = 'avgTotalSizeKibSum',
  AvgTransferSizeSum = 'avgTransferSizeSum',
  AvgUrlCount = 'avgUrlCount',
  AvgWarningAuditsCount = 'avgWarningAuditsCount',
  AvgWastedKibAvg = 'avgWastedKibAvg',
  AvgWastedKibSum = 'avgWastedKibSum',
  AvgWastedPercentAvg = 'avgWastedPercentAvg',
  AvgWastedSecsAvg = 'avgWastedSecsAvg',
  AvgWastedSecsSum = 'avgWastedSecsSum',
  AvgWastedWebpBytesSum = 'avgWastedWebpBytesSum',
  AvgWeightedScore = 'avgWeightedScore',
  CountAuditScoreAvg = 'countAuditScoreAvg',
  CountBlockingTimeSecsAvg = 'countBlockingTimeSecsAvg',
  CountBlockingTimeSecsSum = 'countBlockingTimeSecsSum',
  CountBlockingTimeSum = 'countBlockingTimeSum',
  CountCacheHitProbabilityAvg = 'countCacheHitProbabilityAvg',
  CountCacheLifetimeMsSum = 'countCacheLifetimeMsSum',
  CountDebugDataMaxAge = 'countDebugDataMaxAge',
  CountFailedAuditsCount = 'countFailedAuditsCount',
  CountGaPageviewsSum = 'countGaPageviewsSum',
  CountGscClicksSum = 'countGscClicksSum',
  CountInfoAuditsCount = 'countInfoAuditsCount',
  CountInstanceCount = 'countInstanceCount',
  CountLayoutShiftImpactAvg = 'countLayoutShiftImpactAvg',
  CountLayoutShiftImpactSum = 'countLayoutShiftImpactSum',
  CountPassedAuditsCount = 'countPassedAuditsCount',
  CountProductOfWastedKibAndPageviewsSum = 'countProductOfWastedKibAndPageviewsSum',
  CountProductOfWastedSecsAndPageviewsSum = 'countProductOfWastedSecsAndPageviewsSum',
  CountSavingsScoreSum = 'countSavingsScoreSum',
  CountSubItemsCount = 'countSubItemsCount',
  CountTotalBytesAvg = 'countTotalBytesAvg',
  CountTotalBytesSum = 'countTotalBytesSum',
  CountTotalSizeKibAvg = 'countTotalSizeKibAvg',
  CountTotalSizeKibSum = 'countTotalSizeKibSum',
  CountTransferSizeSum = 'countTransferSizeSum',
  CountUrlCount = 'countUrlCount',
  CountWarningAuditsCount = 'countWarningAuditsCount',
  CountWastedKibAvg = 'countWastedKibAvg',
  CountWastedKibSum = 'countWastedKibSum',
  CountWastedPercentAvg = 'countWastedPercentAvg',
  CountWastedSecsAvg = 'countWastedSecsAvg',
  CountWastedSecsSum = 'countWastedSecsSum',
  CountWastedWebpBytesSum = 'countWastedWebpBytesSum',
  CountWeightedScore = 'countWeightedScore',
  MaxAuditScoreAvg = 'maxAuditScoreAvg',
  MaxBlockingTimeSecsAvg = 'maxBlockingTimeSecsAvg',
  MaxBlockingTimeSecsSum = 'maxBlockingTimeSecsSum',
  MaxBlockingTimeSum = 'maxBlockingTimeSum',
  MaxCacheHitProbabilityAvg = 'maxCacheHitProbabilityAvg',
  MaxCacheLifetimeMsSum = 'maxCacheLifetimeMsSum',
  MaxDebugDataMaxAge = 'maxDebugDataMaxAge',
  MaxFailedAuditsCount = 'maxFailedAuditsCount',
  MaxGaPageviewsSum = 'maxGaPageviewsSum',
  MaxGscClicksSum = 'maxGscClicksSum',
  MaxInfoAuditsCount = 'maxInfoAuditsCount',
  MaxInstanceCount = 'maxInstanceCount',
  MaxLayoutShiftImpactAvg = 'maxLayoutShiftImpactAvg',
  MaxLayoutShiftImpactSum = 'maxLayoutShiftImpactSum',
  MaxPassedAuditsCount = 'maxPassedAuditsCount',
  MaxProductOfWastedKibAndPageviewsSum = 'maxProductOfWastedKibAndPageviewsSum',
  MaxProductOfWastedSecsAndPageviewsSum = 'maxProductOfWastedSecsAndPageviewsSum',
  MaxSavingsScoreSum = 'maxSavingsScoreSum',
  MaxSubItemsCount = 'maxSubItemsCount',
  MaxTotalBytesAvg = 'maxTotalBytesAvg',
  MaxTotalBytesSum = 'maxTotalBytesSum',
  MaxTotalSizeKibAvg = 'maxTotalSizeKibAvg',
  MaxTotalSizeKibSum = 'maxTotalSizeKibSum',
  MaxTransferSizeSum = 'maxTransferSizeSum',
  MaxUrlCount = 'maxUrlCount',
  MaxWarningAuditsCount = 'maxWarningAuditsCount',
  MaxWastedKibAvg = 'maxWastedKibAvg',
  MaxWastedKibSum = 'maxWastedKibSum',
  MaxWastedPercentAvg = 'maxWastedPercentAvg',
  MaxWastedSecsAvg = 'maxWastedSecsAvg',
  MaxWastedSecsSum = 'maxWastedSecsSum',
  MaxWastedWebpBytesSum = 'maxWastedWebpBytesSum',
  MaxWeightedScore = 'maxWeightedScore',
  MinAuditScoreAvg = 'minAuditScoreAvg',
  MinBlockingTimeSecsAvg = 'minBlockingTimeSecsAvg',
  MinBlockingTimeSecsSum = 'minBlockingTimeSecsSum',
  MinBlockingTimeSum = 'minBlockingTimeSum',
  MinCacheHitProbabilityAvg = 'minCacheHitProbabilityAvg',
  MinCacheLifetimeMsSum = 'minCacheLifetimeMsSum',
  MinDebugDataMaxAge = 'minDebugDataMaxAge',
  MinFailedAuditsCount = 'minFailedAuditsCount',
  MinGaPageviewsSum = 'minGaPageviewsSum',
  MinGscClicksSum = 'minGscClicksSum',
  MinInfoAuditsCount = 'minInfoAuditsCount',
  MinInstanceCount = 'minInstanceCount',
  MinLayoutShiftImpactAvg = 'minLayoutShiftImpactAvg',
  MinLayoutShiftImpactSum = 'minLayoutShiftImpactSum',
  MinPassedAuditsCount = 'minPassedAuditsCount',
  MinProductOfWastedKibAndPageviewsSum = 'minProductOfWastedKibAndPageviewsSum',
  MinProductOfWastedSecsAndPageviewsSum = 'minProductOfWastedSecsAndPageviewsSum',
  MinSavingsScoreSum = 'minSavingsScoreSum',
  MinSubItemsCount = 'minSubItemsCount',
  MinTotalBytesAvg = 'minTotalBytesAvg',
  MinTotalBytesSum = 'minTotalBytesSum',
  MinTotalSizeKibAvg = 'minTotalSizeKibAvg',
  MinTotalSizeKibSum = 'minTotalSizeKibSum',
  MinTransferSizeSum = 'minTransferSizeSum',
  MinUrlCount = 'minUrlCount',
  MinWarningAuditsCount = 'minWarningAuditsCount',
  MinWastedKibAvg = 'minWastedKibAvg',
  MinWastedKibSum = 'minWastedKibSum',
  MinWastedPercentAvg = 'minWastedPercentAvg',
  MinWastedSecsAvg = 'minWastedSecsAvg',
  MinWastedSecsSum = 'minWastedSecsSum',
  MinWastedWebpBytesSum = 'minWastedWebpBytesSum',
  MinWeightedScore = 'minWeightedScore',
  SumAuditScoreAvg = 'sumAuditScoreAvg',
  SumBlockingTimeSecsAvg = 'sumBlockingTimeSecsAvg',
  SumBlockingTimeSecsSum = 'sumBlockingTimeSecsSum',
  SumBlockingTimeSum = 'sumBlockingTimeSum',
  SumCacheHitProbabilityAvg = 'sumCacheHitProbabilityAvg',
  SumCacheLifetimeMsSum = 'sumCacheLifetimeMsSum',
  SumDebugDataMaxAge = 'sumDebugDataMaxAge',
  SumFailedAuditsCount = 'sumFailedAuditsCount',
  SumGaPageviewsSum = 'sumGaPageviewsSum',
  SumGscClicksSum = 'sumGscClicksSum',
  SumInfoAuditsCount = 'sumInfoAuditsCount',
  SumInstanceCount = 'sumInstanceCount',
  SumLayoutShiftImpactAvg = 'sumLayoutShiftImpactAvg',
  SumLayoutShiftImpactSum = 'sumLayoutShiftImpactSum',
  SumPassedAuditsCount = 'sumPassedAuditsCount',
  SumProductOfWastedKibAndPageviewsSum = 'sumProductOfWastedKibAndPageviewsSum',
  SumProductOfWastedSecsAndPageviewsSum = 'sumProductOfWastedSecsAndPageviewsSum',
  SumSavingsScoreSum = 'sumSavingsScoreSum',
  SumSubItemsCount = 'sumSubItemsCount',
  SumTotalBytesAvg = 'sumTotalBytesAvg',
  SumTotalBytesSum = 'sumTotalBytesSum',
  SumTotalSizeKibAvg = 'sumTotalSizeKibAvg',
  SumTotalSizeKibSum = 'sumTotalSizeKibSum',
  SumTransferSizeSum = 'sumTransferSizeSum',
  SumUrlCount = 'sumUrlCount',
  SumWarningAuditsCount = 'sumWarningAuditsCount',
  SumWastedKibAvg = 'sumWastedKibAvg',
  SumWastedKibSum = 'sumWastedKibSum',
  SumWastedPercentAvg = 'sumWastedPercentAvg',
  SumWastedSecsAvg = 'sumWastedSecsAvg',
  SumWastedSecsSum = 'sumWastedSecsSum',
  SumWastedWebpBytesSum = 'sumWastedWebpBytesSum',
  SumWeightedScore = 'sumWeightedScore',
  Title = 'title'
}

export type CrawlSiteSpeedAuditOpportunityAvgCalculation = {
  auditScoreAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsSum?: Maybe<Scalars['Float']['output']>;
  blockingTimeSum?: Maybe<Scalars['Float']['output']>;
  cacheHitProbabilityAvg?: Maybe<Scalars['Float']['output']>;
  cacheLifetimeMsSum?: Maybe<Scalars['Float']['output']>;
  debugDataMaxAge?: Maybe<Scalars['Float']['output']>;
  failedAuditsCount?: Maybe<Scalars['Float']['output']>;
  gaPageviewsSum?: Maybe<Scalars['Float']['output']>;
  gscClicksSum?: Maybe<Scalars['Float']['output']>;
  infoAuditsCount?: Maybe<Scalars['Float']['output']>;
  instanceCount?: Maybe<Scalars['Float']['output']>;
  layoutShiftImpactAvg?: Maybe<Scalars['Float']['output']>;
  layoutShiftImpactSum?: Maybe<Scalars['Float']['output']>;
  passedAuditsCount?: Maybe<Scalars['Float']['output']>;
  productOfWastedKibAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  productOfWastedSecsAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  savingsScoreSum?: Maybe<Scalars['Float']['output']>;
  subItemsCount?: Maybe<Scalars['Float']['output']>;
  totalBytesAvg?: Maybe<Scalars['Float']['output']>;
  totalBytesSum?: Maybe<Scalars['Float']['output']>;
  totalSizeKibAvg?: Maybe<Scalars['Float']['output']>;
  totalSizeKibSum?: Maybe<Scalars['Float']['output']>;
  transferSizeSum?: Maybe<Scalars['Float']['output']>;
  urlCount?: Maybe<Scalars['Float']['output']>;
  warningAuditsCount?: Maybe<Scalars['Float']['output']>;
  wastedKibAvg?: Maybe<Scalars['Float']['output']>;
  wastedKibSum?: Maybe<Scalars['Float']['output']>;
  wastedPercentAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsSum?: Maybe<Scalars['Float']['output']>;
  wastedWebpBytesSum?: Maybe<Scalars['Float']['output']>;
  weightedScore?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSiteSpeedAuditOpportunityConnection = {
  /** A list of edges. */
  edges: Array<CrawlSiteSpeedAuditOpportunityEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlSiteSpeedAuditOpportunity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlSiteSpeedAuditOpportunities in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlSiteSpeedAuditOpportunityConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>>;
  auditId?: InputMaybe<ConnectionStringFilterInput>;
  auditScoreAvg?: InputMaybe<ConnectionFloatFilterInput>;
  blockingTimeSecsAvg?: InputMaybe<ConnectionFloatFilterInput>;
  blockingTimeSecsSum?: InputMaybe<ConnectionFloatFilterInput>;
  blockingTimeSum?: InputMaybe<ConnectionFloatFilterInput>;
  cacheHitProbabilityAvg?: InputMaybe<ConnectionFloatFilterInput>;
  cacheLifetimeMsSum?: InputMaybe<ConnectionBigIntFilterInput>;
  debugDataMaxAge?: InputMaybe<ConnectionIntFilterInput>;
  debugDataType?: InputMaybe<ConnectionStringFilterInput>;
  description?: InputMaybe<ConnectionStringFilterInput>;
  element?: InputMaybe<ConnectionStringFilterInput>;
  exampleUrl?: InputMaybe<ConnectionStringFilterInput>;
  exampleUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  failedAuditsCount?: InputMaybe<ConnectionBigIntFilterInput>;
  failureType?: InputMaybe<ConnectionStringFilterInput>;
  fromProtocol?: InputMaybe<ConnectionBooleanFilterInput>;
  gaPageviewsSum?: InputMaybe<ConnectionBigIntFilterInput>;
  gscClicksSum?: InputMaybe<ConnectionBigIntFilterInput>;
  hostname?: InputMaybe<ConnectionStringFilterInput>;
  infoAuditsCount?: InputMaybe<ConnectionBigIntFilterInput>;
  instanceCount?: InputMaybe<ConnectionBigIntFilterInput>;
  isCrossOrigin?: InputMaybe<ConnectionBooleanFilterInput>;
  layoutShiftImpactAvg?: InputMaybe<ConnectionFloatFilterInput>;
  layoutShiftImpactSum?: InputMaybe<ConnectionFloatFilterInput>;
  nodeBoundingRect?: InputMaybe<ConnectionStringFilterInput>;
  nodeNodeLabel?: InputMaybe<ConnectionStringFilterInput>;
  nodePath?: InputMaybe<ConnectionStringFilterInput>;
  nodeSelector?: InputMaybe<ConnectionStringFilterInput>;
  nodeSnippet?: InputMaybe<ConnectionStringFilterInput>;
  opportunityDigest?: InputMaybe<ConnectionStringFilterInput>;
  passedAuditsCount?: InputMaybe<ConnectionBigIntFilterInput>;
  productOfWastedKibAndPageviewsSum?: InputMaybe<ConnectionFloatFilterInput>;
  productOfWastedSecsAndPageviewsSum?: InputMaybe<ConnectionFloatFilterInput>;
  protocol?: InputMaybe<ConnectionStringFilterInput>;
  protocolReason?: InputMaybe<ConnectionStringFilterInput>;
  reason?: InputMaybe<ConnectionStringFilterInput>;
  requestStartTime?: InputMaybe<ConnectionFloatFilterInput>;
  savingsScoreSum?: InputMaybe<ConnectionFloatFilterInput>;
  segmentIds?: InputMaybe<ConnectionIntArrayFilterInput>;
  segmentIdsCount?: InputMaybe<ConnectionIntFilterInput>;
  segmentNames?: InputMaybe<ConnectionStringArrayFilterInput>;
  segmentNamesCount?: InputMaybe<ConnectionIntFilterInput>;
  sourceColumn?: InputMaybe<ConnectionIntFilterInput>;
  sourceLine?: InputMaybe<ConnectionIntFilterInput>;
  sourceUrl?: InputMaybe<ConnectionStringFilterInput>;
  subItems?: InputMaybe<ConnectionStringFilterInput>;
  subItemsCount?: InputMaybe<ConnectionFloatFilterInput>;
  title?: InputMaybe<ConnectionStringFilterInput>;
  totalBytesAvg?: InputMaybe<ConnectionFloatFilterInput>;
  totalBytesSum?: InputMaybe<ConnectionBigIntFilterInput>;
  totalSizeKibAvg?: InputMaybe<ConnectionFloatFilterInput>;
  totalSizeKibSum?: InputMaybe<ConnectionFloatFilterInput>;
  transferSizeSum?: InputMaybe<ConnectionBigIntFilterInput>;
  urlCount?: InputMaybe<ConnectionBigIntFilterInput>;
  warningAuditsCount?: InputMaybe<ConnectionBigIntFilterInput>;
  wastedKibAvg?: InputMaybe<ConnectionFloatFilterInput>;
  wastedKibSum?: InputMaybe<ConnectionFloatFilterInput>;
  wastedPercentAvg?: InputMaybe<ConnectionFloatFilterInput>;
  wastedSecsAvg?: InputMaybe<ConnectionFloatFilterInput>;
  wastedSecsSum?: InputMaybe<ConnectionFloatFilterInput>;
  wastedWebpBytesSum?: InputMaybe<ConnectionBigIntFilterInput>;
  weightedScore?: InputMaybe<ConnectionFloatFilterInput>;
};

export type CrawlSiteSpeedAuditOpportunityCountCalculation = {
  auditScoreAvg: Scalars['Int']['output'];
  blockingTimeSecsAvg: Scalars['Int']['output'];
  blockingTimeSecsSum: Scalars['Int']['output'];
  blockingTimeSum: Scalars['Int']['output'];
  cacheHitProbabilityAvg: Scalars['Int']['output'];
  cacheLifetimeMsSum: Scalars['Int']['output'];
  debugDataMaxAge: Scalars['Int']['output'];
  failedAuditsCount: Scalars['Int']['output'];
  gaPageviewsSum: Scalars['Int']['output'];
  gscClicksSum: Scalars['Int']['output'];
  infoAuditsCount: Scalars['Int']['output'];
  instanceCount: Scalars['Int']['output'];
  layoutShiftImpactAvg: Scalars['Int']['output'];
  layoutShiftImpactSum: Scalars['Int']['output'];
  passedAuditsCount: Scalars['Int']['output'];
  productOfWastedKibAndPageviewsSum: Scalars['Int']['output'];
  productOfWastedSecsAndPageviewsSum: Scalars['Int']['output'];
  savingsScoreSum: Scalars['Int']['output'];
  subItemsCount: Scalars['Int']['output'];
  totalBytesAvg: Scalars['Int']['output'];
  totalBytesSum: Scalars['Int']['output'];
  totalSizeKibAvg: Scalars['Int']['output'];
  totalSizeKibSum: Scalars['Int']['output'];
  transferSizeSum: Scalars['Int']['output'];
  urlCount: Scalars['Int']['output'];
  warningAuditsCount: Scalars['Int']['output'];
  wastedKibAvg: Scalars['Int']['output'];
  wastedKibSum: Scalars['Int']['output'];
  wastedPercentAvg: Scalars['Int']['output'];
  wastedSecsAvg: Scalars['Int']['output'];
  wastedSecsSum: Scalars['Int']['output'];
  wastedWebpBytesSum: Scalars['Int']['output'];
  weightedScore: Scalars['Int']['output'];
};

export type CrawlSiteSpeedAuditOpportunityEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlSiteSpeedAuditOpportunity;
};

export type CrawlSiteSpeedAuditOpportunityMaxCalculation = {
  auditScoreAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsSum?: Maybe<Scalars['Float']['output']>;
  blockingTimeSum?: Maybe<Scalars['Float']['output']>;
  cacheHitProbabilityAvg?: Maybe<Scalars['Float']['output']>;
  cacheLifetimeMsSum?: Maybe<Scalars['BigInt']['output']>;
  debugDataMaxAge?: Maybe<Scalars['Int']['output']>;
  failedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  gaPageviewsSum?: Maybe<Scalars['BigInt']['output']>;
  gscClicksSum?: Maybe<Scalars['BigInt']['output']>;
  infoAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  instanceCount?: Maybe<Scalars['BigInt']['output']>;
  layoutShiftImpactAvg?: Maybe<Scalars['Float']['output']>;
  layoutShiftImpactSum?: Maybe<Scalars['Float']['output']>;
  passedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  productOfWastedKibAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  productOfWastedSecsAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  savingsScoreSum?: Maybe<Scalars['Float']['output']>;
  subItemsCount?: Maybe<Scalars['Float']['output']>;
  totalBytesAvg?: Maybe<Scalars['Float']['output']>;
  totalBytesSum?: Maybe<Scalars['BigInt']['output']>;
  totalSizeKibAvg?: Maybe<Scalars['Float']['output']>;
  totalSizeKibSum?: Maybe<Scalars['Float']['output']>;
  transferSizeSum?: Maybe<Scalars['BigInt']['output']>;
  urlCount?: Maybe<Scalars['BigInt']['output']>;
  warningAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  wastedKibAvg?: Maybe<Scalars['Float']['output']>;
  wastedKibSum?: Maybe<Scalars['Float']['output']>;
  wastedPercentAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsSum?: Maybe<Scalars['Float']['output']>;
  wastedWebpBytesSum?: Maybe<Scalars['BigInt']['output']>;
  weightedScore?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSiteSpeedAuditOpportunityMinCalculation = {
  auditScoreAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsSum?: Maybe<Scalars['Float']['output']>;
  blockingTimeSum?: Maybe<Scalars['Float']['output']>;
  cacheHitProbabilityAvg?: Maybe<Scalars['Float']['output']>;
  cacheLifetimeMsSum?: Maybe<Scalars['BigInt']['output']>;
  debugDataMaxAge?: Maybe<Scalars['Int']['output']>;
  failedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  gaPageviewsSum?: Maybe<Scalars['BigInt']['output']>;
  gscClicksSum?: Maybe<Scalars['BigInt']['output']>;
  infoAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  instanceCount?: Maybe<Scalars['BigInt']['output']>;
  layoutShiftImpactAvg?: Maybe<Scalars['Float']['output']>;
  layoutShiftImpactSum?: Maybe<Scalars['Float']['output']>;
  passedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  productOfWastedKibAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  productOfWastedSecsAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  savingsScoreSum?: Maybe<Scalars['Float']['output']>;
  subItemsCount?: Maybe<Scalars['Float']['output']>;
  totalBytesAvg?: Maybe<Scalars['Float']['output']>;
  totalBytesSum?: Maybe<Scalars['BigInt']['output']>;
  totalSizeKibAvg?: Maybe<Scalars['Float']['output']>;
  totalSizeKibSum?: Maybe<Scalars['Float']['output']>;
  transferSizeSum?: Maybe<Scalars['BigInt']['output']>;
  urlCount?: Maybe<Scalars['BigInt']['output']>;
  warningAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  wastedKibAvg?: Maybe<Scalars['Float']['output']>;
  wastedKibSum?: Maybe<Scalars['Float']['output']>;
  wastedPercentAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsSum?: Maybe<Scalars['Float']['output']>;
  wastedWebpBytesSum?: Maybe<Scalars['BigInt']['output']>;
  weightedScore?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSiteSpeedAuditOpportunityOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlSiteSpeedAuditOpportunities by. */
  field: CrawlSiteSpeedAuditOpportunityOrderField;
};

export enum CrawlSiteSpeedAuditOpportunityOrderField {
  AuditId = 'auditId',
  AuditScoreAvg = 'auditScoreAvg',
  BlockingTimeSecsAvg = 'blockingTimeSecsAvg',
  BlockingTimeSecsSum = 'blockingTimeSecsSum',
  BlockingTimeSum = 'blockingTimeSum',
  CacheHitProbabilityAvg = 'cacheHitProbabilityAvg',
  CacheLifetimeMsSum = 'cacheLifetimeMsSum',
  DebugDataMaxAge = 'debugDataMaxAge',
  DebugDataType = 'debugDataType',
  Description = 'description',
  Element = 'element',
  ExampleUrl = 'exampleUrl',
  ExampleUrlDigest = 'exampleUrlDigest',
  FailedAuditsCount = 'failedAuditsCount',
  FailureType = 'failureType',
  FromProtocol = 'fromProtocol',
  GaPageviewsSum = 'gaPageviewsSum',
  GscClicksSum = 'gscClicksSum',
  Hostname = 'hostname',
  InfoAuditsCount = 'infoAuditsCount',
  InstanceCount = 'instanceCount',
  IsCrossOrigin = 'isCrossOrigin',
  LayoutShiftImpactAvg = 'layoutShiftImpactAvg',
  LayoutShiftImpactSum = 'layoutShiftImpactSum',
  NodeNodeLabel = 'nodeNodeLabel',
  NodePath = 'nodePath',
  NodeSelector = 'nodeSelector',
  NodeSnippet = 'nodeSnippet',
  OpportunityDigest = 'opportunityDigest',
  PassedAuditsCount = 'passedAuditsCount',
  ProductOfWastedKibAndPageviewsSum = 'productOfWastedKibAndPageviewsSum',
  ProductOfWastedSecsAndPageviewsSum = 'productOfWastedSecsAndPageviewsSum',
  Protocol = 'protocol',
  ProtocolReason = 'protocolReason',
  Reason = 'reason',
  RequestStartTime = 'requestStartTime',
  SavingsScoreSum = 'savingsScoreSum',
  SegmentIdsCount = 'segmentIdsCount',
  SegmentNamesCount = 'segmentNamesCount',
  SourceColumn = 'sourceColumn',
  SourceLine = 'sourceLine',
  SourceUrl = 'sourceUrl',
  SubItemsCount = 'subItemsCount',
  Title = 'title',
  TotalBytesAvg = 'totalBytesAvg',
  TotalBytesSum = 'totalBytesSum',
  TotalSizeKibAvg = 'totalSizeKibAvg',
  TotalSizeKibSum = 'totalSizeKibSum',
  TransferSizeSum = 'transferSizeSum',
  UrlCount = 'urlCount',
  WarningAuditsCount = 'warningAuditsCount',
  WastedKibAvg = 'wastedKibAvg',
  WastedKibSum = 'wastedKibSum',
  WastedPercentAvg = 'wastedPercentAvg',
  WastedSecsAvg = 'wastedSecsAvg',
  WastedSecsSum = 'wastedSecsSum',
  WastedWebpBytesSum = 'wastedWebpBytesSum',
  WeightedScore = 'weightedScore'
}

export type CrawlSiteSpeedAuditOpportunitySumCalculation = {
  auditScoreAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsAvg?: Maybe<Scalars['Float']['output']>;
  blockingTimeSecsSum?: Maybe<Scalars['Float']['output']>;
  blockingTimeSum?: Maybe<Scalars['Float']['output']>;
  cacheHitProbabilityAvg?: Maybe<Scalars['Float']['output']>;
  cacheLifetimeMsSum?: Maybe<Scalars['BigInt']['output']>;
  debugDataMaxAge?: Maybe<Scalars['BigInt']['output']>;
  failedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  gaPageviewsSum?: Maybe<Scalars['BigInt']['output']>;
  gscClicksSum?: Maybe<Scalars['BigInt']['output']>;
  infoAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  instanceCount?: Maybe<Scalars['BigInt']['output']>;
  layoutShiftImpactAvg?: Maybe<Scalars['Float']['output']>;
  layoutShiftImpactSum?: Maybe<Scalars['Float']['output']>;
  passedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  productOfWastedKibAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  productOfWastedSecsAndPageviewsSum?: Maybe<Scalars['Float']['output']>;
  savingsScoreSum?: Maybe<Scalars['Float']['output']>;
  subItemsCount?: Maybe<Scalars['Float']['output']>;
  totalBytesAvg?: Maybe<Scalars['Float']['output']>;
  totalBytesSum?: Maybe<Scalars['BigInt']['output']>;
  totalSizeKibAvg?: Maybe<Scalars['Float']['output']>;
  totalSizeKibSum?: Maybe<Scalars['Float']['output']>;
  transferSizeSum?: Maybe<Scalars['BigInt']['output']>;
  urlCount?: Maybe<Scalars['BigInt']['output']>;
  warningAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  wastedKibAvg?: Maybe<Scalars['Float']['output']>;
  wastedKibSum?: Maybe<Scalars['Float']['output']>;
  wastedPercentAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsAvg?: Maybe<Scalars['Float']['output']>;
  wastedSecsSum?: Maybe<Scalars['Float']['output']>;
  wastedWebpBytesSum?: Maybe<Scalars['BigInt']['output']>;
  weightedScore?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSiteSpeedAuditOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlSiteSpeedAudits by. */
  field: CrawlSiteSpeedAuditOrderField;
};

export enum CrawlSiteSpeedAuditOrderField {
  AuditDigest = 'auditDigest',
  AuditId = 'auditId',
  AuditResult = 'auditResult',
  Description = 'description',
  DisplayValue = 'displayValue',
  GscClicks = 'gscClicks',
  GuidanceLevel = 'guidanceLevel',
  ItemsCount = 'itemsCount',
  MetricSavingsCls = 'metricSavingsCls',
  MetricSavingsFcp = 'metricSavingsFcp',
  MetricSavingsInp = 'metricSavingsInp',
  MetricSavingsLcp = 'metricSavingsLcp',
  MetricSavingsTbt = 'metricSavingsTbt',
  NumericUnit = 'numericUnit',
  NumericValue = 'numericValue',
  Pageviews = 'pageviews',
  ProductOfSavingsKibAndPageviews = 'productOfSavingsKibAndPageviews',
  ProductOfSavingsSecsAndPageviews = 'productOfSavingsSecsAndPageviews',
  RedirectsChainCount = 'redirectsChainCount',
  SavingsKib = 'savingsKib',
  SavingsScore = 'savingsScore',
  SavingsSecs = 'savingsSecs',
  Score = 'score',
  ScoreDisplayMode = 'scoreDisplayMode',
  ScoringOptionsMedian = 'scoringOptionsMedian',
  ScoringOptionsPercentile = 'scoringOptionsPercentile',
  SegmentIdsCount = 'segmentIdsCount',
  SegmentNamesCount = 'segmentNamesCount',
  Title = 'title',
  TotalSizeKib = 'totalSizeKib',
  Url = 'url',
  UrlDigest = 'urlDigest',
  Warnings = 'warnings'
}

export type CrawlSiteSpeedAuditSumCalculation = {
  gscClicks?: Maybe<Scalars['BigInt']['output']>;
  itemsCount?: Maybe<Scalars['BigInt']['output']>;
  metricSavingsCls?: Maybe<Scalars['Float']['output']>;
  metricSavingsFcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsInp?: Maybe<Scalars['Float']['output']>;
  metricSavingsLcp?: Maybe<Scalars['Float']['output']>;
  metricSavingsTbt?: Maybe<Scalars['Float']['output']>;
  numericValue?: Maybe<Scalars['Float']['output']>;
  pageviews?: Maybe<Scalars['BigInt']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
  totalSizeKib?: Maybe<Scalars['Float']['output']>;
};

export type CrawlSitemap = {
  contentEncoding?: Maybe<Scalars['String']['output']>;
  contentLength?: Maybe<Scalars['Int']['output']>;
  encoding?: Maybe<Scalars['String']['output']>;
  extension?: Maybe<Scalars['String']['output']>;
  fetchError?: Maybe<Scalars['String']['output']>;
  foundAt?: Maybe<Scalars['String']['output']>;
  foundAtSource?: Maybe<Scalars['String']['output']>;
  fullyQualifiedLocsCount?: Maybe<Scalars['Int']['output']>;
  httpStatusCode?: Maybe<Scalars['Int']['output']>;
  linksCount?: Maybe<Scalars['Int']['output']>;
  logRequestsTotal?: Maybe<Scalars['Int']['output']>;
  nonFullyQualifiedUrlsCount?: Maybe<Scalars['Int']['output']>;
  outsidePathLocsCount?: Maybe<Scalars['Int']['output']>;
  parseError?: Maybe<Scalars['String']['output']>;
  restrictedReason?: Maybe<Scalars['String']['output']>;
  sitemapType?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use httpStatusCode instead. */
  statusCode?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlAlias?: Maybe<Scalars['String']['output']>;
  urlAliasDigest?: Maybe<Scalars['String']['output']>;
  urlCount?: Maybe<Scalars['Int']['output']>;
  urlDigest: Scalars['String']['output'];
};

export type CrawlSitemapConnection = {
  /** A list of edges. */
  edges: Array<CrawlSitemapEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlSitemap>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlSitemaps in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlSitemapConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlSitemapConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlSitemapConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlSitemapConnectionFilterInput>>;
  contentEncoding?: InputMaybe<ConnectionStringFilterInput>;
  contentLength?: InputMaybe<ConnectionIntFilterInput>;
  encoding?: InputMaybe<ConnectionStringFilterInput>;
  extension?: InputMaybe<ConnectionStringFilterInput>;
  fetchError?: InputMaybe<ConnectionStringFilterInput>;
  foundAt?: InputMaybe<ConnectionStringFilterInput>;
  foundAtSource?: InputMaybe<ConnectionStringFilterInput>;
  fullyQualifiedLocsCount?: InputMaybe<ConnectionIntFilterInput>;
  httpStatusCode?: InputMaybe<ConnectionIntFilterInput>;
  linksCount?: InputMaybe<ConnectionIntFilterInput>;
  logRequestsTotal?: InputMaybe<ConnectionIntFilterInput>;
  nonFullyQualifiedUrlsCount?: InputMaybe<ConnectionIntFilterInput>;
  outsidePathLocsCount?: InputMaybe<ConnectionIntFilterInput>;
  parseError?: InputMaybe<ConnectionStringFilterInput>;
  restrictedReason?: InputMaybe<ConnectionStringFilterInput>;
  sitemapType?: InputMaybe<ConnectionStringFilterInput>;
  statusCode?: InputMaybe<ConnectionIntFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
  urlAlias?: InputMaybe<ConnectionStringFilterInput>;
  urlAliasDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlCount?: InputMaybe<ConnectionIntFilterInput>;
  urlDigest?: InputMaybe<ConnectionStringFilterInput>;
};

export type CrawlSitemapEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlSitemap;
};

export type CrawlSitemapOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlSitemaps by. */
  field: CrawlSitemapOrderField;
};

export enum CrawlSitemapOrderField {
  ContentEncoding = 'contentEncoding',
  ContentLength = 'contentLength',
  Encoding = 'encoding',
  Extension = 'extension',
  FetchError = 'fetchError',
  FoundAt = 'foundAt',
  FoundAtSource = 'foundAtSource',
  FullyQualifiedLocsCount = 'fullyQualifiedLocsCount',
  HttpStatusCode = 'httpStatusCode',
  LinksCount = 'linksCount',
  LogRequestsTotal = 'logRequestsTotal',
  NonFullyQualifiedUrlsCount = 'nonFullyQualifiedUrlsCount',
  OutsidePathLocsCount = 'outsidePathLocsCount',
  ParseError = 'parseError',
  RestrictedReason = 'restrictedReason',
  SitemapType = 'sitemapType',
  StatusCode = 'statusCode',
  Url = 'url',
  UrlAlias = 'urlAlias',
  UrlCount = 'urlCount'
}

export type CrawlStat = {
  crawlLevels: Array<CrawlLevel>;
  crawlRates: Array<CrawlRate>;
};

export enum CrawlStatus {
  Archived = 'Archived',
  Archiving = 'Archiving',
  Crawling = 'Crawling',
  Discovering = 'Discovering',
  Draft = 'Draft',
  Errored = 'Errored',
  Finalizing = 'Finalizing',
  Finished = 'Finished',
  Paused = 'Paused',
  Queued = 'Queued',
  Queuing = 'Queuing'
}

export enum CrawlType {
  Backlinks = 'Backlinks',
  CustomMetricsUpload = 'CustomMetricsUpload',
  GoogleAnalytics = 'GoogleAnalytics',
  GoogleSearchConsole = 'GoogleSearchConsole',
  List = 'List',
  LogSummary = 'LogSummary',
  Sitemap = 'Sitemap',
  Task = 'Task',
  Web = 'Web'
}

export type CrawlTypeConnection = {
  /** A list of edges. */
  edges: Array<CrawlTypeEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlTypeMetadata>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlTypes in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlTypeConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlTypeConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlTypeConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlTypeConnectionFilterInput>>;
  code?: InputMaybe<ConnectionCrawlTypeFilterInput>;
};

export type CrawlTypeCount = {
  count: Scalars['Int']['output'];
  crawlTypes: Array<CrawlType>;
};

export type CrawlTypeCountsInput = {
  crawlTypes: Array<CrawlType>;
  reportTemplateCode?: Scalars['String']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type CrawlTypeEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlTypeMetadata;
};

export type CrawlTypeMetadata = {
  code: CrawlType;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  phase: Scalars['Int']['output'];
  rawID: Scalars['String']['output'];
};

export type CrawlTypeOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlTypes by. */
  field: CrawlTypeOrderField;
};

export enum CrawlTypeOrderField {
  Code = 'code',
  Name = 'name',
  Phase = 'phase'
}

export type CrawlUncrawledUrl = {
  fileExtension?: Maybe<Scalars['String']['output']>;
  foundAtSitemap?: Maybe<Scalars['String']['output']>;
  foundAtUrl?: Maybe<Scalars['String']['output']>;
  foundInBacklinks?: Maybe<Scalars['Boolean']['output']>;
  foundInCustomMetricsUpload?: Maybe<Scalars['Boolean']['output']>;
  foundInGoogleAnalytics?: Maybe<Scalars['Boolean']['output']>;
  foundInGoogleSearchConsole?: Maybe<Scalars['Boolean']['output']>;
  foundInList?: Maybe<Scalars['Boolean']['output']>;
  foundInLogSummary?: Maybe<Scalars['Boolean']['output']>;
  foundInSitemap?: Maybe<Scalars['Boolean']['output']>;
  foundInWebCrawl?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  restrictedReason?: Maybe<Scalars['String']['output']>;
  rewriteChain?: Maybe<Scalars['JSONObject']['output']>;
  rewriteChainCount?: Maybe<Scalars['Int']['output']>;
  robotsTxtRuleMatch?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlAlias?: Maybe<Scalars['String']['output']>;
  urlAliasDigest?: Maybe<Scalars['String']['output']>;
  urlDigest: Scalars['String']['output'];
};

export type CrawlUncrawledUrlConnection = {
  /** A list of edges. */
  edges: Array<CrawlUncrawledUrlEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlUncrawledUrl>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlUncrawledUrls in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlUncrawledUrlConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlUncrawledUrlConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlUncrawledUrlConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlUncrawledUrlConnectionFilterInput>>;
  fileExtension?: InputMaybe<ConnectionStringFilterInput>;
  foundAtSitemap?: InputMaybe<ConnectionStringFilterInput>;
  foundAtUrl?: InputMaybe<ConnectionStringFilterInput>;
  foundInBacklinks?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInCustomMetricsUpload?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInGoogleAnalytics?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInGoogleSearchConsole?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInList?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInLogSummary?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInSitemap?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInWebCrawl?: InputMaybe<ConnectionBooleanFilterInput>;
  level?: InputMaybe<ConnectionIntFilterInput>;
  restrictedReason?: InputMaybe<ConnectionStringFilterInput>;
  rewriteChainCount?: InputMaybe<ConnectionIntFilterInput>;
  robotsTxtRuleMatch?: InputMaybe<ConnectionStringFilterInput>;
  tag?: InputMaybe<ConnectionStringFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
  urlAlias?: InputMaybe<ConnectionStringFilterInput>;
  urlAliasDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlDigest?: InputMaybe<ConnectionStringFilterInput>;
};

export type CrawlUncrawledUrlEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlUncrawledUrl;
};

export type CrawlUncrawledUrlOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlUncrawledUrls by. */
  field: CrawlUncrawledUrlOrderField;
};

export enum CrawlUncrawledUrlOrderField {
  FileExtension = 'fileExtension',
  FoundAtSitemap = 'foundAtSitemap',
  FoundAtUrl = 'foundAtUrl',
  FoundInBacklinks = 'foundInBacklinks',
  FoundInCustomMetricsUpload = 'foundInCustomMetricsUpload',
  FoundInGoogleAnalytics = 'foundInGoogleAnalytics',
  FoundInGoogleSearchConsole = 'foundInGoogleSearchConsole',
  FoundInList = 'foundInList',
  FoundInLogSummary = 'foundInLogSummary',
  FoundInSitemap = 'foundInSitemap',
  FoundInWebCrawl = 'foundInWebCrawl',
  Level = 'level',
  RestrictedReason = 'restrictedReason',
  RewriteChainCount = 'rewriteChainCount',
  RobotsTxtRuleMatch = 'robotsTxtRuleMatch',
  Tag = 'tag',
  Url = 'url',
  UrlAlias = 'urlAlias'
}

export type CrawlUniqueLink = {
  anchorText?: Maybe<Scalars['String']['output']>;
  attrRel?: Maybe<Scalars['String']['output']>;
  hasImageAltText?: Maybe<Scalars['Boolean']['output']>;
  hasImageLink?: Maybe<Scalars['Boolean']['output']>;
  hreflang?: Maybe<Scalars['String']['output']>;
  instanceCount?: Maybe<Scalars['Int']['output']>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  isHreflang?: Maybe<Scalars['Boolean']['output']>;
  isImageAltTextEmpty?: Maybe<Scalars['Boolean']['output']>;
  isRedirect?: Maybe<Scalars['Boolean']['output']>;
  isRestricted?: Maybe<Scalars['Boolean']['output']>;
  linkType?: Maybe<Scalars['String']['output']>;
  nofollow?: Maybe<Scalars['Boolean']['output']>;
  primaryUrlFrom?: Maybe<Scalars['String']['output']>;
  primaryUrlFromAlias?: Maybe<Scalars['String']['output']>;
  primaryUrlFromAliasDigest?: Maybe<Scalars['String']['output']>;
  primaryUrlFromDeeprank?: Maybe<Scalars['Float']['output']>;
  primaryUrlFromDigest?: Maybe<Scalars['String']['output']>;
  primaryUrlFromHttps?: Maybe<Scalars['Boolean']['output']>;
  primaryUrlFromStatusCode?: Maybe<Scalars['Int']['output']>;
  primaryUrlFromStatusCodeId?: Maybe<Scalars['Int']['output']>;
  primaryUrlFromTitle?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  uniqueLinkDigest: Scalars['String']['output'];
  urlTo?: Maybe<Scalars['String']['output']>;
  urlToAlias?: Maybe<Scalars['String']['output']>;
  urlToAliasDigest?: Maybe<Scalars['String']['output']>;
  urlToDeeprank?: Maybe<Scalars['Float']['output']>;
  urlToDigest?: Maybe<Scalars['String']['output']>;
  urlToHttps?: Maybe<Scalars['Boolean']['output']>;
  urlToStatusCode?: Maybe<Scalars['Int']['output']>;
  urlToStatusCodeId?: Maybe<Scalars['Int']['output']>;
  urlToTitle?: Maybe<Scalars['String']['output']>;
};

export type CrawlUniqueLinkConnection = {
  /** A list of edges. */
  edges: Array<CrawlUniqueLinkEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlUniqueLink>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlUniqueLinks in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlUniqueLinkConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlUniqueLinkConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlUniqueLinkConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlUniqueLinkConnectionFilterInput>>;
  anchorText?: InputMaybe<ConnectionStringFilterInput>;
  attrRel?: InputMaybe<ConnectionStringFilterInput>;
  hasImageAltText?: InputMaybe<ConnectionBooleanFilterInput>;
  hasImageLink?: InputMaybe<ConnectionBooleanFilterInput>;
  hreflang?: InputMaybe<ConnectionStringFilterInput>;
  instanceCount?: InputMaybe<ConnectionIntFilterInput>;
  internal?: InputMaybe<ConnectionBooleanFilterInput>;
  isHreflang?: InputMaybe<ConnectionBooleanFilterInput>;
  isImageAltTextEmpty?: InputMaybe<ConnectionBooleanFilterInput>;
  isRedirect?: InputMaybe<ConnectionBooleanFilterInput>;
  isRestricted?: InputMaybe<ConnectionBooleanFilterInput>;
  linkType?: InputMaybe<ConnectionStringFilterInput>;
  nofollow?: InputMaybe<ConnectionBooleanFilterInput>;
  primaryUrlFrom?: InputMaybe<ConnectionStringFilterInput>;
  primaryUrlFromAlias?: InputMaybe<ConnectionStringFilterInput>;
  primaryUrlFromAliasDigest?: InputMaybe<ConnectionStringFilterInput>;
  primaryUrlFromDeeprank?: InputMaybe<ConnectionFloatFilterInput>;
  primaryUrlFromDigest?: InputMaybe<ConnectionStringFilterInput>;
  primaryUrlFromHttps?: InputMaybe<ConnectionBooleanFilterInput>;
  primaryUrlFromStatusCode?: InputMaybe<ConnectionIntFilterInput>;
  primaryUrlFromTitle?: InputMaybe<ConnectionStringFilterInput>;
  tag?: InputMaybe<ConnectionStringFilterInput>;
  uniqueLinkDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlTo?: InputMaybe<ConnectionStringFilterInput>;
  urlToAlias?: InputMaybe<ConnectionStringFilterInput>;
  urlToAliasDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlToDeeprank?: InputMaybe<ConnectionFloatFilterInput>;
  urlToDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlToHttps?: InputMaybe<ConnectionBooleanFilterInput>;
  urlToStatusCode?: InputMaybe<ConnectionIntFilterInput>;
  urlToTitle?: InputMaybe<ConnectionStringFilterInput>;
};

export type CrawlUniqueLinkEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlUniqueLink;
};

export type CrawlUniqueLinkOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlUniqueLinks by. */
  field: CrawlUniqueLinkOrderField;
};

export enum CrawlUniqueLinkOrderField {
  AnchorText = 'anchorText',
  AttrRel = 'attrRel',
  HasImageAltText = 'hasImageAltText',
  HasImageLink = 'hasImageLink',
  Hreflang = 'hreflang',
  InstanceCount = 'instanceCount',
  Internal = 'internal',
  IsHreflang = 'isHreflang',
  IsImageAltTextEmpty = 'isImageAltTextEmpty',
  IsRedirect = 'isRedirect',
  IsRestricted = 'isRestricted',
  LinkType = 'linkType',
  Nofollow = 'nofollow',
  PrimaryUrlFrom = 'primaryUrlFrom',
  PrimaryUrlFromAlias = 'primaryUrlFromAlias',
  PrimaryUrlFromDeeprank = 'primaryUrlFromDeeprank',
  PrimaryUrlFromHttps = 'primaryUrlFromHttps',
  PrimaryUrlFromStatusCode = 'primaryUrlFromStatusCode',
  PrimaryUrlFromTitle = 'primaryUrlFromTitle',
  Tag = 'tag',
  UrlTo = 'urlTo',
  UrlToAlias = 'urlToAlias',
  UrlToDeeprank = 'urlToDeeprank',
  UrlToHttps = 'urlToHttps',
  UrlToStatusCode = 'urlToStatusCode',
  UrlToTitle = 'urlToTitle'
}

export type CrawlUrl = {
  accessibilityAIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityAaIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityAaaIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityBpIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityCriticalIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityEnabled?: Maybe<Scalars['Boolean']['output']>;
  accessibilityIncompleteIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityIssuesCountByRuleId?: Maybe<Array<Scalars['JSONObject']['output']>>;
  accessibilityIssuesCountByRuleIdCount?: Maybe<Scalars['Int']['output']>;
  accessibilityMinorIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityModerateIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilitySeriousIssuesCount?: Maybe<Scalars['Int']['output']>;
  adultRating?: Maybe<Scalars['Boolean']['output']>;
  aiBotBlocked?: Maybe<Array<Scalars['String']['output']>>;
  aiBotBlockedCount?: Maybe<Scalars['Int']['output']>;
  amphtml?: Maybe<Scalars['Boolean']['output']>;
  amphtmlReciprocate?: Maybe<Scalars['Boolean']['output']>;
  amphtmlValid?: Maybe<Scalars['Boolean']['output']>;
  attachmentNames?: Maybe<Array<Scalars['String']['output']>>;
  attachments?: Maybe<Array<CrawlUrlAttachment>>;
  backlinkCount?: Maybe<Scalars['Int']['output']>;
  backlinkDomainCount?: Maybe<Scalars['Int']['output']>;
  bodyTagsInHeadCount?: Maybe<Scalars['Int']['output']>;
  breadcrumb01?: Maybe<Scalars['String']['output']>;
  breadcrumb02?: Maybe<Scalars['String']['output']>;
  breadcrumb03?: Maybe<Scalars['String']['output']>;
  breadcrumb04?: Maybe<Scalars['String']['output']>;
  breadcrumb05?: Maybe<Scalars['String']['output']>;
  breadcrumb06?: Maybe<Scalars['String']['output']>;
  breadcrumb07?: Maybe<Scalars['String']['output']>;
  breadcrumb08?: Maybe<Scalars['String']['output']>;
  breadcrumbCount?: Maybe<Scalars['Int']['output']>;
  breadcrumbSchemaCount?: Maybe<Scalars['Int']['output']>;
  cacheControl?: Maybe<Scalars['String']['output']>;
  canonicalHeaderUrl?: Maybe<Scalars['String']['output']>;
  canonicalHtmlUrl?: Maybe<Scalars['String']['output']>;
  canonicalLinksInCount?: Maybe<Scalars['Int']['output']>;
  canonicalUrl?: Maybe<Scalars['String']['output']>;
  canonicalUrlChangedFrom?: Maybe<Scalars['String']['output']>;
  canonicalUrlDigest?: Maybe<Scalars['String']['output']>;
  canonicalUrlIsConsistent?: Maybe<Scalars['Boolean']['output']>;
  canonicalizedPage?: Maybe<Scalars['Boolean']['output']>;
  changes?: Maybe<Array<Scalars['String']['output']>>;
  changesCount?: Maybe<Scalars['Int']['output']>;
  containerExecutionFailures?: Maybe<Array<ContainerExecutionFailure>>;
  containerExecutionFailuresCount?: Maybe<Scalars['Int']['output']>;
  contentHtmlRatio?: Maybe<Scalars['Float']['output']>;
  contentSize?: Maybe<Scalars['Int']['output']>;
  crawlDatetime?: Maybe<Scalars['DateTime']['output']>;
  css?: Maybe<Scalars['Boolean']['output']>;
  cssLinksInCount?: Maybe<Scalars['Int']['output']>;
  cumulativeLayoutShift?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftMainFrame?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftScore?: Maybe<Scalars['Float']['output']>;
  customExtraction1?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction1Count?: Maybe<Scalars['Int']['output']>;
  customExtraction2?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction2Count?: Maybe<Scalars['Int']['output']>;
  customExtraction3?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction3Count?: Maybe<Scalars['Int']['output']>;
  customExtraction4?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction4Count?: Maybe<Scalars['Int']['output']>;
  customExtraction5?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction5Count?: Maybe<Scalars['Int']['output']>;
  customExtraction6?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction6Count?: Maybe<Scalars['Int']['output']>;
  customExtraction7?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction7Count?: Maybe<Scalars['Int']['output']>;
  customExtraction8?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction8Count?: Maybe<Scalars['Int']['output']>;
  customExtraction9?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction9Count?: Maybe<Scalars['Int']['output']>;
  customExtraction10?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction10Count?: Maybe<Scalars['Int']['output']>;
  customExtraction11?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction11Count?: Maybe<Scalars['Int']['output']>;
  customExtraction12?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction12Count?: Maybe<Scalars['Int']['output']>;
  customExtraction13?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction13Count?: Maybe<Scalars['Int']['output']>;
  customExtraction14?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction14Count?: Maybe<Scalars['Int']['output']>;
  customExtraction15?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction15Count?: Maybe<Scalars['Int']['output']>;
  customExtraction16?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction16Count?: Maybe<Scalars['Int']['output']>;
  customExtraction17?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction17Count?: Maybe<Scalars['Int']['output']>;
  customExtraction18?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction18Count?: Maybe<Scalars['Int']['output']>;
  customExtraction19?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction19Count?: Maybe<Scalars['Int']['output']>;
  customExtraction20?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction20Count?: Maybe<Scalars['Int']['output']>;
  customExtraction21?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction21Count?: Maybe<Scalars['Int']['output']>;
  customExtraction22?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction22Count?: Maybe<Scalars['Int']['output']>;
  customExtraction23?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction23Count?: Maybe<Scalars['Int']['output']>;
  customExtraction24?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction24Count?: Maybe<Scalars['Int']['output']>;
  customExtraction25?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction25Count?: Maybe<Scalars['Int']['output']>;
  customExtraction26?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction26Count?: Maybe<Scalars['Int']['output']>;
  customExtraction27?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction27Count?: Maybe<Scalars['Int']['output']>;
  customExtraction28?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction28Count?: Maybe<Scalars['Int']['output']>;
  customExtraction29?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction29Count?: Maybe<Scalars['Int']['output']>;
  customExtraction30?: Maybe<Array<Scalars['String']['output']>>;
  customExtraction30Count?: Maybe<Scalars['Int']['output']>;
  customExtractionMatch?: Maybe<Scalars['Boolean']['output']>;
  customMetrics?: Maybe<Scalars['JSONObject']['output']>;
  deeprank?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionChangedFrom?: Maybe<Scalars['String']['output']>;
  descriptionLength?: Maybe<Scalars['Int']['output']>;
  descriptionLengthPx?: Maybe<Scalars['Int']['output']>;
  desktopUrl?: Maybe<Scalars['String']['output']>;
  desktopUrlDigest?: Maybe<Scalars['String']['output']>;
  disallowedPage?: Maybe<Scalars['Boolean']['output']>;
  dominantLanguages?: Maybe<Array<Scalars['String']['output']>>;
  duplicateBody?: Maybe<Scalars['Boolean']['output']>;
  duplicateBodyCount?: Maybe<Scalars['Int']['output']>;
  duplicateBodyCountIsApproximation?: Maybe<Scalars['Boolean']['output']>;
  duplicateBodyNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateBodyPrimaryUrl?: Maybe<Scalars['String']['output']>;
  duplicateBodyPrimaryUrlDigest?: Maybe<Scalars['String']['output']>;
  duplicateDescription?: Maybe<Scalars['Boolean']['output']>;
  duplicateDescriptionCount?: Maybe<Scalars['Int']['output']>;
  duplicateDescriptionCountIsApproximation?: Maybe<Scalars['Boolean']['output']>;
  duplicateDescriptionNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateDescriptionPrimaryUrl?: Maybe<Scalars['String']['output']>;
  duplicateDescriptionPrimaryUrlDigest?: Maybe<Scalars['String']['output']>;
  duplicatePage?: Maybe<Scalars['Boolean']['output']>;
  duplicatePageCount?: Maybe<Scalars['Int']['output']>;
  duplicatePageCountIsApproximation?: Maybe<Scalars['Boolean']['output']>;
  duplicatePageNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateTitle?: Maybe<Scalars['Boolean']['output']>;
  duplicateTitleCount?: Maybe<Scalars['Int']['output']>;
  duplicateTitleCountIsApproximation?: Maybe<Scalars['Boolean']['output']>;
  duplicateTitleNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateTitlePrimaryUrl?: Maybe<Scalars['String']['output']>;
  duplicateTitlePrimaryUrlDigest?: Maybe<Scalars['String']['output']>;
  duplicateUrl?: Maybe<Scalars['Boolean']['output']>;
  duplicateUrlCount?: Maybe<Scalars['Int']['output']>;
  duplicateUrlCountIsApproximation?: Maybe<Scalars['Boolean']['output']>;
  duplicateUrlNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateUrlPrimaryUrl?: Maybe<Scalars['String']['output']>;
  duplicateUrlPrimaryUrlDigest?: Maybe<Scalars['String']['output']>;
  encodingHeader?: Maybe<Scalars['String']['output']>;
  eventSchemaCount?: Maybe<Scalars['Int']['output']>;
  externalLinksCount?: Maybe<Scalars['Int']['output']>;
  failedAudits?: Maybe<Array<Scalars['String']['output']>>;
  failedAuditsCount?: Maybe<Scalars['Int']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  faqpageSchemaCount?: Maybe<Scalars['Int']['output']>;
  fbAdmins?: Maybe<Scalars['String']['output']>;
  fbAppId?: Maybe<Scalars['String']['output']>;
  fetchTime?: Maybe<Scalars['Float']['output']>;
  fileExtension?: Maybe<Scalars['String']['output']>;
  firstContentfulPaint?: Maybe<Scalars['Int']['output']>;
  firstContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  firstMeaningfulPaint?: Maybe<Scalars['Int']['output']>;
  folderCount?: Maybe<Scalars['Int']['output']>;
  followedExternalLinksOutCount?: Maybe<Scalars['Int']['output']>;
  followedLinksInCount?: Maybe<Scalars['Int']['output']>;
  foundAtSitemap?: Maybe<Scalars['String']['output']>;
  foundAtUrl?: Maybe<Scalars['String']['output']>;
  foundAtUrlDigest?: Maybe<Scalars['String']['output']>;
  foundInBacklinks?: Maybe<Scalars['Boolean']['output']>;
  foundInCustomMetricsUpload?: Maybe<Scalars['Boolean']['output']>;
  foundInGoogleAnalytics?: Maybe<Scalars['Boolean']['output']>;
  foundInGoogleSearchConsole?: Maybe<Scalars['Boolean']['output']>;
  foundInList?: Maybe<Scalars['Boolean']['output']>;
  foundInLogSummary?: Maybe<Scalars['Boolean']['output']>;
  foundInSitemap?: Maybe<Scalars['Boolean']['output']>;
  foundInTask?: Maybe<Scalars['Boolean']['output']>;
  foundInWebCrawl?: Maybe<Scalars['Boolean']['output']>;
  gaAvgPageLoadTime?: Maybe<Scalars['Float']['output']>;
  gaAvgTimeOnPage?: Maybe<Scalars['Float']['output']>;
  gaPageviews?: Maybe<Scalars['Int']['output']>;
  gaPageviewsPerVisits?: Maybe<Scalars['Float']['output']>;
  gaVisitBounceRate?: Maybe<Scalars['Float']['output']>;
  gaVisits?: Maybe<Scalars['Int']['output']>;
  h1Count?: Maybe<Scalars['Int']['output']>;
  h1Length?: Maybe<Scalars['Int']['output']>;
  h1Tag?: Maybe<Array<Scalars['String']['output']>>;
  h1TagChangedFrom?: Maybe<Array<Scalars['String']['output']>>;
  h1TagChangedFromCount?: Maybe<Scalars['Int']['output']>;
  h1TagCount?: Maybe<Scalars['Int']['output']>;
  h2Tag?: Maybe<Array<Scalars['String']['output']>>;
  h2TagCount?: Maybe<Scalars['Int']['output']>;
  h3Tag?: Maybe<Array<Scalars['String']['output']>>;
  h3TagCount?: Maybe<Scalars['Int']['output']>;
  hasStructuredData?: Maybe<Scalars['Boolean']['output']>;
  headTagsInBodyCount?: Maybe<Scalars['Int']['output']>;
  headerContentType?: Maybe<Scalars['String']['output']>;
  headerNoarchive?: Maybe<Scalars['Boolean']['output']>;
  headerNofollow?: Maybe<Scalars['Boolean']['output']>;
  headerNoindex?: Maybe<Scalars['Boolean']['output']>;
  headerNoodp?: Maybe<Scalars['Boolean']['output']>;
  headerNosnippet?: Maybe<Scalars['Boolean']['output']>;
  headerNoydir?: Maybe<Scalars['Boolean']['output']>;
  howtoSchemaCount?: Maybe<Scalars['Int']['output']>;
  hreflangCombination?: Maybe<Array<Scalars['String']['output']>>;
  hreflangCombinationChangedFrom?: Maybe<Array<Scalars['String']['output']>>;
  hreflangCombinationChangedFromCount?: Maybe<Scalars['Int']['output']>;
  hreflangCombinationCount?: Maybe<Scalars['Int']['output']>;
  hreflangLanguagesIn?: Maybe<Array<Scalars['String']['output']>>;
  hreflangLanguagesInCount?: Maybe<Scalars['Int']['output']>;
  hreflangLanguagesInMetaCount?: Maybe<Scalars['Int']['output']>;
  hreflangLinksInCount?: Maybe<Scalars['Int']['output']>;
  hreflangLinksOutCount?: Maybe<Scalars['Int']['output']>;
  hreflangOnHeader?: Maybe<Scalars['Boolean']['output']>;
  hreflangOnPage?: Maybe<Scalars['Boolean']['output']>;
  hreflangOnSitemap?: Maybe<Scalars['Boolean']['output']>;
  hreflangUrlCount?: Maybe<Scalars['Int']['output']>;
  hsts?: Maybe<Scalars['String']['output']>;
  htmlSize?: Maybe<Scalars['Int']['output']>;
  httpStatusCode?: Maybe<Scalars['Int']['output']>;
  httpStatusCodeChangedFrom?: Maybe<Scalars['Int']['output']>;
  https?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ObjectID']['output'];
  identifiedLanguage?: Maybe<Scalars['String']['output']>;
  imgLinksInCount?: Maybe<Scalars['Int']['output']>;
  inconsistentHreflangsInCount?: Maybe<Scalars['Int']['output']>;
  indexable?: Maybe<Scalars['Boolean']['output']>;
  infoAudits?: Maybe<Array<Scalars['String']['output']>>;
  infoAuditsCount?: Maybe<Scalars['Int']['output']>;
  interactive?: Maybe<Scalars['Int']['output']>;
  internal?: Maybe<Scalars['Boolean']['output']>;
  internalLinksCount?: Maybe<Scalars['Int']['output']>;
  isImage?: Maybe<Scalars['Boolean']['output']>;
  isRedirect?: Maybe<Scalars['Boolean']['output']>;
  isRedirectLoop?: Maybe<Scalars['Boolean']['output']>;
  isSelfCanonical?: Maybe<Scalars['Boolean']['output']>;
  isWebLinked?: Maybe<Scalars['Boolean']['output']>;
  js?: Maybe<Scalars['Boolean']['output']>;
  jsLinksInCount?: Maybe<Scalars['Int']['output']>;
  languagesSummary?: Maybe<Array<LanguageSummary>>;
  largestContentfulPaint?: Maybe<Scalars['Int']['output']>;
  largestContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  lcpLoadEnd?: Maybe<Scalars['Int']['output']>;
  lcpLoadStart?: Maybe<Scalars['Int']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  levelChangedFrom?: Maybe<Scalars['Int']['output']>;
  linksInCount?: Maybe<Scalars['Int']['output']>;
  linksOutCount?: Maybe<Scalars['Int']['output']>;
  logRequestsDesktop?: Maybe<Scalars['Int']['output']>;
  logRequestsMobile?: Maybe<Scalars['Int']['output']>;
  logRequestsTotal?: Maybe<Scalars['Int']['output']>;
  maxPotentialFid?: Maybe<Scalars['Int']['output']>;
  metaCharset?: Maybe<Scalars['String']['output']>;
  metaContentType?: Maybe<Scalars['String']['output']>;
  metaDisabledSitelinks?: Maybe<Scalars['Boolean']['output']>;
  metaNoarchive?: Maybe<Scalars['Boolean']['output']>;
  metaNofollow?: Maybe<Scalars['Boolean']['output']>;
  metaNoindex?: Maybe<Scalars['Boolean']['output']>;
  metaNoodp?: Maybe<Scalars['Boolean']['output']>;
  metaNosnippet?: Maybe<Scalars['Boolean']['output']>;
  metaNoydir?: Maybe<Scalars['Boolean']['output']>;
  metaRedirect?: Maybe<Scalars['Boolean']['output']>;
  mobileDesktopContentDifference?: Maybe<Scalars['Int']['output']>;
  mobileDesktopContentMismatch?: Maybe<Scalars['Boolean']['output']>;
  mobileDesktopLinksInDifference?: Maybe<Scalars['Int']['output']>;
  mobileDesktopLinksOutDifference?: Maybe<Scalars['Int']['output']>;
  mobileLinksInCount?: Maybe<Scalars['Int']['output']>;
  mobileReciprocate?: Maybe<Scalars['Boolean']['output']>;
  mobileRelAlternate?: Maybe<Scalars['Boolean']['output']>;
  mobileRelAlternateHeaderUrl?: Maybe<Scalars['String']['output']>;
  mobileRelAlternateHeaderUrlDigest?: Maybe<Scalars['String']['output']>;
  mobileRelAlternateHtmlUrl?: Maybe<Scalars['String']['output']>;
  mobileRelAlternateHtmlUrlDigest?: Maybe<Scalars['String']['output']>;
  mobileRelAlternateUrl?: Maybe<Scalars['String']['output']>;
  mobileRelAlternateUrlDigest?: Maybe<Scalars['String']['output']>;
  mobileRelAlternateUrlIsConsistent?: Maybe<Scalars['Boolean']['output']>;
  mobileRelAmphtml?: Maybe<Scalars['Boolean']['output']>;
  mobileRelAmphtmlUrl?: Maybe<Scalars['String']['output']>;
  mobileRelAmphtmlUrlDigest?: Maybe<Scalars['String']['output']>;
  newsarticleAuthorSchemaValue?: Maybe<Scalars['String']['output']>;
  newsarticleSchemaCount?: Maybe<Scalars['Int']['output']>;
  newsarticleSchemaValid?: Maybe<Scalars['Boolean']['output']>;
  noarchive?: Maybe<Scalars['Boolean']['output']>;
  nofollowedPage?: Maybe<Scalars['Boolean']['output']>;
  noindex?: Maybe<Scalars['Boolean']['output']>;
  nonReciprocateHreflangOutCount?: Maybe<Scalars['Int']['output']>;
  noodp?: Maybe<Scalars['Boolean']['output']>;
  nosnippet?: Maybe<Scalars['Boolean']['output']>;
  noydir?: Maybe<Scalars['Boolean']['output']>;
  ogDescription?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  ogLocale?: Maybe<Scalars['String']['output']>;
  ogSiteName?: Maybe<Scalars['String']['output']>;
  ogTitle?: Maybe<Scalars['String']['output']>;
  ogType?: Maybe<Scalars['String']['output']>;
  ogUrl?: Maybe<Scalars['String']['output']>;
  page1?: Maybe<Scalars['Boolean']['output']>;
  pageGroup?: Maybe<Scalars['String']['output']>;
  pageTitle?: Maybe<Scalars['String']['output']>;
  pageTitleChangedFrom?: Maybe<Scalars['String']['output']>;
  pageTitleLength?: Maybe<Scalars['Int']['output']>;
  pageTitleLengthPx?: Maybe<Scalars['Int']['output']>;
  paginatedLinksInCount?: Maybe<Scalars['Int']['output']>;
  paginatedPage?: Maybe<Scalars['Boolean']['output']>;
  paramCount?: Maybe<Scalars['Int']['output']>;
  passedAudits?: Maybe<Array<Scalars['String']['output']>>;
  passedAuditsCount?: Maybe<Scalars['Int']['output']>;
  path0?: Maybe<Scalars['String']['output']>;
  path1?: Maybe<Scalars['String']['output']>;
  path2?: Maybe<Scalars['String']['output']>;
  path3?: Maybe<Scalars['String']['output']>;
  path4?: Maybe<Scalars['String']['output']>;
  path5?: Maybe<Scalars['String']['output']>;
  path6?: Maybe<Scalars['String']['output']>;
  path7?: Maybe<Scalars['String']['output']>;
  path8?: Maybe<Scalars['String']['output']>;
  path9?: Maybe<Scalars['String']['output']>;
  pathCount?: Maybe<Scalars['Int']['output']>;
  performanceCls?: Maybe<Scalars['Float']['output']>;
  performanceDcl?: Maybe<Scalars['Float']['output']>;
  performanceFcp?: Maybe<Scalars['Float']['output']>;
  performanceLcp?: Maybe<Scalars['Float']['output']>;
  performanceTtfb?: Maybe<Scalars['Float']['output']>;
  performanceTti?: Maybe<Scalars['Float']['output']>;
  previousPath?: Maybe<Scalars['String']['output']>;
  previousPathDigest?: Maybe<Scalars['String']['output']>;
  primaryPage?: Maybe<Scalars['Boolean']['output']>;
  primaryUrl?: Maybe<Scalars['String']['output']>;
  primaryUrlDigest?: Maybe<Scalars['String']['output']>;
  primaryUrlNonIndexable?: Maybe<Scalars['String']['output']>;
  productAvailabilitySchemaValue?: Maybe<Scalars['String']['output']>;
  productBrandSchemaValue?: Maybe<Scalars['String']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  productPriceSchemaValue?: Maybe<Scalars['Float']['output']>;
  productRatingSchemaValue?: Maybe<Scalars['Float']['output']>;
  productSchemaCount?: Maybe<Scalars['Int']['output']>;
  productSchemaValid?: Maybe<Scalars['Boolean']['output']>;
  productSkuSchemaValue?: Maybe<Scalars['String']['output']>;
  qapageSchemaCount?: Maybe<Scalars['Int']['output']>;
  rawHeader?: Maybe<Scalars['JSONObject']['output']>;
  rawID: Scalars['String']['output'];
  recipeSchemaCount?: Maybe<Scalars['Int']['output']>;
  reciprocateHreflangOutCount?: Maybe<Scalars['Int']['output']>;
  redirectChain?: Maybe<Array<Redirect>>;
  redirectChainCount?: Maybe<Scalars['Int']['output']>;
  redirectCount?: Maybe<Scalars['Int']['output']>;
  redirectExclusionReason?: Maybe<RedirectExclusionReason>;
  redirectedToStatusCode?: Maybe<Scalars['Int']['output']>;
  redirectedToUrl?: Maybe<Scalars['String']['output']>;
  redirectedToUrlChangedFrom?: Maybe<Scalars['String']['output']>;
  redirectedToUrlDigest?: Maybe<Scalars['String']['output']>;
  redirectsInCount?: Maybe<Scalars['Int']['output']>;
  relLinksInCount?: Maybe<Scalars['Int']['output']>;
  relNextHeaderUrl?: Maybe<Scalars['String']['output']>;
  relNextHtmlUrl?: Maybe<Scalars['String']['output']>;
  relNextUrl?: Maybe<Scalars['String']['output']>;
  relNextUrlIsConsistent?: Maybe<Scalars['Boolean']['output']>;
  relPrevHeaderUrl?: Maybe<Scalars['String']['output']>;
  relPrevHtmlUrl?: Maybe<Scalars['String']['output']>;
  relPrevUrl?: Maybe<Scalars['String']['output']>;
  relPrevUrlIsConsistent?: Maybe<Scalars['Boolean']['output']>;
  renderedCanonicalLinkMatch?: Maybe<Scalars['Boolean']['output']>;
  renderedLinkCountDifference?: Maybe<Scalars['Int']['output']>;
  renderedWordCountDifference?: Maybe<Scalars['Int']['output']>;
  resolvedHttpStatusCode?: Maybe<ResolvedHttpStatusCode>;
  resourceLinksInCount?: Maybe<Scalars['Int']['output']>;
  responsive?: Maybe<Scalars['Boolean']['output']>;
  reviewSchemaCount?: Maybe<Scalars['Int']['output']>;
  robotsNoindex?: Maybe<Scalars['Boolean']['output']>;
  robotsTxtRuleMatch?: Maybe<Scalars['String']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleDesktopCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleDesktopPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleMobileCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleMobilePosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleTabletCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleTabletPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleTotalCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleTotalPosition?: Maybe<Scalars['Float']['output']>;
  secureFormInputField?: Maybe<Scalars['Boolean']['output']>;
  separateDesktop?: Maybe<Scalars['Boolean']['output']>;
  separateMobile?: Maybe<Scalars['Boolean']['output']>;
  sitemapsInCount?: Maybe<Scalars['Int']['output']>;
  sitespeedAuditResults?: Maybe<Array<SitespeedAuditResult>>;
  sitespeedAuditResultsCount?: Maybe<Scalars['Int']['output']>;
  sitespeedCls?: Maybe<Scalars['Float']['output']>;
  sitespeedClsAuditResults?: Maybe<Array<SitespeedAuditResult>>;
  sitespeedClsAuditResultsCount?: Maybe<Scalars['Int']['output']>;
  sitespeedClsMainFrame?: Maybe<Scalars['Float']['output']>;
  sitespeedClsScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFcp?: Maybe<Scalars['Float']['output']>;
  sitespeedFcpAuditResults?: Maybe<Array<SitespeedAuditResult>>;
  sitespeedFcpAuditResultsCount?: Maybe<Scalars['Int']['output']>;
  sitespeedFcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFmp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcpAuditResults?: Maybe<Array<SitespeedAuditResult>>;
  sitespeedLcpAuditResultsCount?: Maybe<Scalars['Int']['output']>;
  sitespeedLcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedMaxPotentialFid?: Maybe<Scalars['Float']['output']>;
  sitespeedScore?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndex?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndexScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTbt?: Maybe<Scalars['Float']['output']>;
  sitespeedTbtAuditResults?: Maybe<Array<SitespeedAuditResult>>;
  sitespeedTbtAuditResultsCount?: Maybe<Scalars['Int']['output']>;
  sitespeedTbtScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTtfb?: Maybe<Scalars['Float']['output']>;
  sitespeedTti?: Maybe<Scalars['Float']['output']>;
  speedIndex?: Maybe<Scalars['Int']['output']>;
  speedIndexScore?: Maybe<Scalars['Float']['output']>;
  taskIds?: Maybe<Array<Scalars['Int']['output']>>;
  taskIdsCount?: Maybe<Scalars['Int']['output']>;
  thirdPartyCookieOrigins?: Maybe<Array<Scalars['String']['output']>>;
  timeToFirstByte?: Maybe<Scalars['Int']['output']>;
  totalBlockingTime?: Maybe<Scalars['Int']['output']>;
  totalBlockingTimeScore?: Maybe<Scalars['Float']['output']>;
  transferSize?: Maybe<Scalars['Int']['output']>;
  twitterCard?: Maybe<Scalars['String']['output']>;
  twitterDescription?: Maybe<Scalars['String']['output']>;
  twitterDescriptionLength?: Maybe<Scalars['Int']['output']>;
  twitterImage?: Maybe<Scalars['String']['output']>;
  twitterSite?: Maybe<Scalars['String']['output']>;
  twitterTitle?: Maybe<Scalars['String']['output']>;
  unavailableAfter?: Maybe<Scalars['String']['output']>;
  unavailableAfterDays?: Maybe<Scalars['Int']['output']>;
  uniqueLinksInCount?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlAlias?: Maybe<Scalars['String']['output']>;
  urlAliasDigest?: Maybe<Scalars['String']['output']>;
  urlContainsRepeatedPaths?: Maybe<Scalars['Boolean']['output']>;
  urlContainsUnderscores?: Maybe<Scalars['Boolean']['output']>;
  urlDigest: Scalars['String']['output'];
  urlDoubleEncoded?: Maybe<Scalars['Boolean']['output']>;
  urlLength?: Maybe<Scalars['Int']['output']>;
  urlNormalized?: Maybe<Scalars['String']['output']>;
  urlPathContainsDoubleSlashes?: Maybe<Scalars['Boolean']['output']>;
  validTwitterCard?: Maybe<Scalars['Boolean']['output']>;
  varyUserAgent?: Maybe<Scalars['Boolean']['output']>;
  videoobjectSchemaCount?: Maybe<Scalars['Int']['output']>;
  viewport?: Maybe<Scalars['String']['output']>;
  warningAudits?: Maybe<Array<Scalars['String']['output']>>;
  warningAuditsCount?: Maybe<Scalars['Int']['output']>;
  wordCount?: Maybe<Scalars['Int']['output']>;
};

export type CrawlUrlAggregate = {
  adultRating?: Maybe<Scalars['Boolean']['output']>;
  amphtml?: Maybe<Scalars['Boolean']['output']>;
  amphtmlReciprocate?: Maybe<Scalars['Boolean']['output']>;
  avg: CrawlUrlAvgCalculation;
  breadcrumb01?: Maybe<Scalars['String']['output']>;
  breadcrumb02?: Maybe<Scalars['String']['output']>;
  breadcrumb03?: Maybe<Scalars['String']['output']>;
  breadcrumb04?: Maybe<Scalars['String']['output']>;
  breadcrumb05?: Maybe<Scalars['String']['output']>;
  breadcrumb06?: Maybe<Scalars['String']['output']>;
  breadcrumb07?: Maybe<Scalars['String']['output']>;
  breadcrumb08?: Maybe<Scalars['String']['output']>;
  cacheControl?: Maybe<Scalars['String']['output']>;
  canonicalUrlIsConsistent?: Maybe<Scalars['Boolean']['output']>;
  canonicalizedPage?: Maybe<Scalars['Boolean']['output']>;
  count: CrawlUrlCountCalculation;
  css?: Maybe<Scalars['Boolean']['output']>;
  customExtractionMatch?: Maybe<Scalars['Boolean']['output']>;
  disallowedPage?: Maybe<Scalars['Boolean']['output']>;
  duplicateBody?: Maybe<Scalars['Boolean']['output']>;
  duplicateBodyNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateDescription?: Maybe<Scalars['Boolean']['output']>;
  duplicateDescriptionNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicatePage?: Maybe<Scalars['Boolean']['output']>;
  duplicatePageNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateTitle?: Maybe<Scalars['Boolean']['output']>;
  duplicateTitleNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  duplicateUrl?: Maybe<Scalars['Boolean']['output']>;
  duplicateUrlNonIndexable?: Maybe<Scalars['Boolean']['output']>;
  failedReason?: Maybe<Scalars['String']['output']>;
  fileExtension?: Maybe<Scalars['String']['output']>;
  headerContentType?: Maybe<Scalars['String']['output']>;
  httpStatusCode?: Maybe<Scalars['Int']['output']>;
  https?: Maybe<Scalars['Boolean']['output']>;
  indexable?: Maybe<Scalars['Boolean']['output']>;
  isImage?: Maybe<Scalars['Boolean']['output']>;
  isSelfCanonical?: Maybe<Scalars['Boolean']['output']>;
  isWebLinked?: Maybe<Scalars['Boolean']['output']>;
  js?: Maybe<Scalars['Boolean']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  max: CrawlUrlMaxCalculation;
  metaContentType?: Maybe<Scalars['String']['output']>;
  min: CrawlUrlMinCalculation;
  nofollowedPage?: Maybe<Scalars['Boolean']['output']>;
  noindex?: Maybe<Scalars['Boolean']['output']>;
  ogLocale?: Maybe<Scalars['String']['output']>;
  ogType?: Maybe<Scalars['String']['output']>;
  pageGroup?: Maybe<Scalars['String']['output']>;
  path0?: Maybe<Scalars['String']['output']>;
  path1?: Maybe<Scalars['String']['output']>;
  path2?: Maybe<Scalars['String']['output']>;
  path3?: Maybe<Scalars['String']['output']>;
  path4?: Maybe<Scalars['String']['output']>;
  path5?: Maybe<Scalars['String']['output']>;
  path6?: Maybe<Scalars['String']['output']>;
  path7?: Maybe<Scalars['String']['output']>;
  path8?: Maybe<Scalars['String']['output']>;
  path9?: Maybe<Scalars['String']['output']>;
  sum: CrawlUrlSumCalculation;
  urlContainsRepeatedPaths?: Maybe<Scalars['Boolean']['output']>;
  urlContainsUnderscores?: Maybe<Scalars['Boolean']['output']>;
  urlDoubleEncoded?: Maybe<Scalars['Boolean']['output']>;
  urlPathContainsDoubleSlashes?: Maybe<Scalars['Boolean']['output']>;
  viewport?: Maybe<Scalars['String']['output']>;
};

export type CrawlUrlAggregateConnection = {
  /** A list of nodes. */
  nodes: Array<CrawlUrlAggregate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlUrlAggregates in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** Used for grouping the CrawlUrl aggregates results. */
export enum CrawlUrlAggregateDimension {
  AdultRating = 'adultRating',
  Amphtml = 'amphtml',
  AmphtmlReciprocate = 'amphtmlReciprocate',
  Breadcrumb01 = 'breadcrumb01',
  Breadcrumb02 = 'breadcrumb02',
  Breadcrumb03 = 'breadcrumb03',
  Breadcrumb04 = 'breadcrumb04',
  Breadcrumb05 = 'breadcrumb05',
  Breadcrumb06 = 'breadcrumb06',
  Breadcrumb07 = 'breadcrumb07',
  Breadcrumb08 = 'breadcrumb08',
  CacheControl = 'cacheControl',
  CanonicalUrlIsConsistent = 'canonicalUrlIsConsistent',
  CanonicalizedPage = 'canonicalizedPage',
  Css = 'css',
  CustomExtractionMatch = 'customExtractionMatch',
  DisallowedPage = 'disallowedPage',
  DuplicateBody = 'duplicateBody',
  DuplicateBodyNonIndexable = 'duplicateBodyNonIndexable',
  DuplicateDescription = 'duplicateDescription',
  DuplicateDescriptionNonIndexable = 'duplicateDescriptionNonIndexable',
  DuplicatePage = 'duplicatePage',
  DuplicatePageNonIndexable = 'duplicatePageNonIndexable',
  DuplicateTitle = 'duplicateTitle',
  DuplicateTitleNonIndexable = 'duplicateTitleNonIndexable',
  DuplicateUrl = 'duplicateUrl',
  DuplicateUrlNonIndexable = 'duplicateUrlNonIndexable',
  FailedReason = 'failedReason',
  FileExtension = 'fileExtension',
  HeaderContentType = 'headerContentType',
  HttpStatusCode = 'httpStatusCode',
  Https = 'https',
  Indexable = 'indexable',
  IsImage = 'isImage',
  IsSelfCanonical = 'isSelfCanonical',
  IsWebLinked = 'isWebLinked',
  Js = 'js',
  Level = 'level',
  MetaContentType = 'metaContentType',
  NofollowedPage = 'nofollowedPage',
  Noindex = 'noindex',
  OgLocale = 'ogLocale',
  OgType = 'ogType',
  PageGroup = 'pageGroup',
  Path0 = 'path0',
  Path1 = 'path1',
  Path2 = 'path2',
  Path3 = 'path3',
  Path4 = 'path4',
  Path5 = 'path5',
  Path6 = 'path6',
  Path7 = 'path7',
  Path8 = 'path8',
  Path9 = 'path9',
  UrlContainsRepeatedPaths = 'urlContainsRepeatedPaths',
  UrlContainsUnderscores = 'urlContainsUnderscores',
  UrlDoubleEncoded = 'urlDoubleEncoded',
  UrlPathContainsDoubleSlashes = 'urlPathContainsDoubleSlashes',
  Viewport = 'viewport'
}

export type CrawlUrlAggregateOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The dimension or aggregation to order by. */
  field: CrawlUrlAggregateOrderField;
};

export enum CrawlUrlAggregateOrderField {
  AdultRating = 'adultRating',
  Amphtml = 'amphtml',
  AmphtmlReciprocate = 'amphtmlReciprocate',
  AvgAccessibilityAIssuesCount = 'avgAccessibilityAIssuesCount',
  AvgAccessibilityAaIssuesCount = 'avgAccessibilityAaIssuesCount',
  AvgAccessibilityAaaIssuesCount = 'avgAccessibilityAaaIssuesCount',
  AvgAccessibilityBpIssuesCount = 'avgAccessibilityBpIssuesCount',
  AvgAccessibilityCriticalIssuesCount = 'avgAccessibilityCriticalIssuesCount',
  AvgAccessibilityIncompleteIssuesCount = 'avgAccessibilityIncompleteIssuesCount',
  AvgAccessibilityIssuesCount = 'avgAccessibilityIssuesCount',
  AvgAccessibilityMinorIssuesCount = 'avgAccessibilityMinorIssuesCount',
  AvgAccessibilityModerateIssuesCount = 'avgAccessibilityModerateIssuesCount',
  AvgAccessibilitySeriousIssuesCount = 'avgAccessibilitySeriousIssuesCount',
  AvgBacklinkCount = 'avgBacklinkCount',
  AvgBacklinkDomainCount = 'avgBacklinkDomainCount',
  AvgBodyTagsInHeadCount = 'avgBodyTagsInHeadCount',
  AvgBreadcrumbCount = 'avgBreadcrumbCount',
  AvgBreadcrumbSchemaCount = 'avgBreadcrumbSchemaCount',
  AvgCanonicalLinksInCount = 'avgCanonicalLinksInCount',
  AvgContentHtmlRatio = 'avgContentHtmlRatio',
  AvgContentSize = 'avgContentSize',
  AvgCumulativeLayoutShift = 'avgCumulativeLayoutShift',
  AvgCumulativeLayoutShiftMainFrame = 'avgCumulativeLayoutShiftMainFrame',
  AvgCumulativeLayoutShiftScore = 'avgCumulativeLayoutShiftScore',
  AvgDeeprank = 'avgDeeprank',
  AvgDescriptionLength = 'avgDescriptionLength',
  AvgDescriptionLengthPx = 'avgDescriptionLengthPx',
  AvgDuplicateBodyCount = 'avgDuplicateBodyCount',
  AvgDuplicateDescriptionCount = 'avgDuplicateDescriptionCount',
  AvgDuplicatePageCount = 'avgDuplicatePageCount',
  AvgDuplicateTitleCount = 'avgDuplicateTitleCount',
  AvgDuplicateUrlCount = 'avgDuplicateUrlCount',
  AvgEventSchemaCount = 'avgEventSchemaCount',
  AvgExternalLinksCount = 'avgExternalLinksCount',
  AvgFailedAuditsCount = 'avgFailedAuditsCount',
  AvgFaqpageSchemaCount = 'avgFaqpageSchemaCount',
  AvgFetchTime = 'avgFetchTime',
  AvgFirstContentfulPaint = 'avgFirstContentfulPaint',
  AvgFirstContentfulPaintScore = 'avgFirstContentfulPaintScore',
  AvgFirstMeaningfulPaint = 'avgFirstMeaningfulPaint',
  AvgFolderCount = 'avgFolderCount',
  AvgFollowedExternalLinksOutCount = 'avgFollowedExternalLinksOutCount',
  AvgFollowedLinksInCount = 'avgFollowedLinksInCount',
  AvgGaAvgPageLoadTime = 'avgGaAvgPageLoadTime',
  AvgGaAvgTimeOnPage = 'avgGaAvgTimeOnPage',
  AvgGaPageviewsPerVisits = 'avgGaPageviewsPerVisits',
  AvgGaVisitBounceRate = 'avgGaVisitBounceRate',
  AvgGaVisits = 'avgGaVisits',
  AvgH1Count = 'avgH1Count',
  AvgH1Length = 'avgH1Length',
  AvgHeadTagsInBodyCount = 'avgHeadTagsInBodyCount',
  AvgHowtoSchemaCount = 'avgHowtoSchemaCount',
  AvgHreflangLinksInCount = 'avgHreflangLinksInCount',
  AvgHtmlSize = 'avgHtmlSize',
  AvgInfoAuditsCount = 'avgInfoAuditsCount',
  AvgInteractive = 'avgInteractive',
  AvgInternalLinksCount = 'avgInternalLinksCount',
  AvgLargestContentfulPaint = 'avgLargestContentfulPaint',
  AvgLargestContentfulPaintScore = 'avgLargestContentfulPaintScore',
  AvgLcpLoadEnd = 'avgLcpLoadEnd',
  AvgLcpLoadStart = 'avgLcpLoadStart',
  AvgLinksInCount = 'avgLinksInCount',
  AvgLinksOutCount = 'avgLinksOutCount',
  AvgLogRequestsDesktop = 'avgLogRequestsDesktop',
  AvgLogRequestsMobile = 'avgLogRequestsMobile',
  AvgLogRequestsTotal = 'avgLogRequestsTotal',
  AvgMaxPotentialFid = 'avgMaxPotentialFid',
  AvgMobileDesktopContentDifference = 'avgMobileDesktopContentDifference',
  AvgMobileDesktopLinksInDifference = 'avgMobileDesktopLinksInDifference',
  AvgMobileDesktopLinksOutDifference = 'avgMobileDesktopLinksOutDifference',
  AvgMobileLinksInCount = 'avgMobileLinksInCount',
  AvgNewsarticleSchemaCount = 'avgNewsarticleSchemaCount',
  AvgNonReciprocateHreflangOutCount = 'avgNonReciprocateHreflangOutCount',
  AvgPageTitleLength = 'avgPageTitleLength',
  AvgPageTitleLengthPx = 'avgPageTitleLengthPx',
  AvgPaginatedLinksInCount = 'avgPaginatedLinksInCount',
  AvgParamCount = 'avgParamCount',
  AvgPassedAuditsCount = 'avgPassedAuditsCount',
  AvgPerformanceCls = 'avgPerformanceCls',
  AvgPerformanceDcl = 'avgPerformanceDcl',
  AvgPerformanceFcp = 'avgPerformanceFcp',
  AvgPerformanceLcp = 'avgPerformanceLcp',
  AvgPerformanceTtfb = 'avgPerformanceTtfb',
  AvgPerformanceTti = 'avgPerformanceTti',
  AvgProductOfSavingsKibAndPageviews = 'avgProductOfSavingsKibAndPageviews',
  AvgProductOfSavingsSecsAndPageviews = 'avgProductOfSavingsSecsAndPageviews',
  AvgProductPriceSchemaValue = 'avgProductPriceSchemaValue',
  AvgProductRatingSchemaValue = 'avgProductRatingSchemaValue',
  AvgProductSchemaCount = 'avgProductSchemaCount',
  AvgQapageSchemaCount = 'avgQapageSchemaCount',
  AvgRecipeSchemaCount = 'avgRecipeSchemaCount',
  AvgReciprocateHreflangOutCount = 'avgReciprocateHreflangOutCount',
  AvgRedirectCount = 'avgRedirectCount',
  AvgRedirectsInCount = 'avgRedirectsInCount',
  AvgRelLinksInCount = 'avgRelLinksInCount',
  AvgRenderedLinkCountDifference = 'avgRenderedLinkCountDifference',
  AvgRenderedWordCountDifference = 'avgRenderedWordCountDifference',
  AvgResourceLinksInCount = 'avgResourceLinksInCount',
  AvgReviewSchemaCount = 'avgReviewSchemaCount',
  AvgSavingsKib = 'avgSavingsKib',
  AvgSavingsScore = 'avgSavingsScore',
  AvgSavingsSecs = 'avgSavingsSecs',
  AvgSearchConsoleDesktopClicks = 'avgSearchConsoleDesktopClicks',
  AvgSearchConsoleDesktopCtr = 'avgSearchConsoleDesktopCtr',
  AvgSearchConsoleDesktopImpressions = 'avgSearchConsoleDesktopImpressions',
  AvgSearchConsoleDesktopPosition = 'avgSearchConsoleDesktopPosition',
  AvgSearchConsoleMobileClicks = 'avgSearchConsoleMobileClicks',
  AvgSearchConsoleMobileCtr = 'avgSearchConsoleMobileCtr',
  AvgSearchConsoleMobileImpressions = 'avgSearchConsoleMobileImpressions',
  AvgSearchConsoleMobilePosition = 'avgSearchConsoleMobilePosition',
  AvgSearchConsoleTabletClicks = 'avgSearchConsoleTabletClicks',
  AvgSearchConsoleTabletCtr = 'avgSearchConsoleTabletCtr',
  AvgSearchConsoleTabletImpressions = 'avgSearchConsoleTabletImpressions',
  AvgSearchConsoleTabletPosition = 'avgSearchConsoleTabletPosition',
  AvgSearchConsoleTotalClicks = 'avgSearchConsoleTotalClicks',
  AvgSearchConsoleTotalCtr = 'avgSearchConsoleTotalCtr',
  AvgSearchConsoleTotalImpressions = 'avgSearchConsoleTotalImpressions',
  AvgSearchConsoleTotalPosition = 'avgSearchConsoleTotalPosition',
  AvgSitemapsInCount = 'avgSitemapsInCount',
  AvgSitespeedCls = 'avgSitespeedCls',
  AvgSitespeedClsMainFrame = 'avgSitespeedClsMainFrame',
  AvgSitespeedClsScore = 'avgSitespeedClsScore',
  AvgSitespeedFcp = 'avgSitespeedFcp',
  AvgSitespeedFcpScore = 'avgSitespeedFcpScore',
  AvgSitespeedFmp = 'avgSitespeedFmp',
  AvgSitespeedLcp = 'avgSitespeedLcp',
  AvgSitespeedLcpScore = 'avgSitespeedLcpScore',
  AvgSitespeedMaxPotentialFid = 'avgSitespeedMaxPotentialFid',
  AvgSitespeedScore = 'avgSitespeedScore',
  AvgSitespeedSpeedIndex = 'avgSitespeedSpeedIndex',
  AvgSitespeedSpeedIndexScore = 'avgSitespeedSpeedIndexScore',
  AvgSitespeedTbt = 'avgSitespeedTbt',
  AvgSitespeedTbtScore = 'avgSitespeedTbtScore',
  AvgSitespeedTtfb = 'avgSitespeedTtfb',
  AvgSitespeedTti = 'avgSitespeedTti',
  AvgSpeedIndex = 'avgSpeedIndex',
  AvgSpeedIndexScore = 'avgSpeedIndexScore',
  AvgTimeToFirstByte = 'avgTimeToFirstByte',
  AvgTotalBlockingTime = 'avgTotalBlockingTime',
  AvgTotalBlockingTimeScore = 'avgTotalBlockingTimeScore',
  AvgUniqueLinksInCount = 'avgUniqueLinksInCount',
  AvgVideoobjectSchemaCount = 'avgVideoobjectSchemaCount',
  AvgWarningAuditsCount = 'avgWarningAuditsCount',
  AvgWordCount = 'avgWordCount',
  Breadcrumb01 = 'breadcrumb01',
  Breadcrumb02 = 'breadcrumb02',
  Breadcrumb03 = 'breadcrumb03',
  Breadcrumb04 = 'breadcrumb04',
  Breadcrumb05 = 'breadcrumb05',
  Breadcrumb06 = 'breadcrumb06',
  Breadcrumb07 = 'breadcrumb07',
  Breadcrumb08 = 'breadcrumb08',
  CacheControl = 'cacheControl',
  CanonicalUrlIsConsistent = 'canonicalUrlIsConsistent',
  CanonicalizedPage = 'canonicalizedPage',
  CountAccessibilityAIssuesCount = 'countAccessibilityAIssuesCount',
  CountAccessibilityAaIssuesCount = 'countAccessibilityAaIssuesCount',
  CountAccessibilityAaaIssuesCount = 'countAccessibilityAaaIssuesCount',
  CountAccessibilityBpIssuesCount = 'countAccessibilityBpIssuesCount',
  CountAccessibilityCriticalIssuesCount = 'countAccessibilityCriticalIssuesCount',
  CountAccessibilityIncompleteIssuesCount = 'countAccessibilityIncompleteIssuesCount',
  CountAccessibilityIssuesCount = 'countAccessibilityIssuesCount',
  CountAccessibilityMinorIssuesCount = 'countAccessibilityMinorIssuesCount',
  CountAccessibilityModerateIssuesCount = 'countAccessibilityModerateIssuesCount',
  CountAccessibilitySeriousIssuesCount = 'countAccessibilitySeriousIssuesCount',
  CountBacklinkCount = 'countBacklinkCount',
  CountBacklinkDomainCount = 'countBacklinkDomainCount',
  CountBodyTagsInHeadCount = 'countBodyTagsInHeadCount',
  CountBreadcrumbCount = 'countBreadcrumbCount',
  CountBreadcrumbSchemaCount = 'countBreadcrumbSchemaCount',
  CountCanonicalLinksInCount = 'countCanonicalLinksInCount',
  CountContentHtmlRatio = 'countContentHtmlRatio',
  CountContentSize = 'countContentSize',
  CountCumulativeLayoutShift = 'countCumulativeLayoutShift',
  CountCumulativeLayoutShiftMainFrame = 'countCumulativeLayoutShiftMainFrame',
  CountCumulativeLayoutShiftScore = 'countCumulativeLayoutShiftScore',
  CountDeeprank = 'countDeeprank',
  CountDescriptionLength = 'countDescriptionLength',
  CountDescriptionLengthPx = 'countDescriptionLengthPx',
  CountDuplicateBodyCount = 'countDuplicateBodyCount',
  CountDuplicateDescriptionCount = 'countDuplicateDescriptionCount',
  CountDuplicatePageCount = 'countDuplicatePageCount',
  CountDuplicateTitleCount = 'countDuplicateTitleCount',
  CountDuplicateUrlCount = 'countDuplicateUrlCount',
  CountEventSchemaCount = 'countEventSchemaCount',
  CountExternalLinksCount = 'countExternalLinksCount',
  CountFailedAuditsCount = 'countFailedAuditsCount',
  CountFaqpageSchemaCount = 'countFaqpageSchemaCount',
  CountFetchTime = 'countFetchTime',
  CountFirstContentfulPaint = 'countFirstContentfulPaint',
  CountFirstContentfulPaintScore = 'countFirstContentfulPaintScore',
  CountFirstMeaningfulPaint = 'countFirstMeaningfulPaint',
  CountFolderCount = 'countFolderCount',
  CountFollowedExternalLinksOutCount = 'countFollowedExternalLinksOutCount',
  CountFollowedLinksInCount = 'countFollowedLinksInCount',
  CountFoundAtSitemap = 'countFoundAtSitemap',
  CountGaAvgPageLoadTime = 'countGaAvgPageLoadTime',
  CountGaAvgTimeOnPage = 'countGaAvgTimeOnPage',
  CountGaPageviewsPerVisits = 'countGaPageviewsPerVisits',
  CountGaVisitBounceRate = 'countGaVisitBounceRate',
  CountGaVisits = 'countGaVisits',
  CountH1Count = 'countH1Count',
  CountH1Length = 'countH1Length',
  CountHeadTagsInBodyCount = 'countHeadTagsInBodyCount',
  CountHowtoSchemaCount = 'countHowtoSchemaCount',
  CountHreflangLinksInCount = 'countHreflangLinksInCount',
  CountHtmlSize = 'countHtmlSize',
  CountInfoAuditsCount = 'countInfoAuditsCount',
  CountInteractive = 'countInteractive',
  CountInternalLinksCount = 'countInternalLinksCount',
  CountLargestContentfulPaint = 'countLargestContentfulPaint',
  CountLargestContentfulPaintScore = 'countLargestContentfulPaintScore',
  CountLcpLoadEnd = 'countLcpLoadEnd',
  CountLcpLoadStart = 'countLcpLoadStart',
  CountLinksInCount = 'countLinksInCount',
  CountLinksOutCount = 'countLinksOutCount',
  CountLogRequestsDesktop = 'countLogRequestsDesktop',
  CountLogRequestsMobile = 'countLogRequestsMobile',
  CountLogRequestsTotal = 'countLogRequestsTotal',
  CountMaxPotentialFid = 'countMaxPotentialFid',
  CountMobileDesktopContentDifference = 'countMobileDesktopContentDifference',
  CountMobileDesktopLinksInDifference = 'countMobileDesktopLinksInDifference',
  CountMobileDesktopLinksOutDifference = 'countMobileDesktopLinksOutDifference',
  CountMobileLinksInCount = 'countMobileLinksInCount',
  CountNewsarticleSchemaCount = 'countNewsarticleSchemaCount',
  CountNonReciprocateHreflangOutCount = 'countNonReciprocateHreflangOutCount',
  CountPageTitleLength = 'countPageTitleLength',
  CountPageTitleLengthPx = 'countPageTitleLengthPx',
  CountPaginatedLinksInCount = 'countPaginatedLinksInCount',
  CountParamCount = 'countParamCount',
  CountPassedAuditsCount = 'countPassedAuditsCount',
  CountPerformanceCls = 'countPerformanceCls',
  CountPerformanceDcl = 'countPerformanceDcl',
  CountPerformanceFcp = 'countPerformanceFcp',
  CountPerformanceLcp = 'countPerformanceLcp',
  CountPerformanceTtfb = 'countPerformanceTtfb',
  CountPerformanceTti = 'countPerformanceTti',
  CountProductOfSavingsKibAndPageviews = 'countProductOfSavingsKibAndPageviews',
  CountProductOfSavingsSecsAndPageviews = 'countProductOfSavingsSecsAndPageviews',
  CountProductPriceSchemaValue = 'countProductPriceSchemaValue',
  CountProductRatingSchemaValue = 'countProductRatingSchemaValue',
  CountProductSchemaCount = 'countProductSchemaCount',
  CountQapageSchemaCount = 'countQapageSchemaCount',
  CountRecipeSchemaCount = 'countRecipeSchemaCount',
  CountReciprocateHreflangOutCount = 'countReciprocateHreflangOutCount',
  CountRedirectCount = 'countRedirectCount',
  CountRedirectsInCount = 'countRedirectsInCount',
  CountRelLinksInCount = 'countRelLinksInCount',
  CountRenderedLinkCountDifference = 'countRenderedLinkCountDifference',
  CountRenderedWordCountDifference = 'countRenderedWordCountDifference',
  CountResourceLinksInCount = 'countResourceLinksInCount',
  CountReviewSchemaCount = 'countReviewSchemaCount',
  CountSavingsKib = 'countSavingsKib',
  CountSavingsScore = 'countSavingsScore',
  CountSavingsSecs = 'countSavingsSecs',
  CountSearchConsoleDesktopClicks = 'countSearchConsoleDesktopClicks',
  CountSearchConsoleDesktopCtr = 'countSearchConsoleDesktopCtr',
  CountSearchConsoleDesktopImpressions = 'countSearchConsoleDesktopImpressions',
  CountSearchConsoleDesktopPosition = 'countSearchConsoleDesktopPosition',
  CountSearchConsoleMobileClicks = 'countSearchConsoleMobileClicks',
  CountSearchConsoleMobileCtr = 'countSearchConsoleMobileCtr',
  CountSearchConsoleMobileImpressions = 'countSearchConsoleMobileImpressions',
  CountSearchConsoleMobilePosition = 'countSearchConsoleMobilePosition',
  CountSearchConsoleTabletClicks = 'countSearchConsoleTabletClicks',
  CountSearchConsoleTabletCtr = 'countSearchConsoleTabletCtr',
  CountSearchConsoleTabletImpressions = 'countSearchConsoleTabletImpressions',
  CountSearchConsoleTabletPosition = 'countSearchConsoleTabletPosition',
  CountSearchConsoleTotalClicks = 'countSearchConsoleTotalClicks',
  CountSearchConsoleTotalCtr = 'countSearchConsoleTotalCtr',
  CountSearchConsoleTotalImpressions = 'countSearchConsoleTotalImpressions',
  CountSearchConsoleTotalPosition = 'countSearchConsoleTotalPosition',
  CountSitemapsInCount = 'countSitemapsInCount',
  CountSitespeedCls = 'countSitespeedCls',
  CountSitespeedClsMainFrame = 'countSitespeedClsMainFrame',
  CountSitespeedClsScore = 'countSitespeedClsScore',
  CountSitespeedFcp = 'countSitespeedFcp',
  CountSitespeedFcpScore = 'countSitespeedFcpScore',
  CountSitespeedFmp = 'countSitespeedFmp',
  CountSitespeedLcp = 'countSitespeedLcp',
  CountSitespeedLcpScore = 'countSitespeedLcpScore',
  CountSitespeedMaxPotentialFid = 'countSitespeedMaxPotentialFid',
  CountSitespeedScore = 'countSitespeedScore',
  CountSitespeedSpeedIndex = 'countSitespeedSpeedIndex',
  CountSitespeedSpeedIndexScore = 'countSitespeedSpeedIndexScore',
  CountSitespeedTbt = 'countSitespeedTbt',
  CountSitespeedTbtScore = 'countSitespeedTbtScore',
  CountSitespeedTtfb = 'countSitespeedTtfb',
  CountSitespeedTti = 'countSitespeedTti',
  CountSpeedIndex = 'countSpeedIndex',
  CountSpeedIndexScore = 'countSpeedIndexScore',
  CountTimeToFirstByte = 'countTimeToFirstByte',
  CountTotalBlockingTime = 'countTotalBlockingTime',
  CountTotalBlockingTimeScore = 'countTotalBlockingTimeScore',
  CountUniqueLinksInCount = 'countUniqueLinksInCount',
  CountUrl = 'countUrl',
  CountVideoobjectSchemaCount = 'countVideoobjectSchemaCount',
  CountWarningAuditsCount = 'countWarningAuditsCount',
  CountWordCount = 'countWordCount',
  Css = 'css',
  CustomExtractionMatch = 'customExtractionMatch',
  DisallowedPage = 'disallowedPage',
  DuplicateBody = 'duplicateBody',
  DuplicateBodyNonIndexable = 'duplicateBodyNonIndexable',
  DuplicateDescription = 'duplicateDescription',
  DuplicateDescriptionNonIndexable = 'duplicateDescriptionNonIndexable',
  DuplicatePage = 'duplicatePage',
  DuplicatePageNonIndexable = 'duplicatePageNonIndexable',
  DuplicateTitle = 'duplicateTitle',
  DuplicateTitleNonIndexable = 'duplicateTitleNonIndexable',
  DuplicateUrl = 'duplicateUrl',
  DuplicateUrlNonIndexable = 'duplicateUrlNonIndexable',
  FailedReason = 'failedReason',
  FileExtension = 'fileExtension',
  HeaderContentType = 'headerContentType',
  HttpStatusCode = 'httpStatusCode',
  Https = 'https',
  Indexable = 'indexable',
  IsImage = 'isImage',
  IsSelfCanonical = 'isSelfCanonical',
  IsWebLinked = 'isWebLinked',
  Js = 'js',
  Level = 'level',
  MaxAccessibilityAIssuesCount = 'maxAccessibilityAIssuesCount',
  MaxAccessibilityAaIssuesCount = 'maxAccessibilityAaIssuesCount',
  MaxAccessibilityAaaIssuesCount = 'maxAccessibilityAaaIssuesCount',
  MaxAccessibilityBpIssuesCount = 'maxAccessibilityBpIssuesCount',
  MaxAccessibilityCriticalIssuesCount = 'maxAccessibilityCriticalIssuesCount',
  MaxAccessibilityIncompleteIssuesCount = 'maxAccessibilityIncompleteIssuesCount',
  MaxAccessibilityIssuesCount = 'maxAccessibilityIssuesCount',
  MaxAccessibilityMinorIssuesCount = 'maxAccessibilityMinorIssuesCount',
  MaxAccessibilityModerateIssuesCount = 'maxAccessibilityModerateIssuesCount',
  MaxAccessibilitySeriousIssuesCount = 'maxAccessibilitySeriousIssuesCount',
  MaxBacklinkCount = 'maxBacklinkCount',
  MaxBacklinkDomainCount = 'maxBacklinkDomainCount',
  MaxBodyTagsInHeadCount = 'maxBodyTagsInHeadCount',
  MaxBreadcrumbCount = 'maxBreadcrumbCount',
  MaxBreadcrumbSchemaCount = 'maxBreadcrumbSchemaCount',
  MaxCanonicalLinksInCount = 'maxCanonicalLinksInCount',
  MaxContentHtmlRatio = 'maxContentHtmlRatio',
  MaxContentSize = 'maxContentSize',
  MaxCumulativeLayoutShift = 'maxCumulativeLayoutShift',
  MaxCumulativeLayoutShiftMainFrame = 'maxCumulativeLayoutShiftMainFrame',
  MaxCumulativeLayoutShiftScore = 'maxCumulativeLayoutShiftScore',
  MaxDeeprank = 'maxDeeprank',
  MaxDescriptionLength = 'maxDescriptionLength',
  MaxDescriptionLengthPx = 'maxDescriptionLengthPx',
  MaxDuplicateBodyCount = 'maxDuplicateBodyCount',
  MaxDuplicateDescriptionCount = 'maxDuplicateDescriptionCount',
  MaxDuplicatePageCount = 'maxDuplicatePageCount',
  MaxDuplicateTitleCount = 'maxDuplicateTitleCount',
  MaxDuplicateUrlCount = 'maxDuplicateUrlCount',
  MaxEventSchemaCount = 'maxEventSchemaCount',
  MaxExternalLinksCount = 'maxExternalLinksCount',
  MaxFailedAuditsCount = 'maxFailedAuditsCount',
  MaxFaqpageSchemaCount = 'maxFaqpageSchemaCount',
  MaxFetchTime = 'maxFetchTime',
  MaxFirstContentfulPaint = 'maxFirstContentfulPaint',
  MaxFirstContentfulPaintScore = 'maxFirstContentfulPaintScore',
  MaxFirstMeaningfulPaint = 'maxFirstMeaningfulPaint',
  MaxFolderCount = 'maxFolderCount',
  MaxFollowedExternalLinksOutCount = 'maxFollowedExternalLinksOutCount',
  MaxFollowedLinksInCount = 'maxFollowedLinksInCount',
  MaxGaAvgPageLoadTime = 'maxGaAvgPageLoadTime',
  MaxGaAvgTimeOnPage = 'maxGaAvgTimeOnPage',
  MaxGaPageviewsPerVisits = 'maxGaPageviewsPerVisits',
  MaxGaVisitBounceRate = 'maxGaVisitBounceRate',
  MaxGaVisits = 'maxGaVisits',
  MaxH1Count = 'maxH1Count',
  MaxH1Length = 'maxH1Length',
  MaxHeadTagsInBodyCount = 'maxHeadTagsInBodyCount',
  MaxHowtoSchemaCount = 'maxHowtoSchemaCount',
  MaxHreflangLinksInCount = 'maxHreflangLinksInCount',
  MaxHtmlSize = 'maxHtmlSize',
  MaxInfoAuditsCount = 'maxInfoAuditsCount',
  MaxInteractive = 'maxInteractive',
  MaxInternalLinksCount = 'maxInternalLinksCount',
  MaxLargestContentfulPaint = 'maxLargestContentfulPaint',
  MaxLargestContentfulPaintScore = 'maxLargestContentfulPaintScore',
  MaxLcpLoadEnd = 'maxLcpLoadEnd',
  MaxLcpLoadStart = 'maxLcpLoadStart',
  MaxLinksInCount = 'maxLinksInCount',
  MaxLinksOutCount = 'maxLinksOutCount',
  MaxLogRequestsDesktop = 'maxLogRequestsDesktop',
  MaxLogRequestsMobile = 'maxLogRequestsMobile',
  MaxLogRequestsTotal = 'maxLogRequestsTotal',
  MaxMaxPotentialFid = 'maxMaxPotentialFid',
  MaxMobileDesktopContentDifference = 'maxMobileDesktopContentDifference',
  MaxMobileDesktopLinksInDifference = 'maxMobileDesktopLinksInDifference',
  MaxMobileDesktopLinksOutDifference = 'maxMobileDesktopLinksOutDifference',
  MaxMobileLinksInCount = 'maxMobileLinksInCount',
  MaxNewsarticleSchemaCount = 'maxNewsarticleSchemaCount',
  MaxNonReciprocateHreflangOutCount = 'maxNonReciprocateHreflangOutCount',
  MaxPageTitleLength = 'maxPageTitleLength',
  MaxPageTitleLengthPx = 'maxPageTitleLengthPx',
  MaxPaginatedLinksInCount = 'maxPaginatedLinksInCount',
  MaxParamCount = 'maxParamCount',
  MaxPassedAuditsCount = 'maxPassedAuditsCount',
  MaxPerformanceCls = 'maxPerformanceCls',
  MaxPerformanceDcl = 'maxPerformanceDcl',
  MaxPerformanceFcp = 'maxPerformanceFcp',
  MaxPerformanceLcp = 'maxPerformanceLcp',
  MaxPerformanceTtfb = 'maxPerformanceTtfb',
  MaxPerformanceTti = 'maxPerformanceTti',
  MaxProductOfSavingsKibAndPageviews = 'maxProductOfSavingsKibAndPageviews',
  MaxProductOfSavingsSecsAndPageviews = 'maxProductOfSavingsSecsAndPageviews',
  MaxProductPriceSchemaValue = 'maxProductPriceSchemaValue',
  MaxProductRatingSchemaValue = 'maxProductRatingSchemaValue',
  MaxProductSchemaCount = 'maxProductSchemaCount',
  MaxQapageSchemaCount = 'maxQapageSchemaCount',
  MaxRecipeSchemaCount = 'maxRecipeSchemaCount',
  MaxReciprocateHreflangOutCount = 'maxReciprocateHreflangOutCount',
  MaxRedirectCount = 'maxRedirectCount',
  MaxRedirectsInCount = 'maxRedirectsInCount',
  MaxRelLinksInCount = 'maxRelLinksInCount',
  MaxRenderedLinkCountDifference = 'maxRenderedLinkCountDifference',
  MaxRenderedWordCountDifference = 'maxRenderedWordCountDifference',
  MaxResourceLinksInCount = 'maxResourceLinksInCount',
  MaxReviewSchemaCount = 'maxReviewSchemaCount',
  MaxSavingsKib = 'maxSavingsKib',
  MaxSavingsScore = 'maxSavingsScore',
  MaxSavingsSecs = 'maxSavingsSecs',
  MaxSearchConsoleDesktopClicks = 'maxSearchConsoleDesktopClicks',
  MaxSearchConsoleDesktopCtr = 'maxSearchConsoleDesktopCtr',
  MaxSearchConsoleDesktopImpressions = 'maxSearchConsoleDesktopImpressions',
  MaxSearchConsoleDesktopPosition = 'maxSearchConsoleDesktopPosition',
  MaxSearchConsoleMobileClicks = 'maxSearchConsoleMobileClicks',
  MaxSearchConsoleMobileCtr = 'maxSearchConsoleMobileCtr',
  MaxSearchConsoleMobileImpressions = 'maxSearchConsoleMobileImpressions',
  MaxSearchConsoleMobilePosition = 'maxSearchConsoleMobilePosition',
  MaxSearchConsoleTabletClicks = 'maxSearchConsoleTabletClicks',
  MaxSearchConsoleTabletCtr = 'maxSearchConsoleTabletCtr',
  MaxSearchConsoleTabletImpressions = 'maxSearchConsoleTabletImpressions',
  MaxSearchConsoleTabletPosition = 'maxSearchConsoleTabletPosition',
  MaxSearchConsoleTotalClicks = 'maxSearchConsoleTotalClicks',
  MaxSearchConsoleTotalCtr = 'maxSearchConsoleTotalCtr',
  MaxSearchConsoleTotalImpressions = 'maxSearchConsoleTotalImpressions',
  MaxSearchConsoleTotalPosition = 'maxSearchConsoleTotalPosition',
  MaxSitemapsInCount = 'maxSitemapsInCount',
  MaxSitespeedCls = 'maxSitespeedCls',
  MaxSitespeedClsMainFrame = 'maxSitespeedClsMainFrame',
  MaxSitespeedClsScore = 'maxSitespeedClsScore',
  MaxSitespeedFcp = 'maxSitespeedFcp',
  MaxSitespeedFcpScore = 'maxSitespeedFcpScore',
  MaxSitespeedFmp = 'maxSitespeedFmp',
  MaxSitespeedLcp = 'maxSitespeedLcp',
  MaxSitespeedLcpScore = 'maxSitespeedLcpScore',
  MaxSitespeedMaxPotentialFid = 'maxSitespeedMaxPotentialFid',
  MaxSitespeedScore = 'maxSitespeedScore',
  MaxSitespeedSpeedIndex = 'maxSitespeedSpeedIndex',
  MaxSitespeedSpeedIndexScore = 'maxSitespeedSpeedIndexScore',
  MaxSitespeedTbt = 'maxSitespeedTbt',
  MaxSitespeedTbtScore = 'maxSitespeedTbtScore',
  MaxSitespeedTtfb = 'maxSitespeedTtfb',
  MaxSitespeedTti = 'maxSitespeedTti',
  MaxSpeedIndex = 'maxSpeedIndex',
  MaxSpeedIndexScore = 'maxSpeedIndexScore',
  MaxTimeToFirstByte = 'maxTimeToFirstByte',
  MaxTotalBlockingTime = 'maxTotalBlockingTime',
  MaxTotalBlockingTimeScore = 'maxTotalBlockingTimeScore',
  MaxUniqueLinksInCount = 'maxUniqueLinksInCount',
  MaxVideoobjectSchemaCount = 'maxVideoobjectSchemaCount',
  MaxWarningAuditsCount = 'maxWarningAuditsCount',
  MaxWordCount = 'maxWordCount',
  MetaContentType = 'metaContentType',
  MinAccessibilityAIssuesCount = 'minAccessibilityAIssuesCount',
  MinAccessibilityAaIssuesCount = 'minAccessibilityAaIssuesCount',
  MinAccessibilityAaaIssuesCount = 'minAccessibilityAaaIssuesCount',
  MinAccessibilityBpIssuesCount = 'minAccessibilityBpIssuesCount',
  MinAccessibilityCriticalIssuesCount = 'minAccessibilityCriticalIssuesCount',
  MinAccessibilityIncompleteIssuesCount = 'minAccessibilityIncompleteIssuesCount',
  MinAccessibilityIssuesCount = 'minAccessibilityIssuesCount',
  MinAccessibilityMinorIssuesCount = 'minAccessibilityMinorIssuesCount',
  MinAccessibilityModerateIssuesCount = 'minAccessibilityModerateIssuesCount',
  MinAccessibilitySeriousIssuesCount = 'minAccessibilitySeriousIssuesCount',
  MinBacklinkCount = 'minBacklinkCount',
  MinBacklinkDomainCount = 'minBacklinkDomainCount',
  MinBodyTagsInHeadCount = 'minBodyTagsInHeadCount',
  MinBreadcrumbCount = 'minBreadcrumbCount',
  MinBreadcrumbSchemaCount = 'minBreadcrumbSchemaCount',
  MinCanonicalLinksInCount = 'minCanonicalLinksInCount',
  MinContentHtmlRatio = 'minContentHtmlRatio',
  MinContentSize = 'minContentSize',
  MinCumulativeLayoutShift = 'minCumulativeLayoutShift',
  MinCumulativeLayoutShiftMainFrame = 'minCumulativeLayoutShiftMainFrame',
  MinCumulativeLayoutShiftScore = 'minCumulativeLayoutShiftScore',
  MinDeeprank = 'minDeeprank',
  MinDescriptionLength = 'minDescriptionLength',
  MinDescriptionLengthPx = 'minDescriptionLengthPx',
  MinDuplicateBodyCount = 'minDuplicateBodyCount',
  MinDuplicateDescriptionCount = 'minDuplicateDescriptionCount',
  MinDuplicatePageCount = 'minDuplicatePageCount',
  MinDuplicateTitleCount = 'minDuplicateTitleCount',
  MinDuplicateUrlCount = 'minDuplicateUrlCount',
  MinEventSchemaCount = 'minEventSchemaCount',
  MinExternalLinksCount = 'minExternalLinksCount',
  MinFailedAuditsCount = 'minFailedAuditsCount',
  MinFaqpageSchemaCount = 'minFaqpageSchemaCount',
  MinFetchTime = 'minFetchTime',
  MinFirstContentfulPaint = 'minFirstContentfulPaint',
  MinFirstContentfulPaintScore = 'minFirstContentfulPaintScore',
  MinFirstMeaningfulPaint = 'minFirstMeaningfulPaint',
  MinFolderCount = 'minFolderCount',
  MinFollowedExternalLinksOutCount = 'minFollowedExternalLinksOutCount',
  MinFollowedLinksInCount = 'minFollowedLinksInCount',
  MinGaAvgPageLoadTime = 'minGaAvgPageLoadTime',
  MinGaAvgTimeOnPage = 'minGaAvgTimeOnPage',
  MinGaPageviewsPerVisits = 'minGaPageviewsPerVisits',
  MinGaVisitBounceRate = 'minGaVisitBounceRate',
  MinGaVisits = 'minGaVisits',
  MinH1Count = 'minH1Count',
  MinH1Length = 'minH1Length',
  MinHeadTagsInBodyCount = 'minHeadTagsInBodyCount',
  MinHowtoSchemaCount = 'minHowtoSchemaCount',
  MinHreflangLinksInCount = 'minHreflangLinksInCount',
  MinHtmlSize = 'minHtmlSize',
  MinInfoAuditsCount = 'minInfoAuditsCount',
  MinInteractive = 'minInteractive',
  MinInternalLinksCount = 'minInternalLinksCount',
  MinLargestContentfulPaint = 'minLargestContentfulPaint',
  MinLargestContentfulPaintScore = 'minLargestContentfulPaintScore',
  MinLcpLoadEnd = 'minLcpLoadEnd',
  MinLcpLoadStart = 'minLcpLoadStart',
  MinLinksInCount = 'minLinksInCount',
  MinLinksOutCount = 'minLinksOutCount',
  MinLogRequestsDesktop = 'minLogRequestsDesktop',
  MinLogRequestsMobile = 'minLogRequestsMobile',
  MinLogRequestsTotal = 'minLogRequestsTotal',
  MinMaxPotentialFid = 'minMaxPotentialFid',
  MinMobileDesktopContentDifference = 'minMobileDesktopContentDifference',
  MinMobileDesktopLinksInDifference = 'minMobileDesktopLinksInDifference',
  MinMobileDesktopLinksOutDifference = 'minMobileDesktopLinksOutDifference',
  MinMobileLinksInCount = 'minMobileLinksInCount',
  MinNewsarticleSchemaCount = 'minNewsarticleSchemaCount',
  MinNonReciprocateHreflangOutCount = 'minNonReciprocateHreflangOutCount',
  MinPageTitleLength = 'minPageTitleLength',
  MinPageTitleLengthPx = 'minPageTitleLengthPx',
  MinPaginatedLinksInCount = 'minPaginatedLinksInCount',
  MinParamCount = 'minParamCount',
  MinPassedAuditsCount = 'minPassedAuditsCount',
  MinPerformanceCls = 'minPerformanceCls',
  MinPerformanceDcl = 'minPerformanceDcl',
  MinPerformanceFcp = 'minPerformanceFcp',
  MinPerformanceLcp = 'minPerformanceLcp',
  MinPerformanceTtfb = 'minPerformanceTtfb',
  MinPerformanceTti = 'minPerformanceTti',
  MinProductOfSavingsKibAndPageviews = 'minProductOfSavingsKibAndPageviews',
  MinProductOfSavingsSecsAndPageviews = 'minProductOfSavingsSecsAndPageviews',
  MinProductPriceSchemaValue = 'minProductPriceSchemaValue',
  MinProductRatingSchemaValue = 'minProductRatingSchemaValue',
  MinProductSchemaCount = 'minProductSchemaCount',
  MinQapageSchemaCount = 'minQapageSchemaCount',
  MinRecipeSchemaCount = 'minRecipeSchemaCount',
  MinReciprocateHreflangOutCount = 'minReciprocateHreflangOutCount',
  MinRedirectCount = 'minRedirectCount',
  MinRedirectsInCount = 'minRedirectsInCount',
  MinRelLinksInCount = 'minRelLinksInCount',
  MinRenderedLinkCountDifference = 'minRenderedLinkCountDifference',
  MinRenderedWordCountDifference = 'minRenderedWordCountDifference',
  MinResourceLinksInCount = 'minResourceLinksInCount',
  MinReviewSchemaCount = 'minReviewSchemaCount',
  MinSavingsKib = 'minSavingsKib',
  MinSavingsScore = 'minSavingsScore',
  MinSavingsSecs = 'minSavingsSecs',
  MinSearchConsoleDesktopClicks = 'minSearchConsoleDesktopClicks',
  MinSearchConsoleDesktopCtr = 'minSearchConsoleDesktopCtr',
  MinSearchConsoleDesktopImpressions = 'minSearchConsoleDesktopImpressions',
  MinSearchConsoleDesktopPosition = 'minSearchConsoleDesktopPosition',
  MinSearchConsoleMobileClicks = 'minSearchConsoleMobileClicks',
  MinSearchConsoleMobileCtr = 'minSearchConsoleMobileCtr',
  MinSearchConsoleMobileImpressions = 'minSearchConsoleMobileImpressions',
  MinSearchConsoleMobilePosition = 'minSearchConsoleMobilePosition',
  MinSearchConsoleTabletClicks = 'minSearchConsoleTabletClicks',
  MinSearchConsoleTabletCtr = 'minSearchConsoleTabletCtr',
  MinSearchConsoleTabletImpressions = 'minSearchConsoleTabletImpressions',
  MinSearchConsoleTabletPosition = 'minSearchConsoleTabletPosition',
  MinSearchConsoleTotalClicks = 'minSearchConsoleTotalClicks',
  MinSearchConsoleTotalCtr = 'minSearchConsoleTotalCtr',
  MinSearchConsoleTotalImpressions = 'minSearchConsoleTotalImpressions',
  MinSearchConsoleTotalPosition = 'minSearchConsoleTotalPosition',
  MinSitemapsInCount = 'minSitemapsInCount',
  MinSitespeedCls = 'minSitespeedCls',
  MinSitespeedClsMainFrame = 'minSitespeedClsMainFrame',
  MinSitespeedClsScore = 'minSitespeedClsScore',
  MinSitespeedFcp = 'minSitespeedFcp',
  MinSitespeedFcpScore = 'minSitespeedFcpScore',
  MinSitespeedFmp = 'minSitespeedFmp',
  MinSitespeedLcp = 'minSitespeedLcp',
  MinSitespeedLcpScore = 'minSitespeedLcpScore',
  MinSitespeedMaxPotentialFid = 'minSitespeedMaxPotentialFid',
  MinSitespeedScore = 'minSitespeedScore',
  MinSitespeedSpeedIndex = 'minSitespeedSpeedIndex',
  MinSitespeedSpeedIndexScore = 'minSitespeedSpeedIndexScore',
  MinSitespeedTbt = 'minSitespeedTbt',
  MinSitespeedTbtScore = 'minSitespeedTbtScore',
  MinSitespeedTtfb = 'minSitespeedTtfb',
  MinSitespeedTti = 'minSitespeedTti',
  MinSpeedIndex = 'minSpeedIndex',
  MinSpeedIndexScore = 'minSpeedIndexScore',
  MinTimeToFirstByte = 'minTimeToFirstByte',
  MinTotalBlockingTime = 'minTotalBlockingTime',
  MinTotalBlockingTimeScore = 'minTotalBlockingTimeScore',
  MinUniqueLinksInCount = 'minUniqueLinksInCount',
  MinVideoobjectSchemaCount = 'minVideoobjectSchemaCount',
  MinWarningAuditsCount = 'minWarningAuditsCount',
  MinWordCount = 'minWordCount',
  NofollowedPage = 'nofollowedPage',
  Noindex = 'noindex',
  OgLocale = 'ogLocale',
  OgType = 'ogType',
  PageGroup = 'pageGroup',
  Path0 = 'path0',
  Path1 = 'path1',
  Path2 = 'path2',
  Path3 = 'path3',
  Path4 = 'path4',
  Path5 = 'path5',
  Path6 = 'path6',
  Path7 = 'path7',
  Path8 = 'path8',
  Path9 = 'path9',
  SumAccessibilityAIssuesCount = 'sumAccessibilityAIssuesCount',
  SumAccessibilityAaIssuesCount = 'sumAccessibilityAaIssuesCount',
  SumAccessibilityAaaIssuesCount = 'sumAccessibilityAaaIssuesCount',
  SumAccessibilityBpIssuesCount = 'sumAccessibilityBpIssuesCount',
  SumAccessibilityCriticalIssuesCount = 'sumAccessibilityCriticalIssuesCount',
  SumAccessibilityIncompleteIssuesCount = 'sumAccessibilityIncompleteIssuesCount',
  SumAccessibilityIssuesCount = 'sumAccessibilityIssuesCount',
  SumAccessibilityMinorIssuesCount = 'sumAccessibilityMinorIssuesCount',
  SumAccessibilityModerateIssuesCount = 'sumAccessibilityModerateIssuesCount',
  SumAccessibilitySeriousIssuesCount = 'sumAccessibilitySeriousIssuesCount',
  SumBacklinkCount = 'sumBacklinkCount',
  SumBacklinkDomainCount = 'sumBacklinkDomainCount',
  SumBodyTagsInHeadCount = 'sumBodyTagsInHeadCount',
  SumBreadcrumbCount = 'sumBreadcrumbCount',
  SumBreadcrumbSchemaCount = 'sumBreadcrumbSchemaCount',
  SumCanonicalLinksInCount = 'sumCanonicalLinksInCount',
  SumContentHtmlRatio = 'sumContentHtmlRatio',
  SumContentSize = 'sumContentSize',
  SumCumulativeLayoutShift = 'sumCumulativeLayoutShift',
  SumCumulativeLayoutShiftMainFrame = 'sumCumulativeLayoutShiftMainFrame',
  SumCumulativeLayoutShiftScore = 'sumCumulativeLayoutShiftScore',
  SumDeeprank = 'sumDeeprank',
  SumDescriptionLength = 'sumDescriptionLength',
  SumDescriptionLengthPx = 'sumDescriptionLengthPx',
  SumDuplicateBodyCount = 'sumDuplicateBodyCount',
  SumDuplicateDescriptionCount = 'sumDuplicateDescriptionCount',
  SumDuplicatePageCount = 'sumDuplicatePageCount',
  SumDuplicateTitleCount = 'sumDuplicateTitleCount',
  SumDuplicateUrlCount = 'sumDuplicateUrlCount',
  SumEventSchemaCount = 'sumEventSchemaCount',
  SumExternalLinksCount = 'sumExternalLinksCount',
  SumFailedAuditsCount = 'sumFailedAuditsCount',
  SumFaqpageSchemaCount = 'sumFaqpageSchemaCount',
  SumFetchTime = 'sumFetchTime',
  SumFirstContentfulPaint = 'sumFirstContentfulPaint',
  SumFirstContentfulPaintScore = 'sumFirstContentfulPaintScore',
  SumFirstMeaningfulPaint = 'sumFirstMeaningfulPaint',
  SumFolderCount = 'sumFolderCount',
  SumFollowedExternalLinksOutCount = 'sumFollowedExternalLinksOutCount',
  SumFollowedLinksInCount = 'sumFollowedLinksInCount',
  SumGaAvgPageLoadTime = 'sumGaAvgPageLoadTime',
  SumGaAvgTimeOnPage = 'sumGaAvgTimeOnPage',
  SumGaPageviewsPerVisits = 'sumGaPageviewsPerVisits',
  SumGaVisitBounceRate = 'sumGaVisitBounceRate',
  SumGaVisits = 'sumGaVisits',
  SumH1Count = 'sumH1Count',
  SumH1Length = 'sumH1Length',
  SumHeadTagsInBodyCount = 'sumHeadTagsInBodyCount',
  SumHowtoSchemaCount = 'sumHowtoSchemaCount',
  SumHreflangLinksInCount = 'sumHreflangLinksInCount',
  SumHtmlSize = 'sumHtmlSize',
  SumInfoAuditsCount = 'sumInfoAuditsCount',
  SumInteractive = 'sumInteractive',
  SumInternalLinksCount = 'sumInternalLinksCount',
  SumLargestContentfulPaint = 'sumLargestContentfulPaint',
  SumLargestContentfulPaintScore = 'sumLargestContentfulPaintScore',
  SumLcpLoadEnd = 'sumLcpLoadEnd',
  SumLcpLoadStart = 'sumLcpLoadStart',
  SumLinksInCount = 'sumLinksInCount',
  SumLinksOutCount = 'sumLinksOutCount',
  SumLogRequestsDesktop = 'sumLogRequestsDesktop',
  SumLogRequestsMobile = 'sumLogRequestsMobile',
  SumLogRequestsTotal = 'sumLogRequestsTotal',
  SumMaxPotentialFid = 'sumMaxPotentialFid',
  SumMobileDesktopContentDifference = 'sumMobileDesktopContentDifference',
  SumMobileDesktopLinksInDifference = 'sumMobileDesktopLinksInDifference',
  SumMobileDesktopLinksOutDifference = 'sumMobileDesktopLinksOutDifference',
  SumMobileLinksInCount = 'sumMobileLinksInCount',
  SumNewsarticleSchemaCount = 'sumNewsarticleSchemaCount',
  SumNonReciprocateHreflangOutCount = 'sumNonReciprocateHreflangOutCount',
  SumPageTitleLength = 'sumPageTitleLength',
  SumPageTitleLengthPx = 'sumPageTitleLengthPx',
  SumPaginatedLinksInCount = 'sumPaginatedLinksInCount',
  SumParamCount = 'sumParamCount',
  SumPassedAuditsCount = 'sumPassedAuditsCount',
  SumPerformanceCls = 'sumPerformanceCls',
  SumPerformanceDcl = 'sumPerformanceDcl',
  SumPerformanceFcp = 'sumPerformanceFcp',
  SumPerformanceLcp = 'sumPerformanceLcp',
  SumPerformanceTtfb = 'sumPerformanceTtfb',
  SumPerformanceTti = 'sumPerformanceTti',
  SumProductOfSavingsKibAndPageviews = 'sumProductOfSavingsKibAndPageviews',
  SumProductOfSavingsSecsAndPageviews = 'sumProductOfSavingsSecsAndPageviews',
  SumProductPriceSchemaValue = 'sumProductPriceSchemaValue',
  SumProductRatingSchemaValue = 'sumProductRatingSchemaValue',
  SumProductSchemaCount = 'sumProductSchemaCount',
  SumQapageSchemaCount = 'sumQapageSchemaCount',
  SumRecipeSchemaCount = 'sumRecipeSchemaCount',
  SumReciprocateHreflangOutCount = 'sumReciprocateHreflangOutCount',
  SumRedirectCount = 'sumRedirectCount',
  SumRedirectsInCount = 'sumRedirectsInCount',
  SumRelLinksInCount = 'sumRelLinksInCount',
  SumRenderedLinkCountDifference = 'sumRenderedLinkCountDifference',
  SumRenderedWordCountDifference = 'sumRenderedWordCountDifference',
  SumResourceLinksInCount = 'sumResourceLinksInCount',
  SumReviewSchemaCount = 'sumReviewSchemaCount',
  SumSavingsKib = 'sumSavingsKib',
  SumSavingsScore = 'sumSavingsScore',
  SumSavingsSecs = 'sumSavingsSecs',
  SumSearchConsoleDesktopClicks = 'sumSearchConsoleDesktopClicks',
  SumSearchConsoleDesktopCtr = 'sumSearchConsoleDesktopCtr',
  SumSearchConsoleDesktopImpressions = 'sumSearchConsoleDesktopImpressions',
  SumSearchConsoleDesktopPosition = 'sumSearchConsoleDesktopPosition',
  SumSearchConsoleMobileClicks = 'sumSearchConsoleMobileClicks',
  SumSearchConsoleMobileCtr = 'sumSearchConsoleMobileCtr',
  SumSearchConsoleMobileImpressions = 'sumSearchConsoleMobileImpressions',
  SumSearchConsoleMobilePosition = 'sumSearchConsoleMobilePosition',
  SumSearchConsoleTabletClicks = 'sumSearchConsoleTabletClicks',
  SumSearchConsoleTabletCtr = 'sumSearchConsoleTabletCtr',
  SumSearchConsoleTabletImpressions = 'sumSearchConsoleTabletImpressions',
  SumSearchConsoleTabletPosition = 'sumSearchConsoleTabletPosition',
  SumSearchConsoleTotalClicks = 'sumSearchConsoleTotalClicks',
  SumSearchConsoleTotalCtr = 'sumSearchConsoleTotalCtr',
  SumSearchConsoleTotalImpressions = 'sumSearchConsoleTotalImpressions',
  SumSearchConsoleTotalPosition = 'sumSearchConsoleTotalPosition',
  SumSitemapsInCount = 'sumSitemapsInCount',
  SumSitespeedCls = 'sumSitespeedCls',
  SumSitespeedClsMainFrame = 'sumSitespeedClsMainFrame',
  SumSitespeedClsScore = 'sumSitespeedClsScore',
  SumSitespeedFcp = 'sumSitespeedFcp',
  SumSitespeedFcpScore = 'sumSitespeedFcpScore',
  SumSitespeedFmp = 'sumSitespeedFmp',
  SumSitespeedLcp = 'sumSitespeedLcp',
  SumSitespeedLcpScore = 'sumSitespeedLcpScore',
  SumSitespeedMaxPotentialFid = 'sumSitespeedMaxPotentialFid',
  SumSitespeedScore = 'sumSitespeedScore',
  SumSitespeedSpeedIndex = 'sumSitespeedSpeedIndex',
  SumSitespeedSpeedIndexScore = 'sumSitespeedSpeedIndexScore',
  SumSitespeedTbt = 'sumSitespeedTbt',
  SumSitespeedTbtScore = 'sumSitespeedTbtScore',
  SumSitespeedTtfb = 'sumSitespeedTtfb',
  SumSitespeedTti = 'sumSitespeedTti',
  SumSpeedIndex = 'sumSpeedIndex',
  SumSpeedIndexScore = 'sumSpeedIndexScore',
  SumTimeToFirstByte = 'sumTimeToFirstByte',
  SumTotalBlockingTime = 'sumTotalBlockingTime',
  SumTotalBlockingTimeScore = 'sumTotalBlockingTimeScore',
  SumUniqueLinksInCount = 'sumUniqueLinksInCount',
  SumVideoobjectSchemaCount = 'sumVideoobjectSchemaCount',
  SumWarningAuditsCount = 'sumWarningAuditsCount',
  SumWordCount = 'sumWordCount',
  UrlContainsRepeatedPaths = 'urlContainsRepeatedPaths',
  UrlContainsUnderscores = 'urlContainsUnderscores',
  UrlDoubleEncoded = 'urlDoubleEncoded',
  UrlPathContainsDoubleSlashes = 'urlPathContainsDoubleSlashes',
  Viewport = 'viewport'
}

export type CrawlUrlAttachment = {
  contentType: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  signedLocation: Scalars['String']['output'];
  sizeBytes: Scalars['Int']['output'];
};

export type CrawlUrlAvgCalculation = {
  accessibilityAIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilityAaIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilityAaaIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilityBpIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilityCriticalIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilityIncompleteIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilityIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilityMinorIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilityModerateIssuesCount?: Maybe<Scalars['Float']['output']>;
  accessibilitySeriousIssuesCount?: Maybe<Scalars['Float']['output']>;
  backlinkCount?: Maybe<Scalars['Float']['output']>;
  backlinkDomainCount?: Maybe<Scalars['Float']['output']>;
  bodyTagsInHeadCount?: Maybe<Scalars['Float']['output']>;
  breadcrumbCount?: Maybe<Scalars['Float']['output']>;
  breadcrumbSchemaCount?: Maybe<Scalars['Float']['output']>;
  canonicalLinksInCount?: Maybe<Scalars['Float']['output']>;
  contentHtmlRatio?: Maybe<Scalars['Float']['output']>;
  contentSize?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShift?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftMainFrame?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftScore?: Maybe<Scalars['Float']['output']>;
  deeprank?: Maybe<Scalars['Float']['output']>;
  descriptionLength?: Maybe<Scalars['Float']['output']>;
  descriptionLengthPx?: Maybe<Scalars['Float']['output']>;
  duplicateBodyCount?: Maybe<Scalars['Float']['output']>;
  duplicateDescriptionCount?: Maybe<Scalars['Float']['output']>;
  duplicatePageCount?: Maybe<Scalars['Float']['output']>;
  duplicateTitleCount?: Maybe<Scalars['Float']['output']>;
  duplicateUrlCount?: Maybe<Scalars['Float']['output']>;
  eventSchemaCount?: Maybe<Scalars['Float']['output']>;
  externalLinksCount?: Maybe<Scalars['Float']['output']>;
  failedAuditsCount?: Maybe<Scalars['Float']['output']>;
  faqpageSchemaCount?: Maybe<Scalars['Float']['output']>;
  fetchTime?: Maybe<Scalars['Float']['output']>;
  firstContentfulPaint?: Maybe<Scalars['Float']['output']>;
  firstContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  firstMeaningfulPaint?: Maybe<Scalars['Float']['output']>;
  folderCount?: Maybe<Scalars['Float']['output']>;
  followedExternalLinksOutCount?: Maybe<Scalars['Float']['output']>;
  followedLinksInCount?: Maybe<Scalars['Float']['output']>;
  gaAvgPageLoadTime?: Maybe<Scalars['Float']['output']>;
  gaAvgTimeOnPage?: Maybe<Scalars['Float']['output']>;
  gaPageviewsPerVisits?: Maybe<Scalars['Float']['output']>;
  gaVisitBounceRate?: Maybe<Scalars['Float']['output']>;
  gaVisits?: Maybe<Scalars['Float']['output']>;
  h1Count?: Maybe<Scalars['Float']['output']>;
  h1Length?: Maybe<Scalars['Float']['output']>;
  headTagsInBodyCount?: Maybe<Scalars['Float']['output']>;
  howtoSchemaCount?: Maybe<Scalars['Float']['output']>;
  hreflangLinksInCount?: Maybe<Scalars['Float']['output']>;
  htmlSize?: Maybe<Scalars['Float']['output']>;
  infoAuditsCount?: Maybe<Scalars['Float']['output']>;
  interactive?: Maybe<Scalars['Float']['output']>;
  internalLinksCount?: Maybe<Scalars['Float']['output']>;
  largestContentfulPaint?: Maybe<Scalars['Float']['output']>;
  largestContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  lcpLoadEnd?: Maybe<Scalars['Float']['output']>;
  lcpLoadStart?: Maybe<Scalars['Float']['output']>;
  linksInCount?: Maybe<Scalars['Float']['output']>;
  linksOutCount?: Maybe<Scalars['Float']['output']>;
  logRequestsDesktop?: Maybe<Scalars['Float']['output']>;
  logRequestsMobile?: Maybe<Scalars['Float']['output']>;
  logRequestsTotal?: Maybe<Scalars['Float']['output']>;
  maxPotentialFid?: Maybe<Scalars['Float']['output']>;
  mobileDesktopContentDifference?: Maybe<Scalars['Float']['output']>;
  mobileDesktopLinksInDifference?: Maybe<Scalars['Float']['output']>;
  mobileDesktopLinksOutDifference?: Maybe<Scalars['Float']['output']>;
  mobileLinksInCount?: Maybe<Scalars['Float']['output']>;
  newsarticleSchemaCount?: Maybe<Scalars['Float']['output']>;
  nonReciprocateHreflangOutCount?: Maybe<Scalars['Float']['output']>;
  pageTitleLength?: Maybe<Scalars['Float']['output']>;
  pageTitleLengthPx?: Maybe<Scalars['Float']['output']>;
  paginatedLinksInCount?: Maybe<Scalars['Float']['output']>;
  paramCount?: Maybe<Scalars['Float']['output']>;
  passedAuditsCount?: Maybe<Scalars['Float']['output']>;
  performanceCls?: Maybe<Scalars['Float']['output']>;
  performanceDcl?: Maybe<Scalars['Float']['output']>;
  performanceFcp?: Maybe<Scalars['Float']['output']>;
  performanceLcp?: Maybe<Scalars['Float']['output']>;
  performanceTtfb?: Maybe<Scalars['Float']['output']>;
  performanceTti?: Maybe<Scalars['Float']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  productPriceSchemaValue?: Maybe<Scalars['Float']['output']>;
  productRatingSchemaValue?: Maybe<Scalars['Float']['output']>;
  productSchemaCount?: Maybe<Scalars['Float']['output']>;
  qapageSchemaCount?: Maybe<Scalars['Float']['output']>;
  recipeSchemaCount?: Maybe<Scalars['Float']['output']>;
  reciprocateHreflangOutCount?: Maybe<Scalars['Float']['output']>;
  redirectCount?: Maybe<Scalars['Float']['output']>;
  redirectsInCount?: Maybe<Scalars['Float']['output']>;
  relLinksInCount?: Maybe<Scalars['Float']['output']>;
  renderedLinkCountDifference?: Maybe<Scalars['Float']['output']>;
  renderedWordCountDifference?: Maybe<Scalars['Float']['output']>;
  resourceLinksInCount?: Maybe<Scalars['Float']['output']>;
  reviewSchemaCount?: Maybe<Scalars['Float']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopClicks?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopImpressions?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileClicks?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileImpressions?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobilePosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletClicks?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletImpressions?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalClicks?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalImpressions?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalPosition?: Maybe<Scalars['Float']['output']>;
  sitemapsInCount?: Maybe<Scalars['Float']['output']>;
  sitespeedCls?: Maybe<Scalars['Float']['output']>;
  sitespeedClsMainFrame?: Maybe<Scalars['Float']['output']>;
  sitespeedClsScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFcp?: Maybe<Scalars['Float']['output']>;
  sitespeedFcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFmp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedMaxPotentialFid?: Maybe<Scalars['Float']['output']>;
  sitespeedScore?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndex?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndexScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTbt?: Maybe<Scalars['Float']['output']>;
  sitespeedTbtScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTtfb?: Maybe<Scalars['Float']['output']>;
  sitespeedTti?: Maybe<Scalars['Float']['output']>;
  speedIndex?: Maybe<Scalars['Float']['output']>;
  speedIndexScore?: Maybe<Scalars['Float']['output']>;
  timeToFirstByte?: Maybe<Scalars['Float']['output']>;
  totalBlockingTime?: Maybe<Scalars['Float']['output']>;
  totalBlockingTimeScore?: Maybe<Scalars['Float']['output']>;
  uniqueLinksInCount?: Maybe<Scalars['Float']['output']>;
  videoobjectSchemaCount?: Maybe<Scalars['Float']['output']>;
  warningAuditsCount?: Maybe<Scalars['Float']['output']>;
  wordCount?: Maybe<Scalars['Float']['output']>;
};

export type CrawlUrlConnection = {
  /** A list of edges. */
  edges: Array<CrawlUrlEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlUrl>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlUrls in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlUrlConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlUrlConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlUrlConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlUrlConnectionFilterInput>>;
  accessibilityAIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityAaIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityAaaIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityBpIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityCriticalIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  accessibilityIncompleteIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityIssuesCountByRuleIdCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityMinorIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilityModerateIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  accessibilitySeriousIssuesCount?: InputMaybe<ConnectionIntFilterInput>;
  adultRating?: InputMaybe<ConnectionBooleanFilterInput>;
  aiBotBlocked?: InputMaybe<ConnectionStringArrayFilterInput>;
  aiBotBlockedCount?: InputMaybe<ConnectionIntFilterInput>;
  amphtml?: InputMaybe<ConnectionBooleanFilterInput>;
  amphtmlReciprocate?: InputMaybe<ConnectionBooleanFilterInput>;
  amphtmlValid?: InputMaybe<ConnectionBooleanFilterInput>;
  attachmentNames?: InputMaybe<ConnectionStringArrayFilterInput>;
  backlinkCount?: InputMaybe<ConnectionIntFilterInput>;
  backlinkDomainCount?: InputMaybe<ConnectionIntFilterInput>;
  bodyTagsInHeadCount?: InputMaybe<ConnectionIntFilterInput>;
  breadcrumb01?: InputMaybe<ConnectionStringFilterInput>;
  breadcrumb02?: InputMaybe<ConnectionStringFilterInput>;
  breadcrumb03?: InputMaybe<ConnectionStringFilterInput>;
  breadcrumb04?: InputMaybe<ConnectionStringFilterInput>;
  breadcrumb05?: InputMaybe<ConnectionStringFilterInput>;
  breadcrumb06?: InputMaybe<ConnectionStringFilterInput>;
  breadcrumb07?: InputMaybe<ConnectionStringFilterInput>;
  breadcrumb08?: InputMaybe<ConnectionStringFilterInput>;
  breadcrumbCount?: InputMaybe<ConnectionIntFilterInput>;
  breadcrumbSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  cacheControl?: InputMaybe<ConnectionStringFilterInput>;
  canonicalHeaderUrl?: InputMaybe<ConnectionStringFilterInput>;
  canonicalHtmlUrl?: InputMaybe<ConnectionStringFilterInput>;
  canonicalLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  canonicalUrl?: InputMaybe<ConnectionStringFilterInput>;
  canonicalUrlChangedFrom?: InputMaybe<ConnectionStringFilterInput>;
  canonicalUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  canonicalUrlIsConsistent?: InputMaybe<ConnectionBooleanFilterInput>;
  canonicalizedPage?: InputMaybe<ConnectionBooleanFilterInput>;
  changes?: InputMaybe<ConnectionStringArrayFilterInput>;
  changesCount?: InputMaybe<ConnectionIntFilterInput>;
  containerExecutionFailuresCount?: InputMaybe<ConnectionIntFilterInput>;
  contentHtmlRatio?: InputMaybe<ConnectionFloatFilterInput>;
  contentSize?: InputMaybe<ConnectionIntFilterInput>;
  crawlDatetime?: InputMaybe<ConnectionDateFilterInput>;
  css?: InputMaybe<ConnectionBooleanFilterInput>;
  cssLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  cumulativeLayoutShift?: InputMaybe<ConnectionFloatFilterInput>;
  cumulativeLayoutShiftMainFrame?: InputMaybe<ConnectionFloatFilterInput>;
  cumulativeLayoutShiftScore?: InputMaybe<ConnectionFloatFilterInput>;
  customExtraction1?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction1Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction2?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction2Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction3?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction3Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction4?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction4Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction5?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction5Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction6?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction6Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction7?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction7Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction8?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction8Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction9?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction9Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction10?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction10Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction11?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction11Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction12?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction12Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction13?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction13Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction14?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction14Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction15?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction15Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction16?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction16Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction17?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction17Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction18?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction18Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction19?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction19Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction20?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction20Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction21?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction21Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction22?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction22Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction23?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction23Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction24?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction24Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction25?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction25Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction26?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction26Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction27?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction27Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction28?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction28Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction29?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction29Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtraction30?: InputMaybe<ConnectionStringArrayFilterInput>;
  customExtraction30Count?: InputMaybe<ConnectionIntFilterInput>;
  customExtractionMatch?: InputMaybe<ConnectionBooleanFilterInput>;
  customMetrics?: InputMaybe<Scalars['JSONObject']['input']>;
  deeprank?: InputMaybe<ConnectionFloatFilterInput>;
  description?: InputMaybe<ConnectionStringFilterInput>;
  descriptionChangedFrom?: InputMaybe<ConnectionStringFilterInput>;
  descriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  descriptionLengthPx?: InputMaybe<ConnectionIntFilterInput>;
  desktopUrl?: InputMaybe<ConnectionStringFilterInput>;
  desktopUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  disallowedPage?: InputMaybe<ConnectionBooleanFilterInput>;
  dominantLanguages?: InputMaybe<ConnectionStringArrayFilterInput>;
  duplicateBody?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateBodyCount?: InputMaybe<ConnectionIntFilterInput>;
  duplicateBodyCountIsApproximation?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateBodyNonIndexable?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateBodyPrimaryUrl?: InputMaybe<ConnectionStringFilterInput>;
  duplicateBodyPrimaryUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  duplicateDescription?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateDescriptionCount?: InputMaybe<ConnectionIntFilterInput>;
  duplicateDescriptionCountIsApproximation?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateDescriptionNonIndexable?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateDescriptionPrimaryUrl?: InputMaybe<ConnectionStringFilterInput>;
  duplicateDescriptionPrimaryUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  duplicatePage?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicatePageCount?: InputMaybe<ConnectionIntFilterInput>;
  duplicatePageCountIsApproximation?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicatePageNonIndexable?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateTitle?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateTitleCount?: InputMaybe<ConnectionIntFilterInput>;
  duplicateTitleCountIsApproximation?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateTitleNonIndexable?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateTitlePrimaryUrl?: InputMaybe<ConnectionStringFilterInput>;
  duplicateTitlePrimaryUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  duplicateUrl?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateUrlCount?: InputMaybe<ConnectionIntFilterInput>;
  duplicateUrlCountIsApproximation?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateUrlNonIndexable?: InputMaybe<ConnectionBooleanFilterInput>;
  duplicateUrlPrimaryUrl?: InputMaybe<ConnectionStringFilterInput>;
  duplicateUrlPrimaryUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  encodingHeader?: InputMaybe<ConnectionStringFilterInput>;
  eventSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  externalLinksCount?: InputMaybe<ConnectionIntFilterInput>;
  failedAudits?: InputMaybe<ConnectionStringArrayFilterInput>;
  failedAuditsCount?: InputMaybe<ConnectionIntFilterInput>;
  failedReason?: InputMaybe<ConnectionStringFilterInput>;
  faqpageSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  fbAdmins?: InputMaybe<ConnectionStringFilterInput>;
  fbAppId?: InputMaybe<ConnectionStringFilterInput>;
  fetchTime?: InputMaybe<ConnectionFloatFilterInput>;
  fileExtension?: InputMaybe<ConnectionStringFilterInput>;
  firstContentfulPaint?: InputMaybe<ConnectionIntFilterInput>;
  firstContentfulPaintScore?: InputMaybe<ConnectionFloatFilterInput>;
  firstMeaningfulPaint?: InputMaybe<ConnectionIntFilterInput>;
  folderCount?: InputMaybe<ConnectionIntFilterInput>;
  followedExternalLinksOutCount?: InputMaybe<ConnectionIntFilterInput>;
  followedLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  foundAtSitemap?: InputMaybe<ConnectionStringFilterInput>;
  foundAtUrl?: InputMaybe<ConnectionStringFilterInput>;
  foundAtUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  foundInBacklinks?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInCustomMetricsUpload?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInGoogleAnalytics?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInGoogleSearchConsole?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInList?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInLogSummary?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInSitemap?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInTask?: InputMaybe<ConnectionBooleanFilterInput>;
  foundInWebCrawl?: InputMaybe<ConnectionBooleanFilterInput>;
  gaAvgPageLoadTime?: InputMaybe<ConnectionFloatFilterInput>;
  gaAvgTimeOnPage?: InputMaybe<ConnectionFloatFilterInput>;
  gaPageviews?: InputMaybe<ConnectionIntFilterInput>;
  gaPageviewsPerVisits?: InputMaybe<ConnectionFloatFilterInput>;
  gaVisitBounceRate?: InputMaybe<ConnectionFloatFilterInput>;
  gaVisits?: InputMaybe<ConnectionIntFilterInput>;
  h1Count?: InputMaybe<ConnectionIntFilterInput>;
  h1Length?: InputMaybe<ConnectionIntFilterInput>;
  h1Tag?: InputMaybe<ConnectionStringArrayFilterInput>;
  h1TagChangedFrom?: InputMaybe<ConnectionStringArrayFilterInput>;
  h1TagChangedFromCount?: InputMaybe<ConnectionIntFilterInput>;
  h1TagCount?: InputMaybe<ConnectionIntFilterInput>;
  h2Tag?: InputMaybe<ConnectionStringArrayFilterInput>;
  h2TagCount?: InputMaybe<ConnectionIntFilterInput>;
  h3Tag?: InputMaybe<ConnectionStringArrayFilterInput>;
  h3TagCount?: InputMaybe<ConnectionIntFilterInput>;
  hasStructuredData?: InputMaybe<ConnectionBooleanFilterInput>;
  headTagsInBodyCount?: InputMaybe<ConnectionIntFilterInput>;
  headerContentType?: InputMaybe<ConnectionStringFilterInput>;
  headerNoarchive?: InputMaybe<ConnectionBooleanFilterInput>;
  headerNofollow?: InputMaybe<ConnectionBooleanFilterInput>;
  headerNoindex?: InputMaybe<ConnectionBooleanFilterInput>;
  headerNoodp?: InputMaybe<ConnectionBooleanFilterInput>;
  headerNosnippet?: InputMaybe<ConnectionBooleanFilterInput>;
  headerNoydir?: InputMaybe<ConnectionBooleanFilterInput>;
  howtoSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  hreflangCombination?: InputMaybe<ConnectionStringArrayFilterInput>;
  hreflangCombinationChangedFrom?: InputMaybe<ConnectionStringArrayFilterInput>;
  hreflangCombinationChangedFromCount?: InputMaybe<ConnectionIntFilterInput>;
  hreflangCombinationCount?: InputMaybe<ConnectionIntFilterInput>;
  hreflangLanguagesInCount?: InputMaybe<ConnectionIntFilterInput>;
  hreflangLanguagesInMetaCount?: InputMaybe<ConnectionIntFilterInput>;
  hreflangLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  hreflangLinksOutCount?: InputMaybe<ConnectionIntFilterInput>;
  hreflangOnHeader?: InputMaybe<ConnectionBooleanFilterInput>;
  hreflangOnPage?: InputMaybe<ConnectionBooleanFilterInput>;
  hreflangOnSitemap?: InputMaybe<ConnectionBooleanFilterInput>;
  hreflangUrlCount?: InputMaybe<ConnectionIntFilterInput>;
  hsts?: InputMaybe<ConnectionStringFilterInput>;
  htmlSize?: InputMaybe<ConnectionIntFilterInput>;
  httpStatusCode?: InputMaybe<ConnectionIntFilterInput>;
  httpStatusCodeChangedFrom?: InputMaybe<ConnectionIntFilterInput>;
  https?: InputMaybe<ConnectionBooleanFilterInput>;
  identifiedLanguage?: InputMaybe<ConnectionStringFilterInput>;
  imgLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  inconsistentHreflangsInCount?: InputMaybe<ConnectionIntFilterInput>;
  indexable?: InputMaybe<ConnectionBooleanFilterInput>;
  infoAudits?: InputMaybe<ConnectionStringArrayFilterInput>;
  infoAuditsCount?: InputMaybe<ConnectionIntFilterInput>;
  interactive?: InputMaybe<ConnectionIntFilterInput>;
  internal?: InputMaybe<ConnectionBooleanFilterInput>;
  internalLinksCount?: InputMaybe<ConnectionIntFilterInput>;
  isImage?: InputMaybe<ConnectionBooleanFilterInput>;
  isRedirect?: InputMaybe<ConnectionBooleanFilterInput>;
  isRedirectLoop?: InputMaybe<ConnectionBooleanFilterInput>;
  isSelfCanonical?: InputMaybe<ConnectionBooleanFilterInput>;
  isWebLinked?: InputMaybe<ConnectionBooleanFilterInput>;
  js?: InputMaybe<ConnectionBooleanFilterInput>;
  jsLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  largestContentfulPaint?: InputMaybe<ConnectionIntFilterInput>;
  largestContentfulPaintScore?: InputMaybe<ConnectionFloatFilterInput>;
  lcpLoadEnd?: InputMaybe<ConnectionIntFilterInput>;
  lcpLoadStart?: InputMaybe<ConnectionIntFilterInput>;
  level?: InputMaybe<ConnectionIntFilterInput>;
  levelChangedFrom?: InputMaybe<ConnectionIntFilterInput>;
  linksInCount?: InputMaybe<ConnectionIntFilterInput>;
  linksOutCount?: InputMaybe<ConnectionIntFilterInput>;
  logRequestsDesktop?: InputMaybe<ConnectionIntFilterInput>;
  logRequestsMobile?: InputMaybe<ConnectionIntFilterInput>;
  logRequestsTotal?: InputMaybe<ConnectionIntFilterInput>;
  maxPotentialFid?: InputMaybe<ConnectionIntFilterInput>;
  metaCharset?: InputMaybe<ConnectionStringFilterInput>;
  metaContentType?: InputMaybe<ConnectionStringFilterInput>;
  metaDisabledSitelinks?: InputMaybe<ConnectionBooleanFilterInput>;
  metaNoarchive?: InputMaybe<ConnectionBooleanFilterInput>;
  metaNofollow?: InputMaybe<ConnectionBooleanFilterInput>;
  metaNoindex?: InputMaybe<ConnectionBooleanFilterInput>;
  metaNoodp?: InputMaybe<ConnectionBooleanFilterInput>;
  metaNosnippet?: InputMaybe<ConnectionBooleanFilterInput>;
  metaNoydir?: InputMaybe<ConnectionBooleanFilterInput>;
  metaRedirect?: InputMaybe<ConnectionBooleanFilterInput>;
  mobileDesktopContentDifference?: InputMaybe<ConnectionIntFilterInput>;
  mobileDesktopContentMismatch?: InputMaybe<ConnectionBooleanFilterInput>;
  mobileDesktopLinksInDifference?: InputMaybe<ConnectionIntFilterInput>;
  mobileDesktopLinksOutDifference?: InputMaybe<ConnectionIntFilterInput>;
  mobileLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  mobileReciprocate?: InputMaybe<ConnectionBooleanFilterInput>;
  mobileRelAlternate?: InputMaybe<ConnectionBooleanFilterInput>;
  mobileRelAlternateHeaderUrl?: InputMaybe<ConnectionStringFilterInput>;
  mobileRelAlternateHeaderUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  mobileRelAlternateHtmlUrl?: InputMaybe<ConnectionStringFilterInput>;
  mobileRelAlternateHtmlUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  mobileRelAlternateUrl?: InputMaybe<ConnectionStringFilterInput>;
  mobileRelAlternateUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  mobileRelAlternateUrlIsConsistent?: InputMaybe<ConnectionBooleanFilterInput>;
  mobileRelAmphtml?: InputMaybe<ConnectionBooleanFilterInput>;
  mobileRelAmphtmlUrl?: InputMaybe<ConnectionStringFilterInput>;
  mobileRelAmphtmlUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  newsarticleAuthorSchemaValue?: InputMaybe<ConnectionStringFilterInput>;
  newsarticleSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  newsarticleSchemaValid?: InputMaybe<ConnectionBooleanFilterInput>;
  noarchive?: InputMaybe<ConnectionBooleanFilterInput>;
  nofollowedPage?: InputMaybe<ConnectionBooleanFilterInput>;
  noindex?: InputMaybe<ConnectionBooleanFilterInput>;
  nonReciprocateHreflangOutCount?: InputMaybe<ConnectionIntFilterInput>;
  noodp?: InputMaybe<ConnectionBooleanFilterInput>;
  nosnippet?: InputMaybe<ConnectionBooleanFilterInput>;
  noydir?: InputMaybe<ConnectionBooleanFilterInput>;
  ogDescription?: InputMaybe<ConnectionStringFilterInput>;
  ogImage?: InputMaybe<ConnectionStringFilterInput>;
  ogLocale?: InputMaybe<ConnectionStringFilterInput>;
  ogSiteName?: InputMaybe<ConnectionStringFilterInput>;
  ogTitle?: InputMaybe<ConnectionStringFilterInput>;
  ogType?: InputMaybe<ConnectionStringFilterInput>;
  ogUrl?: InputMaybe<ConnectionStringFilterInput>;
  page1?: InputMaybe<ConnectionBooleanFilterInput>;
  pageGroup?: InputMaybe<ConnectionStringFilterInput>;
  pageTitle?: InputMaybe<ConnectionStringFilterInput>;
  pageTitleChangedFrom?: InputMaybe<ConnectionStringFilterInput>;
  pageTitleLength?: InputMaybe<ConnectionIntFilterInput>;
  pageTitleLengthPx?: InputMaybe<ConnectionIntFilterInput>;
  paginatedLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  paginatedPage?: InputMaybe<ConnectionBooleanFilterInput>;
  paramCount?: InputMaybe<ConnectionIntFilterInput>;
  passedAudits?: InputMaybe<ConnectionStringArrayFilterInput>;
  passedAuditsCount?: InputMaybe<ConnectionIntFilterInput>;
  path0?: InputMaybe<ConnectionStringFilterInput>;
  path1?: InputMaybe<ConnectionStringFilterInput>;
  path2?: InputMaybe<ConnectionStringFilterInput>;
  path3?: InputMaybe<ConnectionStringFilterInput>;
  path4?: InputMaybe<ConnectionStringFilterInput>;
  path5?: InputMaybe<ConnectionStringFilterInput>;
  path6?: InputMaybe<ConnectionStringFilterInput>;
  path7?: InputMaybe<ConnectionStringFilterInput>;
  path8?: InputMaybe<ConnectionStringFilterInput>;
  path9?: InputMaybe<ConnectionStringFilterInput>;
  pathCount?: InputMaybe<ConnectionIntFilterInput>;
  performanceCls?: InputMaybe<ConnectionFloatFilterInput>;
  performanceDcl?: InputMaybe<ConnectionFloatFilterInput>;
  performanceFcp?: InputMaybe<ConnectionFloatFilterInput>;
  performanceLcp?: InputMaybe<ConnectionFloatFilterInput>;
  performanceTtfb?: InputMaybe<ConnectionFloatFilterInput>;
  performanceTti?: InputMaybe<ConnectionFloatFilterInput>;
  previousPath?: InputMaybe<ConnectionStringFilterInput>;
  previousPathDigest?: InputMaybe<ConnectionStringFilterInput>;
  primaryPage?: InputMaybe<ConnectionBooleanFilterInput>;
  primaryUrl?: InputMaybe<ConnectionStringFilterInput>;
  primaryUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  primaryUrlNonIndexable?: InputMaybe<ConnectionStringFilterInput>;
  productAvailabilitySchemaValue?: InputMaybe<ConnectionStringFilterInput>;
  productBrandSchemaValue?: InputMaybe<ConnectionStringFilterInput>;
  productOfSavingsKibAndPageviews?: InputMaybe<ConnectionFloatFilterInput>;
  productOfSavingsSecsAndPageviews?: InputMaybe<ConnectionFloatFilterInput>;
  productPriceSchemaValue?: InputMaybe<ConnectionFloatFilterInput>;
  productRatingSchemaValue?: InputMaybe<ConnectionFloatFilterInput>;
  productSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  productSchemaValid?: InputMaybe<ConnectionBooleanFilterInput>;
  productSkuSchemaValue?: InputMaybe<ConnectionStringFilterInput>;
  qapageSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  recipeSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  reciprocateHreflangOutCount?: InputMaybe<ConnectionIntFilterInput>;
  redirectChain?: InputMaybe<RedirectObjectArrayMemberConnectionFilterInput>;
  redirectChainCount?: InputMaybe<ConnectionIntFilterInput>;
  redirectCount?: InputMaybe<ConnectionIntFilterInput>;
  redirectExclusionReason?: InputMaybe<ConnectionRedirectExclusionReasonFilterInput>;
  redirectedToStatusCode?: InputMaybe<ConnectionIntFilterInput>;
  redirectedToUrl?: InputMaybe<ConnectionStringFilterInput>;
  redirectedToUrlChangedFrom?: InputMaybe<ConnectionStringFilterInput>;
  redirectedToUrlDigest?: InputMaybe<ConnectionStringFilterInput>;
  redirectsInCount?: InputMaybe<ConnectionIntFilterInput>;
  relLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  relNextHeaderUrl?: InputMaybe<ConnectionStringFilterInput>;
  relNextHtmlUrl?: InputMaybe<ConnectionStringFilterInput>;
  relNextUrl?: InputMaybe<ConnectionStringFilterInput>;
  relNextUrlIsConsistent?: InputMaybe<ConnectionBooleanFilterInput>;
  relPrevHeaderUrl?: InputMaybe<ConnectionStringFilterInput>;
  relPrevHtmlUrl?: InputMaybe<ConnectionStringFilterInput>;
  relPrevUrl?: InputMaybe<ConnectionStringFilterInput>;
  relPrevUrlIsConsistent?: InputMaybe<ConnectionBooleanFilterInput>;
  renderedCanonicalLinkMatch?: InputMaybe<ConnectionBooleanFilterInput>;
  renderedLinkCountDifference?: InputMaybe<ConnectionIntFilterInput>;
  renderedWordCountDifference?: InputMaybe<ConnectionIntFilterInput>;
  resolvedHttpStatusCode?: InputMaybe<ConnectionResolvedHttpStatusCodeFilterInput>;
  resourceLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  responsive?: InputMaybe<ConnectionBooleanFilterInput>;
  reviewSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  robotsNoindex?: InputMaybe<ConnectionBooleanFilterInput>;
  robotsTxtRuleMatch?: InputMaybe<ConnectionStringFilterInput>;
  savingsKib?: InputMaybe<ConnectionFloatFilterInput>;
  savingsScore?: InputMaybe<ConnectionFloatFilterInput>;
  savingsSecs?: InputMaybe<ConnectionFloatFilterInput>;
  searchConsoleDesktopClicks?: InputMaybe<ConnectionIntFilterInput>;
  searchConsoleDesktopCtr?: InputMaybe<ConnectionFloatFilterInput>;
  searchConsoleDesktopImpressions?: InputMaybe<ConnectionIntFilterInput>;
  searchConsoleDesktopPosition?: InputMaybe<ConnectionFloatFilterInput>;
  searchConsoleMobileClicks?: InputMaybe<ConnectionIntFilterInput>;
  searchConsoleMobileCtr?: InputMaybe<ConnectionFloatFilterInput>;
  searchConsoleMobileImpressions?: InputMaybe<ConnectionIntFilterInput>;
  searchConsoleMobilePosition?: InputMaybe<ConnectionFloatFilterInput>;
  searchConsoleTabletClicks?: InputMaybe<ConnectionIntFilterInput>;
  searchConsoleTabletCtr?: InputMaybe<ConnectionFloatFilterInput>;
  searchConsoleTabletImpressions?: InputMaybe<ConnectionIntFilterInput>;
  searchConsoleTabletPosition?: InputMaybe<ConnectionFloatFilterInput>;
  searchConsoleTotalClicks?: InputMaybe<ConnectionIntFilterInput>;
  searchConsoleTotalCtr?: InputMaybe<ConnectionFloatFilterInput>;
  searchConsoleTotalImpressions?: InputMaybe<ConnectionIntFilterInput>;
  searchConsoleTotalPosition?: InputMaybe<ConnectionFloatFilterInput>;
  secureFormInputField?: InputMaybe<ConnectionBooleanFilterInput>;
  separateDesktop?: InputMaybe<ConnectionBooleanFilterInput>;
  separateMobile?: InputMaybe<ConnectionBooleanFilterInput>;
  sitemapsInCount?: InputMaybe<ConnectionIntFilterInput>;
  sitespeedAuditResultsCount?: InputMaybe<ConnectionIntFilterInput>;
  sitespeedCls?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedClsAuditResultsCount?: InputMaybe<ConnectionIntFilterInput>;
  sitespeedClsMainFrame?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedClsScore?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedFcp?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedFcpAuditResultsCount?: InputMaybe<ConnectionIntFilterInput>;
  sitespeedFcpScore?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedFmp?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedLcp?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedLcpAuditResultsCount?: InputMaybe<ConnectionIntFilterInput>;
  sitespeedLcpScore?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedMaxPotentialFid?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedScore?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedSpeedIndex?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedSpeedIndexScore?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedTbt?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedTbtAuditResultsCount?: InputMaybe<ConnectionIntFilterInput>;
  sitespeedTbtScore?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedTtfb?: InputMaybe<ConnectionFloatFilterInput>;
  sitespeedTti?: InputMaybe<ConnectionFloatFilterInput>;
  speedIndex?: InputMaybe<ConnectionIntFilterInput>;
  speedIndexScore?: InputMaybe<ConnectionFloatFilterInput>;
  taskIds?: InputMaybe<ConnectionIntArrayFilterInput>;
  taskIdsCount?: InputMaybe<ConnectionIntFilterInput>;
  thirdPartyCookieOrigins?: InputMaybe<ConnectionStringArrayFilterInput>;
  timeToFirstByte?: InputMaybe<ConnectionIntFilterInput>;
  totalBlockingTime?: InputMaybe<ConnectionIntFilterInput>;
  totalBlockingTimeScore?: InputMaybe<ConnectionFloatFilterInput>;
  transferSize?: InputMaybe<ConnectionIntFilterInput>;
  twitterCard?: InputMaybe<ConnectionStringFilterInput>;
  twitterDescription?: InputMaybe<ConnectionStringFilterInput>;
  twitterDescriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  twitterImage?: InputMaybe<ConnectionStringFilterInput>;
  twitterSite?: InputMaybe<ConnectionStringFilterInput>;
  twitterTitle?: InputMaybe<ConnectionStringFilterInput>;
  unavailableAfter?: InputMaybe<ConnectionStringFilterInput>;
  unavailableAfterDays?: InputMaybe<ConnectionIntFilterInput>;
  uniqueLinksInCount?: InputMaybe<ConnectionIntFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
  urlAlias?: InputMaybe<ConnectionStringFilterInput>;
  urlAliasDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlContainsRepeatedPaths?: InputMaybe<ConnectionBooleanFilterInput>;
  urlContainsUnderscores?: InputMaybe<ConnectionBooleanFilterInput>;
  urlDigest?: InputMaybe<ConnectionStringFilterInput>;
  urlDoubleEncoded?: InputMaybe<ConnectionBooleanFilterInput>;
  urlLength?: InputMaybe<ConnectionIntFilterInput>;
  urlNormalized?: InputMaybe<ConnectionStringFilterInput>;
  urlPathContainsDoubleSlashes?: InputMaybe<ConnectionBooleanFilterInput>;
  validTwitterCard?: InputMaybe<ConnectionBooleanFilterInput>;
  varyUserAgent?: InputMaybe<ConnectionBooleanFilterInput>;
  videoobjectSchemaCount?: InputMaybe<ConnectionIntFilterInput>;
  viewport?: InputMaybe<ConnectionStringFilterInput>;
  warningAudits?: InputMaybe<ConnectionStringArrayFilterInput>;
  warningAuditsCount?: InputMaybe<ConnectionIntFilterInput>;
  wordCount?: InputMaybe<ConnectionIntFilterInput>;
};

export type CrawlUrlCountCalculation = {
  accessibilityAIssuesCount: Scalars['Int']['output'];
  accessibilityAaIssuesCount: Scalars['Int']['output'];
  accessibilityAaaIssuesCount: Scalars['Int']['output'];
  accessibilityBpIssuesCount: Scalars['Int']['output'];
  accessibilityCriticalIssuesCount: Scalars['Int']['output'];
  accessibilityIncompleteIssuesCount: Scalars['Int']['output'];
  accessibilityIssuesCount: Scalars['Int']['output'];
  accessibilityMinorIssuesCount: Scalars['Int']['output'];
  accessibilityModerateIssuesCount: Scalars['Int']['output'];
  accessibilitySeriousIssuesCount: Scalars['Int']['output'];
  backlinkCount: Scalars['Int']['output'];
  backlinkDomainCount: Scalars['Int']['output'];
  bodyTagsInHeadCount: Scalars['Int']['output'];
  breadcrumbCount: Scalars['Int']['output'];
  breadcrumbSchemaCount: Scalars['Int']['output'];
  canonicalLinksInCount: Scalars['Int']['output'];
  contentHtmlRatio: Scalars['Int']['output'];
  contentSize: Scalars['Int']['output'];
  cumulativeLayoutShift: Scalars['Int']['output'];
  cumulativeLayoutShiftMainFrame: Scalars['Int']['output'];
  cumulativeLayoutShiftScore: Scalars['Int']['output'];
  deeprank: Scalars['Int']['output'];
  descriptionLength: Scalars['Int']['output'];
  descriptionLengthPx: Scalars['Int']['output'];
  duplicateBodyCount: Scalars['Int']['output'];
  duplicateDescriptionCount: Scalars['Int']['output'];
  duplicatePageCount: Scalars['Int']['output'];
  duplicateTitleCount: Scalars['Int']['output'];
  duplicateUrlCount: Scalars['Int']['output'];
  eventSchemaCount: Scalars['Int']['output'];
  externalLinksCount: Scalars['Int']['output'];
  failedAuditsCount: Scalars['Int']['output'];
  faqpageSchemaCount: Scalars['Int']['output'];
  fetchTime: Scalars['Int']['output'];
  firstContentfulPaint: Scalars['Int']['output'];
  firstContentfulPaintScore: Scalars['Int']['output'];
  firstMeaningfulPaint: Scalars['Int']['output'];
  folderCount: Scalars['Int']['output'];
  followedExternalLinksOutCount: Scalars['Int']['output'];
  followedLinksInCount: Scalars['Int']['output'];
  foundAtSitemap: Scalars['Int']['output'];
  gaAvgPageLoadTime: Scalars['Int']['output'];
  gaAvgTimeOnPage: Scalars['Int']['output'];
  gaPageviewsPerVisits: Scalars['Int']['output'];
  gaVisitBounceRate: Scalars['Int']['output'];
  gaVisits: Scalars['Int']['output'];
  h1Count: Scalars['Int']['output'];
  h1Length: Scalars['Int']['output'];
  headTagsInBodyCount: Scalars['Int']['output'];
  howtoSchemaCount: Scalars['Int']['output'];
  hreflangLinksInCount: Scalars['Int']['output'];
  htmlSize: Scalars['Int']['output'];
  infoAuditsCount: Scalars['Int']['output'];
  interactive: Scalars['Int']['output'];
  internalLinksCount: Scalars['Int']['output'];
  largestContentfulPaint: Scalars['Int']['output'];
  largestContentfulPaintScore: Scalars['Int']['output'];
  lcpLoadEnd: Scalars['Int']['output'];
  lcpLoadStart: Scalars['Int']['output'];
  linksInCount: Scalars['Int']['output'];
  linksOutCount: Scalars['Int']['output'];
  logRequestsDesktop: Scalars['Int']['output'];
  logRequestsMobile: Scalars['Int']['output'];
  logRequestsTotal: Scalars['Int']['output'];
  maxPotentialFid: Scalars['Int']['output'];
  mobileDesktopContentDifference: Scalars['Int']['output'];
  mobileDesktopLinksInDifference: Scalars['Int']['output'];
  mobileDesktopLinksOutDifference: Scalars['Int']['output'];
  mobileLinksInCount: Scalars['Int']['output'];
  newsarticleSchemaCount: Scalars['Int']['output'];
  nonReciprocateHreflangOutCount: Scalars['Int']['output'];
  pageTitleLength: Scalars['Int']['output'];
  pageTitleLengthPx: Scalars['Int']['output'];
  paginatedLinksInCount: Scalars['Int']['output'];
  paramCount: Scalars['Int']['output'];
  passedAuditsCount: Scalars['Int']['output'];
  performanceCls: Scalars['Int']['output'];
  performanceDcl: Scalars['Int']['output'];
  performanceFcp: Scalars['Int']['output'];
  performanceLcp: Scalars['Int']['output'];
  performanceTtfb: Scalars['Int']['output'];
  performanceTti: Scalars['Int']['output'];
  productOfSavingsKibAndPageviews: Scalars['Int']['output'];
  productOfSavingsSecsAndPageviews: Scalars['Int']['output'];
  productPriceSchemaValue: Scalars['Int']['output'];
  productRatingSchemaValue: Scalars['Int']['output'];
  productSchemaCount: Scalars['Int']['output'];
  qapageSchemaCount: Scalars['Int']['output'];
  recipeSchemaCount: Scalars['Int']['output'];
  reciprocateHreflangOutCount: Scalars['Int']['output'];
  redirectCount: Scalars['Int']['output'];
  redirectsInCount: Scalars['Int']['output'];
  relLinksInCount: Scalars['Int']['output'];
  renderedLinkCountDifference: Scalars['Int']['output'];
  renderedWordCountDifference: Scalars['Int']['output'];
  resourceLinksInCount: Scalars['Int']['output'];
  reviewSchemaCount: Scalars['Int']['output'];
  savingsKib: Scalars['Int']['output'];
  savingsScore: Scalars['Int']['output'];
  savingsSecs: Scalars['Int']['output'];
  searchConsoleDesktopClicks: Scalars['Int']['output'];
  searchConsoleDesktopCtr: Scalars['Int']['output'];
  searchConsoleDesktopImpressions: Scalars['Int']['output'];
  searchConsoleDesktopPosition: Scalars['Int']['output'];
  searchConsoleMobileClicks: Scalars['Int']['output'];
  searchConsoleMobileCtr: Scalars['Int']['output'];
  searchConsoleMobileImpressions: Scalars['Int']['output'];
  searchConsoleMobilePosition: Scalars['Int']['output'];
  searchConsoleTabletClicks: Scalars['Int']['output'];
  searchConsoleTabletCtr: Scalars['Int']['output'];
  searchConsoleTabletImpressions: Scalars['Int']['output'];
  searchConsoleTabletPosition: Scalars['Int']['output'];
  searchConsoleTotalClicks: Scalars['Int']['output'];
  searchConsoleTotalCtr: Scalars['Int']['output'];
  searchConsoleTotalImpressions: Scalars['Int']['output'];
  searchConsoleTotalPosition: Scalars['Int']['output'];
  sitemapsInCount: Scalars['Int']['output'];
  sitespeedCls: Scalars['Int']['output'];
  sitespeedClsMainFrame: Scalars['Int']['output'];
  sitespeedClsScore: Scalars['Int']['output'];
  sitespeedFcp: Scalars['Int']['output'];
  sitespeedFcpScore: Scalars['Int']['output'];
  sitespeedFmp: Scalars['Int']['output'];
  sitespeedLcp: Scalars['Int']['output'];
  sitespeedLcpScore: Scalars['Int']['output'];
  sitespeedMaxPotentialFid: Scalars['Int']['output'];
  sitespeedScore: Scalars['Int']['output'];
  sitespeedSpeedIndex: Scalars['Int']['output'];
  sitespeedSpeedIndexScore: Scalars['Int']['output'];
  sitespeedTbt: Scalars['Int']['output'];
  sitespeedTbtScore: Scalars['Int']['output'];
  sitespeedTtfb: Scalars['Int']['output'];
  sitespeedTti: Scalars['Int']['output'];
  speedIndex: Scalars['Int']['output'];
  speedIndexScore: Scalars['Int']['output'];
  timeToFirstByte: Scalars['Int']['output'];
  totalBlockingTime: Scalars['Int']['output'];
  totalBlockingTimeScore: Scalars['Int']['output'];
  uniqueLinksInCount: Scalars['Int']['output'];
  url: Scalars['Int']['output'];
  videoobjectSchemaCount: Scalars['Int']['output'];
  warningAuditsCount: Scalars['Int']['output'];
  wordCount: Scalars['Int']['output'];
};

export type CrawlUrlEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlUrl;
};

export type CrawlUrlMaxCalculation = {
  accessibilityAIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityAaIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityAaaIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityBpIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityCriticalIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityIncompleteIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityMinorIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityModerateIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilitySeriousIssuesCount?: Maybe<Scalars['Int']['output']>;
  backlinkCount?: Maybe<Scalars['Int']['output']>;
  backlinkDomainCount?: Maybe<Scalars['Int']['output']>;
  bodyTagsInHeadCount?: Maybe<Scalars['Int']['output']>;
  breadcrumbCount?: Maybe<Scalars['Int']['output']>;
  breadcrumbSchemaCount?: Maybe<Scalars['Int']['output']>;
  canonicalLinksInCount?: Maybe<Scalars['Int']['output']>;
  contentHtmlRatio?: Maybe<Scalars['Float']['output']>;
  contentSize?: Maybe<Scalars['Int']['output']>;
  cumulativeLayoutShift?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftMainFrame?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftScore?: Maybe<Scalars['Float']['output']>;
  deeprank?: Maybe<Scalars['Float']['output']>;
  descriptionLength?: Maybe<Scalars['Int']['output']>;
  descriptionLengthPx?: Maybe<Scalars['Int']['output']>;
  duplicateBodyCount?: Maybe<Scalars['Int']['output']>;
  duplicateDescriptionCount?: Maybe<Scalars['Int']['output']>;
  duplicatePageCount?: Maybe<Scalars['Int']['output']>;
  duplicateTitleCount?: Maybe<Scalars['Int']['output']>;
  duplicateUrlCount?: Maybe<Scalars['Int']['output']>;
  eventSchemaCount?: Maybe<Scalars['Int']['output']>;
  externalLinksCount?: Maybe<Scalars['Int']['output']>;
  failedAuditsCount?: Maybe<Scalars['Int']['output']>;
  faqpageSchemaCount?: Maybe<Scalars['Int']['output']>;
  fetchTime?: Maybe<Scalars['Float']['output']>;
  firstContentfulPaint?: Maybe<Scalars['Int']['output']>;
  firstContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  firstMeaningfulPaint?: Maybe<Scalars['Int']['output']>;
  folderCount?: Maybe<Scalars['Int']['output']>;
  followedExternalLinksOutCount?: Maybe<Scalars['Int']['output']>;
  followedLinksInCount?: Maybe<Scalars['Int']['output']>;
  gaAvgPageLoadTime?: Maybe<Scalars['Float']['output']>;
  gaAvgTimeOnPage?: Maybe<Scalars['Float']['output']>;
  gaPageviewsPerVisits?: Maybe<Scalars['Float']['output']>;
  gaVisitBounceRate?: Maybe<Scalars['Float']['output']>;
  gaVisits?: Maybe<Scalars['Int']['output']>;
  h1Count?: Maybe<Scalars['Int']['output']>;
  h1Length?: Maybe<Scalars['Int']['output']>;
  headTagsInBodyCount?: Maybe<Scalars['Int']['output']>;
  howtoSchemaCount?: Maybe<Scalars['Int']['output']>;
  hreflangLinksInCount?: Maybe<Scalars['Int']['output']>;
  htmlSize?: Maybe<Scalars['Int']['output']>;
  infoAuditsCount?: Maybe<Scalars['Int']['output']>;
  interactive?: Maybe<Scalars['Int']['output']>;
  internalLinksCount?: Maybe<Scalars['Int']['output']>;
  largestContentfulPaint?: Maybe<Scalars['Int']['output']>;
  largestContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  lcpLoadEnd?: Maybe<Scalars['Int']['output']>;
  lcpLoadStart?: Maybe<Scalars['Int']['output']>;
  linksInCount?: Maybe<Scalars['Int']['output']>;
  linksOutCount?: Maybe<Scalars['Int']['output']>;
  logRequestsDesktop?: Maybe<Scalars['Int']['output']>;
  logRequestsMobile?: Maybe<Scalars['Int']['output']>;
  logRequestsTotal?: Maybe<Scalars['Int']['output']>;
  maxPotentialFid?: Maybe<Scalars['Int']['output']>;
  mobileDesktopContentDifference?: Maybe<Scalars['Int']['output']>;
  mobileDesktopLinksInDifference?: Maybe<Scalars['Int']['output']>;
  mobileDesktopLinksOutDifference?: Maybe<Scalars['Int']['output']>;
  mobileLinksInCount?: Maybe<Scalars['Int']['output']>;
  newsarticleSchemaCount?: Maybe<Scalars['Int']['output']>;
  nonReciprocateHreflangOutCount?: Maybe<Scalars['Int']['output']>;
  pageTitleLength?: Maybe<Scalars['Int']['output']>;
  pageTitleLengthPx?: Maybe<Scalars['Int']['output']>;
  paginatedLinksInCount?: Maybe<Scalars['Int']['output']>;
  paramCount?: Maybe<Scalars['Int']['output']>;
  passedAuditsCount?: Maybe<Scalars['Int']['output']>;
  performanceCls?: Maybe<Scalars['Float']['output']>;
  performanceDcl?: Maybe<Scalars['Float']['output']>;
  performanceFcp?: Maybe<Scalars['Float']['output']>;
  performanceLcp?: Maybe<Scalars['Float']['output']>;
  performanceTtfb?: Maybe<Scalars['Float']['output']>;
  performanceTti?: Maybe<Scalars['Float']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  productPriceSchemaValue?: Maybe<Scalars['Float']['output']>;
  productRatingSchemaValue?: Maybe<Scalars['Float']['output']>;
  productSchemaCount?: Maybe<Scalars['Int']['output']>;
  qapageSchemaCount?: Maybe<Scalars['Int']['output']>;
  recipeSchemaCount?: Maybe<Scalars['Int']['output']>;
  reciprocateHreflangOutCount?: Maybe<Scalars['Int']['output']>;
  redirectCount?: Maybe<Scalars['Int']['output']>;
  redirectsInCount?: Maybe<Scalars['Int']['output']>;
  relLinksInCount?: Maybe<Scalars['Int']['output']>;
  renderedLinkCountDifference?: Maybe<Scalars['Int']['output']>;
  renderedWordCountDifference?: Maybe<Scalars['Int']['output']>;
  resourceLinksInCount?: Maybe<Scalars['Int']['output']>;
  reviewSchemaCount?: Maybe<Scalars['Int']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleDesktopCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleDesktopPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleMobileCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleMobilePosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleTabletCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleTabletPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleTotalCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleTotalPosition?: Maybe<Scalars['Float']['output']>;
  sitemapsInCount?: Maybe<Scalars['Int']['output']>;
  sitespeedCls?: Maybe<Scalars['Float']['output']>;
  sitespeedClsMainFrame?: Maybe<Scalars['Float']['output']>;
  sitespeedClsScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFcp?: Maybe<Scalars['Float']['output']>;
  sitespeedFcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFmp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedMaxPotentialFid?: Maybe<Scalars['Float']['output']>;
  sitespeedScore?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndex?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndexScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTbt?: Maybe<Scalars['Float']['output']>;
  sitespeedTbtScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTtfb?: Maybe<Scalars['Float']['output']>;
  sitespeedTti?: Maybe<Scalars['Float']['output']>;
  speedIndex?: Maybe<Scalars['Int']['output']>;
  speedIndexScore?: Maybe<Scalars['Float']['output']>;
  timeToFirstByte?: Maybe<Scalars['Int']['output']>;
  totalBlockingTime?: Maybe<Scalars['Int']['output']>;
  totalBlockingTimeScore?: Maybe<Scalars['Float']['output']>;
  uniqueLinksInCount?: Maybe<Scalars['Int']['output']>;
  videoobjectSchemaCount?: Maybe<Scalars['Int']['output']>;
  warningAuditsCount?: Maybe<Scalars['Int']['output']>;
  wordCount?: Maybe<Scalars['Int']['output']>;
};

export type CrawlUrlMinCalculation = {
  accessibilityAIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityAaIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityAaaIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityBpIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityCriticalIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityIncompleteIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityMinorIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilityModerateIssuesCount?: Maybe<Scalars['Int']['output']>;
  accessibilitySeriousIssuesCount?: Maybe<Scalars['Int']['output']>;
  backlinkCount?: Maybe<Scalars['Int']['output']>;
  backlinkDomainCount?: Maybe<Scalars['Int']['output']>;
  bodyTagsInHeadCount?: Maybe<Scalars['Int']['output']>;
  breadcrumbCount?: Maybe<Scalars['Int']['output']>;
  breadcrumbSchemaCount?: Maybe<Scalars['Int']['output']>;
  canonicalLinksInCount?: Maybe<Scalars['Int']['output']>;
  contentHtmlRatio?: Maybe<Scalars['Float']['output']>;
  contentSize?: Maybe<Scalars['Int']['output']>;
  cumulativeLayoutShift?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftMainFrame?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftScore?: Maybe<Scalars['Float']['output']>;
  deeprank?: Maybe<Scalars['Float']['output']>;
  descriptionLength?: Maybe<Scalars['Int']['output']>;
  descriptionLengthPx?: Maybe<Scalars['Int']['output']>;
  duplicateBodyCount?: Maybe<Scalars['Int']['output']>;
  duplicateDescriptionCount?: Maybe<Scalars['Int']['output']>;
  duplicatePageCount?: Maybe<Scalars['Int']['output']>;
  duplicateTitleCount?: Maybe<Scalars['Int']['output']>;
  duplicateUrlCount?: Maybe<Scalars['Int']['output']>;
  eventSchemaCount?: Maybe<Scalars['Int']['output']>;
  externalLinksCount?: Maybe<Scalars['Int']['output']>;
  failedAuditsCount?: Maybe<Scalars['Int']['output']>;
  faqpageSchemaCount?: Maybe<Scalars['Int']['output']>;
  fetchTime?: Maybe<Scalars['Float']['output']>;
  firstContentfulPaint?: Maybe<Scalars['Int']['output']>;
  firstContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  firstMeaningfulPaint?: Maybe<Scalars['Int']['output']>;
  folderCount?: Maybe<Scalars['Int']['output']>;
  followedExternalLinksOutCount?: Maybe<Scalars['Int']['output']>;
  followedLinksInCount?: Maybe<Scalars['Int']['output']>;
  gaAvgPageLoadTime?: Maybe<Scalars['Float']['output']>;
  gaAvgTimeOnPage?: Maybe<Scalars['Float']['output']>;
  gaPageviewsPerVisits?: Maybe<Scalars['Float']['output']>;
  gaVisitBounceRate?: Maybe<Scalars['Float']['output']>;
  gaVisits?: Maybe<Scalars['Int']['output']>;
  h1Count?: Maybe<Scalars['Int']['output']>;
  h1Length?: Maybe<Scalars['Int']['output']>;
  headTagsInBodyCount?: Maybe<Scalars['Int']['output']>;
  howtoSchemaCount?: Maybe<Scalars['Int']['output']>;
  hreflangLinksInCount?: Maybe<Scalars['Int']['output']>;
  htmlSize?: Maybe<Scalars['Int']['output']>;
  infoAuditsCount?: Maybe<Scalars['Int']['output']>;
  interactive?: Maybe<Scalars['Int']['output']>;
  internalLinksCount?: Maybe<Scalars['Int']['output']>;
  largestContentfulPaint?: Maybe<Scalars['Int']['output']>;
  largestContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  lcpLoadEnd?: Maybe<Scalars['Int']['output']>;
  lcpLoadStart?: Maybe<Scalars['Int']['output']>;
  linksInCount?: Maybe<Scalars['Int']['output']>;
  linksOutCount?: Maybe<Scalars['Int']['output']>;
  logRequestsDesktop?: Maybe<Scalars['Int']['output']>;
  logRequestsMobile?: Maybe<Scalars['Int']['output']>;
  logRequestsTotal?: Maybe<Scalars['Int']['output']>;
  maxPotentialFid?: Maybe<Scalars['Int']['output']>;
  mobileDesktopContentDifference?: Maybe<Scalars['Int']['output']>;
  mobileDesktopLinksInDifference?: Maybe<Scalars['Int']['output']>;
  mobileDesktopLinksOutDifference?: Maybe<Scalars['Int']['output']>;
  mobileLinksInCount?: Maybe<Scalars['Int']['output']>;
  newsarticleSchemaCount?: Maybe<Scalars['Int']['output']>;
  nonReciprocateHreflangOutCount?: Maybe<Scalars['Int']['output']>;
  pageTitleLength?: Maybe<Scalars['Int']['output']>;
  pageTitleLengthPx?: Maybe<Scalars['Int']['output']>;
  paginatedLinksInCount?: Maybe<Scalars['Int']['output']>;
  paramCount?: Maybe<Scalars['Int']['output']>;
  passedAuditsCount?: Maybe<Scalars['Int']['output']>;
  performanceCls?: Maybe<Scalars['Float']['output']>;
  performanceDcl?: Maybe<Scalars['Float']['output']>;
  performanceFcp?: Maybe<Scalars['Float']['output']>;
  performanceLcp?: Maybe<Scalars['Float']['output']>;
  performanceTtfb?: Maybe<Scalars['Float']['output']>;
  performanceTti?: Maybe<Scalars['Float']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  productPriceSchemaValue?: Maybe<Scalars['Float']['output']>;
  productRatingSchemaValue?: Maybe<Scalars['Float']['output']>;
  productSchemaCount?: Maybe<Scalars['Int']['output']>;
  qapageSchemaCount?: Maybe<Scalars['Int']['output']>;
  recipeSchemaCount?: Maybe<Scalars['Int']['output']>;
  reciprocateHreflangOutCount?: Maybe<Scalars['Int']['output']>;
  redirectCount?: Maybe<Scalars['Int']['output']>;
  redirectsInCount?: Maybe<Scalars['Int']['output']>;
  relLinksInCount?: Maybe<Scalars['Int']['output']>;
  renderedLinkCountDifference?: Maybe<Scalars['Int']['output']>;
  renderedWordCountDifference?: Maybe<Scalars['Int']['output']>;
  resourceLinksInCount?: Maybe<Scalars['Int']['output']>;
  reviewSchemaCount?: Maybe<Scalars['Int']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleDesktopCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleDesktopPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleMobileCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleMobilePosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleTabletCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleTabletPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalClicks?: Maybe<Scalars['Int']['output']>;
  searchConsoleTotalCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalImpressions?: Maybe<Scalars['Int']['output']>;
  searchConsoleTotalPosition?: Maybe<Scalars['Float']['output']>;
  sitemapsInCount?: Maybe<Scalars['Int']['output']>;
  sitespeedCls?: Maybe<Scalars['Float']['output']>;
  sitespeedClsMainFrame?: Maybe<Scalars['Float']['output']>;
  sitespeedClsScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFcp?: Maybe<Scalars['Float']['output']>;
  sitespeedFcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFmp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedMaxPotentialFid?: Maybe<Scalars['Float']['output']>;
  sitespeedScore?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndex?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndexScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTbt?: Maybe<Scalars['Float']['output']>;
  sitespeedTbtScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTtfb?: Maybe<Scalars['Float']['output']>;
  sitespeedTti?: Maybe<Scalars['Float']['output']>;
  speedIndex?: Maybe<Scalars['Int']['output']>;
  speedIndexScore?: Maybe<Scalars['Float']['output']>;
  timeToFirstByte?: Maybe<Scalars['Int']['output']>;
  totalBlockingTime?: Maybe<Scalars['Int']['output']>;
  totalBlockingTimeScore?: Maybe<Scalars['Float']['output']>;
  uniqueLinksInCount?: Maybe<Scalars['Int']['output']>;
  videoobjectSchemaCount?: Maybe<Scalars['Int']['output']>;
  warningAuditsCount?: Maybe<Scalars['Int']['output']>;
  wordCount?: Maybe<Scalars['Int']['output']>;
};

export type CrawlUrlOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlUrls by. */
  field: CrawlUrlOrderField;
};

export enum CrawlUrlOrderField {
  AccessibilityAIssuesCount = 'accessibilityAIssuesCount',
  AccessibilityAaIssuesCount = 'accessibilityAaIssuesCount',
  AccessibilityAaaIssuesCount = 'accessibilityAaaIssuesCount',
  AccessibilityBpIssuesCount = 'accessibilityBpIssuesCount',
  AccessibilityCriticalIssuesCount = 'accessibilityCriticalIssuesCount',
  AccessibilityEnabled = 'accessibilityEnabled',
  AccessibilityIncompleteIssuesCount = 'accessibilityIncompleteIssuesCount',
  AccessibilityIssuesCount = 'accessibilityIssuesCount',
  AccessibilityIssuesCountByRuleIdCount = 'accessibilityIssuesCountByRuleIdCount',
  AccessibilityMinorIssuesCount = 'accessibilityMinorIssuesCount',
  AccessibilityModerateIssuesCount = 'accessibilityModerateIssuesCount',
  AccessibilitySeriousIssuesCount = 'accessibilitySeriousIssuesCount',
  AdultRating = 'adultRating',
  AiBotBlockedCount = 'aiBotBlockedCount',
  Amphtml = 'amphtml',
  AmphtmlReciprocate = 'amphtmlReciprocate',
  AmphtmlValid = 'amphtmlValid',
  BacklinkCount = 'backlinkCount',
  BacklinkDomainCount = 'backlinkDomainCount',
  BodyTagsInHeadCount = 'bodyTagsInHeadCount',
  Breadcrumb01 = 'breadcrumb01',
  Breadcrumb02 = 'breadcrumb02',
  Breadcrumb03 = 'breadcrumb03',
  Breadcrumb04 = 'breadcrumb04',
  Breadcrumb05 = 'breadcrumb05',
  Breadcrumb06 = 'breadcrumb06',
  Breadcrumb07 = 'breadcrumb07',
  Breadcrumb08 = 'breadcrumb08',
  BreadcrumbCount = 'breadcrumbCount',
  BreadcrumbSchemaCount = 'breadcrumbSchemaCount',
  CanonicalHeaderUrl = 'canonicalHeaderUrl',
  CanonicalHtmlUrl = 'canonicalHtmlUrl',
  CanonicalLinksInCount = 'canonicalLinksInCount',
  CanonicalUrl = 'canonicalUrl',
  CanonicalUrlChangedFrom = 'canonicalUrlChangedFrom',
  CanonicalUrlIsConsistent = 'canonicalUrlIsConsistent',
  CanonicalizedPage = 'canonicalizedPage',
  ChangesCount = 'changesCount',
  ContainerExecutionFailuresCount = 'containerExecutionFailuresCount',
  ContentHtmlRatio = 'contentHtmlRatio',
  ContentSize = 'contentSize',
  CrawlDatetime = 'crawlDatetime',
  Css = 'css',
  CssLinksInCount = 'cssLinksInCount',
  CumulativeLayoutShift = 'cumulativeLayoutShift',
  CumulativeLayoutShiftMainFrame = 'cumulativeLayoutShiftMainFrame',
  CumulativeLayoutShiftScore = 'cumulativeLayoutShiftScore',
  CustomExtraction1Count = 'customExtraction1Count',
  CustomExtraction2Count = 'customExtraction2Count',
  CustomExtraction3Count = 'customExtraction3Count',
  CustomExtraction4Count = 'customExtraction4Count',
  CustomExtraction5Count = 'customExtraction5Count',
  CustomExtraction6Count = 'customExtraction6Count',
  CustomExtraction7Count = 'customExtraction7Count',
  CustomExtraction8Count = 'customExtraction8Count',
  CustomExtraction9Count = 'customExtraction9Count',
  CustomExtraction10Count = 'customExtraction10Count',
  CustomExtraction11Count = 'customExtraction11Count',
  CustomExtraction12Count = 'customExtraction12Count',
  CustomExtraction13Count = 'customExtraction13Count',
  CustomExtraction14Count = 'customExtraction14Count',
  CustomExtraction15Count = 'customExtraction15Count',
  CustomExtraction16Count = 'customExtraction16Count',
  CustomExtraction17Count = 'customExtraction17Count',
  CustomExtraction18Count = 'customExtraction18Count',
  CustomExtraction19Count = 'customExtraction19Count',
  CustomExtraction20Count = 'customExtraction20Count',
  CustomExtraction21Count = 'customExtraction21Count',
  CustomExtraction22Count = 'customExtraction22Count',
  CustomExtraction23Count = 'customExtraction23Count',
  CustomExtraction24Count = 'customExtraction24Count',
  CustomExtraction25Count = 'customExtraction25Count',
  CustomExtraction26Count = 'customExtraction26Count',
  CustomExtraction27Count = 'customExtraction27Count',
  CustomExtraction28Count = 'customExtraction28Count',
  CustomExtraction29Count = 'customExtraction29Count',
  CustomExtraction30Count = 'customExtraction30Count',
  CustomExtractionMatch = 'customExtractionMatch',
  Deeprank = 'deeprank',
  Description = 'description',
  DescriptionChangedFrom = 'descriptionChangedFrom',
  DescriptionLength = 'descriptionLength',
  DescriptionLengthPx = 'descriptionLengthPx',
  DesktopUrl = 'desktopUrl',
  DisallowedPage = 'disallowedPage',
  DuplicateBody = 'duplicateBody',
  DuplicateBodyCount = 'duplicateBodyCount',
  DuplicateBodyCountIsApproximation = 'duplicateBodyCountIsApproximation',
  DuplicateBodyNonIndexable = 'duplicateBodyNonIndexable',
  DuplicateBodyPrimaryUrl = 'duplicateBodyPrimaryUrl',
  DuplicateDescription = 'duplicateDescription',
  DuplicateDescriptionCount = 'duplicateDescriptionCount',
  DuplicateDescriptionCountIsApproximation = 'duplicateDescriptionCountIsApproximation',
  DuplicateDescriptionNonIndexable = 'duplicateDescriptionNonIndexable',
  DuplicateDescriptionPrimaryUrl = 'duplicateDescriptionPrimaryUrl',
  DuplicatePage = 'duplicatePage',
  DuplicatePageCount = 'duplicatePageCount',
  DuplicatePageCountIsApproximation = 'duplicatePageCountIsApproximation',
  DuplicatePageNonIndexable = 'duplicatePageNonIndexable',
  DuplicateTitle = 'duplicateTitle',
  DuplicateTitleCount = 'duplicateTitleCount',
  DuplicateTitleCountIsApproximation = 'duplicateTitleCountIsApproximation',
  DuplicateTitleNonIndexable = 'duplicateTitleNonIndexable',
  DuplicateTitlePrimaryUrl = 'duplicateTitlePrimaryUrl',
  DuplicateUrl = 'duplicateUrl',
  DuplicateUrlCount = 'duplicateUrlCount',
  DuplicateUrlCountIsApproximation = 'duplicateUrlCountIsApproximation',
  DuplicateUrlNonIndexable = 'duplicateUrlNonIndexable',
  DuplicateUrlPrimaryUrl = 'duplicateUrlPrimaryUrl',
  EncodingHeader = 'encodingHeader',
  EventSchemaCount = 'eventSchemaCount',
  ExternalLinksCount = 'externalLinksCount',
  FailedAuditsCount = 'failedAuditsCount',
  FailedReason = 'failedReason',
  FaqpageSchemaCount = 'faqpageSchemaCount',
  FbAdmins = 'fbAdmins',
  FbAppId = 'fbAppId',
  FetchTime = 'fetchTime',
  FileExtension = 'fileExtension',
  FirstContentfulPaint = 'firstContentfulPaint',
  FirstContentfulPaintScore = 'firstContentfulPaintScore',
  FirstMeaningfulPaint = 'firstMeaningfulPaint',
  FolderCount = 'folderCount',
  FollowedExternalLinksOutCount = 'followedExternalLinksOutCount',
  FollowedLinksInCount = 'followedLinksInCount',
  FoundAtSitemap = 'foundAtSitemap',
  FoundAtUrl = 'foundAtUrl',
  FoundInBacklinks = 'foundInBacklinks',
  FoundInCustomMetricsUpload = 'foundInCustomMetricsUpload',
  FoundInGoogleAnalytics = 'foundInGoogleAnalytics',
  FoundInGoogleSearchConsole = 'foundInGoogleSearchConsole',
  FoundInList = 'foundInList',
  FoundInLogSummary = 'foundInLogSummary',
  FoundInSitemap = 'foundInSitemap',
  FoundInTask = 'foundInTask',
  FoundInWebCrawl = 'foundInWebCrawl',
  GaAvgPageLoadTime = 'gaAvgPageLoadTime',
  GaAvgTimeOnPage = 'gaAvgTimeOnPage',
  GaPageviews = 'gaPageviews',
  GaPageviewsPerVisits = 'gaPageviewsPerVisits',
  GaVisitBounceRate = 'gaVisitBounceRate',
  GaVisits = 'gaVisits',
  H1Count = 'h1Count',
  H1Length = 'h1Length',
  H1TagChangedFromCount = 'h1TagChangedFromCount',
  H1TagCount = 'h1TagCount',
  H2TagCount = 'h2TagCount',
  H3TagCount = 'h3TagCount',
  HasStructuredData = 'hasStructuredData',
  HeadTagsInBodyCount = 'headTagsInBodyCount',
  HeaderContentType = 'headerContentType',
  HeaderNoarchive = 'headerNoarchive',
  HeaderNofollow = 'headerNofollow',
  HeaderNoindex = 'headerNoindex',
  HeaderNoodp = 'headerNoodp',
  HeaderNosnippet = 'headerNosnippet',
  HeaderNoydir = 'headerNoydir',
  HowtoSchemaCount = 'howtoSchemaCount',
  HreflangCombinationChangedFromCount = 'hreflangCombinationChangedFromCount',
  HreflangCombinationCount = 'hreflangCombinationCount',
  HreflangLanguagesInCount = 'hreflangLanguagesInCount',
  HreflangLanguagesInMetaCount = 'hreflangLanguagesInMetaCount',
  HreflangLinksInCount = 'hreflangLinksInCount',
  HreflangLinksOutCount = 'hreflangLinksOutCount',
  HreflangOnHeader = 'hreflangOnHeader',
  HreflangOnPage = 'hreflangOnPage',
  HreflangOnSitemap = 'hreflangOnSitemap',
  HreflangUrlCount = 'hreflangUrlCount',
  Hsts = 'hsts',
  HtmlSize = 'htmlSize',
  HttpStatusCode = 'httpStatusCode',
  HttpStatusCodeChangedFrom = 'httpStatusCodeChangedFrom',
  Https = 'https',
  IdentifiedLanguage = 'identifiedLanguage',
  ImgLinksInCount = 'imgLinksInCount',
  InconsistentHreflangsInCount = 'inconsistentHreflangsInCount',
  Indexable = 'indexable',
  InfoAuditsCount = 'infoAuditsCount',
  Interactive = 'interactive',
  Internal = 'internal',
  InternalLinksCount = 'internalLinksCount',
  IsImage = 'isImage',
  IsRedirect = 'isRedirect',
  IsRedirectLoop = 'isRedirectLoop',
  IsSelfCanonical = 'isSelfCanonical',
  IsWebLinked = 'isWebLinked',
  Js = 'js',
  JsLinksInCount = 'jsLinksInCount',
  LargestContentfulPaint = 'largestContentfulPaint',
  LargestContentfulPaintScore = 'largestContentfulPaintScore',
  LcpLoadEnd = 'lcpLoadEnd',
  LcpLoadStart = 'lcpLoadStart',
  Level = 'level',
  LevelChangedFrom = 'levelChangedFrom',
  LinksInCount = 'linksInCount',
  LinksOutCount = 'linksOutCount',
  LogRequestsDesktop = 'logRequestsDesktop',
  LogRequestsMobile = 'logRequestsMobile',
  LogRequestsTotal = 'logRequestsTotal',
  MaxPotentialFid = 'maxPotentialFid',
  MetaCharset = 'metaCharset',
  MetaContentType = 'metaContentType',
  MetaDisabledSitelinks = 'metaDisabledSitelinks',
  MetaNoarchive = 'metaNoarchive',
  MetaNofollow = 'metaNofollow',
  MetaNoindex = 'metaNoindex',
  MetaNoodp = 'metaNoodp',
  MetaNosnippet = 'metaNosnippet',
  MetaNoydir = 'metaNoydir',
  MetaRedirect = 'metaRedirect',
  MobileDesktopContentDifference = 'mobileDesktopContentDifference',
  MobileDesktopContentMismatch = 'mobileDesktopContentMismatch',
  MobileDesktopLinksInDifference = 'mobileDesktopLinksInDifference',
  MobileDesktopLinksOutDifference = 'mobileDesktopLinksOutDifference',
  MobileLinksInCount = 'mobileLinksInCount',
  MobileReciprocate = 'mobileReciprocate',
  MobileRelAlternate = 'mobileRelAlternate',
  MobileRelAlternateHeaderUrl = 'mobileRelAlternateHeaderUrl',
  MobileRelAlternateHtmlUrl = 'mobileRelAlternateHtmlUrl',
  MobileRelAlternateUrl = 'mobileRelAlternateUrl',
  MobileRelAlternateUrlIsConsistent = 'mobileRelAlternateUrlIsConsistent',
  MobileRelAmphtml = 'mobileRelAmphtml',
  MobileRelAmphtmlUrl = 'mobileRelAmphtmlUrl',
  NewsarticleAuthorSchemaValue = 'newsarticleAuthorSchemaValue',
  NewsarticleSchemaCount = 'newsarticleSchemaCount',
  NewsarticleSchemaValid = 'newsarticleSchemaValid',
  Noarchive = 'noarchive',
  NofollowedPage = 'nofollowedPage',
  Noindex = 'noindex',
  NonReciprocateHreflangOutCount = 'nonReciprocateHreflangOutCount',
  Noodp = 'noodp',
  Nosnippet = 'nosnippet',
  Noydir = 'noydir',
  OgDescription = 'ogDescription',
  OgImage = 'ogImage',
  OgLocale = 'ogLocale',
  OgSiteName = 'ogSiteName',
  OgTitle = 'ogTitle',
  OgType = 'ogType',
  OgUrl = 'ogUrl',
  Page1 = 'page1',
  PageGroup = 'pageGroup',
  PageTitle = 'pageTitle',
  PageTitleChangedFrom = 'pageTitleChangedFrom',
  PageTitleLength = 'pageTitleLength',
  PageTitleLengthPx = 'pageTitleLengthPx',
  PaginatedLinksInCount = 'paginatedLinksInCount',
  PaginatedPage = 'paginatedPage',
  ParamCount = 'paramCount',
  PassedAuditsCount = 'passedAuditsCount',
  Path0 = 'path0',
  Path1 = 'path1',
  Path2 = 'path2',
  Path3 = 'path3',
  Path4 = 'path4',
  Path5 = 'path5',
  Path6 = 'path6',
  Path7 = 'path7',
  Path8 = 'path8',
  Path9 = 'path9',
  PathCount = 'pathCount',
  PerformanceCls = 'performanceCls',
  PerformanceDcl = 'performanceDcl',
  PerformanceFcp = 'performanceFcp',
  PerformanceLcp = 'performanceLcp',
  PerformanceTtfb = 'performanceTtfb',
  PerformanceTti = 'performanceTti',
  PreviousPath = 'previousPath',
  PrimaryPage = 'primaryPage',
  PrimaryUrl = 'primaryUrl',
  PrimaryUrlNonIndexable = 'primaryUrlNonIndexable',
  ProductAvailabilitySchemaValue = 'productAvailabilitySchemaValue',
  ProductBrandSchemaValue = 'productBrandSchemaValue',
  ProductOfSavingsKibAndPageviews = 'productOfSavingsKibAndPageviews',
  ProductOfSavingsSecsAndPageviews = 'productOfSavingsSecsAndPageviews',
  ProductPriceSchemaValue = 'productPriceSchemaValue',
  ProductRatingSchemaValue = 'productRatingSchemaValue',
  ProductSchemaCount = 'productSchemaCount',
  ProductSchemaValid = 'productSchemaValid',
  ProductSkuSchemaValue = 'productSkuSchemaValue',
  QapageSchemaCount = 'qapageSchemaCount',
  RecipeSchemaCount = 'recipeSchemaCount',
  ReciprocateHreflangOutCount = 'reciprocateHreflangOutCount',
  RedirectChainCount = 'redirectChainCount',
  RedirectCount = 'redirectCount',
  RedirectedToStatusCode = 'redirectedToStatusCode',
  RedirectedToUrl = 'redirectedToUrl',
  RedirectedToUrlChangedFrom = 'redirectedToUrlChangedFrom',
  RedirectsInCount = 'redirectsInCount',
  RelLinksInCount = 'relLinksInCount',
  RelNextHeaderUrl = 'relNextHeaderUrl',
  RelNextHtmlUrl = 'relNextHtmlUrl',
  RelNextUrl = 'relNextUrl',
  RelNextUrlIsConsistent = 'relNextUrlIsConsistent',
  RelPrevHeaderUrl = 'relPrevHeaderUrl',
  RelPrevHtmlUrl = 'relPrevHtmlUrl',
  RelPrevUrl = 'relPrevUrl',
  RelPrevUrlIsConsistent = 'relPrevUrlIsConsistent',
  RenderedCanonicalLinkMatch = 'renderedCanonicalLinkMatch',
  RenderedLinkCountDifference = 'renderedLinkCountDifference',
  RenderedWordCountDifference = 'renderedWordCountDifference',
  ResourceLinksInCount = 'resourceLinksInCount',
  Responsive = 'responsive',
  ReviewSchemaCount = 'reviewSchemaCount',
  RobotsNoindex = 'robotsNoindex',
  RobotsTxtRuleMatch = 'robotsTxtRuleMatch',
  SavingsKib = 'savingsKib',
  SavingsScore = 'savingsScore',
  SavingsSecs = 'savingsSecs',
  SearchConsoleDesktopClicks = 'searchConsoleDesktopClicks',
  SearchConsoleDesktopCtr = 'searchConsoleDesktopCtr',
  SearchConsoleDesktopImpressions = 'searchConsoleDesktopImpressions',
  SearchConsoleDesktopPosition = 'searchConsoleDesktopPosition',
  SearchConsoleMobileClicks = 'searchConsoleMobileClicks',
  SearchConsoleMobileCtr = 'searchConsoleMobileCtr',
  SearchConsoleMobileImpressions = 'searchConsoleMobileImpressions',
  SearchConsoleMobilePosition = 'searchConsoleMobilePosition',
  SearchConsoleTabletClicks = 'searchConsoleTabletClicks',
  SearchConsoleTabletCtr = 'searchConsoleTabletCtr',
  SearchConsoleTabletImpressions = 'searchConsoleTabletImpressions',
  SearchConsoleTabletPosition = 'searchConsoleTabletPosition',
  SearchConsoleTotalClicks = 'searchConsoleTotalClicks',
  SearchConsoleTotalCtr = 'searchConsoleTotalCtr',
  SearchConsoleTotalImpressions = 'searchConsoleTotalImpressions',
  SearchConsoleTotalPosition = 'searchConsoleTotalPosition',
  SecureFormInputField = 'secureFormInputField',
  SeparateDesktop = 'separateDesktop',
  SeparateMobile = 'separateMobile',
  SitemapsInCount = 'sitemapsInCount',
  SitespeedAuditResultsCount = 'sitespeedAuditResultsCount',
  SitespeedCls = 'sitespeedCls',
  SitespeedClsAuditResultsCount = 'sitespeedClsAuditResultsCount',
  SitespeedClsMainFrame = 'sitespeedClsMainFrame',
  SitespeedClsScore = 'sitespeedClsScore',
  SitespeedFcp = 'sitespeedFcp',
  SitespeedFcpAuditResultsCount = 'sitespeedFcpAuditResultsCount',
  SitespeedFcpScore = 'sitespeedFcpScore',
  SitespeedFmp = 'sitespeedFmp',
  SitespeedLcp = 'sitespeedLcp',
  SitespeedLcpAuditResultsCount = 'sitespeedLcpAuditResultsCount',
  SitespeedLcpScore = 'sitespeedLcpScore',
  SitespeedMaxPotentialFid = 'sitespeedMaxPotentialFid',
  SitespeedScore = 'sitespeedScore',
  SitespeedSpeedIndex = 'sitespeedSpeedIndex',
  SitespeedSpeedIndexScore = 'sitespeedSpeedIndexScore',
  SitespeedTbt = 'sitespeedTbt',
  SitespeedTbtAuditResultsCount = 'sitespeedTbtAuditResultsCount',
  SitespeedTbtScore = 'sitespeedTbtScore',
  SitespeedTtfb = 'sitespeedTtfb',
  SitespeedTti = 'sitespeedTti',
  SpeedIndex = 'speedIndex',
  SpeedIndexScore = 'speedIndexScore',
  TaskIdsCount = 'taskIdsCount',
  TimeToFirstByte = 'timeToFirstByte',
  TotalBlockingTime = 'totalBlockingTime',
  TotalBlockingTimeScore = 'totalBlockingTimeScore',
  TransferSize = 'transferSize',
  TwitterCard = 'twitterCard',
  TwitterDescription = 'twitterDescription',
  TwitterDescriptionLength = 'twitterDescriptionLength',
  TwitterImage = 'twitterImage',
  TwitterSite = 'twitterSite',
  TwitterTitle = 'twitterTitle',
  UnavailableAfter = 'unavailableAfter',
  UnavailableAfterDays = 'unavailableAfterDays',
  UniqueLinksInCount = 'uniqueLinksInCount',
  Url = 'url',
  UrlAlias = 'urlAlias',
  UrlContainsRepeatedPaths = 'urlContainsRepeatedPaths',
  UrlContainsUnderscores = 'urlContainsUnderscores',
  UrlDoubleEncoded = 'urlDoubleEncoded',
  UrlLength = 'urlLength',
  UrlNormalized = 'urlNormalized',
  UrlPathContainsDoubleSlashes = 'urlPathContainsDoubleSlashes',
  ValidTwitterCard = 'validTwitterCard',
  VaryUserAgent = 'varyUserAgent',
  VideoobjectSchemaCount = 'videoobjectSchemaCount',
  Viewport = 'viewport',
  WarningAuditsCount = 'warningAuditsCount',
  WordCount = 'wordCount'
}

export type CrawlUrlSumCalculation = {
  accessibilityAIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilityAaIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilityAaaIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilityBpIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilityCriticalIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilityIncompleteIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilityIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilityMinorIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilityModerateIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  accessibilitySeriousIssuesCount?: Maybe<Scalars['BigInt']['output']>;
  backlinkCount?: Maybe<Scalars['BigInt']['output']>;
  backlinkDomainCount?: Maybe<Scalars['BigInt']['output']>;
  bodyTagsInHeadCount?: Maybe<Scalars['BigInt']['output']>;
  breadcrumbCount?: Maybe<Scalars['BigInt']['output']>;
  breadcrumbSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  canonicalLinksInCount?: Maybe<Scalars['BigInt']['output']>;
  contentHtmlRatio?: Maybe<Scalars['Float']['output']>;
  contentSize?: Maybe<Scalars['BigInt']['output']>;
  cumulativeLayoutShift?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftMainFrame?: Maybe<Scalars['Float']['output']>;
  cumulativeLayoutShiftScore?: Maybe<Scalars['Float']['output']>;
  deeprank?: Maybe<Scalars['Float']['output']>;
  descriptionLength?: Maybe<Scalars['BigInt']['output']>;
  descriptionLengthPx?: Maybe<Scalars['BigInt']['output']>;
  duplicateBodyCount?: Maybe<Scalars['BigInt']['output']>;
  duplicateDescriptionCount?: Maybe<Scalars['BigInt']['output']>;
  duplicatePageCount?: Maybe<Scalars['BigInt']['output']>;
  duplicateTitleCount?: Maybe<Scalars['BigInt']['output']>;
  duplicateUrlCount?: Maybe<Scalars['BigInt']['output']>;
  eventSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  externalLinksCount?: Maybe<Scalars['BigInt']['output']>;
  failedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  faqpageSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  fetchTime?: Maybe<Scalars['Float']['output']>;
  firstContentfulPaint?: Maybe<Scalars['BigInt']['output']>;
  firstContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  firstMeaningfulPaint?: Maybe<Scalars['BigInt']['output']>;
  folderCount?: Maybe<Scalars['BigInt']['output']>;
  followedExternalLinksOutCount?: Maybe<Scalars['BigInt']['output']>;
  followedLinksInCount?: Maybe<Scalars['BigInt']['output']>;
  gaAvgPageLoadTime?: Maybe<Scalars['Float']['output']>;
  gaAvgTimeOnPage?: Maybe<Scalars['Float']['output']>;
  gaPageviewsPerVisits?: Maybe<Scalars['Float']['output']>;
  gaVisitBounceRate?: Maybe<Scalars['Float']['output']>;
  gaVisits?: Maybe<Scalars['BigInt']['output']>;
  h1Count?: Maybe<Scalars['BigInt']['output']>;
  h1Length?: Maybe<Scalars['BigInt']['output']>;
  headTagsInBodyCount?: Maybe<Scalars['BigInt']['output']>;
  howtoSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  hreflangLinksInCount?: Maybe<Scalars['BigInt']['output']>;
  htmlSize?: Maybe<Scalars['BigInt']['output']>;
  infoAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  interactive?: Maybe<Scalars['BigInt']['output']>;
  internalLinksCount?: Maybe<Scalars['BigInt']['output']>;
  largestContentfulPaint?: Maybe<Scalars['BigInt']['output']>;
  largestContentfulPaintScore?: Maybe<Scalars['Float']['output']>;
  lcpLoadEnd?: Maybe<Scalars['BigInt']['output']>;
  lcpLoadStart?: Maybe<Scalars['BigInt']['output']>;
  linksInCount?: Maybe<Scalars['BigInt']['output']>;
  linksOutCount?: Maybe<Scalars['BigInt']['output']>;
  logRequestsDesktop?: Maybe<Scalars['BigInt']['output']>;
  logRequestsMobile?: Maybe<Scalars['BigInt']['output']>;
  logRequestsTotal?: Maybe<Scalars['BigInt']['output']>;
  maxPotentialFid?: Maybe<Scalars['BigInt']['output']>;
  mobileDesktopContentDifference?: Maybe<Scalars['BigInt']['output']>;
  mobileDesktopLinksInDifference?: Maybe<Scalars['BigInt']['output']>;
  mobileDesktopLinksOutDifference?: Maybe<Scalars['BigInt']['output']>;
  mobileLinksInCount?: Maybe<Scalars['BigInt']['output']>;
  newsarticleSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  nonReciprocateHreflangOutCount?: Maybe<Scalars['BigInt']['output']>;
  pageTitleLength?: Maybe<Scalars['BigInt']['output']>;
  pageTitleLengthPx?: Maybe<Scalars['BigInt']['output']>;
  paginatedLinksInCount?: Maybe<Scalars['BigInt']['output']>;
  paramCount?: Maybe<Scalars['BigInt']['output']>;
  passedAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  performanceCls?: Maybe<Scalars['Float']['output']>;
  performanceDcl?: Maybe<Scalars['Float']['output']>;
  performanceFcp?: Maybe<Scalars['Float']['output']>;
  performanceLcp?: Maybe<Scalars['Float']['output']>;
  performanceTtfb?: Maybe<Scalars['Float']['output']>;
  performanceTti?: Maybe<Scalars['Float']['output']>;
  productOfSavingsKibAndPageviews?: Maybe<Scalars['Float']['output']>;
  productOfSavingsSecsAndPageviews?: Maybe<Scalars['Float']['output']>;
  productPriceSchemaValue?: Maybe<Scalars['Float']['output']>;
  productRatingSchemaValue?: Maybe<Scalars['Float']['output']>;
  productSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  qapageSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  recipeSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  reciprocateHreflangOutCount?: Maybe<Scalars['BigInt']['output']>;
  redirectCount?: Maybe<Scalars['BigInt']['output']>;
  redirectsInCount?: Maybe<Scalars['BigInt']['output']>;
  relLinksInCount?: Maybe<Scalars['BigInt']['output']>;
  renderedLinkCountDifference?: Maybe<Scalars['BigInt']['output']>;
  renderedWordCountDifference?: Maybe<Scalars['BigInt']['output']>;
  resourceLinksInCount?: Maybe<Scalars['BigInt']['output']>;
  reviewSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  savingsKib?: Maybe<Scalars['Float']['output']>;
  savingsScore?: Maybe<Scalars['Float']['output']>;
  savingsSecs?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopClicks?: Maybe<Scalars['BigInt']['output']>;
  searchConsoleDesktopCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleDesktopImpressions?: Maybe<Scalars['BigInt']['output']>;
  searchConsoleDesktopPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileClicks?: Maybe<Scalars['BigInt']['output']>;
  searchConsoleMobileCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleMobileImpressions?: Maybe<Scalars['BigInt']['output']>;
  searchConsoleMobilePosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletClicks?: Maybe<Scalars['BigInt']['output']>;
  searchConsoleTabletCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTabletImpressions?: Maybe<Scalars['BigInt']['output']>;
  searchConsoleTabletPosition?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalClicks?: Maybe<Scalars['BigInt']['output']>;
  searchConsoleTotalCtr?: Maybe<Scalars['Float']['output']>;
  searchConsoleTotalImpressions?: Maybe<Scalars['BigInt']['output']>;
  searchConsoleTotalPosition?: Maybe<Scalars['Float']['output']>;
  sitemapsInCount?: Maybe<Scalars['BigInt']['output']>;
  sitespeedCls?: Maybe<Scalars['Float']['output']>;
  sitespeedClsMainFrame?: Maybe<Scalars['Float']['output']>;
  sitespeedClsScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFcp?: Maybe<Scalars['Float']['output']>;
  sitespeedFcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedFmp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcp?: Maybe<Scalars['Float']['output']>;
  sitespeedLcpScore?: Maybe<Scalars['Float']['output']>;
  sitespeedMaxPotentialFid?: Maybe<Scalars['Float']['output']>;
  sitespeedScore?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndex?: Maybe<Scalars['Float']['output']>;
  sitespeedSpeedIndexScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTbt?: Maybe<Scalars['Float']['output']>;
  sitespeedTbtScore?: Maybe<Scalars['Float']['output']>;
  sitespeedTtfb?: Maybe<Scalars['Float']['output']>;
  sitespeedTti?: Maybe<Scalars['Float']['output']>;
  speedIndex?: Maybe<Scalars['BigInt']['output']>;
  speedIndexScore?: Maybe<Scalars['Float']['output']>;
  timeToFirstByte?: Maybe<Scalars['BigInt']['output']>;
  totalBlockingTime?: Maybe<Scalars['BigInt']['output']>;
  totalBlockingTimeScore?: Maybe<Scalars['Float']['output']>;
  uniqueLinksInCount?: Maybe<Scalars['BigInt']['output']>;
  videoobjectSchemaCount?: Maybe<Scalars['BigInt']['output']>;
  warningAuditsCount?: Maybe<Scalars['BigInt']['output']>;
  wordCount?: Maybe<Scalars['BigInt']['output']>;
};

export type CrawlWebCrawlDepth = {
  duplicatePages?: Maybe<Scalars['Int']['output']>;
  failedUrls?: Maybe<Scalars['Int']['output']>;
  level: Scalars['Int']['output'];
  non200StatusCodePages?: Maybe<Scalars['Int']['output']>;
  nonIndexablePages?: Maybe<Scalars['Int']['output']>;
  paginatedPages?: Maybe<Scalars['Int']['output']>;
  uniquePages?: Maybe<Scalars['Int']['output']>;
};

export type CrawlWebCrawlDepthConnection = {
  /** A list of edges. */
  edges: Array<CrawlWebCrawlDepthEdge>;
  /** A list of nodes. */
  nodes: Array<CrawlWebCrawlDepth>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CrawlWebCrawlDepths in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CrawlWebCrawlDepthConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CrawlWebCrawlDepthConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CrawlWebCrawlDepthConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CrawlWebCrawlDepthConnectionFilterInput>>;
  duplicatePages?: InputMaybe<ConnectionIntFilterInput>;
  failedUrls?: InputMaybe<ConnectionIntFilterInput>;
  level?: InputMaybe<ConnectionIntFilterInput>;
  non200StatusCodePages?: InputMaybe<ConnectionIntFilterInput>;
  nonIndexablePages?: InputMaybe<ConnectionIntFilterInput>;
  paginatedPages?: InputMaybe<ConnectionIntFilterInput>;
  uniquePages?: InputMaybe<ConnectionIntFilterInput>;
};

export type CrawlWebCrawlDepthEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CrawlWebCrawlDepth;
};

export type CrawlWebCrawlDepthOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CrawlWebCrawlDepths by. */
  field: CrawlWebCrawlDepthOrderField;
};

export enum CrawlWebCrawlDepthOrderField {
  DuplicatePages = 'duplicatePages',
  FailedUrls = 'failedUrls',
  Level = 'level',
  Non200StatusCodePages = 'non200StatusCodePages',
  NonIndexablePages = 'nonIndexablePages',
  PaginatedPages = 'paginatedPages',
  UniquePages = 'uniquePages'
}

export enum CrawlerType {
  Legacy = 'Legacy',
  SerpentBasic = 'SerpentBasic',
  SerpentMapped = 'SerpentMapped',
  SerpentModular = 'SerpentModular'
}

export type CreateAccessibilityIssueSolutionSuggestionInput = {
  crawlId: Scalars['ObjectID']['input'];
  issueDigest: Scalars['String']['input'];
  model?: Scalars['String']['input'];
};

export type CreateAccessibilityIssueSolutionSuggestionPayload = {
  accessibilityIssueSolutionSuggestion: AccessibilityIssueSolutionSuggestion;
};

export type CreateAccessibilityProjectInput = {
  accountId: Scalars['ObjectID']['input'];
  alertEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  alertSettingCode?: AlertSettingCode;
  apiCallbackHeaders?: Array<ApiCallbackHeaderInput>;
  apiCallbackUrl?: InputMaybe<Scalars['String']['input']>;
  applyCustomHeadersToNavigationRequestsOnly?: Scalars['Boolean']['input'];
  autoFinalizeOnCrawlLimits?: Scalars['Boolean']['input'];
  block3rdPartyCookies?: Scalars['Boolean']['input'];
  compareToCrawl?: CompareToCrawlType;
  crawlDisallowedUrls1stLevel?: Scalars['Boolean']['input'];
  crawlHyperlinksExternal?: Scalars['Boolean']['input'];
  crawlHyperlinksInternal?: Scalars['Boolean']['input'];
  crawlImagesExternal?: Scalars['Boolean']['input'];
  crawlImagesInternal?: Scalars['Boolean']['input'];
  crawlNofollowHyperlinks?: Scalars['Boolean']['input'];
  crawlNonHtml?: Scalars['Boolean']['input'];
  crawlNotIncluded1stLevel?: Scalars['Boolean']['input'];
  crawlRedirectsExternal?: Scalars['Boolean']['input'];
  crawlRedirectsInternal?: Scalars['Boolean']['input'];
  crawlRelAmphtmlExternal?: Scalars['Boolean']['input'];
  crawlRelAmphtmlInternal?: Scalars['Boolean']['input'];
  crawlRelCanonicalsExternal?: Scalars['Boolean']['input'];
  crawlRelCanonicalsInternal?: Scalars['Boolean']['input'];
  crawlRelHreflangsExternal?: Scalars['Boolean']['input'];
  crawlRelHreflangsInternal?: Scalars['Boolean']['input'];
  crawlRelMobileExternal?: Scalars['Boolean']['input'];
  crawlRelMobileInternal?: Scalars['Boolean']['input'];
  crawlRelNextPrevExternal?: Scalars['Boolean']['input'];
  crawlRelNextPrevInternal?: Scalars['Boolean']['input'];
  crawlRobotsTxtNoindex?: Scalars['Boolean']['input'];
  crawlScriptsExternal?: Scalars['Boolean']['input'];
  crawlScriptsInternal?: Scalars['Boolean']['input'];
  crawlStylesheetsExternal?: Scalars['Boolean']['input'];
  crawlStylesheetsInternal?: Scalars['Boolean']['input'];
  crawlTestSite?: Scalars['Boolean']['input'];
  crawlTypes?: Array<CrawlType>;
  customDns?: Array<CustomDnsSettingInput>;
  customExtractions?: Array<CustomExtractionSettingInput>;
  customRequestHeaders?: Array<CustomRequestHeaderInput>;
  dataLayerName?: InputMaybe<Scalars['String']['input']>;
  dataOnlyCrawlTypes?: InputMaybe<Array<CrawlType>>;
  discoverSitemapsInRobotsTxt?: Scalars['Boolean']['input'];
  duplicatePrecision?: Scalars['Float']['input'];
  emptyPageThreshold?: Scalars['Int']['input'];
  enableKeyValueStore?: Scalars['Boolean']['input'];
  excludeUrlPatterns?: Array<Scalars['String']['input']>;
  flattenIframes?: Scalars['Boolean']['input'];
  flattenShadowDom?: Scalars['Boolean']['input'];
  gaDateRange?: Scalars['Int']['input'];
  ignoreInvalidSSLCertificate?: Scalars['Boolean']['input'];
  ignoreRobotsForNavigationRequests?: Scalars['Boolean']['input'];
  includeBestPractices?: Scalars['Boolean']['input'];
  includeHttpAndHttps?: Scalars['Boolean']['input'];
  includeSubdomains?: Scalars['Boolean']['input'];
  includeUrlPatterns?: Array<Scalars['String']['input']>;
  industryCode?: InputMaybe<Scalars['String']['input']>;
  limitLevelsMax?: InputMaybe<Scalars['Int']['input']>;
  limitUrlsMax?: InputMaybe<Scalars['Int']['input']>;
  locationCode?: LocationCode;
  logSummaryRequestsHigh?: Scalars['Int']['input'];
  logSummaryRequestsLow?: Scalars['Int']['input'];
  maxBodyContentLength?: Scalars['Int']['input'];
  maxDescriptionLength?: Scalars['Int']['input'];
  maxFollowedExternalLinks?: Scalars['Int']['input'];
  maxHtmlSize?: Scalars['Int']['input'];
  maxLinks?: Scalars['Int']['input'];
  maxLoadTime?: Scalars['Float']['input'];
  maxRedirections?: Scalars['Int']['input'];
  maxTitleWidth?: Scalars['Int']['input'];
  maxUrlLength?: Scalars['Int']['input'];
  maximumCrawlRate?: Scalars['Float']['input'];
  maximumCrawlRateAdvanced?: Array<AdvancedCrawlRateInput>;
  minDescriptionLength?: Scalars['Int']['input'];
  minTitleLength?: Scalars['Int']['input'];
  minVisits?: Scalars['Int']['input'];
  mobileHomepageUrl?: InputMaybe<Scalars['String']['input']>;
  mobileUrlPattern?: InputMaybe<Scalars['String']['input']>;
  mobileUserAgentCode?: Scalars['String']['input'];
  mobileViewportHeight?: InputMaybe<Scalars['Int']['input']>;
  mobileViewportWidth?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  primaryDomain: Scalars['String']['input'];
  renderTimeout?: InputMaybe<Scalars['Int']['input']>;
  renderWithImages?: Scalars['Boolean']['input'];
  rendererBlockAds?: Scalars['Boolean']['input'];
  rendererBlockAnalytics?: Scalars['Boolean']['input'];
  rendererBlockCustom?: Array<Scalars['String']['input']>;
  rendererCookies?: Array<RendererCookieInput>;
  rendererJsString?: InputMaybe<Scalars['String']['input']>;
  rendererJsUrls?: Array<Scalars['String']['input']>;
  renderingRobotsCheckMode?: RenderingRobotsCheckMode;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  secondaryDomains?: Array<Scalars['String']['input']>;
  selectedWcagLevel?: WcagLevel;
  selectedWcagVersion?: Scalars['Float']['input'];
  startUrls?: Array<Scalars['String']['input']>;
  storeHtml?: Scalars['Boolean']['input'];
  targetMaxUncrawledUrlsCount?: Scalars['Int']['input'];
  testSiteDomain?: InputMaybe<Scalars['String']['input']>;
  testSitePassword?: InputMaybe<Scalars['String']['input']>;
  testSiteUsername?: InputMaybe<Scalars['String']['input']>;
  thinPageThreshold?: Scalars['Int']['input'];
  urlRewriteQueryParameters?: Array<Scalars['String']['input']>;
  urlRewriteRules?: Array<UrlRewriteRuleInput>;
  urlRewriteStripFragment?: Scalars['Boolean']['input'];
  urlSampling?: Array<UrlSamplingInput>;
  useMobileSettings?: Scalars['Boolean']['input'];
  useNewHeadlessMode?: Scalars['Boolean']['input'];
  useRenderer?: InputMaybe<Scalars['Boolean']['input']>;
  useRobotsOverwrite?: Scalars['Boolean']['input'];
  useStealthMode?: Scalars['Boolean']['input'];
  useStealthModeV2?: Scalars['Boolean']['input'];
  useUrlRewriteRules?: Scalars['Boolean']['input'];
  userAgentCode?: Scalars['String']['input'];
  userAgentIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentMobileIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentString?: InputMaybe<Scalars['String']['input']>;
  userAgentStringMobile?: InputMaybe<Scalars['String']['input']>;
  userAgentToken?: InputMaybe<Scalars['String']['input']>;
  userAgentTokenMobile?: InputMaybe<Scalars['String']['input']>;
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAccessibilityTestSuiteInput = {
  accountId: Scalars['ObjectID']['input'];
  alertEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  applyCustomHeadersToNavigationRequestsOnly?: Scalars['Boolean']['input'];
  automaticThreshold?: Scalars['Boolean']['input'];
  automaticThresholdEnabledForAll?: Scalars['Boolean']['input'];
  block3rdPartyCookies?: Scalars['Boolean']['input'];
  crawlImagesInternal?: Scalars['Boolean']['input'];
  crawlRate?: Scalars['Float']['input'];
  crawlScriptsExternal?: Scalars['Boolean']['input'];
  crawlScriptsInternal?: Scalars['Boolean']['input'];
  crawlStylesheetsExternal?: Scalars['Boolean']['input'];
  crawlStylesheetsInternal?: Scalars['Boolean']['input'];
  crawlTypes?: Array<TestSuiteCrawlType>;
  customDns?: InputMaybe<Array<CustomDnsSettingInput>>;
  customExtractions?: InputMaybe<Array<CustomExtractionSettingInput>>;
  customHeaderUserAgent?: InputMaybe<Scalars['String']['input']>;
  customHeaderUserAgentShort?: InputMaybe<Scalars['String']['input']>;
  duplicatePrecision?: Scalars['Int']['input'];
  emptyPageThreshold?: Scalars['Int']['input'];
  flattenIframes?: Scalars['Boolean']['input'];
  flattenShadowDom?: Scalars['Boolean']['input'];
  highLogSummaryRequests?: Scalars['Int']['input'];
  ignoreRobotsForNavigationRequests?: Scalars['Boolean']['input'];
  includeBestPractices?: Scalars['Boolean']['input'];
  limitPagesMax?: Scalars['Int']['input'];
  location?: TestSuiteLocationCode;
  lowLogSummaryRequests?: Scalars['Int']['input'];
  maxContentSize?: Scalars['Int']['input'];
  maxDescriptionLength?: Scalars['Int']['input'];
  maxExternalLinks?: Scalars['Int']['input'];
  maxHtmlSize?: Scalars['Int']['input'];
  maxLinks?: Scalars['Int']['input'];
  maxLoadTime?: Scalars['Float']['input'];
  maxRedirections?: Scalars['Int']['input'];
  maxTitleWidth?: Scalars['Int']['input'];
  maxUrlLength?: Scalars['Int']['input'];
  minContentRatio?: Scalars['Float']['input'];
  minDescriptionLength?: Scalars['Int']['input'];
  minTitleLength?: Scalars['Int']['input'];
  mobileUserAgentCode?: InputMaybe<Scalars['String']['input']>;
  mobileViewportHeight?: InputMaybe<Scalars['Int']['input']>;
  mobileViewportWidth?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  rendererBlockAds?: Scalars['Boolean']['input'];
  rendererBlockAnalytics?: Scalars['Boolean']['input'];
  rendererBlockCustom?: InputMaybe<Array<Scalars['String']['input']>>;
  rendererCookies?: Array<RendererCookieInput>;
  rendererJsString?: InputMaybe<Scalars['String']['input']>;
  rendererJsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  renderingRobotsCheckMode?: RenderingRobotsCheckMode;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  selectedWcagLevel?: WcagLevel;
  selectedWcagVersion?: Scalars['Float']['input'];
  sitePrimary: Scalars['String']['input'];
  siteTest?: InputMaybe<Scalars['String']['input']>;
  siteTestPass?: InputMaybe<Scalars['String']['input']>;
  siteTestUser?: InputMaybe<Scalars['String']['input']>;
  startUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  thinPageThreshold?: Scalars['Int']['input'];
  urlsExcluded?: InputMaybe<Array<Scalars['String']['input']>>;
  urlsIncluded?: InputMaybe<Array<Scalars['String']['input']>>;
  useNewHeadlessMode?: Scalars['Boolean']['input'];
  useRenderer?: InputMaybe<Scalars['Boolean']['input']>;
  useRobotsOverwrite?: Scalars['Boolean']['input'];
  userAgentCode?: InputMaybe<Scalars['String']['input']>;
  userAgentIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentMobileIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentString?: InputMaybe<Scalars['String']['input']>;
  userAgentStringMobile?: InputMaybe<Scalars['String']['input']>;
  userAgentToken?: InputMaybe<Scalars['String']['input']>;
  userAgentTokenMobile?: InputMaybe<Scalars['String']['input']>;
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateAdobeAnalyticsProjectReportSuiteInput = {
  adobeConnectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  adobeJwtConnectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  maxRows?: InputMaybe<Scalars['Int']['input']>;
  minimumVisits?: InputMaybe<Scalars['Int']['input']>;
  numberOfDays?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ObjectID']['input'];
  reportSuiteId: Scalars['String']['input'];
  suiteName: Scalars['String']['input'];
};

export type CreateAdobeAnalyticsProjectReportSuitePayload = {
  adobeAnalyticsProjectReportSuite: AdobeAnalyticsProjectReportSuite;
};

export type CreateAdobeConnectionInput = {
  apiUsername: Scalars['String']['input'];
  secret: Scalars['String']['input'];
};

export type CreateAdobeConnectionPayload = {
  adobeConnection: AdobeConnection;
};

export type CreateAdobeJwtConnectionInput = {
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  privateKey: Scalars['String']['input'];
  technicalAccountId: Scalars['String']['input'];
};

export type CreateAdobeJwtConnectionPayload = {
  adobeJwtConnection: AdobeJwtConnection;
};

export type CreateAndRunBuildInput = {
  ciBuildId?: InputMaybe<Scalars['String']['input']>;
  testSuiteId: Scalars['ObjectID']['input'];
};

export type CreateAndRunBuildPayload = {
  build: Build;
};

export type CreateBuildScheduleInput = {
  accountId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
  repetitionRate?: InputMaybe<BuildScheduleRepetitionRate>;
  startAt: Scalars['DateTime']['input'];
};

export type CreateBuildSchedulePayload = {
  buildSchedule: BuildSchedule;
};

export type CreateCrawlSegmentInput = {
  crawlId: Scalars['ObjectID']['input'];
  segmentId: Scalars['ObjectID']['input'];
  segmentVersion: Scalars['Int']['input'];
};

export type CreateCrawlSegmentPayload = {
  crawlSegment: CrawlSegment;
};

export type CreateCrawlUrlSegmentInput = {
  crawlUrlFilter: CrawlUrlConnectionFilterInput;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['ObjectID']['input'];
};

export type CreateCrawlUrlSegmentPayload = {
  segment: Segment;
};

export type CreateCreditAllocationForSsoAccountInput = {
  accountId: Scalars['ObjectID']['input'];
  creditsAllocated: Scalars['Int']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  type?: InputMaybe<CreditAllocationType>;
};

export type CreateCreditAllocationForSsoAccountPayload = {
  creditAllocation: CreditAllocation;
};

export type CreateCreditReportExportInput = {
  accountId: Scalars['ObjectID']['input'];
  filter?: InputMaybe<CreditReportConnectionFilterInput>;
};

export type CreateCreditReportExportPayload = {
  creditReportExport: CreditReportExport;
};

export type CreateCustomChartForCustomDashboardInput = {
  customViewIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  metric: Scalars['String']['input'];
  type: CustomChartType;
};

export type CreateCustomChartInput = {
  customDashboardId: Scalars['ObjectID']['input'];
  customViewIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  metric: Scalars['String']['input'];
  type: CustomChartType;
};

export type CreateCustomChartPayload = {
  customChart: CustomChart;
};

export type CreateCustomDashboardCollectionInput = {
  accountId: Scalars['ObjectID']['input'];
  customDashboardIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  customViewIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  initCustomDashboards?: InputMaybe<Array<CustomDashboardType>>;
  name: Scalars['String']['input'];
};

export type CreateCustomDashboardCollectionPayload = {
  customDashboardCollection: CustomDashboardCollection;
};

export type CreateCustomDashboardInput = {
  accountId: Scalars['ObjectID']['input'];
  customCharts?: InputMaybe<Array<CreateCustomChartForCustomDashboardInput>>;
  customDashboardCollectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  customTables?: InputMaybe<Array<CreateCustomTableForCustomDashboardInput>>;
  customViewIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  name: Scalars['String']['input'];
  type: CustomDashboardType;
};

export type CreateCustomDashboardPayload = {
  customDashboard: CustomDashboard;
};

export type CreateCustomMetricContainerInput = {
  /** The id of the account that the custom metric container belongs to. */
  accountId: Scalars['ObjectID']['input'];
  costs?: InputMaybe<Array<CustomMetricContainerCostInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  inputType?: CustomMetricContainerInputType;
  isEssential?: InputMaybe<Scalars['Boolean']['input']>;
  isolate?: InputMaybe<Scalars['Boolean']['input']>;
  linksProducer?: InputMaybe<Scalars['Boolean']['input']>;
  linksProducers?: InputMaybe<Scalars['JSONObject']['input']>;
  minRequiredCpu?: InputMaybe<Scalars['Int']['input']>;
  /** Unique name for the custom metric container. */
  name: Scalars['String']['input'];
  obeyCSP?: InputMaybe<Scalars['Boolean']['input']>;
  outputType?: CustomMetricContainerOutputType;
  requiresResponseBuffer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Which resource types the container extracts metrics from. */
  resourceTypes?: InputMaybe<Array<CustomMetricContainerResourceType>>;
  runFirst?: InputMaybe<Scalars['Boolean']['input']>;
  scope?: CustomMetricContainerScope;
  tableType?: InputMaybe<CustomMetricContainerTableType>;
  tableTypes?: InputMaybe<Scalars['JSONObject']['input']>;
  timeoutMs?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateCustomMetricContainerPayload = {
  customMetricContainer: CustomMetricContainer;
};

export type CreateCustomMetricContainerVersionInput = {
  allowedRenderingResources?: InputMaybe<Array<CustomMetricContainerRenderingResource>>;
  customMetricContainerId: Scalars['ObjectID']['input'];
  navigationTimeoutMs?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateCustomMetricContainerVersionPayload = {
  customMetricContainerVersion: CustomMetricContainerVersion;
};

export type CreateCustomMetricUploadContainerInput = {
  /** The id of the account that the custom metric upload container belongs to. */
  accountId: Scalars['ObjectID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  /** Unique name for the custom metric upload container. */
  name: Scalars['String']['input'];
  uploadTemplate: Scalars['JSONObject']['input'];
};

export type CreateCustomMetricUploadContainerPayload = {
  customMetricUploadContainer: CustomMetricUploadContainer;
};

export type CreateCustomReportTemplateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filter: Scalars['JSONObject']['input'];
  metricsGroupings?: InputMaybe<Array<CustomReportTemplateMetricsGroupingInput>>;
  name: Scalars['String']['input'];
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  projectId: Scalars['ObjectID']['input'];
  reportTemplateCode?: Scalars['String']['input'];
  /** Inherited from the ReportTemplate if not provided. */
  reportTypes?: InputMaybe<Array<ReportTypeCode>>;
  taskId?: InputMaybe<Scalars['ObjectID']['input']>;
  /** Inherited from the ReportTemplate if not provided. */
  totalSign?: InputMaybe<Scalars['Int']['input']>;
  /** Inherited from the ReportTemplate if not provided. */
  totalWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateCustomReportTemplatePayload = {
  customReportTemplate: CustomReportTemplate;
};

export type CreateCustomTableForCustomDashboardInput = {
  columns: Array<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  type: CustomTableType;
};

export type CreateCustomTableInput = {
  columns: Array<Scalars['String']['input']>;
  customDashboardId: Scalars['ObjectID']['input'];
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  type: CustomTableType;
};

export type CreateCustomTablePayload = {
  customTable: CustomTable;
};

export type CreateCustomViewsInput = {
  customViews?: Array<CustomViewInput>;
};

export type CreateCustomViewsPayload = {
  customViews: Array<CustomView>;
};

export type CreateFeatureCostInput = {
  costs: Array<FeatureCostCostInput>;
  featureCode: Scalars['String']['input'];
  projectSetting: Scalars['String']['input'];
};

export type CreateGoogleAnalytics4ProjectPropertyInput = {
  googleConnectionId: Scalars['ObjectID']['input'];
  inheritFromSchedulePeriodOverride?: Scalars['Boolean']['input'];
  lastNDays?: Scalars['Int']['input'];
  minimumSessions?: Scalars['Int']['input'];
  minimumViews?: Scalars['Int']['input'];
  projectId: Scalars['ObjectID']['input'];
  propertyName: Scalars['String']['input'];
  source?: GoogleAnalytics4SourceType;
};

export type CreateGoogleAnalytics4ProjectPropertyPayload = {
  googleAnalytics4ProjectProperty: GoogleAnalytics4ProjectProperty;
};

export type CreateGoogleConnectionInput = {
  redirectUrl: Scalars['String']['input'];
};

export type CreateGoogleConnectionPayload = {
  /** URL to consent page landing. */
  authUrl: Scalars['String']['output'];
};

export type CreateGoogleSearchConsoleConfigurationInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  excludeQueries?: InputMaybe<Array<Scalars['String']['input']>>;
  includeQueries?: InputMaybe<Array<Scalars['String']['input']>>;
  inheritFromSchedulePeriodOverride?: Scalars['Boolean']['input'];
  lastNDays?: Scalars['Int']['input'];
  minClicks?: Scalars['Int']['input'];
  projectId: Scalars['ObjectID']['input'];
  searchType?: GoogleSearchConsoleSearchType;
  useSearchConsolePropertyDomainsAsStartUrls?: Scalars['Boolean']['input'];
};

export type CreateGoogleSearchConsoleConfigurationPayload = {
  googleSearchConsoleConfiguration: GoogleSearchConsoleConfiguration;
};

export type CreateGoogleSearchConsolePropertyInput = {
  googleConnectionId: Scalars['ObjectID']['input'];
  permissionLevel?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ObjectID']['input'];
  siteUrl: Scalars['String']['input'];
};

export type CreateGoogleSearchConsolePropertyPayload = {
  googleSearchConsoleProperty: GoogleSearchConsoleProperty;
};

export type CreateJiraAuthenticationInput = {
  accountId: Scalars['ObjectID']['input'];
  cloudId: Scalars['String']['input'];
  jiraUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};

export type CreateJiraAuthenticationPayload = {
  jiraAuthentication: JiraAuthentication;
};

export type CreateJiraIntegrationInput = {
  jiraAuthenticationId: Scalars['ObjectID']['input'];
  jiraIssueTypeId: Scalars['String']['input'];
  jiraProjectId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateJiraIntegrationPayload = {
  jiraIntegration: JiraIntegration;
};

export type CreateLegacyTaskPayload = {
  legacyTask: LegacyTask;
};

export type CreateLogzioConnectionInput = {
  label: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CreateLogzioConnectionPayload = {
  logzioConnection: LogzioConnection;
};

export type CreateLogzioProjectQueryInput = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  dateRange?: Scalars['Int']['input'];
  desktopUaNotRegexp?: InputMaybe<Scalars['String']['input']>;
  desktopUaRegexp?: Scalars['String']['input'];
  enabled?: Scalars['Boolean']['input'];
  logzioConnectionId: Scalars['ObjectID']['input'];
  maxRows?: Scalars['Int']['input'];
  mobileUaNotRegexp?: InputMaybe<Scalars['String']['input']>;
  mobileUaRegexp?: Scalars['String']['input'];
  pathFieldName?: Scalars['String']['input'];
  projectId: Scalars['ObjectID']['input'];
  queryFilter?: InputMaybe<Scalars['JSONObject']['input']>;
  useLastCrawlDate?: Scalars['Boolean']['input'];
  userAgentFieldName?: Scalars['String']['input'];
};

export type CreateLogzioProjectQueryPayload = {
  logzioProjectQuery: LogzioProjectQuery;
};

export type CreateMajesticConfigurationInput = {
  enabled?: Scalars['Boolean']['input'];
  maxRows: Scalars['Int']['input'];
  projectId: Scalars['ObjectID']['input'];
  useHistoricData?: Scalars['Boolean']['input'];
  useRootDomain?: Scalars['Boolean']['input'];
};

export type CreateMajesticConfigurationPayload = {
  majesticConfiguration: MajesticConfiguration;
};

export type CreateProjectInput = {
  accountId: Scalars['ObjectID']['input'];
  alertEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  alertSettingCode?: AlertSettingCode;
  apiCallbackHeaders?: Array<ApiCallbackHeaderInput>;
  apiCallbackUrl?: InputMaybe<Scalars['String']['input']>;
  applyCustomHeadersToNavigationRequestsOnly?: Scalars['Boolean']['input'];
  autoFinalizeOnCrawlLimits?: Scalars['Boolean']['input'];
  block3rdPartyCookies?: Scalars['Boolean']['input'];
  compareToCrawl?: CompareToCrawlType;
  crawlDisallowedUrls1stLevel?: Scalars['Boolean']['input'];
  crawlHyperlinksExternal?: Scalars['Boolean']['input'];
  crawlHyperlinksInternal?: Scalars['Boolean']['input'];
  crawlImagesExternal?: Scalars['Boolean']['input'];
  crawlImagesInternal?: Scalars['Boolean']['input'];
  crawlNofollowHyperlinks?: Scalars['Boolean']['input'];
  crawlNonHtml?: Scalars['Boolean']['input'];
  crawlNotIncluded1stLevel?: Scalars['Boolean']['input'];
  crawlRedirectsExternal?: Scalars['Boolean']['input'];
  crawlRedirectsInternal?: Scalars['Boolean']['input'];
  crawlRelAmphtmlExternal?: Scalars['Boolean']['input'];
  crawlRelAmphtmlInternal?: Scalars['Boolean']['input'];
  crawlRelCanonicalsExternal?: Scalars['Boolean']['input'];
  crawlRelCanonicalsInternal?: Scalars['Boolean']['input'];
  crawlRelHreflangsExternal?: Scalars['Boolean']['input'];
  crawlRelHreflangsInternal?: Scalars['Boolean']['input'];
  crawlRelMobileExternal?: Scalars['Boolean']['input'];
  crawlRelMobileInternal?: Scalars['Boolean']['input'];
  crawlRelNextPrevExternal?: Scalars['Boolean']['input'];
  crawlRelNextPrevInternal?: Scalars['Boolean']['input'];
  crawlRobotsTxtNoindex?: Scalars['Boolean']['input'];
  crawlScriptsExternal?: Scalars['Boolean']['input'];
  crawlScriptsInternal?: Scalars['Boolean']['input'];
  crawlStylesheetsExternal?: Scalars['Boolean']['input'];
  crawlStylesheetsInternal?: Scalars['Boolean']['input'];
  crawlTestSite?: Scalars['Boolean']['input'];
  crawlTypes?: Array<CrawlType>;
  customDns?: Array<CustomDnsSettingInput>;
  customExtractions?: Array<CustomExtractionSettingInput>;
  customRequestHeaders?: Array<CustomRequestHeaderInput>;
  dataLayerName?: InputMaybe<Scalars['String']['input']>;
  dataOnlyCrawlTypes?: InputMaybe<Array<CrawlType>>;
  discoverSitemapsInRobotsTxt?: Scalars['Boolean']['input'];
  duplicatePrecision?: Scalars['Float']['input'];
  emptyPageThreshold?: Scalars['Int']['input'];
  enableKeyValueStore?: Scalars['Boolean']['input'];
  excludeUrlPatterns?: Array<Scalars['String']['input']>;
  flattenIframes?: Scalars['Boolean']['input'];
  flattenShadowDom?: Scalars['Boolean']['input'];
  gaDateRange?: Scalars['Int']['input'];
  ignoreInvalidSSLCertificate?: Scalars['Boolean']['input'];
  ignoreRobotsForNavigationRequests?: Scalars['Boolean']['input'];
  includeBestPractices?: Scalars['Boolean']['input'];
  includeHttpAndHttps?: Scalars['Boolean']['input'];
  includeSubdomains?: Scalars['Boolean']['input'];
  includeUrlPatterns?: Array<Scalars['String']['input']>;
  industryCode?: InputMaybe<Scalars['String']['input']>;
  limitLevelsMax?: InputMaybe<Scalars['Int']['input']>;
  limitUrlsMax?: InputMaybe<Scalars['Int']['input']>;
  locationCode?: LocationCode;
  logSummaryRequestsHigh?: Scalars['Int']['input'];
  logSummaryRequestsLow?: Scalars['Int']['input'];
  maxBodyContentLength?: Scalars['Int']['input'];
  maxDescriptionLength?: Scalars['Int']['input'];
  maxFollowedExternalLinks?: Scalars['Int']['input'];
  maxHtmlSize?: Scalars['Int']['input'];
  maxLinks?: Scalars['Int']['input'];
  maxLoadTime?: Scalars['Float']['input'];
  maxRedirections?: Scalars['Int']['input'];
  maxTitleWidth?: Scalars['Int']['input'];
  maxUrlLength?: Scalars['Int']['input'];
  maximumCrawlRate?: Scalars['Float']['input'];
  maximumCrawlRateAdvanced?: Array<AdvancedCrawlRateInput>;
  minDescriptionLength?: Scalars['Int']['input'];
  minTitleLength?: Scalars['Int']['input'];
  minVisits?: Scalars['Int']['input'];
  mobileHomepageUrl?: InputMaybe<Scalars['String']['input']>;
  mobileUrlPattern?: InputMaybe<Scalars['String']['input']>;
  mobileUserAgentCode?: Scalars['String']['input'];
  mobileViewportHeight?: InputMaybe<Scalars['Int']['input']>;
  mobileViewportWidth?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  primaryDomain: Scalars['String']['input'];
  renderTimeout?: InputMaybe<Scalars['Int']['input']>;
  renderWithImages?: Scalars['Boolean']['input'];
  rendererBlockAds?: Scalars['Boolean']['input'];
  rendererBlockAnalytics?: Scalars['Boolean']['input'];
  rendererBlockCustom?: Array<Scalars['String']['input']>;
  rendererCookies?: Array<RendererCookieInput>;
  rendererJsString?: InputMaybe<Scalars['String']['input']>;
  rendererJsUrls?: Array<Scalars['String']['input']>;
  renderingRobotsCheckMode?: RenderingRobotsCheckMode;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  secondaryDomains?: Array<Scalars['String']['input']>;
  selectedWcagLevel?: WcagLevel;
  selectedWcagVersion?: Scalars['Float']['input'];
  startUrls?: Array<Scalars['String']['input']>;
  storeHtml?: Scalars['Boolean']['input'];
  targetMaxUncrawledUrlsCount?: Scalars['Int']['input'];
  testSiteDomain?: InputMaybe<Scalars['String']['input']>;
  testSitePassword?: InputMaybe<Scalars['String']['input']>;
  testSiteUsername?: InputMaybe<Scalars['String']['input']>;
  thinPageThreshold?: Scalars['Int']['input'];
  urlRewriteQueryParameters?: Array<Scalars['String']['input']>;
  urlRewriteRules?: Array<UrlRewriteRuleInput>;
  urlRewriteStripFragment?: Scalars['Boolean']['input'];
  urlSampling?: Array<UrlSamplingInput>;
  useMobileSettings?: Scalars['Boolean']['input'];
  useNewHeadlessMode?: Scalars['Boolean']['input'];
  useRenderer?: Scalars['Boolean']['input'];
  useRobotsOverwrite?: Scalars['Boolean']['input'];
  useStealthMode?: Scalars['Boolean']['input'];
  useStealthModeV2?: Scalars['Boolean']['input'];
  useUrlRewriteRules?: Scalars['Boolean']['input'];
  userAgentCode?: Scalars['String']['input'];
  userAgentIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentMobileIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentString?: InputMaybe<Scalars['String']['input']>;
  userAgentStringMobile?: InputMaybe<Scalars['String']['input']>;
  userAgentToken?: InputMaybe<Scalars['String']['input']>;
  userAgentTokenMobile?: InputMaybe<Scalars['String']['input']>;
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateProjectLegacyTaskInput = {
  actions?: InputMaybe<Scalars['String']['input']>;
  assignedTo?: InputMaybe<Array<Scalars['String']['input']>>;
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discoveredAt?: InputMaybe<Scalars['DateTime']['input']>;
  dismissed?: Scalars['Boolean']['input'];
  fixedAt?: InputMaybe<Scalars['DateTime']['input']>;
  howToFix?: InputMaybe<Scalars['String']['input']>;
  identified?: InputMaybe<Scalars['Int']['input']>;
  notifyAssignees?: Scalars['Boolean']['input'];
  priority?: LegacyTaskPriority;
  projectId: Scalars['ObjectID']['input'];
  status?: InputMaybe<LegacyTaskStatus>;
  title: Scalars['String']['input'];
};

export type CreateProjectPayload = {
  project: Project;
};

export type CreateReportDownloadInput = {
  crawlAccessibilityIssueFilter?: InputMaybe<CrawlAccessibilityIssueConnectionFilterInput>;
  crawlAccessibilityIssueInstanceFilter?: InputMaybe<CrawlAccessibilityIssueInstanceConnectionFilterInput>;
  crawlDomainFilter?: InputMaybe<CrawlDomainConnectionFilterInput>;
  crawlDuplicateUrlFilter?: InputMaybe<CrawlDuplicateUrlConnectionFilterInput>;
  crawlHreflangsFilter?: InputMaybe<CrawlHreflangsConnectionFilterInput>;
  crawlId?: InputMaybe<Scalars['ObjectID']['input']>;
  crawlLinkFilter?: InputMaybe<CrawlLinkConnectionFilterInput>;
  crawlLinkedDomainFilter?: InputMaybe<CrawlLinkedDomainConnectionFilterInput>;
  crawlSiteSpeedAuditFilter?: InputMaybe<CrawlSiteSpeedAuditConnectionFilterInput>;
  crawlSiteSpeedAuditItemFilter?: InputMaybe<CrawlSiteSpeedAuditItemConnectionFilterInput>;
  crawlSiteSpeedAuditOpportunityFilter?: InputMaybe<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>;
  crawlSitemapFilter?: InputMaybe<CrawlSitemapConnectionFilterInput>;
  crawlUncrawledUrlFilter?: InputMaybe<CrawlUncrawledUrlConnectionFilterInput>;
  crawlUniqueLinkFilter?: InputMaybe<CrawlUniqueLinkConnectionFilterInput>;
  crawlUrlFilter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  crawlWebCrawlDepthFilter?: InputMaybe<CrawlWebCrawlDepthConnectionFilterInput>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  outputType?: ReportDownloadOutputType;
  /** @deprecated Use combination of crawlId, reportTemplateCode and reportTypeCode instead, with optional segmentId. */
  reportId?: InputMaybe<Scalars['ObjectID']['input']>;
  reportTemplateCode?: InputMaybe<Scalars['String']['input']>;
  reportTypeCode?: InputMaybe<ReportTypeCode>;
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
  /** If selectedMetrics are specified, generated download will only include those metrics. */
  selectedMetrics?: InputMaybe<Array<Scalars['String']['input']>>;
  taskId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type CreateReportDownloadPayload = {
  reportDownload: ReportDownload;
};

export type CreateReportLegacyTaskInput = {
  actions?: InputMaybe<Scalars['String']['input']>;
  assignedTo?: InputMaybe<Array<Scalars['String']['input']>>;
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discoveredAt?: InputMaybe<Scalars['DateTime']['input']>;
  dismissed?: Scalars['Boolean']['input'];
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  fixedAt?: InputMaybe<Scalars['DateTime']['input']>;
  howToFix?: InputMaybe<Scalars['String']['input']>;
  identified?: InputMaybe<Scalars['Int']['input']>;
  notifyAssignees?: Scalars['Boolean']['input'];
  priority?: LegacyTaskPriority;
  reportId: Scalars['ObjectID']['input'];
  status?: InputMaybe<LegacyTaskStatus>;
  title: Scalars['String']['input'];
};

export type CreateReportStatLegacyTaskInput = {
  actions?: InputMaybe<Scalars['String']['input']>;
  assignedTo?: InputMaybe<Array<Scalars['String']['input']>>;
  crawlId: Scalars['ObjectID']['input'];
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discoveredAt?: InputMaybe<Scalars['DateTime']['input']>;
  dismissed?: Scalars['Boolean']['input'];
  filters?: InputMaybe<Scalars['JSONObject']['input']>;
  fixedAt?: InputMaybe<Scalars['DateTime']['input']>;
  howToFix?: InputMaybe<Scalars['String']['input']>;
  identified?: InputMaybe<Scalars['Int']['input']>;
  notifyAssignees?: Scalars['Boolean']['input'];
  priority?: LegacyTaskPriority;
  reportTemplateCode: Scalars['String']['input'];
  reportTypeCode: ReportTypeCode;
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
  status?: InputMaybe<LegacyTaskStatus>;
  title: Scalars['String']['input'];
};

export type CreateReportTemplateOverrideInput = {
  projectId: Scalars['ObjectID']['input'];
  reportTemplateCode: Scalars['String']['input'];
  totalSign: Scalars['Int']['input'];
  totalWeight: Scalars['Float']['input'];
};

export type CreateReportTemplateOverridePayload = {
  reportTemplateOverride: ReportTemplateOverride;
};

export type CreateScheduleInput = {
  nextRunTime: Scalars['DateTime']['input'];
  projectId: Scalars['ObjectID']['input'];
  scheduleFrequency?: ScheduleFrequencyCode;
};

export type CreateSchedulePayload = {
  schedule: Schedule;
};

export type CreateSessionUsingUserKeyInputType = {
  name?: InputMaybe<Scalars['String']['input']>;
  secret: Scalars['String']['input'];
  userKeyId: Scalars['ObjectID']['input'];
};

export type CreateSessionUsingUsernameInputType = {
  name?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateSignedUrlFileUploadInput = {
  crawlTypeCode: CrawlType;
  /** Required for the CustomMetricsCsv type, ignored otherwise. */
  customMetricUploadContainerId?: InputMaybe<Scalars['ObjectID']['input']>;
  enabled?: Scalars['Boolean']['input'];
  fileName: Scalars['String']['input'];
  projectId: Scalars['ObjectID']['input'];
  projectUploadType: ProjectUploadType;
  uploadBaseDomain?: InputMaybe<Scalars['String']['input']>;
  uploadTemplate?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CreateSignedUrlFileUploadPayload = {
  signedS3UploadUrl: Scalars['String']['output'];
  urlFileUpload: UrlFileUpload;
};

export type CreateSinglePageRequesterRequestInput = {
  accountId?: InputMaybe<Scalars['ObjectID']['input']>;
  crawlSettings?: InputMaybe<SinglePageRequesterCrawlSettingsInput>;
  /** List of CustomMetricContainers to include in the execution */
  customMetricContainerIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  enableDebugMode?: InputMaybe<Scalars['Boolean']['input']>;
  enableTracing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Module to use as a base when project is not specified. */
  moduleCode?: InputMaybe<ModuleCode>;
  projectId?: InputMaybe<Scalars['ObjectID']['input']>;
  /** If true, metrics won't be extracted. Only the screenshot will be taken. */
  skipContainers?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

export type CreateSinglePageRequesterRequestPayload = {
  singlePageRequesterRequest: SinglePageRequesterRequest;
};

export type CreateSiteSpeedProjectInput = {
  accountId: Scalars['ObjectID']['input'];
  alertEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  alertSettingCode?: AlertSettingCode;
  apiCallbackHeaders?: Array<ApiCallbackHeaderInput>;
  apiCallbackUrl?: InputMaybe<Scalars['String']['input']>;
  applyCustomHeadersToNavigationRequestsOnly?: Scalars['Boolean']['input'];
  autoFinalizeOnCrawlLimits?: Scalars['Boolean']['input'];
  block3rdPartyCookies?: Scalars['Boolean']['input'];
  compareToCrawl?: CompareToCrawlType;
  crawlDisallowedUrls1stLevel?: Scalars['Boolean']['input'];
  crawlHyperlinksExternal?: Scalars['Boolean']['input'];
  crawlHyperlinksInternal?: Scalars['Boolean']['input'];
  crawlImagesExternal?: Scalars['Boolean']['input'];
  crawlImagesInternal?: Scalars['Boolean']['input'];
  crawlNofollowHyperlinks?: Scalars['Boolean']['input'];
  crawlNonHtml?: Scalars['Boolean']['input'];
  crawlNotIncluded1stLevel?: Scalars['Boolean']['input'];
  crawlRedirectsExternal?: Scalars['Boolean']['input'];
  crawlRedirectsInternal?: Scalars['Boolean']['input'];
  crawlRelAmphtmlExternal?: Scalars['Boolean']['input'];
  crawlRelAmphtmlInternal?: Scalars['Boolean']['input'];
  crawlRelCanonicalsExternal?: Scalars['Boolean']['input'];
  crawlRelCanonicalsInternal?: Scalars['Boolean']['input'];
  crawlRelHreflangsExternal?: Scalars['Boolean']['input'];
  crawlRelHreflangsInternal?: Scalars['Boolean']['input'];
  crawlRelMobileExternal?: Scalars['Boolean']['input'];
  crawlRelMobileInternal?: Scalars['Boolean']['input'];
  crawlRelNextPrevExternal?: Scalars['Boolean']['input'];
  crawlRelNextPrevInternal?: Scalars['Boolean']['input'];
  crawlRobotsTxtNoindex?: Scalars['Boolean']['input'];
  crawlScriptsExternal?: Scalars['Boolean']['input'];
  crawlScriptsInternal?: Scalars['Boolean']['input'];
  crawlStylesheetsExternal?: Scalars['Boolean']['input'];
  crawlStylesheetsInternal?: Scalars['Boolean']['input'];
  crawlTestSite?: Scalars['Boolean']['input'];
  crawlTypes?: Array<CrawlType>;
  customDns?: Array<CustomDnsSettingInput>;
  customExtractions?: Array<CustomExtractionSettingInput>;
  customRequestHeaders?: Array<CustomRequestHeaderInput>;
  dataLayerName?: InputMaybe<Scalars['String']['input']>;
  dataOnlyCrawlTypes?: InputMaybe<Array<CrawlType>>;
  discoverSitemapsInRobotsTxt?: Scalars['Boolean']['input'];
  duplicatePrecision?: Scalars['Float']['input'];
  emptyPageThreshold?: Scalars['Int']['input'];
  enableKeyValueStore?: Scalars['Boolean']['input'];
  excludeUrlPatterns?: Array<Scalars['String']['input']>;
  flattenIframes?: Scalars['Boolean']['input'];
  flattenShadowDom?: Scalars['Boolean']['input'];
  gaDateRange?: Scalars['Int']['input'];
  ignoreInvalidSSLCertificate?: Scalars['Boolean']['input'];
  ignoreRobotsForNavigationRequests?: Scalars['Boolean']['input'];
  includeBestPractices?: Scalars['Boolean']['input'];
  includeHttpAndHttps?: Scalars['Boolean']['input'];
  includeSubdomains?: Scalars['Boolean']['input'];
  includeUrlPatterns?: Array<Scalars['String']['input']>;
  industryCode?: InputMaybe<Scalars['String']['input']>;
  limitLevelsMax?: InputMaybe<Scalars['Int']['input']>;
  limitUrlsMax?: InputMaybe<Scalars['Int']['input']>;
  locationCode?: LocationCode;
  logSummaryRequestsHigh?: Scalars['Int']['input'];
  logSummaryRequestsLow?: Scalars['Int']['input'];
  maxBodyContentLength?: Scalars['Int']['input'];
  maxDescriptionLength?: Scalars['Int']['input'];
  maxFollowedExternalLinks?: Scalars['Int']['input'];
  maxHtmlSize?: Scalars['Int']['input'];
  maxLinks?: Scalars['Int']['input'];
  maxLoadTime?: Scalars['Float']['input'];
  maxRedirections?: Scalars['Int']['input'];
  maxTitleWidth?: Scalars['Int']['input'];
  maxUrlLength?: Scalars['Int']['input'];
  maximumCrawlRate?: Scalars['Float']['input'];
  maximumCrawlRateAdvanced?: Array<AdvancedCrawlRateInput>;
  minDescriptionLength?: Scalars['Int']['input'];
  minTitleLength?: Scalars['Int']['input'];
  minVisits?: Scalars['Int']['input'];
  mobileHomepageUrl?: InputMaybe<Scalars['String']['input']>;
  mobileUrlPattern?: InputMaybe<Scalars['String']['input']>;
  mobileUserAgentCode?: Scalars['String']['input'];
  mobileViewportHeight?: InputMaybe<Scalars['Int']['input']>;
  mobileViewportWidth?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  primaryDomain: Scalars['String']['input'];
  renderTimeout?: InputMaybe<Scalars['Int']['input']>;
  renderWithImages?: Scalars['Boolean']['input'];
  rendererBlockAds?: Scalars['Boolean']['input'];
  rendererBlockAnalytics?: Scalars['Boolean']['input'];
  rendererBlockCustom?: Array<Scalars['String']['input']>;
  rendererCookies?: Array<RendererCookieInput>;
  rendererJsString?: InputMaybe<Scalars['String']['input']>;
  rendererJsUrls?: Array<Scalars['String']['input']>;
  renderingRobotsCheckMode?: RenderingRobotsCheckMode;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  secondaryDomains?: Array<Scalars['String']['input']>;
  selectedWcagLevel?: WcagLevel;
  selectedWcagVersion?: Scalars['Float']['input'];
  startUrls?: Array<Scalars['String']['input']>;
  storeHtml?: Scalars['Boolean']['input'];
  targetMaxUncrawledUrlsCount?: Scalars['Int']['input'];
  testSiteDomain?: InputMaybe<Scalars['String']['input']>;
  testSitePassword?: InputMaybe<Scalars['String']['input']>;
  testSiteUsername?: InputMaybe<Scalars['String']['input']>;
  thinPageThreshold?: Scalars['Int']['input'];
  urlRewriteQueryParameters?: Array<Scalars['String']['input']>;
  urlRewriteRules?: Array<UrlRewriteRuleInput>;
  urlRewriteStripFragment?: Scalars['Boolean']['input'];
  urlSampling?: Array<UrlSamplingInput>;
  useMobileSettings?: Scalars['Boolean']['input'];
  useNewHeadlessMode?: Scalars['Boolean']['input'];
  useRenderer?: InputMaybe<Scalars['Boolean']['input']>;
  useRobotsOverwrite?: Scalars['Boolean']['input'];
  useStealthMode?: Scalars['Boolean']['input'];
  useStealthModeV2?: Scalars['Boolean']['input'];
  useUrlRewriteRules?: Scalars['Boolean']['input'];
  userAgentCode?: Scalars['String']['input'];
  userAgentIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentMobileIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentString?: InputMaybe<Scalars['String']['input']>;
  userAgentStringMobile?: InputMaybe<Scalars['String']['input']>;
  userAgentToken?: InputMaybe<Scalars['String']['input']>;
  userAgentTokenMobile?: InputMaybe<Scalars['String']['input']>;
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSiteSpeedTestSuiteInput = {
  accountId: Scalars['ObjectID']['input'];
  alertEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  applyCustomHeadersToNavigationRequestsOnly?: Scalars['Boolean']['input'];
  automaticThreshold?: Scalars['Boolean']['input'];
  automaticThresholdEnabledForAll?: Scalars['Boolean']['input'];
  block3rdPartyCookies?: Scalars['Boolean']['input'];
  crawlImagesInternal?: Scalars['Boolean']['input'];
  crawlRate?: Scalars['Float']['input'];
  crawlScriptsExternal?: Scalars['Boolean']['input'];
  crawlScriptsInternal?: Scalars['Boolean']['input'];
  crawlStylesheetsExternal?: Scalars['Boolean']['input'];
  crawlStylesheetsInternal?: Scalars['Boolean']['input'];
  crawlTypes?: Array<TestSuiteCrawlType>;
  customDns?: InputMaybe<Array<CustomDnsSettingInput>>;
  customExtractions?: InputMaybe<Array<CustomExtractionSettingInput>>;
  customHeaderUserAgent?: InputMaybe<Scalars['String']['input']>;
  customHeaderUserAgentShort?: InputMaybe<Scalars['String']['input']>;
  duplicatePrecision?: Scalars['Int']['input'];
  emptyPageThreshold?: Scalars['Int']['input'];
  flattenIframes?: Scalars['Boolean']['input'];
  flattenShadowDom?: Scalars['Boolean']['input'];
  highLogSummaryRequests?: Scalars['Int']['input'];
  ignoreRobotsForNavigationRequests?: Scalars['Boolean']['input'];
  includeBestPractices?: Scalars['Boolean']['input'];
  limitPagesMax?: Scalars['Int']['input'];
  location?: TestSuiteLocationCode;
  lowLogSummaryRequests?: Scalars['Int']['input'];
  maxContentSize?: Scalars['Int']['input'];
  maxDescriptionLength?: Scalars['Int']['input'];
  maxExternalLinks?: Scalars['Int']['input'];
  maxHtmlSize?: Scalars['Int']['input'];
  maxLinks?: Scalars['Int']['input'];
  maxLoadTime?: Scalars['Float']['input'];
  maxRedirections?: Scalars['Int']['input'];
  maxTitleWidth?: Scalars['Int']['input'];
  maxUrlLength?: Scalars['Int']['input'];
  minContentRatio?: Scalars['Float']['input'];
  minDescriptionLength?: Scalars['Int']['input'];
  minTitleLength?: Scalars['Int']['input'];
  mobileUserAgentCode?: InputMaybe<Scalars['String']['input']>;
  mobileViewportHeight?: InputMaybe<Scalars['Int']['input']>;
  mobileViewportWidth?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  rendererBlockAds?: Scalars['Boolean']['input'];
  rendererBlockAnalytics?: Scalars['Boolean']['input'];
  rendererBlockCustom?: InputMaybe<Array<Scalars['String']['input']>>;
  rendererCookies?: Array<RendererCookieInput>;
  rendererJsString?: InputMaybe<Scalars['String']['input']>;
  rendererJsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  renderingRobotsCheckMode?: RenderingRobotsCheckMode;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  selectedWcagLevel?: WcagLevel;
  selectedWcagVersion?: Scalars['Float']['input'];
  sitePrimary: Scalars['String']['input'];
  siteTest?: InputMaybe<Scalars['String']['input']>;
  siteTestPass?: InputMaybe<Scalars['String']['input']>;
  siteTestUser?: InputMaybe<Scalars['String']['input']>;
  startUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  thinPageThreshold?: Scalars['Int']['input'];
  urlsExcluded?: InputMaybe<Array<Scalars['String']['input']>>;
  urlsIncluded?: InputMaybe<Array<Scalars['String']['input']>>;
  useNewHeadlessMode?: Scalars['Boolean']['input'];
  useRenderer?: InputMaybe<Scalars['Boolean']['input']>;
  useRobotsOverwrite?: Scalars['Boolean']['input'];
  userAgentCode?: InputMaybe<Scalars['String']['input']>;
  userAgentIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentMobileIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentString?: InputMaybe<Scalars['String']['input']>;
  userAgentStringMobile?: InputMaybe<Scalars['String']['input']>;
  userAgentToken?: InputMaybe<Scalars['String']['input']>;
  userAgentTokenMobile?: InputMaybe<Scalars['String']['input']>;
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateSlackWebhookInput = {
  alertTypes?: Array<SlackAlertType>;
  testSuiteId: Scalars['ObjectID']['input'];
  url: Scalars['String']['input'];
};

export type CreateSlackWebhookPayload = {
  slackWebhook: SlackWebhook;
};

export type CreateSplunkConnectionInput = {
  customProxy?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  proxyCode?: InputMaybe<SplunkProxyCode>;
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateSplunkConnectionPayload = {
  splunkConnection: SplunkConnection;
};

export type CreateSplunkProjectQueryInput = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  dateRange?: Scalars['Int']['input'];
  enabled?: Scalars['Boolean']['input'];
  projectId: Scalars['ObjectID']['input'];
  query: Scalars['String']['input'];
  splunkConnectionId: Scalars['ObjectID']['input'];
  useLastCrawlDate?: Scalars['Boolean']['input'];
};

export type CreateSplunkProjectQueryPayload = {
  splunkProjectQuery: SplunkProjectQuery;
};

export type CreateSsoAccountInput = {
  active?: Scalars['Boolean']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  customLogo?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
  ssoClientAccountId: Scalars['Int']['input'];
};

export type CreateSsoAccountPayload = {
  account: Account;
};

export type CreateSsoAccountSubscriptionInput = {
  accountId: Scalars['ObjectID']['input'];
  currency: Currency;
  planCode: Scalars['String']['input'];
  /** Full list of settings to be included with the account subscription plan. */
  planSettings?: InputMaybe<Array<AccountSubscriptionPlanSettingInput>>;
};

export type CreateSsoAccountSubscriptionPayload = {
  accountSubscription: AccountSubscription;
};

export type CreateTestInput = {
  absoluteThreshold?: Scalars['Int']['input'];
  automaticThresholdAcceptance?: InputMaybe<TestAutoThresholdAcceptance>;
  automaticThresholdAcceptanceWhenTestResultIsBetter?: InputMaybe<TestAutoThresholdAcceptance>;
  automaticThresholdAcceptanceWhenTestResultIsWorse?: InputMaybe<TestAutoThresholdAcceptance>;
  automaticThresholdEnabled?: Scalars['Boolean']['input'];
  automaticThresholdWhenTestResultIsBetter?: InputMaybe<Scalars['Boolean']['input']>;
  automaticThresholdWhenTestResultIsWorse?: InputMaybe<Scalars['Boolean']['input']>;
  createJiraTicketOnFailure?: Scalars['Boolean']['input'];
  jiraTicketCustomNote?: InputMaybe<Scalars['String']['input']>;
  relativeThreshold?: InputMaybe<Scalars['Int']['input']>;
  reportTemplateCode: Scalars['String']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
  severity?: InputMaybe<Severity>;
  testSuiteId: Scalars['ObjectID']['input'];
  thresholdPredicate?: ThresholdPredicate;
  thresholdType?: ThresholdType;
};

export type CreateTestPayload = {
  test: Test;
};

export type CreateTestResultInput = {
  absoluteThreshold?: InputMaybe<Scalars['Int']['input']>;
  buildId: Scalars['ObjectID']['input'];
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  relativeThreshold?: InputMaybe<Scalars['Int']['input']>;
  reportPercentage?: InputMaybe<Scalars['Float']['input']>;
  reportTemplateCode: Scalars['String']['input'];
  reportTotalRows?: InputMaybe<Scalars['Int']['input']>;
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
  severity?: Severity;
  status?: TestResultStatus;
  suggestedAbsoluteThreshold?: InputMaybe<Scalars['Int']['input']>;
  thresholdPredicate?: InputMaybe<ThresholdPredicate>;
  thresholdType?: InputMaybe<ThresholdType>;
};

export type CreateTestResultPayload = {
  testResult: TestResult;
};

export type CreateTestSuiteEmailAlertInput = {
  alertTypes?: Array<EmailAlertType>;
  email: Scalars['String']['input'];
  testSuiteId: Scalars['ObjectID']['input'];
};

export type CreateTestSuiteEmailAlertPayload = {
  testSuiteEmailAlert: TestSuiteEmailAlert;
};

export type CreateTestSuiteInput = {
  accountId: Scalars['ObjectID']['input'];
  alertEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  applyCustomHeadersToNavigationRequestsOnly?: Scalars['Boolean']['input'];
  automaticThreshold?: Scalars['Boolean']['input'];
  automaticThresholdEnabledForAll?: Scalars['Boolean']['input'];
  block3rdPartyCookies?: Scalars['Boolean']['input'];
  crawlRate?: Scalars['Float']['input'];
  crawlTypes?: Array<TestSuiteCrawlType>;
  customDns?: InputMaybe<Array<CustomDnsSettingInput>>;
  customExtractions?: InputMaybe<Array<CustomExtractionSettingInput>>;
  customHeaderUserAgent?: InputMaybe<Scalars['String']['input']>;
  customHeaderUserAgentShort?: InputMaybe<Scalars['String']['input']>;
  duplicatePrecision?: Scalars['Int']['input'];
  emptyPageThreshold?: Scalars['Int']['input'];
  flattenIframes?: Scalars['Boolean']['input'];
  flattenShadowDom?: Scalars['Boolean']['input'];
  highLogSummaryRequests?: Scalars['Int']['input'];
  ignoreRobotsForNavigationRequests?: Scalars['Boolean']['input'];
  includeBestPractices?: Scalars['Boolean']['input'];
  limitPagesMax?: Scalars['Int']['input'];
  location?: TestSuiteLocationCode;
  lowLogSummaryRequests?: Scalars['Int']['input'];
  maxContentSize?: Scalars['Int']['input'];
  maxDescriptionLength?: Scalars['Int']['input'];
  maxExternalLinks?: Scalars['Int']['input'];
  maxHtmlSize?: Scalars['Int']['input'];
  maxLinks?: Scalars['Int']['input'];
  maxLoadTime?: Scalars['Float']['input'];
  maxRedirections?: Scalars['Int']['input'];
  maxTitleWidth?: Scalars['Int']['input'];
  maxUrlLength?: Scalars['Int']['input'];
  minContentRatio?: Scalars['Float']['input'];
  minDescriptionLength?: Scalars['Int']['input'];
  minTitleLength?: Scalars['Int']['input'];
  mobileUserAgentCode?: InputMaybe<Scalars['String']['input']>;
  mobileViewportHeight?: InputMaybe<Scalars['Int']['input']>;
  mobileViewportWidth?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  rendererBlockAds?: Scalars['Boolean']['input'];
  rendererBlockAnalytics?: Scalars['Boolean']['input'];
  rendererBlockCustom?: InputMaybe<Array<Scalars['String']['input']>>;
  rendererCookies?: Array<RendererCookieInput>;
  rendererJsString?: InputMaybe<Scalars['String']['input']>;
  rendererJsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  renderingRobotsCheckMode?: RenderingRobotsCheckMode;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  selectedWcagLevel?: WcagLevel;
  selectedWcagVersion?: Scalars['Float']['input'];
  sitePrimary: Scalars['String']['input'];
  siteTest?: InputMaybe<Scalars['String']['input']>;
  siteTestPass?: InputMaybe<Scalars['String']['input']>;
  siteTestUser?: InputMaybe<Scalars['String']['input']>;
  startUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  thinPageThreshold?: Scalars['Int']['input'];
  urlsExcluded?: InputMaybe<Array<Scalars['String']['input']>>;
  urlsIncluded?: InputMaybe<Array<Scalars['String']['input']>>;
  useNewHeadlessMode?: Scalars['Boolean']['input'];
  useRenderer?: Scalars['Boolean']['input'];
  useRobotsOverwrite?: Scalars['Boolean']['input'];
  userAgentCode?: InputMaybe<Scalars['String']['input']>;
  userAgentIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentMobileIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentString?: InputMaybe<Scalars['String']['input']>;
  userAgentStringMobile?: InputMaybe<Scalars['String']['input']>;
  userAgentToken?: InputMaybe<Scalars['String']['input']>;
  userAgentTokenMobile?: InputMaybe<Scalars['String']['input']>;
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateTestSuitePayload = {
  testSuite: TestSuite;
};

export type CreateTestsPayload = {
  tests: Array<Test>;
};

export type CreateUserInviteInput = {
  accountId: Scalars['ObjectID']['input'];
  ssoClientId?: InputMaybe<Scalars['String']['input']>;
  userEmail: Scalars['String']['input'];
};

export type CreateUserInvitePayload = {
  userInvite: UserInvite;
};

export type CreateUserKeyInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWebhookInput = {
  alertTypes?: Array<AlertType>;
  testSuiteId: Scalars['ObjectID']['input'];
  url: Scalars['String']['input'];
  webhookTemplateType: WebhookTemplateType;
};

export type CreateWebhookPayload = {
  webhook: Webhook;
};

export type CreditAllocation = {
  active: Scalars['Boolean']['output'];
  allocationContainerId: Scalars['Int']['output'];
  automaticallyManaged?: Maybe<Scalars['Boolean']['output']>;
  creditsAllocated: Scalars['Int']['output'];
  creditsUsed: Scalars['Float']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  planId?: Maybe<Scalars['String']['output']>;
  rawID: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  termId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CreditAllocationType>;
};

export type CreditAllocationConnection = {
  /** A list of edges. */
  edges: Array<CreditAllocationEdge>;
  /** A list of nodes. */
  nodes: Array<CreditAllocation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CreditAllocations in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CreditAllocationConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CreditAllocationConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CreditAllocationConnectionFilterInput>>;
  allocationContainerId?: InputMaybe<ConnectionIntFilterInput>;
  automaticallyManaged?: InputMaybe<ConnectionBooleanFilterInput>;
  creditsAllocated?: InputMaybe<ConnectionIntFilterInput>;
  creditsUsed?: InputMaybe<ConnectionFloatFilterInput>;
  endDate?: InputMaybe<ConnectionDateFilterInput>;
  planId?: InputMaybe<ConnectionStringFilterInput>;
  startDate?: InputMaybe<ConnectionDateFilterInput>;
  termId?: InputMaybe<ConnectionStringFilterInput>;
  type?: InputMaybe<ConnectionCreditAllocationTypeFilterInput>;
};

export type CreditAllocationEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CreditAllocation;
};

export type CreditAllocationOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CreditAllocations by. */
  field: CreditAllocationOrderField;
};

export enum CreditAllocationOrderField {
  EndDate = 'endDate',
  StartDate = 'startDate',
  Type = 'type'
}

export enum CreditAllocationType {
  Accessibility = 'Accessibility',
  Custom = 'Custom',
  Seo = 'SEO',
  SiteSpeed = 'SiteSpeed',
  Typeless = 'Typeless'
}

export type CreditReport = {
  crawl?: Maybe<Crawl>;
  crawlId: Scalars['ObjectID']['output'];
  crawlingAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creditAllocationType: CreditAllocationType;
  creditsUsed?: Maybe<Scalars['Float']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  project?: Maybe<Project>;
  projectId: Scalars['ObjectID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  urlsCrawled?: Maybe<Scalars['Int']['output']>;
};

export type CreditReportConnection = {
  /** A list of edges. */
  edges: Array<CreditReportEdge>;
  /** A list of nodes. */
  nodes: Array<CreditReport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CreditReports in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CreditReportConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CreditReportConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CreditReportConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CreditReportConnectionFilterInput>>;
  crawlId?: InputMaybe<ConnectionObjectIdFilterInput>;
  crawlingAt?: InputMaybe<ConnectionDateFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  creditAllocationType?: InputMaybe<ConnectionCreditAllocationTypeFilterInput>;
  creditsUsed?: InputMaybe<ConnectionFloatFilterInput>;
  finishedAt?: InputMaybe<ConnectionDateFilterInput>;
  projectId?: InputMaybe<ConnectionObjectIdFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  urlsCrawled?: InputMaybe<ConnectionIntFilterInput>;
};

export type CreditReportEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CreditReport;
};

export type CreditReportExport = {
  createdAt: Scalars['DateTime']['output'];
  exportedAt?: Maybe<Scalars['DateTime']['output']>;
  exportingAt?: Maybe<Scalars['DateTime']['output']>;
  fileURL?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ObjectID']['output'];
  rawID: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreditReportExportConnection = {
  /** A list of edges. */
  edges: Array<CreditReportExportEdge>;
  /** A list of nodes. */
  nodes: Array<CreditReportExport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CreditReportExports in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CreditReportExportConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CreditReportExportConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CreditReportExportConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CreditReportExportConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  exportedAt?: InputMaybe<ConnectionDateFilterInput>;
  exportingAt?: InputMaybe<ConnectionDateFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type CreditReportExportEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CreditReportExport;
};

export type CreditReportExportOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CreditReportExports by. */
  field: CreditReportExportOrderField;
};

export enum CreditReportExportOrderField {
  CreatedAt = 'createdAt',
  ExportedAt = 'exportedAt',
  ExportingAt = 'exportingAt',
  UpdatedAt = 'updatedAt'
}

export type CreditReportOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CreditReports by. */
  field: CreditReportOrderField;
};

export enum CreditReportOrderField {
  CrawlingAt = 'crawlingAt',
  CreatedAt = 'createdAt',
  CreditAllocationType = 'creditAllocationType',
  CreditsUsed = 'creditsUsed',
  FinishedAt = 'finishedAt',
  UpdatedAt = 'updatedAt',
  UrlsCrawled = 'urlsCrawled'
}

export enum Currency {
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Usd = 'USD'
}

export type CustomChart = {
  createdAt: Scalars['DateTime']['output'];
  customViews: CustomViewConnection;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ObjectID']['output'];
  metadata?: Maybe<Scalars['JSONObject']['output']>;
  metric: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  trends: CustomChartTrendsConnection;
  type: CustomChartType;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomChartCustomViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomViewOrder>>;
};


export type CustomChartTrendsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customViewIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  metric?: InputMaybe<Scalars['String']['input']>;
};

export type CustomChartConnection = {
  /** A list of edges. */
  edges: Array<CustomChartEdge>;
  /** A list of nodes. */
  nodes: Array<CustomChart>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomCharts in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomChartConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomChartConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomChartConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomChartConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  deletedAt?: InputMaybe<ConnectionDateFilterInput>;
  metric?: InputMaybe<ConnectionStringFilterInput>;
  type?: InputMaybe<ConnectionCustomChartTypeFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type CustomChartEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomChart;
};

export type CustomChartOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomCharts by. */
  field: CustomChartOrderField;
};

export enum CustomChartOrderField {
  CreatedAt = 'createdAt',
  Metric = 'metric',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type CustomChartTrendsConnection = {
  nodes: Array<TrendsTableRow>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum CustomChartType {
  HealthScoreCrawlCompare = 'HealthScoreCrawlCompare',
  MonitorCrawlCompare = 'MonitorCrawlCompare'
}

export type CustomDashboard = {
  collection?: Maybe<CustomDashboardCollection>;
  collectionPosition?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customCharts?: Maybe<CustomChartConnection>;
  customDashboardCollectionId?: Maybe<Scalars['Int']['output']>;
  customDashboardViews?: Maybe<CustomDashboardViewConnection>;
  customTables?: Maybe<CustomTableConnection>;
  customViews: CustomViewConnection;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  type: CustomDashboardType;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomDashboardCustomChartsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomChartConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomChartOrder>>;
};


export type CustomDashboardCustomDashboardViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomDashboardViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomDashboardViewOrder>>;
};


export type CustomDashboardCustomTablesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomTableConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomTableOrder>>;
};


export type CustomDashboardCustomViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomViewOrder>>;
};

export type CustomDashboardCollection = {
  accountId: Scalars['ObjectID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customDashboards?: Maybe<CustomDashboardConnection>;
  customViews: CustomViewConnection;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  projects: ProjectConnection;
  rawID: Scalars['String']['output'];
  segments: SegmentConnection;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomDashboardCollectionCustomDashboardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomDashboardConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomDashboardOrder>>;
};


export type CustomDashboardCollectionCustomViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomViewOrder>>;
};


export type CustomDashboardCollectionProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectOrder>>;
};


export type CustomDashboardCollectionSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SegmentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SegmentOrder>>;
};

export type CustomDashboardCollectionConnection = {
  /** A list of edges. */
  edges: Array<CustomDashboardCollectionEdge>;
  /** A list of nodes. */
  nodes: Array<CustomDashboardCollection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomDashboardCollections in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomDashboardCollectionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomDashboardCollectionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomDashboardCollectionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomDashboardCollectionConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  deletedAt?: InputMaybe<ConnectionDateFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  position?: InputMaybe<ConnectionIntFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type CustomDashboardCollectionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomDashboardCollection;
};

export type CustomDashboardCollectionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomDashboardCollections by. */
  field: CustomDashboardCollectionOrderField;
};

export enum CustomDashboardCollectionOrderField {
  CreatedAt = 'createdAt',
  Name = 'name',
  Position = 'position',
  UpdatedAt = 'updatedAt'
}

export type CustomDashboardConnection = {
  /** A list of edges. */
  edges: Array<CustomDashboardEdge>;
  /** A list of nodes. */
  nodes: Array<CustomDashboard>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomDashboards in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomDashboardConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomDashboardConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomDashboardConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomDashboardConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  collectionPosition?: InputMaybe<ConnectionIntFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  customDashboardCollectionId?: InputMaybe<ConnectionIntFilterInput>;
  deletedAt?: InputMaybe<ConnectionDateFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  type?: InputMaybe<ConnectionCustomDashboardTypeFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type CustomDashboardEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomDashboard;
};

export type CustomDashboardOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomDashboards by. */
  field: CustomDashboardOrderField;
};

export enum CustomDashboardOrderField {
  CollectionPosition = 'collectionPosition',
  CreatedAt = 'createdAt',
  CustomDashboardCollectionId = 'customDashboardCollectionId',
  Name = 'name',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum CustomDashboardType {
  HealthScores = 'HealthScores',
  Monitor = 'Monitor'
}

export type CustomDashboardView = {
  customView?: Maybe<CustomView>;
  project?: Maybe<Project>;
  projectAllPagesTotal?: Maybe<Scalars['Int']['output']>;
  projectCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  projectFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  projectLastCrawlCrawlingAt?: Maybe<Scalars['DateTime']['output']>;
  projectLastCrawlStatus?: Maybe<CrawlStatus>;
  projectName: Scalars['String']['output'];
  projectPrimaryDomain: Scalars['String']['output'];
  segment?: Maybe<Segment>;
  segmentName?: Maybe<Scalars['String']['output']>;
};

export type CustomDashboardViewConnection = {
  /** A list of edges. */
  edges: Array<CustomDashboardViewEdge>;
  /** A list of nodes. */
  nodes: Array<CustomDashboardView>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomDashboardViews in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomDashboardViewConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomDashboardViewConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomDashboardViewConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomDashboardViewConnectionFilterInput>>;
  projectAllPagesTotal?: InputMaybe<ConnectionIntFilterInput>;
  projectCreatedAt?: InputMaybe<ConnectionDateFilterInput>;
  projectFinishedAt?: InputMaybe<ConnectionDateFilterInput>;
  projectLastCrawlCrawlingAt?: InputMaybe<ConnectionDateFilterInput>;
  projectLastCrawlStatus?: InputMaybe<ConnectionCrawlStatusFilterInput>;
  projectName?: InputMaybe<ConnectionStringFilterInput>;
  projectPrimaryDomain?: InputMaybe<ConnectionStringFilterInput>;
  segmentName?: InputMaybe<ConnectionStringFilterInput>;
};

export type CustomDashboardViewEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomDashboardView;
};

export type CustomDashboardViewOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomDashboardViews by. */
  field: CustomDashboardViewOrderField;
};

export enum CustomDashboardViewOrderField {
  ProjectAllPagesTotal = 'projectAllPagesTotal',
  ProjectCreatedAt = 'projectCreatedAt',
  ProjectFinishedAt = 'projectFinishedAt',
  ProjectLastCrawlCrawlingAt = 'projectLastCrawlCrawlingAt',
  ProjectLastCrawlStatus = 'projectLastCrawlStatus',
  ProjectName = 'projectName',
  ProjectPrimaryDomain = 'projectPrimaryDomain',
  SegmentName = 'segmentName'
}

export type CustomDnsSetting = {
  hostname: Scalars['String']['output'];
  ipAddress: Scalars['String']['output'];
};

export type CustomDnsSettingInput = {
  hostname: Scalars['String']['input'];
  ipAddress: Scalars['String']['input'];
};

export type CustomExtractionSetting = {
  cleanHtmlTags?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  matchNumberFrom: Scalars['Int']['output'];
  matchNumberTo: Scalars['Int']['output'];
  regex: Scalars['String']['output'];
  regexFilter?: Maybe<Scalars['String']['output']>;
  reportTemplateCode: Scalars['String']['output'];
};

export type CustomExtractionSettingInput = {
  cleanHtmlTags?: Scalars['Boolean']['input'];
  label: Scalars['String']['input'];
  matchNumberFrom?: Scalars['Int']['input'];
  matchNumberTo?: Scalars['Int']['input'];
  regex: Scalars['String']['input'];
  regexFilter?: InputMaybe<Scalars['String']['input']>;
  reportTemplateCode?: InputMaybe<Scalars['String']['input']>;
};

export type CustomMetric = {
  code: Scalars['String']['output'];
  connectionPredicates?: Maybe<Array<ConnectionPredicateMetadata>>;
  containerDescription?: Maybe<Scalars['String']['output']>;
  containerName: Scalars['String']['output'];
  containerType: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  jsonSchema?: Maybe<Scalars['JSONObject']['output']>;
  name: Scalars['String']['output'];
  tableType?: Maybe<CustomMetricContainerTableType>;
  type?: Maybe<CustomMetricType>;
};

export type CustomMetricContainer = {
  account: Account;
  accountId: Scalars['Int']['output'];
  costs?: Maybe<Array<CustomMetricContainerCost>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  inputType: CustomMetricContainerInputType;
  isEssential?: Maybe<Scalars['Boolean']['output']>;
  isGlobal: Scalars['Boolean']['output'];
  isolate: Scalars['Boolean']['output'];
  /** Latest successfully processed version. */
  latestVersion?: Maybe<CustomMetricContainerVersion>;
  linksProducer?: Maybe<Scalars['Boolean']['output']>;
  linksProducers?: Maybe<Array<CustomMetricContainerKeyLinkProducer>>;
  minRequiredCpu?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  obeyCSP?: Maybe<Scalars['Boolean']['output']>;
  outputType?: Maybe<CustomMetricContainerOutputType>;
  rawID: Scalars['String']['output'];
  requiresResponseBuffer?: Maybe<Scalars['Boolean']['output']>;
  /** Which resource types the container extracts metrics from. */
  resourceTypes?: Maybe<Array<CustomMetricContainerResourceType>>;
  runFirst?: Maybe<Scalars['Boolean']['output']>;
  scope: CustomMetricContainerScope;
  tableType?: Maybe<CustomMetricContainerTableType>;
  tableTypes?: Maybe<Array<CustomMetricContainerKeyTableType>>;
  timeoutMs?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  versions?: Maybe<CustomMetricContainerVersionConnection>;
};


export type CustomMetricContainerVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerVersionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerVersionOrder>>;
};

export type CustomMetricContainerConnection = {
  /** A list of edges. */
  edges: Array<CustomMetricContainerEdge>;
  /** A list of nodes. */
  nodes: Array<CustomMetricContainer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomMetricContainers in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomMetricContainerConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomMetricContainerConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomMetricContainerConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomMetricContainerConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  inputType?: InputMaybe<ConnectionCustomMetricContainerInputTypeFilterInput>;
  isGlobal?: InputMaybe<ConnectionBooleanFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  scope?: InputMaybe<ConnectionCustomMetricContainerScopeFilterInput>;
};

export type CustomMetricContainerCost = {
  cost: Scalars['Float']['output'];
  moduleCode: ModuleCode;
};

export type CustomMetricContainerCostInput = {
  cost: Scalars['Float']['input'];
  moduleCode: ModuleCode;
};

export type CustomMetricContainerEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomMetricContainer;
};

export enum CustomMetricContainerInputType {
  Dom = 'DOM',
  Puppeteer = 'Puppeteer'
}

export type CustomMetricContainerKeyLinkProducer = {
  key: Scalars['String']['output'];
  linkProducer: Scalars['Boolean']['output'];
};

export type CustomMetricContainerKeyTableType = {
  key: Scalars['String']['output'];
  tableType: CustomMetricContainerTableType;
};

export type CustomMetricContainerOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomMetricContainers by. */
  field: CustomMetricContainerOrderField;
};

export enum CustomMetricContainerOrderField {
  AccountId = 'accountId',
  Id = 'id',
  InputType = 'inputType',
  IsGlobal = 'isGlobal',
  Name = 'name',
  Scope = 'scope'
}

export enum CustomMetricContainerOutputType {
  MultiOutput = 'MultiOutput',
  SingleOutput = 'SingleOutput'
}

export type CustomMetricContainerProject = {
  /** Parameters that will be passed as part of the context when a container gets executed. */
  containerParams?: Maybe<Scalars['JSONObject']['output']>;
  customJsResources?: Maybe<Array<Scalars['String']['output']>>;
  customJsScripts?: Maybe<Array<Scalars['String']['output']>>;
  customMetricContainer: CustomMetricContainer;
  customMetricContainerId: Scalars['Int']['output'];
  customMetricContainerProjectSecrets: CustomMetricContainerProjectSecretConnection;
  enabled: Scalars['Boolean']['output'];
  project: Project;
  projectId: Scalars['Int']['output'];
};


export type CustomMetricContainerProjectCustomMetricContainerProjectSecretsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerProjectSecretConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerProjectSecretOrder>>;
};

export type CustomMetricContainerProjectConnection = {
  /** A list of edges. */
  edges: Array<CustomMetricContainerProjectEdge>;
  /** A list of nodes. */
  nodes: Array<CustomMetricContainerProject>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomMetricContainerProjects in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomMetricContainerProjectConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomMetricContainerProjectConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomMetricContainerProjectConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomMetricContainerProjectConnectionFilterInput>>;
  customMetricContainerId?: InputMaybe<ConnectionObjectIdFilterInput>;
  enabled?: InputMaybe<ConnectionBooleanFilterInput>;
  projectId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type CustomMetricContainerProjectEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomMetricContainerProject;
};

export type CustomMetricContainerProjectOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomMetricContainerProjects by. */
  field: CustomMetricContainerProjectOrderField;
};

export enum CustomMetricContainerProjectOrderField {
  CustomMetricContainerId = 'customMetricContainerId',
  Enabled = 'enabled',
  ProjectId = 'projectId'
}

export type CustomMetricContainerProjectSecret = {
  createdAt: Scalars['DateTime']['output'];
  customMetricContainerId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomMetricContainerProjectSecretConnection = {
  /** A list of edges. */
  edges: Array<CustomMetricContainerProjectSecretEdge>;
  /** A list of nodes. */
  nodes: Array<CustomMetricContainerProjectSecret>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomMetricContainerProjectSecrets in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomMetricContainerProjectSecretConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomMetricContainerProjectSecretConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomMetricContainerProjectSecretConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomMetricContainerProjectSecretConnectionFilterInput>>;
  customMetricContainerId?: InputMaybe<ConnectionObjectIdFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  projectId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type CustomMetricContainerProjectSecretEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomMetricContainerProjectSecret;
};

export type CustomMetricContainerProjectSecretOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomMetricContainerProjectSecrets by. */
  field: CustomMetricContainerProjectSecretOrderField;
};

export enum CustomMetricContainerProjectSecretOrderField {
  CustomMetricContainerId = 'customMetricContainerId',
  Name = 'name',
  ProjectId = 'projectId'
}

export enum CustomMetricContainerRenderingResource {
  Font = 'Font',
  Image = 'Image',
  TextTrack = 'TextTrack'
}

/** The type of source that the custom metric container is based on. */
export enum CustomMetricContainerResourceType {
  Document = 'Document',
  Image = 'Image',
  Script = 'Script',
  Stylesheet = 'Stylesheet'
}

export enum CustomMetricContainerScope {
  Container = 'Container',
  System = 'System'
}

export enum CustomMetricContainerTableType {
  Accessibility = 'Accessibility',
  AccessibilityOld = 'AccessibilityOld',
  Document = 'Document',
  DocumentOld = 'DocumentOld',
  Domain = 'Domain',
  ExternalCustom = 'ExternalCustom',
  SeoDocument = 'SeoDocument',
  SeoLink = 'SeoLink',
  SiteSpeedAudit = 'SiteSpeedAudit',
  SiteSpeedAuditItem = 'SiteSpeedAuditItem',
  SiteSpeedDocument = 'SiteSpeedDocument',
  SiteSpeedDomain = 'SiteSpeedDomain'
}

export type CustomMetricContainerVersion = {
  allowedRenderingResources?: Maybe<Array<CustomMetricContainerRenderingResource>>;
  avroSchema?: Maybe<Scalars['JSONObject']['output']>;
  avroSchemas?: Maybe<Scalars['JSONObject']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customMetricContainer: CustomMetricContainer;
  customMetrics?: Maybe<Array<CustomMetric>>;
  /** Presigned S3 download URL */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  entrypoint?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  failureReason?: Maybe<Scalars['String']['output']>;
  fieldsMapping?: Maybe<Scalars['JSONObject']['output']>;
  fieldsMappings?: Maybe<Scalars['JSONObject']['output']>;
  handler?: Maybe<Scalars['String']['output']>;
  handlers?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ObjectID']['output'];
  inputType: CustomMetricContainerInputType;
  isEssential?: Maybe<Scalars['Boolean']['output']>;
  isolate: Scalars['Boolean']['output'];
  linksProducer?: Maybe<Scalars['Boolean']['output']>;
  linksProducers?: Maybe<Array<CustomMetricContainerKeyLinkProducer>>;
  metricsMetadata?: Maybe<Scalars['JSONObject']['output']>;
  minRequiredCpu?: Maybe<Scalars['Int']['output']>;
  navigationTimeoutMs?: Maybe<Scalars['Int']['output']>;
  obeyCSP?: Maybe<Scalars['Boolean']['output']>;
  outputSchema?: Maybe<Scalars['JSONObject']['output']>;
  outputSchemaPath?: Maybe<Scalars['String']['output']>;
  outputSchemaPaths?: Maybe<Scalars['JSONObject']['output']>;
  outputSchemas?: Maybe<Scalars['JSONObject']['output']>;
  outputType?: Maybe<CustomMetricContainerOutputType>;
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  processingAt?: Maybe<Scalars['DateTime']['output']>;
  rawID: Scalars['String']['output'];
  requiresResponseBuffer?: Maybe<Scalars['Boolean']['output']>;
  resourceTypes?: Maybe<Array<CustomMetricContainerResourceType>>;
  runFirst?: Maybe<Scalars['Boolean']['output']>;
  s3Location?: Maybe<Scalars['String']['output']>;
  scope: CustomMetricContainerScope;
  status: CustomMetricContainerVersionStatus;
  tableType?: Maybe<CustomMetricContainerTableType>;
  tableTypes?: Maybe<Array<CustomMetricContainerKeyTableType>>;
  timeoutMs?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  /** Presigned S3 upload URL */
  uploadUrl?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type CustomMetricContainerVersionConnection = {
  /** A list of edges. */
  edges: Array<CustomMetricContainerVersionEdge>;
  /** A list of nodes. */
  nodes: Array<CustomMetricContainerVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomMetricContainerVersions in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomMetricContainerVersionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomMetricContainerVersionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomMetricContainerVersionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomMetricContainerVersionConnectionFilterInput>>;
  failedAt?: InputMaybe<ConnectionDateFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  inputType?: InputMaybe<ConnectionCustomMetricContainerInputTypeFilterInput>;
  processedAt?: InputMaybe<ConnectionDateFilterInput>;
  processingAt?: InputMaybe<ConnectionDateFilterInput>;
  scope?: InputMaybe<ConnectionCustomMetricContainerScopeFilterInput>;
  status?: InputMaybe<ConnectionCustomMetricContainerVersionStatusFilterInput>;
};

export type CustomMetricContainerVersionCrawl = {
  containerParams?: Maybe<Scalars['JSONObject']['output']>;
  customJsResources?: Maybe<Array<Scalars['String']['output']>>;
  customJsScripts?: Maybe<Array<Scalars['String']['output']>>;
  customMetricContainerVersion: CustomMetricContainerVersion;
};

export type CustomMetricContainerVersionCrawlConnection = {
  /** A list of edges. */
  edges: Array<CustomMetricContainerVersionCrawlEdge>;
  /** A list of nodes. */
  nodes: Array<CustomMetricContainerVersionCrawl>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomMetricContainerVersionCrawls in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomMetricContainerVersionCrawlConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomMetricContainerVersionCrawlConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomMetricContainerVersionCrawlConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomMetricContainerVersionCrawlConnectionFilterInput>>;
  crawlId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type CustomMetricContainerVersionCrawlEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomMetricContainerVersionCrawl;
};

export type CustomMetricContainerVersionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomMetricContainerVersion;
};

export type CustomMetricContainerVersionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomMetricContainerVersions by. */
  field: CustomMetricContainerVersionOrderField;
};

export enum CustomMetricContainerVersionOrderField {
  FailedAt = 'failedAt',
  Id = 'id',
  InputType = 'inputType',
  ProcessedAt = 'processedAt',
  ProcessingAt = 'processingAt',
  Scope = 'scope'
}

export enum CustomMetricContainerVersionStatus {
  Draft = 'Draft',
  Errored = 'Errored',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum CustomMetricType {
  Boolean = 'Boolean',
  Integer = 'Integer',
  IntegerArray = 'IntegerArray',
  Number = 'Number',
  NumberArray = 'NumberArray',
  String = 'String',
  StringArray = 'StringArray'
}

export type CustomMetricUploadContainer = {
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploadTemplate: Scalars['JSONObject']['output'];
  urlFileUploads: UrlFileUploadConnection;
};


export type CustomMetricUploadContainerUrlFileUploadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UrlFileUploadConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CustomMetricUploadContainerConnection = {
  /** A list of edges. */
  edges: Array<CustomMetricUploadContainerEdge>;
  /** A list of nodes. */
  nodes: Array<CustomMetricUploadContainer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomMetricUploadContainers in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomMetricUploadContainerConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomMetricUploadContainerConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomMetricUploadContainerConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomMetricUploadContainerConnectionFilterInput>>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
};

export type CustomMetricUploadContainerCrawl = {
  createdAt: Scalars['DateTime']['output'];
  customMetricUploadContainer: CustomMetricUploadContainer;
  fileTemplate?: Maybe<Scalars['JSONObject']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uploadTemplate: Scalars['JSONObject']['output'];
};

export type CustomMetricUploadContainerCrawlConnection = {
  /** A list of edges. */
  edges: Array<CustomMetricUploadContainerCrawlEdge>;
  /** A list of nodes. */
  nodes: Array<CustomMetricUploadContainerCrawl>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomMetricUploadContainerCrawls in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomMetricUploadContainerCrawlConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomMetricUploadContainerCrawlConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomMetricUploadContainerCrawlConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomMetricUploadContainerCrawlConnectionFilterInput>>;
  crawlId?: InputMaybe<ConnectionObjectIdFilterInput>;
  customMetricUploadContainerId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type CustomMetricUploadContainerCrawlEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomMetricUploadContainerCrawl;
};

export type CustomMetricUploadContainerEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomMetricUploadContainer;
};

export type CustomMetricUploadContainerOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomMetricUploadContainers by. */
  field: CustomMetricUploadContainerOrderField;
};

export enum CustomMetricUploadContainerOrderField {
  Id = 'id',
  Name = 'name'
}

export type CustomReport = {
  added?: Maybe<Scalars['Float']['output']>;
  basic?: Maybe<Scalars['Float']['output']>;
  crawl: Crawl;
  crawlAccessibilityIssueInstanceInstances?: Maybe<CrawlAccessibilityIssueInstanceConnection>;
  crawlAccessibilityIssues?: Maybe<CrawlAccessibilityIssueConnection>;
  crawlDomains?: Maybe<CrawlDomainConnection>;
  crawlDuplicateUrls?: Maybe<CrawlDuplicateUrlConnection>;
  crawlHreflangs?: Maybe<CrawlHreflangsConnection>;
  crawlId: Scalars['ObjectID']['output'];
  crawlLinkedDomains?: Maybe<CrawlLinkedDomainConnection>;
  crawlLinks?: Maybe<CrawlLinkConnection>;
  crawlSiteSpeedAuditItems?: Maybe<CrawlSiteSpeedAuditItemConnection>;
  crawlSiteSpeedAuditOpportunities?: Maybe<CrawlSiteSpeedAuditOpportunityConnection>;
  crawlSiteSpeedAuditOpportunitiesAggregates?: Maybe<CrawlSiteSpeedAuditOpportunityAggregateConnection>;
  crawlSiteSpeedAudits?: Maybe<CrawlSiteSpeedAuditConnection>;
  crawlSiteSpeedAuditsAggregates?: Maybe<CrawlSiteSpeedAuditAggregateConnection>;
  crawlSitemaps?: Maybe<CrawlSitemapConnection>;
  crawlUncrawledUrls?: Maybe<CrawlUncrawledUrlConnection>;
  crawlUniqueLinks?: Maybe<CrawlUniqueLinkConnection>;
  crawlUrls?: Maybe<CrawlUrlConnection>;
  crawlUrlsAggregates?: Maybe<CrawlUrlAggregateConnection>;
  crawlWebCrawlDepths?: Maybe<CrawlWebCrawlDepthConnection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customReportTemplate: CustomReportTemplate;
  customReportTemplateTotalSign?: Maybe<Scalars['Int']['output']>;
  customReportTemplateTotalWeight?: Maybe<Scalars['Float']['output']>;
  getResource?: Maybe<Scalars['JSONObject']['output']>;
  indexName?: Maybe<Scalars['String']['output']>;
  missing?: Maybe<Scalars['Float']['output']>;
  project: Project;
  projectId: Scalars['ObjectID']['output'];
  removed?: Maybe<Scalars['Float']['output']>;
  reportTemplate: ReportTemplate;
  rows?: Maybe<CustomReportRowConnection>;
  rowsWithAllMetrics?: Maybe<AllMetricsCustomReportRowConnection>;
  segment?: Maybe<Segment>;
  segmentId?: Maybe<Scalars['ObjectID']['output']>;
  segmentVersion?: Maybe<Scalars['Int']['output']>;
  totalWeight: Scalars['Float']['output'];
  trend: Array<CustomReportTrendItem>;
  version: Scalars['Int']['output'];
};


export type CustomReportCrawlAccessibilityIssueInstanceInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlAccessibilityIssueInstanceConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlAccessibilityIssueInstanceOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlAccessibilityIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlAccessibilityIssueConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlAccessibilityIssueOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlDomainConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlDomainOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlDuplicateUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlDuplicateUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlDuplicateUrlOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlHreflangsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlHreflangsConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlHreflangsOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlLinkedDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlLinkedDomainConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlLinkedDomainOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlLinkConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlLinkOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlSiteSpeedAuditItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditItemConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditItemOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlSiteSpeedAuditOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditOpportunityOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlSiteSpeedAuditOpportunitiesAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlSiteSpeedAuditOpportunityAggregateDimension>;
  filter?: InputMaybe<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlSiteSpeedAuditOpportunityAggregateOrder>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlSiteSpeedAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlSiteSpeedAuditsAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlSiteSpeedAuditAggregateDimension>;
  filter?: InputMaybe<CrawlSiteSpeedAuditConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlSiteSpeedAuditAggregateOrder>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlSitemapsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSitemapConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSitemapOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlUncrawledUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUncrawledUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUncrawledUrlOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlUniqueLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUniqueLinkConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUniqueLinkOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUrlOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlUrlsAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlUrlAggregateDimension>;
  filter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlUrlAggregateOrder>;
  reportType?: ReportTypeCode;
};


export type CustomReportCrawlWebCrawlDepthsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlWebCrawlDepthConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlWebCrawlDepthOrder>>;
  reportType?: ReportTypeCode;
};


export type CustomReportGetResourceArgs = {
  input: CustomReportGetResourceInputType;
};


export type CustomReportIndexNameArgs = {
  reportType?: ReportTypeCode;
};


export type CustomReportRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  reportType?: ReportTypeCode;
};


export type CustomReportRowsWithAllMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  reportType?: ReportTypeCode;
};

export type CustomReportGetResourceInputType = {
  reportTypeCode?: ReportTypeCode;
  resourceId: Scalars['String']['input'];
};

export type CustomReportRowConnection = {
  edges: Array<CustomReportRowEdge>;
  nodes: Array<ReportRow>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type CustomReportRowEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: ReportRow;
};

export type CustomReportTemplate = {
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customReportTemplateVersions: CustomReportTemplateVersionConnection;
  description?: Maybe<Scalars['String']['output']>;
  filter: Scalars['JSONObject']['output'];
  id: Scalars['ObjectID']['output'];
  /** The metrics groupings value stored as string[][]. */
  metricsGroupings?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  orderBy?: Maybe<Array<Scalars['JSONObject']['output']>>;
  rawID: Scalars['String']['output'];
  reportTemplate: ReportTemplate;
  reportTemplateCode: Scalars['String']['output'];
  reportTemplateQueryVersion: Scalars['Int']['output'];
  reportTypes: Array<ReportTypeCode>;
  task?: Maybe<LegacyTask>;
  totalSign: Scalars['Int']['output'];
  totalWeight: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};


export type CustomReportTemplateCustomReportTemplateVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomReportTemplateVersionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomReportTemplateVersionOrder>>;
};

export type CustomReportTemplateConnection = {
  /** A list of edges. */
  edges: Array<CustomReportTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<CustomReportTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomReportTemplates in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomReportTemplateConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomReportTemplateConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomReportTemplateConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomReportTemplateConnectionFilterInput>>;
  code?: InputMaybe<ConnectionStringFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  reportTemplateCode?: InputMaybe<ConnectionStringFilterInput>;
  reportTemplateQueryVersion?: InputMaybe<ConnectionIntFilterInput>;
  taskId?: InputMaybe<ConnectionObjectIdFilterInput>;
  totalSign?: InputMaybe<ConnectionIntFilterInput>;
  totalWeight?: InputMaybe<ConnectionFloatFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  version?: InputMaybe<ConnectionIntFilterInput>;
};

export type CustomReportTemplateEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomReportTemplate;
};

export type CustomReportTemplateMetricsGroupingInput = {
  /** An array of Metric.code or CustomMetric.name values. */
  metrics: Array<Scalars['String']['input']>;
};

export type CustomReportTemplateOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomReportTemplates by. */
  field: CustomReportTemplateOrderField;
};

export enum CustomReportTemplateOrderField {
  Code = 'code',
  CreatedAt = 'createdAt',
  Name = 'name',
  ReportTemplateCode = 'reportTemplateCode',
  ReportTemplateQueryVersion = 'reportTemplateQueryVersion',
  TotalSign = 'totalSign',
  TotalWeight = 'totalWeight',
  UpdatedAt = 'updatedAt',
  Version = 'version'
}

export type CustomReportTemplateVersion = {
  createdAt: Scalars['DateTime']['output'];
  filter: Scalars['JSONObject']['output'];
  orderBy?: Maybe<Array<Scalars['JSONObject']['output']>>;
  reportTemplateQueryVersion: Scalars['Int']['output'];
  reportTypes: Array<ReportTypeCode>;
  totalSign: Scalars['Int']['output'];
  totalWeight: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};

export type CustomReportTemplateVersionConnection = {
  /** A list of edges. */
  edges: Array<CustomReportTemplateVersionEdge>;
  /** A list of nodes. */
  nodes: Array<CustomReportTemplateVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomReportTemplateVersions in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomReportTemplateVersionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomReportTemplateVersionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomReportTemplateVersionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomReportTemplateVersionConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  reportTemplateQueryVersion?: InputMaybe<ConnectionIntFilterInput>;
  totalSign?: InputMaybe<ConnectionIntFilterInput>;
  totalWeight?: InputMaybe<ConnectionFloatFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  version?: InputMaybe<ConnectionIntFilterInput>;
};

export type CustomReportTemplateVersionCrawl = {
  customReportTemplate: CustomReportTemplate;
  internalGeneratedESQuery?: Maybe<Scalars['JSONObject']['output']>;
};


export type CustomReportTemplateVersionCrawlInternalGeneratedEsQueryArgs = {
  reportType?: ReportTypeCode;
};

export type CustomReportTemplateVersionCrawlConnection = {
  /** A list of edges. */
  edges: Array<CustomReportTemplateVersionCrawlEdge>;
  /** A list of nodes. */
  nodes: Array<CustomReportTemplateVersionCrawl>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomReportTemplateVersionCrawls in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomReportTemplateVersionCrawlConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomReportTemplateVersionCrawlConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomReportTemplateVersionCrawlConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomReportTemplateVersionCrawlConnectionFilterInput>>;
  crawlId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type CustomReportTemplateVersionCrawlEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomReportTemplateVersionCrawl;
};

export type CustomReportTemplateVersionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomReportTemplateVersion;
};

export type CustomReportTemplateVersionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomReportTemplateVersions by. */
  field: CustomReportTemplateVersionOrderField;
};

export enum CustomReportTemplateVersionOrderField {
  CreatedAt = 'createdAt',
  ReportTemplateQueryVersion = 'reportTemplateQueryVersion',
  TotalSign = 'totalSign',
  TotalWeight = 'totalWeight',
  UpdatedAt = 'updatedAt',
  Version = 'version'
}

export type CustomReportTrendItem = {
  added?: Maybe<Scalars['Float']['output']>;
  basic?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customReportTemplateTotalSign?: Maybe<Scalars['Int']['output']>;
  customReportTemplateTotalWeight?: Maybe<Scalars['Float']['output']>;
  missing?: Maybe<Scalars['Float']['output']>;
  removed?: Maybe<Scalars['Float']['output']>;
  segmentVersion?: Maybe<Scalars['Int']['output']>;
  totalWeight: Scalars['Float']['output'];
  version: Scalars['Int']['output'];
};

export type CustomRequestHeaderInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type CustomTable = {
  columns: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  filter?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ObjectID']['output'];
  orderBy?: Maybe<Array<Scalars['JSONObject']['output']>>;
  rawID: Scalars['String']['output'];
  table: CustomTableTrendsRowConnection;
  type: CustomTableType;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomTableTableArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomDashboardViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TrendsTableOrderBy>;
};

export type CustomTableConnection = {
  /** A list of edges. */
  edges: Array<CustomTableEdge>;
  /** A list of nodes. */
  nodes: Array<CustomTable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomTables in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomTableConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomTableConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomTableConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomTableConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  deletedAt?: InputMaybe<ConnectionDateFilterInput>;
  type?: InputMaybe<ConnectionCustomTableTypeFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type CustomTableEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomTable;
};

export type CustomTableOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomTables by. */
  field: CustomTableOrderField;
};

export enum CustomTableOrderField {
  CreatedAt = 'createdAt',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type CustomTableTrendsNode = {
  customDashboardView: CustomDashboardView;
  row: TrendsTableRow;
};

export type CustomTableTrendsRowConnection = {
  nodes: Array<CustomTableTrendsNode>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export enum CustomTableType {
  HealthScores = 'HealthScores',
  MonitorProjects = 'MonitorProjects'
}

export type CustomView = {
  account: Account;
  accountId: Scalars['Int']['output'];
  id: Scalars['ObjectID']['output'];
  project: Project;
  projectId: Scalars['Int']['output'];
  rawID: Scalars['String']['output'];
  segment?: Maybe<Segment>;
  segmentId?: Maybe<Scalars['Int']['output']>;
};

export type CustomViewConnection = {
  /** A list of edges. */
  edges: Array<CustomViewEdge>;
  /** A list of nodes. */
  nodes: Array<CustomView>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of CustomViews in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type CustomViewConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<CustomViewConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<CustomViewConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<CustomViewConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  projectId?: InputMaybe<ConnectionObjectIdFilterInput>;
  segmentId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type CustomViewEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: CustomView;
};

export type CustomViewInput = {
  accountId: Scalars['ObjectID']['input'];
  projectId: Scalars['ObjectID']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type CustomViewOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order CustomViews by. */
  field: CustomViewOrderField;
};

export enum CustomViewOrderField {
  AccountId = 'accountId',
  Id = 'id',
  ProjectId = 'projectId',
  SegmentId = 'segmentId'
}

export type Datasource = {
  /** @deprecated Use datasourceCode instead. */
  code: Scalars['String']['output'];
  datasourceCode: DatasourceCode;
  isPrivate: Scalars['Boolean']['output'];
  metadata?: Maybe<DatasourceMetadata>;
  metrics: Array<Metric>;
  /** @deprecated No longer used. */
  version: Scalars['Int']['output'];
};

export enum DatasourceCode {
  CrawlAccessibilityIssueInstances = 'CrawlAccessibilityIssueInstances',
  CrawlAccessibilityIssues = 'CrawlAccessibilityIssues',
  CrawlDomains = 'CrawlDomains',
  CrawlDuplicateUrls = 'CrawlDuplicateUrls',
  CrawlHreflangs = 'CrawlHreflangs',
  CrawlLinkedDomains = 'CrawlLinkedDomains',
  CrawlLinks = 'CrawlLinks',
  CrawlSiteSpeedAuditItems = 'CrawlSiteSpeedAuditItems',
  CrawlSiteSpeedAuditOpportunities = 'CrawlSiteSpeedAuditOpportunities',
  CrawlSiteSpeedAudits = 'CrawlSiteSpeedAudits',
  CrawlSitemapDiscoveries = 'CrawlSitemapDiscoveries',
  CrawlSitemaps = 'CrawlSitemaps',
  CrawlUncrawledUrls = 'CrawlUncrawledUrls',
  CrawlUniqueLinks = 'CrawlUniqueLinks',
  CrawlUrls = 'CrawlUrls',
  CrawlWebCrawlDepths = 'CrawlWebCrawlDepths'
}

export type DatasourceMetadata = {
  resourceIdMetric?: Maybe<Scalars['String']['output']>;
};

export type DateTimeRange = {
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type DefaultCustomMetricContainer = {
  customMetricContainer: CustomMetricContainer;
  dataset: DefaultCustomMetricContainerDataset;
};

export enum DefaultCustomMetricContainerDataset {
  /** @deprecated No longer in use. */
  Accessibility = 'Accessibility'
}

export type DeleteAccountUserInput = {
  accountId: Scalars['ObjectID']['input'];
  userId: Scalars['ObjectID']['input'];
};

export type DeleteAccountUserPayload = {
  account: Account;
  deleted: Scalars['Boolean']['output'];
};

export type DeleteAdobeAnalyticsProjectReportSuiteInput = {
  adobeAnalyticsProjectReportSuiteId: Scalars['ObjectID']['input'];
};

export type DeleteAdobeAnalyticsProjectReportSuitePayload = {
  adobeAnalyticsProjectReportSuite: AdobeAnalyticsProjectReportSuite;
};

export type DeleteAdobeConnectionInput = {
  adobeConnectionId: Scalars['ObjectID']['input'];
  deleteProjectReportSuites?: Scalars['Boolean']['input'];
};

export type DeleteAdobeConnectionPayload = {
  adobeConnection: AdobeConnection;
};

export type DeleteAdobeJwtConnectionInput = {
  adobeJwtConnectionId: Scalars['ObjectID']['input'];
  deleteProjectReportSuites?: Scalars['Boolean']['input'];
};

export type DeleteAdobeJwtConnectionPayload = {
  adobeJwtConnection: AdobeJwtConnection;
};

export type DeleteBuildScheduleInput = {
  buildScheduleId: Scalars['ObjectID']['input'];
};

export type DeleteBuildSchedulePayload = {
  buildSchedule: BuildSchedule;
};

export type DeleteCrawlInput = {
  crawlId: Scalars['ObjectID']['input'];
};

export type DeleteCrawlPayload = {
  crawl: Crawl;
};

export type DeleteCustomChartPayload = {
  customChart: CustomChart;
};

export type DeleteCustomDashboardCollectionInput = {
  customDashboardCollectionId: Scalars['ObjectID']['input'];
};

export type DeleteCustomDashboardCollectionPayload = {
  customDashboardCollection: CustomDashboardCollection;
};

export type DeleteCustomDashboardPayload = {
  customDashboard: CustomDashboard;
};

export type DeleteCustomMetricContainerInput = {
  customMetricContainerId: Scalars['ObjectID']['input'];
};

export type DeleteCustomMetricContainerPayload = {
  customMetricContainer: CustomMetricContainer;
};

export type DeleteCustomMetricContainerProjectSecretInput = {
  customMetricContainerId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ObjectID']['input'];
};

export type DeleteCustomMetricContainerProjectSecretPayload = {
  customMetricContainerProjectSecret: CustomMetricContainerProjectSecret;
};

export type DeleteCustomReportTemplateInput = {
  customReportTemplateId: Scalars['ObjectID']['input'];
};

export type DeleteCustomReportTemplatePayload = {
  customReportTemplate: CustomReportTemplate;
};

export type DeleteCustomTablePayload = {
  customTable: CustomTable;
};

export type DeleteCustomViewsInput = {
  customViewIds: Array<Scalars['ObjectID']['input']>;
};

export type DeleteCustomViewsPayload = {
  customViews: Array<CustomView>;
};

export type DeleteGoogleAnalytics4ProjectPropertyInput = {
  projectId: Scalars['ObjectID']['input'];
};

export type DeleteGoogleAnalytics4ProjectPropertyPayload = {
  googleAnalytics4ProjectProperty: GoogleAnalytics4ProjectProperty;
};

export type DeleteGoogleConnectionInput = {
  googleConnectionId: Scalars['ObjectID']['input'];
};

export type DeleteGoogleConnectionPayload = {
  googleConnection: GoogleConnection;
};

export type DeleteGoogleSearchConsoleConfigurationInput = {
  projectId: Scalars['ObjectID']['input'];
};

export type DeleteGoogleSearchConsoleConfigurationPayload = {
  googleSearchConsoleConfiguration: GoogleSearchConsoleConfiguration;
};

export type DeleteGoogleSearchConsolePropertyInput = {
  googleSearchConsolePropertyId: Scalars['ObjectID']['input'];
};

export type DeleteGoogleSearchConsolePropertyPayload = {
  googleSearchConsoleProperty: GoogleSearchConsoleProperty;
};

export type DeleteJiraAuthenticationInput = {
  jiraAuthenticationId: Scalars['ObjectID']['input'];
};

export type DeleteJiraAuthenticationPayload = {
  jiraAuthentication: JiraAuthentication;
};

export type DeleteJiraIntegrationInput = {
  jiraIntegrationId: Scalars['ObjectID']['input'];
};

export type DeleteJiraIntegrationPayload = {
  jiraIntegration: JiraIntegration;
};

export type DeleteLegacyTaskInput = {
  legacyTaskId: Scalars['ObjectID']['input'];
};

export type DeleteLegacyTaskPayload = {
  legacyTask: LegacyTask;
};

export type DeleteLogzioConnectionInput = {
  logzioConnectionId: Scalars['ObjectID']['input'];
};

export type DeleteLogzioConnectionPayload = {
  logzioConnection: LogzioConnection;
};

export type DeleteLogzioProjectQueryInput = {
  logzioProjectQueryId: Scalars['ObjectID']['input'];
};

export type DeleteLogzioProjectQueryPayload = {
  logzioProjectQuery: LogzioProjectQuery;
};

export type DeleteProjectPayload = {
  project: Project;
};

export type DeleteReportDownloadInput = {
  reportDownloadId: Scalars['ObjectID']['input'];
};

export type DeleteReportDownloadPayload = {
  reportDownload: ReportDownload;
};

export type DeleteReportTemplateOverrideInput = {
  projectId: Scalars['ObjectID']['input'];
  reportTemplateCode: Scalars['String']['input'];
};

export type DeleteReportTemplateOverridePayload = {
  reportTemplateOverride: ReportTemplateOverride;
};

export type DeleteScheduleInput = {
  scheduleId: Scalars['ObjectID']['input'];
};

export type DeleteSchedulePayload = {
  schedule: Schedule;
};

export type DeleteSegmentInput = {
  segmentId: Scalars['ObjectID']['input'];
};

export type DeleteSegmentPayload = {
  segment: Segment;
};

export type DeleteSitemapInput = {
  projectId: Scalars['ObjectID']['input'];
  urlDigest: Scalars['String']['input'];
};

export type DeleteSitemapPayload = {
  sitemaps: Array<Sitemap>;
};

export type DeleteSlackWebhookInput = {
  slackWebhookId: Scalars['ObjectID']['input'];
};

export type DeleteSlackWebhookPayload = {
  slackWebhook: SlackWebhook;
};

export type DeleteSplunkConnectionInput = {
  splunkConnectionId: Scalars['ObjectID']['input'];
};

export type DeleteSplunkConnectionPayload = {
  splunkConnection: SplunkConnection;
};

export type DeleteSplunkProjectQueryInput = {
  splunkProjectQueryId: Scalars['ObjectID']['input'];
};

export type DeleteSplunkProjectQueryPayload = {
  splunkProjectQuery: SplunkProjectQuery;
};

export type DeleteTestInput = {
  testId: Scalars['ObjectID']['input'];
};

export type DeleteTestPayload = {
  test: Test;
};

export type DeleteTestSuiteEmailAlertInput = {
  testSuiteEmailAlertId: Scalars['ObjectID']['input'];
};

export type DeleteTestSuiteEmailAlertPayload = {
  testSuiteEmailAlert: TestSuiteEmailAlert;
};

export type DeleteTestSuiteInput = {
  testSuiteId: Scalars['ObjectID']['input'];
};

export type DeleteTestSuitePayload = {
  testSuite: TestSuite;
};

export type DeleteTestsInput = {
  testIds: Array<Scalars['ObjectID']['input']>;
};

export type DeleteTestsPayload = {
  tests: Array<Test>;
};

export type DeleteUrlFileUploadInput = {
  urlFileUploadId: Scalars['ObjectID']['input'];
};

export type DeleteUrlFileUploadPayload = {
  urlFileUpload: UrlFileUpload;
};

export type DeleteUserKeyInput = {
  userKeyId: Scalars['ObjectID']['input'];
};

export type DeleteUserKeyPayload = {
  userKey: UserKey;
};

export type DeleteWebhookInput = {
  webhookId: Scalars['ObjectID']['input'];
};

export type DeleteWebhookPayload = {
  webhook: Webhook;
};

export type DisableSitemapInput = {
  projectId: Scalars['ObjectID']['input'];
  urlDigest: Scalars['String']['input'];
};

export type DisableSitemapPayload = {
  sitemaps: Array<Sitemap>;
};

export type DisableSitemapsInput = {
  projectId: Scalars['ObjectID']['input'];
  /** When no value is provided, all sitemaps get disabled. */
  urlDigests?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DisableSitemapsPayload = {
  sitemaps: Array<Sitemap>;
};

export type DomainCheckVariant = {
  domain: Scalars['String']['output'];
  redirectsTo?: Maybe<Scalars['String']['output']>;
  redirectsToStatusCode?: Maybe<Scalars['Int']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
};

export enum EmailAlertType {
  Fail = 'Fail',
  Pass = 'Pass',
  Warning = 'Warning'
}

export type EnableSitemapInput = {
  projectId: Scalars['ObjectID']['input'];
  urlDigest: Scalars['String']['input'];
};

export type EnableSitemapPayload = {
  sitemaps: Array<Sitemap>;
};

export type EnableSitemapsInput = {
  projectId: Scalars['ObjectID']['input'];
  /** When no value is provided, all sitemaps get enabled. */
  urlDigests?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EnableSitemapsPayload = {
  sitemaps: Array<Sitemap>;
};

export type Entity = AccessibilityIssueSolutionSuggestion | Account | AdobeAnalyticsProjectReportSuite | AdobeConnection | AdobeJwtConnection | Build | BuildSchedule | BuildScheduleRun | BuildScheduleTestSuite | Crawl | CrawlSetting | CrawlTypeMetadata | CrawlUrl | CreditReportExport | CustomChart | CustomDashboard | CustomDashboardCollection | CustomMetricContainer | CustomMetricContainerVersion | CustomMetricUploadContainer | CustomReportTemplate | CustomTable | CustomView | GoogleAnalytics4ProjectProperty | GoogleConnection | GoogleSearchConsoleProperty | JiraAuthentication | JiraIntegration | LegacyTask | Location | LogzioConnection | LogzioProjectQuery | Project | Report | ReportDownload | ReportTemplate | Schedule | Segment | SinglePageRequesterRequest | SlackWebhook | SplunkConnection | SplunkProjectQuery | Test | TestResult | TestSuite | TestSuiteEmailAlert | TestSuiteJiraIntegration | UrlFileUpload | UrlFileUploadType | User | UserInvite | Webhook;

export type FeatureCost = {
  costs: Array<FeatureCostCost>;
  description: Scalars['String']['output'];
  /** Unique code used for localization purposes with Lokalise keys like "featureCosts.{featureCode}.name". */
  featureCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  projectSetting: Scalars['String']['output'];
};

export type FeatureCostConnection = {
  /** A list of edges. */
  edges: Array<FeatureCostEdge>;
  /** A list of nodes. */
  nodes: Array<FeatureCost>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of FeatureCosts in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type FeatureCostConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<FeatureCostConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<FeatureCostConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<FeatureCostConnectionFilterInput>>;
  featureCode?: InputMaybe<ConnectionStringFilterInput>;
  projectSetting?: InputMaybe<ConnectionStringFilterInput>;
};

export type FeatureCostCost = {
  cost: Scalars['Float']['output'];
  moduleCode: ModuleCode;
};

export type FeatureCostCostInput = {
  cost: Scalars['Float']['input'];
  moduleCode: ModuleCode;
};

export type FeatureCostEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: FeatureCost;
};

export type FeatureCostOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order FeatureCosts by. */
  field: FeatureCostOrderField;
};

export enum FeatureCostOrderField {
  FeatureCode = 'featureCode',
  ProjectSetting = 'projectSetting'
}

export type FeatureFlag = {
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type FinishBuildInput = {
  buildId: Scalars['ObjectID']['input'];
  failedTestCount?: InputMaybe<Scalars['Int']['input']>;
  missingTestCount?: InputMaybe<Scalars['Int']['input']>;
  passed?: InputMaybe<Scalars['Boolean']['input']>;
  passedTestCount?: InputMaybe<Scalars['Int']['input']>;
  warnedTestCount?: InputMaybe<Scalars['Int']['input']>;
};

export type FinishBuildPayload = {
  build: Build;
};

export type GenerateLegacyTaskTicketDetailsInput = {
  crawlId?: InputMaybe<Scalars['ObjectID']['input']>;
  legacyTaskId: Scalars['ObjectID']['input'];
};

export type GenerateLegacyTaskTicketDetailsPayload = {
  legacyTask: LegacyTask;
};

export type GetCustomReportInput = {
  crawlId: Scalars['ObjectID']['input'];
  customReportTemplateId: Scalars['ObjectID']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type GetHealthScoreInput = {
  crawlId: Scalars['ObjectID']['input'];
  reportCategoryCode: Scalars['String']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type GetJiraIssueTypesInput = {
  jiraAuthenticationId: Scalars['ObjectID']['input'];
  jiraProjectId: Scalars['String']['input'];
};

export type GetJiraProjectsInput = {
  jiraAuthenticationId: Scalars['ObjectID']['input'];
  nameContains?: InputMaybe<Scalars['String']['input']>;
};

export type GetReportInputType = {
  crawlId: Scalars['ObjectID']['input'];
  reportTemplateCode: Scalars['String']['input'];
  reportTypeCode: ReportTypeCode;
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type GetReportStatInput = {
  crawlId: Scalars['ObjectID']['input'];
  reportTemplateCode: Scalars['String']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type GoogleAnalytics4ProjectProperty = {
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  googleConnection: GoogleConnection;
  id: Scalars['ObjectID']['output'];
  inheritFromSchedulePeriodOverride: Scalars['Boolean']['output'];
  lastNDays: Scalars['Int']['output'];
  minimumSessions: Scalars['Int']['output'];
  minimumViews: Scalars['Int']['output'];
  project: Project;
  propertyName: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  source: GoogleAnalytics4SourceType;
  updatedAt: Scalars['DateTime']['output'];
};

export type GoogleAnalytics4ProjectPropertyConnection = {
  /** A list of edges. */
  edges: Array<GoogleAnalytics4ProjectPropertyEdge>;
  /** A list of nodes. */
  nodes: Array<GoogleAnalytics4ProjectProperty>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of GoogleAnalytics4ProjectProperties in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type GoogleAnalytics4ProjectPropertyEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GoogleAnalytics4ProjectProperty;
};

export type GoogleAnalytics4Property = {
  displayName: Scalars['String']['output'];
  propertyName?: Maybe<Scalars['String']['output']>;
};

export enum GoogleAnalytics4SourceType {
  All = 'All',
  BingOrganicSearchLandingPages = 'BingOrganicSearchLandingPages',
  GoogleOrganicSearchLandingPages = 'GoogleOrganicSearchLandingPages',
  OrganicSearchLandingPages = 'OrganicSearchLandingPages'
}

export type GoogleAnalyticsView = {
  accountName?: Maybe<Scalars['String']['output']>;
  viewId?: Maybe<Scalars['String']['output']>;
  viewName?: Maybe<Scalars['String']['output']>;
  webPropertyId?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type GoogleConnection = {
  analytics4Properties?: Maybe<Array<GoogleAnalytics4Property>>;
  analyticsViews?: Maybe<Array<GoogleAnalyticsView>>;
  connectionType: GoogleConnectionType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  isWorking?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  scopes: Array<GoogleScope>;
  searchConsoleSites?: Maybe<Array<GoogleSearchConsoleSite>>;
  updatedAt: Scalars['DateTime']['output'];
  users: UserConnection;
};


export type GoogleConnectionUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserOrder>>;
};

export type GoogleConnectionConnection = {
  /** A list of edges. */
  edges: Array<GoogleConnectionEdge>;
  /** A list of nodes. */
  nodes: Array<GoogleConnection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of GoogleConnections in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type GoogleConnectionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GoogleConnection;
};

export enum GoogleConnectionType {
  ServiceAccount = 'ServiceAccount',
  Web = 'Web'
}

export type GoogleScope = {
  name: Scalars['String']['output'];
};

export type GoogleSearchConsoleConfiguration = {
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excludeQueries?: Maybe<Array<Scalars['String']['output']>>;
  includeQueries?: Maybe<Array<Scalars['String']['output']>>;
  inheritFromSchedulePeriodOverride: Scalars['Boolean']['output'];
  lastNDays: Scalars['Int']['output'];
  minClicks: Scalars['Int']['output'];
  searchType: GoogleSearchConsoleSearchType;
  updatedAt: Scalars['DateTime']['output'];
  useSearchConsolePropertyDomainsAsStartUrls: Scalars['Boolean']['output'];
};

export type GoogleSearchConsoleProperty = {
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  excludeQueries?: Maybe<Array<Scalars['String']['output']>>;
  googleConnection: GoogleConnection;
  id: Scalars['ObjectID']['output'];
  includeQueries?: Maybe<Array<Scalars['String']['output']>>;
  inheritFromSchedulePeriodOverride: Scalars['Boolean']['output'];
  lastNDays: Scalars['Int']['output'];
  minClicks: Scalars['Int']['output'];
  permissionLevel?: Maybe<Scalars['String']['output']>;
  project: Project;
  rawID: Scalars['String']['output'];
  searchType: GoogleSearchConsoleSearchType;
  siteUrl: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type GoogleSearchConsolePropertyConnection = {
  /** A list of edges. */
  edges: Array<GoogleSearchConsolePropertyEdge>;
  /** A list of nodes. */
  nodes: Array<GoogleSearchConsoleProperty>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of GoogleSearchConsoleProperties in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type GoogleSearchConsolePropertyEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GoogleSearchConsoleProperty;
};

export type GoogleSearchConsolePropertyOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order GoogleSearchConsoleProperties by. */
  field: GoogleSearchConsolePropertyOrderField;
};

export enum GoogleSearchConsolePropertyOrderField {
  CreatedAt = 'createdAt',
  SiteUrl = 'siteUrl',
  UpdatedAt = 'updatedAt'
}

export enum GoogleSearchConsoleSearchType {
  Image = 'Image',
  Video = 'Video',
  Web = 'Web'
}

export type GoogleSearchConsoleSite = {
  permissionLevel?: Maybe<Scalars['String']['output']>;
  siteUrl?: Maybe<Scalars['String']['output']>;
};

export type HealthScore = {
  categoryWeight: Scalars['Float']['output'];
  crawl: Crawl;
  crawlId: Scalars['ObjectID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  healthScore: Scalars['Float']['output'];
  project: Project;
  projectId: Scalars['ObjectID']['output'];
  reportCategory: ReportCategory;
  reportCategoryCode: Scalars['String']['output'];
  segment?: Maybe<Segment>;
  segmentId?: Maybe<Scalars['ObjectID']['output']>;
  segmentVersion?: Maybe<Scalars['Int']['output']>;
  trend: Array<HealthScoreTrendItem>;
};

export type HealthScoreTrendComputed = {
  absDiff?: Maybe<HealthScoreTrendItem>;
  diff?: Maybe<HealthScoreTrendItem>;
  last?: Maybe<HealthScoreTrendItem>;
};

export type HealthScoreTrendData = {
  computed: HealthScoreTrendComputed;
  points: Array<HealthScoreTrendItem>;
  reportCategoryCode: Scalars['String']['output'];
};

export type HealthScoreTrendItem = {
  categoryWeight: Scalars['Float']['output'];
  crawlId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  healthScore: Scalars['Float']['output'];
  segmentVersion?: Maybe<Scalars['Int']['output']>;
};

export type HealthScoreTrendsTableRow = {
  columns: Array<HealthScoreTrendData>;
  projectId: Scalars['Int']['output'];
  segmentId: Scalars['Int']['output'];
};

export type Industry = {
  code: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type IndustryBenchmark = {
  healthScore: Scalars['Float']['output'];
  industryCode: Scalars['String']['output'];
  reportCategoryCode: Scalars['String']['output'];
};

export type IndustryBenchmarkConnection = {
  /** A list of edges. */
  edges: Array<IndustryBenchmarkEdge>;
  /** A list of nodes. */
  nodes: Array<IndustryBenchmark>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of IndustryBenchmarks in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type IndustryBenchmarkConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<IndustryBenchmarkConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<IndustryBenchmarkConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<IndustryBenchmarkConnectionFilterInput>>;
  healthScore?: InputMaybe<ConnectionFloatFilterInput>;
  industryCode?: InputMaybe<ConnectionStringFilterInput>;
  reportCategoryCode?: InputMaybe<ConnectionStringFilterInput>;
};

export type IndustryBenchmarkEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IndustryBenchmark;
};

export type IndustryBenchmarkOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order IndustryBenchmarks by. */
  field: IndustryBenchmarkOrderField;
};

export enum IndustryBenchmarkOrderField {
  HealthScore = 'healthScore',
  IndustryCode = 'industryCode',
  ReportCategoryCode = 'reportCategoryCode'
}

export type IndustryConnection = {
  /** A list of edges. */
  edges: Array<IndustryEdge>;
  /** A list of nodes. */
  nodes: Array<Industry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Industries in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type IndustryConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<IndustryConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<IndustryConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<IndustryConnectionFilterInput>>;
  code?: InputMaybe<ConnectionStringFilterInput>;
};

export type IndustryEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Industry;
};

export type IndustryOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Industries by. */
  field: IndustryOrderField;
};

export enum IndustryOrderField {
  Code = 'code'
}

export type InternalAddAccountUserInput = {
  accountId: Scalars['ObjectID']['input'];
  roleCode: RoleCode;
  userEmail: Scalars['String']['input'];
};

export type InternalConnectChargebeeToAccountInput = {
  accountId: Scalars['ObjectID']['input'];
  chargebeeId: Scalars['String']['input'];
  chargebeeSiteRef: ChargebeeSiteRef;
};

export type InternalCreateAccountInput = {
  active?: Scalars['Boolean']['input'];
  aiFeaturesEnabled?: Scalars['Boolean']['input'];
  apiCallbackHeaders?: Array<ApiCallbackHeaderInput>;
  apiCallbackUrl?: InputMaybe<Scalars['String']['input']>;
  conductorExpiry?: InputMaybe<Scalars['DateTime']['input']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  contractTerminationDate?: InputMaybe<Scalars['DateTime']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  csPartnerName?: InputMaybe<Scalars['String']['input']>;
  customLogo?: InputMaybe<Scalars['Upload']['input']>;
  customProxy?: InputMaybe<Scalars['String']['input']>;
  disableUpselling?: Scalars['Boolean']['input'];
  limitLevelsMax?: Scalars['Int']['input'];
  limitPagesMax?: Scalars['Int']['input'];
  maxConcurrentCrawls?: Scalars['Int']['input'];
  maxCrawlRate?: Scalars['Int']['input'];
  maxCustomReportsPerProject?: Scalars['Int']['input'];
  maxDashboardCollectionViews?: Scalars['Int']['input'];
  maxGoogleSearchConsolePropertiesPerProject?: Scalars['Int']['input'];
  maxLegacyTasksPerProject?: Scalars['Int']['input'];
  maxProjectTests?: Scalars['Int']['input'];
  maxReportTemplateOverridesPerProject?: Scalars['Int']['input'];
  maxSegmentRestrictedFilterPredicates?: Scalars['Int']['input'];
  maximumRenderTimeout?: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  serpentVpcs?: InputMaybe<Array<Scalars['String']['input']>>;
  taggedTaskReportDownloadRowsLimit?: Scalars['Int']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
  userAgentSuffix?: InputMaybe<Scalars['String']['input']>;
};

export type InternalCreateAccountSsoClientInput = {
  accountId: Scalars['ObjectID']['input'];
  defaultRoleCode?: InputMaybe<RoleCode>;
  ssoClientAccountId?: InputMaybe<Scalars['Int']['input']>;
  ssoClientId: Scalars['String']['input'];
};

export type InternalCreateAccountSubscriptionInput = {
  accountId: Scalars['ObjectID']['input'];
  /** Full list of addons codes to be included with the account subscription. */
  addonCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  currency: Currency;
  planCode: Scalars['String']['input'];
  /** Full list of settings to be included with the account subscription plan. */
  planSettings?: InputMaybe<Array<AccountSubscriptionPlanSettingInput>>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type InternalCreateBuildForCrawlIfMissingPayload = {
  build?: Maybe<Build>;
  crawl: Crawl;
};

export type InternalCreateCreditAllocationInput = {
  accountId: Scalars['ObjectID']['input'];
  creditsAllocated: Scalars['Int']['input'];
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  type?: InputMaybe<CreditAllocationType>;
};

export type InternalCreateProxyInput = {
  enabled?: Scalars['Boolean']['input'];
  locationCode: LocationCode;
  url: Scalars['String']['input'];
};

export type InternalCreateSsoClientInput = {
  accountSubscriptionPlanCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  userAgentSuffix?: InputMaybe<Scalars['String']['input']>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type InternalCreateUserAgentInput = {
  code: Scalars['String']['input'];
  isDeprecated?: Scalars['Boolean']['input'];
  isMobile?: Scalars['Boolean']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  supportedModules?: InputMaybe<Array<ModuleCode>>;
  userAgentStrings: Array<UserAgentStringsInput>;
};

export type InternalDeleteAccountSsoClientInput = {
  accountId: Scalars['ObjectID']['input'];
  ssoClientId: Scalars['String']['input'];
};

export type InternalDeleteSegmentsPayload = {
  segments: Array<Segment>;
};

export type InternalGrantAdminPermissionPayload = {
  users: Array<User>;
};

export type InternalQueueBuildSchedulesPayload = {
  buildSchedules: Array<BuildSchedule>;
};

export type InternalReportCategoriesCsvExportPayload = {
  event: Scalars['JSONObject']['output'];
  /** Signed URL to download the CSV file. The file will be available once the export is complete. */
  signedUrl: Scalars['String']['output'];
};

export type InternalReportTemplatesCsvExportPayload = {
  event: Scalars['JSONObject']['output'];
  /** Signed URL to download the CSV file. The file will be available once the export is complete. */
  signedUrl: Scalars['String']['output'];
};

export type InternalRequestDatasourcesCsvExportPayload = {
  event: Scalars['JSONObject']['output'];
  /** Signed URL to download the CSV file. The file will be available once the export is complete. */
  signedUrl: Scalars['String']['output'];
};

export type InternalRequestFinishedCrawlsCsvExportPayload = {
  event: Scalars['JSONObject']['output'];
  /** Signed URL to download the CSV file. The file will be available once the export is complete. */
  signedUrl: Scalars['String']['output'];
};

export type InternalRequestMetricsCsvExportPayload = {
  event: Scalars['JSONObject']['output'];
  /** Signed URL to download the CSV file. The file will be available once the export is complete. */
  signedUrl: Scalars['String']['output'];
};

export type InternalRequestUserAgentsCsvExportPayload = {
  event: Scalars['JSONObject']['output'];
  /** Signed URL to download the CSV file. The file will be available once the export is complete. */
  signedUrl: Scalars['String']['output'];
};

export type InternalRunBuildScheduleInput = {
  buildScheduleRunId: Scalars['ObjectID']['input'];
};

export type InternalRunBuildSchedulePayload = {
  builds: Array<Build>;
};

export type InternalUpdateAccountSsoClientInput = {
  accountId: Scalars['ObjectID']['input'];
  defaultRoleCode?: InputMaybe<RoleCode>;
  ssoClientAccountId?: InputMaybe<Scalars['Int']['input']>;
  ssoClientId: Scalars['String']['input'];
};

export type InternalUpdateAccountSubscriptionInput = {
  accountId: Scalars['ObjectID']['input'];
  /** Full list of addons to be included with the account subscription. */
  addons?: InputMaybe<Array<AccountSubscriptionAddonInput>>;
  planCode?: InputMaybe<Scalars['String']['input']>;
  /** Full list of settings to be included with the account subscription plan. */
  planSettings?: InputMaybe<Array<AccountSubscriptionPlanSettingInput>>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type InternalUpdateCrawlInput = {
  crawlId: Scalars['ObjectID']['input'];
  totalStepsCrawled: Array<LevelProgressInput>;
};

export type InternalUpdateCrawlRateInput = {
  crawlId: Scalars['ObjectID']['input'];
  crawlRate: Scalars['Float']['input'];
};

export type InternalUpdateCreditAllocationInput = {
  creditAllocationId: Scalars['ObjectID']['input'];
  creditsAllocated?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<CreditAllocationType>;
};

export type InternalUpdateCustomMetricContainerVersionInput = {
  avroSchema?: InputMaybe<Scalars['JSONObject']['input']>;
  avroSchemas?: InputMaybe<Scalars['JSONObject']['input']>;
  customMetricContainerId: Scalars['ObjectID']['input'];
  entrypoint?: InputMaybe<Scalars['String']['input']>;
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  failureReason?: InputMaybe<Scalars['String']['input']>;
  fieldsMapping?: InputMaybe<Scalars['JSONObject']['input']>;
  fieldsMappings?: InputMaybe<Scalars['JSONObject']['input']>;
  handler?: InputMaybe<Scalars['String']['input']>;
  handlers?: InputMaybe<Scalars['JSONObject']['input']>;
  metricsMetadata?: InputMaybe<Scalars['JSONObject']['input']>;
  outputSchema?: InputMaybe<Scalars['JSONObject']['input']>;
  outputSchemaPath?: InputMaybe<Scalars['String']['input']>;
  outputSchemaPaths?: InputMaybe<Scalars['JSONObject']['input']>;
  outputSchemas?: InputMaybe<Scalars['JSONObject']['input']>;
  processedAt?: InputMaybe<Scalars['DateTime']['input']>;
  processingAt?: InputMaybe<Scalars['DateTime']['input']>;
  resourceTypes?: InputMaybe<Array<CustomMetricContainerResourceType>>;
  s3Location?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CustomMetricContainerVersionStatus>;
  version: Scalars['Int']['input'];
};

export type InternalUpdateCustomMetricContainerVersionPayload = {
  customMetricContainerVersion: CustomMetricContainerVersion;
};

export type InternalUpdateModuleInput = {
  code: ModuleCode;
  serpentCrawlerQueryGraphType?: InputMaybe<SerpentCrawlerQueryGraphType>;
  userAgentSuffixEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** @deprecated Split into separate viewportHeightDesktop and viewportHeightMobile fields. */
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportHeightDesktop?: InputMaybe<Scalars['Int']['input']>;
  viewportHeightMobile?: InputMaybe<Scalars['Int']['input']>;
  viewportWidthDesktop?: InputMaybe<Scalars['Int']['input']>;
  viewportWidthMobile?: InputMaybe<Scalars['Int']['input']>;
};

export type InternalUpdateProxyInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  proxyId: Scalars['ObjectID']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type InternalUpdateReportTemplateInput = {
  admin?: InputMaybe<Scalars['Boolean']['input']>;
  aggregate?: InputMaybe<Scalars['String']['input']>;
  automatorEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  beta?: InputMaybe<Scalars['Boolean']['input']>;
  categoriesRaw?: InputMaybe<Scalars['String']['input']>;
  changeSign?: InputMaybe<Scalars['Int']['input']>;
  changeWeight?: InputMaybe<Scalars['Float']['input']>;
  code: Scalars['String']['input'];
  default?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<UpdateReportTemplateMetadataInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  newMetricsGrouping?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  queryVersion?: InputMaybe<Scalars['Int']['input']>;
  reportTypes?: InputMaybe<Array<ReportTypeCode>>;
  scopeReportTemplateCode?: InputMaybe<Scalars['String']['input']>;
  supportedModules?: InputMaybe<Array<ModuleCode>>;
  tagsRaw?: InputMaybe<Scalars['String']['input']>;
  totalSign?: InputMaybe<Scalars['Int']['input']>;
  totalWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type InternalUpdateSsoClientInput = {
  accountSubscriptionPlanCodes?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  userAgentSuffix?: InputMaybe<Scalars['String']['input']>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type InternalUpdateSinglePageRequesterRequestInput = {
  outputs?: InputMaybe<Scalars['JSONObject']['input']>;
  requestId: Scalars['String']['input'];
  status?: InputMaybe<SinglePageRequesterRequestStatus>;
};

export type InternalUpdateSinglePageRequesterRequestPayload = {
  singlePageRequesterRequest: SinglePageRequesterRequest;
};

export type InternalUpdateUrlFileUploadInput = {
  errorMessage?: InputMaybe<Scalars['String']['input']>;
  erroredAt?: InputMaybe<Scalars['DateTime']['input']>;
  totalRows?: InputMaybe<Scalars['Int']['input']>;
  transformedAt?: InputMaybe<Scalars['DateTime']['input']>;
  transformingAt?: InputMaybe<Scalars['DateTime']['input']>;
  urlFileUploadId: Scalars['ObjectID']['input'];
};

export type InternalUpdateUserAgentInput = {
  code: Scalars['String']['input'];
  isDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
  isMobile?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  supportedModules?: InputMaybe<Array<ModuleCode>>;
  userAgentStrings?: InputMaybe<Array<UserAgentStringsInput>>;
};

export type InternalUpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  emailValidatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  limitLevelsMax?: InputMaybe<Scalars['Int']['input']>;
  limitPagesMax?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ObjectID']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type JiraAuthentication = {
  cloudId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  jiraIntegrations: JiraIntegrationConnection;
  jiraUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type JiraAuthenticationJiraIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type JiraAuthenticationConnection = {
  /** A list of edges. */
  edges: Array<JiraAuthenticationEdge>;
  /** A list of nodes. */
  nodes: Array<JiraAuthentication>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of JiraAuthentications in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type JiraAuthenticationEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: JiraAuthentication;
};

export type JiraIntegration = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  jiraIssueTypeId: Scalars['String']['output'];
  jiraIssueTypeName: Scalars['String']['output'];
  jiraProjectId: Scalars['String']['output'];
  jiraProjectName: Scalars['String']['output'];
  jiraUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  testSuiteJiraIntegrations: TestSuiteJiraIntegrationConnection;
  updatedAt: Scalars['DateTime']['output'];
};


export type JiraIntegrationTestSuiteJiraIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type JiraIntegrationConnection = {
  /** A list of edges. */
  edges: Array<JiraIntegrationEdge>;
  /** A list of nodes. */
  nodes: Array<JiraIntegration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of JiraIntegrations in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type JiraIntegrationEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: JiraIntegration;
};

export type JiraIssueType = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type JiraProject = {
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type JiraSettings = {
  /** Authentication callback URL set up on Atlassian developer console for the current environment. */
  authCallbackUrl: Scalars['String']['output'];
  /** Client ID set up on Atlassian developer console for the current environment. */
  clientId: Scalars['String']['output'];
};

export type LanguageSummary = {
  language: Scalars['String']['output'];
  percentage: Scalars['Float']['output'];
};

export type LegacyReportRowConnection = {
  /** A list of edges. */
  edges: Array<LegacyReportRowEdge>;
  /** A list of nodes. */
  nodes: Array<Scalars['JSONObject']['output']>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of LegacyReportRows in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type LegacyReportRowEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: Scalars['JSONObject']['output'];
};

export type LegacyTask = {
  actions?: Maybe<Scalars['String']['output']>;
  assignedTo?: Maybe<Array<Scalars['String']['output']>>;
  connectionFilter?: Maybe<Scalars['JSONObject']['output']>;
  crawlId?: Maybe<Scalars['ObjectID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customReportTemplates: CustomReportTemplateConnection;
  deadlineAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discoveredAt?: Maybe<Scalars['DateTime']['output']>;
  dismissed: Scalars['Boolean']['output'];
  filters?: Maybe<Scalars['JSONObject']['output']>;
  fixedAt?: Maybe<Scalars['DateTime']['output']>;
  howToFix?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  identified?: Maybe<Scalars['Int']['output']>;
  priority: LegacyTaskPriority;
  project: Project;
  rawID: Scalars['String']['output'];
  remaining?: Maybe<Scalars['Int']['output']>;
  reportTemplate?: Maybe<ReportTemplate>;
  reportTemplateId?: Maybe<Scalars['Int']['output']>;
  reportType?: Maybe<ReportType>;
  reportTypeId?: Maybe<Scalars['Int']['output']>;
  segment?: Maybe<Segment>;
  status?: Maybe<LegacyTaskStatus>;
  ticketCrawlId?: Maybe<Scalars['Int']['output']>;
  ticketDetails?: Maybe<Scalars['JSONObject']['output']>;
  ticketGenerationFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  ticketGenerationRequestedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  trend?: Maybe<Array<LegacyTaskTrendEntry>>;
  updatedAt: Scalars['DateTime']['output'];
};


export type LegacyTaskCustomReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomReportTemplateConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomReportTemplateOrder>>;
};

export type LegacyTaskConnection = {
  /** A list of edges. */
  edges: Array<LegacyTaskEdge>;
  /** A list of nodes. */
  nodes: Array<LegacyTask>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of LegacyTasks in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type LegacyTaskConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<LegacyTaskConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<LegacyTaskConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<LegacyTaskConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  deadlineAt?: InputMaybe<ConnectionDateFilterInput>;
  description?: InputMaybe<ConnectionStringFilterInput>;
  discoveredAt?: InputMaybe<ConnectionDateFilterInput>;
  dismissed?: InputMaybe<ConnectionBooleanFilterInput>;
  fixedAt?: InputMaybe<ConnectionDateFilterInput>;
  identified?: InputMaybe<ConnectionIntFilterInput>;
  priority?: InputMaybe<ConnectionLegacyTaskPriorityFilterInput>;
  remaining?: InputMaybe<ConnectionIntFilterInput>;
  segmentId?: InputMaybe<ConnectionObjectIdFilterInput>;
  status?: InputMaybe<ConnectionLegacyTaskStatusFilterInput>;
  title?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type LegacyTaskEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LegacyTask;
};

export type LegacyTaskOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order LegacyTasks by. */
  field: LegacyTaskOrderField;
};

export enum LegacyTaskOrderField {
  CreatedAt = 'createdAt',
  DeadlineAt = 'deadlineAt',
  Description = 'description',
  DiscoveredAt = 'discoveredAt',
  FixedAt = 'fixedAt',
  Id = 'id',
  Identified = 'identified',
  Priority = 'priority',
  Remaining = 'remaining',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export enum LegacyTaskPriority {
  Critical = 'Critical',
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  Note = 'Note'
}

export enum LegacyTaskStatus {
  Backlog = 'Backlog',
  Done = 'Done',
  InProgress = 'InProgress',
  Testing = 'Testing',
  ToDo = 'ToDo'
}

export type LegacyTaskTrendEntry = {
  crawlFinishedAt: Scalars['DateTime']['output'];
  crawlId?: Maybe<Scalars['ObjectID']['output']>;
  identified?: Maybe<Scalars['Int']['output']>;
};

export type LevelProgress = {
  level?: Maybe<Scalars['Int']['output']>;
  stepsProcessed: Scalars['Int']['output'];
};

export type LevelProgressInput = {
  level?: InputMaybe<Scalars['Int']['input']>;
  stepsProcessed: Scalars['Int']['input'];
};

export type LinkChildTestSuiteToParentTestSuiteInput = {
  childTestSuiteId: Scalars['ObjectID']['input'];
  parentTestSuiteId: Scalars['ObjectID']['input'];
};

export type LinkChildTestSuiteToParentTestSuitePayload = {
  childTestSuite: TestSuite;
  parentTestSuite: TestSuite;
};

export type LinkCustomMetricContainerToProjectInput = {
  /** Parameters that will be passed as part of the context when a container gets executed. */
  containerParams?: InputMaybe<Scalars['JSONObject']['input']>;
  customJsResources?: InputMaybe<Array<Scalars['String']['input']>>;
  customJsScripts?: InputMaybe<Array<Scalars['String']['input']>>;
  customMetricContainerId: Scalars['ObjectID']['input'];
  enabled?: Scalars['Boolean']['input'];
  projectId: Scalars['ObjectID']['input'];
};

export type LinkCustomMetricContainerToProjectPayload = {
  customMetricContainer: CustomMetricContainer;
  customMetricContainerProject: CustomMetricContainerProject;
  project: Project;
};

export type LinkJiraIntegrationToTestSuiteInput = {
  jiraIntegrationId: Scalars['ObjectID']['input'];
  testSuiteId: Scalars['ObjectID']['input'];
};

export type LinkJiraIntegrationToTestSuitePayload = {
  testSuiteJiraIntegration: TestSuiteJiraIntegration;
};

export type LinkTestSuiteToBuildScheduleInput = {
  buildScheduleId: Scalars['ObjectID']['input'];
  testSuiteId: Scalars['ObjectID']['input'];
};

export type LinkTestSuiteToBuildSchedulePayload = {
  buildScheduleTestSuite: BuildScheduleTestSuite;
};

export type Location = {
  code: LocationCode;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  type: LocationType;
};

export enum LocationCode {
  Australia = 'Australia',
  Austria = 'Austria',
  Belgium = 'Belgium',
  Brazil = 'Brazil',
  Canada = 'Canada',
  China = 'China',
  Custom = 'Custom',
  Default = 'Default',
  Egypt = 'Egypt',
  France = 'France',
  Germany = 'Germany',
  Global = 'Global',
  GreatBritain = 'GreatBritain',
  India = 'India',
  Italy = 'Italy',
  Japan = 'Japan',
  Mexico = 'Mexico',
  Netherlands = 'Netherlands',
  Pakistan = 'Pakistan',
  Singapore = 'Singapore',
  Spain = 'Spain',
  Switzerland = 'Switzerland',
  Turkey = 'Turkey',
  UnitedStates = 'UnitedStates',
  Uzbekistan = 'Uzbekistan'
}

export type LocationConnection = {
  /** A list of edges. */
  edges: Array<LocationEdge>;
  /** A list of nodes. */
  nodes: Array<Location>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Locations in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type LocationEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Location;
};

export enum LocationType {
  Country = 'Country',
  CustomIp = 'CustomIP',
  DynamicIp = 'DynamicIP',
  Global = 'Global',
  StaticIp = 'StaticIP'
}

export enum LogzioApiRegion {
  AsiaPacificSydney = 'AsiaPacificSydney',
  CanadaCentral = 'CanadaCentral',
  EuropeFrankfurt = 'EuropeFrankfurt',
  EuropeLondon = 'EuropeLondon',
  UsEastNorthernVirginia = 'USEastNorthernVirginia',
  WestEuropeNetherlands = 'WestEuropeNetherlands',
  WestUs2Washington = 'WestUS2Washington'
}

export type LogzioConnection = {
  createdAt: Scalars['DateTime']['output'];
  /** Basic information about the User who created the LogzioConnection. */
  createdByUser: UserInfo;
  id: Scalars['ObjectID']['output'];
  isWorking: Scalars['Boolean']['output'];
  label?: Maybe<Scalars['String']['output']>;
  logzioProjectQueries: LogzioProjectQueryConnection;
  rawID: Scalars['String']['output'];
  region: LogzioApiRegion;
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};


export type LogzioConnectionLogzioProjectQueriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LogzioProjectQueryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LogzioProjectQueryOrder>>;
};

export type LogzioConnectionConnection = {
  /** A list of edges. */
  edges: Array<LogzioConnectionEdge>;
  /** A list of nodes. */
  nodes: Array<LogzioConnection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of LogzioConnections in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type LogzioConnectionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<LogzioConnectionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<LogzioConnectionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<LogzioConnectionConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  label?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  userId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type LogzioConnectionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LogzioConnection;
};

export type LogzioConnectionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order LogzioConnections by. */
  field: LogzioConnectionOrderField;
};

export enum LogzioConnectionOrderField {
  CreatedAt = 'createdAt',
  Label = 'label',
  UpdatedAt = 'updatedAt'
}

export type LogzioProjectQuery = {
  baseUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dateRange: Scalars['Int']['output'];
  desktopUaNotRegexp?: Maybe<Scalars['String']['output']>;
  desktopUaRegexp: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ObjectID']['output'];
  logzioConnection?: Maybe<LogzioConnection>;
  maxRows: Scalars['Int']['output'];
  mobileUaNotRegexp?: Maybe<Scalars['String']['output']>;
  mobileUaRegexp: Scalars['String']['output'];
  pathFieldName: Scalars['String']['output'];
  project: Project;
  queryFilter?: Maybe<Scalars['JSONObject']['output']>;
  rawID: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useLastCrawlDate: Scalars['Boolean']['output'];
  userAgentFieldName: Scalars['String']['output'];
};

export type LogzioProjectQueryConnection = {
  /** A list of edges. */
  edges: Array<LogzioProjectQueryEdge>;
  /** A list of nodes. */
  nodes: Array<LogzioProjectQuery>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of LogzioProjectQueries in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type LogzioProjectQueryConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<LogzioProjectQueryConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<LogzioProjectQueryConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<LogzioProjectQueryConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  dateRange?: InputMaybe<ConnectionIntFilterInput>;
  enabled?: InputMaybe<ConnectionBooleanFilterInput>;
  maxRows?: InputMaybe<ConnectionIntFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  useLastCrawlDate?: InputMaybe<ConnectionBooleanFilterInput>;
};

export type LogzioProjectQueryEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LogzioProjectQuery;
};

export type LogzioProjectQueryOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order LogzioProjectQueries by. */
  field: LogzioProjectQueryOrderField;
};

export enum LogzioProjectQueryOrderField {
  CreatedAt = 'createdAt',
  DateRange = 'dateRange',
  Enabled = 'enabled',
  MaxRows = 'maxRows',
  UpdatedAt = 'updatedAt',
  UseLastCrawlDate = 'useLastCrawlDate'
}

export type MajesticConfiguration = {
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  maxRows: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useHistoricData: Scalars['Boolean']['output'];
  useRootDomain: Scalars['Boolean']['output'];
};

export type MarkCrawlAsPopulatedInput = {
  id: Scalars['ObjectID']['input'];
  postCrawlPopulatedAt: Scalars['DateTime']['input'];
};

export type MarkCrawlAsPopulatedPayload = {
  crawl: Crawl;
};

export type MarkReportDownloadAsGeneratedInput = {
  id: Scalars['ObjectID']['input'];
  outputLocation?: InputMaybe<Scalars['String']['input']>;
  reportedAt: Scalars['DateTime']['input'];
};

export type MarkReportDownloadAsGeneratedPayload = {
  reportDownload: ReportDownload;
};

export type MarkReportDownloadAsGeneratingInput = {
  id: Scalars['ObjectID']['input'];
  reportingAt: Scalars['DateTime']['input'];
};

export type MarkReportDownloadAsGeneratingPayload = {
  reportDownload: ReportDownload;
};

export type Metric = {
  changedMetricCode?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  connectionPredicates: Array<ConnectionPredicateMetadata>;
  datasourceCode: DatasourceCode;
  description: Scalars['String']['output'];
  internal: Scalars['Boolean']['output'];
  metadata?: Maybe<MetricMetadata>;
  name: Scalars['String']['output'];
  rawChangedMetricCode?: Maybe<Scalars['String']['output']>;
  rawCode: Scalars['String']['output'];
  supportedModules?: Maybe<Array<ModuleCode>>;
  type: MetricType;
};

export type MetricConnection = {
  /** A list of edges. */
  edges: Array<MetricEdge>;
  /** A list of nodes. */
  nodes: Array<Metric>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Metrics in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type MetricConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<MetricConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<MetricConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<MetricConnectionFilterInput>>;
  datasourceCode?: InputMaybe<ConnectionDatasourceCodeFilterInput>;
  internal?: InputMaybe<ConnectionBooleanFilterInput>;
  rawChangedMetricCode?: InputMaybe<ConnectionStringFilterInput>;
  rawCode?: InputMaybe<ConnectionStringFilterInput>;
  type?: InputMaybe<ConnectionMetricTypeFilterInput>;
};

export type MetricEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Metric;
};

export type MetricMetadata = {
  abbreviation?: Maybe<Scalars['String']['output']>;
  availableFromTableTypeVersion?: Maybe<Scalars['Int']['output']>;
  crawlType?: Maybe<CrawlType>;
  digestMetric?: Maybe<Scalars['String']['output']>;
  items?: Maybe<MetricMetadataItems>;
  jsonSchema?: Maybe<Scalars['JSONObject']['output']>;
  rendererOnly?: Maybe<Scalars['Boolean']['output']>;
  reportTemplateCode?: Maybe<Scalars['String']['output']>;
  siteSpeedScoring?: Maybe<MetricMetadataSiteSpeedScoring>;
  stringType?: Maybe<Scalars['String']['output']>;
  thresholds?: Maybe<MetricMetadataThresholds>;
  unit?: Maybe<Scalars['String']['output']>;
};

export type MetricMetadataItems = {
  type?: Maybe<Scalars['String']['output']>;
};

export type MetricMetadataSiteSpeedScoring = {
  desktop: MetricMetadataSiteSpeedScoringDetails;
  mobile: MetricMetadataSiteSpeedScoringDetails;
};

export type MetricMetadataSiteSpeedScoringDetails = {
  median: Scalars['Float']['output'];
  p10: Scalars['Float']['output'];
};

export type MetricMetadataThresholds = {
  fast: Scalars['String']['output'];
  medium: Scalars['String']['output'];
  slow: Scalars['String']['output'];
};

export type MetricOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Metrics by. */
  field: MetricOrderField;
};

export enum MetricOrderField {
  DatasourceCode = 'datasourceCode',
  Internal = 'internal',
  Type = 'type'
}

export enum MetricType {
  Array = 'Array',
  Bit = 'Bit',
  Boolean = 'Boolean',
  Date = 'Date',
  Decimal = 'Decimal',
  Integer = 'Integer',
  Json = 'JSON',
  String = 'String',
  StringifiedJson = 'StringifiedJSON'
}

export type MetricsGrouping = {
  metrics: Array<Metric>;
};

export enum MetricsServerId {
  ElasticSearch7Cluster1 = 'ElasticSearch7Cluster1'
}

export type Module = {
  code: ModuleCode;
  customMetricContainers?: Maybe<Array<CustomMetricContainer>>;
  datasourceCodes?: Maybe<Array<DatasourceCode>>;
  datasources?: Maybe<Array<Datasource>>;
  reportTemplates?: Maybe<Array<ReportTemplate>>;
  serpentCrawlerQueryGraphType: SerpentCrawlerQueryGraphType;
  userAgentSuffixEnabled: Scalars['Boolean']['output'];
  viewportHeightDesktop: Scalars['Int']['output'];
  viewportHeightMobile: Scalars['Int']['output'];
  viewportWidthDesktop: Scalars['Int']['output'];
  viewportWidthMobile: Scalars['Int']['output'];
};

export enum ModuleCode {
  Accessibility = 'Accessibility',
  Basic = 'Basic',
  Seo = 'SEO',
  SiteSpeed = 'SiteSpeed'
}

export type ModuleConnection = {
  /** A list of edges. */
  edges: Array<ModuleEdge>;
  /** A list of nodes. */
  nodes: Array<Module>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Modules in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ModuleEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Module;
};

export type MoveCustomDashboardCollectionInput = {
  customDashboardCollectionId: Scalars['ObjectID']['input'];
  toPosition: Scalars['Int']['input'];
};

export type MoveCustomDashboardCollectionPayload = {
  customDashboardCollection: CustomDashboardCollection;
};

export type Mutation = {
  abortBuild: AbortBuildPayload;
  acceptSuggestedThreshold: Array<UpdateTestResultPayload>;
  acceptTermsAndConditions: User;
  /** Adds username+password user to account. */
  addAccountUser: AddAccountUserPayload;
  addCustomDashboardsToCollection: UpdateCustomDashboardCollectionPayload;
  addCustomSitemaps: AddCustomSitemapsPayload;
  addCustomViewsToCustomDashboard: AddCustomViewsToCustomDashboardPayload;
  addCustomViewsToCustomDashboardCollection: AddCustomViewsToCustomDashboardCollectionPayload;
  bulkAcceptSuggestedThresholds: BulkProcessSuggestedThresholdsPayload;
  bulkRejectSuggestedThresholds: BulkProcessSuggestedThresholdsPayload;
  cancelBuild: CancelBuildPayload;
  /** Cancels crawling. */
  cancelCrawling: CancelCrawlingPayload;
  checkDomain: CheckDomainPayload;
  cloneProject: CloneProjectPayload;
  cloneTestSuite: CloneTestSuitePayload;
  confirmEmail: ConfirmEmailPayload;
  convertAccountSubscriptionToChargebee: ConvertAccountSubscriptionToChargebeePayload;
  copyCustomDashboard: CreateCustomDashboardPayload;
  copyCustomDashboardCollection: CreateCustomDashboardCollectionPayload;
  /** Copies non-segmented and non custom-extraction Tests from one Project or TestSuite to another. Existing tests with the same reportTemplateCode will be overwritten */
  copyTests: CopyTestsPayload;
  createAccessibilityIssueSolutionSuggestion?: Maybe<CreateAccessibilityIssueSolutionSuggestionPayload>;
  createAccessibilityProject: CreateProjectPayload;
  createAccessibilityTestSuite: CreateTestSuitePayload;
  createAdobeAnalyticsProjectReportSuite: CreateAdobeAnalyticsProjectReportSuitePayload;
  createAdobeConnection: CreateAdobeConnectionPayload;
  createAdobeJwtConnection: CreateAdobeJwtConnectionPayload;
  /** Creates a build and immediately runs a crawl for it. */
  createAndRunBuild: CreateAndRunBuildPayload;
  /** Creates a build of trigger type Manual and immediately runs a crawl for it. */
  createAndRunManualBuild: CreateAndRunBuildPayload;
  /** Generates auth token, that has 120 days expiration time. */
  createAutomatorAuthToken: AuthToken;
  createAutomatorSlackWebhook: CreateSlackWebhookPayload;
  createBasicProject: CreateProjectPayload;
  createBuildSchedule: CreateBuildSchedulePayload;
  createCrawlSegment: CreateCrawlSegmentPayload;
  createCrawlUrlSegment: CreateCrawlUrlSegmentPayload;
  createCreditAllocationForSsoAccount: CreateCreditAllocationForSsoAccountPayload;
  createCreditReportExport: CreateCreditReportExportPayload;
  createCustomChart: CreateCustomChartPayload;
  createCustomDashboard: CreateCustomDashboardPayload;
  createCustomDashboardCollection: CreateCustomDashboardCollectionPayload;
  createCustomMetricContainer: CreateCustomMetricContainerPayload;
  createCustomMetricContainerVersion: CreateCustomMetricContainerVersionPayload;
  createCustomMetricUploadContainer: CreateCustomMetricUploadContainerPayload;
  createCustomReportTemplate: CreateCustomReportTemplatePayload;
  createCustomTable: CreateCustomTablePayload;
  createCustomView: CreateCustomViewsPayload;
  createGoogleAnalytics4ProjectProperty: CreateGoogleAnalytics4ProjectPropertyPayload;
  /** Creates a Google Analytics connection. */
  createGoogleConnection: CreateGoogleConnectionPayload;
  createGoogleSearchConsoleConfiguration: CreateGoogleSearchConsoleConfigurationPayload;
  createGoogleSearchConsoleProperty: CreateGoogleSearchConsolePropertyPayload;
  createJiraAuthentication: CreateJiraAuthenticationPayload;
  createJiraIntegration: CreateJiraIntegrationPayload;
  createLogzioConnection: CreateLogzioConnectionPayload;
  createLogzioProjectQuery: CreateLogzioProjectQueryPayload;
  createMajesticConfiguration: CreateMajesticConfigurationPayload;
  /** @deprecated Use createSEOProject instead */
  createProject: CreateProjectPayload;
  /** @deprecated Use createReportLegacyTask instead */
  createProjectLegacyTask: CreateLegacyTaskPayload;
  createReportDownload: CreateReportDownloadPayload;
  /** @deprecated Use createReportStatLegacyTask */
  createReportLegacyTask: CreateLegacyTaskPayload;
  createReportStatLegacyTask: CreateLegacyTaskPayload;
  createReportTemplateOverride: CreateReportTemplateOverridePayload;
  createSEOProject: CreateProjectPayload;
  createSchedule: CreateSchedulePayload;
  /** Creates new session using UserKey's id and secret. */
  createSessionUsingUserKey: Session;
  /**
   * Creates new session using username and password.
   * @deprecated To authenticate with API use `createSessionUsingUserKey` mutation instead.
   */
  createSessionUsingUsername: Session;
  createSignedUrlFileUpload: CreateSignedUrlFileUploadPayload;
  createSinglePageRequesterRequest?: Maybe<CreateSinglePageRequesterRequestPayload>;
  createSiteSpeedProject: CreateProjectPayload;
  createSiteSpeedTestSuite: CreateTestSuitePayload;
  createSplunkConnection: CreateSplunkConnectionPayload;
  createSplunkProjectQuery: CreateSplunkProjectQueryPayload;
  createSsoAccount: CreateSsoAccountPayload;
  createSsoAccountSubscription: CreateSsoAccountSubscriptionPayload;
  createTest: CreateTestPayload;
  createTestResult: CreateTestResultPayload;
  createTestSuite: CreateTestSuitePayload;
  createTestSuiteEmailAlert: CreateTestSuiteEmailAlertPayload;
  createTests: CreateTestsPayload;
  /** Invites a user via email to create SSO user on Lumar platform. */
  createUserInvite: CreateUserInvitePayload;
  /** Generates a new personal API key for authentication. */
  createUserKey: UserKey;
  createWebhook: CreateWebhookPayload;
  deleteAccountUser: DeleteAccountUserPayload;
  deleteAdobeAnalyticsProjectReportSuite: DeleteAdobeAnalyticsProjectReportSuitePayload;
  deleteAdobeConnection: DeleteAdobeConnectionPayload;
  deleteAdobeJwtConnection: DeleteAdobeJwtConnectionPayload;
  deleteAutomatorSlackWebhook: DeleteSlackWebhookPayload;
  deleteBuildSchedule: DeleteBuildSchedulePayload;
  deleteCrawl: DeleteCrawlPayload;
  deleteCustomChart: DeleteCustomChartPayload;
  deleteCustomDashboard: DeleteCustomDashboardPayload;
  deleteCustomDashboardCollection: DeleteCustomDashboardCollectionPayload;
  deleteCustomMetricContainer: DeleteCustomMetricContainerPayload;
  deleteCustomMetricContainerProjectSecret: DeleteCustomMetricContainerProjectSecretPayload;
  deleteCustomReportTemplate: DeleteCustomReportTemplatePayload;
  deleteCustomTable: DeleteCustomTablePayload;
  deleteCustomViews: DeleteCustomViewsPayload;
  deleteGoogleAnalytics4ProjectProperty: DeleteGoogleAnalytics4ProjectPropertyPayload;
  deleteGoogleConnection: DeleteGoogleConnectionPayload;
  deleteGoogleSearchConsoleConfiguration: DeleteGoogleSearchConsoleConfigurationPayload;
  deleteGoogleSearchConsoleProperty: DeleteGoogleSearchConsolePropertyPayload;
  deleteJiraAuthentication: DeleteJiraAuthenticationPayload;
  deleteJiraIntegration: DeleteJiraIntegrationPayload;
  deleteLegacyTask: DeleteLegacyTaskPayload;
  deleteLogzioConnection: DeleteLogzioConnectionPayload;
  deleteLogzioProjectQuery: DeleteLogzioProjectQueryPayload;
  deleteProject: DeleteProjectPayload;
  deleteReportDownload: DeleteReportDownloadPayload;
  deleteReportTemplateOverride: DeleteReportTemplateOverridePayload;
  deleteSchedule: DeleteSchedulePayload;
  deleteSegment: DeleteSegmentPayload;
  /** Destroys current session. */
  deleteSession: Session;
  deleteSitemap: DeleteSitemapPayload;
  deleteSplunkConnection: DeleteSplunkConnectionPayload;
  deleteSplunkProjectQuery: DeleteSplunkProjectQueryPayload;
  deleteTest: DeleteTestPayload;
  deleteTestSuite: DeleteTestSuitePayload;
  deleteTestSuiteEmailAlert: DeleteTestSuiteEmailAlertPayload;
  deleteTests: DeleteTestsPayload;
  deleteUrlFileUpload: DeleteUrlFileUploadPayload;
  deleteUserKey: DeleteUserKeyPayload;
  deleteWebhook: DeleteWebhookPayload;
  disableSitemap: DisableSitemapPayload;
  disableSitemaps: DisableSitemapsPayload;
  enableSitemap: EnableSitemapPayload;
  enableSitemaps: EnableSitemapsPayload;
  findOrCreateCustomView: CreateCustomViewsPayload;
  finishBuild: FinishBuildPayload;
  generateLegacyTaskTicketDetails: GenerateLegacyTaskTicketDetailsPayload;
  /** Adds username+password User to the Account. */
  internalAddAccountUser: Account;
  /** Transitions a Crawl to the archived state. */
  internalArchiveCrawl: Crawl;
  internalChangeCrawlArchiving: Crawl;
  internalConnectChargebeeToAccount: Account;
  internalCreateAccount: Account;
  internalCreateAccountSsoClient: AccountSsoClient;
  /** Create an AccountSubscription with an AccountSubscriptionPlan */
  internalCreateAccountSubscription: AccountSubscription;
  internalCreateBuildForCrawlIfMissing?: Maybe<InternalCreateBuildForCrawlIfMissingPayload>;
  internalCreateChatCompletion: Scalars['JSONObject']['output'];
  internalCreateCrawlSegments: Array<CrawlSegment>;
  internalCreateCreditAllocation: CreditAllocation;
  /** @deprecated No longer in used. */
  internalCreateDefaultCustomMetricContainer: DefaultCustomMetricContainer;
  internalCreateFeatureCost: FeatureCost;
  internalCreateOrUpdateCreditReportForCrawl?: Maybe<CreditReport>;
  /** Creates or updates Datasources from CSV file. */
  internalCreateOrUpdateDatasources: Scalars['Boolean']['output'];
  /** Creates or updates Industries based on the code. Returns the number of affected database rows. */
  internalCreateOrUpdateIndustries: Scalars['Int']['output'];
  /** Creates or updates Industries based on the industryCode and reportCategoryCode. Returns the number of affected database rows. */
  internalCreateOrUpdateIndustryBenchmarks: Scalars['Int']['output'];
  /** Creates or updates Metrics from CSV file. */
  internalCreateOrUpdateMetrics: Scalars['Boolean']['output'];
  /** Creates or updates ReportCategories based on the code. Returns the number of affected database rows. */
  internalCreateOrUpdateReportCategories: Scalars['Int']['output'];
  /** Creates or updates UserAgents from CSV file. */
  internalCreateOrUpdateUserAgents: Scalars['Int']['output'];
  internalCreateProxy: Proxy;
  /** Creates ReportTemplates from CSV file. */
  internalCreateReportTemplates: Array<ReportTemplate>;
  internalCreateResetPasswordToken: ResetPasswordToken;
  internalCreateSsoClient: SsoClient;
  internalCreateSystemSetting: SystemSetting;
  internalCreateUserAgent: UserAgent;
  internalDeleteAccount: Account;
  internalDeleteAccountSsoClient: AccountSsoClient;
  /** @deprecated No longer in used. */
  internalDeleteDefaultCustomMetricContainer: DefaultCustomMetricContainer;
  internalDeleteFeatureCost: FeatureCost;
  /** Returns the number of affected database rows. */
  internalDeleteIndustries: Scalars['Int']['output'];
  internalDeleteIndustryBenchmark: IndustryBenchmark;
  internalDeleteProxy: Proxy;
  /** Returns the number of affected database rows. */
  internalDeleteReportCategories: Scalars['Int']['output'];
  internalDeleteSegments: InternalDeleteSegmentsPayload;
  internalDeleteSystemSetting: SystemSetting;
  internalDeleteUser: User;
  internalDisconnectChargebeeFromAccount: Account;
  internalEnqueueCrawl: Crawl;
  /** Emits the CrawlCacheExpired event and returns the details. */
  internalExpireCrawlCache: Scalars['JSONObject']['output'];
  /** @deprecated AdminGrant permission is no longer in use. */
  internalGrantAdminPermission: InternalGrantAdminPermissionPayload;
  internalHardDeleteCrawl: Crawl;
  internalHardDeleteProject: Project;
  /** Deletes Segments from the database and returns the number of affected rows. */
  internalHardDeleteSegments: Scalars['Int']['output'];
  /** Transitions a Crawl to paused state. */
  internalMarkCrawlAsPaused: Crawl;
  /** Sets timestamps required for populating a Crawl. Needed by Serpent Crawls. */
  internalMarkCrawlAsPopulateReady: Crawl;
  internalMarkCrawlAsPopulated: MarkCrawlAsPopulatedPayload;
  internalMarkCrawlAsTransformed: Crawl;
  internalMarkCreditReportExportAsGenerated: CreditReportExport;
  internalMarkReportDownloadAsGenerated: MarkReportDownloadAsGeneratedPayload;
  internalMarkReportDownloadAsGenerating: MarkReportDownloadAsGeneratingPayload;
  internalPopulateCrawl: Crawl;
  internalProjectRecalculateSegmentsTotalCount: Project;
  internalQueueBuildSchedules: InternalQueueBuildSchedulesPayload;
  internalRecalculateProjectsCrawlsTotalCount: Scalars['Int']['output'];
  internalRecalculateProjectsLegacyTasksTotalCount: Scalars['Int']['output'];
  internalRecalculateProjectsSchedulesTotalCount: Scalars['Int']['output'];
  internalRecalculateProjectsTestsTotalCount: Scalars['Int']['output'];
  /** Allows to retry ReportDownload file generation, e.g. in case it's stuck. */
  internalRegenerateReportDownload: ReportDownload;
  /** Requests a datasources CSV file export and returns signed URL to the file. */
  internalRequestDatasourcesCsvExport: InternalRequestDatasourcesCsvExportPayload;
  /** Requests a crawls CSV file export and returns signed URL to the file. */
  internalRequestFinishedCrawlsCsvExport: InternalRequestFinishedCrawlsCsvExportPayload;
  internalRequestLocaleUpdate: Scalars['Boolean']['output'];
  internalRequestMailCallbackFailed?: Maybe<Array<Scalars['JSONObject']['output']>>;
  internalRequestMailScheduledCrawlFailedActiveProjectsLimit?: Maybe<Array<Scalars['JSONObject']['output']>>;
  internalRequestMailScheduledCrawlFailedAnalytics?: Maybe<Array<Scalars['JSONObject']['output']>>;
  internalRequestMailScheduledCrawlFailedCrawlRunning?: Maybe<Array<Scalars['JSONObject']['output']>>;
  internalRequestMailScheduledCrawlFailedNoCredits?: Maybe<Array<Scalars['JSONObject']['output']>>;
  /** Requests a metrics CSV file export and returns signed URL to the file. */
  internalRequestMetricsCsvExport: InternalRequestMetricsCsvExportPayload;
  /** Requests a report categories CSV export and returns a signed URL to the CSV file. */
  internalRequestReportCategoriesCsvExport: InternalReportCategoriesCsvExportPayload;
  /** Returns ReportDownloadGenerated Callback NotificationRequested event details if apiCallbackUrl is configured. Also sends the event, unless dryRun is true. */
  internalRequestReportDownloadGeneratedCallback?: Maybe<Scalars['JSONObject']['output']>;
  /** Synchronizes report templates cache on S3 with latest version. */
  internalRequestReportTemplateS3CacheSync: Scalars['Boolean']['output'];
  /** Requests a report templates CSV export and returns a signed URL to the CSV file. */
  internalRequestReportTemplatesCsvExport: InternalReportTemplatesCsvExportPayload;
  /** Returns ReportsGenerationRequested event details. Also sends the event, unless dryRun is true. */
  internalRequestReportsGeneration: Scalars['JSONObject']['output'];
  /** Returns SegmentMarkedAsDeleted event details. Also sends the events, unless dryRun is true. */
  internalRequestSegmentsDeletion: Array<Scalars['JSONObject']['output']>;
  /** Returns SRP Crawl Create Requested event details. Also sends the event, unless dryRun is true. */
  internalRequestSerpentCrawling: Scalars['JSONObject']['output'];
  /** Returns SerpentTransformRequested event details. Also sends the event, unless dryRun is true. */
  internalRequestSerpentTransform: Scalars['JSONObject']['output'];
  /** Sends ProjectUploadsProcessingRequested event and returns the details. Ignores legacy uploads. */
  internalRequestUrlFileUploadProcessing?: Maybe<Scalars['JSONObject']['output']>;
  /** Requests a user agents CSV file export and returns signed URL to the file. */
  internalRequestUserAgentsCsvExport: InternalRequestUserAgentsCsvExportPayload;
  /** @deprecated AdminGrant permission is no longer in use. */
  internalRevokeAdminPermission: InternalGrantAdminPermissionPayload;
  internalRunBuildSchedule: InternalRunBuildSchedulePayload;
  internalTransferGoogleConnections: Scalars['Boolean']['output'];
  internalTransferLogzioConnections: Scalars['Boolean']['output'];
  internalTransferProjectsToAccount: Scalars['Boolean']['output'];
  internalUpdateAccountSsoClient: AccountSsoClient;
  /** Update an AccountSubscription */
  internalUpdateAccountSubscription: AccountSubscription;
  internalUpdateAccountsSerpentVpcs: Array<Account>;
  internalUpdateAccountsServerOverride: Array<Account>;
  /** Update account's aretestSuiteAdminActionsAllowed field value. */
  internalUpdateAreTestSuiteAdminActionsAllowed: Account;
  internalUpdateCrawl: Crawl;
  internalUpdateCrawlRate: Crawl;
  /** Use to update a Crawl with metadata from the DataServicesTransformCompleted event. */
  internalUpdateCrawlWithTransformCompleteMetadata: Crawl;
  internalUpdateCreditAllocation: CreditAllocation;
  internalUpdateCustomMetricContainerVersion: InternalUpdateCustomMetricContainerVersionPayload;
  internalUpdateFeatureCost: FeatureCost;
  internalUpdateLegacyTasks?: Maybe<Crawl>;
  /** Update account's maxDashboardCollectionViews field value. */
  internalUpdateMaxDashboardCollectionViews: Array<Account>;
  internalUpdateModule: Module;
  internalUpdateProxy: Proxy;
  /** Updates ReportCategories based on the code. Returns the number of affected database rows. */
  internalUpdateReportCategories: Scalars['Int']['output'];
  /** Updates ReportTemplate from id */
  internalUpdateReportTemplate: ReportTemplate;
  /** Updates ReportTemplates reportCategories. */
  internalUpdateReportTemplateReportCategories: Array<ReportTemplate>;
  /** Updates ReportTemplates based on the code. */
  internalUpdateReportTemplates: Array<ReportTemplate>;
  internalUpdateSinglePageRequesterRequest?: Maybe<InternalUpdateSinglePageRequesterRequestPayload>;
  internalUpdateSsoClient: SsoClient;
  internalUpdateSystemSetting: SystemSetting;
  /** Ignores legacy uploads. */
  internalUpdateUrlFileUpload?: Maybe<UrlFileUpload>;
  internalUpdateUser: User;
  internalUpdateUserAgent: UserAgent;
  linkChildTestSuiteToParentTestSuite: LinkChildTestSuiteToParentTestSuitePayload;
  linkCustomMetricContainerToProject: LinkCustomMetricContainerToProjectPayload;
  linkJiraIntegrationToTestSuite: LinkJiraIntegrationToTestSuitePayload;
  linkTestSuiteToBuildSchedule: LinkTestSuiteToBuildSchedulePayload;
  moveCustomDashboardCollection: MoveCustomDashboardCollectionPayload;
  /** Pauses crawling. */
  pauseCrawling: PauseCrawlingPayload;
  registerSchema: RegisterCrawlSchemaPayload;
  rejectSuggestedThreshold: Array<UpdateTestResultPayload>;
  removeCustomViewsFromCustomDashboard: UpdateCustomDashboardPayload;
  removeCustomViewsFromCustomDashboardCollection: RemoveCustomViewsFromCustomDashboardCollectionPayload;
  requestPasswordReset: Scalars['Boolean']['output'];
  resendAccountUserInvite: ResendAccountUserPayload;
  resetPassword: ResetPasswordPayload;
  /** Resumes crawling. */
  resumeCrawling: ResumeCrawlingPayload;
  runBuild: RunBuildPayload;
  /** Creates a crawl and immediately runs it. */
  runCrawlForProject: RunCrawlForProjectPayload;
  setBuildScheduleTestSuites: SetBuildScheduleTestSuitesPayload;
  setCustomMetricContainerProjectSecret: SetCustomMetricContainerProjectSecretPayload;
  setUserData?: Maybe<Scalars['JSON']['output']>;
  /** Generates a shareable token to give viewer access to specific project */
  shareProject: ShareProjectPayload;
  sortCustomDashboardCollections: SortCustomDashboardCollectionsPayload;
  sortCustomDashboardsInCollection: SortCustomDashboardsPayload;
  /** Unarchives crawl. */
  unarchiveCrawl: RunCrawlForProjectPayload;
  unlinkChildTestSuiteFromParentTestSuite: UnlinkChildTestSuiteFromParentTestSuitePayload;
  unlinkCustomMetricContainerFromProject: UnlinkCustomMetricContainerFromProjectPayload;
  unlinkJiraIntegrationFromTestSuite: UnlinkJiraIntegrationFromTestSuitePayload;
  unlinkTestSuiteFromBuildSchedule: UnlinkTestSuiteFromBuildSchedulePayload;
  updateAccount: UpdateAccountPayload;
  updateAccountUser: UpdateAccountUserPayload;
  updateAdobeAnalyticsProjectReportSuite: UpdateAdobeAnalyticsProjectReportSuitePayload;
  updateAdobeConnection: UpdateAdobeConnectionPayload;
  updateAdobeJwtConnection: UpdateAdobeJwtConnectionPayload;
  updateAutomatorSlackWebhook: CreateSlackWebhookPayload;
  updateBuildSchedule: UpdateBuildSchedulePayload;
  updateCrawlSegment: UpdateCrawlSegmentPayload;
  updateCreditAllocationForSsoAccount: UpdateCreditAllocationForSsoAccountPayload;
  updateCurrentUser: UpdateCurrentUserPayload;
  updateCustomChart: UpdateCustomChartPayload;
  updateCustomDashboard: UpdateCustomDashboardPayload;
  updateCustomDashboardCollection: UpdateCustomDashboardCollectionPayload;
  updateCustomMetricContainer: UpdateCustomMetricContainerPayload;
  updateCustomMetricContainerProject: UpdateCustomMetricContainerProjectPayload;
  updateCustomMetricUploadContainer: UpdateCustomMetricUploadContainerPayload;
  updateCustomReportTemplate: UpdateCustomReportTemplatePayload;
  updateCustomTable: UpdateCustomTablePayload;
  updateGoogleAnalytics4ProjectProperty: UpdateGoogleAnalytics4ProjectPropertyPayload;
  updateGoogleSearchConsoleConfiguration: UpdateGoogleSearchConsoleConfigurationPayload;
  updateGoogleSearchConsoleProperty: UpdateGoogleSearchConsolePropertyPayload;
  updateJiraAuthentication: UpdateJiraAuthenticationPayload;
  updateJiraIntegration: UpdateJiraIntegrationPayload;
  updateLegacyTask: UpdateLegacyTaskPayload;
  updateLogzioConnection: UpdateLogzioConnectionPayload;
  updateLogzioProjectQuery: UpdateLogzioProjectQueryPayload;
  updateMajesticConfiguration: UpdateMajesticConfigurationPayload;
  updateMonitorNotificationsStatus: UpdateMonitorNotificationsStatusPayload;
  updatePassword: UpdatePasswordPayload;
  updateProject: UpdateProjectPayload;
  updateReportTemplateOverride: UpdateReportTemplateOverridePayload;
  updateSchedule: UpdateSchedulePayload;
  updateSegment: UpdateSegmentPayload;
  updateSegments: UpdateSegmentsPayload;
  updateSplunkConnection: UpdateSplunkConnectionPayload;
  updateSplunkProjectQuery: UpdateSplunkProjectQueryPayload;
  updateSsoAccount: UpdateSsoAccountPayload;
  updateSsoAccountSubscription: UpdateSsoAccountSubscriptionPayload;
  updateTest: UpdateTestPayload;
  updateTestResult: UpdateTestResultPayload;
  updateTestSuite: UpdateTestSuitePayload;
  updateTestSuiteEmailAlert: CreateTestSuiteEmailAlertPayload;
  updateTests: UpdateTestsPayload;
  updateUrlFileUpload: UpdateUrlFileUploadPayload;
  updateWebhook: CreateWebhookPayload;
};


export type MutationAbortBuildArgs = {
  input: AbortBuildInput;
};


export type MutationAcceptSuggestedThresholdArgs = {
  input: AcceptSuggestedThresholdInput;
};


export type MutationAddAccountUserArgs = {
  input: AddAccountUserInput;
};


export type MutationAddCustomDashboardsToCollectionArgs = {
  input: AddCustomDashboardToCollectionInput;
};


export type MutationAddCustomSitemapsArgs = {
  input: AddCustomSitemapsInput;
};


export type MutationAddCustomViewsToCustomDashboardArgs = {
  input: AddCustomViewsToCustomDashboardInput;
};


export type MutationAddCustomViewsToCustomDashboardCollectionArgs = {
  input: AddCustomViewsToCollectionInput;
};


export type MutationBulkAcceptSuggestedThresholdsArgs = {
  input: SelectMonitorNotificationsStatusInput;
};


export type MutationBulkRejectSuggestedThresholdsArgs = {
  input: SelectMonitorNotificationsStatusInput;
};


export type MutationCancelBuildArgs = {
  input: CancelBuildInput;
};


export type MutationCancelCrawlingArgs = {
  input: CancelCrawlingInput;
};


export type MutationCheckDomainArgs = {
  input: CheckDomainInput;
};


export type MutationCloneProjectArgs = {
  projectId: Scalars['ObjectID']['input'];
};


export type MutationCloneTestSuiteArgs = {
  input: CloneTestSuiteInput;
};


export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};


export type MutationConvertAccountSubscriptionToChargebeeArgs = {
  input: ConvertAccountSubscriptionToChargebeeInput;
};


export type MutationCopyCustomDashboardArgs = {
  input: CopyCustomDashboardInput;
};


export type MutationCopyCustomDashboardCollectionArgs = {
  input: CopyCustomDashboardCollectionInput;
};


export type MutationCopyTestsArgs = {
  input: CopyTestsInput;
};


export type MutationCreateAccessibilityIssueSolutionSuggestionArgs = {
  input: CreateAccessibilityIssueSolutionSuggestionInput;
};


export type MutationCreateAccessibilityProjectArgs = {
  input: CreateAccessibilityProjectInput;
};


export type MutationCreateAccessibilityTestSuiteArgs = {
  input: CreateAccessibilityTestSuiteInput;
};


export type MutationCreateAdobeAnalyticsProjectReportSuiteArgs = {
  input: CreateAdobeAnalyticsProjectReportSuiteInput;
};


export type MutationCreateAdobeConnectionArgs = {
  input: CreateAdobeConnectionInput;
};


export type MutationCreateAdobeJwtConnectionArgs = {
  input: CreateAdobeJwtConnectionInput;
};


export type MutationCreateAndRunBuildArgs = {
  input: CreateAndRunBuildInput;
};


export type MutationCreateAndRunManualBuildArgs = {
  input: CreateAndRunBuildInput;
};


export type MutationCreateAutomatorSlackWebhookArgs = {
  input: CreateSlackWebhookInput;
};


export type MutationCreateBasicProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreateBuildScheduleArgs = {
  input: CreateBuildScheduleInput;
};


export type MutationCreateCrawlSegmentArgs = {
  input: CreateCrawlSegmentInput;
};


export type MutationCreateCrawlUrlSegmentArgs = {
  input: CreateCrawlUrlSegmentInput;
};


export type MutationCreateCreditAllocationForSsoAccountArgs = {
  input: CreateCreditAllocationForSsoAccountInput;
};


export type MutationCreateCreditReportExportArgs = {
  input: CreateCreditReportExportInput;
};


export type MutationCreateCustomChartArgs = {
  input: CreateCustomChartInput;
};


export type MutationCreateCustomDashboardArgs = {
  input: CreateCustomDashboardInput;
};


export type MutationCreateCustomDashboardCollectionArgs = {
  input: CreateCustomDashboardCollectionInput;
};


export type MutationCreateCustomMetricContainerArgs = {
  input: CreateCustomMetricContainerInput;
};


export type MutationCreateCustomMetricContainerVersionArgs = {
  input: CreateCustomMetricContainerVersionInput;
};


export type MutationCreateCustomMetricUploadContainerArgs = {
  input: CreateCustomMetricUploadContainerInput;
};


export type MutationCreateCustomReportTemplateArgs = {
  input: CreateCustomReportTemplateInput;
};


export type MutationCreateCustomTableArgs = {
  input: CreateCustomTableInput;
};


export type MutationCreateCustomViewArgs = {
  input: CreateCustomViewsInput;
};


export type MutationCreateGoogleAnalytics4ProjectPropertyArgs = {
  input: CreateGoogleAnalytics4ProjectPropertyInput;
};


export type MutationCreateGoogleConnectionArgs = {
  input: CreateGoogleConnectionInput;
};


export type MutationCreateGoogleSearchConsoleConfigurationArgs = {
  input: CreateGoogleSearchConsoleConfigurationInput;
};


export type MutationCreateGoogleSearchConsolePropertyArgs = {
  input: CreateGoogleSearchConsolePropertyInput;
};


export type MutationCreateJiraAuthenticationArgs = {
  input: CreateJiraAuthenticationInput;
};


export type MutationCreateJiraIntegrationArgs = {
  input: CreateJiraIntegrationInput;
};


export type MutationCreateLogzioConnectionArgs = {
  input: CreateLogzioConnectionInput;
};


export type MutationCreateLogzioProjectQueryArgs = {
  input: CreateLogzioProjectQueryInput;
};


export type MutationCreateMajesticConfigurationArgs = {
  input: CreateMajesticConfigurationInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreateProjectLegacyTaskArgs = {
  input: CreateProjectLegacyTaskInput;
};


export type MutationCreateReportDownloadArgs = {
  input: CreateReportDownloadInput;
};


export type MutationCreateReportLegacyTaskArgs = {
  input: CreateReportLegacyTaskInput;
};


export type MutationCreateReportStatLegacyTaskArgs = {
  input: CreateReportStatLegacyTaskInput;
};


export type MutationCreateReportTemplateOverrideArgs = {
  input: CreateReportTemplateOverrideInput;
};


export type MutationCreateSeoProjectArgs = {
  input: CreateProjectInput;
};


export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};


export type MutationCreateSessionUsingUserKeyArgs = {
  input: CreateSessionUsingUserKeyInputType;
};


export type MutationCreateSessionUsingUsernameArgs = {
  input: CreateSessionUsingUsernameInputType;
};


export type MutationCreateSignedUrlFileUploadArgs = {
  input: CreateSignedUrlFileUploadInput;
};


export type MutationCreateSinglePageRequesterRequestArgs = {
  input: CreateSinglePageRequesterRequestInput;
};


export type MutationCreateSiteSpeedProjectArgs = {
  input: CreateSiteSpeedProjectInput;
};


export type MutationCreateSiteSpeedTestSuiteArgs = {
  input: CreateSiteSpeedTestSuiteInput;
};


export type MutationCreateSplunkConnectionArgs = {
  input: CreateSplunkConnectionInput;
};


export type MutationCreateSplunkProjectQueryArgs = {
  input: CreateSplunkProjectQueryInput;
};


export type MutationCreateSsoAccountArgs = {
  input: CreateSsoAccountInput;
};


export type MutationCreateSsoAccountSubscriptionArgs = {
  input: CreateSsoAccountSubscriptionInput;
};


export type MutationCreateTestArgs = {
  input: CreateTestInput;
};


export type MutationCreateTestResultArgs = {
  input: CreateTestResultInput;
};


export type MutationCreateTestSuiteArgs = {
  input: CreateTestSuiteInput;
};


export type MutationCreateTestSuiteEmailAlertArgs = {
  input: CreateTestSuiteEmailAlertInput;
};


export type MutationCreateTestsArgs = {
  inputs: Array<CreateTestInput>;
};


export type MutationCreateUserInviteArgs = {
  input: CreateUserInviteInput;
};


export type MutationCreateUserKeyArgs = {
  input?: InputMaybe<CreateUserKeyInput>;
};


export type MutationCreateWebhookArgs = {
  input: CreateWebhookInput;
};


export type MutationDeleteAccountUserArgs = {
  input: DeleteAccountUserInput;
};


export type MutationDeleteAdobeAnalyticsProjectReportSuiteArgs = {
  input: DeleteAdobeAnalyticsProjectReportSuiteInput;
};


export type MutationDeleteAdobeConnectionArgs = {
  input: DeleteAdobeConnectionInput;
};


export type MutationDeleteAdobeJwtConnectionArgs = {
  input: DeleteAdobeJwtConnectionInput;
};


export type MutationDeleteAutomatorSlackWebhookArgs = {
  input: DeleteSlackWebhookInput;
};


export type MutationDeleteBuildScheduleArgs = {
  input: DeleteBuildScheduleInput;
};


export type MutationDeleteCrawlArgs = {
  input: DeleteCrawlInput;
};


export type MutationDeleteCustomChartArgs = {
  customChartId: Scalars['ObjectID']['input'];
};


export type MutationDeleteCustomDashboardArgs = {
  customDashboardId: Scalars['ObjectID']['input'];
};


export type MutationDeleteCustomDashboardCollectionArgs = {
  input: DeleteCustomDashboardCollectionInput;
};


export type MutationDeleteCustomMetricContainerArgs = {
  input: DeleteCustomMetricContainerInput;
};


export type MutationDeleteCustomMetricContainerProjectSecretArgs = {
  input: DeleteCustomMetricContainerProjectSecretInput;
};


export type MutationDeleteCustomReportTemplateArgs = {
  input: DeleteCustomReportTemplateInput;
};


export type MutationDeleteCustomTableArgs = {
  customTableId: Scalars['ObjectID']['input'];
};


export type MutationDeleteCustomViewsArgs = {
  input: DeleteCustomViewsInput;
};


export type MutationDeleteGoogleAnalytics4ProjectPropertyArgs = {
  input: DeleteGoogleAnalytics4ProjectPropertyInput;
};


export type MutationDeleteGoogleConnectionArgs = {
  input: DeleteGoogleConnectionInput;
};


export type MutationDeleteGoogleSearchConsoleConfigurationArgs = {
  input: DeleteGoogleSearchConsoleConfigurationInput;
};


export type MutationDeleteGoogleSearchConsolePropertyArgs = {
  input: DeleteGoogleSearchConsolePropertyInput;
};


export type MutationDeleteJiraAuthenticationArgs = {
  input: DeleteJiraAuthenticationInput;
};


export type MutationDeleteJiraIntegrationArgs = {
  input: DeleteJiraIntegrationInput;
};


export type MutationDeleteLegacyTaskArgs = {
  input: DeleteLegacyTaskInput;
};


export type MutationDeleteLogzioConnectionArgs = {
  input: DeleteLogzioConnectionInput;
};


export type MutationDeleteLogzioProjectQueryArgs = {
  input: DeleteLogzioProjectQueryInput;
};


export type MutationDeleteProjectArgs = {
  projectId: Scalars['ObjectID']['input'];
};


export type MutationDeleteReportDownloadArgs = {
  input: DeleteReportDownloadInput;
};


export type MutationDeleteReportTemplateOverrideArgs = {
  input: DeleteReportTemplateOverrideInput;
};


export type MutationDeleteScheduleArgs = {
  input: DeleteScheduleInput;
};


export type MutationDeleteSegmentArgs = {
  input: DeleteSegmentInput;
};


export type MutationDeleteSitemapArgs = {
  input: DeleteSitemapInput;
};


export type MutationDeleteSplunkConnectionArgs = {
  input: DeleteSplunkConnectionInput;
};


export type MutationDeleteSplunkProjectQueryArgs = {
  input: DeleteSplunkProjectQueryInput;
};


export type MutationDeleteTestArgs = {
  input: DeleteTestInput;
};


export type MutationDeleteTestSuiteArgs = {
  input: DeleteTestSuiteInput;
};


export type MutationDeleteTestSuiteEmailAlertArgs = {
  input: DeleteTestSuiteEmailAlertInput;
};


export type MutationDeleteTestsArgs = {
  input: DeleteTestsInput;
};


export type MutationDeleteUrlFileUploadArgs = {
  input: DeleteUrlFileUploadInput;
};


export type MutationDeleteUserKeyArgs = {
  input: DeleteUserKeyInput;
};


export type MutationDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};


export type MutationDisableSitemapArgs = {
  input: DisableSitemapInput;
};


export type MutationDisableSitemapsArgs = {
  input: DisableSitemapsInput;
};


export type MutationEnableSitemapArgs = {
  input: EnableSitemapInput;
};


export type MutationEnableSitemapsArgs = {
  input: EnableSitemapsInput;
};


export type MutationFindOrCreateCustomViewArgs = {
  input: CreateCustomViewsInput;
};


export type MutationFinishBuildArgs = {
  input: FinishBuildInput;
};


export type MutationGenerateLegacyTaskTicketDetailsArgs = {
  input: GenerateLegacyTaskTicketDetailsInput;
};


export type MutationInternalAddAccountUserArgs = {
  input: InternalAddAccountUserInput;
};


export type MutationInternalArchiveCrawlArgs = {
  crawlId: Scalars['ObjectID']['input'];
};


export type MutationInternalChangeCrawlArchivingArgs = {
  crawlId: Scalars['ObjectID']['input'];
  enabled?: Scalars['Boolean']['input'];
};


export type MutationInternalConnectChargebeeToAccountArgs = {
  input: InternalConnectChargebeeToAccountInput;
};


export type MutationInternalCreateAccountArgs = {
  input: InternalCreateAccountInput;
};


export type MutationInternalCreateAccountSsoClientArgs = {
  input: InternalCreateAccountSsoClientInput;
};


export type MutationInternalCreateAccountSubscriptionArgs = {
  input: InternalCreateAccountSubscriptionInput;
};


export type MutationInternalCreateBuildForCrawlIfMissingArgs = {
  crawlId: Scalars['ObjectID']['input'];
};


export type MutationInternalCreateChatCompletionArgs = {
  model?: Scalars['String']['input'];
  systemMessages?: InputMaybe<Array<Scalars['String']['input']>>;
  userMessage: Scalars['String']['input'];
};


export type MutationInternalCreateCrawlSegmentsArgs = {
  crawlId: Scalars['ObjectID']['input'];
};


export type MutationInternalCreateCreditAllocationArgs = {
  input: InternalCreateCreditAllocationInput;
};


export type MutationInternalCreateDefaultCustomMetricContainerArgs = {
  customMetricContainerId: Scalars['ObjectID']['input'];
  dataset: DefaultCustomMetricContainerDataset;
};


export type MutationInternalCreateFeatureCostArgs = {
  input: CreateFeatureCostInput;
};


export type MutationInternalCreateOrUpdateCreditReportForCrawlArgs = {
  crawlId: Scalars['ObjectID']['input'];
};


export type MutationInternalCreateOrUpdateDatasourcesArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationInternalCreateOrUpdateIndustriesArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};


export type MutationInternalCreateOrUpdateIndustryBenchmarksArgs = {
  file?: InputMaybe<Scalars['Upload']['input']>;
};


export type MutationInternalCreateOrUpdateMetricsArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationInternalCreateOrUpdateReportCategoriesArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationInternalCreateOrUpdateUserAgentsArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationInternalCreateProxyArgs = {
  input: InternalCreateProxyInput;
};


export type MutationInternalCreateReportTemplatesArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationInternalCreateResetPasswordTokenArgs = {
  userId: Scalars['ObjectID']['input'];
};


export type MutationInternalCreateSsoClientArgs = {
  input: InternalCreateSsoClientInput;
};


export type MutationInternalCreateSystemSettingArgs = {
  code: Scalars['String']['input'];
  type: SystemSettingType;
  value: Scalars['String']['input'];
};


export type MutationInternalCreateUserAgentArgs = {
  input: InternalCreateUserAgentInput;
};


export type MutationInternalDeleteAccountArgs = {
  accountId: Scalars['ObjectID']['input'];
};


export type MutationInternalDeleteAccountSsoClientArgs = {
  input: InternalDeleteAccountSsoClientInput;
};


export type MutationInternalDeleteDefaultCustomMetricContainerArgs = {
  customMetricContainerId: Scalars['ObjectID']['input'];
};


export type MutationInternalDeleteFeatureCostArgs = {
  featureCode: Scalars['String']['input'];
};


export type MutationInternalDeleteIndustriesArgs = {
  codes: Array<Scalars['String']['input']>;
};


export type MutationInternalDeleteIndustryBenchmarkArgs = {
  industryCode: Scalars['String']['input'];
  reportCategoryCode: Scalars['String']['input'];
};


export type MutationInternalDeleteProxyArgs = {
  proxyId: Scalars['ObjectID']['input'];
};


export type MutationInternalDeleteReportCategoriesArgs = {
  codes: Array<Scalars['String']['input']>;
};


export type MutationInternalDeleteSegmentsArgs = {
  ids: Array<Scalars['ObjectID']['input']>;
};


export type MutationInternalDeleteSystemSettingArgs = {
  code: Scalars['String']['input'];
};


export type MutationInternalDeleteUserArgs = {
  userId: Scalars['ObjectID']['input'];
};


export type MutationInternalDisconnectChargebeeFromAccountArgs = {
  accountId: Scalars['ObjectID']['input'];
};


export type MutationInternalEnqueueCrawlArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationInternalExpireCrawlCacheArgs = {
  crawlId: Scalars['ObjectID']['input'];
  projectId: Scalars['ObjectID']['input'];
};


export type MutationInternalGrantAdminPermissionArgs = {
  userIds: Array<Scalars['ObjectID']['input']>;
};


export type MutationInternalHardDeleteCrawlArgs = {
  crawlId: Scalars['ObjectID']['input'];
};


export type MutationInternalHardDeleteProjectArgs = {
  projectId: Scalars['ObjectID']['input'];
};


export type MutationInternalHardDeleteSegmentsArgs = {
  ids: Array<Scalars['ObjectID']['input']>;
};


export type MutationInternalMarkCrawlAsPausedArgs = {
  id: Scalars['ObjectID']['input'];
  reason: Scalars['String']['input'];
};


export type MutationInternalMarkCrawlAsPopulateReadyArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationInternalMarkCrawlAsPopulatedArgs = {
  input: MarkCrawlAsPopulatedInput;
};


export type MutationInternalMarkCrawlAsTransformedArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationInternalMarkCreditReportExportAsGeneratedArgs = {
  creditReportExportId: Scalars['ObjectID']['input'];
  exportedAt: Scalars['DateTime']['input'];
};


export type MutationInternalMarkReportDownloadAsGeneratedArgs = {
  input: MarkReportDownloadAsGeneratedInput;
};


export type MutationInternalMarkReportDownloadAsGeneratingArgs = {
  input: MarkReportDownloadAsGeneratingInput;
};


export type MutationInternalPopulateCrawlArgs = {
  force?: Scalars['Boolean']['input'];
  id: Scalars['ObjectID']['input'];
};


export type MutationInternalProjectRecalculateSegmentsTotalCountArgs = {
  projectId: Scalars['ObjectID']['input'];
};


export type MutationInternalRegenerateReportDownloadArgs = {
  reportDownloadId: Scalars['ObjectID']['input'];
};


export type MutationInternalRequestFinishedCrawlsCsvExportArgs = {
  date: Scalars['DateTime']['input'];
  dryRun?: Scalars['Boolean']['input'];
};


export type MutationInternalRequestMailCallbackFailedArgs = {
  projectId: Scalars['ObjectID']['input'];
  status?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationInternalRequestMailScheduledCrawlFailedActiveProjectsLimitArgs = {
  projectId: Scalars['ObjectID']['input'];
};


export type MutationInternalRequestMailScheduledCrawlFailedAnalyticsArgs = {
  projectId: Scalars['ObjectID']['input'];
};


export type MutationInternalRequestMailScheduledCrawlFailedCrawlRunningArgs = {
  projectId: Scalars['ObjectID']['input'];
};


export type MutationInternalRequestMailScheduledCrawlFailedNoCreditsArgs = {
  projectId: Scalars['ObjectID']['input'];
};


export type MutationInternalRequestMetricsCsvExportArgs = {
  filter?: InputMaybe<MetricConnectionFilterInput>;
};


export type MutationInternalRequestReportCategoriesCsvExportArgs = {
  filter?: InputMaybe<ReportCategoryConnectionFilterInput>;
};


export type MutationInternalRequestReportDownloadGeneratedCallbackArgs = {
  dryRun?: Scalars['Boolean']['input'];
  reportDownloadId: Scalars['ObjectID']['input'];
};


export type MutationInternalRequestReportTemplatesCsvExportArgs = {
  filter?: InputMaybe<ReportTemplateConnectionFilterInput>;
};


export type MutationInternalRequestReportsGenerationArgs = {
  crawlId: Scalars['ObjectID']['input'];
  dryRun?: Scalars['Boolean']['input'];
};


export type MutationInternalRequestSegmentsDeletionArgs = {
  dryRun?: Scalars['Boolean']['input'];
  segmentIds: Array<Scalars['ObjectID']['input']>;
};


export type MutationInternalRequestSerpentCrawlingArgs = {
  crawlId: Scalars['ObjectID']['input'];
  dryRun?: Scalars['Boolean']['input'];
};


export type MutationInternalRequestSerpentTransformArgs = {
  crawlId: Scalars['ObjectID']['input'];
  dryRun?: Scalars['Boolean']['input'];
  retransform?: Scalars['Boolean']['input'];
};


export type MutationInternalRequestUrlFileUploadProcessingArgs = {
  dryRun?: Scalars['Boolean']['input'];
  urlFileUploadId: Scalars['ObjectID']['input'];
};


export type MutationInternalRequestUserAgentsCsvExportArgs = {
  filter?: InputMaybe<UserAgentConnectionFilterInput>;
};


export type MutationInternalRevokeAdminPermissionArgs = {
  userIds: Array<Scalars['ObjectID']['input']>;
};


export type MutationInternalRunBuildScheduleArgs = {
  input: InternalRunBuildScheduleInput;
};


export type MutationInternalTransferGoogleConnectionsArgs = {
  fromUserId: Scalars['ObjectID']['input'];
  googleConnectionIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  toUserId: Scalars['ObjectID']['input'];
};


export type MutationInternalTransferLogzioConnectionsArgs = {
  fromUserId: Scalars['ObjectID']['input'];
  logzioConnectionIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  toUserId: Scalars['ObjectID']['input'];
};


export type MutationInternalTransferProjectsToAccountArgs = {
  fromAccountId: Scalars['ObjectID']['input'];
  projectIds: Array<Scalars['ObjectID']['input']>;
  toAccountId: Scalars['ObjectID']['input'];
};


export type MutationInternalUpdateAccountSsoClientArgs = {
  input: InternalUpdateAccountSsoClientInput;
};


export type MutationInternalUpdateAccountSubscriptionArgs = {
  input: InternalUpdateAccountSubscriptionInput;
};


export type MutationInternalUpdateAccountsSerpentVpcsArgs = {
  accountIds: Array<Scalars['ObjectID']['input']>;
  serpentVpcs: Array<Scalars['String']['input']>;
};


export type MutationInternalUpdateAccountsServerOverrideArgs = {
  accountIds: Array<Scalars['ObjectID']['input']>;
  serverOverride?: InputMaybe<MetricsServerId>;
};


export type MutationInternalUpdateAreTestSuiteAdminActionsAllowedArgs = {
  accountId: Scalars['ObjectID']['input'];
  allowed?: Scalars['Boolean']['input'];
};


export type MutationInternalUpdateCrawlArgs = {
  input: InternalUpdateCrawlInput;
};


export type MutationInternalUpdateCrawlRateArgs = {
  input: InternalUpdateCrawlRateInput;
};


export type MutationInternalUpdateCrawlWithTransformCompleteMetadataArgs = {
  input: UpdateCrawlWithTransformCompleteMetadataInput;
};


export type MutationInternalUpdateCreditAllocationArgs = {
  input: InternalUpdateCreditAllocationInput;
};


export type MutationInternalUpdateCustomMetricContainerVersionArgs = {
  input: InternalUpdateCustomMetricContainerVersionInput;
};


export type MutationInternalUpdateFeatureCostArgs = {
  input: UpdateFeatureCostInput;
};


export type MutationInternalUpdateLegacyTasksArgs = {
  crawlId: Scalars['ObjectID']['input'];
};


export type MutationInternalUpdateMaxDashboardCollectionViewsArgs = {
  accountIds: Array<Scalars['ObjectID']['input']>;
  maxDashboardCollectionViews: Scalars['Int']['input'];
};


export type MutationInternalUpdateModuleArgs = {
  input: InternalUpdateModuleInput;
};


export type MutationInternalUpdateProxyArgs = {
  input: InternalUpdateProxyInput;
};


export type MutationInternalUpdateReportCategoriesArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationInternalUpdateReportTemplateArgs = {
  input: InternalUpdateReportTemplateInput;
};


export type MutationInternalUpdateReportTemplateReportCategoriesArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationInternalUpdateReportTemplatesArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationInternalUpdateSinglePageRequesterRequestArgs = {
  input: InternalUpdateSinglePageRequesterRequestInput;
};


export type MutationInternalUpdateSsoClientArgs = {
  input: InternalUpdateSsoClientInput;
};


export type MutationInternalUpdateSystemSettingArgs = {
  code: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationInternalUpdateUrlFileUploadArgs = {
  input: InternalUpdateUrlFileUploadInput;
};


export type MutationInternalUpdateUserArgs = {
  input: InternalUpdateUserInput;
};


export type MutationInternalUpdateUserAgentArgs = {
  input: InternalUpdateUserAgentInput;
};


export type MutationLinkChildTestSuiteToParentTestSuiteArgs = {
  input: LinkChildTestSuiteToParentTestSuiteInput;
};


export type MutationLinkCustomMetricContainerToProjectArgs = {
  input: LinkCustomMetricContainerToProjectInput;
};


export type MutationLinkJiraIntegrationToTestSuiteArgs = {
  input: LinkJiraIntegrationToTestSuiteInput;
};


export type MutationLinkTestSuiteToBuildScheduleArgs = {
  input: LinkTestSuiteToBuildScheduleInput;
};


export type MutationMoveCustomDashboardCollectionArgs = {
  input: MoveCustomDashboardCollectionInput;
};


export type MutationPauseCrawlingArgs = {
  input: PauseCrawlingInput;
};


export type MutationRegisterSchemaArgs = {
  input: RegisterCrawlSchemaInput;
};


export type MutationRejectSuggestedThresholdArgs = {
  input: RejectSuggestedThresholdInput;
};


export type MutationRemoveCustomViewsFromCustomDashboardArgs = {
  input: AddCustomViewsToCustomDashboardInput;
};


export type MutationRemoveCustomViewsFromCustomDashboardCollectionArgs = {
  input: RemoveCustomDashboardFromCollectionInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationResendAccountUserInviteArgs = {
  input: ResendAccountUserInviteInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResumeCrawlingArgs = {
  input: ResumeCrawlingInput;
};


export type MutationRunBuildArgs = {
  input: RunBuildInput;
};


export type MutationRunCrawlForProjectArgs = {
  input: RunCrawlForProjectInput;
};


export type MutationSetBuildScheduleTestSuitesArgs = {
  input: SetBuildScheduleTestSuitesInput;
};


export type MutationSetCustomMetricContainerProjectSecretArgs = {
  input: SetCustomMetricContainerProjectSecretInput;
};


export type MutationSetUserDataArgs = {
  input: SetUserDataInput;
};


export type MutationShareProjectArgs = {
  input: ShareProjectInput;
};


export type MutationSortCustomDashboardCollectionsArgs = {
  input: SortCustomDashboardCollectionsInput;
};


export type MutationSortCustomDashboardsInCollectionArgs = {
  input: SortCustomDashboardsInput;
};


export type MutationUnarchiveCrawlArgs = {
  input: UnarchiveCrawlInput;
};


export type MutationUnlinkChildTestSuiteFromParentTestSuiteArgs = {
  input: UnlinkChildTestSuiteFromParentTestSuiteInput;
};


export type MutationUnlinkCustomMetricContainerFromProjectArgs = {
  input: UnlinkCustomMetricContainerFromProjectInput;
};


export type MutationUnlinkJiraIntegrationFromTestSuiteArgs = {
  input: UnlinkJiraIntegrationFromTestSuiteInput;
};


export type MutationUnlinkTestSuiteFromBuildScheduleArgs = {
  input: UnlinkTestSuiteFromBuildScheduleInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateAccountUserArgs = {
  input: UpdateAccountUserInput;
};


export type MutationUpdateAdobeAnalyticsProjectReportSuiteArgs = {
  input: UpdateAdobeAnalyticsProjectReportSuiteInput;
};


export type MutationUpdateAdobeConnectionArgs = {
  input: UpdateAdobeConnectionInput;
};


export type MutationUpdateAdobeJwtConnectionArgs = {
  input: UpdateAdobeJwtConnectionInput;
};


export type MutationUpdateAutomatorSlackWebhookArgs = {
  input: UpdateSlackWebhookInput;
};


export type MutationUpdateBuildScheduleArgs = {
  input: UpdateBuildScheduleInput;
};


export type MutationUpdateCrawlSegmentArgs = {
  input: UpdateCrawlSegmentInput;
};


export type MutationUpdateCreditAllocationForSsoAccountArgs = {
  input: UpdateCreditAllocationForSsoAccountInput;
};


export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUserInput;
};


export type MutationUpdateCustomChartArgs = {
  input: UpdateCustomChartInput;
};


export type MutationUpdateCustomDashboardArgs = {
  input: UpdateCustomDashboardInput;
};


export type MutationUpdateCustomDashboardCollectionArgs = {
  input: UpdateCustomDashboardCollectionInput;
};


export type MutationUpdateCustomMetricContainerArgs = {
  input: UpdateCustomMetricContainerInput;
};


export type MutationUpdateCustomMetricContainerProjectArgs = {
  input: UpdateCustomMetricContainerProjectInput;
};


export type MutationUpdateCustomMetricUploadContainerArgs = {
  input: UpdateCustomMetricUploadContainerInput;
};


export type MutationUpdateCustomReportTemplateArgs = {
  input: UpdateCustomReportTemplateInput;
};


export type MutationUpdateCustomTableArgs = {
  input: UpdateCustomTableInput;
};


export type MutationUpdateGoogleAnalytics4ProjectPropertyArgs = {
  input: UpdateGoogleAnalytics4ProjectPropertyInput;
};


export type MutationUpdateGoogleSearchConsoleConfigurationArgs = {
  input: UpdateGoogleSearchConsoleConfigurationInput;
};


export type MutationUpdateGoogleSearchConsolePropertyArgs = {
  input: UpdateGoogleSearchConsolePropertyInput;
};


export type MutationUpdateJiraAuthenticationArgs = {
  input: UpdateJiraAuthenticationInput;
};


export type MutationUpdateJiraIntegrationArgs = {
  input: UpdateJiraIntegrationInput;
};


export type MutationUpdateLegacyTaskArgs = {
  input: UpdateLegacyTaskInput;
};


export type MutationUpdateLogzioConnectionArgs = {
  input: UpdateLogzioConnectionInput;
};


export type MutationUpdateLogzioProjectQueryArgs = {
  input: UpdateLogzioProjectQueryInput;
};


export type MutationUpdateMajesticConfigurationArgs = {
  input: UpdateMajesticConfigurationInput;
};


export type MutationUpdateMonitorNotificationsStatusArgs = {
  input: UpdateMonitorNotificationsStatusInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationUpdateReportTemplateOverrideArgs = {
  input: UpdateReportTemplateOverrideInput;
};


export type MutationUpdateScheduleArgs = {
  input: UpdateScheduleInput;
};


export type MutationUpdateSegmentArgs = {
  input: UpdateSegmentInput;
};


export type MutationUpdateSegmentsArgs = {
  inputs: Array<UpdateSegmentInput>;
};


export type MutationUpdateSplunkConnectionArgs = {
  input: UpdateSplunkConnectionInput;
};


export type MutationUpdateSplunkProjectQueryArgs = {
  input: UpdateSplunkProjectQueryInput;
};


export type MutationUpdateSsoAccountArgs = {
  input: UpdateSsoAccountInput;
};


export type MutationUpdateSsoAccountSubscriptionArgs = {
  input: UpdateSsoAccountSubscriptionInput;
};


export type MutationUpdateTestArgs = {
  input: UpdateTestInput;
};


export type MutationUpdateTestResultArgs = {
  input: UpdateTestResultInput;
};


export type MutationUpdateTestSuiteArgs = {
  input: UpdateTestSuiteInput;
};


export type MutationUpdateTestSuiteEmailAlertArgs = {
  input: UpdateTestSuiteEmailAlertInput;
};


export type MutationUpdateTestsArgs = {
  inputs: Array<UpdateTestInput>;
};


export type MutationUpdateUrlFileUploadArgs = {
  input: UpdateUrlFileUploadInput;
};


export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Package = {
  active: Scalars['Boolean']['output'];
  bespoke: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  credits: Scalars['Int']['output'];
  isAnnual: Scalars['Boolean']['output'];
  minCommitmentPeriod: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  packageType: Scalars['String']['output'];
  projects: Scalars['Int']['output'];
  trainingSessions: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
};

export type PackageAccount = {
  active: Scalars['Boolean']['output'];
  chargebee: Scalars['Boolean']['output'];
  chargebeeId?: Maybe<Scalars['String']['output']>;
  chargebeeSiteRef?: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PauseCrawlingInput = {
  crawlId: Scalars['ObjectID']['input'];
};

export type PauseCrawlingPayload = {
  crawl: Crawl;
};

export enum PauseReason {
  CreditLimit = 'CreditLimit',
  CustomExtractionTimeout = 'CustomExtractionTimeout',
  LevelLimit = 'LevelLimit',
  Manual = 'Manual',
  PageGroupingTimeout = 'PageGroupingTimeout',
  UrlLimit = 'UrlLimit',
  UrlsExcludedTimeout = 'UrlsExcludedTimeout',
  UrlsIncludedTimeout = 'UrlsIncludedTimeout'
}

export type Permission = {
  code: PermissionCode;
};

export enum PermissionCode {
  Admin = 'Admin',
  /** @deprecated No longer in use. */
  AdminGrant = 'AdminGrant',
  /** @deprecated No longer in use. */
  Beta = 'Beta',
  /** @deprecated No longer in use. */
  Dev = 'Dev',
  SsoClient = 'SsoClient'
}

export type Project = BaseProject & {
  account: Account;
  adobeAnalyticsProjectReportSuite?: Maybe<AdobeAnalyticsProjectReportSuite>;
  alertEmails?: Maybe<Array<Scalars['String']['output']>>;
  alertSetting: AlertSetting;
  allPagesTotal?: Maybe<Scalars['Int']['output']>;
  apiCallbackHeaders?: Maybe<Array<ApiCallbackHeader>>;
  apiCallbackUrl?: Maybe<Scalars['String']['output']>;
  applyCustomHeadersToNavigationRequestsOnly: Scalars['Boolean']['output'];
  autoFinalizeOnCrawlLimits: Scalars['Boolean']['output'];
  /** Flags if threshold on all tests is calculated automatically, based on previous test results. */
  automaticThreshold: Scalars['Boolean']['output'];
  /** Flags if automatic thresholds are calculated for all (true) or some (false) tests. */
  automaticThresholdEnabledForAll: Scalars['Boolean']['output'];
  /** Blocks third-party cookies. */
  block3rdPartyCookies: Scalars['Boolean']['output'];
  clonedAt?: Maybe<Scalars['DateTime']['output']>;
  compareToCrawl?: Maybe<Scalars['CompareToCrawl']['output']>;
  coreUIUrl: Scalars['String']['output'];
  crawlDisallowedUrls1stLevel: Scalars['Boolean']['output'];
  crawlHyperlinksExternal: Scalars['Boolean']['output'];
  crawlHyperlinksInternal: Scalars['Boolean']['output'];
  crawlImagesExternal: Scalars['Boolean']['output'];
  crawlImagesInternal: Scalars['Boolean']['output'];
  crawlNofollowHyperlinks: Scalars['Boolean']['output'];
  crawlNonHtml: Scalars['Boolean']['output'];
  crawlNotIncluded1stLevel: Scalars['Boolean']['output'];
  crawlOtherRelExternal: Scalars['Boolean']['output'];
  crawlOtherRelInternal: Scalars['Boolean']['output'];
  /** @deprecated Renamed to maximumCrawlRate. */
  crawlRate: Scalars['Float']['output'];
  crawlRedirectsExternal: Scalars['Boolean']['output'];
  crawlRedirectsInternal: Scalars['Boolean']['output'];
  crawlRelAmphtmlExternal: Scalars['Boolean']['output'];
  crawlRelAmphtmlInternal: Scalars['Boolean']['output'];
  crawlRelCanonicalsExternal: Scalars['Boolean']['output'];
  crawlRelCanonicalsInternal: Scalars['Boolean']['output'];
  crawlRelHreflangsExternal: Scalars['Boolean']['output'];
  crawlRelHreflangsInternal: Scalars['Boolean']['output'];
  crawlRelMobileExternal: Scalars['Boolean']['output'];
  crawlRelMobileInternal: Scalars['Boolean']['output'];
  crawlRelNextPrevExternal: Scalars['Boolean']['output'];
  crawlRelNextPrevInternal: Scalars['Boolean']['output'];
  crawlRobotsTxtNoindex: Scalars['Boolean']['output'];
  crawlScriptsExternal: Scalars['Boolean']['output'];
  crawlScriptsInternal: Scalars['Boolean']['output'];
  crawlSitemapsExternal: Scalars['Boolean']['output'];
  crawlSitemapsInternal: Scalars['Boolean']['output'];
  crawlStylesheetsExternal: Scalars['Boolean']['output'];
  crawlStylesheetsInternal: Scalars['Boolean']['output'];
  crawlTestSite: Scalars['Boolean']['output'];
  crawlTypes: Array<CrawlType>;
  crawlUrlsTotal?: Maybe<Scalars['Int']['output']>;
  crawlingAt?: Maybe<Scalars['DateTime']['output']>;
  crawls: CrawlConnection;
  crawlsTotalCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  customDns?: Maybe<Array<CustomDnsSetting>>;
  /** Use regular expressions to extract custom information from pages when they are crawled. */
  customExtractions?: Maybe<Array<CustomExtractionSetting>>;
  customMetricContainerProjects: CustomMetricContainerProjectConnection;
  customMetricContainers: CustomMetricContainerConnection;
  customMetrics: Array<CustomMetric>;
  customReportTemplates: CustomReportTemplateConnection;
  customRequestHeaders?: Maybe<Scalars['JSONObject']['output']>;
  customViews: CustomViewConnection;
  dataLayerName?: Maybe<Scalars['String']['output']>;
  dataOnlyCrawlTypes?: Maybe<Array<CrawlType>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discoverSitemapsInRobotsTxt: Scalars['Boolean']['output'];
  duplicatePrecision: Scalars['Float']['output'];
  emptyPageThreshold?: Maybe<Scalars['Int']['output']>;
  enableKeyValueStore: Scalars['Boolean']['output'];
  excludeUrlPatterns?: Maybe<Array<Scalars['String']['output']>>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  flattenIframes: Scalars['Boolean']['output'];
  flattenShadowDom: Scalars['Boolean']['output'];
  gaDateRange: Scalars['Int']['output'];
  googleAnalytics4ProjectProperty?: Maybe<GoogleAnalytics4ProjectProperty>;
  googleSearchConsoleConfiguration?: Maybe<GoogleSearchConsoleConfiguration>;
  googleSearchConsoleProperties?: Maybe<GoogleSearchConsolePropertyConnection>;
  /** @deprecated Use moduleCode */
  hasAccessibility: Scalars['Boolean']['output'];
  hasTestSitePassword: Scalars['Boolean']['output'];
  healthScore: Array<ProjectHealthScoreItem>;
  /** @deprecated Renamed to logSummaryRequestsHigh. */
  highLogSummaryRequests: Scalars['Int']['output'];
  id: Scalars['ObjectID']['output'];
  ignoreInvalidSSLCertificate?: Maybe<Scalars['Boolean']['output']>;
  ignoreRobotsForNavigationRequests: Scalars['Boolean']['output'];
  includeBestPractices: Scalars['Boolean']['output'];
  includeHttpAndHttps: Scalars['Boolean']['output'];
  includeSubdomains: Scalars['Boolean']['output'];
  includeUrlPatterns?: Maybe<Array<Scalars['String']['output']>>;
  industryCode?: Maybe<Scalars['String']['output']>;
  isTestSuite: Scalars['Boolean']['output'];
  /** Same as crawlingAt, but includes dates of deleted crawls. */
  lastCrawlCrawlingAt?: Maybe<Scalars['DateTime']['output']>;
  lastCrawlStatus?: Maybe<CrawlStatus>;
  lastFinishedCrawl?: Maybe<Crawl>;
  legacyTasks: LegacyTaskConnection;
  legacyTasksTotalCount: Scalars['Int']['output'];
  limitLevelsMax: Scalars['Int']['output'];
  /** @deprecated Renamed to limitUrlsMax. */
  limitPagesMax: Scalars['Int']['output'];
  limitUrlsMax: Scalars['Int']['output'];
  location: Location;
  logSummaryRequestsHigh: Scalars['Int']['output'];
  logSummaryRequestsLow: Scalars['Int']['output'];
  logzioProjectQueries: LogzioProjectQueryConnection;
  /** @deprecated Renamed to logSummaryRequestsLow. */
  lowLogSummaryRequests: Scalars['Int']['output'];
  majesticConfiguration?: Maybe<MajesticConfiguration>;
  maxBodyContentLength?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Renamed to maxBodyContentLength. */
  maxContentSize: Scalars['Int']['output'];
  maxDescriptionLength?: Maybe<Scalars['Int']['output']>;
  maxExternalLinks?: Maybe<Scalars['Int']['output']>;
  maxHtmlSize?: Maybe<Scalars['Int']['output']>;
  maxLinks?: Maybe<Scalars['Int']['output']>;
  maxLinksProcessed: Scalars['Int']['output'];
  maxLoadTime?: Maybe<Scalars['Float']['output']>;
  maxRedirections: Scalars['Int']['output'];
  maxTitleWidth: Scalars['Int']['output'];
  maxUrlLength?: Maybe<Scalars['Int']['output']>;
  maximumCrawlRate: Scalars['Float']['output'];
  maximumCrawlRateAdvanced?: Maybe<Array<AdvancedCrawlRate>>;
  minContentRatio?: Maybe<Scalars['Float']['output']>;
  minDescriptionLength: Scalars['Int']['output'];
  minTitleLength: Scalars['Int']['output'];
  minVisits: Scalars['Int']['output'];
  mobileHomepageUrl?: Maybe<Scalars['String']['output']>;
  mobileUrlPattern?: Maybe<Scalars['String']['output']>;
  mobileUserAgent?: Maybe<UserAgent>;
  /** Overrides the viewport height during crawling of the mobile site. */
  mobileViewportHeight?: Maybe<Scalars['Int']['output']>;
  /** Overrides the vieport width during crawling of the mobile site. */
  mobileViewportWidth?: Maybe<Scalars['Int']['output']>;
  moduleCode: ModuleCode;
  name: Scalars['String']['output'];
  primaryDomain: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  renderTimeout: Scalars['Int']['output'];
  renderWithImages: Scalars['Boolean']['output'];
  rendererBlockAds: Scalars['Boolean']['output'];
  rendererBlockAnalytics: Scalars['Boolean']['output'];
  rendererBlockCustom?: Maybe<Array<Scalars['String']['output']>>;
  rendererCookies?: Maybe<Array<RendererCookie>>;
  rendererJsString?: Maybe<Scalars['String']['output']>;
  rendererJsUrls?: Maybe<Array<Scalars['String']['output']>>;
  renderingRobotsCheckMode?: Maybe<RenderingRobotsCheckMode>;
  reportTemplateOverrides: ReportTemplateOverrideConnection;
  reportTrend: Array<ProjectReportTrendItem>;
  robotsOverwrite?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<Schedule>;
  schedulesTotalCount: Scalars['Int']['output'];
  secondaryDomains?: Maybe<Array<Scalars['String']['output']>>;
  segmentCountsByGroup: Array<SegmentCountByGroup>;
  segments?: Maybe<SegmentConnection>;
  segmentsTotalCount: Scalars['Int']['output'];
  selectedWcagLevel: WcagLevel;
  selectedWcagVersion: Scalars['Float']['output'];
  /** @deprecated The flag is no longer being used. */
  serpentCrawlerEnabled?: Maybe<Scalars['Boolean']['output']>;
  serpentPlatformId?: Maybe<Scalars['String']['output']>;
  singlePageRequesterRequests?: Maybe<SinglePageRequesterRequestConnection>;
  /** @deprecated Renamed to primaryDomain. */
  sitePrimary: Scalars['String']['output'];
  /** @deprecated Renamed to testSiteDomain. */
  siteTest?: Maybe<Scalars['String']['output']>;
  sitemapUrls?: Maybe<Array<Scalars['String']['output']>>;
  sitemaps: Array<Sitemap>;
  splunkProjectQueries: SplunkProjectQueryConnection;
  startUrls?: Maybe<Array<Scalars['String']['output']>>;
  storeHtml: Scalars['Boolean']['output'];
  targetMaxUncrawledUrlsCount: Scalars['Int']['output'];
  testSiteDomain?: Maybe<Scalars['String']['output']>;
  testSiteUsername?: Maybe<Scalars['String']['output']>;
  tests: TestConnection;
  testsTotalCount: Scalars['Int']['output'];
  thinPageThreshold?: Maybe<Scalars['Int']['output']>;
  totalSteps?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  urlFileUploads: UrlFileUploadConnection;
  urlRewriteQueryParameters?: Maybe<Array<Scalars['String']['output']>>;
  urlRewriteRules?: Maybe<Array<UrlRewriteRule>>;
  urlRewriteStripFragment: Scalars['Boolean']['output'];
  urlSampling?: Maybe<Array<UrlSampling>>;
  /** @deprecated Renamed to excludeUrlPatterns. */
  urlsExcluded?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Renamed to includeUrlPatterns. */
  urlsIncluded?: Maybe<Array<Scalars['String']['output']>>;
  useMobileSettings: Scalars['Boolean']['output'];
  /** Use new Chrome headless mode. */
  useNewHeadlessMode: Scalars['Boolean']['output'];
  useOptimus: Scalars['Boolean']['output'];
  useRenderer: Scalars['Boolean']['output'];
  useRobotsOverwrite: Scalars['Boolean']['output'];
  useStealthMode: Scalars['Boolean']['output'];
  useStealthModeV2: Scalars['Boolean']['output'];
  useUrlRewriteRules: Scalars['Boolean']['output'];
  userAgent?: Maybe<UserAgent>;
  userAgentIsMobile?: Maybe<Scalars['Boolean']['output']>;
  userAgentMobileIsMobile?: Maybe<Scalars['Boolean']['output']>;
  userAgentString?: Maybe<Scalars['String']['output']>;
  userAgentStringMobile?: Maybe<Scalars['String']['output']>;
  userAgentToken?: Maybe<Scalars['String']['output']>;
  userAgentTokenMobile?: Maybe<Scalars['String']['output']>;
  /** Overrides the default viewport height during crawling, if present. */
  viewportHeight?: Maybe<Scalars['Int']['output']>;
  /** Overrides the default viewport width during crawling, if present. */
  viewportWidth?: Maybe<Scalars['Int']['output']>;
};


export type ProjectCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlOrder>>;
};


export type ProjectCustomMetricContainerProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerProjectConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerProjectOrder>>;
};


export type ProjectCustomMetricContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerOrder>>;
};


export type ProjectCustomReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomReportTemplateConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomReportTemplateOrder>>;
};


export type ProjectCustomViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomViewOrder>>;
};


export type ProjectGoogleSearchConsolePropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GoogleSearchConsolePropertyOrder>>;
};


export type ProjectHealthScoreArgs = {
  reportCategoryCode: Scalars['String']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
  trendLimit?: Scalars['Int']['input'];
};


export type ProjectLegacyTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LegacyTaskConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyTaskOrder>>;
};


export type ProjectLogzioProjectQueriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LogzioProjectQueryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LogzioProjectQueryOrder>>;
};


export type ProjectReportTemplateOverridesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportTemplateOverrideConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportTemplateOverrideOrder>>;
};


export type ProjectReportTrendArgs = {
  reportTemplateCode: Scalars['String']['input'];
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type ProjectSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SegmentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SegmentOrder>>;
};


export type ProjectSinglePageRequesterRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SinglePageRequesterRequestConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SinglePageRequesterRequestOrder>>;
};


export type ProjectSplunkProjectQueriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SplunkProjectQueryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SplunkProjectQueryOrder>>;
};


export type ProjectTestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestOrder>>;
};


export type ProjectUrlFileUploadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UrlFileUploadConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProjectConnection = {
  /** A list of edges. */
  edges: Array<ProjectEdge>;
  /** A list of nodes. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Projects in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ProjectConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ProjectConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ProjectConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ProjectConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  allPagesTotal?: InputMaybe<ConnectionIntFilterInput>;
  applyCustomHeadersToNavigationRequestsOnly?: InputMaybe<ConnectionBooleanFilterInput>;
  block3rdPartyCookies?: InputMaybe<ConnectionBooleanFilterInput>;
  clonedAt?: InputMaybe<ConnectionDateFilterInput>;
  crawlRate?: InputMaybe<ConnectionFloatFilterInput>;
  crawlUrlsTotal?: InputMaybe<ConnectionIntFilterInput>;
  crawlingAt?: InputMaybe<ConnectionDateFilterInput>;
  crawlsTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  deletedAt?: InputMaybe<ConnectionDateFilterInput>;
  duplicatePrecision?: InputMaybe<ConnectionFloatFilterInput>;
  emptyPageThreshold?: InputMaybe<ConnectionIntFilterInput>;
  enableKeyValueStore?: InputMaybe<ConnectionBooleanFilterInput>;
  finishedAt?: InputMaybe<ConnectionDateFilterInput>;
  flattenIframes?: InputMaybe<ConnectionBooleanFilterInput>;
  flattenShadowDom?: InputMaybe<ConnectionBooleanFilterInput>;
  highLogSummaryRequests?: InputMaybe<ConnectionIntFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  ignoreRobotsForNavigationRequests?: InputMaybe<ConnectionBooleanFilterInput>;
  includeBestPractices?: InputMaybe<ConnectionBooleanFilterInput>;
  industryCode?: InputMaybe<ConnectionStringFilterInput>;
  isTestSuite?: InputMaybe<ConnectionBooleanFilterInput>;
  lastCrawlCrawlingAt?: InputMaybe<ConnectionDateFilterInput>;
  lastCrawlStatus?: InputMaybe<ConnectionCrawlStatusFilterInput>;
  legacyTasksTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  limitPagesMax?: InputMaybe<ConnectionIntFilterInput>;
  lowLogSummaryRequests?: InputMaybe<ConnectionIntFilterInput>;
  maxContentSize?: InputMaybe<ConnectionIntFilterInput>;
  maxDescriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  maxExternalLinks?: InputMaybe<ConnectionIntFilterInput>;
  maxHtmlSize?: InputMaybe<ConnectionIntFilterInput>;
  maxLinks?: InputMaybe<ConnectionIntFilterInput>;
  maxLoadTime?: InputMaybe<ConnectionFloatFilterInput>;
  maxRedirections?: InputMaybe<ConnectionIntFilterInput>;
  maxTitleWidth?: InputMaybe<ConnectionIntFilterInput>;
  maxUrlLength?: InputMaybe<ConnectionIntFilterInput>;
  minContentRatio?: InputMaybe<ConnectionFloatFilterInput>;
  minDescriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  minTitleLength?: InputMaybe<ConnectionIntFilterInput>;
  moduleCode?: InputMaybe<ConnectionModuleCodeFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  renderTimeout?: InputMaybe<ConnectionIntFilterInput>;
  renderWithImages?: InputMaybe<ConnectionBooleanFilterInput>;
  rendererBlockAds?: InputMaybe<ConnectionBooleanFilterInput>;
  rendererBlockAnalytics?: InputMaybe<ConnectionBooleanFilterInput>;
  rendererJsString?: InputMaybe<ConnectionStringFilterInput>;
  renderingRobotsCheckMode?: InputMaybe<ConnectionRenderingRobotsCheckModeFilterInput>;
  robotsOverwrite?: InputMaybe<ConnectionStringFilterInput>;
  schedulesTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  segmentsTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  selectedWcagLevel?: InputMaybe<ConnectionWcagLevelFilterInput>;
  selectedWcagVersion?: InputMaybe<ConnectionFloatFilterInput>;
  serpentCrawlerEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  serpentPlatformId?: InputMaybe<ConnectionStringFilterInput>;
  sitePrimary?: InputMaybe<ConnectionStringFilterInput>;
  siteTest?: InputMaybe<ConnectionStringFilterInput>;
  targetMaxUncrawledUrlsCount?: InputMaybe<ConnectionIntFilterInput>;
  testsTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  thinPageThreshold?: InputMaybe<ConnectionIntFilterInput>;
  totalSteps?: InputMaybe<ConnectionIntFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  useNewHeadlessMode?: InputMaybe<ConnectionBooleanFilterInput>;
  useRenderer?: InputMaybe<ConnectionBooleanFilterInput>;
  useRobotsOverwrite?: InputMaybe<ConnectionBooleanFilterInput>;
};

export type ProjectEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Project;
};

export type ProjectHealthScoreItem = {
  categoryWeight: Scalars['Float']['output'];
  crawlId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  healthScore: Scalars['Float']['output'];
};

export type ProjectOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Projects by. */
  field: ProjectOrderField;
};

export enum ProjectOrderField {
  AllPagesTotal = 'allPagesTotal',
  ClonedAt = 'clonedAt',
  CrawlRate = 'crawlRate',
  CrawlUrlsTotal = 'crawlUrlsTotal',
  CrawlingAt = 'crawlingAt',
  CrawlsTotalCount = 'crawlsTotalCount',
  CreatedAt = 'createdAt',
  DuplicatePrecision = 'duplicatePrecision',
  EmptyPageThreshold = 'emptyPageThreshold',
  EnableKeyValueStore = 'enableKeyValueStore',
  FinishedAt = 'finishedAt',
  FlattenIframes = 'flattenIframes',
  FlattenShadowDom = 'flattenShadowDom',
  HighLogSummaryRequests = 'highLogSummaryRequests',
  Id = 'id',
  IncludeBestPractices = 'includeBestPractices',
  IndustryCode = 'industryCode',
  IsTestSuite = 'isTestSuite',
  LastCrawlCrawlingAt = 'lastCrawlCrawlingAt',
  LastCrawlStatus = 'lastCrawlStatus',
  LegacyTasksTotalCount = 'legacyTasksTotalCount',
  LimitPagesMax = 'limitPagesMax',
  LowLogSummaryRequests = 'lowLogSummaryRequests',
  MaxContentSize = 'maxContentSize',
  MaxDescriptionLength = 'maxDescriptionLength',
  MaxExternalLinks = 'maxExternalLinks',
  MaxHtmlSize = 'maxHtmlSize',
  MaxLinks = 'maxLinks',
  MaxLoadTime = 'maxLoadTime',
  MaxRedirections = 'maxRedirections',
  MaxTitleWidth = 'maxTitleWidth',
  MaxUrlLength = 'maxUrlLength',
  MinContentRatio = 'minContentRatio',
  MinDescriptionLength = 'minDescriptionLength',
  MinTitleLength = 'minTitleLength',
  Name = 'name',
  RenderTimeout = 'renderTimeout',
  RenderWithImages = 'renderWithImages',
  RendererBlockAds = 'rendererBlockAds',
  RendererBlockAnalytics = 'rendererBlockAnalytics',
  RendererJsString = 'rendererJsString',
  RenderingRobotsCheckMode = 'renderingRobotsCheckMode',
  RobotsOverwrite = 'robotsOverwrite',
  SchedulesTotalCount = 'schedulesTotalCount',
  SegmentsTotalCount = 'segmentsTotalCount',
  SelectedWcagLevel = 'selectedWcagLevel',
  SelectedWcagVersion = 'selectedWcagVersion',
  SitePrimary = 'sitePrimary',
  SiteTest = 'siteTest',
  TargetMaxUncrawledUrlsCount = 'targetMaxUncrawledUrlsCount',
  TestsTotalCount = 'testsTotalCount',
  ThinPageThreshold = 'thinPageThreshold',
  TotalSteps = 'totalSteps',
  UpdatedAt = 'updatedAt',
  UseRenderer = 'useRenderer',
  UseRobotsOverwrite = 'useRobotsOverwrite'
}

export type ProjectReportTrendItem = {
  added?: Maybe<Scalars['Float']['output']>;
  basic?: Maybe<Scalars['Float']['output']>;
  crawlId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  missing?: Maybe<Scalars['Float']['output']>;
  removed?: Maybe<Scalars['Float']['output']>;
  totalWeight?: Maybe<Scalars['Float']['output']>;
};

export enum ProjectUploadType {
  AdobeAnalyticsCsv = 'AdobeAnalyticsCSV',
  AdwordsFinalUrlCsv = 'AdwordsFinalUrlCSV',
  AhrefsBacklinksCsv = 'AhrefsBacklinksCSV',
  AhrefsBacklinksCsv2 = 'AhrefsBacklinksCSV2',
  AnalyticsCsv = 'AnalyticsCSV',
  BacklinksCsv = 'BacklinksCSV',
  Custom = 'Custom',
  CustomMetricsCsv = 'CustomMetricsCsv',
  GenericBacklinksCsv = 'GenericBacklinksCSV',
  GenericLogfileCsv = 'GenericLogfileCSV',
  GoogleAnalyticsCsv = 'GoogleAnalyticsCSV',
  ListTxt = 'ListTxt',
  LogzioLogfileDesktopCsv = 'LogzioLogfileDesktopCSV',
  LogzioLogfileMobileCsv = 'LogzioLogfileMobileCSV',
  MajesticBacklinksCsv = 'MajesticBacklinksCSV',
  MajesticBacklinksCsv2 = 'MajesticBacklinksCSV2',
  OseBacklinksCsv = 'OSEBacklinksCSV',
  ReducedAdobeAnalyticsCsv = 'ReducedAdobeAnalyticsCSV',
  ScreamingFrogLogfileBaiduCsv = 'ScreamingFrogLogfileBaiduCSV',
  ScreamingFrogLogfileBingbotCsv = 'ScreamingFrogLogfileBingbotCSV',
  ScreamingFrogLogfileGooglebotCsv = 'ScreamingFrogLogfileGooglebotCSV',
  ScreamingFrogLogfileGooglebotOnlyCsv = 'ScreamingFrogLogfileGooglebotOnlyCSV',
  ScreamingFrogLogfileGooglebotOnlyCsv2 = 'ScreamingFrogLogfileGooglebotOnlyCSV2',
  ScreamingFrogLogfileYandexCsv = 'ScreamingFrogLogfileYandexCSV',
  Sitemap = 'Sitemap',
  SplunkLogfileBingCsv = 'SplunkLogfileBingCSV',
  SplunkLogfileDesktopCsv = 'SplunkLogfileDesktopCSV',
  SplunkLogfileGoogleCsv = 'SplunkLogfileGoogleCSV',
  SplunkLogfileMobileCsv = 'SplunkLogfileMobileCSV'
}

export type Proxy = {
  accountId?: Maybe<Scalars['ObjectID']['output']>;
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ObjectID']['output'];
  locationCode: LocationCode;
  rawID: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProxyConnection = {
  /** A list of edges. */
  edges: Array<ProxyEdge>;
  /** A list of nodes. */
  nodes: Array<Proxy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Proxies in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ProxyConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ProxyConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ProxyConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ProxyConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  enabled?: InputMaybe<ConnectionBooleanFilterInput>;
  locationCode?: InputMaybe<ConnectionLocationCodeFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
};

export type ProxyEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Proxy;
};

export type ProxyOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Proxies by. */
  field: ProxyOrderField;
};

export enum ProxyOrderField {
  Enabled = 'enabled',
  LocationCode = 'locationCode',
  Url = 'url'
}

export type Query = {
  accessToken?: Maybe<Scalars['JSONObject']['output']>;
  datasource?: Maybe<Datasource>;
  /** @deprecated Use getDatasources instead. */
  datasources: Array<Datasource>;
  getAccessibilityIssueSolutionSuggestion?: Maybe<AccessibilityIssueSolutionSuggestion>;
  /** Look up account by ObjectID. */
  getAccount?: Maybe<Account>;
  getAccountSubscriptionPlan?: Maybe<AccountSubscriptionPlan>;
  /** List of available plans. */
  getAccountSubscriptionPlans: Array<AccountSubscriptionPlan>;
  getAccounts: AccountConnection;
  /** List of available alert settings. */
  getAlertSettings: Array<AlertSetting>;
  /** Look up automator account statistics by account ObjectID. */
  getAutomatorAccountStatistics: AutomatorAccountStatistics;
  /** Find Automator Report Templates by connection arguments. */
  getAutomatorReportTemplates: ReportTemplateConnection;
  /** Look up build by ObjectID. */
  getBuild?: Maybe<Build>;
  /** Look up buildSchedule by ObjectID. */
  getBuildSchedule?: Maybe<BuildSchedule>;
  /** Look up crawl by ObjectID. */
  getCrawl?: Maybe<Crawl>;
  /** List of available CrawlTypes. */
  getCrawlTypesMetadata: Array<CrawlTypeMetadata>;
  /** Look up custom chart by ObjectID. */
  getCustomChart?: Maybe<CustomChart>;
  /** Look up custom dashboard by ObjectID. */
  getCustomDashboard?: Maybe<CustomDashboard>;
  /** Look up custom dashboard collection by ObjectID. */
  getCustomDashboardCollection?: Maybe<CustomDashboardCollection>;
  /** Look up CustomMetricContainer by ObjectID. */
  getCustomMetricContainer?: Maybe<CustomMetricContainer>;
  getCustomMetricContainerVersion?: Maybe<CustomMetricContainerVersion>;
  getCustomMetricContainerVersionByVersion?: Maybe<CustomMetricContainerVersion>;
  getCustomMetricUploadContainer?: Maybe<CustomMetricUploadContainer>;
  getCustomReport?: Maybe<CustomReport>;
  /** Look up CustomReportTemplate by ObjectID. */
  getCustomReportTemplate?: Maybe<CustomReportTemplate>;
  getCustomReports?: Maybe<Array<CustomReport>>;
  /** Look up custom table by ObjectID. */
  getCustomTable?: Maybe<CustomTable>;
  getDatasources: Array<Datasource>;
  /**
   * List of available DefaultCustomMetricContainer.
   * @deprecated No longer in used.
   */
  getDefaultCustomMetricContainers: Array<DefaultCustomMetricContainer>;
  getFeatureCosts: Array<FeatureCost>;
  getGlobalCustomMetricContainers: CustomMetricContainerConnection;
  getHealthScore?: Maybe<HealthScore>;
  /** Returns the health score trend for a given Crawl. */
  getHealthScoreTrendForCrawl: CrawlHealthScoreTrendConnection;
  /** Returns the health score trend for a given CrawlSegment. */
  getHealthScoreTrendForCrawlSegment: CrawlSegmentHealthScoreTrendConnection;
  getHealthScores?: Maybe<Array<HealthScore>>;
  /**
   * Returns CrawlSegment health scores for a list of report category codes.
   * @deprecated Use getHealthScores query instead.
   */
  getHealthScoresForCrawlSegments: CrawlSegmentHealthScoreConnection;
  /**
   * Returns Crawl health scores for a list of report category codes.
   * @deprecated Use getHealthScores query instead.
   */
  getHealthScoresForCrawls: CrawlHealthScoreConnection;
  getIndustries: IndustryConnection;
  getIndustryBenchmarks: IndustryBenchmarkConnection;
  getJiraIssueTypes: Array<JiraIssueType>;
  getJiraProjects: Array<JiraProject>;
  getJiraSettings: JiraSettings;
  getLegacyTasksForProjects: LegacyTaskConnection;
  getModule?: Maybe<Module>;
  getModules?: Maybe<Array<Module>>;
  /** Look up project by ObjectID. */
  getProject?: Maybe<Project>;
  /** @deprecated Use getReportStat instead. */
  getReport?: Maybe<Report>;
  getReportCategories: ReportCategoryConnection;
  getReportStat?: Maybe<ReportStat>;
  getReportStats?: Maybe<Array<ReportStat>>;
  /** Find ReportTemplateVersions by connection arguments. */
  getReportTemplateVersions: ReportTemplateVersionConnection;
  /** Find Report Templates by connection arguments. */
  getReportTemplates: ReportTemplateConnection;
  /** List of available report types. */
  getReportTypes: Array<ReportType>;
  /** @deprecated Use Crawl.reportStats instead. */
  getReportsForCrawls: ReportScrollConnection;
  /** List of available roles. */
  getRoles: Array<Role>;
  /** List of available schedule frequencies. */
  getScheduleFrequencies: Array<ScheduleFrequency>;
  /** Look up segment by ObjectID. */
  getSegment?: Maybe<Segment>;
  /** Look up SinglePageRequesterRequest by ObjectID. */
  getSinglePageRequesterRequest?: Maybe<SinglePageRequesterRequest>;
  /** Look up SinglePageRequesterRequest by request ID. */
  getSinglePageRequesterRequestByRequestId?: Maybe<SinglePageRequesterRequest>;
  /** List of available plans. */
  getSsoAccountSubscriptionPlans: Array<AccountSubscriptionPlan>;
  /** Look up testSuite by ObjectID. */
  getTestSuite?: Maybe<TestSuite>;
  /** List of locations available for test suite. */
  getTestSuiteLocations: Array<Location>;
  /** List of available UrlFileUploadTypes. */
  getUrlFileUploadTypes: Array<UrlFileUploadType>;
  /** List of all available user agents. */
  getUserAgents: Array<UserAgent>;
  /** Find AccessibilityIssueSolutionSuggestion by Object ID. */
  internalGetAccessibilityIssueSolutionSuggestion: AccessibilityIssueSolutionSuggestion;
  /** Find AccessibilityIssueSolutionSuggestions by connection arguments. */
  internalGetAccessibilityIssueSolutionSuggestions: AccessibilityIssueSolutionSuggestionConnection;
  /** Find Account by Object ID. */
  internalGetAccount: Account;
  /** Find AccountSSOClients by connection arguments. */
  internalGetAccountSSOClients: AccountSsoClientConnection;
  internalGetAccountSubscriptionPlanAddons: Array<AccountSubscriptionAddon>;
  /** List of all available plans. */
  internalGetAccountSubscriptionPlans: Array<AccountSubscriptionPlan>;
  /** Find AccountUsers by connection arguments. */
  internalGetAccountUsers: AccountUserConnection;
  /** Find Accounts by connection arguments. */
  internalGetAccounts: AccountConnection;
  /** Find AdobeAnalyticsProjectReportSuite by Object ID. */
  internalGetAdobeAnalyticsProjectReportSuite: AdobeAnalyticsProjectReportSuite;
  /** Find AdobeAnalyticsProjectReportSuites by connection arguments. */
  internalGetAdobeAnalyticsProjectReportSuites: AdobeAnalyticsProjectReportSuiteConnection;
  /** Find AdobeConnection by Object ID. */
  internalGetAdobeConnection: AdobeConnection;
  /** Find AdobeConnections by connection arguments. */
  internalGetAdobeConnections: AdobeConnectionConnection;
  /** Find AdobeJwtConnection by Object ID. */
  internalGetAdobeJwtConnection: AdobeJwtConnection;
  /** Find AdobeJwtConnections by connection arguments. */
  internalGetAdobeJwtConnections: AdobeJwtConnectionConnection;
  /** A list of all available settings. */
  internalGetBaseAccountSettings: Array<BaseAccountSetting>;
  /** Find Build by Object ID. */
  internalGetBuild: Build;
  /** Find BuildSchedule by Object ID. */
  internalGetBuildSchedule: BuildSchedule;
  /** Find BuildScheduleRun by Object ID. */
  internalGetBuildScheduleRun: BuildScheduleRun;
  /** Find BuildScheduleRuns by connection arguments. */
  internalGetBuildScheduleRuns: BuildScheduleRunConnection;
  /** Find BuildScheduleTestSuite by Object ID. */
  internalGetBuildScheduleTestSuite: BuildScheduleTestSuite;
  /** Find BuildScheduleTestSuites by connection arguments. */
  internalGetBuildScheduleTestSuites: BuildScheduleTestSuiteConnection;
  /** Find BuildSchedules by connection arguments. */
  internalGetBuildSchedules: BuildScheduleConnection;
  /** Find Builds by connection arguments. */
  internalGetBuilds: BuildConnection;
  /** Find Crawl by Object ID. */
  internalGetCrawl: Crawl;
  /** Find CrawlReportTemplates by connection arguments. */
  internalGetCrawlReportTemplates: CrawlReportTemplateConnection;
  /** Find CrawlSegments by connection arguments. */
  internalGetCrawlSegments: CrawlSegmentConnection;
  /** Find CrawlSetting by Object ID. */
  internalGetCrawlSetting: CrawlSetting;
  /** Find CrawlSettings by connection arguments. */
  internalGetCrawlSettings: CrawlSettingConnection;
  /** Find CrawlType by Object ID. */
  internalGetCrawlType: CrawlTypeMetadata;
  /** Find CrawlTypes by connection arguments. */
  internalGetCrawlTypes: CrawlTypeConnection;
  /** Find CrawlUrl by Object ID. */
  internalGetCrawlUrl: CrawlUrl;
  /** Find CrawlUrls by connection arguments. */
  internalGetCrawlUrls: CrawlUrlConnection;
  /** Find Crawls by connection arguments. */
  internalGetCrawls: CrawlConnection;
  /** Find CreditAllocation by Object ID. */
  internalGetCreditAllocation: CreditAllocation;
  /** Find CreditAllocations by connection arguments. */
  internalGetCreditAllocations: CreditAllocationConnection;
  /** Find CreditReportExport by Object ID. */
  internalGetCreditReportExport: CreditReportExport;
  /** Find CreditReportExports by connection arguments. */
  internalGetCreditReportExports: CreditReportExportConnection;
  /** Find CustomChart by Object ID. */
  internalGetCustomChart: CustomChart;
  /** Find CustomCharts by connection arguments. */
  internalGetCustomCharts: CustomChartConnection;
  /** Find CustomDashboard by Object ID. */
  internalGetCustomDashboard: CustomDashboard;
  /** Find CustomDashboardCollection by Object ID. */
  internalGetCustomDashboardCollection: CustomDashboardCollection;
  /** Find CustomDashboardCollections by connection arguments. */
  internalGetCustomDashboardCollections: CustomDashboardCollectionConnection;
  /** Find CustomDashboards by connection arguments. */
  internalGetCustomDashboards: CustomDashboardConnection;
  /** Find CustomMetricContainer by Object ID. */
  internalGetCustomMetricContainer: CustomMetricContainer;
  internalGetCustomMetricContainerProject?: Maybe<CustomMetricContainerProject>;
  /** Find CustomMetricContainerProjectSecrets by connection arguments. */
  internalGetCustomMetricContainerProjectSecrets: CustomMetricContainerProjectSecretConnection;
  /** Find CustomMetricContainerProjects by connection arguments. */
  internalGetCustomMetricContainerProjects: CustomMetricContainerProjectConnection;
  /** Find CustomMetricContainerVersion by Object ID. */
  internalGetCustomMetricContainerVersion: CustomMetricContainerVersion;
  /** Find CustomMetricContainerVersionCrawls by connection arguments. */
  internalGetCustomMetricContainerVersionCrawls: CustomMetricContainerVersionCrawlConnection;
  /** Find CustomMetricContainerVersions by connection arguments. */
  internalGetCustomMetricContainerVersions: CustomMetricContainerVersionConnection;
  /** Find CustomMetricContainers by connection arguments. */
  internalGetCustomMetricContainers: CustomMetricContainerConnection;
  /** Find CustomMetricUploadContainer by Object ID. */
  internalGetCustomMetricUploadContainer: CustomMetricUploadContainer;
  /** Find CustomMetricUploadContainerCrawls by connection arguments. */
  internalGetCustomMetricUploadContainerCrawls: CustomMetricUploadContainerCrawlConnection;
  /** Find CustomMetricUploadContainers by connection arguments. */
  internalGetCustomMetricUploadContainers: CustomMetricUploadContainerConnection;
  /** Find CustomReportTemplate by Object ID. */
  internalGetCustomReportTemplate: CustomReportTemplate;
  /** Find CustomReportTemplateVersionCrawls by connection arguments. */
  internalGetCustomReportTemplateVersionCrawls: CustomReportTemplateVersionCrawlConnection;
  /** Find CustomReportTemplates by connection arguments. */
  internalGetCustomReportTemplates: CustomReportTemplateConnection;
  /** Find CustomTable by Object ID. */
  internalGetCustomTable: CustomTable;
  /** Find CustomTables by connection arguments. */
  internalGetCustomTables: CustomTableConnection;
  /** Find CustomView by Object ID. */
  internalGetCustomView: CustomView;
  /** Find CustomViews by connection arguments. */
  internalGetCustomViews: CustomViewConnection;
  internalGetDatasources: Array<Datasource>;
  internalGetFeatureCost?: Maybe<FeatureCost>;
  /** Find FeatureCosts by connection arguments. */
  internalGetFeatureCosts: FeatureCostConnection;
  /** Find GoogleAnalytics4ProjectProperties by connection arguments. */
  internalGetGoogleAnalytics4ProjectProperties: GoogleAnalytics4ProjectPropertyConnection;
  /** Find GoogleAnalytics4ProjectProperty by Object ID. */
  internalGetGoogleAnalytics4ProjectProperty: GoogleAnalytics4ProjectProperty;
  /** Find GoogleConnection by Object ID. */
  internalGetGoogleConnection: GoogleConnection;
  /** Find GoogleConnections by connection arguments. */
  internalGetGoogleConnections: GoogleConnectionConnection;
  /** Find GoogleSearchConsoleProperties by connection arguments. */
  internalGetGoogleSearchConsoleProperties: GoogleSearchConsolePropertyConnection;
  /** Find GoogleSearchConsoleProperty by Object ID. */
  internalGetGoogleSearchConsoleProperty: GoogleSearchConsoleProperty;
  /** Find JiraAuthentication by Object ID. */
  internalGetJiraAuthentication: JiraAuthentication;
  /** Find JiraAuthentications by connection arguments. */
  internalGetJiraAuthentications: JiraAuthenticationConnection;
  /** Find JiraIntegration by Object ID. */
  internalGetJiraIntegration: JiraIntegration;
  /** Find JiraIntegrations by connection arguments. */
  internalGetJiraIntegrations: JiraIntegrationConnection;
  /** Find LegacyTask by Object ID. */
  internalGetLegacyTask: LegacyTask;
  /** Find LegacyTasks by connection arguments. */
  internalGetLegacyTasks: LegacyTaskConnection;
  /** Find Location by Object ID. */
  internalGetLocation: Location;
  /** Find Locations by connection arguments. */
  internalGetLocations: LocationConnection;
  /** Find LogzioConnection by Object ID. */
  internalGetLogzioConnection: LogzioConnection;
  /** Find LogzioConnections by connection arguments. */
  internalGetLogzioConnections: LogzioConnectionConnection;
  /** Find LogzioProjectQueries by connection arguments. */
  internalGetLogzioProjectQueries: LogzioProjectQueryConnection;
  /** Find LogzioProjectQuery by Object ID. */
  internalGetLogzioProjectQuery: LogzioProjectQuery;
  /** Find Metrics by connection arguments. */
  internalGetMetrics: MetricConnection;
  /** Find Modules by connection arguments. */
  internalGetModules: ModuleConnection;
  /** List of available packages. */
  internalGetPackages: Array<Package>;
  /** Find Project by Object ID. */
  internalGetProject: Project;
  /** Find Projects by connection arguments. */
  internalGetProjects: ProjectConnection;
  /** Find Proxies by connection arguments. */
  internalGetProxies: ProxyConnection;
  /** Find Proxy by Object ID. */
  internalGetProxy: Proxy;
  /** Find Report by Object ID. */
  internalGetReport: Report;
  /** Find ReportDownload by Object ID. */
  internalGetReportDownload: ReportDownload;
  /** Find ReportDownloads by connection arguments. */
  internalGetReportDownloads: ReportDownloadConnection;
  /** Find ReportTemplate by Object ID. */
  internalGetReportTemplate: ReportTemplate;
  /** Find ReportTemplates by connection arguments. */
  internalGetReportTemplates: ReportTemplateConnection;
  /** Find Reports by connection arguments. */
  internalGetReports: ReportConnection;
  internalGetSSOClient?: Maybe<SsoClient>;
  /** Find SSOClients by connection arguments. */
  internalGetSSOClients: SsoClientConnection;
  /** Find Schedule by Object ID. */
  internalGetSchedule: Schedule;
  /** Find Schedules by connection arguments. */
  internalGetSchedules: ScheduleConnection;
  /** Find Segment by Object ID. */
  internalGetSegment: Segment;
  /** Find Segments by connection arguments. */
  internalGetSegments: SegmentConnection;
  /** Find SinglePageRequesterRequest by Object ID. */
  internalGetSinglePageRequesterRequest: SinglePageRequesterRequest;
  /** Find SinglePageRequesterRequests by connection arguments. */
  internalGetSinglePageRequesterRequests: SinglePageRequesterRequestConnection;
  /** Find SlackWebhook by Object ID. */
  internalGetSlackWebhook: SlackWebhook;
  /** Find SlackWebhooks by connection arguments. */
  internalGetSlackWebhooks: SlackWebhookConnection;
  /** Find SplunkConnection by Object ID. */
  internalGetSplunkConnection: SplunkConnection;
  /** Find SplunkConnections by connection arguments. */
  internalGetSplunkConnections: SplunkConnectionConnection;
  /** Find SplunkProjectQueries by connection arguments. */
  internalGetSplunkProjectQueries: SplunkProjectQueryConnection;
  /** Find SplunkProjectQuery by Object ID. */
  internalGetSplunkProjectQuery: SplunkProjectQuery;
  internalGetSystemSetting?: Maybe<SystemSetting>;
  internalGetSystemSettings: Array<SystemSetting>;
  internalGetTableType?: Maybe<Scalars['JSONObject']['output']>;
  /** Find Test by Object ID. */
  internalGetTest: Test;
  /** Find TestResult by Object ID. */
  internalGetTestResult: TestResult;
  /** Find TestResults by connection arguments. */
  internalGetTestResults: TestResultConnection;
  /** Find TestSuite by Object ID. */
  internalGetTestSuite: TestSuite;
  /** Find TestSuiteEmailAlert by Object ID. */
  internalGetTestSuiteEmailAlert: TestSuiteEmailAlert;
  /** Find TestSuiteEmailAlerts by connection arguments. */
  internalGetTestSuiteEmailAlerts: TestSuiteEmailAlertConnection;
  /** Find TestSuiteJiraIntegration by Object ID. */
  internalGetTestSuiteJiraIntegration: TestSuiteJiraIntegration;
  /** Find TestSuiteJiraIntegrations by connection arguments. */
  internalGetTestSuiteJiraIntegrations: TestSuiteJiraIntegrationConnection;
  /** Find TestSuites by connection arguments. */
  internalGetTestSuites: TestSuiteConnection;
  /** Find Tests by connection arguments. */
  internalGetTests: TestConnection;
  /** Find UrlFileUpload by Object ID. */
  internalGetUrlFileUpload: UrlFileUpload;
  /** Find UrlFileUploadType by Object ID. */
  internalGetUrlFileUploadType: UrlFileUploadType;
  /** Find UrlFileUploadTypes by connection arguments. */
  internalGetUrlFileUploadTypes: UrlFileUploadTypeConnection;
  /** Find UrlFileUploads by connection arguments. */
  internalGetUrlFileUploads: UrlFileUploadConnection;
  /** Find User by Object ID. */
  internalGetUser: User;
  internalGetUserAgent?: Maybe<UserAgent>;
  /** Find UserAgents by connection arguments. */
  internalGetUserAgents: UserAgentConnection;
  /** Find UserInvite by Object ID. */
  internalGetUserInvite: UserInvite;
  /** Find UserInvites by connection arguments. */
  internalGetUserInvites: UserInviteConnection;
  /** Find UserKey by Object ID. */
  internalGetUserKey: UserKey;
  /** Find UserKeys by connection arguments. */
  internalGetUserKeys: UserKeyConnection;
  /** Find Users by connection arguments. */
  internalGetUsers: UserConnection;
  /** Find Webhook by Object ID. */
  internalGetWebhook: Webhook;
  /** Find Webhooks by connection arguments. */
  internalGetWebhooks: WebhookConnection;
  /** The currently authenticated user. */
  me: User;
  /** Fetches an object given its ID. */
  node?: Maybe<Entity>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Entity>;
  sharelink?: Maybe<Sharelink>;
  version: Scalars['String']['output'];
};


export type QueryDatasourceArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  datasourceCode?: InputMaybe<DatasourceCode>;
};


export type QueryGetAccessibilityIssueSolutionSuggestionArgs = {
  crawlId: Scalars['ObjectID']['input'];
  issueDigest: Scalars['String']['input'];
};


export type QueryGetAccountArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetAccountSubscriptionPlanArgs = {
  planCode: Scalars['String']['input'];
};


export type QueryGetAccountSubscriptionPlansArgs = {
  currency?: InputMaybe<Currency>;
};


export type QueryGetAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountOrder>>;
};


export type QueryGetAutomatorAccountStatisticsArgs = {
  accountId: Scalars['ObjectID']['input'];
};


export type QueryGetAutomatorReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportTemplateConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportTemplateOrder>>;
};


export type QueryGetBuildArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetBuildScheduleArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCrawlArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCustomChartArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCustomDashboardArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCustomDashboardCollectionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCustomMetricContainerArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCustomMetricContainerVersionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCustomMetricContainerVersionByVersionArgs = {
  customMetricContainerId: Scalars['ObjectID']['input'];
  version: Scalars['Int']['input'];
};


export type QueryGetCustomMetricUploadContainerArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCustomReportArgs = {
  input: GetCustomReportInput;
};


export type QueryGetCustomReportTemplateArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetCustomReportsArgs = {
  inputs: Array<GetCustomReportInput>;
};


export type QueryGetCustomTableArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetGlobalCustomMetricContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerOrder>>;
};


export type QueryGetHealthScoreArgs = {
  input: GetHealthScoreInput;
};


export type QueryGetHealthScoreTrendForCrawlArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  crawlId: Scalars['ObjectID']['input'];
  filter?: InputMaybe<ReportCategoryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportCategoryOrder>>;
};


export type QueryGetHealthScoreTrendForCrawlSegmentArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  crawlId: Scalars['ObjectID']['input'];
  filter?: InputMaybe<ReportCategoryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportCategoryOrder>>;
  segmentId: Scalars['ObjectID']['input'];
};


export type QueryGetHealthScoresArgs = {
  inputs: Array<GetHealthScoreInput>;
};


export type QueryGetHealthScoresForCrawlSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSegmentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSegmentOrder>>;
  reportCategoryCodes: Array<Scalars['String']['input']>;
};


export type QueryGetHealthScoresForCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlOrder>>;
  reportCategoryCodes: Array<Scalars['String']['input']>;
};


export type QueryGetIndustriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IndustryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IndustryOrder>>;
};


export type QueryGetIndustryBenchmarksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<IndustryBenchmarkConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IndustryBenchmarkOrder>>;
};


export type QueryGetJiraIssueTypesArgs = {
  input: GetJiraIssueTypesInput;
};


export type QueryGetJiraProjectsArgs = {
  input: GetJiraProjectsInput;
};


export type QueryGetLegacyTasksForProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LegacyTaskConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyTaskOrder>>;
  projectIds: Array<Scalars['ObjectID']['input']>;
};


export type QueryGetModuleArgs = {
  code: ModuleCode;
};


export type QueryGetProjectArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetReportArgs = {
  input: GetReportInputType;
};


export type QueryGetReportCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportCategoryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportCategoryOrder>>;
};


export type QueryGetReportStatArgs = {
  input: GetReportStatInput;
};


export type QueryGetReportStatsArgs = {
  inputs: Array<GetReportStatInput>;
};


export type QueryGetReportTemplateVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportTemplateVersionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportTemplateVersionOrder>>;
};


export type QueryGetReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportTemplateConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportTemplateOrder>>;
};


export type QueryGetReportsForCrawlsArgs = {
  crawlIds: Array<Scalars['ObjectID']['input']>;
  filter?: InputMaybe<ReportConnectionFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  scrollId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSegmentArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetSinglePageRequesterRequestArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryGetSinglePageRequesterRequestByRequestIdArgs = {
  requestId: Scalars['String']['input'];
};


export type QueryGetTestSuiteArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetAccessibilityIssueSolutionSuggestionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetAccessibilityIssueSolutionSuggestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccessibilityIssueSolutionSuggestionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessibilityIssueSolutionSuggestionOrder>>;
};


export type QueryInternalGetAccountArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetAccountSsoClientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountSsoClientConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountSsoClientOrder>>;
};


export type QueryInternalGetAccountSubscriptionPlanAddonsArgs = {
  planCode: Scalars['String']['input'];
};


export type QueryInternalGetAccountUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountUserConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountOrder>>;
};


export type QueryInternalGetAdobeAnalyticsProjectReportSuiteArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetAdobeAnalyticsProjectReportSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AdobeAnalyticsProjectReportSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdobeAnalyticsProjectReportSuiteOrder>>;
};


export type QueryInternalGetAdobeConnectionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetAdobeConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AdobeConnectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdobeConnectionOrder>>;
};


export type QueryInternalGetAdobeJwtConnectionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetAdobeJwtConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AdobeJwtConnectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdobeJwtConnectionOrder>>;
};


export type QueryInternalGetBuildArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetBuildScheduleArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetBuildScheduleRunArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetBuildScheduleRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BuildScheduleRunConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BuildScheduleRunOrder>>;
};


export type QueryInternalGetBuildScheduleTestSuiteArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetBuildScheduleTestSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BuildScheduleTestSuiteOrder>>;
};


export type QueryInternalGetBuildSchedulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BuildScheduleConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BuildScheduleOrder>>;
};


export type QueryInternalGetBuildsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BuildConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BuildOrder>>;
};


export type QueryInternalGetCrawlArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCrawlReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlReportTemplateConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlReportTemplateOrder>>;
};


export type QueryInternalGetCrawlSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSegmentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSegmentOrder>>;
};


export type QueryInternalGetCrawlSettingArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCrawlSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSettingConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSettingOrder>>;
};


export type QueryInternalGetCrawlTypeArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCrawlTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlTypeConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlTypeOrder>>;
};


export type QueryInternalGetCrawlUrlArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCrawlUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUrlOrder>>;
};


export type QueryInternalGetCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlOrder>>;
};


export type QueryInternalGetCreditAllocationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCreditAllocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CreditAllocationConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreditAllocationOrder>>;
};


export type QueryInternalGetCreditReportExportArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCreditReportExportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CreditReportExportConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CreditReportExportOrder>>;
};


export type QueryInternalGetCustomChartArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomChartsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomChartConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomChartOrder>>;
};


export type QueryInternalGetCustomDashboardArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomDashboardCollectionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomDashboardCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomDashboardCollectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomDashboardCollectionOrder>>;
};


export type QueryInternalGetCustomDashboardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomDashboardConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomDashboardOrder>>;
};


export type QueryInternalGetCustomMetricContainerArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomMetricContainerProjectArgs = {
  customMetricContainerId: Scalars['ObjectID']['input'];
  projectId: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomMetricContainerProjectSecretsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerProjectSecretConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerProjectSecretOrder>>;
};


export type QueryInternalGetCustomMetricContainerProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerProjectConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerProjectOrder>>;
};


export type QueryInternalGetCustomMetricContainerVersionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomMetricContainerVersionCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerVersionCrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetCustomMetricContainerVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerVersionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerVersionOrder>>;
};


export type QueryInternalGetCustomMetricContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerOrder>>;
};


export type QueryInternalGetCustomMetricUploadContainerArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomMetricUploadContainerCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricUploadContainerCrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetCustomMetricUploadContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricUploadContainerConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricUploadContainerOrder>>;
};


export type QueryInternalGetCustomReportTemplateArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomReportTemplateVersionCrawlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomReportTemplateVersionCrawlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetCustomReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomReportTemplateConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomReportTemplateOrder>>;
};


export type QueryInternalGetCustomTableArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomTablesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomTableConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomTableOrder>>;
};


export type QueryInternalGetCustomViewArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetCustomViewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomViewConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomViewOrder>>;
};


export type QueryInternalGetFeatureCostArgs = {
  featureCode: Scalars['String']['input'];
};


export type QueryInternalGetFeatureCostsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FeatureCostConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeatureCostOrder>>;
};


export type QueryInternalGetGoogleAnalytics4ProjectPropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetGoogleAnalytics4ProjectPropertyArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetGoogleConnectionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetGoogleConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetGoogleSearchConsolePropertiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GoogleSearchConsolePropertyOrder>>;
};


export type QueryInternalGetGoogleSearchConsolePropertyArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetJiraAuthenticationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetJiraAuthenticationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetJiraIntegrationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetJiraIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetLegacyTaskArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetLegacyTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LegacyTaskConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyTaskOrder>>;
};


export type QueryInternalGetLocationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetLogzioConnectionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetLogzioConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LogzioConnectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LogzioConnectionOrder>>;
};


export type QueryInternalGetLogzioProjectQueriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LogzioProjectQueryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LogzioProjectQueryOrder>>;
};


export type QueryInternalGetLogzioProjectQueryArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MetricConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MetricOrder>>;
};


export type QueryInternalGetModulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetProjectArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProjectConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProjectOrder>>;
};


export type QueryInternalGetProxiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProxyConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProxyOrder>>;
};


export type QueryInternalGetProxyArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetReportArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetReportDownloadArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetReportDownloadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportDownloadConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportDownloadOrder>>;
};


export type QueryInternalGetReportTemplateArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetReportTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportTemplateConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportTemplateOrder>>;
};


export type QueryInternalGetReportsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportOrder>>;
};


export type QueryInternalGetSsoClientArgs = {
  id: Scalars['String']['input'];
};


export type QueryInternalGetSsoClientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SsoClientConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetScheduleArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetSchedulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ScheduleConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScheduleOrder>>;
};


export type QueryInternalGetSegmentArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SegmentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SegmentOrder>>;
};


export type QueryInternalGetSinglePageRequesterRequestArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetSinglePageRequesterRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SinglePageRequesterRequestConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SinglePageRequesterRequestOrder>>;
};


export type QueryInternalGetSlackWebhookArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetSlackWebhooksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SlackWebhookOrder>>;
};


export type QueryInternalGetSplunkConnectionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetSplunkConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SplunkConnectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SplunkConnectionOrder>>;
};


export type QueryInternalGetSplunkProjectQueriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SplunkProjectQueryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SplunkProjectQueryOrder>>;
};


export type QueryInternalGetSplunkProjectQueryArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetSystemSettingArgs = {
  code: Scalars['String']['input'];
};


export type QueryInternalGetTableTypeArgs = {
  name: Scalars['String']['input'];
  version?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetTestArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetTestResultArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetTestResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestResultConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestResultOrder>>;
};


export type QueryInternalGetTestSuiteArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetTestSuiteEmailAlertArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetTestSuiteEmailAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestSuiteEmailAlertOrder>>;
};


export type QueryInternalGetTestSuiteJiraIntegrationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetTestSuiteJiraIntegrationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetTestSuitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestSuiteOrder>>;
};


export type QueryInternalGetTestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestOrder>>;
};


export type QueryInternalGetUrlFileUploadArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetUrlFileUploadTypeArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetUrlFileUploadTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UrlFileUploadTypeConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UrlFileUploadTypeOrder>>;
};


export type QueryInternalGetUrlFileUploadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UrlFileUploadConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInternalGetUserArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetUserAgentArgs = {
  code: Scalars['String']['input'];
};


export type QueryInternalGetUserAgentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserAgentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserAgentOrder>>;
};


export type QueryInternalGetUserInviteArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetUserInvitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserInviteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserInviteOrder>>;
};


export type QueryInternalGetUserKeyArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetUserKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserKeyConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserKeyOrder>>;
};


export type QueryInternalGetUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserOrder>>;
};


export type QueryInternalGetWebhookArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryInternalGetWebhooksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WebhookOrder>>;
};


export type QueryNodeArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ObjectID']['input']>;
};

export type Redirect = {
  exclusionReason?: Maybe<RedirectExclusionReason>;
  isJSRedirect?: Maybe<Scalars['Boolean']['output']>;
  isMetaRedirect: Scalars['Boolean']['output'];
  redirectsTo?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  statusCode?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};

export enum RedirectExclusionReason {
  Disallowed = 'Disallowed',
  ExcludedUrl = 'ExcludedUrl',
  External = 'External',
  Internal = 'Internal',
  MalformedUrl = 'MalformedUrl',
  NonHtmlFile = 'NonHtmlFile',
  NotIncludedUrl = 'NotIncludedUrl',
  Other = 'Other',
  PageGroupingRestricted = 'PageGroupingRestricted',
  TooManyRedirects = 'TooManyRedirects'
}

export type RedirectObjectArrayMemberConnectionFilterInput = {
  redirectsTo?: InputMaybe<ConnectionStringObjectArrayMemberFilterInput>;
  result?: InputMaybe<ConnectionStringObjectArrayMemberFilterInput>;
  statusCode?: InputMaybe<ConnectionIntObjectArrayMemberFilterInput>;
  url?: InputMaybe<ConnectionStringObjectArrayMemberFilterInput>;
};

export type RegisterCrawlSchemaInput = {
  crawlId: Scalars['ObjectID']['input'];
};

export type RegisterCrawlSchemaPayload = {
  crawl: Crawl;
};

export type RejectSuggestedThresholdInput = {
  testResultIds: Array<Scalars['ObjectID']['input']>;
};

export type RemoveCustomDashboardFromCollectionInput = {
  customDashboardCollectionId: Scalars['ObjectID']['input'];
  customViewIds: Array<Scalars['ObjectID']['input']>;
};

export type RemoveCustomViewsFromCustomDashboardCollectionPayload = {
  customViews: Array<CustomView>;
};

export type RendererCookie = {
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type RendererCookieInput = {
  name: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum RenderingRobotsCheckMode {
  BlockDisallowed = 'BlockDisallowed',
  RequestDisallowed = 'RequestDisallowed'
}

export type Report = {
  aggregateValue?: Maybe<Scalars['Float']['output']>;
  change?: Maybe<Scalars['Int']['output']>;
  changeWeight: Scalars['Float']['output'];
  coreUIUrl: Scalars['String']['output'];
  crawl: Crawl;
  crawlAccessibilityIssueInstanceInstances?: Maybe<CrawlAccessibilityIssueInstanceConnection>;
  crawlAccessibilityIssues?: Maybe<CrawlAccessibilityIssueConnection>;
  crawlDomains?: Maybe<CrawlDomainConnection>;
  crawlDuplicateUrls?: Maybe<CrawlDuplicateUrlConnection>;
  crawlHreflangs?: Maybe<CrawlHreflangsConnection>;
  crawlId: Scalars['ObjectID']['output'];
  crawlLinkedDomains?: Maybe<CrawlLinkedDomainConnection>;
  crawlLinks?: Maybe<CrawlLinkConnection>;
  crawlSegment?: Maybe<CrawlSegment>;
  crawlSiteSpeedAuditItems?: Maybe<CrawlSiteSpeedAuditItemConnection>;
  crawlSiteSpeedAuditOpportunities?: Maybe<CrawlSiteSpeedAuditOpportunityConnection>;
  crawlSiteSpeedAuditOpportunitiesAggregates?: Maybe<CrawlSiteSpeedAuditOpportunityAggregateConnection>;
  crawlSiteSpeedAudits?: Maybe<CrawlSiteSpeedAuditConnection>;
  crawlSiteSpeedAuditsAggregates?: Maybe<CrawlSiteSpeedAuditAggregateConnection>;
  crawlSitemaps?: Maybe<CrawlSitemapConnection>;
  crawlUncrawledUrls?: Maybe<CrawlUncrawledUrlConnection>;
  crawlUniqueLinks?: Maybe<CrawlUniqueLinkConnection>;
  crawlUrls?: Maybe<CrawlUrlConnection>;
  crawlUrlsAggregates?: Maybe<Array<CrawlUrlAggregate>>;
  crawlWebCrawlDepths?: Maybe<CrawlWebCrawlDepthConnection>;
  /** @deprecated Reports are created when Crawl finalizes */
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use reportTemplate.datasourceCode instead. */
  datasourceCode: Scalars['String']['output'];
  /** @deprecated Use reportTemplate.datasourceCode instead. */
  datasourceCodeEnum: DatasourceCode;
  diffReportTotals: Array<ReportTotalRows>;
  /** @deprecated unused, admin only */
  generationTime?: Maybe<Scalars['Int']['output']>;
  getResource?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ObjectID']['output'];
  indexName?: Maybe<Scalars['String']['output']>;
  internalGeneratedESQuery: Scalars['JSONObject']['output'];
  internalGeneratedSegmentFilters?: Maybe<Scalars['JSONObject']['output']>;
  internalRows?: Maybe<ReportRowConnection>;
  legacyRows?: Maybe<LegacyReportRowConnection>;
  legacyTasks: LegacyTaskConnection;
  paginatedCrawlUrlsAggregates?: Maybe<CrawlUrlAggregateConnection>;
  project: Project;
  projectId: Scalars['ObjectID']['output'];
  queryVersion?: Maybe<Scalars['Int']['output']>;
  rawID: Scalars['String']['output'];
  reportDownloads?: Maybe<ReportDownloadConnection>;
  reportTemplate: ReportTemplate;
  reportTemplateCode: Scalars['String']['output'];
  reportTemplateName: Scalars['String']['output'];
  reportTrend: Array<ReportTrendItem>;
  /** @deprecated Use reportTypeCodeEnum instead. */
  reportTypeCode: Scalars['String']['output'];
  reportTypeCodeEnum: ReportTypeCode;
  rows?: Maybe<ReportRowConnection>;
  rowsWithAllMetrics?: Maybe<AllMetricsReportRowConnection>;
  segment?: Maybe<Segment>;
  segmentId?: Maybe<Scalars['ObjectID']['output']>;
  segmentVersion?: Maybe<Scalars['Int']['output']>;
  templateTotalSign?: Maybe<Scalars['Int']['output']>;
  templateTotalWeight?: Maybe<Scalars['Float']['output']>;
  testResults: TestResultConnection;
  totalRows?: Maybe<Scalars['Int']['output']>;
  totalWeight?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use reportTrend instead. */
  trend: Array<Report>;
  type: ReportType;
  typeCode: ReportTypeCode;
};


export type ReportCrawlAccessibilityIssueInstanceInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlAccessibilityIssueInstanceConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlAccessibilityIssueInstanceOrder>>;
};


export type ReportCrawlAccessibilityIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlAccessibilityIssueConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlAccessibilityIssueOrder>>;
};


export type ReportCrawlDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlDomainConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlDomainOrder>>;
};


export type ReportCrawlDuplicateUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlDuplicateUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlDuplicateUrlOrder>>;
};


export type ReportCrawlHreflangsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlHreflangsConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlHreflangsOrder>>;
};


export type ReportCrawlLinkedDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlLinkedDomainConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlLinkedDomainOrder>>;
};


export type ReportCrawlLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlLinkConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlLinkOrder>>;
};


export type ReportCrawlSiteSpeedAuditItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditItemConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditItemOrder>>;
};


export type ReportCrawlSiteSpeedAuditOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditOpportunityOrder>>;
};


export type ReportCrawlSiteSpeedAuditOpportunitiesAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlSiteSpeedAuditOpportunityAggregateDimension>;
  filter?: InputMaybe<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlSiteSpeedAuditOpportunityAggregateOrder>;
};


export type ReportCrawlSiteSpeedAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditOrder>>;
};


export type ReportCrawlSiteSpeedAuditsAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlSiteSpeedAuditAggregateDimension>;
  filter?: InputMaybe<CrawlSiteSpeedAuditConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlSiteSpeedAuditAggregateOrder>;
};


export type ReportCrawlSitemapsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSitemapConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSitemapOrder>>;
};


export type ReportCrawlUncrawledUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUncrawledUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUncrawledUrlOrder>>;
};


export type ReportCrawlUniqueLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUniqueLinkConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUniqueLinkOrder>>;
};


export type ReportCrawlUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUrlOrder>>;
};


export type ReportCrawlUrlsAggregatesArgs = {
  dimensions?: Array<CrawlUrlAggregateDimension>;
  filter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  orderBy?: Array<CrawlUrlAggregateOrder>;
};


export type ReportCrawlWebCrawlDepthsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlWebCrawlDepthConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlWebCrawlDepthOrder>>;
};


export type ReportDiffReportTotalsArgs = {
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  includeSelf?: Scalars['Boolean']['input'];
};


export type ReportGetResourceArgs = {
  input: ReportGetResourceInputType;
};


export type ReportInternalRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
};


export type ReportLegacyRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
};


export type ReportLegacyTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LegacyTaskConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyTaskOrder>>;
};


export type ReportPaginatedCrawlUrlsAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlUrlAggregateDimension>;
  filter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlUrlAggregateOrder>;
};


export type ReportReportDownloadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportDownloadConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportDownloadOrder>>;
};


export type ReportRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
};


export type ReportRowsWithAllMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
};


export type ReportTestResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestResultConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestResultOrder>>;
};

export type ReportCategory = {
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  healthScore: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parentCode?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
};

export type ReportCategoryConnection = {
  /** A list of edges. */
  edges: Array<ReportCategoryEdge>;
  /** A list of nodes. */
  nodes: Array<ReportCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of ReportCategories in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReportCategoryConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ReportCategoryConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ReportCategoryConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ReportCategoryConnectionFilterInput>>;
  code?: InputMaybe<ConnectionStringFilterInput>;
  healthScore?: InputMaybe<ConnectionBooleanFilterInput>;
  parentCode?: InputMaybe<ConnectionStringFilterInput>;
  position?: InputMaybe<ConnectionIntFilterInput>;
};

export type ReportCategoryEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ReportCategory;
};

export type ReportCategoryOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order ReportCategories by. */
  field: ReportCategoryOrderField;
};

export enum ReportCategoryOrderField {
  Code = 'code',
  HealthScore = 'healthScore',
  ParentCode = 'parentCode',
  Position = 'position'
}

export type ReportConnection = {
  /** A list of edges. */
  edges: Array<ReportEdge>;
  /** A list of nodes. */
  nodes: Array<Report>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Reports in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReportConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ReportConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ReportConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ReportConnectionFilterInput>>;
  aggregateValue?: InputMaybe<ConnectionFloatFilterInput>;
  change?: InputMaybe<ConnectionIntFilterInput>;
  crawlId?: InputMaybe<ConnectionObjectIdFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  datasourceCode?: InputMaybe<ConnectionStringFilterInput>;
  datasourceCodeEnum?: InputMaybe<ConnectionDatasourceCodeFilterInput>;
  generationTime?: InputMaybe<ConnectionIntFilterInput>;
  projectId?: InputMaybe<ConnectionObjectIdFilterInput>;
  queryVersion?: InputMaybe<ConnectionIntFilterInput>;
  reportTemplateCode?: InputMaybe<ConnectionStringFilterInput>;
  reportTypeCode?: InputMaybe<ConnectionStringFilterInput>;
  reportTypeCodeEnum?: InputMaybe<ConnectionReportTypeCodeFilterInput>;
  segmentId?: InputMaybe<ConnectionObjectIdFilterInput>;
  totalRows?: InputMaybe<ConnectionIntFilterInput>;
  totalWeight?: InputMaybe<ConnectionFloatFilterInput>;
};

export type ReportDownload = {
  crawl: Crawl;
  createdAt: Scalars['DateTime']['output'];
  fileURL?: Maybe<Scalars['String']['output']>;
  filter?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  outputType: ReportDownloadOutputType;
  rawID: Scalars['String']['output'];
  /** @deprecated No longer used. */
  report: Report;
  reportTemplate: ReportTemplate;
  reportedAt?: Maybe<Scalars['DateTime']['output']>;
  reportingAt?: Maybe<Scalars['DateTime']['output']>;
  selectedMetrics?: Maybe<Array<Scalars['String']['output']>>;
  status: ReportDownloadStatus;
  task?: Maybe<LegacyTask>;
  totalRows?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};


export type ReportDownloadFileUrlArgs = {
  fileName?: InputMaybe<Scalars['String']['input']>;
};

export type ReportDownloadConnection = {
  /** A list of edges. */
  edges: Array<ReportDownloadEdge>;
  /** A list of nodes. */
  nodes: Array<ReportDownload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of ReportDownloads in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReportDownloadConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ReportDownloadConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ReportDownloadConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ReportDownloadConnectionFilterInput>>;
  crawlId?: InputMaybe<ConnectionObjectIdFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  reportTypeId?: InputMaybe<ConnectionObjectIdFilterInput>;
  segmentId?: InputMaybe<ConnectionObjectIdFilterInput>;
  taskId?: InputMaybe<ConnectionObjectIdFilterInput>;
  totalRows?: InputMaybe<ConnectionFloatFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type ReportDownloadEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ReportDownload;
};

export type ReportDownloadOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order ReportDownloads by. */
  field: ReportDownloadOrderField;
};

export enum ReportDownloadOrderField {
  CreatedAt = 'createdAt',
  TotalRows = 'totalRows',
  UpdatedAt = 'updatedAt'
}

export enum ReportDownloadOutputType {
  CompactCsvZip = 'CompactCsvZip',
  CsvGzip = 'CsvGzip',
  CsvTarGz = 'CsvTarGz',
  CsvZip = 'CsvZip',
  XmlZip = 'XmlZip'
}

export enum ReportDownloadStatus {
  Draft = 'Draft',
  Generated = 'Generated',
  Generating = 'Generating'
}

export type ReportEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Report;
};

export type ReportGetResourceInputType = {
  resourceId: Scalars['String']['input'];
};

export type ReportOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Reports by. */
  field: ReportOrderField;
};

export enum ReportOrderField {
  AggregateValue = 'aggregateValue',
  Change = 'change',
  CreatedAt = 'createdAt',
  DatasourceCode = 'datasourceCode',
  DatasourceCodeEnum = 'datasourceCodeEnum',
  GenerationTime = 'generationTime',
  QueryVersion = 'queryVersion',
  ReportTemplateCode = 'reportTemplateCode',
  ReportTypeCode = 'reportTypeCode',
  ReportTypeCodeEnum = 'reportTypeCodeEnum',
  TotalRows = 'totalRows',
  TotalWeight = 'totalWeight'
}

export type ReportRow = CrawlAccessibilityIssue | CrawlAccessibilityIssueInstance | CrawlDomain | CrawlDuplicateUrl | CrawlHreflangs | CrawlLink | CrawlLinkedDomain | CrawlSiteSpeedAudit | CrawlSiteSpeedAuditItem | CrawlSiteSpeedAuditOpportunity | CrawlSitemap | CrawlUncrawledUrl | CrawlUniqueLink | CrawlUrl | CrawlWebCrawlDepth;

export type ReportRowConnection = {
  edges: Array<ReportRowEdge>;
  nodes: Array<ReportRow>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportRowEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: ReportRow;
};

export type ReportScrollConnection = {
  nodes: Array<Report>;
  scrollId: Scalars['String']['output'];
  totalCount: Scalars['Int']['output'];
};

export type ReportStat = {
  added?: Maybe<Scalars['Float']['output']>;
  basic?: Maybe<Scalars['Float']['output']>;
  crawl: Crawl;
  crawlAccessibilityIssueInstanceInstances?: Maybe<CrawlAccessibilityIssueInstanceConnection>;
  crawlAccessibilityIssues?: Maybe<CrawlAccessibilityIssueConnection>;
  crawlDuplicateUrls?: Maybe<CrawlDuplicateUrlConnection>;
  crawlHreflangs?: Maybe<CrawlHreflangsConnection>;
  crawlId: Scalars['ObjectID']['output'];
  crawlLinkedDomains?: Maybe<CrawlLinkedDomainConnection>;
  crawlLinks?: Maybe<CrawlLinkConnection>;
  crawlSiteSpeedAuditItems?: Maybe<CrawlSiteSpeedAuditItemConnection>;
  crawlSiteSpeedAuditOpportunities?: Maybe<CrawlSiteSpeedAuditOpportunityConnection>;
  crawlSiteSpeedAuditOpportunitiesAggregates?: Maybe<CrawlSiteSpeedAuditOpportunityAggregateConnection>;
  crawlSiteSpeedAudits?: Maybe<CrawlSiteSpeedAuditConnection>;
  crawlSiteSpeedAuditsAggregates?: Maybe<CrawlSiteSpeedAuditAggregateConnection>;
  crawlSitemaps?: Maybe<CrawlSitemapConnection>;
  crawlUncrawledUrls?: Maybe<CrawlUncrawledUrlConnection>;
  crawlUniqueLinks?: Maybe<CrawlUniqueLinkConnection>;
  crawlUrls?: Maybe<CrawlUrlConnection>;
  crawlUrlsAggregates?: Maybe<CrawlUrlAggregateConnection>;
  crawlWebCrawlDepths?: Maybe<CrawlWebCrawlDepthConnection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  getResource?: Maybe<Scalars['JSONObject']['output']>;
  indexName?: Maybe<Scalars['String']['output']>;
  legacyTasks: LegacyTaskConnection;
  missing?: Maybe<Scalars['Float']['output']>;
  project: Project;
  projectId: Scalars['ObjectID']['output'];
  queryVersion: Scalars['Int']['output'];
  removed?: Maybe<Scalars['Float']['output']>;
  reportDownloads?: Maybe<ReportDownloadConnection>;
  reportTemplate: ReportTemplate;
  reportTemplateCode: Scalars['String']['output'];
  reportTemplateName: Scalars['String']['output'];
  reportTemplateTotalSign?: Maybe<Scalars['Int']['output']>;
  reportTemplateTotalWeight?: Maybe<Scalars['Float']['output']>;
  rows?: Maybe<ReportStatRowConnection>;
  rowsWithAllMetrics?: Maybe<AllMetricsReportStatRowConnection>;
  segment?: Maybe<Segment>;
  segmentId?: Maybe<Scalars['ObjectID']['output']>;
  segmentVersion?: Maybe<Scalars['Int']['output']>;
  testResults: TestResultConnection;
  totalWeight: Scalars['Float']['output'];
  trend: Array<ReportStatTrendItem>;
};


export type ReportStatCrawlAccessibilityIssueInstanceInstancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlAccessibilityIssueInstanceConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlAccessibilityIssueInstanceOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlAccessibilityIssuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlAccessibilityIssueConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlAccessibilityIssueOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlDuplicateUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlDuplicateUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlDuplicateUrlOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlHreflangsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlHreflangsConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlHreflangsOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlLinkedDomainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlLinkedDomainConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlLinkedDomainOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlLinkConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlLinkOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlSiteSpeedAuditItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditItemConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditItemOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlSiteSpeedAuditOpportunitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditOpportunityOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlSiteSpeedAuditOpportunitiesAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlSiteSpeedAuditOpportunityAggregateDimension>;
  filter?: InputMaybe<CrawlSiteSpeedAuditOpportunityConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlSiteSpeedAuditOpportunityAggregateOrder>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlSiteSpeedAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSiteSpeedAuditConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSiteSpeedAuditOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlSiteSpeedAuditsAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlSiteSpeedAuditAggregateDimension>;
  filter?: InputMaybe<CrawlSiteSpeedAuditConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlSiteSpeedAuditAggregateOrder>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlSitemapsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSitemapConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSitemapOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlUncrawledUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUncrawledUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUncrawledUrlOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlUniqueLinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUniqueLinkConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUniqueLinkOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlUrlsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlUrlOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlUrlsAggregatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  dimensions?: Array<CrawlUrlAggregateDimension>;
  filter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: Array<CrawlUrlAggregateOrder>;
  reportType?: ReportTypeCode;
};


export type ReportStatCrawlWebCrawlDepthsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlWebCrawlDepthConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlWebCrawlDepthOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatGetResourceArgs = {
  input: ReportStatGetResourceInputType;
};


export type ReportStatIndexNameArgs = {
  reportType?: ReportTypeCode;
};


export type ReportStatLegacyTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LegacyTaskConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LegacyTaskOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatReportDownloadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportDownloadConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportDownloadOrder>>;
  reportType?: ReportTypeCode;
};


export type ReportStatRowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  reportType?: ReportTypeCode;
};


export type ReportStatRowsWithAllMetricsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  reportType?: ReportTypeCode;
};


export type ReportStatTestResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestResultConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestResultOrder>>;
};

export type ReportStatGetResourceInputType = {
  reportTypeCode?: ReportTypeCode;
  resourceId: Scalars['String']['input'];
};

export type ReportStatRowConnection = {
  edges: Array<ReportStatRowEdge>;
  nodes: Array<ReportRow>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ReportStatRowEdge = {
  /** Used in `before` and `after` args */
  cursor: Scalars['String']['output'];
  node: ReportRow;
};

export type ReportStatTrendItem = {
  added?: Maybe<Scalars['Float']['output']>;
  basic?: Maybe<Scalars['Float']['output']>;
  crawlId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  missing?: Maybe<Scalars['Float']['output']>;
  queryVersion: Scalars['Int']['output'];
  removed?: Maybe<Scalars['Float']['output']>;
  reportTemplateTotalSign?: Maybe<Scalars['Int']['output']>;
  reportTemplateTotalWeight?: Maybe<Scalars['Float']['output']>;
  segmentVersion?: Maybe<Scalars['Int']['output']>;
  totalWeight: Scalars['Float']['output'];
};

export type ReportTemplate = {
  admin: Scalars['Boolean']['output'];
  aggregate?: Maybe<Scalars['JSONObject']['output']>;
  aggregatesByCode?: Maybe<Scalars['JSONObject']['output']>;
  /** A list of allowed output types when generating ReportDownloads. */
  allowedOutputTypes: Array<ReportDownloadOutputType>;
  automatorEnabled: Scalars['Boolean']['output'];
  bestPractice: Scalars['Boolean']['output'];
  beta: Scalars['Boolean']['output'];
  /** @deprecated Use reportTemplate.reportCategories instead. */
  categories: Array<Scalars['String']['output']>;
  changeSign?: Maybe<Scalars['Int']['output']>;
  changeWeight?: Maybe<Scalars['Float']['output']>;
  code: Scalars['String']['output'];
  contributesToHealthScores: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  datasource: Datasource;
  /** @deprecated Use datasourceCodeEnum instead. */
  datasourceCode: Scalars['String']['output'];
  datasourceCodeEnum: DatasourceCode;
  /** @deprecated No longer used. */
  datasourceVersion: Scalars['Int']['output'];
  default: Scalars['Boolean']['output'];
  /** A list of metrics recommended for this report template. */
  defaultMetrics: Array<Scalars['String']['output']>;
  defaultOrderBy: Array<Scalars['JSONObject']['output']>;
  definition: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  effect: Scalars['String']['output'];
  healthScoreParentReportTemplateCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  impact: Scalars['String']['output'];
  /** A raw ElasticSearch query, using default Report settings. */
  internalGeneratedESQuery?: Maybe<Scalars['JSONObject']['output']>;
  /** A raw SQL query compatible with the Odin API, using default Report settings. */
  internalGeneratedSQLQuery?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<ReportTemplateMetadata>;
  metricsGroupings: Array<MetricsGrouping>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  primaryReportCategoryCode?: Maybe<Scalars['String']['output']>;
  query: Scalars['JSONObject']['output'];
  queryVersion: Scalars['Int']['output'];
  queryVersionChanges: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  reportCategories: Array<ReportCategory>;
  reportTemplateVersions: ReportTemplateVersionConnection;
  reportTypes?: Maybe<Array<ReportTypeCode>>;
  scopeReportTemplateCode?: Maybe<Scalars['String']['output']>;
  solutions: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  supportedModules: Array<ModuleCode>;
  tableTypeVersion?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  totalSign?: Maybe<Scalars['Int']['output']>;
  totalWeight?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  wcagLevel?: Maybe<WcagLevel>;
  wcagVersion?: Maybe<Scalars['Float']['output']>;
};


export type ReportTemplateInternalGeneratedEsQueryArgs = {
  reportType: ReportTypeCode;
};


export type ReportTemplateInternalGeneratedSqlQueryArgs = {
  reportType: ReportTypeCode;
};


export type ReportTemplateReportTemplateVersionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ReportTemplateVersionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportTemplateVersionOrder>>;
};

export type ReportTemplateConnection = {
  /** A list of edges. */
  edges: Array<ReportTemplateEdge>;
  /** A list of nodes. */
  nodes: Array<ReportTemplate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of ReportTemplates in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReportTemplateConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ReportTemplateConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ReportTemplateConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ReportTemplateConnectionFilterInput>>;
  admin?: InputMaybe<ConnectionBooleanFilterInput>;
  automatorEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  bestPractice?: InputMaybe<ConnectionBooleanFilterInput>;
  beta?: InputMaybe<ConnectionBooleanFilterInput>;
  categoriesRaw?: InputMaybe<ConnectionStringFilterInput>;
  changeSign?: InputMaybe<ConnectionIntFilterInput>;
  changeWeight?: InputMaybe<ConnectionFloatFilterInput>;
  code?: InputMaybe<ConnectionStringFilterInput>;
  contributesToHealthScores?: InputMaybe<ConnectionBooleanFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  datasourceCode?: InputMaybe<ConnectionStringFilterInput>;
  datasourceCodeEnum?: InputMaybe<ConnectionDatasourceCodeFilterInput>;
  default?: InputMaybe<ConnectionBooleanFilterInput>;
  deletedAt?: InputMaybe<ConnectionDateFilterInput>;
  healthScoreParentReportTemplateCode?: InputMaybe<ConnectionStringFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  position?: InputMaybe<ConnectionIntFilterInput>;
  primaryReportCategoryCode?: InputMaybe<ConnectionStringFilterInput>;
  scopeReportTemplateCode?: InputMaybe<ConnectionStringFilterInput>;
  tagsRaw?: InputMaybe<ConnectionStringFilterInput>;
  totalSign?: InputMaybe<ConnectionIntFilterInput>;
  totalWeight?: InputMaybe<ConnectionFloatFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  wcagLevel?: InputMaybe<ConnectionWcagLevelFilterInput>;
  wcagVersion?: InputMaybe<ConnectionFloatFilterInput>;
};

export type ReportTemplateEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ReportTemplate;
};

export type ReportTemplateMetadata = {
  requiredCrawlTypes?: Maybe<Array<CrawlType>>;
  /** Datasources on which ReportTemplate depends, other than ReportTemplate.datasourceCode. */
  requiredDatasources?: Maybe<Array<DatasourceCode>>;
  supportedContainerTableTypes?: Maybe<Array<CustomMetricContainerTableType>>;
  unit?: Maybe<ReportTemplateUnit>;
};

export type ReportTemplateOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order ReportTemplates by. */
  field: ReportTemplateOrderField;
};

export enum ReportTemplateOrderField {
  BestPractice = 'bestPractice',
  Beta = 'beta',
  ChangeSign = 'changeSign',
  ChangeWeight = 'changeWeight',
  Code = 'code',
  ContributesToHealthScores = 'contributesToHealthScores',
  CreatedAt = 'createdAt',
  DatasourceCode = 'datasourceCode',
  DatasourceCodeEnum = 'datasourceCodeEnum',
  DeletedAt = 'deletedAt',
  HealthScoreParentReportTemplateCode = 'healthScoreParentReportTemplateCode',
  Id = 'id',
  Name = 'name',
  Position = 'position',
  PrimaryReportCategoryCode = 'primaryReportCategoryCode',
  ScopeReportTemplateCode = 'scopeReportTemplateCode',
  TotalSign = 'totalSign',
  TotalWeight = 'totalWeight',
  UpdatedAt = 'updatedAt',
  WcagLevel = 'wcagLevel',
  WcagVersion = 'wcagVersion'
}

export type ReportTemplateOverride = {
  createdAt: Scalars['DateTime']['output'];
  reportTemplateCode: Scalars['String']['output'];
  totalSign: Scalars['Int']['output'];
  totalWeight: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ReportTemplateOverrideConnection = {
  /** A list of edges. */
  edges: Array<ReportTemplateOverrideEdge>;
  /** A list of nodes. */
  nodes: Array<ReportTemplateOverride>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of ReportTemplateOverrides in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReportTemplateOverrideConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ReportTemplateOverrideConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ReportTemplateOverrideConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ReportTemplateOverrideConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  reportTemplateCode?: InputMaybe<ConnectionStringFilterInput>;
  totalSign?: InputMaybe<ConnectionIntFilterInput>;
  totalWeight?: InputMaybe<ConnectionFloatFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type ReportTemplateOverrideEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ReportTemplateOverride;
};

export type ReportTemplateOverrideInfo = {
  reportTemplateCode: Scalars['String']['output'];
  totalSign: Scalars['Int']['output'];
  totalWeight: Scalars['Float']['output'];
};

export type ReportTemplateOverrideOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order ReportTemplateOverrides by. */
  field: ReportTemplateOverrideOrderField;
};

export enum ReportTemplateOverrideOrderField {
  CreatedAt = 'createdAt',
  ReportTemplateCode = 'reportTemplateCode',
  TotalSign = 'totalSign',
  TotalWeight = 'totalWeight',
  UpdatedAt = 'updatedAt'
}

export enum ReportTemplateUnit {
  Instances = 'Instances',
  Issues = 'Issues',
  Links = 'Links',
  Sets = 'Sets',
  Sitemaps = 'Sitemaps',
  UrLs = 'URLs'
}

export type ReportTemplateVersion = {
  aggregate?: Maybe<Scalars['JSONObject']['output']>;
  aggregatesByCode?: Maybe<Scalars['JSONObject']['output']>;
  changeSign: Scalars['Int']['output'];
  changeWeight: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  contributesToHealthScores: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  primaryReportCategoryCode?: Maybe<Scalars['String']['output']>;
  query: Scalars['JSONObject']['output'];
  queryVersion: Scalars['Int']['output'];
  queryVersionChanges: Scalars['String']['output'];
  reportTypes?: Maybe<Array<ReportTypeCode>>;
  scopeReportTemplateCode?: Maybe<Scalars['String']['output']>;
  tableTypeVersion?: Maybe<Scalars['Int']['output']>;
  totalSign: Scalars['Int']['output'];
  totalWeight: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ReportTemplateVersionConnection = {
  /** A list of edges. */
  edges: Array<ReportTemplateVersionEdge>;
  /** A list of nodes. */
  nodes: Array<ReportTemplateVersion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of ReportTemplateVersions in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ReportTemplateVersionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ReportTemplateVersionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ReportTemplateVersionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ReportTemplateVersionConnectionFilterInput>>;
  code?: InputMaybe<ConnectionStringFilterInput>;
  contributesToHealthScores?: InputMaybe<ConnectionBooleanFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  queryVersion?: InputMaybe<ConnectionIntFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type ReportTemplateVersionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ReportTemplateVersion;
};

export type ReportTemplateVersionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order ReportTemplateVersions by. */
  field: ReportTemplateVersionOrderField;
};

export enum ReportTemplateVersionOrderField {
  Code = 'code',
  ContributesToHealthScores = 'contributesToHealthScores',
  CreatedAt = 'createdAt',
  QueryVersion = 'queryVersion',
  UpdatedAt = 'updatedAt'
}

export type ReportTotalRows = {
  rawReportId: Scalars['String']['output'];
  reportId: Scalars['ObjectID']['output'];
  reportTypeCode: ReportTypeCode;
  totalRows: Scalars['Int']['output'];
};

export type ReportTrendComputed = {
  absDiff?: Maybe<ReportTrendItem>;
  diff?: Maybe<ReportTrendItem>;
  last?: Maybe<ReportTrendItem>;
};

export type ReportTrendData = {
  computed: ReportTrendComputed;
  points: Array<ReportTrendItem>;
  reportTemplateCode: Scalars['String']['output'];
};

export type ReportTrendItem = {
  added?: Maybe<Scalars['Float']['output']>;
  basic?: Maybe<Scalars['Float']['output']>;
  crawlId: Scalars['Int']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  missing?: Maybe<Scalars['Float']['output']>;
  removed?: Maybe<Scalars['Float']['output']>;
  totalWeight?: Maybe<Scalars['Float']['output']>;
};

export type ReportTrendsTableRow = {
  columns: Array<ReportTrendData>;
  projectId: Scalars['Int']['output'];
  segmentId: Scalars['Int']['output'];
};

export type ReportType = {
  code: ReportTypeCode;
  id: Scalars['ObjectID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  rawID: Scalars['String']['output'];
};

export enum ReportTypeCode {
  Added = 'Added',
  Basic = 'Basic',
  Missing = 'Missing',
  Removed = 'Removed'
}

export type RequestPasswordResetInput = {
  email: Scalars['String']['input'];
};

export type ResendAccountUserInviteInput = {
  accountId: Scalars['ObjectID']['input'];
  userId: Scalars['ObjectID']['input'];
};

export type ResendAccountUserPayload = {
  account: Account;
};

export type ResetPasswordInput = {
  newPassword: Scalars['String']['input'];
  passwordResetToken: Scalars['String']['input'];
  /** Skips leaked password validation using https://haveibeenpwned.com/ service. */
  skipLeakedPasswordValidation?: Scalars['Boolean']['input'];
};

export type ResetPasswordPayload = {
  passwordUpdated: Scalars['Boolean']['output'];
  passwordValidationErrors?: Maybe<Array<Scalars['String']['output']>>;
};

export type ResetPasswordToken = {
  value: Scalars['String']['output'];
};

export enum ResolvedHttpStatusCode {
  Permanent = 'Permanent',
  Temporary = 'Temporary'
}

export type ResumeCrawlingInput = {
  crawlId: Scalars['ObjectID']['input'];
};

export type ResumeCrawlingPayload = {
  crawl: Crawl;
};

export type Role = {
  code: RoleCode;
  name: Scalars['String']['output'];
};

export enum RoleCode {
  Admin = 'Admin',
  Editor = 'Editor',
  Viewer = 'Viewer'
}

export type RunBuildInput = {
  buildId: Scalars['ObjectID']['input'];
};

export type RunBuildPayload = {
  build: Build;
};

export type RunCrawlForProjectInput = {
  isScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  platformId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ObjectID']['input'];
  sitemapCrawlerPlatformId?: InputMaybe<Scalars['String']['input']>;
};

export type RunCrawlForProjectPayload = {
  crawl: Crawl;
};

export type SsoClient = {
  accountSubscriptionPlanCodes?: Maybe<Array<Scalars['String']['output']>>;
  /** Used as i18n variable for translatable fields. */
  brand?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  userAgentSuffix?: Maybe<Scalars['String']['output']>;
  webhookUrl?: Maybe<Scalars['String']['output']>;
};

export type SsoClientConnection = {
  /** A list of edges. */
  edges: Array<SsoClientEdge>;
  /** A list of nodes. */
  nodes: Array<SsoClient>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of SSOClients in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SsoClientConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<SsoClientConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<SsoClientConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<SsoClientConnectionFilterInput>>;
  userAgentSuffix?: InputMaybe<ConnectionStringFilterInput>;
};

export type SsoClientEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SsoClient;
};

export type Schedule = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  latestRunTime: Scalars['DateTime']['output'];
  nextRunTime: Scalars['DateTime']['output'];
  noCreditsEmailSentAt?: Maybe<Scalars['DateTime']['output']>;
  project: Project;
  rawID: Scalars['String']['output'];
  scheduleFrequency: ScheduleFrequency;
  updatedAt: Scalars['DateTime']['output'];
};

export type ScheduleConnection = {
  /** A list of edges. */
  edges: Array<ScheduleEdge>;
  /** A list of nodes. */
  nodes: Array<Schedule>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Schedules in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ScheduleConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<ScheduleConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<ScheduleConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<ScheduleConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  latestRunTime?: InputMaybe<ConnectionDateFilterInput>;
  nextRunTime?: InputMaybe<ConnectionDateFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type ScheduleEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Schedule;
};

export type ScheduleFrequency = {
  code: ScheduleFrequencyCode;
  name: Scalars['String']['output'];
};

export enum ScheduleFrequencyCode {
  Bimonthly = 'Bimonthly',
  Daily = 'Daily',
  Fortnightly = 'Fortnightly',
  Hourly = 'Hourly',
  MondayFriday = 'MondayFriday',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly'
}

export type ScheduleOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Schedules by. */
  field: ScheduleOrderField;
};

export enum ScheduleOrderField {
  CreatedAt = 'createdAt',
  Id = 'id',
  LatestRunTime = 'latestRunTime',
  NextRunTime = 'nextRunTime',
  UpdatedAt = 'updatedAt'
}

export type Segment = {
  crawlSegments?: Maybe<CrawlSegmentConnection>;
  crawlUrlFilter: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<User>;
  filter?: Maybe<Scalars['JSONObject']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  internalGetCrawlUrlElasticsearchFilter?: Maybe<Scalars['JSONObject']['output']>;
  lastFailedAt?: Maybe<Scalars['DateTime']['output']>;
  lastGeneratedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  project: Project;
  rawID: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['Int']['output'];
};


export type SegmentCrawlSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CrawlSegmentConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CrawlSegmentOrder>>;
};

export type SegmentConnection = {
  /** A list of edges. */
  edges: Array<SegmentEdge>;
  /** A list of nodes. */
  nodes: Array<Segment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Segments in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SegmentConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<SegmentConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<SegmentConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<SegmentConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  deletedAt?: InputMaybe<ConnectionDateFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type SegmentCountByGroup = {
  group?: Maybe<Scalars['String']['output']>;
  segmentCount: Scalars['Int']['output'];
};

export type SegmentEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Segment;
};

export type SegmentOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Segments by. */
  field: SegmentOrderField;
};

export enum SegmentOrderField {
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type SegmentedProject = {
  account: Account;
  project: Project;
  projectFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  projectName: Scalars['String']['output'];
  projectPrimaryDomain: Scalars['String']['output'];
  segment?: Maybe<Segment>;
  segmentName?: Maybe<Scalars['String']['output']>;
};

export type SegmentedProjectConnection = {
  /** A list of edges. */
  edges: Array<SegmentedProjectEdge>;
  /** A list of nodes. */
  nodes: Array<SegmentedProject>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of SegmentedProjects in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SegmentedProjectConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<SegmentedProjectConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<SegmentedProjectConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<SegmentedProjectConnectionFilterInput>>;
  projectFinishedAt?: InputMaybe<ConnectionDateFilterInput>;
  projectName?: InputMaybe<ConnectionStringFilterInput>;
  projectPrimaryDomain?: InputMaybe<ConnectionStringFilterInput>;
  segmentName?: InputMaybe<ConnectionStringFilterInput>;
};

export type SegmentedProjectEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SegmentedProject;
};

export type SegmentedProjectOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order SegmentedProjects by. */
  field: SegmentedProjectOrderField;
};

export enum SegmentedProjectOrderField {
  ProjectFinishedAt = 'projectFinishedAt',
  ProjectName = 'projectName',
  ProjectPrimaryDomain = 'projectPrimaryDomain',
  SegmentName = 'segmentName'
}

export type SelectMonitorNotificationsStatusInput = {
  accountId: Scalars['ObjectID']['input'];
  /** Filtering options for TestResults. */
  filter?: InputMaybe<TestResultConnectionFilterInput>;
  projectIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  testResultIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  testResultIdsBlacklist?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SerpentCrawlerQueryGraphType {
  Basic = 'Basic',
  Mapped = 'Mapped',
  Modular = 'Modular'
}

export type Session = {
  expiresAt: Scalars['DateTime']['output'];
  token: Scalars['String']['output'];
};

export type SetBuildScheduleTestSuitesInput = {
  buildScheduleId: Scalars['ObjectID']['input'];
  testSuiteIds: Array<Scalars['ObjectID']['input']>;
};

export type SetBuildScheduleTestSuitesPayload = {
  buildScheduleTestSuites: Array<BuildScheduleTestSuite>;
};

export type SetCustomMetricContainerProjectSecretInput = {
  customMetricContainerId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['ObjectID']['input'];
  value: Scalars['String']['input'];
};

export type SetCustomMetricContainerProjectSecretPayload = {
  customMetricContainerProjectSecret: CustomMetricContainerProjectSecret;
};

export type SetUserDataInput = {
  key: Scalars['String']['input'];
  value: Scalars['JSON']['input'];
};

export enum Severity {
  Fail = 'Fail',
  Warning = 'Warning'
}

export type ShareProjectInput = {
  expiresAt: Scalars['DateTime']['input'];
  projectId: Scalars['ObjectID']['input'];
  targetUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ShareProjectPayload = {
  authToken: AuthToken;
  sharelink: Sharelink;
};

export type Sharelink = {
  account: Account;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  rawID: Scalars['String']['output'];
  sharelinkTemplate: SharelinkTemplate;
  targetUrl?: Maybe<Scalars['String']['output']>;
};

export type SharelinkTemplate = {
  code: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SinglePageRequesterCrawlSettingsBasicAuthInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type SinglePageRequesterCrawlSettingsInput = {
  applyCustomHeadersToNavigationRequestsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  basicAuth?: InputMaybe<SinglePageRequesterCrawlSettingsBasicAuthInput>;
  block3rdPartyCookies?: InputMaybe<Scalars['Boolean']['input']>;
  blockAdScripts?: InputMaybe<Scalars['Boolean']['input']>;
  blockAnalyticsScripts?: InputMaybe<Scalars['Boolean']['input']>;
  blockCustomScripts?: InputMaybe<Array<Scalars['String']['input']>>;
  crawlRedirectsExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRedirectsInternal?: InputMaybe<Scalars['Boolean']['input']>;
  customDnsEntries?: InputMaybe<Array<CustomDnsSettingInput>>;
  customJavascriptResources?: InputMaybe<Array<Scalars['String']['input']>>;
  customJavascripts?: InputMaybe<Array<Scalars['String']['input']>>;
  customRequestHeaders: Array<CustomRequestHeaderInput>;
  dataLayerName?: InputMaybe<Scalars['String']['input']>;
  enableRendering?: InputMaybe<Scalars['Boolean']['input']>;
  excludeUrlFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  flattenIframes?: InputMaybe<Scalars['Boolean']['input']>;
  flattenShadowDom?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreInvalidSslCert?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreRobotsForNavigationRequests?: InputMaybe<Scalars['Boolean']['input']>;
  includeOnlyUrlFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryDomain?: InputMaybe<Scalars['String']['input']>;
  removeUrlParameters?: Array<Scalars['String']['input']>;
  renderWithImages?: InputMaybe<Scalars['Boolean']['input']>;
  rendererCookies?: InputMaybe<Array<RendererCookieInput>>;
  renderingRobotsCheckMode?: InputMaybe<RenderingRobotsCheckMode>;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  stealthCrawlMode?: InputMaybe<Scalars['Boolean']['input']>;
  stealthCrawlModeV2?: InputMaybe<Scalars['Boolean']['input']>;
  stripHashtagFragments?: InputMaybe<Scalars['Boolean']['input']>;
  useNewHeadlessMode?: InputMaybe<Scalars['Boolean']['input']>;
  userAgent?: InputMaybe<SinglePageRequesterCrawlSettingsUserAgentInput>;
};

export type SinglePageRequesterCrawlSettingsUserAgentInput = {
  fullUserAgentString: Scalars['String']['input'];
  isMobile?: Scalars['Boolean']['input'];
  userAgentToken: Scalars['String']['input'];
};

export type SinglePageRequesterRequest = {
  crawlOptions: Scalars['JSONObject']['output'];
  crawlSettings: Scalars['JSONObject']['output'];
  createdAt: Scalars['DateTime']['output'];
  customMetricContainerVersions?: Maybe<Array<Scalars['JSONObject']['output']>>;
  /** CustomMetric fields mapping. */
  customMetricContainersFieldsMapping?: Maybe<Scalars['JSONObject']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ObjectID']['output'];
  outputs?: Maybe<Scalars['JSONObject']['output']>;
  rawID: Scalars['String']['output'];
  /** Requests expire after 90 days. */
  renderedBodyDownloadUrl?: Maybe<Scalars['String']['output']>;
  requestId: Scalars['String']['output'];
  responseHeaders?: Maybe<Scalars['JSONObject']['output']>;
  /** Requests expire after 90 days. */
  screenshotDownloadUrl?: Maybe<Scalars['String']['output']>;
  /** Requests expire after 90 days. */
  signedOutputs?: Maybe<Scalars['JSONObject']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Requests expire after 90 days. */
  staticBodyDownloadUrl?: Maybe<Scalars['String']['output']>;
  status: SinglePageRequesterRequestStatus;
  /** Requests expire after 90 days. */
  stepDownloadUrl?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
};

export type SinglePageRequesterRequestConnection = {
  /** A list of edges. */
  edges: Array<SinglePageRequesterRequestEdge>;
  /** A list of nodes. */
  nodes: Array<SinglePageRequesterRequest>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of SinglePageRequesterRequests in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SinglePageRequesterRequestConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<SinglePageRequesterRequestConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<SinglePageRequesterRequestConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<SinglePageRequesterRequestConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionIntFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  failedAt?: InputMaybe<ConnectionDateFilterInput>;
  finishedAt?: InputMaybe<ConnectionDateFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  projectId?: InputMaybe<ConnectionIntFilterInput>;
  requestId?: InputMaybe<ConnectionStringFilterInput>;
  startedAt?: InputMaybe<ConnectionDateFilterInput>;
  status?: InputMaybe<ConnectionSinglePageRequesterRequestStatusFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
};

export type SinglePageRequesterRequestEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SinglePageRequesterRequest;
};

export type SinglePageRequesterRequestOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order SinglePageRequesterRequests by. */
  field: SinglePageRequesterRequestOrderField;
};

export enum SinglePageRequesterRequestOrderField {
  CreatedAt = 'createdAt',
  FailedAt = 'failedAt',
  FinishedAt = 'finishedAt',
  Id = 'id',
  RequestId = 'requestId',
  StartedAt = 'startedAt',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  Url = 'url'
}

export enum SinglePageRequesterRequestStatus {
  Failed = 'Failed',
  Finished = 'Finished',
  Queued = 'Queued',
  Started = 'Started'
}

export type SiteSpeedAuditRedirect = {
  url: Scalars['String']['output'];
  wastedMs: Scalars['Int']['output'];
};

export type Sitemap = {
  enabled: Scalars['Boolean']['output'];
  level?: Maybe<Scalars['Int']['output']>;
  linksOut?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<Scalars['String']['output']>;
  status: SitemapStatus;
  type: SitemapType;
  url: Scalars['String']['output'];
  urlDigest: Scalars['String']['output'];
};

export enum SitemapStatus {
  Malformed = 'Malformed',
  Valid = 'Valid'
}

export enum SitemapType {
  Custom = 'Custom',
  Discovered = 'Discovered'
}

export type SitespeedAuditResult = {
  audits: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  result: SitespeedAuditResultType;
};

export enum SitespeedAuditResultType {
  Failed = 'Failed',
  NeedsReview = 'NeedsReview',
  Passed = 'Passed',
  Warning = 'Warning'
}

export enum SlackAlertType {
  Fail = 'Fail',
  Pass = 'Pass',
  Warning = 'Warning'
}

export type SlackWebhook = {
  alertTypes: Array<SlackAlertType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  rawID: Scalars['String']['output'];
  testSuite: TestSuite;
  url: Scalars['String']['output'];
};

export type SlackWebhookConnection = {
  /** A list of edges. */
  edges: Array<SlackWebhookEdge>;
  /** A list of nodes. */
  nodes: Array<SlackWebhook>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of SlackWebhooks in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SlackWebhookEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SlackWebhook;
};

export type SlackWebhookOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order SlackWebhooks by. */
  field: SlackWebhookOrderField;
};

export enum SlackWebhookOrderField {
  CreatedAt = 'createdAt'
}

export type SortCustomDashboardCollectionsInput = {
  accountId: Scalars['ObjectID']['input'];
  customDashboardCollectionIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
};

export type SortCustomDashboardCollectionsPayload = {
  customDashboardCollections: Array<CustomDashboardCollection>;
};

export type SortCustomDashboardsInput = {
  customDashboardCollectionId: Scalars['ObjectID']['input'];
  customDashboardIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
};

export type SortCustomDashboardsPayload = {
  customDashboards: Array<CustomDashboard>;
};

export type SplunkConnection = {
  createdAt: Scalars['DateTime']['output'];
  customProxy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ObjectID']['output'];
  isWorking: Scalars['Boolean']['output'];
  proxyCode?: Maybe<SplunkProxyCode>;
  rawID: Scalars['String']['output'];
  splunkProjectQueries: SplunkProjectQueryConnection;
  updatedAt: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
  user: User;
  username: Scalars['String']['output'];
};


export type SplunkConnectionSplunkProjectQueriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SplunkProjectQueryConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SplunkProjectQueryOrder>>;
};

export type SplunkConnectionConnection = {
  /** A list of edges. */
  edges: Array<SplunkConnectionEdge>;
  /** A list of nodes. */
  nodes: Array<SplunkConnection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of SplunkConnections in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SplunkConnectionConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<SplunkConnectionConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<SplunkConnectionConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<SplunkConnectionConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  url?: InputMaybe<ConnectionStringFilterInput>;
  username?: InputMaybe<ConnectionStringFilterInput>;
};

export type SplunkConnectionEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SplunkConnection;
};

export type SplunkConnectionOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order SplunkConnections by. */
  field: SplunkConnectionOrderField;
};

export enum SplunkConnectionOrderField {
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Username = 'username'
}

export type SplunkProjectQuery = {
  baseUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dateRange: Scalars['Int']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ObjectID']['output'];
  project: Project;
  query: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  splunkConnection?: Maybe<SplunkConnection>;
  updatedAt: Scalars['DateTime']['output'];
  useLastCrawlDate: Scalars['Boolean']['output'];
};

export type SplunkProjectQueryConnection = {
  /** A list of edges. */
  edges: Array<SplunkProjectQueryEdge>;
  /** A list of nodes. */
  nodes: Array<SplunkProjectQuery>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of SplunkProjectQueries in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type SplunkProjectQueryConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<SplunkProjectQueryConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<SplunkProjectQueryConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<SplunkProjectQueryConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  dateRange?: InputMaybe<ConnectionIntFilterInput>;
  enabled?: InputMaybe<ConnectionBooleanFilterInput>;
  query?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  useLastCrawlDate?: InputMaybe<ConnectionBooleanFilterInput>;
};

export type SplunkProjectQueryEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SplunkProjectQuery;
};

export type SplunkProjectQueryOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order SplunkProjectQueries by. */
  field: SplunkProjectQueryOrderField;
};

export enum SplunkProjectQueryOrderField {
  CreatedAt = 'createdAt',
  DateRange = 'dateRange',
  Enabled = 'enabled',
  UpdatedAt = 'updatedAt',
  UseLastCrawlDate = 'useLastCrawlDate'
}

export enum SplunkProxyCode {
  Custom = 'Custom'
}

export type SystemSetting = {
  code: Scalars['String']['output'];
  type: SystemSettingType;
  value: Scalars['String']['output'];
};

export enum SystemSettingType {
  Date = 'Date',
  Int = 'Int',
  String = 'String'
}

export type Test = {
  /** The absolute number of pages in the report that will fail the test. */
  absoluteThreshold: Scalars['Int']['output'];
  /** Acceptance mode for automatic threshold, when test results get better */
  automaticThresholdAcceptanceWhenTestResultIsBetter?: Maybe<TestAutoThresholdAcceptance>;
  /** Acceptance mode for automatic threshold, when test results get worse */
  automaticThresholdAcceptanceWhenTestResultIsWorse?: Maybe<TestAutoThresholdAcceptance>;
  /** Whether the test's threshold should be adjusted by the automatic threshold algorithm or not. */
  automaticThresholdEnabled: Scalars['Boolean']['output'];
  createJiraTicketOnFailure: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  jiraTicketCustomNote?: Maybe<Scalars['String']['output']>;
  rawID: Scalars['String']['output'];
  /** Percentage of pages in the report (relative to all crawled pages) that will fail the test. */
  relativeThreshold: Scalars['Int']['output'];
  reportTemplate: ReportTemplate;
  reportTemplateCode: Scalars['String']['output'];
  segment?: Maybe<Segment>;
  severity: Severity;
  testResults: TestResultConnection;
  /** Relational operator for threshold used to calculate test results. */
  thresholdPredicate: ThresholdPredicate;
  /** Type of threshold used to calculate test results. */
  thresholdType: ThresholdType;
  updatedAt: Scalars['DateTime']['output'];
};


export type TestTestResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestResultConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestResultOrder>>;
};

export enum TestAutoThresholdAcceptance {
  AutoAcceptAbsolute = 'AutoAcceptAbsolute',
  None = 'None',
  Suggest = 'Suggest'
}

export type TestConnection = {
  /** A list of edges. */
  edges: Array<TestEdge>;
  /** A list of nodes. */
  nodes: Array<Test>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Tests in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type TestConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<TestConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<TestConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<TestConnectionFilterInput>>;
  absoluteThreshold?: InputMaybe<ConnectionIntFilterInput>;
  automaticThresholdAcceptanceWhenTestResultIsBetter?: InputMaybe<ConnectionTestAutoThresholdAcceptanceFilterInput>;
  automaticThresholdAcceptanceWhenTestResultIsWorse?: InputMaybe<ConnectionTestAutoThresholdAcceptanceFilterInput>;
  automaticThresholdEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  createJiraTicketOnFailure?: InputMaybe<ConnectionBooleanFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  relativeThreshold?: InputMaybe<ConnectionIntFilterInput>;
  severity?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type TestEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Test;
};

export type TestOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Tests by. */
  field: TestOrderField;
};

export enum TestOrderField {
  AbsoluteThreshold = 'absoluteThreshold',
  AutomaticThresholdAcceptanceWhenTestResultIsBetter = 'automaticThresholdAcceptanceWhenTestResultIsBetter',
  AutomaticThresholdAcceptanceWhenTestResultIsWorse = 'automaticThresholdAcceptanceWhenTestResultIsWorse',
  CreatedAt = 'createdAt',
  RelativeThreshold = 'relativeThreshold',
  Severity = 'severity',
  ThresholdType = 'thresholdType',
  UpdatedAt = 'updatedAt'
}

export type TestResult = {
  absoluteThreshold?: Maybe<Scalars['Int']['output']>;
  /** Test's value for automatic threshold acceptance when test results get better, at the time of testing */
  automaticThresholdAcceptanceWhenTestResultIsBetter?: Maybe<TestAutoThresholdAcceptance>;
  /** Test's value for automatic threshold acceptance when test results get worse, at the time of testing */
  automaticThresholdAcceptanceWhenTestResultIsWorse?: Maybe<TestAutoThresholdAcceptance>;
  crawlId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  passed?: Maybe<Scalars['Boolean']['output']>;
  rawID: Scalars['String']['output'];
  relativeThreshold?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use TestResult.reportStat instead. */
  report?: Maybe<Report>;
  reportPercentage?: Maybe<Scalars['Float']['output']>;
  reportStat?: Maybe<ReportStat>;
  reportTemplate: ReportTemplate;
  reportTemplateCode: Scalars['String']['output'];
  reportTotalRows?: Maybe<Scalars['Int']['output']>;
  segment?: Maybe<Segment>;
  severity: Severity;
  status: TestResultStatus;
  suggestedAbsoluteThreshold?: Maybe<Scalars['Int']['output']>;
  suggestedAbsoluteThresholdAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
  suggestedAbsoluteThresholdRejectedAt?: Maybe<Scalars['DateTime']['output']>;
  test?: Maybe<Test>;
  thresholdChangeType?: Maybe<ThresholdChangeType>;
  thresholdPredicate?: Maybe<ThresholdPredicate>;
  thresholdType?: Maybe<ThresholdType>;
};


export type TestResultReportArgs = {
  type?: ReportTypeCode;
};

export type TestResultConnection = {
  /** A list of edges. */
  edges: Array<TestResultEdge>;
  /** A list of nodes. */
  nodes: Array<TestResult>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of TestResults in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type TestResultConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<TestResultConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<TestResultConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<TestResultConnectionFilterInput>>;
  automaticThresholdAcceptanceWhenTestResultIsBetter?: InputMaybe<ConnectionTestAutoThresholdAcceptanceFilterInput>;
  automaticThresholdAcceptanceWhenTestResultIsWorse?: InputMaybe<ConnectionTestAutoThresholdAcceptanceFilterInput>;
  crawlId?: InputMaybe<ConnectionIntFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  passed?: InputMaybe<ConnectionBooleanFilterInput>;
  reportTemplateCode?: InputMaybe<ConnectionStringFilterInput>;
  severity?: InputMaybe<ConnectionStringFilterInput>;
  status?: InputMaybe<ConnectionTestResultStatusFilterInput>;
  suggestedAbsoluteThreshold?: InputMaybe<ConnectionIntFilterInput>;
  suggestedAbsoluteThresholdAcceptedAt?: InputMaybe<ConnectionDateFilterInput>;
  suggestedAbsoluteThresholdRejectedAt?: InputMaybe<ConnectionDateFilterInput>;
  thresholdChangeType?: InputMaybe<ConnectionThresholdChangeTypeFilterInput>;
};

export type TestResultEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TestResult;
};

export type TestResultOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order TestResults by. */
  field: TestResultOrderField;
};

export enum TestResultOrderField {
  AutomaticThresholdAcceptanceWhenTestResultIsBetter = 'automaticThresholdAcceptanceWhenTestResultIsBetter',
  AutomaticThresholdAcceptanceWhenTestResultIsWorse = 'automaticThresholdAcceptanceWhenTestResultIsWorse',
  CrawlId = 'crawlId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Passed = 'passed',
  ReportTemplateCode = 'reportTemplateCode',
  Severity = 'severity',
  Status = 'status',
  SuggestedAbsoluteThresholdAcceptedAt = 'suggestedAbsoluteThresholdAcceptedAt',
  SuggestedAbsoluteThresholdRejectedAt = 'suggestedAbsoluteThresholdRejectedAt'
}

export enum TestResultStatus {
  Done = 'Done',
  Read = 'Read',
  Unresolved = 'Unresolved',
  WorkingOnIt = 'WorkingOnIt'
}

export type TestSuite = BaseProject & {
  account: Account;
  alertEmails?: Maybe<Array<Scalars['String']['output']>>;
  allPagesTotal?: Maybe<Scalars['Int']['output']>;
  apiCallbackHeaders?: Maybe<Array<ApiCallbackHeader>>;
  apiCallbackUrl?: Maybe<Scalars['String']['output']>;
  applyCustomHeadersToNavigationRequestsOnly: Scalars['Boolean']['output'];
  autoFinalizeOnCrawlLimits: Scalars['Boolean']['output'];
  /** Flags if threshold on all tests is calculated automatically, based on previous test results. */
  automaticThreshold: Scalars['Boolean']['output'];
  /** Flags if automatic thresholds are calculated for all (true) or some (false) tests. */
  automaticThresholdEnabledForAll: Scalars['Boolean']['output'];
  /** Blocks third-party cookies. */
  block3rdPartyCookies: Scalars['Boolean']['output'];
  builds: BuildConnection;
  children?: Maybe<TestSuiteConnection>;
  clonedAt?: Maybe<Scalars['DateTime']['output']>;
  compareToCrawl?: Maybe<Scalars['CompareToCrawl']['output']>;
  coreUIUrl: Scalars['String']['output'];
  crawlDisallowedUrls1stLevel: Scalars['Boolean']['output'];
  crawlHyperlinksExternal: Scalars['Boolean']['output'];
  crawlHyperlinksInternal: Scalars['Boolean']['output'];
  crawlImagesExternal: Scalars['Boolean']['output'];
  crawlImagesInternal: Scalars['Boolean']['output'];
  crawlNofollowHyperlinks: Scalars['Boolean']['output'];
  crawlNonHtml: Scalars['Boolean']['output'];
  crawlNotIncluded1stLevel: Scalars['Boolean']['output'];
  crawlOtherRelExternal: Scalars['Boolean']['output'];
  crawlOtherRelInternal: Scalars['Boolean']['output'];
  /** @deprecated Renamed to maximumCrawlRate. */
  crawlRate: Scalars['Float']['output'];
  crawlRedirectsExternal: Scalars['Boolean']['output'];
  crawlRedirectsInternal: Scalars['Boolean']['output'];
  crawlRelAmphtmlExternal: Scalars['Boolean']['output'];
  crawlRelAmphtmlInternal: Scalars['Boolean']['output'];
  crawlRelCanonicalsExternal: Scalars['Boolean']['output'];
  crawlRelCanonicalsInternal: Scalars['Boolean']['output'];
  crawlRelHreflangsExternal: Scalars['Boolean']['output'];
  crawlRelHreflangsInternal: Scalars['Boolean']['output'];
  crawlRelMobileExternal: Scalars['Boolean']['output'];
  crawlRelMobileInternal: Scalars['Boolean']['output'];
  crawlRelNextPrevExternal: Scalars['Boolean']['output'];
  crawlRelNextPrevInternal: Scalars['Boolean']['output'];
  crawlRobotsTxtNoindex: Scalars['Boolean']['output'];
  crawlScriptsExternal: Scalars['Boolean']['output'];
  crawlScriptsInternal: Scalars['Boolean']['output'];
  crawlSitemapsExternal: Scalars['Boolean']['output'];
  crawlSitemapsInternal: Scalars['Boolean']['output'];
  crawlStylesheetsExternal: Scalars['Boolean']['output'];
  crawlStylesheetsInternal: Scalars['Boolean']['output'];
  crawlTestSite: Scalars['Boolean']['output'];
  crawlTypes: Array<TestSuiteCrawlType>;
  crawlUrlsTotal?: Maybe<Scalars['Int']['output']>;
  crawlingAt?: Maybe<Scalars['DateTime']['output']>;
  crawlsTotalCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  customDns?: Maybe<Array<CustomDnsSetting>>;
  /** Use regular expressions to extract custom information from pages when they are crawled. */
  customExtractions?: Maybe<Array<CustomExtractionSetting>>;
  customMetricContainers: CustomMetricContainerConnection;
  customRequestHeaders?: Maybe<Scalars['JSONObject']['output']>;
  dataLayerName?: Maybe<Scalars['String']['output']>;
  dataOnlyCrawlTypes?: Maybe<Array<CrawlType>>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discoverSitemapsInRobotsTxt: Scalars['Boolean']['output'];
  duplicatePrecision: Scalars['Float']['output'];
  emailAlerts?: Maybe<TestSuiteEmailAlertConnection>;
  emptyPageThreshold?: Maybe<Scalars['Int']['output']>;
  enableKeyValueStore: Scalars['Boolean']['output'];
  excludeUrlPatterns?: Maybe<Array<Scalars['String']['output']>>;
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  flattenIframes: Scalars['Boolean']['output'];
  flattenShadowDom: Scalars['Boolean']['output'];
  gaDateRange: Scalars['Int']['output'];
  hasAccessibility: Scalars['Boolean']['output'];
  hasTestSitePassword: Scalars['Boolean']['output'];
  /** @deprecated Renamed to logSummaryRequestsHigh. */
  highLogSummaryRequests: Scalars['Int']['output'];
  id: Scalars['ObjectID']['output'];
  ignoreInvalidSSLCertificate?: Maybe<Scalars['Boolean']['output']>;
  ignoreRobotsForNavigationRequests: Scalars['Boolean']['output'];
  includeBestPractices: Scalars['Boolean']['output'];
  includeHttpAndHttps: Scalars['Boolean']['output'];
  includeSubdomains: Scalars['Boolean']['output'];
  includeUrlPatterns?: Maybe<Array<Scalars['String']['output']>>;
  industryCode?: Maybe<Scalars['String']['output']>;
  isTestSuite: Scalars['Boolean']['output'];
  /** Same as crawlingAt, but includes dates of deleted crawls. */
  lastCrawlCrawlingAt?: Maybe<Scalars['DateTime']['output']>;
  lastCrawlStatus?: Maybe<CrawlStatus>;
  legacyTasksTotalCount: Scalars['Int']['output'];
  limitLevelsMax: Scalars['Int']['output'];
  /** @deprecated Renamed to limitUrlsMax. */
  limitPagesMax: Scalars['Int']['output'];
  limitUrlsMax: Scalars['Int']['output'];
  location: Location;
  logSummaryRequestsHigh: Scalars['Int']['output'];
  logSummaryRequestsLow: Scalars['Int']['output'];
  /** @deprecated Renamed to logSummaryRequestsLow. */
  lowLogSummaryRequests: Scalars['Int']['output'];
  maxBodyContentLength?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Renamed to maxBodyContentLength. */
  maxContentSize: Scalars['Int']['output'];
  maxDescriptionLength?: Maybe<Scalars['Int']['output']>;
  maxExternalLinks?: Maybe<Scalars['Int']['output']>;
  maxHtmlSize?: Maybe<Scalars['Int']['output']>;
  maxLinks?: Maybe<Scalars['Int']['output']>;
  maxLinksProcessed: Scalars['Int']['output'];
  maxLoadTime?: Maybe<Scalars['Float']['output']>;
  maxRedirections: Scalars['Int']['output'];
  maxTitleWidth: Scalars['Int']['output'];
  maxUrlLength?: Maybe<Scalars['Int']['output']>;
  maximumCrawlRate: Scalars['Float']['output'];
  maximumCrawlRateAdvanced?: Maybe<Array<AdvancedCrawlRate>>;
  minContentRatio?: Maybe<Scalars['Float']['output']>;
  minDescriptionLength: Scalars['Int']['output'];
  minTitleLength: Scalars['Int']['output'];
  minVisits: Scalars['Int']['output'];
  mobileHomepageUrl?: Maybe<Scalars['String']['output']>;
  mobileUrlPattern?: Maybe<Scalars['String']['output']>;
  mobileUserAgent?: Maybe<UserAgent>;
  /** Overrides the viewport height during crawling of the mobile site. */
  mobileViewportHeight?: Maybe<Scalars['Int']['output']>;
  /** Overrides the vieport width during crawling of the mobile site. */
  mobileViewportWidth?: Maybe<Scalars['Int']['output']>;
  moduleCode: ModuleCode;
  name: Scalars['String']['output'];
  parent?: Maybe<TestSuite>;
  parentLinkedAt?: Maybe<Scalars['DateTime']['output']>;
  primaryDomain: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  renderTimeout: Scalars['Int']['output'];
  renderWithImages: Scalars['Boolean']['output'];
  rendererBlockAds: Scalars['Boolean']['output'];
  rendererBlockAnalytics: Scalars['Boolean']['output'];
  rendererBlockCustom?: Maybe<Array<Scalars['String']['output']>>;
  rendererCookies?: Maybe<Array<RendererCookie>>;
  rendererJsString?: Maybe<Scalars['String']['output']>;
  rendererJsUrls?: Maybe<Array<Scalars['String']['output']>>;
  renderingRobotsCheckMode?: Maybe<RenderingRobotsCheckMode>;
  robotsOverwrite?: Maybe<Scalars['String']['output']>;
  schedulesTotalCount: Scalars['Int']['output'];
  secondaryDomains?: Maybe<Array<Scalars['String']['output']>>;
  segmentsTotalCount: Scalars['Int']['output'];
  selectedWcagLevel: WcagLevel;
  selectedWcagVersion: Scalars['Float']['output'];
  /** @deprecated The flag is no longer being used. */
  serpentCrawlerEnabled?: Maybe<Scalars['Boolean']['output']>;
  serpentPlatformId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Renamed to primaryDomain. */
  sitePrimary: Scalars['String']['output'];
  /** @deprecated Renamed to testSiteDomain. */
  siteTest?: Maybe<Scalars['String']['output']>;
  sitemapUrls?: Maybe<Array<Scalars['String']['output']>>;
  slackWebhooks?: Maybe<SlackWebhookConnection>;
  startUrls?: Maybe<Array<Scalars['String']['output']>>;
  storeHtml: Scalars['Boolean']['output'];
  targetMaxUncrawledUrlsCount: Scalars['Int']['output'];
  testSiteDomain?: Maybe<Scalars['String']['output']>;
  testSiteUsername?: Maybe<Scalars['String']['output']>;
  testSuiteJiraIntegration?: Maybe<TestSuiteJiraIntegration>;
  tests: TestConnection;
  testsTotalCount: Scalars['Int']['output'];
  thinPageThreshold?: Maybe<Scalars['Int']['output']>;
  totalSteps?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  urlFileUploads: UrlFileUploadConnection;
  urlRewriteQueryParameters?: Maybe<Array<Scalars['String']['output']>>;
  urlRewriteRules?: Maybe<Array<UrlRewriteRule>>;
  urlRewriteStripFragment: Scalars['Boolean']['output'];
  urlSampling?: Maybe<Array<UrlSampling>>;
  /** @deprecated Renamed to excludeUrlPatterns. */
  urlsExcluded?: Maybe<Array<Scalars['String']['output']>>;
  /** @deprecated Renamed to includeUrlPatterns. */
  urlsIncluded?: Maybe<Array<Scalars['String']['output']>>;
  useMobileSettings: Scalars['Boolean']['output'];
  /** Use new Chrome headless mode. */
  useNewHeadlessMode: Scalars['Boolean']['output'];
  useOptimus: Scalars['Boolean']['output'];
  useRenderer: Scalars['Boolean']['output'];
  useRobotsOverwrite: Scalars['Boolean']['output'];
  useStealthMode: Scalars['Boolean']['output'];
  useStealthModeV2: Scalars['Boolean']['output'];
  useUrlRewriteRules: Scalars['Boolean']['output'];
  userAgent?: Maybe<UserAgent>;
  userAgentIsMobile?: Maybe<Scalars['Boolean']['output']>;
  userAgentMobileIsMobile?: Maybe<Scalars['Boolean']['output']>;
  userAgentString?: Maybe<Scalars['String']['output']>;
  userAgentStringMobile?: Maybe<Scalars['String']['output']>;
  userAgentToken?: Maybe<Scalars['String']['output']>;
  userAgentTokenMobile?: Maybe<Scalars['String']['output']>;
  /** Overrides the default viewport height during crawling, if present. */
  viewportHeight?: Maybe<Scalars['Int']['output']>;
  /** Overrides the default viewport width during crawling, if present. */
  viewportWidth?: Maybe<Scalars['Int']['output']>;
  webhooks?: Maybe<WebhookConnection>;
};


export type TestSuiteBuildsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BuildConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BuildOrder>>;
};


export type TestSuiteChildrenArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestSuiteConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestSuiteOrder>>;
};


export type TestSuiteCustomMetricContainersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CustomMetricContainerConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CustomMetricContainerOrder>>;
};


export type TestSuiteEmailAlertsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestSuiteEmailAlertOrder>>;
};


export type TestSuiteSlackWebhooksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SlackWebhookOrder>>;
};


export type TestSuiteTestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TestConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestOrder>>;
};


export type TestSuiteUrlFileUploadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UrlFileUploadConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type TestSuiteWebhooksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<WebhookOrder>>;
};

export type TestSuiteConnection = {
  /** A list of edges. */
  edges: Array<TestSuiteEdge>;
  /** A list of nodes. */
  nodes: Array<TestSuite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of TestSuites in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type TestSuiteConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<TestSuiteConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<TestSuiteConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<TestSuiteConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  allPagesTotal?: InputMaybe<ConnectionIntFilterInput>;
  applyCustomHeadersToNavigationRequestsOnly?: InputMaybe<ConnectionBooleanFilterInput>;
  block3rdPartyCookies?: InputMaybe<ConnectionBooleanFilterInput>;
  clonedAt?: InputMaybe<ConnectionDateFilterInput>;
  crawlRate?: InputMaybe<ConnectionFloatFilterInput>;
  crawlUrlsTotal?: InputMaybe<ConnectionIntFilterInput>;
  crawlingAt?: InputMaybe<ConnectionDateFilterInput>;
  crawlsTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  deletedAt?: InputMaybe<ConnectionDateFilterInput>;
  duplicatePrecision?: InputMaybe<ConnectionFloatFilterInput>;
  emptyPageThreshold?: InputMaybe<ConnectionIntFilterInput>;
  enableKeyValueStore?: InputMaybe<ConnectionBooleanFilterInput>;
  finishedAt?: InputMaybe<ConnectionDateFilterInput>;
  flattenIframes?: InputMaybe<ConnectionBooleanFilterInput>;
  flattenShadowDom?: InputMaybe<ConnectionBooleanFilterInput>;
  highLogSummaryRequests?: InputMaybe<ConnectionIntFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  ignoreRobotsForNavigationRequests?: InputMaybe<ConnectionBooleanFilterInput>;
  includeBestPractices?: InputMaybe<ConnectionBooleanFilterInput>;
  industryCode?: InputMaybe<ConnectionStringFilterInput>;
  isTestSuite?: InputMaybe<ConnectionBooleanFilterInput>;
  lastCrawlCrawlingAt?: InputMaybe<ConnectionDateFilterInput>;
  lastCrawlStatus?: InputMaybe<ConnectionCrawlStatusFilterInput>;
  legacyTasksTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  limitPagesMax?: InputMaybe<ConnectionIntFilterInput>;
  lowLogSummaryRequests?: InputMaybe<ConnectionIntFilterInput>;
  maxContentSize?: InputMaybe<ConnectionIntFilterInput>;
  maxDescriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  maxExternalLinks?: InputMaybe<ConnectionIntFilterInput>;
  maxHtmlSize?: InputMaybe<ConnectionIntFilterInput>;
  maxLinks?: InputMaybe<ConnectionIntFilterInput>;
  maxLoadTime?: InputMaybe<ConnectionFloatFilterInput>;
  maxRedirections?: InputMaybe<ConnectionIntFilterInput>;
  maxTitleWidth?: InputMaybe<ConnectionIntFilterInput>;
  maxUrlLength?: InputMaybe<ConnectionIntFilterInput>;
  minContentRatio?: InputMaybe<ConnectionFloatFilterInput>;
  minDescriptionLength?: InputMaybe<ConnectionIntFilterInput>;
  minTitleLength?: InputMaybe<ConnectionIntFilterInput>;
  moduleCode?: InputMaybe<ConnectionModuleCodeFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
  parentLinkedAt?: InputMaybe<ConnectionDateFilterInput>;
  renderTimeout?: InputMaybe<ConnectionIntFilterInput>;
  renderWithImages?: InputMaybe<ConnectionBooleanFilterInput>;
  rendererBlockAds?: InputMaybe<ConnectionBooleanFilterInput>;
  rendererBlockAnalytics?: InputMaybe<ConnectionBooleanFilterInput>;
  rendererJsString?: InputMaybe<ConnectionStringFilterInput>;
  renderingRobotsCheckMode?: InputMaybe<ConnectionRenderingRobotsCheckModeFilterInput>;
  robotsOverwrite?: InputMaybe<ConnectionStringFilterInput>;
  schedulesTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  segmentsTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  selectedWcagLevel?: InputMaybe<ConnectionWcagLevelFilterInput>;
  selectedWcagVersion?: InputMaybe<ConnectionFloatFilterInput>;
  serpentCrawlerEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  serpentPlatformId?: InputMaybe<ConnectionStringFilterInput>;
  sitePrimary?: InputMaybe<ConnectionStringFilterInput>;
  siteTest?: InputMaybe<ConnectionStringFilterInput>;
  targetMaxUncrawledUrlsCount?: InputMaybe<ConnectionIntFilterInput>;
  testsTotalCount?: InputMaybe<ConnectionIntFilterInput>;
  thinPageThreshold?: InputMaybe<ConnectionIntFilterInput>;
  totalSteps?: InputMaybe<ConnectionIntFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  useNewHeadlessMode?: InputMaybe<ConnectionBooleanFilterInput>;
  useRenderer?: InputMaybe<ConnectionBooleanFilterInput>;
  useRobotsOverwrite?: InputMaybe<ConnectionBooleanFilterInput>;
};

export enum TestSuiteCrawlType {
  Backlinks = 'Backlinks',
  GoogleAnalytics = 'GoogleAnalytics',
  GoogleSearchConsole = 'GoogleSearchConsole',
  List = 'List',
  LogSummary = 'LogSummary',
  Sitemap = 'Sitemap',
  Task = 'Task',
  Web = 'Web'
}

export type TestSuiteEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TestSuite;
};

export type TestSuiteEmailAlert = {
  alertTypes: Array<EmailAlertType>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ObjectID']['output'];
  rawID: Scalars['String']['output'];
  testSuite: TestSuite;
};

export type TestSuiteEmailAlertConnection = {
  /** A list of edges. */
  edges: Array<TestSuiteEmailAlertEdge>;
  /** A list of nodes. */
  nodes: Array<TestSuiteEmailAlert>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of TestSuiteEmailAlerts in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type TestSuiteEmailAlertEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TestSuiteEmailAlert;
};

export type TestSuiteEmailAlertOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order TestSuiteEmailAlerts by. */
  field: TestSuiteEmailAlertOrderField;
};

export enum TestSuiteEmailAlertOrderField {
  CreatedAt = 'createdAt'
}

export type TestSuiteJiraIntegration = {
  id: Scalars['ObjectID']['output'];
  jiraIntegration: JiraIntegration;
  rawID: Scalars['String']['output'];
  testSuite: TestSuite;
};

export type TestSuiteJiraIntegrationConnection = {
  /** A list of edges. */
  edges: Array<TestSuiteJiraIntegrationEdge>;
  /** A list of nodes. */
  nodes: Array<TestSuiteJiraIntegration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of TestSuiteJiraIntegrations in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type TestSuiteJiraIntegrationEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TestSuiteJiraIntegration;
};

export enum TestSuiteLocationCode {
  Custom = 'Custom',
  Default = 'Default'
}

export type TestSuiteOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order TestSuites by. */
  field: TestSuiteOrderField;
};

export enum TestSuiteOrderField {
  AllPagesTotal = 'allPagesTotal',
  ClonedAt = 'clonedAt',
  CrawlRate = 'crawlRate',
  CrawlUrlsTotal = 'crawlUrlsTotal',
  CrawlingAt = 'crawlingAt',
  CrawlsTotalCount = 'crawlsTotalCount',
  CreatedAt = 'createdAt',
  DuplicatePrecision = 'duplicatePrecision',
  EmptyPageThreshold = 'emptyPageThreshold',
  EnableKeyValueStore = 'enableKeyValueStore',
  FinishedAt = 'finishedAt',
  FlattenIframes = 'flattenIframes',
  FlattenShadowDom = 'flattenShadowDom',
  HighLogSummaryRequests = 'highLogSummaryRequests',
  Id = 'id',
  IncludeBestPractices = 'includeBestPractices',
  IndustryCode = 'industryCode',
  IsTestSuite = 'isTestSuite',
  LastCrawlCrawlingAt = 'lastCrawlCrawlingAt',
  LastCrawlStatus = 'lastCrawlStatus',
  LegacyTasksTotalCount = 'legacyTasksTotalCount',
  LimitPagesMax = 'limitPagesMax',
  LowLogSummaryRequests = 'lowLogSummaryRequests',
  MaxContentSize = 'maxContentSize',
  MaxDescriptionLength = 'maxDescriptionLength',
  MaxExternalLinks = 'maxExternalLinks',
  MaxHtmlSize = 'maxHtmlSize',
  MaxLinks = 'maxLinks',
  MaxLoadTime = 'maxLoadTime',
  MaxRedirections = 'maxRedirections',
  MaxTitleWidth = 'maxTitleWidth',
  MaxUrlLength = 'maxUrlLength',
  MinContentRatio = 'minContentRatio',
  MinDescriptionLength = 'minDescriptionLength',
  MinTitleLength = 'minTitleLength',
  Name = 'name',
  ParentLinkedAt = 'parentLinkedAt',
  RenderTimeout = 'renderTimeout',
  RenderWithImages = 'renderWithImages',
  RendererBlockAds = 'rendererBlockAds',
  RendererBlockAnalytics = 'rendererBlockAnalytics',
  RendererJsString = 'rendererJsString',
  RenderingRobotsCheckMode = 'renderingRobotsCheckMode',
  RobotsOverwrite = 'robotsOverwrite',
  SchedulesTotalCount = 'schedulesTotalCount',
  SegmentsTotalCount = 'segmentsTotalCount',
  SelectedWcagLevel = 'selectedWcagLevel',
  SelectedWcagVersion = 'selectedWcagVersion',
  SitePrimary = 'sitePrimary',
  SiteTest = 'siteTest',
  TargetMaxUncrawledUrlsCount = 'targetMaxUncrawledUrlsCount',
  TestsTotalCount = 'testsTotalCount',
  ThinPageThreshold = 'thinPageThreshold',
  TotalSteps = 'totalSteps',
  UpdatedAt = 'updatedAt',
  UseRenderer = 'useRenderer',
  UseRobotsOverwrite = 'useRobotsOverwrite'
}

export enum ThresholdChangeType {
  Better = 'Better',
  Worse = 'Worse'
}

export enum ThresholdPredicate {
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LessThan = 'LessThan'
}

export enum ThresholdType {
  Absolute = 'Absolute',
  Relative = 'Relative'
}

export enum TrendsComputedType {
  AbsDiff = 'absDiff',
  Diff = 'diff',
  Last = 'last'
}

export type TrendsSortOptions = {
  /** The reportTemplateCode or reportCategoryCode of the column to sort the table by. */
  code?: InputMaybe<Scalars['String']['input']>;
  /** The computed trend type. */
  computedType?: InputMaybe<TrendsComputedType>;
  /** The trend field to be sorted. */
  field?: InputMaybe<Scalars['String']['input']>;
};

export type TrendsTableOrderBy = {
  /** The ordering direction. */
  direction: OrderDirection;
  field: TrendsTableSortableField;
  trendsSortOptions?: InputMaybe<TrendsSortOptions>;
};

/** Data types for trend items returned by trend-sdk TrendsHealthScores and TrendsReportStats services. */
export type TrendsTableRow = HealthScoreTrendsTableRow | ReportTrendsTableRow;

export enum TrendsTableSortableField {
  ProjectFinishedAt = 'projectFinishedAt',
  ProjectName = 'projectName',
  SegmentName = 'segmentName',
  Trend = 'trend'
}

export type UnarchiveCrawlInput = {
  crawlId: Scalars['ObjectID']['input'];
};

export type UnlinkChildTestSuiteFromParentTestSuiteInput = {
  childTestSuiteId: Scalars['ObjectID']['input'];
};

export type UnlinkChildTestSuiteFromParentTestSuitePayload = {
  childTestSuite: TestSuite;
  parentTestSuite: TestSuite;
};

export type UnlinkCustomMetricContainerFromProjectInput = {
  customMetricContainerId: Scalars['ObjectID']['input'];
  projectId: Scalars['ObjectID']['input'];
};

export type UnlinkCustomMetricContainerFromProjectPayload = {
  customMetricContainer: CustomMetricContainer;
  project: Project;
};

export type UnlinkJiraIntegrationFromTestSuiteInput = {
  testSuiteJiraIntegrationId: Scalars['ObjectID']['input'];
};

export type UnlinkJiraIntegrationFromTestSuitePayload = {
  testSuiteJiraIntegration: TestSuiteJiraIntegration;
};

export type UnlinkTestSuiteFromBuildScheduleInput = {
  buildScheduleTestSuiteId: Scalars['ObjectID']['input'];
};

export type UnlinkTestSuiteFromBuildSchedulePayload = {
  buildScheduleTestSuite: BuildScheduleTestSuite;
};

export type UpdateAccountInput = {
  accountId: Scalars['ObjectID']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  aiFeaturesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  apiCallbackHeaders?: InputMaybe<Array<ApiCallbackHeaderInput>>;
  apiCallbackUrl?: InputMaybe<Scalars['String']['input']>;
  conductorExpiry?: InputMaybe<Scalars['DateTime']['input']>;
  contractStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  contractTerminationDate?: InputMaybe<Scalars['DateTime']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  csPartnerName?: InputMaybe<Scalars['String']['input']>;
  customLogo?: InputMaybe<Scalars['Upload']['input']>;
  customProxy?: InputMaybe<Scalars['String']['input']>;
  disableUpselling?: InputMaybe<Scalars['Boolean']['input']>;
  limitLevelsMax?: InputMaybe<Scalars['Int']['input']>;
  limitPagesMax?: InputMaybe<Scalars['Int']['input']>;
  maxConcurrentCrawls?: InputMaybe<Scalars['Int']['input']>;
  maxCrawlRate?: InputMaybe<Scalars['Int']['input']>;
  maxCustomReportsPerProject?: InputMaybe<Scalars['Int']['input']>;
  maxDashboardCollectionViews?: InputMaybe<Scalars['Int']['input']>;
  maxGoogleSearchConsolePropertiesPerProject?: InputMaybe<Scalars['Int']['input']>;
  maxLegacyTasksPerProject?: InputMaybe<Scalars['Int']['input']>;
  maxProjectTests?: InputMaybe<Scalars['Int']['input']>;
  maxReportTemplateOverridesPerProject?: InputMaybe<Scalars['Int']['input']>;
  maxSegmentRestrictedFilterPredicates?: InputMaybe<Scalars['Int']['input']>;
  maxSegmentsPerProject?: InputMaybe<Scalars['Int']['input']>;
  maximumRenderTimeout?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serpentVpcs?: InputMaybe<Array<Scalars['String']['input']>>;
  taggedTaskReportDownloadRowsLimit?: InputMaybe<Scalars['Int']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  userAgentSuffix?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAccountPayload = {
  account: Account;
};

export type UpdateAccountUserInput = {
  accountId: Scalars['ObjectID']['input'];
  roleCode: RoleCode;
  userId: Scalars['ObjectID']['input'];
};

export type UpdateAccountUserPayload = {
  account: Account;
};

export type UpdateAdobeAnalyticsProjectReportSuiteInput = {
  adobeAnalyticsProjectReportSuiteId: Scalars['ObjectID']['input'];
  adobeConnectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  adobeJwtConnectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  maxRows?: InputMaybe<Scalars['Int']['input']>;
  minimumVisits?: InputMaybe<Scalars['Int']['input']>;
  numberOfDays?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  reportSuiteId?: InputMaybe<Scalars['String']['input']>;
  suiteName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdobeAnalyticsProjectReportSuitePayload = {
  adobeAnalyticsProjectReportSuite: AdobeAnalyticsProjectReportSuite;
};

export type UpdateAdobeConnectionInput = {
  adobeConnectionId: Scalars['ObjectID']['input'];
  apiUsername?: InputMaybe<Scalars['String']['input']>;
  secret?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdobeConnectionPayload = {
  adobeConnection: AdobeConnection;
};

export type UpdateAdobeJwtConnectionInput = {
  adobeJwtConnectionId: Scalars['ObjectID']['input'];
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  privateKey?: InputMaybe<Scalars['String']['input']>;
  technicalAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdobeJwtConnectionPayload = {
  adobeJwtConnection: AdobeJwtConnection;
};

export type UpdateBuildScheduleInput = {
  buildScheduleId: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  repetitionRate?: InputMaybe<BuildScheduleRepetitionRate>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateBuildSchedulePayload = {
  buildSchedule: BuildSchedule;
};

export type UpdateCrawlSegmentInput = {
  crawlId: Scalars['ObjectID']['input'];
  failedAt?: InputMaybe<Scalars['DateTime']['input']>;
  failureReason?: InputMaybe<Scalars['String']['input']>;
  generatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  generatingAt?: InputMaybe<Scalars['DateTime']['input']>;
  segmentId: Scalars['ObjectID']['input'];
  segmentVersion: Scalars['Int']['input'];
};

export type UpdateCrawlSegmentPayload = {
  crawlSegment: CrawlSegment;
};

export type UpdateCrawlWithTransformCompleteMetadataInput = {
  crawlId: Scalars['ObjectID']['input'];
  totalStepLinks: Scalars['BigInt']['input'];
  totalSteps: Scalars['Int']['input'];
};

export type UpdateCreditAllocationForSsoAccountInput = {
  creditAllocationId: Scalars['ObjectID']['input'];
  creditsAllocated?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<CreditAllocationType>;
};

export type UpdateCreditAllocationForSsoAccountPayload = {
  creditAllocation: CreditAllocation;
};

export type UpdateCurrentUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCurrentUserPayload = {
  user: User;
};

export type UpdateCustomChartInput = {
  customChartId: Scalars['ObjectID']['input'];
  customViewIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  metadata?: InputMaybe<Scalars['JSONObject']['input']>;
  metric?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomChartPayload = {
  customChart: CustomChart;
};

export type UpdateCustomDashboardCollectionInput = {
  customDashboardCollectionId: Scalars['ObjectID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomDashboardCollectionPayload = {
  customDashboardCollection: CustomDashboardCollection;
};

export type UpdateCustomDashboardInput = {
  customDashboardId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateCustomDashboardPayload = {
  customDashboard: CustomDashboard;
};

export type UpdateCustomMetricContainerInput = {
  costs?: InputMaybe<Array<CustomMetricContainerCostInput>>;
  customMetricContainerId: Scalars['ObjectID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  inputType?: InputMaybe<CustomMetricContainerInputType>;
  isEssential?: InputMaybe<Scalars['Boolean']['input']>;
  /** If enabled, the CustomMetricContainer can be used by any Project. */
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  isolate?: InputMaybe<Scalars['Boolean']['input']>;
  latestVersion?: InputMaybe<Scalars['Int']['input']>;
  minRequiredCpu?: InputMaybe<Scalars['Int']['input']>;
  obeyCSP?: InputMaybe<Scalars['Boolean']['input']>;
  requiresResponseBuffer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Which resource types the container extracts metrics from. */
  resourceTypes?: InputMaybe<Array<CustomMetricContainerResourceType>>;
  runFirst?: InputMaybe<Scalars['Boolean']['input']>;
  timeoutMs?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateCustomMetricContainerPayload = {
  customMetricContainer: CustomMetricContainer;
};

export type UpdateCustomMetricContainerProjectInput = {
  /** Parameters that will be passed as part of the context when a container gets executed. */
  containerParams?: InputMaybe<Scalars['JSONObject']['input']>;
  customJsResources?: InputMaybe<Array<Scalars['String']['input']>>;
  customJsScripts?: InputMaybe<Array<Scalars['String']['input']>>;
  customMetricContainerId: Scalars['ObjectID']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  projectId: Scalars['ObjectID']['input'];
};

export type UpdateCustomMetricContainerProjectPayload = {
  customMetricContainer: CustomMetricContainer;
  customMetricContainerProject: CustomMetricContainerProject;
  project: Project;
};

export type UpdateCustomMetricUploadContainerInput = {
  customMetricUploadContainerId: Scalars['ObjectID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  uploadTemplate?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateCustomMetricUploadContainerPayload = {
  customMetricUploadContainer: CustomMetricUploadContainer;
};

export type UpdateCustomReportTemplateInput = {
  customReportTemplateId: Scalars['ObjectID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  metricsGroupings?: InputMaybe<Array<CustomReportTemplateMetricsGroupingInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  /** Inherited from the ReportTemplate if not provided. */
  reportTypes?: InputMaybe<Array<ReportTypeCode>>;
  /** Inherited from the ReportTemplate if not provided. */
  totalSign?: InputMaybe<Scalars['Int']['input']>;
  /** Inherited from the ReportTemplate if not provided. */
  totalWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Updates the reportTemplateQueryVersion to the latest one. */
  updateReportTemplateQueryVersion?: Scalars['Boolean']['input'];
};

export type UpdateCustomReportTemplatePayload = {
  customReportTemplate: CustomReportTemplate;
};

export type UpdateCustomTableInput = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  customTableId: Scalars['ObjectID']['input'];
  filter?: InputMaybe<Scalars['JSONObject']['input']>;
  orderBy?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
};

export type UpdateCustomTablePayload = {
  customTable: CustomTable;
};

export type UpdateFeatureCostInput = {
  costs?: InputMaybe<Array<FeatureCostCostInput>>;
  featureCode: Scalars['String']['input'];
  projectSetting?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGoogleAnalytics4ProjectPropertyInput = {
  googleConnectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  inheritFromSchedulePeriodOverride?: InputMaybe<Scalars['Boolean']['input']>;
  lastNDays?: InputMaybe<Scalars['Int']['input']>;
  minimumSessions?: InputMaybe<Scalars['Int']['input']>;
  minimumViews?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ObjectID']['input'];
  propertyName?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<GoogleAnalytics4SourceType>;
};

export type UpdateGoogleAnalytics4ProjectPropertyPayload = {
  googleAnalytics4ProjectProperty: GoogleAnalytics4ProjectProperty;
};

export type UpdateGoogleSearchConsoleConfigurationInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  excludeQueries?: InputMaybe<Array<Scalars['String']['input']>>;
  includeQueries?: InputMaybe<Array<Scalars['String']['input']>>;
  inheritFromSchedulePeriodOverride?: InputMaybe<Scalars['Boolean']['input']>;
  lastNDays?: InputMaybe<Scalars['Int']['input']>;
  minClicks?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ObjectID']['input'];
  searchType?: InputMaybe<GoogleSearchConsoleSearchType>;
  useSearchConsolePropertyDomainsAsStartUrls?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateGoogleSearchConsoleConfigurationPayload = {
  googleSearchConsoleConfiguration: GoogleSearchConsoleConfiguration;
};

export type UpdateGoogleSearchConsolePropertyInput = {
  googleConnectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  googleSearchConsolePropertyId: Scalars['ObjectID']['input'];
  permissionLevel?: InputMaybe<Scalars['String']['input']>;
  siteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGoogleSearchConsolePropertyPayload = {
  googleSearchConsoleProperty: GoogleSearchConsoleProperty;
};

export type UpdateJiraAuthenticationInput = {
  jiraAuthenticationId: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateJiraAuthenticationPayload = {
  jiraAuthentication: JiraAuthentication;
};

export type UpdateJiraIntegrationInput = {
  jiraIntegrationId: Scalars['ObjectID']['input'];
  jiraIssueTypeId?: InputMaybe<Scalars['String']['input']>;
  jiraProjectId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJiraIntegrationPayload = {
  jiraIntegration: JiraIntegration;
};

export type UpdateLegacyTaskInput = {
  assignedTo?: InputMaybe<Array<Scalars['String']['input']>>;
  deadlineAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fixedAt?: InputMaybe<Scalars['DateTime']['input']>;
  howToFix?: InputMaybe<Scalars['String']['input']>;
  legacyTaskId: Scalars['ObjectID']['input'];
  notifyAssignees?: Scalars['Boolean']['input'];
  priority?: InputMaybe<LegacyTaskPriority>;
  status?: InputMaybe<LegacyTaskStatus>;
  ticketCrawlId?: InputMaybe<Scalars['ObjectID']['input']>;
  ticketDetails?: InputMaybe<Scalars['JSONObject']['input']>;
  ticketGenerationFinishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  ticketGenerationRequestedAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLegacyTaskPayload = {
  legacyTask: LegacyTask;
};

export type UpdateLogzioConnectionInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  logzioConnectionId: Scalars['ObjectID']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLogzioConnectionPayload = {
  logzioConnection: LogzioConnection;
};

export type UpdateLogzioProjectQueryInput = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<Scalars['Int']['input']>;
  desktopUaNotRegexp?: InputMaybe<Scalars['String']['input']>;
  desktopUaRegexp?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  logzioConnectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  logzioProjectQueryId: Scalars['ObjectID']['input'];
  maxRows?: InputMaybe<Scalars['Int']['input']>;
  mobileUaNotRegexp?: InputMaybe<Scalars['String']['input']>;
  mobileUaRegexp?: InputMaybe<Scalars['String']['input']>;
  pathFieldName?: InputMaybe<Scalars['String']['input']>;
  queryFilter?: InputMaybe<Scalars['JSONObject']['input']>;
  useLastCrawlDate?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentFieldName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateLogzioProjectQueryPayload = {
  logzioProjectQuery: LogzioProjectQuery;
};

export type UpdateMajesticConfigurationInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  maxRows?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['ObjectID']['input'];
  useHistoricData?: InputMaybe<Scalars['Boolean']['input']>;
  useRootDomain?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateMajesticConfigurationPayload = {
  majesticConfiguration: MajesticConfiguration;
};

export type UpdateMonitorNotificationsStatusInput = {
  accountId: Scalars['ObjectID']['input'];
  /** Filtering options for TestResults. */
  filter?: InputMaybe<TestResultConnectionFilterInput>;
  projectIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  testResultIds?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  testResultIdsBlacklist?: InputMaybe<Scalars['Boolean']['input']>;
  toStatus: TestResultStatus;
};

export type UpdateMonitorNotificationsStatusPayload = {
  updatedTestResultsCount: Scalars['Int']['output'];
};

export type UpdatePasswordInput = {
  newPassword: Scalars['String']['input'];
  /** Skips leaked password validation using https://haveibeenpwned.com/ service. */
  skipLeakedPasswordValidation?: Scalars['Boolean']['input'];
  userId?: InputMaybe<Scalars['ObjectID']['input']>;
};

export type UpdatePasswordPayload = {
  passwordUpdated: Scalars['Boolean']['output'];
  passwordValidationErrors?: Maybe<Array<Scalars['String']['output']>>;
};

export type UpdateProjectInput = {
  alertEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  alertSettingCode?: InputMaybe<AlertSettingCode>;
  apiCallbackHeaders?: InputMaybe<Array<ApiCallbackHeaderInput>>;
  apiCallbackUrl?: InputMaybe<Scalars['String']['input']>;
  applyCustomHeadersToNavigationRequestsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  autoFinalizeOnCrawlLimits?: InputMaybe<Scalars['Boolean']['input']>;
  block3rdPartyCookies?: InputMaybe<Scalars['Boolean']['input']>;
  compareToCrawl?: InputMaybe<Scalars['CompareToCrawl']['input']>;
  crawlDisallowedUrls1stLevel?: InputMaybe<Scalars['Boolean']['input']>;
  crawlHyperlinksExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlHyperlinksInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlImagesExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlImagesInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlNofollowHyperlinks?: InputMaybe<Scalars['Boolean']['input']>;
  crawlNonHtml?: InputMaybe<Scalars['Boolean']['input']>;
  crawlNotIncluded1stLevel?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRedirectsExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRedirectsInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelAmphtmlExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelAmphtmlInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelCanonicalsExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelCanonicalsInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelHreflangsExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelHreflangsInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelMobileExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelMobileInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelNextPrevExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRelNextPrevInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRobotsTxtNoindex?: InputMaybe<Scalars['Boolean']['input']>;
  crawlScriptsExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlScriptsInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlStylesheetsExternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlStylesheetsInternal?: InputMaybe<Scalars['Boolean']['input']>;
  crawlTestSite?: InputMaybe<Scalars['Boolean']['input']>;
  crawlTypes?: InputMaybe<Array<CrawlType>>;
  customDns?: InputMaybe<Array<CustomDnsSettingInput>>;
  customExtractions?: InputMaybe<Array<CustomExtractionSettingInput>>;
  customRequestHeaders?: InputMaybe<Array<CustomRequestHeaderInput>>;
  dataLayerName?: InputMaybe<Scalars['String']['input']>;
  dataOnlyCrawlTypes?: InputMaybe<Array<CrawlType>>;
  discoverSitemapsInRobotsTxt?: InputMaybe<Scalars['Boolean']['input']>;
  duplicatePrecision?: InputMaybe<Scalars['Float']['input']>;
  emptyPageThreshold?: InputMaybe<Scalars['Int']['input']>;
  enableKeyValueStore?: InputMaybe<Scalars['Boolean']['input']>;
  excludeUrlPatterns?: InputMaybe<Array<Scalars['String']['input']>>;
  flattenIframes?: InputMaybe<Scalars['Boolean']['input']>;
  flattenShadowDom?: InputMaybe<Scalars['Boolean']['input']>;
  gaDateRange?: InputMaybe<Scalars['Int']['input']>;
  ignoreInvalidSSLCertificate?: InputMaybe<Scalars['Boolean']['input']>;
  ignoreRobotsForNavigationRequests?: InputMaybe<Scalars['Boolean']['input']>;
  includeBestPractices?: InputMaybe<Scalars['Boolean']['input']>;
  includeHttpAndHttps?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubdomains?: InputMaybe<Scalars['Boolean']['input']>;
  includeUrlPatterns?: InputMaybe<Array<Scalars['String']['input']>>;
  industryCode?: InputMaybe<Scalars['String']['input']>;
  limitLevelsMax?: InputMaybe<Scalars['Int']['input']>;
  limitUrlsMax?: InputMaybe<Scalars['Int']['input']>;
  locationCode?: InputMaybe<LocationCode>;
  logSummaryRequestsHigh?: InputMaybe<Scalars['Int']['input']>;
  logSummaryRequestsLow?: InputMaybe<Scalars['Int']['input']>;
  maxBodyContentLength?: InputMaybe<Scalars['Int']['input']>;
  maxDescriptionLength?: InputMaybe<Scalars['Int']['input']>;
  maxFollowedExternalLinks?: InputMaybe<Scalars['Int']['input']>;
  maxHtmlSize?: InputMaybe<Scalars['Int']['input']>;
  maxLinks?: InputMaybe<Scalars['Int']['input']>;
  maxLoadTime?: InputMaybe<Scalars['Float']['input']>;
  maxRedirections?: InputMaybe<Scalars['Int']['input']>;
  maxTitleWidth?: InputMaybe<Scalars['Int']['input']>;
  maxUrlLength?: InputMaybe<Scalars['Int']['input']>;
  maximumCrawlRate?: InputMaybe<Scalars['Float']['input']>;
  maximumCrawlRateAdvanced?: InputMaybe<Array<AdvancedCrawlRateInput>>;
  minContentRatio?: InputMaybe<Scalars['Float']['input']>;
  minDescriptionLength?: InputMaybe<Scalars['Int']['input']>;
  minTitleLength?: InputMaybe<Scalars['Int']['input']>;
  minVisits?: InputMaybe<Scalars['Int']['input']>;
  mobileHomepageUrl?: InputMaybe<Scalars['String']['input']>;
  mobileUrlPattern?: InputMaybe<Scalars['String']['input']>;
  mobileUserAgentCode?: InputMaybe<Scalars['String']['input']>;
  mobileViewportHeight?: InputMaybe<Scalars['Int']['input']>;
  mobileViewportWidth?: InputMaybe<Scalars['Int']['input']>;
  moduleCode?: InputMaybe<ModuleCode>;
  name?: InputMaybe<Scalars['String']['input']>;
  primaryDomain?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ObjectID']['input'];
  renderTimeout?: InputMaybe<Scalars['Int']['input']>;
  renderWithImages?: InputMaybe<Scalars['Boolean']['input']>;
  rendererBlockAds?: InputMaybe<Scalars['Boolean']['input']>;
  rendererBlockAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
  rendererBlockCustom?: InputMaybe<Array<Scalars['String']['input']>>;
  rendererCookies?: InputMaybe<Array<RendererCookieInput>>;
  rendererJsString?: InputMaybe<Scalars['String']['input']>;
  rendererJsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  renderingRobotsCheckMode?: InputMaybe<RenderingRobotsCheckMode>;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  secondaryDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  selectedWcagLevel?: InputMaybe<WcagLevel>;
  selectedWcagVersion?: InputMaybe<Scalars['Float']['input']>;
  startUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  storeHtml?: InputMaybe<Scalars['Boolean']['input']>;
  targetMaxUncrawledUrlsCount?: InputMaybe<Scalars['Int']['input']>;
  testSiteDomain?: InputMaybe<Scalars['String']['input']>;
  testSitePassword?: InputMaybe<Scalars['String']['input']>;
  testSiteUsername?: InputMaybe<Scalars['String']['input']>;
  thinPageThreshold?: InputMaybe<Scalars['Int']['input']>;
  urlRewriteQueryParameters?: InputMaybe<Array<Scalars['String']['input']>>;
  urlRewriteRules?: InputMaybe<Array<UrlRewriteRuleInput>>;
  urlRewriteStripFragment?: InputMaybe<Scalars['Boolean']['input']>;
  urlSampling?: InputMaybe<Array<UrlSamplingInput>>;
  useMobileSettings?: InputMaybe<Scalars['Boolean']['input']>;
  useNewHeadlessMode?: InputMaybe<Scalars['Boolean']['input']>;
  useRenderer?: InputMaybe<Scalars['Boolean']['input']>;
  useRobotsOverwrite?: InputMaybe<Scalars['Boolean']['input']>;
  useStealthMode?: InputMaybe<Scalars['Boolean']['input']>;
  useStealthModeV2?: InputMaybe<Scalars['Boolean']['input']>;
  useUrlRewriteRules?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentCode?: InputMaybe<Scalars['String']['input']>;
  userAgentIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentMobileIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentString?: InputMaybe<Scalars['String']['input']>;
  userAgentStringMobile?: InputMaybe<Scalars['String']['input']>;
  userAgentToken?: InputMaybe<Scalars['String']['input']>;
  userAgentTokenMobile?: InputMaybe<Scalars['String']['input']>;
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProjectPayload = {
  project: Project;
};

export type UpdateReportTemplateMetadataInput = {
  requiredCrawlTypes?: InputMaybe<Array<CrawlType>>;
  requiredDatasources?: InputMaybe<Array<DatasourceCode>>;
  supportedContainerTableTypes?: InputMaybe<Array<CustomMetricContainerTableType>>;
  unit?: InputMaybe<ReportTemplateUnit>;
};

export type UpdateReportTemplateOverrideInput = {
  projectId: Scalars['ObjectID']['input'];
  reportTemplateCode: Scalars['String']['input'];
  totalSign?: InputMaybe<Scalars['Int']['input']>;
  totalWeight?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateReportTemplateOverridePayload = {
  reportTemplateOverride: ReportTemplateOverride;
};

export type UpdateScheduleInput = {
  nextRunTime?: InputMaybe<Scalars['DateTime']['input']>;
  scheduleFrequency?: InputMaybe<ScheduleFrequencyCode>;
  scheduleId: Scalars['ObjectID']['input'];
};

export type UpdateSchedulePayload = {
  schedule: Schedule;
};

export type UpdateSegmentInput = {
  crawlUrlFilter?: InputMaybe<CrawlUrlConnectionFilterInput>;
  group?: InputMaybe<Scalars['String']['input']>;
  lastFailedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastGeneratedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  segmentId: Scalars['ObjectID']['input'];
};

export type UpdateSegmentPayload = {
  segment: Segment;
};

export type UpdateSegmentsPayload = {
  segments: Array<Segment>;
};

export type UpdateSlackWebhookInput = {
  alertTypes: Array<SlackAlertType>;
  slackWebhookId: Scalars['ObjectID']['input'];
};

export type UpdateSplunkConnectionInput = {
  customProxy?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  proxyCode?: InputMaybe<SplunkProxyCode>;
  splunkConnectionId: Scalars['ObjectID']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSplunkConnectionPayload = {
  splunkConnection: SplunkConnection;
};

export type UpdateSplunkProjectQueryInput = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<Scalars['Int']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  splunkConnectionId?: InputMaybe<Scalars['ObjectID']['input']>;
  splunkProjectQueryId: Scalars['ObjectID']['input'];
  useLastCrawlDate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSplunkProjectQueryPayload = {
  splunkProjectQuery: SplunkProjectQuery;
};

export type UpdateSsoAccountInput = {
  accountId: Scalars['ObjectID']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  customLogo?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSsoAccountPayload = {
  account: Account;
};

export type UpdateSsoAccountSubscriptionInput = {
  accountId: Scalars['ObjectID']['input'];
  planCode?: InputMaybe<Scalars['String']['input']>;
  /** Full list of settings to be included with the account subscription plan. */
  planSettings?: InputMaybe<Array<AccountSubscriptionPlanSettingInput>>;
};

export type UpdateSsoAccountSubscriptionPayload = {
  accountSubscription: AccountSubscription;
};

export type UpdateTestInput = {
  absoluteThreshold?: InputMaybe<Scalars['Int']['input']>;
  automaticThresholdAcceptance?: InputMaybe<TestAutoThresholdAcceptance>;
  automaticThresholdAcceptanceWhenTestResultIsBetter?: InputMaybe<TestAutoThresholdAcceptance>;
  automaticThresholdAcceptanceWhenTestResultIsWorse?: InputMaybe<TestAutoThresholdAcceptance>;
  automaticThresholdEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  automaticThresholdWhenTestResultIsBetter?: InputMaybe<Scalars['Boolean']['input']>;
  automaticThresholdWhenTestResultIsWorse?: InputMaybe<Scalars['Boolean']['input']>;
  createJiraTicketOnFailure?: InputMaybe<Scalars['Boolean']['input']>;
  jiraTicketCustomNote?: InputMaybe<Scalars['String']['input']>;
  relativeThreshold?: InputMaybe<Scalars['Int']['input']>;
  segmentId?: InputMaybe<Scalars['ObjectID']['input']>;
  severity?: InputMaybe<Severity>;
  testId: Scalars['ObjectID']['input'];
  thresholdPredicate?: InputMaybe<ThresholdPredicate>;
  thresholdType?: InputMaybe<ThresholdType>;
};

export type UpdateTestPayload = {
  test: Test;
};

export type UpdateTestResultInput = {
  status: TestResultStatus;
  testResultId: Scalars['ObjectID']['input'];
};

export type UpdateTestResultPayload = {
  testResult: TestResult;
};

export type UpdateTestSuiteEmailAlertInput = {
  alertTypes?: InputMaybe<Array<EmailAlertType>>;
  email?: InputMaybe<Scalars['String']['input']>;
  testSuiteEmailAlertId: Scalars['ObjectID']['input'];
};

export type UpdateTestSuiteInput = {
  alertEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  applyCustomHeadersToNavigationRequestsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  automaticThreshold?: InputMaybe<Scalars['Boolean']['input']>;
  automaticThresholdEnabledForAll?: InputMaybe<Scalars['Boolean']['input']>;
  block3rdPartyCookies?: InputMaybe<Scalars['Boolean']['input']>;
  crawlRate?: InputMaybe<Scalars['Float']['input']>;
  crawlTypes?: InputMaybe<Array<TestSuiteCrawlType>>;
  customDns?: InputMaybe<Array<CustomDnsSettingInput>>;
  customExtractions?: InputMaybe<Array<CustomExtractionSettingInput>>;
  customHeaderUserAgent?: InputMaybe<Scalars['String']['input']>;
  customHeaderUserAgentShort?: InputMaybe<Scalars['String']['input']>;
  duplicatePrecision?: InputMaybe<Scalars['Int']['input']>;
  emptyPageThreshold?: InputMaybe<Scalars['Int']['input']>;
  flattenIframes?: InputMaybe<Scalars['Boolean']['input']>;
  flattenShadowDom?: InputMaybe<Scalars['Boolean']['input']>;
  highLogSummaryRequests?: InputMaybe<Scalars['Int']['input']>;
  ignoreRobotsForNavigationRequests?: InputMaybe<Scalars['Boolean']['input']>;
  includeBestPractices?: InputMaybe<Scalars['Boolean']['input']>;
  limitPagesMax?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<TestSuiteLocationCode>;
  lowLogSummaryRequests?: InputMaybe<Scalars['Int']['input']>;
  maxContentSize?: InputMaybe<Scalars['Int']['input']>;
  maxDescriptionLength?: InputMaybe<Scalars['Int']['input']>;
  maxExternalLinks?: InputMaybe<Scalars['Int']['input']>;
  maxHtmlSize?: InputMaybe<Scalars['Int']['input']>;
  maxLinks?: InputMaybe<Scalars['Int']['input']>;
  maxLoadTime?: InputMaybe<Scalars['Float']['input']>;
  maxRedirections?: InputMaybe<Scalars['Int']['input']>;
  maxTitleWidth?: InputMaybe<Scalars['Int']['input']>;
  maxUrlLength?: InputMaybe<Scalars['Int']['input']>;
  minContentRatio?: InputMaybe<Scalars['Float']['input']>;
  minDescriptionLength?: InputMaybe<Scalars['Int']['input']>;
  minTitleLength?: InputMaybe<Scalars['Int']['input']>;
  mobileUserAgentCode?: InputMaybe<Scalars['String']['input']>;
  mobileViewportHeight?: InputMaybe<Scalars['Int']['input']>;
  mobileViewportWidth?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rendererBlockAds?: InputMaybe<Scalars['Boolean']['input']>;
  rendererBlockAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
  rendererBlockCustom?: InputMaybe<Array<Scalars['String']['input']>>;
  rendererCookies?: InputMaybe<Array<RendererCookieInput>>;
  rendererJsString?: InputMaybe<Scalars['String']['input']>;
  rendererJsUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  renderingRobotsCheckMode?: InputMaybe<RenderingRobotsCheckMode>;
  robotsOverwrite?: InputMaybe<Scalars['String']['input']>;
  selectedWcagLevel?: InputMaybe<WcagLevel>;
  selectedWcagVersion?: InputMaybe<Scalars['Float']['input']>;
  sitePrimary?: InputMaybe<Scalars['String']['input']>;
  siteTest?: InputMaybe<Scalars['String']['input']>;
  siteTestPass?: InputMaybe<Scalars['String']['input']>;
  siteTestUser?: InputMaybe<Scalars['String']['input']>;
  startUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  testSuiteId: Scalars['ObjectID']['input'];
  thinPageThreshold?: InputMaybe<Scalars['Int']['input']>;
  urlsExcluded?: InputMaybe<Array<Scalars['String']['input']>>;
  urlsIncluded?: InputMaybe<Array<Scalars['String']['input']>>;
  useNewHeadlessMode?: InputMaybe<Scalars['Boolean']['input']>;
  useRenderer?: InputMaybe<Scalars['Boolean']['input']>;
  useRobotsOverwrite?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentCode?: InputMaybe<Scalars['String']['input']>;
  userAgentIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentMobileIsMobile?: InputMaybe<Scalars['Boolean']['input']>;
  userAgentString?: InputMaybe<Scalars['String']['input']>;
  userAgentStringMobile?: InputMaybe<Scalars['String']['input']>;
  userAgentToken?: InputMaybe<Scalars['String']['input']>;
  userAgentTokenMobile?: InputMaybe<Scalars['String']['input']>;
  viewportHeight?: InputMaybe<Scalars['Int']['input']>;
  viewportWidth?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTestSuitePayload = {
  testSuite: TestSuite;
};

export type UpdateTestsPayload = {
  tests: Array<Test>;
};

export type UpdateUrlFileUploadInput = {
  /** @deprecated No longer used. */
  customType?: InputMaybe<Scalars['Boolean']['input']>;
  customUploadTemplate?: InputMaybe<Scalars['JSONObject']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  projectUploadType?: InputMaybe<ProjectUploadType>;
  uploadBaseDomain?: InputMaybe<Scalars['String']['input']>;
  urlFileUploadId: Scalars['ObjectID']['input'];
};

export type UpdateUrlFileUploadPayload = {
  urlFileUpload: UrlFileUpload;
};

export type UpdateWebhookInput = {
  alertTypes: Array<AlertType>;
  webhookId: Scalars['ObjectID']['input'];
  webhookTemplateType: WebhookTemplateType;
};

export type UrlFileUpload = {
  crawlType: CrawlTypeMetadata;
  createdAt: Scalars['DateTime']['output'];
  customMetricUploadContainer?: Maybe<CustomMetricUploadContainer>;
  customUploadTemplate?: Maybe<Scalars['JSONObject']['output']>;
  enabled: Scalars['Boolean']['output'];
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  id: Scalars['ObjectID']['output'];
  isCustomizable: Scalars['Boolean']['output'];
  processingFeedback?: Maybe<Scalars['String']['output']>;
  rawID: Scalars['String']['output'];
  sample: Scalars['String']['output'];
  status: UrlFileUploadStatus;
  totalRows?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uploadBaseDomain?: Maybe<Scalars['String']['output']>;
  urlFileUploadType?: Maybe<UrlFileUploadType>;
};

export type UrlFileUploadConnection = {
  /** A list of edges. */
  edges: Array<UrlFileUploadEdge>;
  /** A list of nodes. */
  nodes: Array<UrlFileUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of UrlFileUploads in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type UrlFileUploadConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<UrlFileUploadConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<UrlFileUploadConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<UrlFileUploadConnectionFilterInput>>;
  crawlTypeId?: InputMaybe<ConnectionObjectIdFilterInput>;
  fileName?: InputMaybe<ConnectionStringFilterInput>;
};

export type UrlFileUploadEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UrlFileUpload;
};

export enum UrlFileUploadStatus {
  Draft = 'Draft',
  Errored = 'Errored',
  Processed = 'Processed',
  Processing = 'Processing'
}

export type UrlFileUploadType = {
  code: ProjectUploadType;
  crawlType: CrawlTypeMetadata;
  id: Scalars['ObjectID']['output'];
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  rawID: Scalars['String']['output'];
  uploadTemplate?: Maybe<Scalars['JSONObject']['output']>;
};

export type UrlFileUploadTypeConnection = {
  /** A list of edges. */
  edges: Array<UrlFileUploadTypeEdge>;
  /** A list of nodes. */
  nodes: Array<UrlFileUploadType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of UrlFileUploadTypes in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type UrlFileUploadTypeConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<UrlFileUploadTypeConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<UrlFileUploadTypeConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<UrlFileUploadTypeConnectionFilterInput>>;
  name?: InputMaybe<ConnectionStringFilterInput>;
};

export type UrlFileUploadTypeEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UrlFileUploadType;
};

export type UrlFileUploadTypeOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order UrlFileUploadTypes by. */
  field: UrlFileUploadTypeOrderField;
};

export enum UrlFileUploadTypeOrderField {
  Name = 'name'
}

export enum UrlRewriteCaseOption {
  RewriteToLowercase = 'RewriteToLowercase',
  RewriteToUppercase = 'RewriteToUppercase'
}

export type UrlRewriteRule = {
  caseOption?: Maybe<UrlRewriteCaseOption>;
  matchFrom: Scalars['String']['output'];
  rewriteTo: Scalars['String']['output'];
};

export type UrlRewriteRuleInput = {
  caseOption?: InputMaybe<UrlRewriteCaseOption>;
  matchFrom: Scalars['String']['input'];
  rewriteTo: Scalars['String']['input'];
};

export type UrlSampling = {
  name: Scalars['String']['output'];
  regexPattern: Scalars['String']['output'];
  samplePercentage: Scalars['Float']['output'];
};

export type UrlSamplingInput = {
  name: Scalars['String']['input'];
  regexPattern: Scalars['String']['input'];
  samplePercentage: Scalars['Float']['input'];
};

export type User = {
  accounts: AccountConnection;
  accountsUsers: AccountUserConnection;
  adobeConnections: AdobeConnectionConnection;
  adobeJwtConnections: AdobeJwtConnectionConnection;
  auth0UserId?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailValidatedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  googleConnections: GoogleConnectionConnection;
  id: Scalars['ObjectID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  limitLevelsMax: Scalars['Int']['output'];
  limitPagesMax: Scalars['Int']['output'];
  logzioConnections: LogzioConnectionConnection;
  overallLimitLevelsMax: Scalars['Int']['output'];
  overallLimitPagesMax: Scalars['Int']['output'];
  permissions: Array<Permission>;
  rawID: Scalars['String']['output'];
  splunkConnections: SplunkConnectionConnection;
  ssoAttributes?: Maybe<Scalars['JSONObject']['output']>;
  ssoClientId?: Maybe<Scalars['String']['output']>;
  termsAgreed: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userData?: Maybe<Scalars['JSON']['output']>;
  userKeys: UserKeyConnection;
  username?: Maybe<Scalars['String']['output']>;
};


export type UserAccountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccountOrder>>;
};


export type UserAccountsUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountUserConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserAdobeConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AdobeConnectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdobeConnectionOrder>>;
};


export type UserAdobeJwtConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AdobeJwtConnectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AdobeJwtConnectionOrder>>;
};


export type UserGoogleConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserLogzioConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LogzioConnectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LogzioConnectionOrder>>;
};


export type UserSplunkConnectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SplunkConnectionConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SplunkConnectionOrder>>;
};


export type UserUserDataArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type UserUserKeysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserKeyConnectionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserKeyOrder>>;
};

export type UserAgent = {
  code: Scalars['String']['output'];
  isDeprecated: Scalars['Boolean']['output'];
  isMobile: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  supportedModules?: Maybe<Array<ModuleCode>>;
  userAgentStrings: Array<UserAgentStrings>;
};

export type UserAgentConnection = {
  /** A list of edges. */
  edges: Array<UserAgentEdge>;
  /** A list of nodes. */
  nodes: Array<UserAgent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of UserAgents in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type UserAgentConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<UserAgentConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<UserAgentConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<UserAgentConnectionFilterInput>>;
  code?: InputMaybe<ConnectionStringFilterInput>;
  isDeprecated?: InputMaybe<ConnectionBooleanFilterInput>;
  isMobile?: InputMaybe<ConnectionBooleanFilterInput>;
  isPublic?: InputMaybe<ConnectionBooleanFilterInput>;
  name?: InputMaybe<ConnectionStringFilterInput>;
};

export type UserAgentEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UserAgent;
};

export type UserAgentOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order UserAgents by. */
  field: UserAgentOrderField;
};

export enum UserAgentOrderField {
  Code = 'code',
  IsDeprecated = 'isDeprecated',
  IsMobile = 'isMobile',
  IsPublic = 'isPublic',
  Name = 'name'
}

export type UserAgentStrings = {
  full: Scalars['String']['output'];
  short: Scalars['String']['output'];
};

export type UserAgentStringsInput = {
  full: Scalars['String']['input'];
  short: Scalars['String']['input'];
};

export type UserConnection = {
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** A list of nodes. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Users in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type UserConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<UserConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<UserConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<UserConnectionFilterInput>>;
  auth0UserId?: InputMaybe<ConnectionStringFilterInput>;
  country?: InputMaybe<ConnectionStringFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  email?: InputMaybe<ConnectionStringFilterInput>;
  emailValidatedAt?: InputMaybe<ConnectionDateFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  isEnabled?: InputMaybe<ConnectionBooleanFilterInput>;
  limitLevelsMax?: InputMaybe<ConnectionIntFilterInput>;
  limitPagesMax?: InputMaybe<ConnectionIntFilterInput>;
  ssoClientId?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
  username?: InputMaybe<ConnectionStringFilterInput>;
};

export type UserEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: User;
};

export type UserInfo = {
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type UserInvite = {
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ObjectID']['output'];
  rawID: Scalars['String']['output'];
  ssoClientId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserInviteConnection = {
  /** A list of edges. */
  edges: Array<UserInviteEdge>;
  /** A list of nodes. */
  nodes: Array<UserInvite>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of UserInvites in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type UserInviteConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<UserInviteConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<UserInviteConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<UserInviteConnectionFilterInput>>;
  accountId?: InputMaybe<ConnectionObjectIdFilterInput>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  email?: InputMaybe<ConnectionStringFilterInput>;
  id?: InputMaybe<ConnectionObjectIdFilterInput>;
  ssoClientId?: InputMaybe<ConnectionStringFilterInput>;
  updatedAt?: InputMaybe<ConnectionDateFilterInput>;
};

export type UserInviteEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UserInvite;
};

export type UserInviteOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order UserInvites by. */
  field: UserInviteOrderField;
};

export enum UserInviteOrderField {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type UserKey = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rawID: Scalars['String']['output'];
  /** Plain text secret, only visible right after creation of UserKey via createUserKey. */
  secret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
};

export type UserKeyConnection = {
  /** A list of edges. */
  edges: Array<UserKeyEdge>;
  /** A list of nodes. */
  nodes: Array<UserKey>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of UserKeys in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type UserKeyConnectionFilterInput = {
  /** Groups each filter object using AND */
  _and?: InputMaybe<Array<UserKeyConnectionFilterInput>>;
  /** Groups each filter object using AND, wrapped inside NOT */
  _not?: InputMaybe<Array<UserKeyConnectionFilterInput>>;
  /** Groups each filter object using OR */
  _or?: InputMaybe<Array<UserKeyConnectionFilterInput>>;
  createdAt?: InputMaybe<ConnectionDateFilterInput>;
  userId?: InputMaybe<ConnectionObjectIdFilterInput>;
};

export type UserKeyEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: UserKey;
};

export type UserKeyOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order UserKeys by. */
  field: UserKeyOrderField;
};

export enum UserKeyOrderField {
  CreatedAt = 'createdAt'
}

export type UserOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Users by. */
  field: UserOrderField;
};

export enum UserOrderField {
  Auth0UserId = 'auth0UserId',
  CreatedAt = 'createdAt',
  Email = 'email',
  EmailValidatedAt = 'emailValidatedAt',
  Id = 'id',
  IsEnabled = 'isEnabled',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export enum WcagLevel {
  A = 'A',
  Aa = 'AA',
  Aaa = 'AAA'
}

export type Webhook = {
  alertTypes: Array<AlertType>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ObjectID']['output'];
  rawID: Scalars['String']['output'];
  testSuite: TestSuite;
  url: Scalars['String']['output'];
  webhookTemplateType: WebhookTemplateType;
};

export type WebhookConnection = {
  /** A list of edges. */
  edges: Array<WebhookEdge>;
  /** A list of nodes. */
  nodes: Array<Webhook>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of Webhooks in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type WebhookEdge = {
  /** A cursor to use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Webhook;
};

export type WebhookOrder = {
  /** The ordering direction. */
  direction: OrderDirection;
  /** The field to order Webhooks by. */
  field: WebhookOrderField;
};

export enum WebhookOrderField {
  CreatedAt = 'createdAt'
}

export enum WebhookTemplateType {
  MsTeams = 'MSTeams'
}

export type GetAccountLogoQueryVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
}>;


export type GetAccountLogoQuery = { getAccount?: { id: ObjectID, customLogo?: string | null } | null };

export type GetAccountQueryVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
}>;


export type GetAccountQuery = { getAccount?: { id: ObjectID, name?: string | null, customProxy?: string | null, country?: string | null, customLogo?: string | null, aiFeaturesEnabled: boolean } | null };

export type UpdateAccountSettingsMutationVariables = Exact<{
  input: UpdateAccountInput;
}>;


export type UpdateAccountSettingsMutation = { updateAccount: { account: { id: ObjectID, customLogo?: string | null, customProxy?: string | null, country?: string | null, aiFeaturesEnabled: boolean } } };

export type GetAccountsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<AccountOrder> | AccountOrder>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AccountConnectionFilterInput>;
}>;


export type GetAccountsQuery = { getAccounts: { totalCount: number, edges: Array<{ cursor: string, node: { id: ObjectID, name?: string | null, active: boolean, accountSsoClients?: { nodes: Array<{ accountId: number, ssoClientId: string, ssoClientAccountId?: number | null }> } | null, subscription?: { plan: { code: string, settings: Array<{ code: string, limit?: number | null }> } } | null } }>, pageInfo: { endCursor?: string | null, hasNextPage: boolean } } };

export type CreateSsoAccountMutationVariables = Exact<{
  name: Scalars['String']['input'];
  ssoClientAccountId: Scalars['Int']['input'];
}>;


export type CreateSsoAccountMutation = { createSsoAccount: { account: { id: ObjectID, name?: string | null } } };

export type DeactivateSsoAccountMutationVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
}>;


export type DeactivateSsoAccountMutation = { updateSsoAccount: { account: { id: ObjectID, name?: string | null, active: boolean } } };

export type AccountSubscriptionPlanQueryVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
}>;


export type AccountSubscriptionPlanQuery = { getAccount?: { subscription?: { plan: { code: string, settings: Array<{ code: string, limit?: number | null }> } } | null } | null };

export type UpdateAccountPlanMutationVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  planCode: Scalars['String']['input'];
  planSettings: Array<AccountSubscriptionPlanSettingInput> | AccountSubscriptionPlanSettingInput;
}>;


export type UpdateAccountPlanMutation = { updateSsoAccountSubscription: { accountSubscription: { plan: { code: string, settings: Array<{ code: string, limit?: number | null }> } } } };

export type GetCreditAllocationsQueryVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  orderBy?: InputMaybe<Array<CreditAllocationOrder> | CreditAllocationOrder>;
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCreditAllocationsQuery = { getAccount?: { creditAllocations?: { totalCount: number, edges: Array<{ node: { id: ObjectID, startDate: string, endDate: string, creditsAllocated: number, creditsUsed: number } }>, pageInfo: { endCursor?: string | null, hasNextPage: boolean } } | null } | null };

export type CreateCreditAllocationForSsoAccountMutationVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  creditsAllocated: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  endDate: Scalars['DateTime']['input'];
}>;


export type CreateCreditAllocationForSsoAccountMutation = { createCreditAllocationForSsoAccount: { creditAllocation: { id: ObjectID } } };

export type UpdateSsoCreditAllocationMutationVariables = Exact<{
  creditAllocationId: Scalars['ObjectID']['input'];
  creditsAllocated: Scalars['Int']['input'];
  endDate: Scalars['DateTime']['input'];
}>;


export type UpdateSsoCreditAllocationMutation = { updateCreditAllocationForSsoAccount: { creditAllocation: { id: ObjectID } } };

export type CreateUserKeyMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateUserKeyMutation = { createUserKey: { id: ObjectID, secret?: string | null } };

export type DeleteUserKeyMutationVariables = Exact<{
  userKeyId: Scalars['ObjectID']['input'];
}>;


export type DeleteUserKeyMutation = { deleteUserKey: { userKey: { id: ObjectID } } };

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars['ObjectID']['input'];
  apiCallbackUrl?: InputMaybe<Scalars['String']['input']>;
  apiCallbackHeaders?: InputMaybe<Array<ApiCallbackHeaderInput> | ApiCallbackHeaderInput>;
}>;


export type UpdateAccountMutation = { updateAccount: { account: { id: ObjectID } } };

export type GetUserKeysQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetUserKeysQuery = { me: { userKeys: { edges: Array<{ node: { name?: string | null, id: ObjectID, createdAt: string } }>, pageInfo: { endCursor?: string | null, hasNextPage: boolean } } } };

export type GetCallbackQueryVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
}>;


export type GetCallbackQuery = { getAccount?: { apiCallbackUrl?: string | null, apiCallbackHeaders?: Array<{ key: string, value: string }> | null } | null };

export type CreateUserPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
}>;


export type CreateUserPasswordMutation = { updatePassword: { passwordUpdated: boolean, passwordValidationErrors?: Array<string> | null } };

export type ConfirmUserEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ConfirmUserEmailMutation = { confirmEmail: { session: { token: string } } };

export type AddAdobeConnectionMutationVariables = Exact<{
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  privateKey: Scalars['String']['input'];
  technicalAccountId: Scalars['String']['input'];
}>;


export type AddAdobeConnectionMutation = { createAdobeJwtConnection: { adobeJwtConnection: { id: ObjectID, isWorking: boolean, clientId: string, orgId: string, technicalAccountId: string, secretLastFragment: string } } };

export type UpdateAdobeConnectionMutationVariables = Exact<{
  adobeConnectionId: Scalars['ObjectID']['input'];
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  privateKey?: InputMaybe<Scalars['String']['input']>;
  technicalAccountId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateAdobeConnectionMutation = { updateAdobeJwtConnection: { adobeJwtConnection: { id: ObjectID, isWorking: boolean, clientId: string, orgId: string, technicalAccountId: string, secretLastFragment: string } } };

export type DeleteAdobeConnectionMutationVariables = Exact<{
  adobeConnectionId: Scalars['ObjectID']['input'];
}>;


export type DeleteAdobeConnectionMutation = { deleteAdobeJwtConnection: { adobeJwtConnection: { id: ObjectID } } };

export type RemoveAdobeConnectionMutationVariables = Exact<{
  adobeConnectionId: Scalars['ObjectID']['input'];
}>;


export type RemoveAdobeConnectionMutation = { deleteAdobeConnection: { adobeConnection: { id: ObjectID } } };

export type GetConnectedAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConnectedAppsQuery = { me: { googleConnections: { nodes: Array<{ id: ObjectID, name: string, isWorking?: boolean | null, scopes: Array<{ name: string }> }> }, logzioConnections: { nodes: Array<{ id: ObjectID, label?: string | null, token: string, isWorking: boolean }> }, splunkConnections: { nodes: Array<{ id: ObjectID, url: string, username: string, proxyCode?: SplunkProxyCode | null, isWorking: boolean }> }, adobeJwtConnections: { nodes: Array<{ id: ObjectID, clientId: string, technicalAccountId: string, orgId: string, secretLastFragment: string, isWorking: boolean }> }, adobeConnections: { nodes: Array<{ id: ObjectID, username: string, company?: string | null, secretLastFragment: string, isWorking: boolean }> } } };

export type AddGoogleConnectionMutationVariables = Exact<{
  redirectUrl: Scalars['String']['input'];
}>;


export type AddGoogleConnectionMutation = { createGoogleConnection: { authUrl: string } };

export type RemoveGoogleConnectionMutationVariables = Exact<{
  googleConnectionId: Scalars['ObjectID']['input'];
}>;


export type RemoveGoogleConnectionMutation = { deleteGoogleConnection: { googleConnection: { id: ObjectID } } };

export type AddLogzioConnectionMutationVariables = Exact<{
  label: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type AddLogzioConnectionMutation = { createLogzioConnection: { logzioConnection: { id: ObjectID, label?: string | null, token: string, isWorking: boolean } } };

export type UpdateLogzioConnectionMutationVariables = Exact<{
  logzioConnectionId: Scalars['ObjectID']['input'];
  label: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type UpdateLogzioConnectionMutation = { updateLogzioConnection: { logzioConnection: { id: ObjectID, label?: string | null, token: string, isWorking: boolean } } };

export type RemoveLogzioConnectionMutationVariables = Exact<{
  logzioConnectionId: Scalars['ObjectID']['input'];
}>;


export type RemoveLogzioConnectionMutation = { deleteLogzioConnection: { logzioConnection: { id: ObjectID } } };

export type AddSplunkConnectionMutationVariables = Exact<{
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  proxyCode?: InputMaybe<SplunkProxyCode>;
}>;


export type AddSplunkConnectionMutation = { createSplunkConnection: { splunkConnection: { id: ObjectID, url: string, username: string, proxyCode?: SplunkProxyCode | null, isWorking: boolean } } };

export type UpdateSplunkConnectionMutationVariables = Exact<{
  splunkConnectionId: Scalars['ObjectID']['input'];
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  proxyCode?: InputMaybe<SplunkProxyCode>;
}>;


export type UpdateSplunkConnectionMutation = { updateSplunkConnection: { splunkConnection: { id: ObjectID, url: string, username: string, proxyCode?: SplunkProxyCode | null, isWorking: boolean } } };

export type RemoveSplunkConnectionMutationVariables = Exact<{
  splunkConnectionId: Scalars['ObjectID']['input'];
}>;


export type RemoveSplunkConnectionMutation = { deleteSplunkConnection: { splunkConnection: { id: ObjectID } } };

export type GetCreditUsageDataQueryVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  filters: CreditReportConnectionFilterInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Array<CreditReportOrder> | CreditReportOrder>;
}>;


export type GetCreditUsageDataQuery = { getAccount?: { id: ObjectID, totalCreditReports?: { totalCount: number } | null, creditReports?: { totalCount: number, edges: Array<{ cursor: string, node: { finishedAt?: string | null, createdAt: string, updatedAt: string, crawlId: ObjectID, creditsUsed?: number | null, creditAllocationType: CreditAllocationType, crawl?: { id: ObjectID, createdByUser?: { id: number, email?: string | null } | null } | null, project?: { id: ObjectID, name: string, primaryDomain: string } | null } }> } | null } | null };

export type GenerateCreditReportExportsMutationVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  filter: CreditReportConnectionFilterInput;
}>;


export type GenerateCreditReportExportsMutation = { createCreditReportExport: { creditReportExport: { id: ObjectID } } };

export type GeneratedCreditReportExportsQueryVariables = Exact<{
  id: Scalars['ObjectID']['input'];
}>;


export type GeneratedCreditReportExportsQuery = { node?: { id: ObjectID, exportingAt?: string | null, fileURL?: string | null } | {} | null };

export type AccountSubscriptionQueryVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
}>;


export type AccountSubscriptionQuery = { getAccount?: { id: ObjectID, accountManagers: Array<string>, maxDashboardCollectionViews: number, maxCrawlRate: number, limitPagesMax: number, limitLevelsMax: number, maxProjectTests: number, contractTerminationDate?: string | null, contractStartDate?: string | null, accessibilityCredits: number, seoCredits: number, siteSpeedCredits: number, primaryAccountPackage?: { maxSeoCredits: any, maxAccessibilityCredits: any, maxSiteSpeedCredits: any } | null, accountSettings: Array<{ type: AccountSettingType, dataType: AccountSettingDataType, source?: AccountSettingSource | null, limit?: number | null, code: string, description?: string | null, visible?: boolean | null, unit?: string | null, name: string }>, subscription?: { automateAvailable: boolean, monitorAvailable: boolean, analyzeAvailable: boolean, impactAvailable: boolean, status: string, plan: { code: string, name: string, status?: string | null } } | null } | null };

export type AddAccountUserMutationVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  roleCode: RoleCode;
  userEmail: Scalars['String']['input'];
}>;


export type AddAccountUserMutation = { addAccountUser: { account: { id: ObjectID } } };

export type DeleteAccountUserMutationVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  userId: Scalars['ObjectID']['input'];
}>;


export type DeleteAccountUserMutation = { deleteAccountUser: { account: { id: ObjectID } } };

export type GetAccountUsersQueryVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAccountUsersQuery = { getAccount?: { id: ObjectID, accountUsers?: { totalCount: number, pageInfo: { hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ cursor: string, node: { userLastName?: string | null, userId: ObjectID, userFirstName?: string | null, userEmailValidatedAt?: string | null, userSsoClientId?: string | null, userEmail?: string | null, roleCode: RoleCode, role: { code: RoleCode, name: string }, account: { id: ObjectID, active: boolean } } }> } | null, accountSsoClients?: { totalCount: number } | null, accountSettings: Array<{ limit?: number | null, name: string }> } | null };

export type ResendInviteLinkMutationVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  userId: Scalars['ObjectID']['input'];
}>;


export type ResendInviteLinkMutation = { resendAccountUserInvite: { account: { id: ObjectID } } };

export type UpdateUserRoleMutationVariables = Exact<{
  accountId: Scalars['ObjectID']['input'];
  roleCode: RoleCode;
  userId: Scalars['ObjectID']['input'];
}>;


export type UpdateUserRoleMutation = { updateAccountUser: { account: { id: ObjectID, accountUsers?: { nodes: Array<{ roleCode: RoleCode, userId: ObjectID }> } | null } } };

export type UpdateUserDetailsMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
}>;


export type UpdateUserDetailsMutation = { updateCurrentUser: { user: { id: ObjectID } } };

export type GetUserSettingsDataQueryVariables = Exact<{
  userId: Scalars['ObjectID']['input'];
}>;


export type GetUserSettingsDataQuery = { node?: { id: ObjectID, email?: string | null, firstName?: string | null, lastName?: string | null, jobTitle?: string | null, username?: string | null } | {} | null };

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type RequestPasswordResetMutation = { requestPasswordReset: boolean };

export type UpdateUserSettingsMutationVariables = Exact<{
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
}>;


export type UpdateUserSettingsMutation = { updateCurrentUser: { user: { id: ObjectID, username?: string | null } } };


export const GetAccountLogoDocument = gql`
    query GetAccountLogo($accountId: ObjectID!) {
  getAccount(id: $accountId) {
    id
    customLogo
  }
}
    `;

/**
 * __useGetAccountLogoQuery__
 *
 * To run a query within a React component, call `useGetAccountLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLogoQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountLogoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAccountLogoQuery, GetAccountLogoQueryVariables> & ({ variables: GetAccountLogoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAccountLogoQuery, GetAccountLogoQueryVariables>(GetAccountLogoDocument, options);
      }
export function useGetAccountLogoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountLogoQuery, GetAccountLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAccountLogoQuery, GetAccountLogoQueryVariables>(GetAccountLogoDocument, options);
        }
export function useGetAccountLogoSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetAccountLogoQuery, GetAccountLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAccountLogoQuery, GetAccountLogoQueryVariables>(GetAccountLogoDocument, options);
        }
export type GetAccountLogoQueryHookResult = ReturnType<typeof useGetAccountLogoQuery>;
export type GetAccountLogoLazyQueryHookResult = ReturnType<typeof useGetAccountLogoLazyQuery>;
export type GetAccountLogoSuspenseQueryHookResult = ReturnType<typeof useGetAccountLogoSuspenseQuery>;
export type GetAccountLogoQueryResult = ApolloReactCommon.QueryResult<GetAccountLogoQuery, GetAccountLogoQueryVariables>;
export const GetAccountDocument = gql`
    query GetAccount($accountId: ObjectID!) {
  getAccount(id: $accountId) {
    id
    name
    customProxy
    country
    customLogo
    aiFeaturesEnabled
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables> & ({ variables: GetAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export function useGetAccountSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountSuspenseQueryHookResult = ReturnType<typeof useGetAccountSuspenseQuery>;
export type GetAccountQueryResult = ApolloReactCommon.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const UpdateAccountSettingsDocument = gql`
    mutation UpdateAccountSettings($input: UpdateAccountInput!) {
  updateAccount(input: $input) {
    account {
      id
      customLogo
      customProxy
      country
      aiFeaturesEnabled
    }
  }
}
    `;
export type UpdateAccountSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>;

/**
 * __useUpdateAccountSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountSettingsMutation, { data, loading, error }] = useUpdateAccountSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>(UpdateAccountSettingsDocument, options);
      }
export type UpdateAccountSettingsMutationHookResult = ReturnType<typeof useUpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateAccountSettingsMutation>;
export type UpdateAccountSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountSettingsMutation, UpdateAccountSettingsMutationVariables>;
export const GetAccountsDocument = gql`
    query GetAccounts($orderBy: [AccountOrder!], $first: Int, $after: String, $last: Int, $before: String, $filter: AccountConnectionFilterInput) {
  getAccounts(
    filter: $filter
    first: $first
    after: $after
    orderBy: $orderBy
    last: $last
    before: $before
  ) {
    totalCount
    edges {
      cursor
      node {
        id
        name
        active
        accountSsoClients {
          nodes {
            accountId
            ssoClientId
            ssoClientAccountId
          }
        }
        subscription {
          plan {
            code
            settings {
              code
              limit
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export function useGetAccountsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsSuspenseQueryHookResult = ReturnType<typeof useGetAccountsSuspenseQuery>;
export type GetAccountsQueryResult = ApolloReactCommon.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const CreateSsoAccountDocument = gql`
    mutation CreateSSOAccount($name: String!, $ssoClientAccountId: Int!) {
  createSsoAccount(input: {name: $name, ssoClientAccountId: $ssoClientAccountId}) {
    account {
      id
      name
    }
  }
}
    `;
export type CreateSsoAccountMutationFn = ApolloReactCommon.MutationFunction<CreateSsoAccountMutation, CreateSsoAccountMutationVariables>;

/**
 * __useCreateSsoAccountMutation__
 *
 * To run a mutation, you first call `useCreateSsoAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSsoAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSsoAccountMutation, { data, loading, error }] = useCreateSsoAccountMutation({
 *   variables: {
 *      name: // value for 'name'
 *      ssoClientAccountId: // value for 'ssoClientAccountId'
 *   },
 * });
 */
export function useCreateSsoAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSsoAccountMutation, CreateSsoAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSsoAccountMutation, CreateSsoAccountMutationVariables>(CreateSsoAccountDocument, options);
      }
export type CreateSsoAccountMutationHookResult = ReturnType<typeof useCreateSsoAccountMutation>;
export type CreateSsoAccountMutationResult = ApolloReactCommon.MutationResult<CreateSsoAccountMutation>;
export type CreateSsoAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSsoAccountMutation, CreateSsoAccountMutationVariables>;
export const DeactivateSsoAccountDocument = gql`
    mutation DeactivateSSOAccount($accountId: ObjectID!) {
  updateSsoAccount(input: {accountId: $accountId, active: false}) {
    account {
      id
      name
      active
    }
  }
}
    `;
export type DeactivateSsoAccountMutationFn = ApolloReactCommon.MutationFunction<DeactivateSsoAccountMutation, DeactivateSsoAccountMutationVariables>;

/**
 * __useDeactivateSsoAccountMutation__
 *
 * To run a mutation, you first call `useDeactivateSsoAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateSsoAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateSsoAccountMutation, { data, loading, error }] = useDeactivateSsoAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeactivateSsoAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeactivateSsoAccountMutation, DeactivateSsoAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeactivateSsoAccountMutation, DeactivateSsoAccountMutationVariables>(DeactivateSsoAccountDocument, options);
      }
export type DeactivateSsoAccountMutationHookResult = ReturnType<typeof useDeactivateSsoAccountMutation>;
export type DeactivateSsoAccountMutationResult = ApolloReactCommon.MutationResult<DeactivateSsoAccountMutation>;
export type DeactivateSsoAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeactivateSsoAccountMutation, DeactivateSsoAccountMutationVariables>;
export const AccountSubscriptionPlanDocument = gql`
    query AccountSubscriptionPlan($accountId: ObjectID!) {
  getAccount(id: $accountId) {
    subscription {
      plan {
        code
        settings {
          code
          limit
        }
      }
    }
  }
}
    `;

/**
 * __useAccountSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useAccountSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSubscriptionPlanQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountSubscriptionPlanQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AccountSubscriptionPlanQuery, AccountSubscriptionPlanQueryVariables> & ({ variables: AccountSubscriptionPlanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AccountSubscriptionPlanQuery, AccountSubscriptionPlanQueryVariables>(AccountSubscriptionPlanDocument, options);
      }
export function useAccountSubscriptionPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountSubscriptionPlanQuery, AccountSubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AccountSubscriptionPlanQuery, AccountSubscriptionPlanQueryVariables>(AccountSubscriptionPlanDocument, options);
        }
export function useAccountSubscriptionPlanSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AccountSubscriptionPlanQuery, AccountSubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<AccountSubscriptionPlanQuery, AccountSubscriptionPlanQueryVariables>(AccountSubscriptionPlanDocument, options);
        }
export type AccountSubscriptionPlanQueryHookResult = ReturnType<typeof useAccountSubscriptionPlanQuery>;
export type AccountSubscriptionPlanLazyQueryHookResult = ReturnType<typeof useAccountSubscriptionPlanLazyQuery>;
export type AccountSubscriptionPlanSuspenseQueryHookResult = ReturnType<typeof useAccountSubscriptionPlanSuspenseQuery>;
export type AccountSubscriptionPlanQueryResult = ApolloReactCommon.QueryResult<AccountSubscriptionPlanQuery, AccountSubscriptionPlanQueryVariables>;
export const UpdateAccountPlanDocument = gql`
    mutation UpdateAccountPlan($accountId: ObjectID!, $planCode: String!, $planSettings: [AccountSubscriptionPlanSettingInput!]!) {
  updateSsoAccountSubscription(
    input: {accountId: $accountId, planCode: $planCode, planSettings: $planSettings}
  ) {
    accountSubscription {
      plan {
        code
        settings {
          code
          limit
        }
      }
    }
  }
}
    `;
export type UpdateAccountPlanMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountPlanMutation, UpdateAccountPlanMutationVariables>;

/**
 * __useUpdateAccountPlanMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPlanMutation, { data, loading, error }] = useUpdateAccountPlanMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      planCode: // value for 'planCode'
 *      planSettings: // value for 'planSettings'
 *   },
 * });
 */
export function useUpdateAccountPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountPlanMutation, UpdateAccountPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAccountPlanMutation, UpdateAccountPlanMutationVariables>(UpdateAccountPlanDocument, options);
      }
export type UpdateAccountPlanMutationHookResult = ReturnType<typeof useUpdateAccountPlanMutation>;
export type UpdateAccountPlanMutationResult = ApolloReactCommon.MutationResult<UpdateAccountPlanMutation>;
export type UpdateAccountPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountPlanMutation, UpdateAccountPlanMutationVariables>;
export const GetCreditAllocationsDocument = gql`
    query GetCreditAllocations($accountId: ObjectID!, $orderBy: [CreditAllocationOrder!], $first: Int, $after: String, $last: Int, $before: String) {
  getAccount(id: $accountId) {
    creditAllocations(
      first: $first
      after: $after
      orderBy: $orderBy
      last: $last
      before: $before
    ) {
      totalCount
      edges {
        node {
          id
          startDate
          endDate
          creditsAllocated
          creditsUsed
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
    `;

/**
 * __useGetCreditAllocationsQuery__
 *
 * To run a query within a React component, call `useGetCreditAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditAllocationsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetCreditAllocationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCreditAllocationsQuery, GetCreditAllocationsQueryVariables> & ({ variables: GetCreditAllocationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCreditAllocationsQuery, GetCreditAllocationsQueryVariables>(GetCreditAllocationsDocument, options);
      }
export function useGetCreditAllocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditAllocationsQuery, GetCreditAllocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCreditAllocationsQuery, GetCreditAllocationsQueryVariables>(GetCreditAllocationsDocument, options);
        }
export function useGetCreditAllocationsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetCreditAllocationsQuery, GetCreditAllocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCreditAllocationsQuery, GetCreditAllocationsQueryVariables>(GetCreditAllocationsDocument, options);
        }
export type GetCreditAllocationsQueryHookResult = ReturnType<typeof useGetCreditAllocationsQuery>;
export type GetCreditAllocationsLazyQueryHookResult = ReturnType<typeof useGetCreditAllocationsLazyQuery>;
export type GetCreditAllocationsSuspenseQueryHookResult = ReturnType<typeof useGetCreditAllocationsSuspenseQuery>;
export type GetCreditAllocationsQueryResult = ApolloReactCommon.QueryResult<GetCreditAllocationsQuery, GetCreditAllocationsQueryVariables>;
export const CreateCreditAllocationForSsoAccountDocument = gql`
    mutation createCreditAllocationForSsoAccount($accountId: ObjectID!, $creditsAllocated: Int!, $startDate: DateTime!, $endDate: DateTime!) {
  createCreditAllocationForSsoAccount(
    input: {accountId: $accountId, creditsAllocated: $creditsAllocated, endDate: $endDate, startDate: $startDate}
  ) {
    creditAllocation {
      id
    }
  }
}
    `;
export type CreateCreditAllocationForSsoAccountMutationFn = ApolloReactCommon.MutationFunction<CreateCreditAllocationForSsoAccountMutation, CreateCreditAllocationForSsoAccountMutationVariables>;

/**
 * __useCreateCreditAllocationForSsoAccountMutation__
 *
 * To run a mutation, you first call `useCreateCreditAllocationForSsoAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditAllocationForSsoAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditAllocationForSsoAccountMutation, { data, loading, error }] = useCreateCreditAllocationForSsoAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      creditsAllocated: // value for 'creditsAllocated'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCreateCreditAllocationForSsoAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditAllocationForSsoAccountMutation, CreateCreditAllocationForSsoAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateCreditAllocationForSsoAccountMutation, CreateCreditAllocationForSsoAccountMutationVariables>(CreateCreditAllocationForSsoAccountDocument, options);
      }
export type CreateCreditAllocationForSsoAccountMutationHookResult = ReturnType<typeof useCreateCreditAllocationForSsoAccountMutation>;
export type CreateCreditAllocationForSsoAccountMutationResult = ApolloReactCommon.MutationResult<CreateCreditAllocationForSsoAccountMutation>;
export type CreateCreditAllocationForSsoAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditAllocationForSsoAccountMutation, CreateCreditAllocationForSsoAccountMutationVariables>;
export const UpdateSsoCreditAllocationDocument = gql`
    mutation UpdateSSOCreditAllocation($creditAllocationId: ObjectID!, $creditsAllocated: Int!, $endDate: DateTime!) {
  updateCreditAllocationForSsoAccount(
    input: {creditAllocationId: $creditAllocationId, creditsAllocated: $creditsAllocated, endDate: $endDate}
  ) {
    creditAllocation {
      id
    }
  }
}
    `;
export type UpdateSsoCreditAllocationMutationFn = ApolloReactCommon.MutationFunction<UpdateSsoCreditAllocationMutation, UpdateSsoCreditAllocationMutationVariables>;

/**
 * __useUpdateSsoCreditAllocationMutation__
 *
 * To run a mutation, you first call `useUpdateSsoCreditAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSsoCreditAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSsoCreditAllocationMutation, { data, loading, error }] = useUpdateSsoCreditAllocationMutation({
 *   variables: {
 *      creditAllocationId: // value for 'creditAllocationId'
 *      creditsAllocated: // value for 'creditsAllocated'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useUpdateSsoCreditAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSsoCreditAllocationMutation, UpdateSsoCreditAllocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSsoCreditAllocationMutation, UpdateSsoCreditAllocationMutationVariables>(UpdateSsoCreditAllocationDocument, options);
      }
export type UpdateSsoCreditAllocationMutationHookResult = ReturnType<typeof useUpdateSsoCreditAllocationMutation>;
export type UpdateSsoCreditAllocationMutationResult = ApolloReactCommon.MutationResult<UpdateSsoCreditAllocationMutation>;
export type UpdateSsoCreditAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSsoCreditAllocationMutation, UpdateSsoCreditAllocationMutationVariables>;
export const CreateUserKeyDocument = gql`
    mutation CreateUserKey($name: String) {
  createUserKey(input: {name: $name}) {
    id
    secret
  }
}
    `;
export type CreateUserKeyMutationFn = ApolloReactCommon.MutationFunction<CreateUserKeyMutation, CreateUserKeyMutationVariables>;

/**
 * __useCreateUserKeyMutation__
 *
 * To run a mutation, you first call `useCreateUserKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserKeyMutation, { data, loading, error }] = useCreateUserKeyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateUserKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserKeyMutation, CreateUserKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateUserKeyMutation, CreateUserKeyMutationVariables>(CreateUserKeyDocument, options);
      }
export type CreateUserKeyMutationHookResult = ReturnType<typeof useCreateUserKeyMutation>;
export type CreateUserKeyMutationResult = ApolloReactCommon.MutationResult<CreateUserKeyMutation>;
export type CreateUserKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserKeyMutation, CreateUserKeyMutationVariables>;
export const DeleteUserKeyDocument = gql`
    mutation DeleteUserKey($userKeyId: ObjectID!) {
  deleteUserKey(input: {userKeyId: $userKeyId}) {
    userKey {
      id
    }
  }
}
    `;
export type DeleteUserKeyMutationFn = ApolloReactCommon.MutationFunction<DeleteUserKeyMutation, DeleteUserKeyMutationVariables>;

/**
 * __useDeleteUserKeyMutation__
 *
 * To run a mutation, you first call `useDeleteUserKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserKeyMutation, { data, loading, error }] = useDeleteUserKeyMutation({
 *   variables: {
 *      userKeyId: // value for 'userKeyId'
 *   },
 * });
 */
export function useDeleteUserKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserKeyMutation, DeleteUserKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteUserKeyMutation, DeleteUserKeyMutationVariables>(DeleteUserKeyDocument, options);
      }
export type DeleteUserKeyMutationHookResult = ReturnType<typeof useDeleteUserKeyMutation>;
export type DeleteUserKeyMutationResult = ApolloReactCommon.MutationResult<DeleteUserKeyMutation>;
export type DeleteUserKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserKeyMutation, DeleteUserKeyMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation UpdateAccount($id: ObjectID!, $apiCallbackUrl: String, $apiCallbackHeaders: [APICallbackHeaderInput!]) {
  updateAccount(
    input: {accountId: $id, apiCallbackUrl: $apiCallbackUrl, apiCallbackHeaders: $apiCallbackHeaders}
  ) {
    account {
      id
    }
  }
}
    `;
export type UpdateAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      apiCallbackUrl: // value for 'apiCallbackUrl'
 *      apiCallbackHeaders: // value for 'apiCallbackHeaders'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = ApolloReactCommon.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const GetUserKeysDocument = gql`
    query GetUserKeys($cursor: String) {
  me {
    userKeys(
      first: 100
      after: $cursor
      orderBy: {field: createdAt, direction: DESC}
    ) {
      edges {
        node {
          name
          id
          createdAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}
    `;

/**
 * __useGetUserKeysQuery__
 *
 * To run a query within a React component, call `useGetUserKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserKeysQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserKeysQuery, GetUserKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserKeysQuery, GetUserKeysQueryVariables>(GetUserKeysDocument, options);
      }
export function useGetUserKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserKeysQuery, GetUserKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserKeysQuery, GetUserKeysQueryVariables>(GetUserKeysDocument, options);
        }
export function useGetUserKeysSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetUserKeysQuery, GetUserKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetUserKeysQuery, GetUserKeysQueryVariables>(GetUserKeysDocument, options);
        }
export type GetUserKeysQueryHookResult = ReturnType<typeof useGetUserKeysQuery>;
export type GetUserKeysLazyQueryHookResult = ReturnType<typeof useGetUserKeysLazyQuery>;
export type GetUserKeysSuspenseQueryHookResult = ReturnType<typeof useGetUserKeysSuspenseQuery>;
export type GetUserKeysQueryResult = ApolloReactCommon.QueryResult<GetUserKeysQuery, GetUserKeysQueryVariables>;
export const GetCallbackDocument = gql`
    query GetCallback($accountId: ObjectID!) {
  getAccount(id: $accountId) {
    apiCallbackUrl
    apiCallbackHeaders {
      key
      value
    }
  }
}
    `;

/**
 * __useGetCallbackQuery__
 *
 * To run a query within a React component, call `useGetCallbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallbackQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetCallbackQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCallbackQuery, GetCallbackQueryVariables> & ({ variables: GetCallbackQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCallbackQuery, GetCallbackQueryVariables>(GetCallbackDocument, options);
      }
export function useGetCallbackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCallbackQuery, GetCallbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCallbackQuery, GetCallbackQueryVariables>(GetCallbackDocument, options);
        }
export function useGetCallbackSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetCallbackQuery, GetCallbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCallbackQuery, GetCallbackQueryVariables>(GetCallbackDocument, options);
        }
export type GetCallbackQueryHookResult = ReturnType<typeof useGetCallbackQuery>;
export type GetCallbackLazyQueryHookResult = ReturnType<typeof useGetCallbackLazyQuery>;
export type GetCallbackSuspenseQueryHookResult = ReturnType<typeof useGetCallbackSuspenseQuery>;
export type GetCallbackQueryResult = ApolloReactCommon.QueryResult<GetCallbackQuery, GetCallbackQueryVariables>;
export const CreateUserPasswordDocument = gql`
    mutation CreateUserPassword($password: String!) {
  updatePassword(input: {newPassword: $password}) {
    passwordUpdated
    passwordValidationErrors
  }
}
    `;
export type CreateUserPasswordMutationFn = ApolloReactCommon.MutationFunction<CreateUserPasswordMutation, CreateUserPasswordMutationVariables>;

/**
 * __useCreateUserPasswordMutation__
 *
 * To run a mutation, you first call `useCreateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPasswordMutation, { data, loading, error }] = useCreateUserPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserPasswordMutation, CreateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateUserPasswordMutation, CreateUserPasswordMutationVariables>(CreateUserPasswordDocument, options);
      }
export type CreateUserPasswordMutationHookResult = ReturnType<typeof useCreateUserPasswordMutation>;
export type CreateUserPasswordMutationResult = ApolloReactCommon.MutationResult<CreateUserPasswordMutation>;
export type CreateUserPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserPasswordMutation, CreateUserPasswordMutationVariables>;
export const ConfirmUserEmailDocument = gql`
    mutation ConfirmUserEmail($token: String!) {
  confirmEmail(input: {token: $token}) {
    session {
      token
    }
  }
}
    `;
export type ConfirmUserEmailMutationFn = ApolloReactCommon.MutationFunction<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>;

/**
 * __useConfirmUserEmailMutation__
 *
 * To run a mutation, you first call `useConfirmUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserEmailMutation, { data, loading, error }] = useConfirmUserEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmUserEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>(ConfirmUserEmailDocument, options);
      }
export type ConfirmUserEmailMutationHookResult = ReturnType<typeof useConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationResult = ApolloReactCommon.MutationResult<ConfirmUserEmailMutation>;
export type ConfirmUserEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmUserEmailMutation, ConfirmUserEmailMutationVariables>;
export const AddAdobeConnectionDocument = gql`
    mutation AddAdobeConnection($clientId: String!, $clientSecret: String!, $orgId: String!, $privateKey: String!, $technicalAccountId: String!) {
  createAdobeJwtConnection(
    input: {clientId: $clientId, orgId: $orgId, technicalAccountId: $technicalAccountId, clientSecret: $clientSecret, privateKey: $privateKey}
  ) {
    adobeJwtConnection {
      id
      isWorking
      clientId
      orgId
      technicalAccountId
      secretLastFragment
    }
  }
}
    `;
export type AddAdobeConnectionMutationFn = ApolloReactCommon.MutationFunction<AddAdobeConnectionMutation, AddAdobeConnectionMutationVariables>;

/**
 * __useAddAdobeConnectionMutation__
 *
 * To run a mutation, you first call `useAddAdobeConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdobeConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdobeConnectionMutation, { data, loading, error }] = useAddAdobeConnectionMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      clientSecret: // value for 'clientSecret'
 *      orgId: // value for 'orgId'
 *      privateKey: // value for 'privateKey'
 *      technicalAccountId: // value for 'technicalAccountId'
 *   },
 * });
 */
export function useAddAdobeConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAdobeConnectionMutation, AddAdobeConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddAdobeConnectionMutation, AddAdobeConnectionMutationVariables>(AddAdobeConnectionDocument, options);
      }
export type AddAdobeConnectionMutationHookResult = ReturnType<typeof useAddAdobeConnectionMutation>;
export type AddAdobeConnectionMutationResult = ApolloReactCommon.MutationResult<AddAdobeConnectionMutation>;
export type AddAdobeConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAdobeConnectionMutation, AddAdobeConnectionMutationVariables>;
export const UpdateAdobeConnectionDocument = gql`
    mutation UpdateAdobeConnection($adobeConnectionId: ObjectID!, $clientId: String, $clientSecret: String, $orgId: String, $privateKey: String, $technicalAccountId: String) {
  updateAdobeJwtConnection(
    input: {adobeJwtConnectionId: $adobeConnectionId, clientId: $clientId, clientSecret: $clientSecret, orgId: $orgId, privateKey: $privateKey, technicalAccountId: $technicalAccountId}
  ) {
    adobeJwtConnection {
      id
      isWorking
      clientId
      orgId
      technicalAccountId
      secretLastFragment
    }
  }
}
    `;
export type UpdateAdobeConnectionMutationFn = ApolloReactCommon.MutationFunction<UpdateAdobeConnectionMutation, UpdateAdobeConnectionMutationVariables>;

/**
 * __useUpdateAdobeConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateAdobeConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdobeConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdobeConnectionMutation, { data, loading, error }] = useUpdateAdobeConnectionMutation({
 *   variables: {
 *      adobeConnectionId: // value for 'adobeConnectionId'
 *      clientId: // value for 'clientId'
 *      clientSecret: // value for 'clientSecret'
 *      orgId: // value for 'orgId'
 *      privateKey: // value for 'privateKey'
 *      technicalAccountId: // value for 'technicalAccountId'
 *   },
 * });
 */
export function useUpdateAdobeConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAdobeConnectionMutation, UpdateAdobeConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateAdobeConnectionMutation, UpdateAdobeConnectionMutationVariables>(UpdateAdobeConnectionDocument, options);
      }
export type UpdateAdobeConnectionMutationHookResult = ReturnType<typeof useUpdateAdobeConnectionMutation>;
export type UpdateAdobeConnectionMutationResult = ApolloReactCommon.MutationResult<UpdateAdobeConnectionMutation>;
export type UpdateAdobeConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAdobeConnectionMutation, UpdateAdobeConnectionMutationVariables>;
export const DeleteAdobeConnectionDocument = gql`
    mutation DeleteAdobeConnection($adobeConnectionId: ObjectID!) {
  deleteAdobeJwtConnection(input: {adobeJwtConnectionId: $adobeConnectionId}) {
    adobeJwtConnection {
      id
    }
  }
}
    `;
export type DeleteAdobeConnectionMutationFn = ApolloReactCommon.MutationFunction<DeleteAdobeConnectionMutation, DeleteAdobeConnectionMutationVariables>;

/**
 * __useDeleteAdobeConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteAdobeConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdobeConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdobeConnectionMutation, { data, loading, error }] = useDeleteAdobeConnectionMutation({
 *   variables: {
 *      adobeConnectionId: // value for 'adobeConnectionId'
 *   },
 * });
 */
export function useDeleteAdobeConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAdobeConnectionMutation, DeleteAdobeConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteAdobeConnectionMutation, DeleteAdobeConnectionMutationVariables>(DeleteAdobeConnectionDocument, options);
      }
export type DeleteAdobeConnectionMutationHookResult = ReturnType<typeof useDeleteAdobeConnectionMutation>;
export type DeleteAdobeConnectionMutationResult = ApolloReactCommon.MutationResult<DeleteAdobeConnectionMutation>;
export type DeleteAdobeConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAdobeConnectionMutation, DeleteAdobeConnectionMutationVariables>;
export const RemoveAdobeConnectionDocument = gql`
    mutation RemoveAdobeConnection($adobeConnectionId: ObjectID!) {
  deleteAdobeConnection(input: {adobeConnectionId: $adobeConnectionId}) {
    adobeConnection {
      id
    }
  }
}
    `;
export type RemoveAdobeConnectionMutationFn = ApolloReactCommon.MutationFunction<RemoveAdobeConnectionMutation, RemoveAdobeConnectionMutationVariables>;

/**
 * __useRemoveAdobeConnectionMutation__
 *
 * To run a mutation, you first call `useRemoveAdobeConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdobeConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdobeConnectionMutation, { data, loading, error }] = useRemoveAdobeConnectionMutation({
 *   variables: {
 *      adobeConnectionId: // value for 'adobeConnectionId'
 *   },
 * });
 */
export function useRemoveAdobeConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAdobeConnectionMutation, RemoveAdobeConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveAdobeConnectionMutation, RemoveAdobeConnectionMutationVariables>(RemoveAdobeConnectionDocument, options);
      }
export type RemoveAdobeConnectionMutationHookResult = ReturnType<typeof useRemoveAdobeConnectionMutation>;
export type RemoveAdobeConnectionMutationResult = ApolloReactCommon.MutationResult<RemoveAdobeConnectionMutation>;
export type RemoveAdobeConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAdobeConnectionMutation, RemoveAdobeConnectionMutationVariables>;
export const GetConnectedAppsDocument = gql`
    query GetConnectedApps {
  me {
    googleConnections(first: 100) {
      nodes {
        id
        name
        isWorking
        scopes {
          name
        }
      }
    }
    logzioConnections(first: 100) {
      nodes {
        id
        label
        token
        isWorking
      }
    }
    splunkConnections(first: 100) {
      nodes {
        id
        url
        username
        proxyCode
        isWorking
      }
    }
    adobeJwtConnections(first: 100) {
      nodes {
        id
        clientId
        technicalAccountId
        orgId
        secretLastFragment
        isWorking
      }
    }
    adobeConnections(first: 100) {
      nodes {
        id
        username
        company
        secretLastFragment
        isWorking
      }
    }
  }
}
    `;

/**
 * __useGetConnectedAppsQuery__
 *
 * To run a query within a React component, call `useGetConnectedAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConnectedAppsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConnectedAppsQuery, GetConnectedAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetConnectedAppsQuery, GetConnectedAppsQueryVariables>(GetConnectedAppsDocument, options);
      }
export function useGetConnectedAppsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConnectedAppsQuery, GetConnectedAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetConnectedAppsQuery, GetConnectedAppsQueryVariables>(GetConnectedAppsDocument, options);
        }
export function useGetConnectedAppsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetConnectedAppsQuery, GetConnectedAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetConnectedAppsQuery, GetConnectedAppsQueryVariables>(GetConnectedAppsDocument, options);
        }
export type GetConnectedAppsQueryHookResult = ReturnType<typeof useGetConnectedAppsQuery>;
export type GetConnectedAppsLazyQueryHookResult = ReturnType<typeof useGetConnectedAppsLazyQuery>;
export type GetConnectedAppsSuspenseQueryHookResult = ReturnType<typeof useGetConnectedAppsSuspenseQuery>;
export type GetConnectedAppsQueryResult = ApolloReactCommon.QueryResult<GetConnectedAppsQuery, GetConnectedAppsQueryVariables>;
export const AddGoogleConnectionDocument = gql`
    mutation AddGoogleConnection($redirectUrl: String!) {
  createGoogleConnection(input: {redirectUrl: $redirectUrl}) {
    authUrl
  }
}
    `;
export type AddGoogleConnectionMutationFn = ApolloReactCommon.MutationFunction<AddGoogleConnectionMutation, AddGoogleConnectionMutationVariables>;

/**
 * __useAddGoogleConnectionMutation__
 *
 * To run a mutation, you first call `useAddGoogleConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGoogleConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGoogleConnectionMutation, { data, loading, error }] = useAddGoogleConnectionMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *   },
 * });
 */
export function useAddGoogleConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddGoogleConnectionMutation, AddGoogleConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddGoogleConnectionMutation, AddGoogleConnectionMutationVariables>(AddGoogleConnectionDocument, options);
      }
export type AddGoogleConnectionMutationHookResult = ReturnType<typeof useAddGoogleConnectionMutation>;
export type AddGoogleConnectionMutationResult = ApolloReactCommon.MutationResult<AddGoogleConnectionMutation>;
export type AddGoogleConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddGoogleConnectionMutation, AddGoogleConnectionMutationVariables>;
export const RemoveGoogleConnectionDocument = gql`
    mutation RemoveGoogleConnection($googleConnectionId: ObjectID!) {
  deleteGoogleConnection(input: {googleConnectionId: $googleConnectionId}) {
    googleConnection {
      id
    }
  }
}
    `;
export type RemoveGoogleConnectionMutationFn = ApolloReactCommon.MutationFunction<RemoveGoogleConnectionMutation, RemoveGoogleConnectionMutationVariables>;

/**
 * __useRemoveGoogleConnectionMutation__
 *
 * To run a mutation, you first call `useRemoveGoogleConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGoogleConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGoogleConnectionMutation, { data, loading, error }] = useRemoveGoogleConnectionMutation({
 *   variables: {
 *      googleConnectionId: // value for 'googleConnectionId'
 *   },
 * });
 */
export function useRemoveGoogleConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveGoogleConnectionMutation, RemoveGoogleConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveGoogleConnectionMutation, RemoveGoogleConnectionMutationVariables>(RemoveGoogleConnectionDocument, options);
      }
export type RemoveGoogleConnectionMutationHookResult = ReturnType<typeof useRemoveGoogleConnectionMutation>;
export type RemoveGoogleConnectionMutationResult = ApolloReactCommon.MutationResult<RemoveGoogleConnectionMutation>;
export type RemoveGoogleConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveGoogleConnectionMutation, RemoveGoogleConnectionMutationVariables>;
export const AddLogzioConnectionDocument = gql`
    mutation AddLogzioConnection($label: String!, $token: String!) {
  createLogzioConnection(input: {label: $label, token: $token}) {
    logzioConnection {
      id
      label
      token
      isWorking
    }
  }
}
    `;
export type AddLogzioConnectionMutationFn = ApolloReactCommon.MutationFunction<AddLogzioConnectionMutation, AddLogzioConnectionMutationVariables>;

/**
 * __useAddLogzioConnectionMutation__
 *
 * To run a mutation, you first call `useAddLogzioConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLogzioConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLogzioConnectionMutation, { data, loading, error }] = useAddLogzioConnectionMutation({
 *   variables: {
 *      label: // value for 'label'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddLogzioConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddLogzioConnectionMutation, AddLogzioConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddLogzioConnectionMutation, AddLogzioConnectionMutationVariables>(AddLogzioConnectionDocument, options);
      }
export type AddLogzioConnectionMutationHookResult = ReturnType<typeof useAddLogzioConnectionMutation>;
export type AddLogzioConnectionMutationResult = ApolloReactCommon.MutationResult<AddLogzioConnectionMutation>;
export type AddLogzioConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddLogzioConnectionMutation, AddLogzioConnectionMutationVariables>;
export const UpdateLogzioConnectionDocument = gql`
    mutation UpdateLogzioConnection($logzioConnectionId: ObjectID!, $label: String!, $token: String!) {
  updateLogzioConnection(
    input: {logzioConnectionId: $logzioConnectionId, label: $label, token: $token}
  ) {
    logzioConnection {
      id
      label
      token
      isWorking
    }
  }
}
    `;
export type UpdateLogzioConnectionMutationFn = ApolloReactCommon.MutationFunction<UpdateLogzioConnectionMutation, UpdateLogzioConnectionMutationVariables>;

/**
 * __useUpdateLogzioConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateLogzioConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLogzioConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLogzioConnectionMutation, { data, loading, error }] = useUpdateLogzioConnectionMutation({
 *   variables: {
 *      logzioConnectionId: // value for 'logzioConnectionId'
 *      label: // value for 'label'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdateLogzioConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLogzioConnectionMutation, UpdateLogzioConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateLogzioConnectionMutation, UpdateLogzioConnectionMutationVariables>(UpdateLogzioConnectionDocument, options);
      }
export type UpdateLogzioConnectionMutationHookResult = ReturnType<typeof useUpdateLogzioConnectionMutation>;
export type UpdateLogzioConnectionMutationResult = ApolloReactCommon.MutationResult<UpdateLogzioConnectionMutation>;
export type UpdateLogzioConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLogzioConnectionMutation, UpdateLogzioConnectionMutationVariables>;
export const RemoveLogzioConnectionDocument = gql`
    mutation RemoveLogzioConnection($logzioConnectionId: ObjectID!) {
  deleteLogzioConnection(input: {logzioConnectionId: $logzioConnectionId}) {
    logzioConnection {
      id
    }
  }
}
    `;
export type RemoveLogzioConnectionMutationFn = ApolloReactCommon.MutationFunction<RemoveLogzioConnectionMutation, RemoveLogzioConnectionMutationVariables>;

/**
 * __useRemoveLogzioConnectionMutation__
 *
 * To run a mutation, you first call `useRemoveLogzioConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLogzioConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLogzioConnectionMutation, { data, loading, error }] = useRemoveLogzioConnectionMutation({
 *   variables: {
 *      logzioConnectionId: // value for 'logzioConnectionId'
 *   },
 * });
 */
export function useRemoveLogzioConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveLogzioConnectionMutation, RemoveLogzioConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveLogzioConnectionMutation, RemoveLogzioConnectionMutationVariables>(RemoveLogzioConnectionDocument, options);
      }
export type RemoveLogzioConnectionMutationHookResult = ReturnType<typeof useRemoveLogzioConnectionMutation>;
export type RemoveLogzioConnectionMutationResult = ApolloReactCommon.MutationResult<RemoveLogzioConnectionMutation>;
export type RemoveLogzioConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveLogzioConnectionMutation, RemoveLogzioConnectionMutationVariables>;
export const AddSplunkConnectionDocument = gql`
    mutation AddSplunkConnection($url: String!, $username: String!, $password: String!, $proxyCode: SplunkProxyCode) {
  createSplunkConnection(
    input: {url: $url, username: $username, password: $password, proxyCode: $proxyCode}
  ) {
    splunkConnection {
      id
      url
      username
      proxyCode
      isWorking
    }
  }
}
    `;
export type AddSplunkConnectionMutationFn = ApolloReactCommon.MutationFunction<AddSplunkConnectionMutation, AddSplunkConnectionMutationVariables>;

/**
 * __useAddSplunkConnectionMutation__
 *
 * To run a mutation, you first call `useAddSplunkConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSplunkConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSplunkConnectionMutation, { data, loading, error }] = useAddSplunkConnectionMutation({
 *   variables: {
 *      url: // value for 'url'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      proxyCode: // value for 'proxyCode'
 *   },
 * });
 */
export function useAddSplunkConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSplunkConnectionMutation, AddSplunkConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddSplunkConnectionMutation, AddSplunkConnectionMutationVariables>(AddSplunkConnectionDocument, options);
      }
export type AddSplunkConnectionMutationHookResult = ReturnType<typeof useAddSplunkConnectionMutation>;
export type AddSplunkConnectionMutationResult = ApolloReactCommon.MutationResult<AddSplunkConnectionMutation>;
export type AddSplunkConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSplunkConnectionMutation, AddSplunkConnectionMutationVariables>;
export const UpdateSplunkConnectionDocument = gql`
    mutation UpdateSplunkConnection($splunkConnectionId: ObjectID!, $url: String!, $username: String!, $password: String!, $proxyCode: SplunkProxyCode) {
  updateSplunkConnection(
    input: {splunkConnectionId: $splunkConnectionId, url: $url, username: $username, password: $password, proxyCode: $proxyCode}
  ) {
    splunkConnection {
      id
      url
      username
      proxyCode
      isWorking
    }
  }
}
    `;
export type UpdateSplunkConnectionMutationFn = ApolloReactCommon.MutationFunction<UpdateSplunkConnectionMutation, UpdateSplunkConnectionMutationVariables>;

/**
 * __useUpdateSplunkConnectionMutation__
 *
 * To run a mutation, you first call `useUpdateSplunkConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSplunkConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSplunkConnectionMutation, { data, loading, error }] = useUpdateSplunkConnectionMutation({
 *   variables: {
 *      splunkConnectionId: // value for 'splunkConnectionId'
 *      url: // value for 'url'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      proxyCode: // value for 'proxyCode'
 *   },
 * });
 */
export function useUpdateSplunkConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSplunkConnectionMutation, UpdateSplunkConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSplunkConnectionMutation, UpdateSplunkConnectionMutationVariables>(UpdateSplunkConnectionDocument, options);
      }
export type UpdateSplunkConnectionMutationHookResult = ReturnType<typeof useUpdateSplunkConnectionMutation>;
export type UpdateSplunkConnectionMutationResult = ApolloReactCommon.MutationResult<UpdateSplunkConnectionMutation>;
export type UpdateSplunkConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSplunkConnectionMutation, UpdateSplunkConnectionMutationVariables>;
export const RemoveSplunkConnectionDocument = gql`
    mutation RemoveSplunkConnection($splunkConnectionId: ObjectID!) {
  deleteSplunkConnection(input: {splunkConnectionId: $splunkConnectionId}) {
    splunkConnection {
      id
    }
  }
}
    `;
export type RemoveSplunkConnectionMutationFn = ApolloReactCommon.MutationFunction<RemoveSplunkConnectionMutation, RemoveSplunkConnectionMutationVariables>;

/**
 * __useRemoveSplunkConnectionMutation__
 *
 * To run a mutation, you first call `useRemoveSplunkConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSplunkConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSplunkConnectionMutation, { data, loading, error }] = useRemoveSplunkConnectionMutation({
 *   variables: {
 *      splunkConnectionId: // value for 'splunkConnectionId'
 *   },
 * });
 */
export function useRemoveSplunkConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSplunkConnectionMutation, RemoveSplunkConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveSplunkConnectionMutation, RemoveSplunkConnectionMutationVariables>(RemoveSplunkConnectionDocument, options);
      }
export type RemoveSplunkConnectionMutationHookResult = ReturnType<typeof useRemoveSplunkConnectionMutation>;
export type RemoveSplunkConnectionMutationResult = ApolloReactCommon.MutationResult<RemoveSplunkConnectionMutation>;
export type RemoveSplunkConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSplunkConnectionMutation, RemoveSplunkConnectionMutationVariables>;
export const GetCreditUsageDataDocument = gql`
    query GetCreditUsageData($accountId: ObjectID!, $filters: CreditReportConnectionFilterInput!, $first: Int, $last: Int, $before: String, $after: String, $orderBy: [CreditReportOrder!]) {
  getAccount(id: $accountId) {
    id
    totalCreditReports: creditReports {
      totalCount
    }
    creditReports(
      filter: $filters
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          finishedAt
          createdAt
          updatedAt
          crawlId
          creditsUsed
          creditAllocationType
          crawl {
            id
            createdByUser {
              id
              email
            }
          }
          project {
            id
            name
            primaryDomain
          }
        }
      }
      totalCount
    }
  }
}
    `;

/**
 * __useGetCreditUsageDataQuery__
 *
 * To run a query within a React component, call `useGetCreditUsageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditUsageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditUsageDataQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCreditUsageDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCreditUsageDataQuery, GetCreditUsageDataQueryVariables> & ({ variables: GetCreditUsageDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCreditUsageDataQuery, GetCreditUsageDataQueryVariables>(GetCreditUsageDataDocument, options);
      }
export function useGetCreditUsageDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditUsageDataQuery, GetCreditUsageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCreditUsageDataQuery, GetCreditUsageDataQueryVariables>(GetCreditUsageDataDocument, options);
        }
export function useGetCreditUsageDataSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetCreditUsageDataQuery, GetCreditUsageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetCreditUsageDataQuery, GetCreditUsageDataQueryVariables>(GetCreditUsageDataDocument, options);
        }
export type GetCreditUsageDataQueryHookResult = ReturnType<typeof useGetCreditUsageDataQuery>;
export type GetCreditUsageDataLazyQueryHookResult = ReturnType<typeof useGetCreditUsageDataLazyQuery>;
export type GetCreditUsageDataSuspenseQueryHookResult = ReturnType<typeof useGetCreditUsageDataSuspenseQuery>;
export type GetCreditUsageDataQueryResult = ApolloReactCommon.QueryResult<GetCreditUsageDataQuery, GetCreditUsageDataQueryVariables>;
export const GenerateCreditReportExportsDocument = gql`
    mutation GenerateCreditReportExports($accountId: ObjectID!, $filter: CreditReportConnectionFilterInput!) {
  createCreditReportExport(input: {accountId: $accountId, filter: $filter}) {
    creditReportExport {
      id
    }
  }
}
    `;
export type GenerateCreditReportExportsMutationFn = ApolloReactCommon.MutationFunction<GenerateCreditReportExportsMutation, GenerateCreditReportExportsMutationVariables>;

/**
 * __useGenerateCreditReportExportsMutation__
 *
 * To run a mutation, you first call `useGenerateCreditReportExportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCreditReportExportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCreditReportExportsMutation, { data, loading, error }] = useGenerateCreditReportExportsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGenerateCreditReportExportsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateCreditReportExportsMutation, GenerateCreditReportExportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GenerateCreditReportExportsMutation, GenerateCreditReportExportsMutationVariables>(GenerateCreditReportExportsDocument, options);
      }
export type GenerateCreditReportExportsMutationHookResult = ReturnType<typeof useGenerateCreditReportExportsMutation>;
export type GenerateCreditReportExportsMutationResult = ApolloReactCommon.MutationResult<GenerateCreditReportExportsMutation>;
export type GenerateCreditReportExportsMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateCreditReportExportsMutation, GenerateCreditReportExportsMutationVariables>;
export const GeneratedCreditReportExportsDocument = gql`
    query GeneratedCreditReportExports($id: ObjectID!) {
  node(id: $id) {
    ... on CreditReportExport {
      id
      exportingAt
      fileURL
    }
  }
}
    `;

/**
 * __useGeneratedCreditReportExportsQuery__
 *
 * To run a query within a React component, call `useGeneratedCreditReportExportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratedCreditReportExportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratedCreditReportExportsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGeneratedCreditReportExportsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GeneratedCreditReportExportsQuery, GeneratedCreditReportExportsQueryVariables> & ({ variables: GeneratedCreditReportExportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GeneratedCreditReportExportsQuery, GeneratedCreditReportExportsQueryVariables>(GeneratedCreditReportExportsDocument, options);
      }
export function useGeneratedCreditReportExportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GeneratedCreditReportExportsQuery, GeneratedCreditReportExportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GeneratedCreditReportExportsQuery, GeneratedCreditReportExportsQueryVariables>(GeneratedCreditReportExportsDocument, options);
        }
export function useGeneratedCreditReportExportsSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GeneratedCreditReportExportsQuery, GeneratedCreditReportExportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GeneratedCreditReportExportsQuery, GeneratedCreditReportExportsQueryVariables>(GeneratedCreditReportExportsDocument, options);
        }
export type GeneratedCreditReportExportsQueryHookResult = ReturnType<typeof useGeneratedCreditReportExportsQuery>;
export type GeneratedCreditReportExportsLazyQueryHookResult = ReturnType<typeof useGeneratedCreditReportExportsLazyQuery>;
export type GeneratedCreditReportExportsSuspenseQueryHookResult = ReturnType<typeof useGeneratedCreditReportExportsSuspenseQuery>;
export type GeneratedCreditReportExportsQueryResult = ApolloReactCommon.QueryResult<GeneratedCreditReportExportsQuery, GeneratedCreditReportExportsQueryVariables>;
export const AccountSubscriptionDocument = gql`
    query AccountSubscription($accountId: ObjectID!) {
  getAccount(id: $accountId) {
    id
    accountManagers
    maxDashboardCollectionViews
    maxCrawlRate
    limitPagesMax
    limitLevelsMax
    maxProjectTests
    contractTerminationDate
    contractStartDate
    accessibilityCredits: availableCredits(creditAllocationType: Accessibility)
    seoCredits: availableCredits(creditAllocationType: SEO)
    siteSpeedCredits: availableCredits(creditAllocationType: SiteSpeed)
    primaryAccountPackage {
      maxSeoCredits: credits
      maxAccessibilityCredits: creditsAccessibility
      maxSiteSpeedCredits: creditsSiteSpeed
    }
    accountSettings {
      type
      dataType
      source
      limit
      code
      description
      visible
      unit
      name
    }
    subscription {
      automateAvailable
      monitorAvailable
      analyzeAvailable
      impactAvailable
      status
      plan {
        code
        name
        status
      }
    }
  }
}
    `;

/**
 * __useAccountSubscriptionQuery__
 *
 * To run a query within a React component, call `useAccountSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSubscriptionQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountSubscriptionQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AccountSubscriptionQuery, AccountSubscriptionQueryVariables> & ({ variables: AccountSubscriptionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<AccountSubscriptionQuery, AccountSubscriptionQueryVariables>(AccountSubscriptionDocument, options);
      }
export function useAccountSubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountSubscriptionQuery, AccountSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<AccountSubscriptionQuery, AccountSubscriptionQueryVariables>(AccountSubscriptionDocument, options);
        }
export function useAccountSubscriptionSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AccountSubscriptionQuery, AccountSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<AccountSubscriptionQuery, AccountSubscriptionQueryVariables>(AccountSubscriptionDocument, options);
        }
export type AccountSubscriptionQueryHookResult = ReturnType<typeof useAccountSubscriptionQuery>;
export type AccountSubscriptionLazyQueryHookResult = ReturnType<typeof useAccountSubscriptionLazyQuery>;
export type AccountSubscriptionSuspenseQueryHookResult = ReturnType<typeof useAccountSubscriptionSuspenseQuery>;
export type AccountSubscriptionQueryResult = ApolloReactCommon.QueryResult<AccountSubscriptionQuery, AccountSubscriptionQueryVariables>;
export const AddAccountUserDocument = gql`
    mutation AddAccountUser($accountId: ObjectID!, $roleCode: RoleCode!, $userEmail: String!) {
  addAccountUser(
    input: {accountId: $accountId, roleCode: $roleCode, userEmail: $userEmail}
  ) {
    account {
      id
    }
  }
}
    `;
export type AddAccountUserMutationFn = ApolloReactCommon.MutationFunction<AddAccountUserMutation, AddAccountUserMutationVariables>;

/**
 * __useAddAccountUserMutation__
 *
 * To run a mutation, you first call `useAddAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountUserMutation, { data, loading, error }] = useAddAccountUserMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      roleCode: // value for 'roleCode'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useAddAccountUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAccountUserMutation, AddAccountUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddAccountUserMutation, AddAccountUserMutationVariables>(AddAccountUserDocument, options);
      }
export type AddAccountUserMutationHookResult = ReturnType<typeof useAddAccountUserMutation>;
export type AddAccountUserMutationResult = ApolloReactCommon.MutationResult<AddAccountUserMutation>;
export type AddAccountUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAccountUserMutation, AddAccountUserMutationVariables>;
export const DeleteAccountUserDocument = gql`
    mutation DeleteAccountUser($accountId: ObjectID!, $userId: ObjectID!) {
  deleteAccountUser(input: {accountId: $accountId, userId: $userId}) {
    account {
      id
    }
  }
}
    `;
export type DeleteAccountUserMutationFn = ApolloReactCommon.MutationFunction<DeleteAccountUserMutation, DeleteAccountUserMutationVariables>;

/**
 * __useDeleteAccountUserMutation__
 *
 * To run a mutation, you first call `useDeleteAccountUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountUserMutation, { data, loading, error }] = useDeleteAccountUserMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteAccountUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccountUserMutation, DeleteAccountUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteAccountUserMutation, DeleteAccountUserMutationVariables>(DeleteAccountUserDocument, options);
      }
export type DeleteAccountUserMutationHookResult = ReturnType<typeof useDeleteAccountUserMutation>;
export type DeleteAccountUserMutationResult = ApolloReactCommon.MutationResult<DeleteAccountUserMutation>;
export type DeleteAccountUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAccountUserMutation, DeleteAccountUserMutationVariables>;
export const GetAccountUsersDocument = gql`
    query GetAccountUsers($accountId: ObjectID!, $cursor: String) {
  getAccount(id: $accountId) {
    id
    accountUsers(after: $cursor, first: 100) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          userLastName
          userId
          userFirstName
          userEmailValidatedAt
          userSsoClientId
          userEmail
          roleCode
          role {
            code
            name
          }
          account {
            id
            active
          }
        }
      }
      totalCount
    }
    accountSsoClients {
      totalCount
    }
    accountSettings {
      limit
      name
    }
  }
}
    `;

/**
 * __useGetAccountUsersQuery__
 *
 * To run a query within a React component, call `useGetAccountUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountUsersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAccountUsersQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAccountUsersQuery, GetAccountUsersQueryVariables> & ({ variables: GetAccountUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAccountUsersQuery, GetAccountUsersQueryVariables>(GetAccountUsersDocument, options);
      }
export function useGetAccountUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccountUsersQuery, GetAccountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAccountUsersQuery, GetAccountUsersQueryVariables>(GetAccountUsersDocument, options);
        }
export function useGetAccountUsersSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetAccountUsersQuery, GetAccountUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetAccountUsersQuery, GetAccountUsersQueryVariables>(GetAccountUsersDocument, options);
        }
export type GetAccountUsersQueryHookResult = ReturnType<typeof useGetAccountUsersQuery>;
export type GetAccountUsersLazyQueryHookResult = ReturnType<typeof useGetAccountUsersLazyQuery>;
export type GetAccountUsersSuspenseQueryHookResult = ReturnType<typeof useGetAccountUsersSuspenseQuery>;
export type GetAccountUsersQueryResult = ApolloReactCommon.QueryResult<GetAccountUsersQuery, GetAccountUsersQueryVariables>;
export const ResendInviteLinkDocument = gql`
    mutation ResendInviteLink($accountId: ObjectID!, $userId: ObjectID!) {
  resendAccountUserInvite(input: {accountId: $accountId, userId: $userId}) {
    account {
      id
    }
  }
}
    `;
export type ResendInviteLinkMutationFn = ApolloReactCommon.MutationFunction<ResendInviteLinkMutation, ResendInviteLinkMutationVariables>;

/**
 * __useResendInviteLinkMutation__
 *
 * To run a mutation, you first call `useResendInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteLinkMutation, { data, loading, error }] = useResendInviteLinkMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInviteLinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendInviteLinkMutation, ResendInviteLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResendInviteLinkMutation, ResendInviteLinkMutationVariables>(ResendInviteLinkDocument, options);
      }
export type ResendInviteLinkMutationHookResult = ReturnType<typeof useResendInviteLinkMutation>;
export type ResendInviteLinkMutationResult = ApolloReactCommon.MutationResult<ResendInviteLinkMutation>;
export type ResendInviteLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendInviteLinkMutation, ResendInviteLinkMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($accountId: ObjectID!, $roleCode: RoleCode!, $userId: ObjectID!) {
  updateAccountUser(
    input: {accountId: $accountId, roleCode: $roleCode, userId: $userId}
  ) {
    account {
      id
      accountUsers {
        nodes {
          roleCode
          userId
        }
      }
    }
  }
}
    `;
export type UpdateUserRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      roleCode: // value for 'roleCode'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const UpdateUserDetailsDocument = gql`
    mutation UpdateUserDetails($firstName: String!, $lastName: String!, $jobTitle: String!) {
  updateCurrentUser(
    input: {firstName: $firstName, jobTitle: $jobTitle, lastName: $lastName}
  ) {
    user {
      id
    }
  }
}
    `;
export type UpdateUserDetailsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>;

/**
 * __useUpdateUserDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDetailsMutation, { data, loading, error }] = useUpdateUserDetailsMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      jobTitle: // value for 'jobTitle'
 *   },
 * });
 */
export function useUpdateUserDetailsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>(UpdateUserDetailsDocument, options);
      }
export type UpdateUserDetailsMutationHookResult = ReturnType<typeof useUpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateUserDetailsMutation>;
export type UpdateUserDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserDetailsMutation, UpdateUserDetailsMutationVariables>;
export const GetUserSettingsDataDocument = gql`
    query GetUserSettingsData($userId: ObjectID!) {
  node(id: $userId) {
    ... on User {
      id
      email
      firstName
      lastName
      jobTitle
      username
    }
  }
}
    `;

/**
 * __useGetUserSettingsDataQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserSettingsDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserSettingsDataQuery, GetUserSettingsDataQueryVariables> & ({ variables: GetUserSettingsDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserSettingsDataQuery, GetUserSettingsDataQueryVariables>(GetUserSettingsDataDocument, options);
      }
export function useGetUserSettingsDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserSettingsDataQuery, GetUserSettingsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserSettingsDataQuery, GetUserSettingsDataQueryVariables>(GetUserSettingsDataDocument, options);
        }
export function useGetUserSettingsDataSuspenseQuery(baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetUserSettingsDataQuery, GetUserSettingsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useSuspenseQuery<GetUserSettingsDataQuery, GetUserSettingsDataQueryVariables>(GetUserSettingsDataDocument, options);
        }
export type GetUserSettingsDataQueryHookResult = ReturnType<typeof useGetUserSettingsDataQuery>;
export type GetUserSettingsDataLazyQueryHookResult = ReturnType<typeof useGetUserSettingsDataLazyQuery>;
export type GetUserSettingsDataSuspenseQueryHookResult = ReturnType<typeof useGetUserSettingsDataSuspenseQuery>;
export type GetUserSettingsDataQueryResult = ApolloReactCommon.QueryResult<GetUserSettingsDataQuery, GetUserSettingsDataQueryVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(input: {email: $email})
}
    `;
export type RequestPasswordResetMutationFn = ApolloReactCommon.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($firstName: String!, $lastName: String!, $email: String!, $jobTitle: String!) {
  updateCurrentUser(
    input: {email: $email, firstName: $firstName, jobTitle: $jobTitle, lastName: $lastName}
  ) {
    user {
      id
      username
    }
  }
}
    `;
export type UpdateUserSettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      jobTitle: // value for 'jobTitle'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, options);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = ApolloReactCommon.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;